<div class="form-group">
    <label class="title">
        {{ 'key-lockScreen' | translate | capitalFirstWord }}
    </label>
    <ng-template [ngIf]="_lockInfo">
        <ng-template ngFor let-lockItem [ngForOf]="LOCKSCREEN_LIST">
            <i *ngIf="_lockInfo[lockItem.mode]" class="fas fa-lock ml-2 red-1" data-toggle="tooltip" [title]="lockItem.displayName + ' is lock by policy <<' + _lockInfo[lockItem.mode].policyName + '>>'"></i>
        </ng-template>
    </ng-template>
    <div class='switch-container'>
        <input id="cmn-toggle-lockscreen" class="cmn-toggle cmn-toggle-flat cmn-toggle-rect-flat" type="checkbox"
            [disabled]="_lockInfo" [checked]="_info.enabled ? 'checked': null"
            (change)="enableFeature($event.target.checked)">
        <label for="cmn-toggle-lockscreen">
            <span class="sr-only">Enable {{ 'key-lockScreen' | translate }}</span>
        </label>
    </div>
    <div class="mt-2">
        <div>
            Select mode :
            <div class="btn-group ml-2">
                <button id="dropdownLockScreenMode" type="button" [disabled]="!_info.enabled || _lockInfo"
                    class="btn btn-sm btn-action btn-light dropdown-toggle" data-toggle="dropdown">
                    {{ _info.lockScreenMode || 'Select a mode' }}
                </button>
                <ul class="dropdown-menu dropdown-menu-left" aria-labelledby="dropdownLockScreenMode">
                    <li *ngFor="let lockScreenMode of LOCKSCREEN_LIST" class="dropdown-item"
                        (click)="selectLockScreenMode(lockScreenMode)">
                        {{ lockScreenMode.displayName }}
                    </li>
                </ul>
            </div>
        </div>
        <div>
            <span [ngSwitch]="_info.lockScreenMode">
                <!-- user sign up and wait for admin to send invitation mail -->
                <ng-template [ngSwitchCase]="_enumLockScreenMode.screenOff">
                    <na-screenOff [data]="_info.data" [disabled]="!_info.enabled" [showSwitch]="false"
                        [lock]="_lockInfo?.[_enumLockScreenMode.screenOff]"
                        (onDataChanged)="onLockScreenSettingChanged($event)"></na-screenOff>
                </ng-template>
                <!-- admin send invitation mail and wait for user to accept -->
                <ng-template [ngSwitchCase]="_enumLockScreenMode.screenSaver">
                    <na-screenSaver [data]="_info.data" [disabled]="!_info.enabled" [showSwitch]="false"
                        [lock]="_lockInfo?.[_enumLockScreenMode.screenSaver]"
                        (onDataChanged)="onLockScreenSettingChanged($event)"></na-screenSaver>
                </ng-template>
            </span>
        </div>
    </div>
</div>