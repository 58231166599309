import { Component, OnInit } from '@angular/core';

import { ConstantService } from '../../../../../../lib/common/constant.service';
import { DeviceInfo } from '../../../../../device/data/device-info';
import { DeviceService } from '../../../../../device/device.service';
import { DeviceCommandAlertConfigData } from '../../alert.data';
import { AlertHelper } from '../../alert.helper';
import { IAlertDataCtrl } from '../alert-data-ctrl.interface';

@Component({
    templateUrl: './alert-data-device-cmd.component.html',
    styleUrls: ['../../alert.style.css']
})
export class AlertDeviceCommandDataComponent implements OnInit, IAlertDataCtrl {
    _receivers: string;
    _monitorList: { id: string, displayName: string, checked: boolean }[] = [];
    _defaultSelectDeviceIDList: string[] = [];
    _updateDeviceList: DeviceInfo[] = [];

    private _data: DeviceCommandAlertConfigData = new DeviceCommandAlertConfigData();
    set alertData(data: DeviceCommandAlertConfigData) {
        this._data = data;
        this._receivers = this._data && this._data.alertReceiverList ? this._data.alertReceiverList.join('\n') : '';
        this._defaultSelectDeviceIDList = this._data.alertApplyDeviceList.map(d => d.virtualDeviceID);
    }

    _isViewMode: boolean = true;
    set isViewMode(isView: boolean) {
        this._isViewMode = isView;
    }

    constructor(private constantSvc: ConstantService, private devSvc: DeviceService) { }

    ngOnInit(): void {
        const currMonitorMap: { [monitorID: string]: boolean } = this._data.taskActionIDList.reduce((acc, curr) => {
            acc[curr] = true;
            return acc;
        }, {});

        this._monitorList = [
            { id: this.constantSvc.TASKTYPE_REBOOT, displayName: 'Reboot failed' },
            { id: this.constantSvc.TASKTYPE_INSTALL_FIRMWARE, displayName: 'Update firmware failed' },
            { id: this.constantSvc.TASKTYPE_CONFIG_BASIC, displayName: 'Modify basic configuration failed' },
            { id: this.constantSvc.TASKTYPE_CONFIG_NET, displayName: 'Modify network configuration failed' },
            { id: this.constantSvc.TASKTYPE_SECURITY_LOCALPWD, displayName: 'Change device password failed' },
            { id: this.constantSvc.TASKTYPE_INSTALL_APK, displayName: 'Update IAdeaCare APK failed' },
            { id: this.constantSvc.TASKTYPE_CLEARCACHE, displayName: 'Clear device cache failed' }
        ].map(m => {
            return {
                id: m.id,
                displayName: m.displayName,
                checked: currMonitorMap[m.id]
            }
        });
    }

    changeMonitorItem(option: { id: string, displayName: string, checked: boolean }, checked: boolean): void {
        option.checked = checked;
        this._data.changeMonitorOptionList(this._monitorList);
    }

    changeReceivers(receiver: string): void {
        this._data.changeReceiver(receiver);
    }

    transformReceiverList(receiverList: string[]): string {
        return AlertHelper.transformReceiverList(receiverList);
    }

    transformApplyDeviceList(deviceIDList: { virtualDeviceID: string }[]): string {
        return AlertHelper.transformApplyDeviceList(this.devSvc, deviceIDList);
    }

    onDeviceCommandAlertDeviceSelectChange(deviceList: DeviceInfo[]): void {
        this._data.changeApplyDeviceList(deviceList);
    }
}