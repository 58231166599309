import { Component, Input, OnInit } from '@angular/core';
import { ConstantService } from '../../../../app/lib/common/constant.service';
import { DailyRebootInfo } from './dailyReboot.data';

@Component({
    selector: 'na-dailyReboot-preview',
    templateUrl: './dailyReboot-preview.component.html'
})
export class DailyRebootPreviewComponent implements OnInit {
    _info: DailyRebootInfo;

    private _config: { [name: string]: { value: any } } = {};
    @Input('config')
    set config(c: { [name: string]: { value: any } }) {
        this._config = c;
    }

    private _target: string = 'origin';
    @Input('target')
    set target(c: string) {
        this._target = c;
    }

    constructor(private constantSvc: ConstantService) {}

    ngOnInit(): void {
        this._info = new DailyRebootInfo(this._config[this.constantSvc.DEVKEY_SCHEDULE_REBOOT]?.[this._target], this._config[this.constantSvc.DEVKEY_SCHEDULE_REBOOT_ENABLED]?.[this._target]);
    }
}