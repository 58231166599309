export enum AppMode {
    normal = 'normal',
    preview = 'preview'
}

export class AppCacheData {
    backURL: string;
    demoRouteList: { route: string, hint?: string }[];
    raw: any;

    constructor(backUrl: string, demoRouteList: { route: string, hint?: string }[], rawData?: any) {
        this.backURL = backUrl;
        this.demoRouteList = demoRouteList;
        this.raw = rawData;
    }
}

export class AppPreviewCacheData extends AppCacheData {
    bgUrl: string;
    logoDarkUrl: string;
    logoLightUrl: string;

    constructor(backUrl: string, demoRouteList: { route: string, hint?: string }[], bgUrl: string, logoDarkUrl: string, logoLightUrl: string, rawData?: any) {
        super(backUrl, demoRouteList, rawData);

        this.bgUrl = bgUrl;
        this.logoDarkUrl = logoDarkUrl;
        this.logoLightUrl = logoLightUrl;
    }
}

