import { Component, Input, OnInit } from '@angular/core';
import { DeviceInfo } from '../../device/data/device-info';
import { DialogPage } from '../../../lib/common/common.data';
import { LicenseBaseFormComponent } from './license-base-form.component';

@Component({
    selector: 'license-add',
    templateUrl: './license-add-form.component.html',
    styleUrls: ['./license-add-form.component.css', './license-material.style.css']
})
export class LicenseAddFormComponent extends LicenseBaseFormComponent implements OnInit {
    @Input()
    set devices(devs: DeviceInfo[]) {
        if (!devs) {
            return;
        }

        this._devices = devs;
    }

    private _devLicenseImportErrorList: { device: DeviceInfo, errorMessageList: { licenseKey: string, errorMessage: string }[] }[] = [];
    private _devLicenseKeyMap: { [virtualDeviceId: string]: { raw?: string, keys: string[], formatError?: string, duplicateError?: string } } = {};

    ngOnInit(): void {
        super.ngOnInit();

        if (this._devices.length > 0) {
            const comparedAccountID: string = this.accountSvc.isEnterprise() ? this.accountSvc.enterpriseAccountID : this.accountSvc.accountID;
            this._legalDevices = this._devices.filter(d => d.virtualDeviceOwnerID === comparedAccountID);
            //check if devices are shared since shared device do not have permission to do license function.
            this._shareForbiddenDevices = this._devices.filter(d => d.virtualDeviceOwnerID !== comparedAccountID);
            if (this._shareForbiddenDevices.length === 0) {
                this.goNext();
            }
        }
    }

    goNext(): void {
        switch (this._page) {
            case DialogPage.prepare:
                {
                    this._legalDevices.forEach((dev: DeviceInfo) => {
                        this._devLicenseKeyMap[dev.virtualId] = { keys: [] };
                    });
                    super.goNext();
                }
                break;
            case DialogPage.submit:
                {
                    super.goNext();
                }
                break;
            case DialogPage.action:
                {
                    let hasError: boolean = false;
                    const licenseKeys: string[] = [];
                    Object.keys(this._devLicenseKeyMap).forEach((virtualDeviceId: string) => {
                        this._devLicenseKeyMap[virtualDeviceId].formatError = null;
                        this._devLicenseKeyMap[virtualDeviceId].duplicateError = null;
                        this._devLicenseKeyMap[virtualDeviceId].keys = [];

                        if (this._devLicenseKeyMap[virtualDeviceId].raw) {
                            this._devLicenseKeyMap[virtualDeviceId].keys = this._devLicenseKeyMap[virtualDeviceId].raw.split(/[,;]+/).map(s => s.trim().toUpperCase());
                            const errorKeys: string[] = [];
                            const duplicateKeys: string[] = [];

                            for (const licenseKey of this._devLicenseKeyMap[virtualDeviceId].keys) {
                                if (!this.licenseSvc.isLicenseFormatValid(licenseKey)) {
                                    errorKeys.push(licenseKey);
                                }
                                else {
                                    const found_duplicate_key: string = licenseKeys.find((key: string) => key === licenseKey);
                                    if (found_duplicate_key) {
                                        duplicateKeys.push(licenseKey);
                                    }
                                    else {
                                        licenseKeys.push(licenseKey);
                                    }
                                }
                            }

                            if (errorKeys.length > 0) {
                                this._devLicenseKeyMap[virtualDeviceId].formatError = 'Format of ' + errorKeys.join(',') + (errorKeys.length > 1 ? ' are ' : ' is ') + 'wrong';
                                hasError = true;
                            }
                            if (duplicateKeys.length > 0) {
                                this._devLicenseKeyMap[virtualDeviceId].duplicateError = 'License ' + duplicateKeys.join(',') + (duplicateKeys.length > 1 ? ' are ' : ' is ') + 'duplicate';
                                hasError = true;
                            }
                        }
                    });

                    if (!hasError) {
                        super.goNext();
                    }
                }
                break;
            case DialogPage.confirm:
                {
                    this._page = DialogPage.submit;

                    const requests: { licenseKeys: string[], targetDevice: DeviceInfo }[] = [];
                    Object.keys(this._devLicenseKeyMap).forEach((virtualDeviceId: string) => {
                        if (this._devLicenseKeyMap[virtualDeviceId].keys.length > 0) {
                            const dev: DeviceInfo = this._legalDevices.find((d: DeviceInfo) => d.virtualId === virtualDeviceId);
                            if (dev) {
                                requests.push({
                                    licenseKeys: this._devLicenseKeyMap[virtualDeviceId].keys,
                                    targetDevice: dev
                                })
                            }
                        }
                    });

                    this._devLicenseImportErrorList = [];
                    this.licenseSvc.importAndAssignLicense(requests).subscribe((res: { hasNext: boolean, result?: { targetDevice: DeviceInfo, isFault: boolean, errorMessage?: any }[] }) => {
                        if (!res.hasNext) {
                            if (res.result) {
                                res.result.forEach(result => {
                                    if (result.isFault) {
                                        //only consider import error now.
                                        if (result.errorMessage instanceof Array) {
                                            this._devLicenseImportErrorList.push({
                                                device: result.targetDevice,
                                                errorMessageList: result.errorMessage
                                            });
                                        }
                                    }
                                });
                            }
                            super.goNext();
                        }
                    });
                }
                break;
        }
    }

    allowSubmit(): boolean {
        if (this._page === DialogPage.confirm) {
            for (const virtualDeviceId of Object.keys(this._devLicenseKeyMap)) {
                if (this._devLicenseKeyMap[virtualDeviceId] && this._devLicenseKeyMap[virtualDeviceId].keys.length > 0) {
                    return true;
                }
            }
            return false;
        }

        return super.allowSubmit();
    }

    protected isPageValid(page: DialogPage): boolean {
        if (page === DialogPage.prepare) {
            //check if devices length > 0
            return this._legalDevices.length > 0 ? true : false;
        }

        return super.isPageValid(page);
    }
}