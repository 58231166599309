import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../../api.base';
import { IAlertSetting } from '../api.alert.common';

export interface IListAlertQueryParameter {
    skip?: number;
    limit?: number;
}

export interface IListAlertRxData {
    skip: number;
    limit: number;
    total: number;
    itemList: IAlertSetting[];
}

export class APIListAlertManager extends APIBaseManager<void, IListAlertQueryParameter, void, IListAlertRxData> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_ListAlert';
        this._apiMethod = 'GET';
    }

    protected getRequestURL(pathParameters: void, queryParameters: IListAlertQueryParameter): string {
        return super.getRequestURL() + 'accounts/alertSettings' + this.transformObjectQueryParameterToStr(queryParameters);
    }
}