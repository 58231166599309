import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../api.base';
import { ITaskData } from './api.task.common';

export interface IListTaskPathParameter {
    virtualDeviceID: string;
    virtualDevicePairedID: string;
}

export interface IListTaskQueryParameter {
    limit?: number;
    skip?: number;
}

export interface IListTaskRxData {
    limit: number;
    skip: number;
    total: number;
    itemList: ITaskData[]
}

export class APIListTaskManager extends APIBaseManager<IListTaskPathParameter, IListTaskQueryParameter, void, IListTaskRxData> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_ListTask';
        this._apiMethod = 'GET';
    }

    protected getRequestURL(pathParameters: IListTaskPathParameter, queryParameters: IListTaskQueryParameter): string {
        return super.getRequestURL() + 'virtualDevices/' + pathParameters.virtualDeviceID + '/' + pathParameters.virtualDevicePairedID + '/tasks' + this.transformObjectQueryParameterToStr(queryParameters);
    }
}