import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { Router, Event, NavigationEnd } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TopMenuService } from '../../../../app/content/menu.service';
import { AccountService } from '../../../../app/entry/account.service';

enum ReportTabType {
    records = 'records',
    config = 'config',
}

@Component({
    templateUrl: './report-wrapper.component.html',
    styleUrls: []
})
export class ReportWrapperComponent implements OnInit, OnDestroy {
    private _allUnsubscribe: Subject<void> = new Subject();

    _reportListRoute: string;
    _reportConfigRoute: string;

    _activeTab: ReportTabType = ReportTabType.config;
    _enumReportTabType: typeof ReportTabType = ReportTabType;

    constructor(
        private location: Location,
        private router: Router,
        private acocuntSvc: AccountService,
        private menuSvc: TopMenuService) {
    }

    ngOnInit(): void {
        this._reportConfigRoute = this.menuSvc.getReportRoute(ReportTabType.config);
        this._reportListRoute = this.menuSvc.getReportRoute(ReportTabType.records);

        this.router.events.pipe(
            takeUntil(this._allUnsubscribe)
        ).subscribe((routeEvent: Event) => {
            if (routeEvent instanceof NavigationEnd) {
                this.update_active_route(this.location.path());
            }
        });

        this.update_active_route(this.location.path());
    }

    ngOnDestroy(): void {
        this._allUnsubscribe.next();
        this._allUnsubscribe.complete();
    }

    supportReportView(): boolean {
        return this.acocuntSvc.hasScope_weeklyReport_view();
    }

    supportReportUpdate(): boolean {
        return this.acocuntSvc.hasScope_weeklyReport_update();
    }

    private update_active_route(route: string): void {
        const route_pieces: string[] = this.location.path().split(/\//g);
        if (!ReportTabType[route_pieces[route_pieces.length - 1]]) {
            this.router.navigate([this.menuSvc.getReportRoute(ReportTabType.records)]);
        }
        else {
            this._activeTab = ReportTabType[route_pieces[route_pieces.length - 1]];
        }
    }
}