<div *ngIf="!_isViewMode; else templateViewMode" class="row">
  <div class="col-md-5 col-12">
    <div class="form-group">
      <label>Auto apply to newly-paired device(s)</label>
      <div class='switch-container'>
        <input id="cmn-alert-offline-autoApply" class="cmn-toggle cmn-toggle-flat cmn-toggle-rect-flat" type="checkbox"
          [checked]="_data.isAlertAutoApply" (change)="_data.changeAutoApply($event.target.checked)">
        <label for="cmn-alert-offline-autoApply">
          <span class="sr-only">Auto apply to newly-paired device(s)</span>
        </label>
      </div>
    </div>
    <div class="form-group">
      <label for="alert-lastTime">Alert when this situation last for more than</label>
      <div class="btn-group w-100">
        <button id="dropdownOfflineAlertThresholdLink" type="button"
          class="btn btn-sm btn-action btn-light dropdown-toggle" data-toggle="dropdown">
          {{ _data.alertThreshold | timeAliasTransform }}
          <span class="sr-only">Select the alert moment when this situation lasts for more than</span>
        </button>
        <ul class="dropdown-menu dropdown-menu-left option-list w-100"
          aria-labelledby="dropdownOfflineAlertThresholdLink">
          <li *ngFor="let threshold of _alertThresholdList" [class.selected]="_data.alertThreshold === threshold"
            (click)="_data.changeThreshold(threshold)">
            <div>
              {{ threshold | timeAliasTransform }}
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="form-group">
      <label for="alert-checkpoint">Check the event every</label>
      <div class="btn-group w-100">
        <button id="dropdownAlertCheckPointLink" type="button" class="btn btn-sm btn-action btn-light dropdown-toggle"
          data-toggle="dropdown">
          {{ _data.alertCheckPoint | timeAliasTransform }}
          <span class="sr-only">Select the period the alert event is being detected</span>
        </button>
        <ul class="dropdown-menu dropdown-menu-left option-list w-100" aria-labelledby="dropdownAlertCheckPointLink">
          <li *ngFor="let checkPoint of _alertCheckPointList" [class.selected]="_data.alertCheckPoint === checkPoint"
            (click)="_data.changeCheckPoint(checkPoint)">
            <div>
              {{ checkPoint | timeAliasTransform }}
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="form-group">
      <div class="d-flex">
        <label for="alert-monitorPeriod">Monitor</label>
        <span class="note text-muted ml-1">(based on device's timezone)</span>
      </div>
      <fieldset class="fieldset-inset bg-white pt-0">
        <legend class="sr-only">Monitor period</legend>
        <ul>
          <li>
            <div>
              <label>Period</label>
              <div class="ml-2">
                <div class="cmn-radio">
                  <input id="id-monitorType-always" type="radio" name="monitor-type-radio"
                    [checked]="_data.alertMonitorType === _enumAlertEventMonitorType.always"
                    (change)="_data.changeMonitorType(_enumAlertEventMonitorType.always)" />
                  <label for="id-monitorType-always" class="radio"></label>
                  <span class="ml-2">Always</span>
                </div>
                <div class="cmn-radio">
                  <input id="id-monitorType-timeToTime" type="radio" name="monitor-type-radio"
                    [checked]="_data.alertMonitorType === _enumAlertEventMonitorType.timeToTime"
                    (change)="_data.changeMonitorType(_enumAlertEventMonitorType.timeToTime)" />
                  <label for="id-monitorType-timeToTime" class="radio"></label>
                  <span class="ml-2">From</span>
                  <div class="btn-group mx-2">
                    <button id="dropdownAlertMonitorPeriodFromLink" type="button"
                      class="btn btn-sm btn-action btn-light dropdown-toggle" data-toggle="dropdown"
                      [disabled]="_data.alertMonitorType !== _enumAlertEventMonitorType.timeToTime">
                      {{ _data.alertStartTime }}
                      <span class="sr-only">Monitor begin time</span>
                    </button>
                    <ul class="dropdown-menu dropdown-menu-left option-list"
                      aria-labelledby="dropdownAlertMonitorPeriodFromLink" style="min-width:300px">
                      <na-timePicker [time]="_data.alertStartTime" [minuteIncre]="30" [showBorder]="false"
                        [showSelectionTime]="false" (onTimeChanged)="_data.changeMonitorStartTime($event)">
                      </na-timePicker>
                    </ul>
                  </div>
                  <span>to</span>
                  <div class="btn-group mx-2">
                    <button id="dropdownAlertMonitorPeriodToLink" type="button"
                      class="btn btn-sm btn-action btn-light dropdown-toggle" data-toggle="dropdown"
                      [disabled]="_data.alertMonitorType !== _enumAlertEventMonitorType.timeToTime">
                      {{ _data.alertEndTime }}
                      <span class="sr-only">Monitor end time</span>
                    </button>
                    <ul class="dropdown-menu dropdown-menu-left option-list"
                      aria-labelledby="dropdownAlertMonitorPeriodToLink" style="min-width:300px">
                      <na-timePicker [time]="_data.alertEndTime" [minuteIncre]="30" [showBorder]="false"
                        [showSelectionTime]="false" (onTimeChanged)="_data.changeMonitorEndTime($event)">
                      </na-timePicker>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div>
              <label>Advanced settings</label>
              <div class="ml-2">
                <div class="d-flex align-items-center">
                  <input id="id-monitorOption-excludeWeekend" type="checkbox" class="cmn-checkbox"
                    [checked]="_data.isWeekendExcluded" (change)="_data.changeExcludeWeekend($event.target.checked)" />
                  <label for="id-monitorOption-excludeWeekend">
                    <svg>
                      <use xlink:href="#checkmark" />
                    </svg>
                    <span class="sr-only">Select a period to monitor the alert event</span>
                  </label>
                  <span class="ml-2">Exclude weekend</span>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </fieldset>
    </div>
    <div class="form-group">
      <div class="d-flex">
        <label for="alert-receiver">Deliver to</label>
        <span class="note text-muted ml-1">(split by comma or 'Enter')</span>
      </div>
      <div>
        <textarea id="alert-receiver" class="form-control" rows="6"
          placeholder="Enter valid email(s) here by spliting with comma or 'Enter'..." [ngModel]="_receivers"
          (ngModelChange)="changeReceivers($event)">
        </textarea>
      </div>
    </div>
  </div>
  <div class="col-md-7 col-12">
    <na-dev-select [title]="'Apply to'" [selectDeviceIDList]="_defaultSelectDeviceIDList"
      (onDeviceSelectChanged)="onOfflineAlertDeviceSelectChange($event)">
    </na-dev-select>
  </div>
</div>
<ng-template #templateViewMode>
  <div>
    <!-- auto apply -->
    <div *ngIf="_data.isAlertAutoApply" class="form-group row">
      <div class="col-12">
        <div id="col-alert-autoapply" class="form-control-plaintext red-1">
          This alert is set to apply to newly-paired devices
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label for="col-alert-alertLastTime" class="col-lg-7 col-6">
        Alert when this situation last for more than
      </label>
      <div class="col-lg-5 col-6">
        <div id="col-alert-alertLastTime" class="form-control-plaintext">
          {{ _data.alertThreshold | timeAliasTransform }}
        </div>
      </div>
    </div>
    <!-- event check -->
    <div class="form-group row">
      <label for="col-alert-eventcheck" class="col-lg-7 col-6">
        Check event every
      </label>
      <div class="col-lg-5 col-6">
        <div id="col-alert-eventcheck" class="form-control-plaintext">
          {{ _data.alertCheckPoint | timeAliasTransform }}
        </div>
      </div>
    </div>
    <!-- monitor period -->
    <div class="form-group row">
      <label for="col-alert-eventperiod" class="col-lg-7 col-6">
        Monitor period
      </label>
      <div class="col-lg-5 col-6">
        <div id="col-alert-eventperiod" class="form-control-plaintext">
          <div *ngIf="_data.alertMonitorType === _enumAlertEventMonitorType.always; else templateAlertTimeToTime">
            always
          </div>
          <ng-template #templateAlertTimeToTime>
            From {{ _data.alertStartTime }} to {{ _data.alertEndTime }}
          </ng-template>
        </div>
      </div>
    </div>
    <!-- apply device -->
    <div class="form-group row">
      <label for="col-alert-devices" class="col-12">
        Applied devices
      </label>
      <div class="col-12">
        <div id="col-alert-devices" class="alert-list-block">
          {{transformApplyDeviceList(_data.alertApplyDeviceList)}}
        </div>
      </div>
    </div>
    <!-- deliver to -->
    <div class="form-group row">
      <label for="col-alert-receiver" class="col-12">
        Alert receiver
      </label>
      <div class="col-12">
        <div id="col-alert-receiver" class="alert-list-block">
          {{transformReceiverList(_data.alertReceiverList)}}
        </div>
      </div>
    </div>
  </div>
</ng-template>