import { Injectable } from '@angular/core';
import { of as observableOf, Observable } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';

import { TicketInfo, TicketDeviceInfo } from './troubleshoot-data';
import { DeviceInfo, TaskStatus } from '../device/data/device-info';
import { AccountService } from '../../entry/account.service';
import { DeviceService } from '../device/device.service';
import { NAService } from '../../API/na.service';
import { ITicketQuestionnaire, ITicketData } from '../../API/v1/Ticket/api.ticket.common';
import { IAPIRx } from '../../API/api.base';
import { ConstantService } from '../../../app/lib/common/constant.service';
import { IListTicketRxData } from '../../API/v1/Ticket/api.ticket.list';
import { HelperLib } from '../../lib/common/helper.lib';

@Injectable()
export class TroubleshootService {
    private readonly TICKET_REFRESH_PERIOD: number = 6000000; //10 min
    readonly TICKET_REQUEST_LIMIT: number = 50;
    private _ticketMap: { [ticketID: string]: { ticketInfo: TicketInfo, lastUpdateTime: number } } = {}
    private _lastUpdateTime: number = 0;

    constructor(
        private constantSvc: ConstantService,
        private devSvc: DeviceService,
        private naSvc: NAService,
        private accountSvc: AccountService
    ) {
        this.accountSvc.loginChanged.subscribe((isLogin: boolean) => {
            if (isLogin) {
                this._ticketMap = {};
                this._lastUpdateTime = 0;
            }
        });
    }

    report(devices: DeviceInfo[] = [], reportData: {
        ticketSubject?: string,
        ticketBody?: string,
        questionnaire?: ITicketQuestionnaire,
        ignoreZendesk?: boolean,
        receiverList?: string[]
    }): Observable<IAPIRx<ITicketData>> {
        return observableOf(true).pipe(
            concatMap(() => {
                return this.naSvc.createTicket({
                    ticketAction: this.constantSvc.TICKETTYPE_TROUBLESHOOT,
                    resourceData: {
                        ticketSubject: reportData.ticketSubject,
                        ticketBody: reportData.ticketBody || '',
                        ticketQuestionnaire: reportData.questionnaire || null,
                        ignoreZendesk: reportData.ignoreZendesk,
                        logReceiverList: reportData.receiverList,
                        virtualDeviceList: devices.map(d => {
                            return {
                                virtualDeviceID: d.virtualId,
                                virtualDevicePairedID: d.virtualPairId
                            }
                        })
                    }
                }, this.accountSvc.token);
            }),
            map((res: IAPIRx<ITicketData>) => {
                if (res && res.error === 0) {
                    const ticketInfo = new TicketInfo();
                    ticketInfo.id = res.data.ticketID;
                    ticketInfo.status = res.data.ticketStatus.currentStatus;
                    ticketInfo.subject = reportData.ticketSubject;
                    ticketInfo.content = reportData.ticketBody || '';
                    res.data.ticketStatus.taskList.forEach((taskInfo) => {
                        const ticket_device = new TicketDeviceInfo();
                        ticket_device.virtualDeviceID = taskInfo.virtualDevice.virtualDeviceID;
                        ticket_device.virtualDevicePairedID = taskInfo.virtualDevice.virtualDevicePairedID;
                        ticket_device.taskId = taskInfo.taskID;
                        ticket_device.status = this.transform_task_status(taskInfo.status.currentStatus);

                        const match_device = devices.find(d => d.virtualId === ticket_device.virtualDeviceID);
                        if (match_device) {
                            ticket_device.name = match_device.virtualName;
                        }

                        ticketInfo.targets.push(ticket_device);
                    });

                    this._ticketMap[ticketInfo.id] = {
                        ticketInfo: ticketInfo,
                        lastUpdateTime: new Date().getTime()
                    };

                    this._lastUpdateTime = 0;
                }

                return res;
            })
        );
    }

    getTickets(page: number = 1, searchOption?: { subject?: string }): Observable<{ isFault: boolean, tickets?: TicketInfo[], skip?: number, limit?: number, total?: number, errorMessage?: string }> {
        const search: {
            ticketAction?: string;
            ticketSubject?: string;
        } = {
            ticketAction: this.constantSvc.TICKETTYPE_TROUBLESHOOT
        };

        if (searchOption && searchOption.subject) {
            search.ticketSubject = searchOption.subject;
        } 
        
        return this.naSvc.listTickets(this.accountSvc.token, (page - 1) * this.TICKET_REQUEST_LIMIT , this.TICKET_REQUEST_LIMIT, search).pipe(
            map((res: IAPIRx<IListTicketRxData>) => {
                if (res.error === 0 && res.data) {
                    const tickets: TicketInfo[] = [];
                    res.data.itemList.forEach((ticketData: ITicketData) => {
                        this._ticketMap[ticketData.ticketID] = this._ticketMap[ticketData.ticketID] || { ticketInfo: new TicketInfo(), lastUpdateTime: 0 };
                        const updateTicket: TicketInfo = this.update_ticket_infos(this._ticketMap[ticketData.ticketID].ticketInfo, ticketData);
                        tickets.push(updateTicket);
                    });

                    this._lastUpdateTime = Date.now();
                    return {
                        isFault: false,
                        tickets: tickets,
                        skip: res.data.skip,
                        limit: res.data.limit,
                        total: res.data.total
                    };
                }

                return {
                    isFault: true,
                    errorMessage: HelperLib.getErrorMessage(res)
                };
            })
        );
    }

    private update_ticket_infos(oldTicketInfo: TicketInfo, newTicketData: ITicketData): TicketInfo {
        oldTicketInfo.id = newTicketData.ticketID;
        oldTicketInfo.issueDateTime = newTicketData.issueDate;
        oldTicketInfo.startedDate = newTicketData.ticketStatus.startedDate;
        oldTicketInfo.finishedDate = newTicketData.ticketStatus.finishedDate;
        oldTicketInfo.status = this.transform_ticket_status(newTicketData.ticketStatus.currentStatus, newTicketData.ticketStatus.success);

        if (newTicketData.ticketInput && newTicketData.ticketInput.resourceData) {
            oldTicketInfo.subject = newTicketData.ticketInput.resourceData.ticketSubject;
            oldTicketInfo.content = newTicketData.ticketInput.resourceData.ticketBody;
            if (newTicketData.ticketInput.resourceData.ticketQuestionnaire) {
                oldTicketInfo.symptom = newTicketData.ticketInput.resourceData.ticketQuestionnaire.symptom;
                oldTicketInfo.frequency = newTicketData.ticketInput.resourceData.ticketQuestionnaire.frequency;
            }
        }

        if (newTicketData.ticketStatus.taskList) {
            oldTicketInfo.targets = newTicketData.ticketStatus.taskList.map(task => {
                const taskInfo = new TicketDeviceInfo();
                taskInfo.taskId = task.taskID;
                if (task.virtualDevice) {
                    taskInfo.name = this.devSvc.getDeviceName(task.virtualDevice.virtualDeviceID);
                    if (taskInfo.name !== task.virtualDevice.virtualDeviceID) {
                        taskInfo.detailURL = '/app/device/devices/' + task.virtualDevice.virtualDeviceID;
                    }
                    taskInfo.virtualDeviceID = task.virtualDevice.virtualDeviceID;
                    taskInfo.virtualDevicePairedID = task.virtualDevice.virtualDevicePairedID;
                }
                if (task.status) {
                    taskInfo.startTimestamp = task.status.startTimestamp;
                    taskInfo.finishTimestamp = task.status.finishedTimestamp;
                    taskInfo.status = this.transform_task_status(task.status.currentStatus, task.status.success);
                    taskInfo.errorMessage = task.status.errorMessage;
                    taskInfo.show = false;
                }

                return taskInfo;
            });
        }

        return oldTicketInfo;
    }

    getTicketDetail(ticketID: string, force: boolean = false): Observable<TicketInfo> {
        if (this.need_refresh(ticketID, force)) {
            return this.naSvc.getTicket({ ticketID: ticketID }, this.accountSvc.token).pipe(
                map((res: IAPIRx<ITicketData>) => {
                    if (res && res.error === 0 && res.data) {
                        this._ticketMap[res.data.ticketID] = this._ticketMap[res.data.ticketID] || { ticketInfo: new TicketInfo(), lastUpdateTime: 0 };
                        this.update_ticket_infos(this._ticketMap[res.data.ticketID].ticketInfo, res.data);
                        this._ticketMap[ticketID].lastUpdateTime = Date.now();
                    }

                    return this._ticketMap[res.data.ticketID].ticketInfo;
                })
            )
        }
        else {
            return observableOf(this._ticketMap[ticketID].ticketInfo);
        }
    }

    private need_refresh(ticketID: string, force: boolean = false): boolean {
        if (ticketID) {
            return force ||
                !this._ticketMap[ticketID] ||
                !this._ticketMap[ticketID].ticketInfo.startedDate ||
                new Date().getTime() - this._ticketMap[ticketID].lastUpdateTime > this.TICKET_REFRESH_PERIOD ? true : false;
        }
        else {
            return force || new Date().getTime() - this._lastUpdateTime > this.TICKET_REFRESH_PERIOD ? true : false;
        }
    }

    private transform_ticket_status(status: string, success: boolean = true): string {
        switch (status) {
            case 'finished':
                {
                    return success ? 'reported' : 'fail';
                }
            default:
                {
                    return status;
                }
        }
    }

    private transform_task_status(status: string, success: boolean = true): TaskStatus {
        switch (status) {
            case 'finish':
                {
                    return success ? TaskStatus.success : TaskStatus.fail;
                }
            case 'pending':
                {
                    return TaskStatus.pending;
                }
            default:
                {
                    return TaskStatus.progress;
                }
        }
    }
}