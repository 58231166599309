import { Component } from '@angular/core';
import { DialogPage } from '../../../../../lib/common/common.data';
import { UserGroupInfo } from '../../group/user-group.data';
import { UserBaseDlgComponent } from '../user-dlg-base.component';
import { USER_DLG_GROUP_FUNC_REMOVE_CONTENT } from '../user-dlg-func.def';

@Component({
    templateUrl: '../user-dlg-base.component.html',
    styleUrls: ['../../user.style.css']
})
export class UserGroupRemoveDlgComponent extends UserBaseDlgComponent<UserGroupInfo, void> {

    ngOnInit(): void {
        this._action_txt = 'Delete user group';
        this._action_icon = 'fa-trash';
        this._content_func_name = USER_DLG_GROUP_FUNC_REMOVE_CONTENT;

        this._isDataValid = true;
        this._page = DialogPage.action;

        super.ngOnInit();
    }

    submit(): void {
        this._page = DialogPage.submit;
        this._errorMessage = null;

        this.userSvc.removeUserGroup(this.data).subscribe((res: { group: UserGroupInfo, isFault: boolean, errorMessage?: string }) => {
            this._errorMessage = res.errorMessage;
            this._page = DialogPage.result;
        });
    }
}