import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'na-volume-preview',
    templateUrl: './volume-preview.component.html',
    styleUrls: ['./volume-preview.component.css']
})
export class VolumePreviewComponent implements OnInit {
    _displayVolume: number = 0;

    private _volume: number = 0;
    @Input()
    set volume(v: number) {
        this._volume = v;
    }

    private _max: number = 100;
    @Input('volumeMax')
    set max(m: number) {
        if (m) {
            this._max = m;
        }  
    }

    ngOnInit(): void {
        this._displayVolume = Math.round(this._volume * 100 / this._max);
    }
}