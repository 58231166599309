import { Injectable, Directive } from '@angular/core';
import { Observable, of as observableOf } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';

import { IClass } from '../../../lib/common/common.data';
import { HelperLib, REFRESH_DURATION } from '../../../lib/common/helper.lib';
import { NAService } from '../../../API/na.service';
import { AccountService } from '../../../entry/account.service';
import { IAPIRx } from '../../../API/api.base';
import { AccessKeyInfo } from '../../../API/v1/Account/api.accessKey.common';
import { ISigninAccessKeyRxData } from '../../../API/v1/Account/api.accessKey.signin';

@Directive()
@Injectable()
export class AccessKeyService implements IClass {
    className: string;
    private _accessKeyList: AccessKeyInfo[] = [];
    private _lastRefreshTime: number = 0;
    private _refreshing: boolean = false;

    private _appSettingList: { appName: string, templatePath?: string, setting?: string }[] = [];
    get appSettingList(): { appName: string, templatePath?: string, setting?: string }[] {
        return this._appSettingList;
    }

    constructor(private naSvc: NAService, private accountSvc: AccountService) {
        this.className = 'AccessKeySvc';
        this._appSettingList = [
            {
                appName: 'Planon',
                templatePath: 'assets/integration/Planon/setting.txt'
            }
        ];

        this.accountSvc.loginChanged.subscribe((isLogin: boolean) => {
            if (isLogin) {
                this._accessKeyList = [];
                this._lastRefreshTime = 0;
                this._refreshing = false;
            }
        });
    }

    getAccessKeyList(force: boolean = false): Observable<AccessKeyInfo[]> {
        return new Observable((observer) => {
            HelperLib.checkState(1, () => { return !this._refreshing }, () => {
                observer.next(true);
                observer.complete();
            });
        }).pipe(
            concatMap(() => {
                if (!this.needUpdate(force)) {
                    return observableOf(this._accessKeyList);
                }
                else {
                    this._refreshing = true;
                    return this.naSvc.listAccessKey(this.accountSvc.token).pipe(
                        map((res: IAPIRx<AccessKeyInfo[]>) => {
                            if (res.error === 0) {
                                this._accessKeyList = res.data || this._accessKeyList;
                                this._lastRefreshTime = new Date().getTime();
                            }

                            this._refreshing = false;
                            return this._accessKeyList;
                        })
                    )
                }
            })
        );
    }

    createAccessKey(): Observable<{ isFault: boolean, key: AccessKeyInfo, errorMessage?: string }> {
        return this.naSvc.createAccessKey(this.accountSvc.token).pipe(
            map((res: IAPIRx<AccessKeyInfo>) => {
                if (res.error === 0) {
                    this._accessKeyList.push(res.data);
                    this._lastRefreshTime = Date.now();
                }

                return {
                    isFault: res.error !== 0,
                    key: res.data,
                    errorMessage: res.errorMessage
                };
            })
        );
    }

    deleteAccessKey(accessKeyID: string): Observable<{ isFault: boolean, keyList: AccessKeyInfo[], errorMessage?: string }> {
        return this.naSvc.deleteAccessKey(accessKeyID, this.accountSvc.token).pipe(
            map((res: IAPIRx<void>) => {
                if (res.error === 0) {
                    const foundKey: AccessKeyInfo = this._accessKeyList.find(k => k.accessKeyID === accessKeyID);
                    if (foundKey) {
                        this._accessKeyList.splice(this._accessKeyList.indexOf(foundKey), 1);
                    }
                }

                return {
                    isFault: res.error !== 0,
                    keyList: this._accessKeyList,
                    errorMessage: res.errorMessage
                };
            })
        );
    }

    updateAccessKey(key: AccessKeyInfo, enabled: boolean, remark?: string): Observable<{ isFault: boolean, key: AccessKeyInfo, errorMessage?: string }> {
        return this.naSvc.updateAccessKey(key.accessKeyID, remark || key.accessKeyRemark, enabled, this.accountSvc.token).pipe(
            map((res: IAPIRx<AccessKeyInfo>) => {
                if (res.error === 0) {
                    key.isAccessKeyEnabled = res.data.isAccessKeyEnabled;
                    key.accessKeyRemark = res.data.accessKeyRemark;
                }

                return {
                    isFault: res.error !== 0,
                    key: key,
                    errorMessage: res.errorMessage
                };
            })
        )
    }

    loginAccessKey(accessKeyID: string, accessKeySecret: string): Observable<{ token: string, error?: string }> {
        return this.naSvc.loginAccessKey(accessKeyID, accessKeySecret).pipe(
            map((res: IAPIRx<ISigninAccessKeyRxData>) => {
                return res.error === 0 && res.data ? { token: res.data.token } : { token: null, error: res.errorMessage + ' (' + res.error + ')' };
            })
        )
    }

    private needUpdate(force: boolean = false): boolean {
        return force || !this._lastRefreshTime || (new Date().getTime() - this._lastRefreshTime) > (REFRESH_DURATION * 10000) ? true : false;
    }

}