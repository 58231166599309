import { Component, EventEmitter, Input, Output, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

import { IUIElement } from '../uiElement.interface';
import { AppManagementInfo, AppManagementSplitInfo } from './appmgr.data';

@Component({
    selector: 'na-app-management',
    templateUrl: './appmgr.component.html',
    styleUrls: ['../uiElement.style.css', './appmgr.style.css']
})
export class AppManagementComponent implements OnInit, IUIElement {
    readonly APP_ID_NONE: string = 'App-None';

    _unsupportReason: string;
    _disabled: boolean;
    _lockInfo: { isSync: boolean; policyID: string; policyName: string; };

    _appList: AppManagementInfo[] = [];
    _app: AppManagementInfo;
    _currentAppIndex: number = 0;

    _appLaunchList: AppManagementInfo[] = [];
    _appToLaunch: AppManagementInfo;

    _split: AppManagementSplitInfo;
    _isSplitNameConfirmed: boolean = false;

    @Input('data')
    set data(raw: AppManagementInfo[]) {
        this._appList = raw;
        if (this._appList) {
            this._app = this._appList[0];
            this._currentAppIndex = 0;
        }
    }

    private _appDetailForm: NgForm;
    @ViewChild('appDetailForm')
    set appDetailForm(f: NgForm) {
        this._appDetailForm = f;
        if (this._appDetailForm) {
            this._appDetailForm.statusChanges.pipe(
                debounceTime(200),
            ).subscribe((status: string) => {
                this.onValidChanged.emit(status === 'INVALID' || !this.validateApp() ? false : true);
            });
        }
    }

    @Output() onValidChanged = new EventEmitter<boolean>();
    @Output() updated = new EventEmitter<AppManagementInfo[]>();

    ngOnInit(): void {
        this._appLaunchList = [new AppManagementInfo({ id: this.APP_ID_NONE, name: 'None' })];

        // app assigned to be launched
        this._appLaunchList = this._appList?.map(a => a);
        const launchedApp: AppManagementInfo = this._appList.find(app => app.bindToAppstart);
        this._appToLaunch = launchedApp ? launchedApp : this._appLaunchList[0];
        this.onValidChanged.emit(this._appList.length > 0 ? true : false);

        this.updateSplit();
    }

    addApp(): void {
        const newApp: AppManagementInfo = new AppManagementInfo();
        this._appList.push(newApp);
        this._app = newApp;
        this._currentAppIndex = this._appList.indexOf(this._app);

        this._appLaunchList.push(newApp);

        this.updateSplit();
    }

    goPrevApp(): void {
        if (this._currentAppIndex > 0) {
            this._currentAppIndex--;
            this._app = this._appList[this._currentAppIndex];

            this.updateSplit();
        }
    }

    goNextApp(): void {
        if (this._currentAppIndex < this._appList.length - 1) {
            this._currentAppIndex++;
            this._app = this._appList[this._currentAppIndex];

            this.updateSplit();
        }
    }

    removeApp(): void {
        if (this._app) {
            const a = this._appLaunchList.find(a => a.id === this._app.id);
            if (a) {
                this._appLaunchList.splice(this._appLaunchList.indexOf(a), 1);
                if (this._appToLaunch && a.id === this._appToLaunch.id) {
                    this._appToLaunch = this._appLaunchList[0];
                }
            }

            this._appList.splice(this._appList.indexOf(this._app), 1);

            if (this._currentAppIndex >= this._appList.length) {
                this._currentAppIndex = this._appList.length - 1 >= 0 ? this._appList.length - 1 : 0;
            }
            this._app = this._appList[this._currentAppIndex];

            this.updateSplit();
        }

        this.validateApp();
    }

    changeExecuteByAppstart(appInfo: AppManagementInfo): void {
        this._appList.forEach(a => a.bindToAppstart = false);
        this._appToLaunch = appInfo;

        const app: AppManagementInfo = this._appList.find(app => app.id === appInfo.id);
        if (app) {
            app.bindToAppstart = true;
        }
    }

    changeAllowDowngrade(app: AppManagementInfo, allow: boolean): void {
        if (app.allowDowngrade !== allow) {
            app.allowDowngrade = allow;
        }
    }

    updateSplit(): void {
        // update selected split according to different app
        if (this._app) {
            this._split = this._app.splits?.[0];
            if (this._split) {
                // split should be confirmed on last setting
                this._isSplitNameConfirmed = true;
            }
        }
    }

    addSplit(): void {
        this._split = this._app.addSplit();
        this._isSplitNameConfirmed = false;
    }

    confirmSplitName(): void {
        this._isSplitNameConfirmed = true;
    }

    selectSplit(split: AppManagementSplitInfo): void {
        this._split = split;
    }

    removeSplit(split: AppManagementSplitInfo): void {
        split = split || this._split;
        this._app.removeSplit(split.id);
        this._split = this._app.splits[0];
        this._isSplitNameConfirmed = true;
    }

    private validateApp(): boolean {
        if (this._appList.length === 0) {
            return false;
        }

        for (let app of this._appList) {
            if (!app.name || !app.apkLink || !app.packageName) {
                return false;
            }

            if (app.splits) {
                for (let split of app.splits) {
                    if (!split.name || !split.link) {
                        return false;
                    }
                }

                if (this.isAppSplitDuplicate(app)) {
                    return false;
                }
            }
        }

        if (this.isAppNameDuplicate()) {
            return false;
        }

        return true;
    }

    isAppNameDuplicate(compared?: AppManagementInfo): boolean {
        if (!compared) {
            const appNameMap: { [name: string]: boolean } = {};
            for (let app of this._appList) {
                if (appNameMap[app.name]) {
                    return true;
                }
                appNameMap[app.name] = true;
            }

            return false;
        }

        if (!compared.name) {
            return false;
        }

        for (let app of this._appList) {
            if (app !== compared && app.name == compared.name) {
                return true;
            }
        }

        return false;
    }

    isAppSplitDuplicate(app: AppManagementInfo): boolean {
        if (!app || app.splits.length === 0) {
            return false;
        }

        const appSplitNameMap: { [name: string]: boolean } = {};
        for (let split of app.splits) {
            if (appSplitNameMap[split.name]) {
                return true;
            }
            appSplitNameMap[split.name] = true;
        }

        return false;
    }
}