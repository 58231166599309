import { Injectable } from '@angular/core';

import { IssueActionSubFormComponent } from '../../troubleshoot/action/issue-action-subform.component';
import { FirmwareActionSubFormComponent } from '../../firmware/action/firmware-action-subform.component';
import { BasicConfigActionSubFormComponent } from '../../config/action/basic-config-action-subform.component';
import { NetConfigActionSubFormComponent } from '../../config/action/net-config-action-subform.component';
import { AlertActionSubFormComponent } from '../../alert/action/alert-action-subform.component';
import { ShareActionSubFormComponent } from '../../share/action/share-action-subform.component';

import { DevFuncActionItem } from './dev-func-action-item';
import { 
  DIALOG_NAME_ISSUE_REPORT, 
  DIALOG_NAME_FIRMWARE_UPDATE, 
  DIALOG_NAME_BASIC_CONFIG, 
  DIALOG_NAME_NET_CONFIG, 
  DIALOG_NAME_ALERT,
  DIALOG_NAME_SHARE,
  DIALOG_NAME_MULTI_BASIC_CONFIG,
  DIALOG_NAME_SECURITY,
  DIALOG_NAME_OTP
} from '../base-config-data';
import { MultiBasicConfigActionSubFormComponent } from '../../config-m/action/m-basic-config-action-subform.component';
import { SecurityActionSubFormComponent } from '../../security/action/security-action-subform.component';
import { OTPActionSubFormComponent } from '../../otp/action/otp-action-subform.component';

@Injectable()
export class DevFuncActionService {
  private _items: DevFuncActionItem[] = [];

  constructor() {
    this._items = [
      new DevFuncActionItem(IssueActionSubFormComponent, DIALOG_NAME_ISSUE_REPORT),
      new DevFuncActionItem(FirmwareActionSubFormComponent, DIALOG_NAME_FIRMWARE_UPDATE),
      new DevFuncActionItem(BasicConfigActionSubFormComponent, DIALOG_NAME_BASIC_CONFIG),
      new DevFuncActionItem(MultiBasicConfigActionSubFormComponent, DIALOG_NAME_MULTI_BASIC_CONFIG),
      new DevFuncActionItem(NetConfigActionSubFormComponent, DIALOG_NAME_NET_CONFIG),
      new DevFuncActionItem(AlertActionSubFormComponent, DIALOG_NAME_ALERT),
      new DevFuncActionItem(ShareActionSubFormComponent, DIALOG_NAME_SHARE),
      new DevFuncActionItem(SecurityActionSubFormComponent, DIALOG_NAME_SECURITY),
      new DevFuncActionItem(OTPActionSubFormComponent, DIALOG_NAME_OTP)
    ];
  }

  getAllItems(): DevFuncActionItem[] {
    return this._items;
  }

  getItemByName(name: string): DevFuncActionItem {
    return this._items.find(i => i.name === name);
  }
}
