
import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { TroubleshootService } from './troubleshoot.service';
import { TicketInfo } from './troubleshoot-data';

import { fromEvent as observableFromEvent, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { ConstantService } from '../../lib/common/constant.service';

@Component({
    selector: 'troubleshoot',
    templateUrl: './troubleshoot.component.html',
    styleUrls: ['./troubleshoot.component.css']
})
export class TroubleshootComponent implements OnInit, OnDestroy {
    private _ticketList: TicketInfo[] = [];
    private _searchText: string;
    _selectTicket: TicketInfo;
    _loading: boolean = false;

    _currentPage: number = 1;
    _numberInPage: number = this.troubleSvc.TICKET_REQUEST_LIMIT;
    _total: number = 0;

    private _allSubscribe: Subject<void> = new Subject();

    private _searchEleRef: ElementRef;
    @ViewChild('search', { static: true })
    set search(holder: ElementRef) {
        if (!holder) {
            return;
        }

        this._searchEleRef = holder;
        const searchElement = this._searchEleRef.nativeElement;
        const searchInputOb = observableFromEvent(searchElement, 'input');

        searchInputOb.pipe(
            debounceTime(200),
            takeUntil(this._allSubscribe)
        ).subscribe((e: any) => {
            this._searchText = e.target.value.toString().toLocaleLowerCase();
        });
    }

    constructor(private troubleSvc: TroubleshootService, private constantSvc: ConstantService) { }

    ngOnInit(): void {
        this.updateTicketList();
    }

    ngOnDestroy(): void {
        this._allSubscribe.next();
        this._allSubscribe.complete();
    }

    onPageChange(page: number): void {
        this._currentPage = page;
        this.updateTicketList();
    }

    applySearch(): void {
        this.updateTicketList();
    }

    selectTicket(ticket: TicketInfo): void {
        this._selectTicket = ticket;
    }

    keyDown(keyCode: number): void {
        if (keyCode === this.constantSvc.KEYCODE_RETURN) {
            this.updateTicketList();
        }
    }

    private updateTicketList(): void {
        this._loading = true;
        this.troubleSvc.getTickets(this._currentPage, { subject: this._searchText })
            .subscribe((res: { isFault: boolean, tickets?: TicketInfo[], skip?: number, limit?: number, total?: number, errorMessage?: string }) => {
                if (!res.isFault) {
                    this._ticketList = res.tickets;
    
                    this._total = res.total;
                    //this.sort();
                }
                
                this._loading = false;
            });
    }

    private sort(): void {
        this._ticketList.sort((a: TicketInfo, b: TicketInfo) => {
            return a.issueDateTime <= b.issueDateTime ? 1 : -1;
        });
    }
}