import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, HostListener } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DeviceGroupInfo, DEVICE_GROUP_FUNC_EXCLUDE, DEVICE_GROUP_FUNC_MOVE, GroupSwitch } from './group/group.data';
import { DeviceGroupService } from './group/dev-group.service';
import { HelperLib } from '../../../app/lib/common/helper.lib';
import { DeviceGroupFuncItem, DeviceGroupFuncInterface } from './group/dlg/group-func.def';
import { DeviceGroupFuncService } from './group/dlg/group-func.service';
import { DeviceGroupFuncDirective } from './group/dlg/group-func.directive';
import { UserPreferenceService } from '../user-preference.service';

@Component({
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy {
  _loading: boolean = false;

  _groupRouteList: DeviceGroupInfo[] = [];
  _showGroupTree: boolean = true;
  _groupCtrlAllowHide: boolean = false;
  _groupSwitch: GroupSwitch = GroupSwitch.on;
  _enumGroupSwitch: typeof GroupSwitch = GroupSwitch;
  _selectedDeviceGroup: DeviceGroupInfo;

  private _allUnsubscribe: Subject<void> = new Subject();

  _selectedDeviceCount: number = 0;
  _selectedGroupCount: number = 0;

  @HostListener('window:resize', ['$event'])
  onresize(event) {
    if (HelperLib.isMobileLayout(event.target.innerWidth)) {
      this._showGroupTree = false;
      this._groupCtrlAllowHide = true;
    }
    else {
      this._showGroupTree = true;
      this._groupCtrlAllowHide = false;
    }
  }

  @ViewChild(DeviceGroupFuncDirective, { static: true }) groupFuncHost: DeviceGroupFuncDirective;

  constructor(
    private groupSvc: DeviceGroupService,
    private groupFuncSvc: DeviceGroupFuncService,
    private userPrefSvc: UserPreferenceService) {
  }

  ngOnInit(): void {
    this._groupSwitch = this.groupSvc.groupSwitch;

    this.groupSvc.onRouteChanged.pipe(
      takeUntil(this._allUnsubscribe)
    ).subscribe((routeList: DeviceGroupInfo[]) => {
      this._groupRouteList = routeList;
    });

    this.groupSvc.onGroupSwitchChanged.pipe(
      takeUntil(this._allUnsubscribe)
    ).subscribe((groupSwitch: GroupSwitch) => {
      this._groupSwitch = groupSwitch;
    });

    this._loading = true;
    HelperLib.checkState(1, () => { return this.groupSvc.isReady }, () => {
      this._groupRouteList = this.groupSvc.getHomeGroupRoute();
      this.updateSelectDeviceGroup(this.groupSvc.getActiveGroup());
      this._loading = false;
    });
  }

  ngAfterViewInit(): void {
    if (window.innerWidth < 992) {
      this._groupCtrlAllowHide = true;
      this._showGroupTree = false;
    }
  }

  ngOnDestroy(): void {
    this._allUnsubscribe.next();
    this._allUnsubscribe.complete();
  }

  inspectGroup(g: DeviceGroupInfo, expand: boolean = false): void {
    this.groupSvc.inspectGroup(null, g, expand);
    this.updateSelectDeviceGroup(g);
  }

  turnOnOffGroupView(): void {
    this._groupSwitch = this._groupSwitch === GroupSwitch.on ? GroupSwitch.off : GroupSwitch.on;
    this.groupSvc.turnOnOffGroup(this._groupSwitch);
    this.userPrefSvc.changeHomeLayout(this._groupSwitch === GroupSwitch.on);
  }

  changeDeviceGroup(): void {
    this.playGroupFunc(DEVICE_GROUP_FUNC_MOVE);
  }

  removeDeviceFromGroup(): void {
    this.playGroupFunc(DEVICE_GROUP_FUNC_EXCLUDE);
  }

  onDeviceGroupInspect(g: DeviceGroupInfo): void {
    this.updateSelectDeviceGroup(g);
  }

  private updateSelectDeviceGroup(g: DeviceGroupInfo): void {
    this._selectedDeviceGroup = g.id === this.groupSvc.getHomeGroup().id ? null : g;
  }

  onItemSelectionChange(e: { group: number, device: number }): void {
    this._selectedDeviceCount = e.device;
    this._selectedGroupCount = e.group;
  }

  onDeviceGroupAccountChanged(accountName: string): void {
    this.userPrefSvc.changeHomeDefaultGroupAccount(accountName);
  }

  playGroupFunc(funcName: string, g?: DeviceGroupInfo): void {
    const item: DeviceGroupFuncItem = this.groupFuncSvc.getFunctionByName(funcName);
    if (item) {
      const viewContainerRef = this.groupFuncHost.viewContainerRef;
      viewContainerRef.clear();

      const componentRef = viewContainerRef.createComponent(item.component);

      (<DeviceGroupFuncInterface>componentRef.instance).title = item.title;
      (<DeviceGroupFuncInterface>componentRef.instance).group = g || this.groupSvc.getActiveGroup(null);
    }
  }
}