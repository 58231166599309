export const DIALOG_NAME_ISSUE_REPORT: string = 'dialog-issue-report';
export const DIALOG_NAME_REBOOT: string = 'dialog-reboot';
export const DIALOG_NAME_FIRMWARE_UPDATE: string = 'dialog-firmware-update';
export const DIALOG_NAME_APK_UPDATE: string = 'dialog-apk-update';
export const DIALOG_NAME_BASIC_CONFIG: string = 'dialog-basic-config';
export const DIALOG_NAME_MULTI_BASIC_CONFIG: string = 'dialog-multi-basic-config';
export const DIALOG_NAME_NET_CONFIG: string = 'dialog-net-config';
export const DIALOG_NAME_ALERT: string = 'dialog-alert';
export const DIALOG_NAME_SHARE: string = 'dialog-share';
export const DIALOG_NAME_SECURITY: string = 'dialog-security';
export const DIALOG_NAME_CLEARCACHE: string = 'dialog-clearCache';
export const DIALOG_NAME_OTP: string = 'dialog-otp';

export enum ConfigDialogPage {
    checking = 1,
    action,
    confirm,
    submit,
    result
}