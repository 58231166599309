import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { Router, Event, NavigationEnd } from '@angular/router';
import { Subject, fromEvent, interval, timer, of } from 'rxjs';
import { concatMap, debounceTime, map, switchMap, takeUntil } from 'rxjs/operators';

import { TranslateService, LanguageItem } from '../translate/translate.service';
import { AccountService } from '../entry/account.service';
import { AppConfigService } from '../app.config';
import { LOCALSTORAGE_KEY_TOKEN, PermissionCode } from '../lib/common/common.data';
import { ThemeService } from '../theme/theme.service';
import { ITheme } from '../theme/theme';
import { IMenu, TopMenuService } from './menu.service';
import { AppService } from '../app.service';
import { NAService } from '../../app/API/na.service';
import { IAPIRx } from '../../app/API/api.base';
import { HelperLib } from '../../app/lib/common/helper.lib';
import { Logger } from '../../app/lib/common/logger';

@Component({
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.css']
})
export class ContentComponent implements OnInit, OnDestroy {
  readonly Enable_Theme: boolean = AppConfigService.configs.common.theme.enabled;

  _title: string;
  _loginAccount: string = '';
  _currentLanguage: LanguageItem;

  _enumPermissionCode: typeof PermissionCode = PermissionCode;
  _themeList: ITheme[] = [];
  _theme: ITheme;
  _isRemoteCtrlMode: boolean = false;
  _isAdminMenuExpand: boolean = false;
  _showAccountPwdDialog: boolean = false;
  _idleStatus: { forceLogout: boolean, errorMessage?: string } = { forceLogout: false };

  private readonly _idleDlg$: Subject<void> = new Subject();
  private readonly _unsubscribe$: Subject<void> = new Subject();;

  get isEnterpriseSSO(): boolean {
    return this.accountSvc.isEnterpriseSSO();
  }

  private _btnIdleRef: ElementRef;
  @ViewChild('btnIdle', { static: true })
  set btnTokenHintLaunch(holder: ElementRef) {
    if (holder) {
      this._btnIdleRef = holder;
    }
  }

  private _btnIdleCloseRef: ElementRef;
  @ViewChild('btnIdleClose', { static: true })
  set btnTokenHintClose(holder: ElementRef) {
    if (holder) {
      this._btnIdleCloseRef = holder;
    }
  }

  private _iadeaCareBodyRef: ElementRef;
  @ViewChild('iadeaCareBody', { static: true })
  set iadeaCareBody(holder: ElementRef) {
    if (holder) {
      this._iadeaCareBodyRef = holder;
      const mouseDown = fromEvent(this._iadeaCareBodyRef.nativeElement, 'mousedown');
      mouseDown.pipe(
        debounceTime(3000),
        takeUntil(this._unsubscribe$)
      ).subscribe(() => {
        this._idleDlg$.next();
      })
    }
  }

  constructor(
    private location: Location,
    private router: Router,
    private naSvc: NAService,
    private translateSvc: TranslateService,
    private accountSvc: AccountService,
    public menuSvc: TopMenuService,
    public appSvc: AppService,
    private themeSvc: ThemeService) {
  }

  ngOnInit(): void {
    //language
    this.translateSvc.enableFallback(true);
    this._currentLanguage = this.translateSvc.getLanguage();
    this._loginAccount = this.accountSvc.accountName;
    this.menuSvc.onAdminMenuExpandChanged.pipe(
      takeUntil(this._unsubscribe$)
    ).subscribe((res: { menu: IMenu, expand: boolean }) => {
      this._isAdminMenuExpand = res.expand;
    });

    this.router.events.pipe(
      takeUntil(this._unsubscribe$)
    ).subscribe((routeEvent: Event) => {
      if (routeEvent instanceof NavigationEnd) {
        this.updatePageItems();
      }
    });

    //repeatly check the token (and refresh token if required)
    timer(300000 + Math.random() * 10000, AppConfigService.configs.trigger.tokenRefreshPeriod).pipe(
      concatMap(() => {
        const lastToken: string = HelperLib.getLocalStorageRecord(LOCALSTORAGE_KEY_TOKEN);
        return lastToken ? this.naSvc.validateAccountToken(lastToken) : of(false);
      }),
      takeUntil(this._unsubscribe$)
    ).subscribe(() => {
    });

    //detect idle & pop up dialog to remind user.
    this._idleDlg$.pipe(
      switchMap(() => {
        return interval(AppConfigService.configs.trigger.idleNoticePeriod).pipe(
          concatMap(() => {
            //validate token first, if token is unavailable now, do not need to notice user the long idle time.
            //otherwise, should notice user to logout directly.
            Logger.logInfo('Content', 'idle', 'Idle dlg jump');
            this._idleStatus.forceLogout = false;
            this._idleStatus.errorMessage = null;

            return this.naSvc.validateAccountToken(this.accountSvc.token).pipe(
              concatMap((res: IAPIRx<string>) => {
                if (res.error !== 0) {
                  this._idleStatus.forceLogout = true;
                  this._idleStatus.errorMessage = HelperLib.getErrorMessage(res);
                }

                if (!res.data) {
                  this._idleStatus.forceLogout = true;
                  this._idleStatus.errorMessage = 'Could not get valid token';
                }

                this._btnIdleRef.nativeElement.click();

                if (!this._idleStatus.errorMessage) {
                  Logger.logInfo('Content', 'idle', 'Idle 2 min more');
                  return timer(120000).pipe(
                    map(() => {
                      this._btnIdleCloseRef.nativeElement.click();
                      setTimeout(() => {
                        this.accountSvc.logout(true);
                      }, 0);
                    })
                  );
                }
              })
            );
          })
        )
      }),
      takeUntil(this._unsubscribe$)
    ).subscribe();

    this._idleDlg$.next();

    this._themeList = this.themeSvc.getThemeList();
    this._theme = this.themeSvc.getCurrentTheme();

    this.updatePageItems();
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  changeTheme(theme: ITheme): void {
    this._theme = this.themeSvc.setActiveTheme(theme);
  }

  private updatePageItems(): void {
    const route: string = this.location.path();
    const activeRoutes: IMenu[] = this.menuSvc.updateActiveMenuRoute(route);
    this._title = activeRoutes && activeRoutes.length > 0 ? activeRoutes[activeRoutes.length - 1].title || activeRoutes[activeRoutes.length - 1].name : null;
    //handle special cases: no need title under these routes
    if (['/device/devices/', '/device/remotectrl', 'event/activity/', 'admin/users/all/', 'admin/scep/'].find(url => route.indexOf(url) > 0)) {
      this._title = '';
    }

    if (route.indexOf('/device/remotectrl/') > 0) {
      this._isRemoteCtrlMode = true;
    }
  }

  changePassword(): void {
    this._showAccountPwdDialog = true;
  }

  onAccountPwdDialogClosed(): void {
    this._showAccountPwdDialog = false;
  }

  logout(): void {
    this._btnIdleCloseRef.nativeElement.click();
    this.accountSvc.logout();
  }

  keepUse(): void {
    this._idleDlg$.next();
    this._btnIdleCloseRef.nativeElement.click();
  }
}
