<div>
  <div *ngIf="_devices.length === 1; else templateMultipleDevices">
    <div *ngIf="_actionData.hasLicense">
      Add collaborators and set their authorities for {{ _actionData.deviceNames }}
    </div>
    <div *ngIf="!_actionData.hasLicense">
      The device doesn't have valid license to add / modify share setting. You can only revoke current settings.
    </div>
    <na-share [data]="_actionData.commonShareInfoMap" [hasLicense]="_actionData.hasLicense"
      (onShareSettingChanged)="onShareSettingChanged($event)">
    </na-share>
  </div>
  <ng-template #templateMultipleDevices>
    <div>
      <div>
        Add collaborators and set their authorities on all selected devices as common setting.
      </div>
      <na-share [data]="_actionData.commonShareInfoMap" [hasLicense]="_actionData.hasLicense"
        (onShareSettingChanged)="onShareSettingChanged($event)">
      </na-share>
    </div>
    <div class="mt-2">Check remaining seperate settings by device. (View only)</div>
    <div class="row">
      <div class="col-md-4 col-12">
        <div class="list-group">
          <div class="list-group-item list-group-header black">
            <div class="row">
              <div class="col title">
                Device name
              </div>
            </div>
          </div>
          <div class="list-group-content list-group-content-modal-nr-1">
            <div *ngFor="let device of _devices" class="list-group-item list-group-item-action"
              [class.active]="_individualShareSettingDevice?.virtualId === device.virtualId"
              (click)="viewShareSettingByDevice(device)">
              <div class="row align-items-center">
                <div class="col text-break">
                  <span>{{ device?.virtualName }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="_individualShareSettingList.length > 0" class="col-md-8 pl-md-0 col-12 single-device-permission">
        <div class="list-group">
          <div class="list-group-item list-group-header black">
            <div class="row">
              <div class="col-4 title">
                Account
              </div>
              <div class="col=8 title">
                Permission
              </div>
            </div>
          </div>
          <div class="list-group-content list-group-hoverless">
            <div *ngFor="let s of _individualShareSettingList" class="list-group-item list-group-item-action">
              <div class="row align-items-center">
                <div class="col-4 text-break">
                  <span>{{ s.accountName }}</span>
                </div>
                <div class="col-8 text-break">
                  <span *ngFor="let p of s.permission" data-target="tooltip" title="{{ p }}" class="permission">
                    {{ p }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>