<div *ngIf="validating(); else templateValidateSitePass" class="app-loading">
    <div class="d-flex justify-content-center pt-3">
        <img [src]="appSvc.logoDarkUrl | safe: 'url'" style="width: 40%">
    </div>
    <div class="d-flex justify-content-center pt-2 loading-text">
        <span>Loading</span>
        <span class="dot">.</span>
        <span class="dot dot-2">.</span>
        <span class="dot dot-3">.</span>
    </div>
</div>
<ng-template #templateValidateSitePass>
    <div class="d-relative">
        <div *ngIf="_appMode === _enumAppMode.preview" class="preview-layer">
            <div class="preview-center">
                <div>{{ _appMode | capitalFirstWord }} mode</div>
                <div class="ctrls">
                    <i class="fas fa-angle-left" data-toggle="tooltip" title="Prev" [class.disabled]="_demoIndex === 0" (click)="goDemoBack()"></i>
                    <i class="fas fa-times" data-toggle="tooltip" title="Close" (click)="resetResMode()"></i>
                    <i class="fas fa-angle-right" data-toggle="tooltip" title="Next" [class.disabled]="_demoIndex === _demoRouteList.length - 1" (click)="goDemoNext()"></i>
                </div>
            </div>
        </div>
        <router-outlet></router-outlet>
    </div>
</ng-template>

<svg xmlns="http://www.w3.org/2000/svg" style="display: none">
    <symbol id="checkmark" viewBox="0 0 22 22">
        <path stroke-linecap="round" stroke-miterlimit="10" fill="none" d="M22.9 3.7l-15.2 16.6-6.6-7.1">
        </path>
    </symbol>
</svg>