import { Component, ViewChild, ElementRef } from '@angular/core';
import { LOGIN_SLOGAN } from '../login.data';
import { AppConfigService } from '../../../../app/app.config';
import { AppService } from '../../../app.service';

declare var grecaptcha;

@Component({
    templateUrl: './login-accenture.component.html',
    styleUrls: ['./login-accenture.component.css'],
})
export class LoginAccentureComponent {
    _slogan: string;

    _errorMessage: string = '';
    readonly _appVersion: string = AppConfigService.configs.common.appVersion;
    readonly _releaseDate: string = AppConfigService.configs.common.releaseDate;
    readonly _enableRecaptcha: boolean = AppConfigService.configs.common.reCaptcha.enabled;
    
    _msAuthUrl: string;

    _bReCaptchaReady: boolean = false;
    private _msLoginLink: ElementRef;
    @ViewChild('msLoginLink', { static: true })
    set msLoginLink(holder: ElementRef) {
      if (holder) {
        this._msLoginLink = holder;
      }
    }

    constructor(public appSvc: AppService) {
        grecaptcha.ready(() => {
            this._bReCaptchaReady = true;
        });

        this._slogan = LOGIN_SLOGAN;
        try {
            const method = AppConfigService.configs.setting.login.methods.find(m => m.name === 'ms');
            if (method) {
                this._msAuthUrl = method.authUrl;
            }
        }
        catch {

        }
    }

    loginICare(): void {
        if (this._msLoginLink) {
            this._msLoginLink.nativeElement.click();
        }
    }
}