import { Component, OnDestroy, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ConstantService } from '../../../../../app/lib/common/constant.service';
import { DevFuncActionInterface } from '../../base/action/dev-func-action.interface';

@Component({
    templateUrl: './security-action-subform.component.html',
    styleUrls: ['../../base/base-config-form.component.css', './security-action-subform.component.css', '../../dev-func.style.css']
})
export class SecurityActionSubFormComponent implements DevFuncActionInterface, OnDestroy {
    readonly PWD_MIN_LEN: number = 4;

    actionCompleteHandler?: (result: any) => void;
    actionStatusUpdateHandler?: (isValid: boolean) => void;
    actionCommunicateHandler?: (data: any) => void;

    _actionData: any;
    set actionData(data: any) {
        this._actionData = data;
        if (this._actionData) {
            this._actionData.subject = this._actionData.subject || 'Security password';
            this._lockInfo = this._actionData.lockMap?.[this.constantSvc.DEVKEY_FAKE_DEVICE_PASSWORD];
        }
    }

    _showPwd: boolean = false;
    _lockInfo: { isSync: boolean, policyID: string, policyName: string };

    private _allUnsubscribe: Subject<void> = new Subject();
    private _securityForm: NgForm;
    @ViewChild('securityForm', { static: true })
    set issueForm(f: NgForm) {
        this._securityForm = f;
        if (this._securityForm) {
            this._securityForm.statusChanges.pipe(
                takeUntil(this._allUnsubscribe)
            ).subscribe((status: string) => {
                this.actionStatusUpdateHandler(status === "VALID" && !this._lockInfo ? true : false);
            });
        }
    }

    constructor(private constantSvc: ConstantService) { }

    ngOnDestroy(): void {
        this._allUnsubscribe.next();
        this._allUnsubscribe.complete();
    }

    showPwd(show: boolean): void {
        this._showPwd = show;
    }
}