<div>
    <div class="d-flex">
        <div *ngIf="supportAddLicense()" class="layout-desktop mr-2">
            <button id="id_license_do_add" type="button"
                class="btn btn-sm btn-action btn-light d-flex align-items-center" data-toggle="modal"
                data-target='#licenseFuncModalHost' [disabled]="_isLoading || _exporting"
                (click)="playDevFunc(FUNCNAME_LICENSE_ADD)">
                <i class="fas fa-plus"></i>
                <span class="ml-2">Add license</span>
            </button>
        </div>
        <div *ngIf="supportAddLicense()" class="layout-desktop mr-2">
            <button id="id_license_do_import" type="button"
                class="btn btn-sm btn-action btn-light d-flex align-items-center" data-toggle="modal"
                data-target="#licenseFuncModalHost" [disabled]="_isLoading || _exporting"
                (click)="playDevFunc(FUNCNAME_LICENSE_IMPORT)">
                <i class="fas fa-file-import"></i>
                <span class="ml-2">Import license</span>
            </button>
        </div>
        <div *ngIf="supportReallocateLicense()" class="layout-desktop mr-2">
            <button id="id_license_do_reallocate" type="button"
                class="btn btn-sm btn-action btn-light d-flex align-items-center" data-toggle="modal"
                data-target='#licenseFuncModalHost' [disabled]="_isLoading || _exporting"
                (click)="playDevFunc(FUNCNAME_LICENSE_REALLOCATE)">
                <i class="icon fas fa-exchange-alt"></i>
                <span class="ml-2">Reallocate license</span>
            </button>
        </div>
        <div class="btn-group mr-2">
            <button id="id_license_do_filter" type="button" class="btn btn-sm btn-action btn-light dropdown-toggle"
                data-toggle="dropdown" [disabled]="_isLoading || _exporting"
                (click)="_licenseExpiryTypeFilter.startFilter(); _licenseExpiryTimeFilter.startFilter()">
                Advanced filter
                <strong *ngIf="_licenseExpiryTypeFilter.filterMessage && _licenseExpiryTimeFilter.filterMessage"
                    class="red-1">*</strong>
            </button>
            <div #dropdownMenuExpiry class="dropdown-menu dropdown-menu-left dropdown-menu-expiry"
                aria-labelledby="id_license_do_filter">
                <div class="container-fluid" (click)="$event.stopPropagation()">
                    <div class="py-1">
                        <strong>Type :</strong>
                    </div>
                    <div>
                        <div *ngFor="let licenseType of _licenseExpiryTypeFilter.tmpList"
                            class="d-flex align-items-center mb-1">
                            <input type="checkbox" [id]="'cb-filter-type-' + licenseType.name" class="cmn-checkbox"
                                [checked]="licenseType.checked"
                                (change)="_licenseExpiryTypeFilter.changeType(licenseType, $event.target.checked)">
                            <label [for]="'cb-filter-type-' + licenseType.name">
                                <svg>
                                    <use xlink:href="#checkmark" />
                                </svg>
                                <span class="sr-only">Select type {{ licenseType.name }}</span>
                            </label>
                            <span class="ml-2">{{ licenseType.name | capitalFirstWord }}</span>
                        </div>
                    </div>
                    <div class="py-1">
                        <strong>Expiry time range :</strong>
                    </div>
                    <div class="row">
                        <div class="col-md-6 col-12 pr-md-1">
                            <div>
                                <strong>
                                    From :
                                    <span class="red-1">
                                        <span
                                            *ngIf="_licenseExpiryTimeFilter.tmpData.beginDate; else templateNoFilterBeginDate">
                                            {{ _licenseExpiryTimeFilter.tmpData.beginDate }}
                                        </span>
                                        <ng-template #templateNoFilterBeginDate>
                                            xxxx-xx-xx
                                        </ng-template>
                                    </span>
                                </strong>
                                <div>
                                    <input type="date" class="form-control form-control-sm"
                                        aria-label="Select license expiry filter start date"
                                        [value]="_licenseExpiryTimeFilter.tmpData.beginDate"
                                        (change)="_licenseExpiryTimeFilter.changeBeginDate($event.target.value)">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-12 pl-md-1">
                            <div>
                                <strong>
                                    To :
                                    <span class="red-1">
                                        <span
                                            *ngIf="_licenseExpiryTimeFilter.tmpData.beginDate; else templateNoFilterEndDate">
                                            {{ _licenseExpiryTimeFilter.tmpData.endDate }}
                                        </span>
                                        <ng-template #templateNoFilterEndDate>
                                            xxxx-xx-xx
                                        </ng-template>
                                    </span>
                                </strong>
                                <div>
                                    <input type="date" class="form-control form-control-sm"
                                        aria-label="Select license expiry filter end date"
                                        [value]="_licenseExpiryTimeFilter.tmpData.endDate"
                                        (change)="_licenseExpiryTimeFilter.changeEndDate($event.target.value)">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="_licenseExpiryTimeFilter.errorMessage" class="mt-2">
                        <div class="warning-block">{{ _licenseExpiryTimeFilter.errorMessage }}</div>
                    </div>
                    <div class="my-2 text-right">
                        <button type="button" class="btn btn-sm btn-action btn-light"
                            (click)="clearFilterSearch()">Clear</button>
                        <button type="button" class="btn btn-sm btn-action btn-light ml-2" data-dismiss="modal"
                            [disabled]="_licenseExpiryTimeFilter.errorMessage"
                            (click)="dropdownMenuExpiry.classList.remove('show'); applyFilterSearch()">
                            Apply
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <button id="id_license_do_export" type="button" class="btn btn-sm btn-action btn-light ml-auto"
            [disabled]="_exporting" (click)="export()">
            <i class="fas fa-download mr-1"></i>
            Export
            <i *ngIf="_exporting" class="ml-2 fas fa-spinner rotate"></i>
        </button>
        <a #exportLink class="d-none"></a>
    </div>
    <div class="d-flex mt-2">
        <div class="input-group input-group-sm">
            <div class="input-group-prepend">
                <span class="input-group-text" id="search-by-pName">
                    <i class="fas fa-search"></i>
                </span>
            </div>
            <input #search class="form-control" type='text' name='search' [disabled]="_isLoading || _exporting"
                placeholder='Search by device name ...' aria-label="Search by device name"
                aria-describedby="search-by-pName">
        </div>
        <button id="id_license_do_refreshAll" type="button" class="btn btn-sm btn-action btn-light ml-2"
            [disabled]="_isLoading || _exporting || _licenseRefreshCounter > 0" (click)="refreshLicenses()">
            <span [class.rotate]="_licenseRefreshCounter > 0" class="fas fa-redo-alt"></span>
            <span class="sr-only">Refresh license</span>
            <span *ngIf="_licenseRefreshCounter > 0" class="d-inline-block">
                &nbsp;- {{ _licenseRefreshCounter }} s
            </span>
        </button>
        <div class="dropdown-wrapper btn-group ml-2">
            <button id="dropdownLicensePageAmountLink" type="button"
                class="btn btn-sm btn-action btn-light dropdown-toggle" data-toggle="dropdown"
                [disabled]="_isLoading || _exporting">
                {{ _numberInPage }}
                <span class="caret ml-2"></span>
            </button>
            <ul class="dropdown-menu dropdown-menu-right option-list" aria-labelledby="dropdownLicensePageAmountLink">
                <li *ngFor="let numberInPage of NUMBER_IN_PAGE_OPTIONS"
                    [class.selected]="numberInPage === _numberInPage" (click)="changeNumberInPage(numberInPage)">
                    <div>
                        {{ numberInPage }}
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <div class="layout-mobile scroll scroll-md mt-2">
        <div *ngFor="let l of _displayDevLicenseList; let recordInex = index" class="card card-list">
            <div class="card-header d-flex align-items-center white">
                <input [id]="'cb-td-mobile-' + recordInex" type="checkbox" class="cmn-checkbox"
                    [checked]="_devSelectionList[l.device.virtualId]" (change)="selectPlayer(l.device.virtualId)" />
                <label [for]="'cb-td-mobile-' + recordInex">
                    <svg>
                        <use xlink:href="#checkmark" />
                    </svg>
                    <span class="sr-only">Select device {{l.device.virtualName}}</span>
                </label>
                <a [routerLink]="['/app/device', l.device.virtualId]" class="white ml-2">
                    {{ l.device.virtualName }}
                </a>
                <span class="ml-2">
                    <span *ngIf="!l.device.isPaired; else templatePaired">(Unpaired)</span>
                    <ng-template #templatePaired>
                        <span
                            *ngIf="l.device.currentSettings[constantSvc.DEVKEY_NET_WIFI_CONNECTED] && !l.device.currentSettings[constantSvc.DEVKEY_NET_LAN_CONNECTED]; else templateMacNoWifi">
                            ({{ l.device.currentSettings[constantSvc.DEVKEY_NET_WIFI_MAC] }})
                            <span class="badge badge-info">
                                <i class="fas fa-wifi"></i>
                            </span>
                        </span>
                        <ng-template #templateMacNoWifi>
                            ({{ l.device.currentSettings[constantSvc.DEVKEY_NET_LAN_MAC] }})
                        </ng-template>
                    </ng-template>
                </span>
            </div>
            <div class="card-body py-0">
                <div class="form-group row">
                    <span class="col-4">Warranty</span>
                    <div class="col-8 pl-0">
                        {{ l.device.currentSettings[constantSvc.DEVKEY_INFO_WARRANTY_ENDDATE] | date:'yyyy-MM-dd' }}
                    </div>
                </div>
                <div class="form-group row">
                    <span class="col-4">IAdeaCare license</span>
                    <div class="col-8 pl-0">
                        <div *ngIf="l.iCareLicense; else templateNoLicense">
                            <div *ngIf="l.iCareLicense.currentActivated && l.iCareLicense.currentActivated.length > 0">
                                Type: {{ l.iCareLicense.currentActivated | mergeContentPipe }}
                            </div>
                            <div>
                                Expiration date: {{ l.iCareLicense?.expiryDate | date:'yyyy-MM-dd' }}
                                <span *ngIf="l.iCareLicense?.isExpired"
                                    class="license-note license-note-warn">Expired</span>
                            </div>
                        </div>
                        <ng-template #templateNoLicense>
                            <span class="license-note license-note-warn">No license</span>
                        </ng-template>
                    </div>
                </div>
                <div class="form-group row">
                    <span class="col-4">miscellaneous</span>
                    <div class="col-8 pl-0">
                        <div *ngIf="l.cloudLicense">
                            <div>
                                Type: Cloud update
                                <i class="glyphicon glyphicon-cloud clue-icon blue-1 ml-1"></i>
                            </div>
                            <div>
                                Expiration date: {{ l.cloudLicense.expiryDate | date:'yyyy-MM-dd' }}
                                <span *ngIf="l.cloudLicense?.isExpired"
                                    class="license-note license-note-warn ml-2">Expired</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="list-group layout-desktop mt-2">
        <div class="list-group-item list-group-header">
            <div class="row">
                <div *ngFor="let headerItem of _tableHeaderHandler.list; let firstRecord = first"
                    class="d-flex col title">
                    <ng-template [ngIf]="firstRecord">
                        <input type="checkbox" id="cb-th" class="cmn-checkbox" [checked]="_bSelectAll"
                            (change)="selectAllPlayer()">
                        <label for="cb-th">
                            <svg>
                                <use xlink:href="#checkmark" />
                            </svg>
                            <span class="sr-only">Select all devices</span>
                        </label>
                    </ng-template>
                    <span class="ml-2">{{ headerItem.displayName | capitalFirstWord }}</span>
                    <span *ngIf="headerItem.sorter" class="sorter">
                        <span class="fas fa-caret-up sort-icon descend"
                            [class.active]="headerItem.sorter.currentSortType === _enumSortType.descend"
                            (click)="_tableHeaderHandler.sort(headerItem, _enumSortType.descend)"></span>
                        <span class="fas fa-caret-down sort-icon ascend"
                            [class.active]="headerItem.sorter.currentSortType === _enumSortType.ascend"
                            (click)="_tableHeaderHandler.sort(headerItem, _enumSortType.ascend)"></span>
                    </span>
                </div>
            </div>
        </div>
        <div class="list-group-content list-group-hoverless list-group-content-nr-3">
            <div *ngFor="let devLicenseInfo of _displayDevLicenseList; let recordIndex = index" class="list-group-item">
                <div class="row align-items-center">
                    <div class="col d-flex align-items-center text-break">
                        <input [id]="'cb-td-l-' + recordIndex" type="checkbox" class="cmn-checkbox"
                            [checked]="_devSelectionList[devLicenseInfo.device.virtualId]"
                            (change)="selectPlayer(devLicenseInfo.device.virtualId)" />
                        <label [for]="'cb-td-l-' + recordIndex">
                            <svg>
                                <use xlink:href="#checkmark" />
                            </svg>
                            <span class="sr-only">Select device {{devLicenseInfo.device.virtualName}}</span>
                        </label>
                        <a [routerLink]="['/app/device/devices', devLicenseInfo.device.virtualId]" data-toggle="tooltip"
                            [title]="devLicenseInfo.device.virtualId" class="ml-2">
                            {{ devLicenseInfo.device.virtualName }}
                        </a>
                    </div>
                    <div class="col">
                        <span *ngIf="!devLicenseInfo.device.isPaired; else templatePaired"
                            class="red-1">(Unpaired)</span>
                        <ng-template #templatePaired>
                            <span
                                *ngIf="devLicenseInfo.device.currentSettings[constantSvc.DEVKEY_NET_WIFI_CONNECTED] && !devLicenseInfo.device.currentSettings[constantSvc.DEVKEY_NET_LAN_CONNECTED]; else templateMacNoWifi">
                                {{ devLicenseInfo.device.currentSettings[constantSvc.DEVKEY_NET_WIFI_MAC] }}
                                <span class="badge badge-info">
                                    <i class="fas fa-wifi"></i>
                                </span>
                            </span>
                            <ng-template #templateMacNoWifi>
                                {{ devLicenseInfo.device.currentSettings[constantSvc.DEVKEY_NET_LAN_MAC] }}
                            </ng-template>
                        </ng-template>
                    </div>
                    <div class="col">
                        {{ devLicenseInfo.device.currentSettings[constantSvc.DEVKEY_INFO_WARRANTY_ENDDATE] |
                        date:'yyyy-MM-dd' }}
                    </div>
                    <div class="col">
                        <div *ngIf="devLicenseInfo.iCareLicense; else templateNoLicense">
                            <div
                                *ngIf="devLicenseInfo.iCareLicense.currentActivated && devLicenseInfo.iCareLicense.currentActivated.length > 0">
                                Type: {{ devLicenseInfo.iCareLicense.currentActivated | mergeContentPipe }}
                            </div>
                            <div>
                                Expiration date: {{ devLicenseInfo.iCareLicense?.expiryDate | date:'yyyy-MM-dd' }}
                                <span *ngIf="devLicenseInfo.iCareLicense?.isExpired"
                                    class="license-note license-note-warn">Expired</span>
                            </div>
                        </div>
                        <ng-template #templateNoLicense>
                            <span class="license-note license-note-warn">No license</span>
                        </ng-template>
                    </div>
                    <div class="col">
                        <div *ngIf="devLicenseInfo.cloudLicense">
                            <div>
                                Type: Cloud update
                                <i class="glyphicon glyphicon-cloud clue-icon blue-1 ml-1"></i>
                            </div>
                            <div>
                                Expiration date: {{ devLicenseInfo.cloudLicense.expiryDate | date:'yyyy-MM-dd' }}
                                <span *ngIf="devLicenseInfo.cloudLicense?.isExpired"
                                    class="license-note license-note-warn ml-2">Expired</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="loading" *ngIf="_isLoading">
        <p>Please wait...</p>
        <div class="loading-box"></div>
    </div>
    <div class="mt-1 float-right">
        <na-pager [currentPage]="_currentPage" [numberInPage]="_numberInPage" [total]="_licenseAmount"
            (onPageChanged)="onPageChange($event)">
        </na-pager>
    </div>
    <div class="layout-mobile">
        <div class="control-block-bottom fixed-bottom">
            <div *ngIf="supportAddLicense()" class="control-item" [ngClass]="_isLoading || _exporting ? 'disabled' : ''"
                data-toggle="modal" data-target="#licenseFuncModalHost"
                (click)="_isLoading || _exporting ? $event.stopPropagation() : playDevFunc(FUNCNAME_LICENSE_ADD)">
                <div class="icon fas fa-plus"></div>
                <span>Add</span>
            </div>
            <div *ngIf="supportAddLicense()" class="control-item" [ngClass]="_isLoading || _exporting ? 'disabled' : ''"
                data-toggle="modal" data-target="#licenseFuncModalHost"
                (click)="_isLoading || _exporting ? $event.stopPropagation() : playDevFunc(FUNCNAME_LICENSE_IMPORT)">
                <div class="icon fas fa-file-import"></div>
                <span>Import</span>
            </div>
            <div *ngIf="supportReallocateLicense()" class="control-item"
                [ngClass]="_isLoading || _exporting ? 'disabled' : ''" data-toggle="modal"
                data-target="#licenseFuncModalHost"
                (click)="_isLoading || _exporting ? $event.stopPropagation() : playDevFunc(FUNCNAME_LICENSE_REALLOCATE)">
                <div class="icon fas fa-exchange-alt"></div>
                <span>Reallocate</span>
            </div>
        </div>
    </div>
</div>
<div id="licenseFuncModalHost" class="modal fade" data-backdrop="static" data-keyboard="false" role="dialog">
    <ng-template license-func-host></ng-template>
</div>