import { Component, Input } from '@angular/core';

@Component({
    selector: 'na-timeserver-preview',
    templateUrl: './timeserver-preview.component.html'
})
export class TimeserverPreviewComponent {
    _enabled: boolean = false;
    @Input('enabled')
    set on(v: boolean) {

        this._enabled = v;
    }

    _source: string;
    @Input('source')
    set source(v: string) {
        this._source = v;
    }
}