
import { ScopeFunctionInfo, LicenseScopeType } from './license.data';

export const LICENSE_SCOPE_FUNCTION_MAP: { [tag: string]: ScopeFunctionInfo } = {
    'IAdeaCare.pairing': {
        displayName: 'Pairing',
        type: LicenseScopeType.pairing
    },
    'IAdeaCare.heartbeat.long': {
        displayName: 'Heartbeat (long)',
        type: LicenseScopeType.heartbeatAutoRefresh,
        refreshDuration: 600
    },
    'IAdeaCare.heartbeat.normal': {
        displayName: 'Heartbeat (normal)',
        type: LicenseScopeType.heartbeatAutoRefresh,
        refreshDuration: 300
    },
    'IAdeaCare.screenshot.long': {
        displayName: 'Remote screenshot (long)',
        type: LicenseScopeType.screenshotAutoRefresh,
        refreshDuration: 300
    },
    'IAdeaCare.screenshot.normal': {
        displayName: 'Remote screenshot  (normal)',
        type: LicenseScopeType.screenshotAutoRefresh,
        refreshDuration: 30
    },
    'IAdeaCare.task.remoteFirmwareUpdate': {
        displayName: 'Firmware update',
        type: LicenseScopeType.firmwareUpdate
    },
    'IAdeaCare.task.setConfiguration': {
        displayName: 'Basic / network configuration',
        type: LicenseScopeType.taskConfig
    },
    'IAdeaCare.task.takeScreenshot': {
        displayName: 'Take screenshot',
        type: LicenseScopeType.takeScreenshot
    },
    'IAdeaCare.task.clearCache': {
        displayName: 'Clear cache',
        type: LicenseScopeType.clearCache
    },
    'IAdeaCare.monitor.alertEmail': {
        displayName: 'Alert email',
        type: LicenseScopeType.alertEmail
    },
    'IAdeaCare.activities.list.amount': {
        displayName: 'Recent activities (normal)',
        type: LicenseScopeType.activityHistory,
        amount: 10
    },
    'IAdeaCare.activities.list.history': {
        displayName: 'Recent activities (long)',
        type: LicenseScopeType.activityHistory,
        amount: 30 //temp
    },
    'IAdeaCare.device.sharing': {
        displayName: 'Share device',
        type: LicenseScopeType.sharePlayer
    },
    'IAdeaCare.devicePolicy': {
        displayName: 'Device policy',
        type: LicenseScopeType.devicePolicy
    },
    'IAdeaCare.remoteAssistance': {
        displayName: 'Remote assistance',
        type: LicenseScopeType.remoteAssistance
    },
    'IAdeaCare.report.diagnostic': {
        displayName: 'Remote diagnostic',
        type: LicenseScopeType.reportDiagnostic
    },
    'IAdeaCare.report.play.proof': {
        displayName: 'Play detection',
        type: LicenseScopeType.playProof
    },
    'IAdeaCare.report.health': {
        displayName: 'Device health status',
        type: LicenseScopeType.reportHealth
    }
}