<div class='modal-dialog config-dlg' role="document">
    <div class='modal-content'>
        <div class='modal-header'>
            <span class="modal-title">{{ title }}</span>
            <button #dlgClose type="button" class="close" [disabled]="_loading" data-dismiss="modal">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div>
                <p>
                    Are you sure you want to inactive key <strong>{{ key?.accessKeyID }}</strong> ?
                </p>
                <div class="block block-warning">
                    <div class="red-1">
                        <i class="fas fa-exclamation-circle"></i>
                        <span class="ml-2">Warning</span>
                    </div>
                    <div>
                        You cannot use inactive access key for API calls to IAdeaCare service but you can active it later.
                    </div>
                </div>
                <div class="mt-2">
                    <strong>Access key last used :</strong>
                    <span class="ml-2">{{ key?.lastUsedDate | date: 'yyyy-MM-dd HH:mm:ss'}}</span>
                </div>
                <div>
                    <strong>Note :</strong>
                    <span class="ml-2">{{ key?.accessKeyRemark }}</span>
                </div>
            </div>
            <div *ngIf="_inactiveResult" class="mt-4">
                <div *ngIf="!_inactiveResult.isFault; else templateInactiveError" class="block block-success">
                    <i class="fas fa-check-circle white"></i>
                    <span class="ml-2">Access key is deactivated.</span>
                </div>
                <ng-template #templateInactiveError>
                    <div>
                        <i class="fas fa-exclamation-triangle red-1"></i>
                        <span class="ml-2">
                            Deactivated access key failed. Please try again or contact support@IAdea.com
                        </span>
                        <div>{{ _inactiveResult.errorMessage }}</div>
                    </div>
                </ng-template>
            </div>
            <div class="row control-block d-flex justify-content-center align-items-center mt-4">
                <button *ngIf="_page === _enumDialogPage.result" type="button" class="btn btn-sm btn-action btn-light" data-dismiss="modal" [disabled]="_loading">
                    <span class="fas fa-times-circle icon-left"></span>
                    {{ 'key-close' | translate | capitalFirstWord }}
                </button>
                <ng-template [ngIf]="_page === _enumDialogPage.action">
                    <button type="button" class="btn btn-sm btn-action btn-light" data-dismiss="modal" [disabled]="_loading">
                        <span class="fas fa-times-circle icon-left"></span>
                        {{ 'key-cancel' | translate | capitalFirstWord }}
                    </button>
                    <button type="button" class="btn btn-sm btn-action btn-action-red btn-light" [disabled]="_loading"
                        (click)="inactiveAcc()">
                        <span class="fas fa-trash icon-left"></span>
                        Inactive
                    </button>
                    <div *ngIf="_loading" class="loading loading-p0 ml-4">
                        <div class="loading-box"></div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</div>