<nav>
  <ul class="pagination mb-0">
    <li class="page-item">
      <a class="page-link" aria-label="First page" (click)="goToPage(1)">&laquo;</a>
    </li>
    <li class="page-item">
      <a class="page-link" aria-label="Previous page" (click)="goToPage(_currentPage - 1)">
        &lsaquo;<span *ngIf="_showMoreL" class="ml-2">...</span>
      </a>
    </li>
    <li *ngFor="let pageIndex of _pageIndexes" class="page-item" [class.active]="pageIndex === _currentPage">
      <a *ngIf="show(pageIndex)" class="page-link" (click)="goToPage(pageIndex)">{{ pageIndex }}</a>
    </li>
    <li class="page-item">
      <a class="page-link" aria-label="Next page" (click)="goToPage(_currentPage + 1)">
        <span *ngIf="_showMoreR" class="mr-2">...</span>&rsaquo;
      </a>
    </li>
    <li class="page-item">
      <a class="page-link" aria-label="Last page" (click)="goToPage(_pageIndexes.length)">&raquo;</a>
    </li>
    <li *ngIf="_showDropdown" class="page-item">
      <div class="dropdown">
        <button id="dropdownPager" type="button" class="btn btn-sm btn-action btn-light stick-left dropdown-toggle"
          data-toggle="dropdown">
          ...
        </button>
        <div class="dropdown-menu dropdown-menu-left" aria-labelledby="dropdownPager">
          <span *ngFor="let pageIndex of _pageIndexes" class="dropdown-item" (click)="goToPage(pageIndex)">
            {{ pageIndex }}
          </span>
        </div>
      </div>
    </li>
  </ul>
</nav>