<form #inviteUserForm="ngForm">
    <div class="mb-2">
        <label for="id-user-identity" class="font-weight-bold">Identity</label>
        <div id="id-user-identity">
            <div class="form-group row">
                <label for="col-user-firstName"
                    class="col-6 col-sm-5 col-md-4 col-form-label col-form-label-sm required">
                    First name
                </label>
                <div class="col-6 col-sm-7 col-md-8">
                    <input #userFirstName="ngModel" id="col-user-firstName" type="text" name="userFirstName" required
                        class="form-control form-control-sm" [disabled]="_isReInvite"
                        [(ngModel)]="other.user.firstName">
                    <div [hidden]="userFirstName.valid || userFirstName.pristine" class="alert alert-danger mb-0">
                        First name is required
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label for="col-user-lastName"
                    class="col-6 col-sm-5 col-md-4 col-form-label col-form-label-sm required">
                    Last name
                </label>
                <div class="col-6 col-sm-7 col-md-8">
                    <input #userLastName="ngModel" id="col-user-lastName" type="text" name="userLastName" required
                        class="form-control form-control-sm" [disabled]="_isReInvite" [(ngModel)]="other.user.lastName">
                    <div [hidden]="userLastName.valid || userLastName.pristine" class="alert alert-danger mb-0">
                        Last name is required
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label for="col-user-email" class="col-6 col-sm-5 col-md-4 col-form-label col-form-label-sm required">
                    Email
                </label>
                <div class="col-6 col-sm-7 col-md-8">
                    <div class="input-group">
                        <input #userEmail="ngModel" id="col-user-email" type="text" name="userEmail" required
                            class="form-control form-control-sm" [disabled]="_isReInvite"
                            [(ngModel)]="other.user.email">
                        <div class="input-group-append position-relative">
                            <button type="button" class="btn btn-sm btn-action btn-light dropdown-toggle"
                                [disabled]="_isReInvite" data-toggle="dropdown" aria-expanded="false">
                                <span *ngIf="other.domain; else templateNoDomain">
                                    @{{ other.domain }}
                                </span>
                                <ng-template #templateNoDomain>
                                    Choose domain
                                </ng-template>
                            </button>
                            <ul class="dropdown-menu option-list w-100" data-backdrop="static" data-keyboard="false"
                                aria-labelledby="dropdownUserRoleLink">
                                <ng-template ngFor let-domain [ngForOf]="_availableDomainList">
                                    <li class="dropdown-item" (click)="changeUserDomain(domain)">
                                        {{ domain }}
                                    </li>
                                </ng-template>
                            </ul>
                        </div>
                    </div>
                    <div [hidden]="userEmail.valid || userEmail.pristine" class="alert alert-danger mb-0">
                        Email is required
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="mb-2">
        <label for="id-user-job" class="font-weight-bold">Job info</label>
        <div id="id-user-job">
            <div class="form-group row">
                <label for="col-user-company" class="col-6 col-sm-5 col-md-4 col-form-label col-form-label-sm">
                    Company
                </label>
                <div class="col-6 col-sm-7 col-md-8">
                    <input id="col-user-company" type="text" [disabled]="_isReInvite"
                        class="form-control form-control-sm" [(ngModel)]="other.user.company">
                </div>
            </div>
            <div class="form-group row">
                <label for="col-user-depart" class="col-6 col-sm-5 col-md-4 col-form-label col-form-label-sm">
                    Department
                </label>
                <div class="col-6 col-sm-7 col-md-8">
                    <input id="col-user-depart" type="text" [disabled]="_isReInvite"
                        class="form-control form-control-sm" [(ngModel)]="other.user.department">
                </div>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="col-user-policy"
            class="col-6 col-sm-5 col-md-4 col-form-label col-form-label-sm font-weight-bold required">
            User role
        </label>
        <div class="col-6 col-sm-7 col-md-8">
            <div id="col-user-policy" class="position-relative">
                <button id="dropdownUserRoleLink" type="button" class="btn btn-sm btn-action btn-light dropdown-toggle"
                    data-toggle="dropdown" [disabled]="_isReInvite">
                    {{ other.user.userRole }}
                </button>
                <ul class="dropdown-menu option-list w-100" data-backdrop="static" data-keyboard="false"
                    aria-labelledby="dropdownUserRoleLink">
                    <ng-template ngFor let-role [ngForOf]="_availableUserRoleList">
                        <li class="dropdown-item" (click)="changeUserRole(role)">
                            <span class="ml-2">
                                {{ role.name | capitalFirstWord }}
                            </span>
                        </li>
                    </ng-template>
                </ul>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="col-user-group"
            class="col-6 col-sm-5 col-md-4 col-form-label col-form-label-sm font-weight-bold required">
            User group
        </label>
        <div class="col-6 col-sm-7 col-md-8">
            <div id="col-user-group" class="position-relative">
                <button id="dropdownUserGroupLink" type="button" class="btn btn-sm btn-action btn-light dropdown-toggle"
                    data-toggle="dropdown" [disabled]="_isReInvite">
                    {{ _currentUserGroupName }}
                </button>
                <ul class="dropdown-menu option-list w-100" data-backdrop="static" data-keyboard="false"
                    aria-labelledby="dropdownUserGroupLink">
                    <ng-template ngFor let-group [ngForOf]="_availableUserGroupList">
                        <li class="dropdown-item" (click)="changeUserGroup(group)">
                            <span class="ml-2">
                                {{ group.name | capitalFirstWord }}
                            </span>
                        </li>
                    </ng-template>
                </ul>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="col-user-msg" class="col-12 col-form-label col-form-label-sm font-weight-bold">
            Messages
        </label>
        <div class="col-12">
            <textarea id="col-user-msg" rows="5" class="form-control form-control-sm"
                [(ngModel)]="this.other.message"></textarea>
        </div>
    </div>
</form>