import { Component, OnInit } from '@angular/core';
import { UserService } from '../../user.service';

@Component({
    selector: 'na-user-policy-scope',
    templateUrl: './user-policy-scope.component.html',
    styleUrls: ['./user-policy-scope.component.css']
})
export class UserPolicyScopeComponent implements OnInit {
    _userRoleList: string[] = ['admin', 'editor', 'viewer'];
    _userScopeList: { prefix: string, subScopeList: { permission: string, role: { admin?: boolean, editor?: boolean, viewer?: boolean }}[] }[]= [];

    constructor(private userSvc: UserService) { }

    ngOnInit(): void {
        this._userScopeList = [
            {
                prefix: 'Batch actions',
                subScopeList: [
                    {
                        permission: 'Basic configuration',
                        role: { admin: true, editor: true }
                    },
                    {
                        permission: 'Update security password',
                        role: { admin: true, editor: true }
                    },
                    {
                        permission: 'Update firmware',
                        role: { admin: true, editor: true }
                    },
                    {
                        permission: 'Update APK',
                        role: { admin: true, editor: true }
                    },
                    {
                        permission: 'Reboot',
                        role: { admin: true, editor: true }
                    },
                    {
                        permission: 'Troubleshoot',
                        role: { admin: true, editor: true }
                    },
                    {
                        permission: 'Clear cache',
                        role: { admin: true, editor: true }
                    }
                ] 
            },
            {
                prefix: '',
                subScopeList: [
                    {
                        permission: 'Create/move/delete/edit/change device group',
                        role: { admin: true }
                    },
                    {
                        permission: 'Create/edit/delete group policy settings',
                        role: { admin: true }
                    },
                    {
                        permission: 'Device status/info display',
                        role: { admin: true, editor: true, viewer: true }
                    },
                    {
                        permission: 'Device screenshot',
                        role: { admin: true, editor: true, viewer: true }
                    }
                ]
            },
            {
                prefix: 'Device',
                subScopeList: [
                    {
                        permission: 'Registration',
                        role: { admin: true }
                    },
                    {
                        permission: 'Reboot',
                        role: { admin: true, editor: true }
                    },
                    {
                        permission: 'Update firmware',
                        role: { admin: true, editor: true }
                    },
                    {
                        permission: 'Basic configuration',
                        role: { admin: true, editor: true }
                    },
                    {
                        permission: 'Network',
                        role: { admin: true, editor: true }
                    },
                    {
                        permission: 'Security',
                        role: { admin: true, editor: true }
                    },
                    {
                        permission: 'Troubleshoot',
                        role: { admin: true, editor: true, viewer: true }
                    },
                    {
                        permission: 'Activities',
                        role: { admin: true, editor: true, viewer: true }
                    },
                    {
                        permission: 'Alert (Create/edit)',
                        role: { admin: true, editor: true, viewer: true }
                    },
                    {
                        permission: 'Add license',
                        role: { admin: true }
                    },
                    {
                        permission: 'Change policy',
                        role: { admin: true }
                    },
                ]
            },
            {
                prefix: 'Notification',
                subScopeList: [
                    {
                        permission: 'View alert settings',
                        role: { admin: true, editor: true, viewer: true }
                    },
                    {
                        permission: 'Create/edit/delete alert settings',
                        role: { admin: true, editor: true, viewer: true }
                    },
                    {
                        permission: 'Acknowledged open alerts',
                        role: { admin: true, editor: true, viewer: true }
                    },
                    {
                        permission: 'View reports',
                        role: { admin: true, editor: true, viewer: true }
                    },
                    {
                        permission: 'Change report setting',
                        role: { admin: true, editor: true, viewer: true }
                    },
                ]
            },
            {
                prefix: 'Miscellaneous',
                subScopeList: [
                    {
                        permission: 'LAN config tool download links',
                        role: { admin: true, editor: true }
                    },
                    {
                        permission: 'View access key',
                        role: { admin: true, editor: true, viewer: true }
                    },
                    {
                        permission: 'Create/download/delete access key',
                        role: { admin: true, editor: true, viewer: true }
                    }
                ]
            },
            {
                prefix: 'Troubleshoot',
                subScopeList: [
                    {
                        permission: 'View/search troubleshoot tickets',
                        role: { admin: true, editor: true, viewer: true }
                    }
                ]
            },
            {
                prefix: 'License',
                subScopeList: [
                    {
                        permission: 'View/refresh/search device licenses',
                        role: { admin: true, editor: true, viewer: true }
                    },
                    {
                        permission: 'Add/import/reallocate license',
                        role: { admin: true }
                    },
                    {
                        permission: 'Export device license data',
                        role: { admin: true }
                    }
                ]
            },
            {
                prefix: 'Event feeds',
                subScopeList: [
                    {
                        permission: 'View/search event logs',
                        role: { admin: true, editor: true, viewer: true }
                    },
                    {
                        permission: 'Export event logs',
                        role: { admin: true, editor: true, viewer: true }
                    },
                    {
                        permission: 'View/search device activities',
                        role: { admin: true, editor: true, viewer: true }
                    },
                ]
            },
            {
                prefix: 'Administrative settings',
                subScopeList: [
                    {
                        permission: 'Invite/edit/delete user',
                        role: { admin: true }
                    },
                    {
                        permission: 'Edit group policy',
                        role: { admin: true }
                    },
                    {
                        permission: 'Create/edit/delete user group',
                        role: { admin: true }
                    },
                    {
                        permission: 'Create/edit/delete SCEP server',
                        role: { admin: true }
                    },
                    {
                        permission: 'Edit logo/background',
                        role: { admin: true }
                    }
                ]
            }
        ];
    }
}