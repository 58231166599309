export class ActivityInfo {
    activityID: string;
    isScheduled: boolean;
    issuer: string;
    isAdmin: boolean;
    scheduledDateTime: Date;
    issueDateTime: Date;
    startDateTime: Date;
    finishedDateTime: Date;
    statusCode: ActivityStatus;
    requestForCancel: boolean;
    requestForCancelDateTime: Date;
    funcName: string; 
    subject: string;
    content: string;
    commonActivityInputList?: {
        actionID: string;
        resourceData: any;
    }[];
    taskList: {
        taskID: string;
        virtualDevice: {
            virtualDeviceID: string;
            virtualDevicePairedID: string;
        };
        seperateActivityInput?: {
            actionID: string;
            resourceData: any;
        };
        status: {
            currentStatus: string;
            errorMessage?: string;
            startTimestamp?: number;
            finishedTimestamp?: number;
            retryTimes?: number;
            success?: boolean;
            isCancelled?: boolean;
            isTimeout?: boolean;
        } 
    }[];
    statusCount: {
        success: number;
        failed: number;
        cancelled: number;
        progress: number;
        pending: number;
    }
}

export enum ActivityStatus {
    all = 'all',
    finished = 'finished',
    pending = 'pending',
    fail = 'fail',
    progress = 'progress'
}