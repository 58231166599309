import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PageNotFoundComponent } from './other/page-not-found.component';
import { AuthGuard } from './entry/auth-guard.service';
import { AppComponent } from './app.component';

//The order of the routes matters.
//The router uses a first-mathc wins strategy when matching routes, so more specific routes should be placed above less specific routes.
//Generally, routes with a static path are listed first, followed by an empty path route, that matches the default route.
//The wildcard route comes last because it matches every URL and should be selected only if no other routes are matched first.
const routes: Routes = [
    {
        path: '',
        canActivate: [AuthGuard],
        component: AppComponent,
        pathMatch: 'full'
    },
    {
        path: '**', //wildcard
        component: PageNotFoundComponent
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { anchorScrolling: 'enabled', scrollPositionRestoration: 'enabled', relativeLinkResolution: 'legacy' })
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }