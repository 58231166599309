import { Component, EventEmitter, Input, Output, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { AppManagementInfo, AppManagementSplitInfo } from './appmgr.data';

@Component({
    selector: 'na-app-management-preview',
    templateUrl: './appmgr-preview.component.html',
    styleUrls: ['./appmgr.style.css']
})
export class AppManagementPreviewComponent {
    _appList: AppManagementInfo[] = [];
    _app: AppManagementInfo;
    _appToLaunch: AppManagementInfo;
    _currentAppIndex: number = -1;

    _split: AppManagementSplitInfo;

    @Input('data')
    set data(raw: AppManagementInfo[]) {
        this._appList = raw;
        if (this._appList && this._appList.length > 0) {
            this._app = this._appList[0];
            this._appToLaunch = this._appList.find(app => app.bindToAppstart);
            this._currentAppIndex = 0;

            if (this._app) {
                this._split = this._app.splits?.[0];
            }
        }
    }

    goPrevApp(): void {
        if (this._currentAppIndex > 0) {
            this._currentAppIndex--;
            this._app = this._appList[this._currentAppIndex];
        }
    }

    goNextApp(): void {
        if (this._currentAppIndex < this._appList.length - 1) {
            this._currentAppIndex++;
            this._app = this._appList[this._currentAppIndex];
        }
    }

    selectSplit(split: AppManagementSplitInfo): void {
        this._split = split;
    }
}