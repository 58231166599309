import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { ILicenseDynamicComponent } from './license-func.def';
import { DeviceInfo } from '../../device/data/device-info';
import { DialogPage } from '../../../lib/common/common.data';
import { DeviceService } from '../../device/device.service';
import { LicenseService } from '../license.service';
import { AccountService } from '../../../entry/account.service';
import { Subscription } from 'rxjs';


@Component({
    template: '',
    styleUrls: ['./license-material.style.css']
})
export class LicenseBaseFormComponent implements ILicenseDynamicComponent, OnInit, OnDestroy {
    title: string;
    dialogCompleteHandler?: (result: any) => void;
    _devices: DeviceInfo[] = [];

    _page: DialogPage = DialogPage.prepare;
    _enumPage: typeof DialogPage = DialogPage;
    protected _errorMessage: string;

    protected _legalDevices: DeviceInfo[] = [];
    protected _shareForbiddenDevices: DeviceInfo[] = [];
    
    private _eventSubscription: Subscription;
    private dlgCloseElementRef: ElementRef;
    @ViewChild('dlgClose', { static: true })
    set dlgClose(holder: ElementRef) {
        if (holder) {
            this.dlgCloseElementRef = holder;
        }
    }
    
    constructor(protected devSvc: DeviceService, protected licenseSvc: LicenseService, protected accountSvc: AccountService) { }

    ngOnInit(): void {
        this._eventSubscription = this.accountSvc.loginChanged.subscribe((isLogin: boolean) => {
            if (!isLogin) {
                this.dlgCloseElementRef.nativeElement.click();
            }
        });
    }
    ngOnDestroy(): void {
        if (this._eventSubscription) {
            this._eventSubscription.unsubscribe();
        }
    }

    allowClose(): boolean {
        switch (this._page) {
            case DialogPage.prepare:
                {
                    return this.isPageValid(this._page) ? false : true;
                }
            case DialogPage.result:
                {
                    return true;
                }
            default:
                {
                    return false;
                }
        }
    }

    allowSubmit(): boolean {
        switch (this._page) {
            case DialogPage.confirm:
                {
                    return true;
                }
            default:
                {
                    return false;
                }
        }
    }

    allowCancel(): boolean {
        switch (this._page) {
            case DialogPage.prepare:
                {
                    return this.isPageValid(this._page) ? true : false;
                }
            case DialogPage.action:
            case DialogPage.confirm:
                {
                    return true;
                }
            default:
                {
                    return false;
                }
        }
    }

    allowGoBack(): boolean {
        switch (this._page) {
            case DialogPage.confirm:
                {
                    return true;
                }
            default:
                {
                    return false;
                }
        }
    }

    allowGoNext(): boolean {
        switch (this._page) {
            case DialogPage.prepare:
                {
                    return this.isPageValid(this._page) ? true : false;
                }
            case DialogPage.action:
                {
                    //check if license code is valid => length match?
                    return true;
                }
            default:
                {
                    return false;
                }
        }
    }

    goBack(): void {
        switch (this._page) {
            case DialogPage.confirm:
                {
                    this._page = DialogPage.action;
                }
                break;
        }
    }

    goNext(): void {
        switch (this._page) {
            case DialogPage.prepare:
                {
                    this._page = DialogPage.action;
                }
                break;
            case DialogPage.action:
                {
                    this._page = DialogPage.confirm;
                }
                break;
            case DialogPage.confirm:
                {
                    this._page = DialogPage.submit;
                }
                break;
            case DialogPage.submit:
                {
                    this._page = DialogPage.result;
                }
                break;
        }
    }

    protected isPageValid(page: DialogPage): boolean {
        return true;
    }
}