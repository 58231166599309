import { Component, Input } from '@angular/core';

@Component({
    selector: 'na-share-preview',
    templateUrl: './share-preview.component.html',
    styleUrls: ['./share.style.css']
})
export class SharePreviewComponent {
    _shareList: { accountName: string, permission: string[] }[] = [];
    @Input('data')
    set data(vMap: {
        [accountID: string]: {
            accountID: string,
            accountName: string,
            permission: string[],
            action?: string
        }
    }) {
        if (vMap) {
            this._shareList = Object.keys(vMap).filter((accountID: string) => vMap[accountID].action !== '-').map((accountID: string) => {
                return {
                    accountName: vMap[accountID].accountName,
                    permission: vMap[accountID].permission
                }
            })
        }
    }
}