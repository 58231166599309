<div class="d-flex align-items-end">
    <span *ngIf="title" class="mr-2">{{ title }}</span>
    <button *ngIf="supportCertAddition" type="button" class="btn btn-sm btn-action mr-2" data-toggle="modal"
        data-target="#certFuncModalHost" (click)="addCert()">
        <i class="fas fa-plus mr-1"></i>
        Add certificate
    </button>
    <a *ngIf="supportCertMgrLink" [routerLink]="['/app/miscellaneous/cert']" class="mr-2">
        <i class="fas fa-edit"></i>
    </a>
    <div *ngIf="supportSearch" class="input-group input-group-sm w-50 ml-auto">
        <div class="input-group-prepend">
            <span class="input-group-text">
                <i class="fas fa-search"></i>
            </span>
        </div>
        <input class="form-control form-control-sm" type='text' [disabled]="_loading" name='search' #search
            placeholder="Search by certificate name ..." aria-label="Search certificates">
    </div>
</div>
<div class="list-group mt-1">
    <div class="list-group-item list-group-header">
        <div class="row align-items-center">
            <div *ngIf="supportCertSelection" class="col-1 title">

            </div>
            <div class="col title">
                Name
                <span class="sorter">
                    <i class="fas fa-caret-up sort-icon descend"
                        [ngClass]="_currentSortKey === CERT_COLKEY_NAME && _currentSortType === _enumSortType.descend ? 'active' : ''"
                        (click)="sortDescend(CERT_COLKEY_NAME)">
                    </i>
                    <i class="fas fa-caret-down sort-icon ascend"
                        [ngClass]="_currentSortKey === CERT_COLKEY_NAME && _currentSortType === _enumSortType.ascend ? 'active' : ''"
                        (click)="sortAscend(CERT_COLKEY_NAME)">
                    </i>
                </span>
            </div>
            <div class="col title">
                Expiry date
                <span class="sorter">
                    <i class="fas fa-caret-up sort-icon descend"
                        [ngClass]="_currentSortKey === CERT_COLKEY_EXPIRYDATE && _currentSortType === _enumSortType.descend ? 'active' : ''"
                        (click)="sortDescend(CERT_COLKEY_EXPIRYDATE)">
                    </i>
                    <i class="fas fa-caret-down sort-icon ascend"
                        [ngClass]="_currentSortKey === CERT_COLKEY_EXPIRYDATE && _currentSortType === _enumSortType.ascend ? 'active' : ''"
                        (click)="sortAscend(CERT_COLKEY_EXPIRYDATE)">
                    </i>
                </span>
            </div>
            <div *ngIf="supportCertRemoval" class="col title">
                Action
            </div>
        </div>
    </div>
    <div *ngIf="!_loading; else templateLoadingCert">
        <div class="list-group-content list-group-hoverless">
            <div *ngIf="_displayList.length == 0; else templateCertList" class="text-center my-4">
                No certificates
            </div>
            <ng-template #templateCertList>
                <div *ngFor="let cert of _displayList" class="list-group-item" [class.d-none]="_lockedCertMap[cert.id]">
                    <div class="row align-items-center">
                        <div *ngIf="supportCertSelection" class="col-1 text-break">
                            <input [id]="'cb-td-cert-' + cert.id" type="checkbox" class="cmn-checkbox"
                                [checked]="_selectedCertMap[cert.id] && _selectedCertMap[cert.id].selected"
                                (change)="selectCert(cert, $event.target.checked)" />
                            <label [for]="'cb-td-cert-' + cert.id">
                                <svg>
                                    <use xlink:href="#checkmark" />
                                </svg>
                                <span class="sr-only">Select certificate {{ cert.name }}</span>
                            </label>
                        </div>
                        <div class="col text-break">
                            {{ cert.name }}
                        </div>
                        <div class="col text-break">
                            {{ cert.expiryDate | date:'yyyy-MM-dd' }}
                        </div>
                        <div *ngIf="supportCertRemoval" class="col text-break">
                            <i class="fas fa-trash-alt" data-toggle="modal" data-target="#certFuncModalHost"
                                (click)="removeCert(cert)"></i>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
    <ng-template #templateLoadingCert>
        <div class="loading">
            <p>Please wait...</p>
            <div class="loading-box"></div>
        </div>
    </ng-template>
    <div class="ml-auto mt-2">
        <na-pager [currentPage]="_currentPage" [numberInPage]="numberInPage" [total]="_displayAmount"
            (onPageChanged)="onPageChange($event)">
        </na-pager>
    </div>
</div>
<div id="certFuncModalHost" class="modal fade" data-backdrop="static" data-keyboard="false" role="dialog">
    <ng-template cert-func></ng-template>
</div>