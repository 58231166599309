import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AccountService } from '../../../../entry/account.service';
import { DialogPage } from '../../../../lib/common/common.data';
import { UserDlgContentFuncService } from './user-dlg-content-func.service';
import { IUserDlgFuncContent, IUserDlgFuncCtrl, UserDlgFuncItem } from './user-dlg-func.def';
import { UserDlgFuncDirective } from './user-dlg-func.directive';
import { UserService } from '../user.service';

@Component({
    selector: 'na-user-dlg-wrapper',
    templateUrl: './user-dlg-base.component.html',
    styleUrls: ['../user.style.css']
})
export class UserBaseDlgComponent<D, O> implements IUserDlgFuncCtrl<D, O>, OnInit, OnDestroy {
    title: string;
    funcName: string;
    data: D; // UserInfo[] | UserGroupInfo
    other?: O;

    _action_txt: string; // text to show on 'submit' button
    _action_icon: string; // icon to show on 'submit' button
    _complete_data: any; // do to transmit when click 'close' button
    _content_func_name: string;

    _isDataValid: boolean = false;
    _errorMessage: string;
    _page: DialogPage = DialogPage.prepare;
    _enumPage: typeof DialogPage = DialogPage;

    private _allUnsubscribe: Subject<void> = new Subject();

    private _userDlgFuncHost: UserDlgFuncDirective;
    @ViewChild(UserDlgFuncDirective, { static: true })
    set userDlgFuncHost(host: any) {
        this._userDlgFuncHost = host;
    }

    private _btnCloseElementRef: ElementRef;
    @ViewChild('dlgClose', { static: true })
    set btnClose(v: any) {
        this._btnCloseElementRef = v;
    }

    onActionCompleted: (ret: { funcName: string; isFault: boolean; data?: any; errorMessage?: string; }) => void;
    onActionPrepared: () => void;
    onActionValidChanged: (valid: boolean) => void;
    onActionCancelled: (ret: { funcName: string }) => void;

    constructor(
        private accountSvc: AccountService,
        protected userSvc: UserService,
        protected userDlgContentSvc: UserDlgContentFuncService) { }

    ngOnInit(): void {
        this.accountSvc.loginChanged.pipe(
            takeUntil(this._allUnsubscribe)
        ).subscribe((isLogin: boolean) => {
            if (!isLogin) {
                this._btnCloseElementRef.nativeElement.click();
            }
        });

        this.createUserDlgContent(this._content_func_name);
    }

    ngOnDestroy(): void {
        this._allUnsubscribe.next();
        this._allUnsubscribe.complete();
    }

    isCacelValid(): boolean {
        if (this._page === DialogPage.prepare || this._page === DialogPage.submit) {
            return false;
        }

        return true;
    }

    isSubmitValid(): boolean {
        if (this._page === DialogPage.prepare || !this._isDataValid || this._page === DialogPage.submit) {
            return false;
        }

        return true;
    }

    submit(): void {

    }

    cancel(): void {
        this._btnCloseElementRef.nativeElement.click();
        this.onActionCancelled({ funcName: this.funcName });
    }

    close(): void {
        this.onActionCompleted({ funcName: this.funcName, isFault: this._errorMessage ? true : false, data: this._complete_data });
    }

    private onActionReady(): void {
        this._page = DialogPage.action;
    }

    private onActionValidChange(valid: boolean): void {
        this._isDataValid = valid;
    }

    protected createUserDlgContent(userFuncName: string): void {
        const item: UserDlgFuncItem = this.userDlgContentSvc.getItemByName(userFuncName);
        if (item) {
            const viewContainerRef = this._userDlgFuncHost.viewContainerRef;
            viewContainerRef.clear();

            const componentRef = viewContainerRef.createComponent(item.component);

            (<IUserDlgFuncContent<D, O>>componentRef.instance).funcName = userFuncName;
            (<IUserDlgFuncContent<D, O>>componentRef.instance).data = this.data;
            (<IUserDlgFuncContent<D, O>>componentRef.instance).other = this.other;
            (<IUserDlgFuncContent<D, O>>componentRef.instance).onActionPrepared = this.onActionReady.bind(this);
            (<IUserDlgFuncContent<D, O>>componentRef.instance).onActionValidChanged = this.onActionValidChange.bind(this);
        }
    }
}