<div *ngIf="data.length > 0; else templateNoUserSelect">
    <div *ngIf="!_isFixTargetGroup; else templateFixTargetGroup">
        <p>
            Move users from <strong>{{ other.from.name }}</strong> to another user group.
        </p>
        <div class="row">
            <div class="col-6">
                <strong class="mb-1">Users under <strong class="red-1">{{ other.from.name }}</strong></strong>
                <ul class="border">
                    <li *ngFor="let user of data">
                        {{ user.email }}
                    </li>
                </ul>
            </div>
            <div class="col-6">
                <strong class="mb-1">Move to <strong class="red-1">{{ other.to?.name }}</strong></strong>
                <na-user-group-view [funcMode]="selection" (onUserGroupChosen)="onUserGroupChosen($event)"></na-user-group-view>
            </div>
        </div>
    </div>
    <ng-template #templateFixTargetGroup>
        <div *ngIf="!_isToUngroup; else templateMoveToUngroup">
            <p>Move users from <strong class="red-1">{{ other.from.name }}</strong> to <strong class="red-1">{{ other.to?.name }}</strong></p>
        </div>
        <ng-template #templateMoveToUngroup>
            <p>Are you sure the remove these users from group <strong class="red-1">{{ other.from.name }}</strong> ?</p>
        </ng-template>
        <ul class="border py-1">
            <li *ngFor="let user of data">
                {{ user.email }}
            </li>
        </ul>
    </ng-template>
</div>
<ng-template #templateNoUserSelect>
    <p>Please select at least a user.</p>
</ng-template>