import { Component } from '@angular/core';

import { DevFuncConfirmInterface } from '../../base/confirm/dev-func-confirm.interface';
import { DeviceBasicConfigControl } from '../data/device-basic-config.control';
import { ConfigurableItemKey } from '../../../../../app/lib/common/common.data';
import { BasicConfigItem } from '../data/device-config.item';
import { ConstantService } from '../../../../lib/common/constant.service';

@Component({
    templateUrl: './basic-config-confirm-subform.component.html',
    styleUrls: ['../../base/base-config-form.component.css', './basic-config-confirm-subform.component.css']
})
export class BasicConfigConfirmSubFormComponent implements DevFuncConfirmInterface {
    private _actionData: any;
    set actionData(data: any) {
        this._actionData = data;
        if (this._actionData) {
            this._config = this._actionData.configData;
        }
    }

    set allowSubmitCallback(fn: (isAllow: boolean) => void) {
        fn(this._config.hasChangeItems());
    }

    private _enumConfigCategory: typeof ConfigurableItemKey = ConfigurableItemKey;
    _config: DeviceBasicConfigControl;

    constructor(private constantSvc: ConstantService) {}

    private getConfigValue(config: BasicConfigItem<any>, origin: boolean = false): any {
        const keys: string[] = Object.keys(config.settingMap);
        if (keys.length === 1) {
            return origin ? config.settingMap[keys[0]].origin : config.settingMap[keys[0]].value;
        }

        return 'Unknown';
    }
}