import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
    selector: '[upperCase]'
})
export class UppercaseDirective {
    @Output() ngModelChange: EventEmitter<string> = new EventEmitter();
    value: any;

    @HostListener('input', ['$event']) onInputChange($event) {
        this.value = $event.target.value.toUpperCase();
        this.ngModelChange.emit(this.value);
    }
}