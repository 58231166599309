<div *ngFor="let s of _shareList" class="share-item-block">
    <div>
        <strong>Account</strong>
        <span class="ml-2">{{ s.accountName }} </span>
    </div>
    <div>
        <strong>Permission</strong>
        <div class="permission-block">
            <ng-template ngFor let-p [ngForOf]="s.permission">
                <span>[{{ p }}]</span>
            </ng-template>
        </div>
    </div>
</div>

