import { Component } from '@angular/core';
import { of } from 'rxjs';

import { DialogPage } from '../../../../../lib/common/common.data';
import { UserBaseDlgComponent } from '../user-dlg-base.component';
import { USER_DLG_FUNC_EDITCONFIRM_CONTENT } from '../user-dlg-func.def';
import { UserInfo } from '../../user.data';
import { concatMap, map } from 'rxjs/operators';

@Component({
    templateUrl: '../user-dlg-base.component.html',
    styleUrls: ['../../user.style.css']
})
export class UserConfirmEditDlgComponent extends UserBaseDlgComponent<UserInfo[], UserInfo> {
    ngOnInit(): void {
        this._action_txt = 'Save changes';
        this._action_icon = 'fa-save';
        this._content_func_name = USER_DLG_FUNC_EDITCONFIRM_CONTENT;

        this._page = DialogPage.action;

        super.ngOnInit();
    }

    submit(): void {
        this._errorMessage = null;
        this._page = DialogPage.submit;

        const originUser: UserInfo = this.other;
        const updateUser: UserInfo = this.data[0];

        of(true).pipe(
            concatMap(() => {
                if (updateUser.userRole !== originUser.userRole) {
                    return this.userSvc.isEnterpriseLicenseSufficient(updateUser.userRole).pipe(
                        map((sufficient: boolean) => {
                            if (sufficient) {
                                return true;
                            }

                            //show alert message
                            alert(this.userSvc.USER_ROLE_EXCEED_ALERT);
                            return false;
                        })
                    )
                }

                return of(true);
            }),
            concatMap((proceed: boolean) => {
                if (proceed) {
                    return this.userSvc.updateUser(updateUser).pipe(
                        map((res: { isFault: boolean, errorMessage?: string, user?: UserInfo }) => {
                            this._errorMessage = res.errorMessage;
                            this._page = DialogPage.result;
                
                            this._complete_data = res.user;

                            return true;
                        })
                    );
                }
                
                this._page = DialogPage.action;

                return of(false);
            })
        ).subscribe();
    }
}