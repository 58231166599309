import { Component, Input } from '@angular/core';
import { TicketInfo } from '../troubleshoot-data';
import { TroubleshootService } from '../troubleshoot.service';
import { TaskStatus } from '../../device/data/device-info';
import { REFRESH_DURATION, HelperLib } from '../../../lib/common/helper.lib';

@Component({
    selector: 'ticket-report',
    templateUrl: './ticket-report.component.html',
    styleUrls: ['./ticket-report.component.css']
})
export class TicketReportComponent {
    _bLoadingTicketDetail: boolean = false;
    _ticketRefreshCountdown: number = 0;
    _enumTaskStatus: typeof TaskStatus = TaskStatus;

    _ticket: TicketInfo;
    @Input('ticket')
    set ticket(t: TicketInfo) {
        if (t) {
            this._ticket = t;
        }
    }

    constructor(private troubleSvc: TroubleshootService) { }

    refreshTicket(ticketId: string): void {
        this._ticketRefreshCountdown = REFRESH_DURATION;
        HelperLib.countdown(this._ticketRefreshCountdown, 0, (counter: number) => {
            this._ticketRefreshCountdown = counter;
        });

        this._bLoadingTicketDetail = true;
        this.troubleSvc.getTicketDetail(ticketId, true).subscribe((t: TicketInfo) => {
            this._bLoadingTicketDetail = false;
            this._ticket = t;
        });
    }
}