<div *ngIf="!_isViewMode; else templateViewMode" class="row">
    <div class="col-md-5 col-12">
        <div class="form-group">
            <label>Auto apply to newly-paired device(s)</label>
            <div class='switch-container'>
                <input id="cmn-alert-offline-autoApply" class="cmn-toggle cmn-toggle-flat cmn-toggle-rect-flat"
                    type="checkbox" [checked]="_data.isAlertAutoApply ? 'checked': null"
                    (change)="_data.changeAutoApply($event.target.checked)">
                <label for="cmn-alert-offline-autoApply">
                    <span class="sr-only">Auto apply to newly-paired device(s)</span>
                </label>
            </div>
        </div>
        <div class="form-group">
            <label>Alert email will be sent when</label>
            <div class="ml-2">
                <div *ngFor="let monitorItem of _monitorList" class="d-flex align-items-center">
                    <input [id]="'id-dev-cmd-' + monitorItem.id" type="checkbox" class="cmn-checkbox"
                        [checked]="monitorItem.checked"
                        (change)="changeMonitorItem(monitorItem, $event.target.checked)" />
                    <label [for]="'id-dev-cmd-' + monitorItem.id">
                        <svg>
                            <use xlink:href="#checkmark" />
                        </svg>
                    </label>
                    <span class="ml-2">{{ monitorItem.displayName }}</span>
                </div>
            </div>
        </div>
        <div class="form-group">
            <label for="alert-receiver">Deliver to</label>
            <div>
                <textarea id="alert-receiver" class="form-control" rows="6"
                    placeholder="Enter valid email(s) here by spliting with comma or 'Enter'..." [ngModel]="_receivers"
                    (ngModelChange)="changeReceivers($event)">
                </textarea>
                <span class="note text-muted ml-2">(split by comma or 'Enter')</span>
            </div>
        </div>
    </div>
    <div class="col-md-7 col-12">
        <na-dev-select [title]="'Apply to'" [selectDeviceIDList]="_defaultSelectDeviceIDList"
            (onDeviceSelectChanged)="onDeviceCommandAlertDeviceSelectChange($event)">
        </na-dev-select>
    </div>
</div>
<ng-template #templateViewMode>
    <div>
        <!-- auto apply -->
        <div *ngIf="_data.isAlertAutoApply" class="form-group row">
            <div class="col-12">
                <div id="col-alert-autoapply" class="form-control-plaintext red-1">
                    This alert is set to apply to newly-paired devices
                </div>
            </div>
        </div>
        <!-- monitor item -->
        <div class="form-group row">
            <label for="col-monitor-items" class="col-12">
                Alert email will be sent when
            </label>
            <div class="col-12">
                <ng-template ngFor let-monitorItem [ngForOf]="_monitorList">
                    <div *ngIf="monitorItem.checked" class="form-control-plaintext blue-2 ml-2">{{ monitorItem.displayName }}</div>
                </ng-template>
            </div>
        </div>
        <!-- apply device -->
        <div class="form-group row">
            <label for="col-alert-devices" class="col-12">
                Applied devices
            </label>
            <div class="col-12">
                <div id="col-alert-devices" class="alert-list-block">
                    {{transformApplyDeviceList(_data.alertApplyDeviceList)}}
                </div>
            </div>
        </div>
        <!-- deliver to -->
        <div class="form-group row">
            <label for="col-alert-receiver" class="col-12">
                Alert receiver
            </label>
            <div class="col-12">
                <div id="col-alert-receiver" class="alert-list-block">
                    {{transformReceiverList(_data.alertReceiverList)}}
                </div>
            </div>
        </div>
    </div>
</ng-template>