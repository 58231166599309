import { Component, Input, Output, ViewChild, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { of as observableOf, Subject } from 'rxjs';
import { concatMap, map, takeUntil } from 'rxjs/operators';

import { ConfigDialogPage } from './base-config-data';
import { DevFuncInterface } from '../dev-func.interface';
import { DeviceInfo, IPolicyLockMap, OnlineStatus } from '../../device/data/device-info';
import { DeviceService } from '../../device/device.service';
import { LicenseScopeType } from '../../license/license.data';
import { DevFuncActionDirective } from './action/dev-func-action.directive';
import { DevFuncActionItem } from './action/dev-func-action-item';
import { DevFuncActionService } from './action/dev-func-action.service';
import { DevFuncActionInterface } from './action/dev-func-action.interface';
import { DevFuncConfirmDirective } from './confirm/dev-func-confirm.directive';
import { DevFuncConfirmItem } from './confirm/dev-func-confirm-item';
import { DevFuncConfirmService } from './confirm/dev-func-confirm.service';
import { DevFuncConfirmInterface } from './confirm/dev-func-confirm.interface';
import { SharePermissionType } from '../../../lib/common/helper.lib';
import { LicenseService } from '../../license/license.service';
import { IClass } from '../../../lib/common/common.data';
import { Logger } from '../../../lib/common/logger';
import { APKService } from '../apk/apk.service';
import { AccountService } from '../../../../app/entry/account.service';
import { ConstantService } from '../../../../app/lib/common/constant.service';
import { NAService } from '../../../API/na.service';
import { PolicyType } from '../../../../app/content/setting/policy/policy.data';

@Component({
    templateUrl: './base-config-form.component.html',
    styleUrls: ['./base-config-form.component.css']
})
export class BaseConfigFormComponent implements OnInit, OnDestroy, DevFuncInterface, IClass {
    className: string;
    _title: string;
    @Input()
    set title(t: string) {
        this._title = t;
    };

    _minWidth: number = 800;
    @Input()
    set minWidth(minW: number) {
        this._minWidth = minW;
    }

    private _licenseScope: LicenseScopeType;
    set licenseScope(ls: LicenseScopeType) {
        this._licenseScope = ls;
    }

    protected _lockMap: IPolicyLockMap;
    @Input()
    set lockMap(i: IPolicyLockMap) {
        this._lockMap = i;
    }

    protected _devices: DeviceInfo[];
    @Input()
    set devices(devs: DeviceInfo | DeviceInfo[]) {
        if (!devs) {
            return;
        }

        this.reset();

        if (devs instanceof DeviceInfo) {
            devs = [devs];
            this._bSpecific = true;
        }

        devs.forEach(d => {
            this._devices.push(d);
        });
    }
    @Output() dialogCompleteHandler?: (result: any) => void;
    @Output() dialogInteractHandler?: (data: any) => void;

    protected _legalDevices: DeviceInfo[];
    protected _licenseForbiddenDevices: DeviceInfo[] = [];
    protected _shareForbiddenDevices: DeviceInfo[] = [];
    protected _offlineForbiddenDevices: DeviceInfo[] = [];
    protected _policyForbiddenDevices: DeviceInfo[] = [];
    protected _bSpecific: boolean;
    protected _legal_device_count: number = 0;

    protected _loading: boolean = false;
    protected _actionData: any = {};
    protected _customActionName: string;
    _dialogName: string;
    protected _licenseScopeType: LicenseScopeType;
    protected _policyLockType: PolicyType;
    protected _sharePermissionType: string;
    protected _confirmErrorMessage: string;
    _errorMessage: string;
    protected _resultErrorList: { virtualDeviceID: string, virtualDeviceName: string, errorMessage: string }[] = [];
    protected _result_msg_success: string;
    _page: ConfigDialogPage = ConfigDialogPage.checking;
    _enumPage: typeof ConfigDialogPage = ConfigDialogPage;
    private _bActionStatusValid: boolean = true;
    _allowSubmit: boolean = true;
    private _monitorDeviceNames: string;

    protected _allUnsubscribe: Subject<void> = new Subject();

    @ViewChild(DevFuncActionDirective, { static: true }) devFuncActionHost: DevFuncActionDirective;
    @ViewChild(DevFuncConfirmDirective, { static: true }) devFuncConfirmHost: DevFuncConfirmDirective;

    private dlgCloseElementRef: ElementRef;
    @ViewChild('dlgClose', { static: true })
    set dlgClose(holder: ElementRef) {
        if (holder) {
            this.dlgCloseElementRef = holder;
        }
    }

    constructor(
        protected devFuncActionSvc: DevFuncActionService,
        protected devFuncConfirmSvc: DevFuncConfirmService,
        protected accountSvc: AccountService,
        protected naSvc: NAService,
        protected licenseSvc: LicenseService,
        protected constantSvc: ConstantService,
        protected devSvc: DeviceService,
        protected apkSvc: APKService) {

        this.className = 'Basic-config-form';
        this._licenseScopeType = LicenseScopeType.taskConfig;
        this._sharePermissionType = SharePermissionType.BasicConfig;
        this._policyLockType = null;
        this._result_msg_success = 'New settings have been applied successfully. It will take some times for the new settings to take effect to the devices.'
    }

    ngOnInit(): void {
        this.accountSvc.loginChanged.pipe(
            takeUntil(this._allUnsubscribe)
        ).subscribe((isLogin: boolean) => {
            if (!isLogin) {
                this.dlgCloseElementRef.nativeElement.click();
            }
        });

        this._loading = true;
        //check function legality for assigned devices.
        //1. check if owner of the device is self, only self device can do share function
        //2. If the device is a shared device, check if owner has authorize the function permission to you
        //3. check if the device has the license to do the function.
        //4. check the device onlineStatus -> firmwareUpdate, reboot, basic/net config should be disabled if offline
        observableOf(true).pipe(
            map(() => {
                const policyPassedDevices: DeviceInfo[] = this._bSpecific || !this._policyLockType ? this._devices : [];
                
                if (!this._bSpecific && this._policyLockType) {
                    this._devices.forEach(d => {
                        if (!d.virtualDeviceGroup || !d.virtualDeviceGroup.policies || !d.virtualDeviceGroup.policies[this._policyLockType] || d.virtualDeviceGroup.policies[this._policyLockType].length === 0) {
                            policyPassedDevices.push(d);
                        }
                        else {
                            this._policyForbiddenDevices.push(d);
                        }
                    });
                }

                return {
                    result: this.checkSharePermissionLegality(policyPassedDevices, this._sharePermissionType),
                    deviceList: policyPassedDevices
                }
            }),
            concatMap((sharePermissionResult: { result: { [virtualDeviceID: string]: boolean }, deviceList: DeviceInfo[] }) => {
                const sharePermissionPassedDeviceList: DeviceInfo[] = [];
                sharePermissionResult.deviceList.forEach((d: DeviceInfo) => {
                    if (sharePermissionResult.result[d.virtualId]) {
                        sharePermissionPassedDeviceList.push(d);
                    }
                    else {
                        this._shareForbiddenDevices.push(d);
                    }
                });

                Logger.logInfo(this.className, 'OnInit', 'Share permission result = ', sharePermissionResult.result);
                return this.licenseSvc.checkLicenseLegality(sharePermissionPassedDeviceList, this._licenseScopeType);
            })
        ).subscribe((result: { dev: DeviceInfo, isLegal: boolean }[]) => {
            Logger.logInfo(this.className, 'OnInit', 'License permission result = ', result);

            result.forEach((r: { dev?: DeviceInfo, isLegal: boolean }) => {
                if (r.isLegal) {
                    if ((this._licenseScopeType === LicenseScopeType.taskConfig || this._licenseScopeType === LicenseScopeType.firmwareUpdate) && r.dev.currentSettings[this.constantSvc.DEVKEY_FAKE_ONLINESTATUS] !== OnlineStatus.Online) {
                        this._offlineForbiddenDevices.push(r.dev);
                    }
                    else {
                        this._legalDevices.push(r.dev);
                    }
                }
                else {
                    this._licenseForbiddenDevices.push(r.dev);
                }
            });

            this._loading = false;
            this.reset_legal_device_count();
            this.afterInitProcess();
        });
    }

    ngOnDestroy(): void {
        this._allUnsubscribe.next();
        this._allUnsubscribe.complete();
    }

    protected afterInitProcess(): void {
        if (this._bSpecific && this._licenseForbiddenDevices.length === 0) {
            //go to action page directly
            this.goNext();
        }
    }

    protected goNext(fromPage?: ConfigDialogPage): void {
        this._errorMessage = null;

        if (this._page === ConfigDialogPage.checking) {
            const deviceName: string = this._legalDevices.filter(d => this._bSpecific ? true : d.isSelect).map(d => d.virtualName).join(', ');

            this._actionData.deviceNames = deviceName;
            this._actionData.bSpecific = this._bSpecific;
            this._actionData.deviceList = this._legalDevices;
            this._actionData.lockMap = this._lockMap;
        }

        if (fromPage) {
            this._page = fromPage;
        }

        this._page++;
        this.handlePageComponent();
    }

    protected goBack(fromPage?: ConfigDialogPage): void {
        if (fromPage) {
            this._page = fromPage;
        }
        this._page--;
        this.handlePageComponent();
    }

    private handlePageComponent(): void {
        this.clearDynamicChildDialog();

        if (this._page === ConfigDialogPage.action) {
            this.playFuncAction(this._dialogName);
        }
        else if (this._page === ConfigDialogPage.confirm) {
            this.playFuncConfirm(this._dialogName);
        }
    }

    private checkSharePermissionLegality(devices: DeviceInfo[], queryPermissionType: string): { [virtualDeviceID: string]: boolean } {
        const sharePermissionMap: { [virtualDeviceID: string]: boolean } = {};
        const comparedAccountID: string = this.accountSvc.isEnterprise() ? this.accountSvc.enterpriseAccountID : this.accountSvc.accountID;
        
        devices.forEach((d: DeviceInfo) => {
            sharePermissionMap[d.virtualId] = false;
            if (d.virtualDeviceOwnerID == comparedAccountID) {
                //you have all permissions for your own device
                sharePermissionMap[d.virtualId] = true;
            }
            else {
                //device which is shared from other user does not have share permission
                if (queryPermissionType === 'Share') {
                    sharePermissionMap[d.virtualId] = false;
                }
                else if (d.virtualDeviceSharing && d.virtualDeviceSharing[this.accountSvc.accountID] && d.virtualDeviceSharing[this.accountSvc.accountID].permission.find(p => p == queryPermissionType)) {
                    sharePermissionMap[d.virtualId] = true;
                }
            }
        });

        return sharePermissionMap;
    }

    protected submit(): void {
        this._resultErrorList = [];
        this._errorMessage = null;
    }

    protected doCustomAction(): void {

    }

    protected close(): void {
        setTimeout(() => {
            this.reset();
        }, 0);
    }

    protected cancel(): void {
        setTimeout(() => {
            this.reset();
        }, 0);
    }

    protected reset(): void {
        this._devices = [];
        this._legalDevices = [];
        this._licenseForbiddenDevices = [];
        this._shareForbiddenDevices = [];
        this._offlineForbiddenDevices = [];
        this._bSpecific = false;

        this._page = ConfigDialogPage.checking;
        this._errorMessage = null;
        this._monitorDeviceNames = null;
        this._actionData = {};

        this.clearDynamicChildDialog();
    }

    allowGoNext(): boolean {
        return (!this._loading && this._page === ConfigDialogPage.checking) || this._page === ConfigDialogPage.action;
    }

    allowGoBack(): boolean {
        return (this._page === ConfigDialogPage.action && !this._bSpecific) || this._page === ConfigDialogPage.confirm;
    }

    allowClose(): boolean {
        return this._page === ConfigDialogPage.result;
    }

    allowCancel(): boolean {
        return (!this._loading && this._page === ConfigDialogPage.checking) || this._page === ConfigDialogPage.action || this._page === ConfigDialogPage.confirm
    }

    allowCustomAction(): boolean {
        return false;
    }

    protected isCustomActionValid(): boolean {
        return true;
    }

    protected isPageValid(page: ConfigDialogPage): boolean {
        if (this._loading) {
            return false;
        }

        if (page === ConfigDialogPage.checking) {
            if (this._legal_device_count === 0) {
                return false;
            }
        }
        else if (page === ConfigDialogPage.action) {
            return this._bActionStatusValid;
        }

        return true;
    }

    private is_all_device_select(): boolean {
        if (!this._legalDevices) {
            return false;
        }

        if (this._legalDevices.length === 0) {
            return false;
        }

        for (const d of this._legalDevices) {
            if (!d.isSelect) {
                return false;
            }
        }

        return true;
    }

    selectAllDevice(e: any): void {
        this._legalDevices.forEach(d => d.isSelect = e.target.checked);
        this.reset_legal_device_count();
    }

    selectDevice(device: DeviceInfo): void {
        device.isSelect = !device.isSelect;
        this.reset_legal_device_count();
    }

    private reset_legal_device_count(): void {
        this._legal_device_count = this._legalDevices.filter(d => d.isSelect).length;
    }

    protected action_page_complete(result: any): void {
        this._actionData = Object.assign({}, this._actionData, result);
    }

    protected action_page_status_change(isValid: boolean): void {
        this._bActionStatusValid = isValid;
    }

    protected action_page_communicate(data: any): void {

    }

    private updateAllowSubmit(allow: boolean): void {
        this._allowSubmit = allow;
    }

    private playFuncAction(dialogName: string): void {
        const devFuncActionItem: DevFuncActionItem = this.devFuncActionSvc.getItemByName(dialogName);
        if (devFuncActionItem) {
            const viewContainerRef = this.devFuncActionHost.viewContainerRef;
            viewContainerRef.clear();

            const componentRef = viewContainerRef.createComponent(devFuncActionItem.component);

            (<DevFuncActionInterface>componentRef.instance).actionData = this._actionData;
            (<DevFuncActionInterface>componentRef.instance).actionCompleteHandler = this.action_page_complete.bind(this);
            (<DevFuncActionInterface>componentRef.instance).actionStatusUpdateHandler = this.action_page_status_change.bind(this);
            (<DevFuncActionInterface>componentRef.instance).actionCommunicateHandler = this.action_page_communicate.bind(this);
        }
    }

    private playFuncConfirm(dialogName: string): void {
        const devFuncConfirmItem: DevFuncConfirmItem = this.devFuncConfirmSvc.getItemByName(dialogName);
        if (devFuncConfirmItem) {
            const viewContainerRef = this.devFuncConfirmHost.viewContainerRef;
            viewContainerRef.clear();

            const componentRef = viewContainerRef.createComponent(devFuncConfirmItem.component);

            (<DevFuncConfirmInterface>componentRef.instance).actionData = this._actionData;
            (<DevFuncConfirmInterface>componentRef.instance).allowSubmitCallback = this.updateAllowSubmit.bind(this);
        }
    }

    private clearDynamicChildDialog(): void {
        const viewActionContainerRef = this.devFuncActionHost.viewContainerRef;
        if (viewActionContainerRef) {
            viewActionContainerRef.clear();
        }

        const viewConfirmContainerRef = this.devFuncConfirmHost.viewContainerRef;
        if (viewConfirmContainerRef) {
            viewConfirmContainerRef.clear();
        }
    }
}