import { HttpClient } from "@angular/common/http";
import { APIWrapperBase } from "../../../../app/API/api.wrapper.base";
import { APIAssignDeviceGroupToEnterpriseAccountGroupManager } from "./account/api.ent.account-group.assignDeviceGroup";
import { APICreateEnterpriseAccountGroupManager } from "./account/api.ent.account-group.create";
import { APIListEnterpriseAccountGroupManager } from "./account/api.ent.account-group.list";
import { APIRemoveEnterpriseAccountGroupManager } from "./account/api.ent.account-group.remove";
import { APIUpdateEnterpriseAccountGroupManager } from "./account/api.ent.account-group.update";
import { APIGetEnterpriseAccountDetailManager } from "./account/api.ent.account.getDetail";
import { APIInviteEnterpriseAccountManager } from "./account/api.ent.account.invite";
import { APIListEnterpriseAccountManager } from "./account/api.ent.account.list";
import { APIListEnterpriseDeviceGroupManager } from "./account/api.ent.account.listDeviceGroup";
import { APIRemoveEnterpriseAccountManager } from "./account/api.ent.account.remove";
import { APIUpdateEnterpriseAccountManager } from "./account/api.ent.account.update";
import { APIApplyEnterpriseManager } from "./api.enterprise.apply";
import { APIGetEnterpriseDetailInfoManager } from "./api.enterprise.detail.get";
import { APISetEnterpriseCustomizeBackgroundManager } from "./customization/api.ent.customize-bg.set";
import { APISetEnterpriseCustomizeLogoManager } from "./customization/api.ent.customize-logo.set";
import { APIEnterpriseCreateScepManager } from "./scep/api.ent.scep.create";
import { APIEnterpriseListScepManager } from "./scep/api.ent.scep.list";
import { APIEnterpriseRemoveScepManager } from "./scep/api.ent.scep.remove";
import { APIEnterpriseUpdateScepManager } from "./scep/api.ent.scep.update";

export class APIEnterpriseWrapper extends APIWrapperBase {
    GetEnterpriseDetailInfo: APIGetEnterpriseDetailInfoManager;
    ApplyEnterprise: APIApplyEnterpriseManager;

    ListEnterpriseAccount: APIListEnterpriseAccountManager;
    GetEnterpriseAccountDetail: APIGetEnterpriseAccountDetailManager;
    RemoveEnterpriseAccount: APIRemoveEnterpriseAccountManager;
    InviteEnterpriseAccount: APIInviteEnterpriseAccountManager;
    UpdateEnterpriseAccount: APIUpdateEnterpriseAccountManager;

    CreateEnterpriseAccountGroup: APICreateEnterpriseAccountGroupManager;
    RemoveEnterpriseAccountGroup: APIRemoveEnterpriseAccountGroupManager;
    UpdateEnterpriseAccountGroup: APIUpdateEnterpriseAccountGroupManager;
    ListEnterpriseAccountGroup: APIListEnterpriseAccountGroupManager;
    AssignDeviceGroupToEnterpriseAccountGroup: APIAssignDeviceGroupToEnterpriseAccountGroupManager;

    ListEnterpriseDeviceGroup: APIListEnterpriseDeviceGroupManager;

    CreateScep: APIEnterpriseCreateScepManager;
    ListScep: APIEnterpriseListScepManager;
    UpdateScep: APIEnterpriseUpdateScepManager;
    RemoveScep: APIEnterpriseRemoveScepManager;

    SetLogo: APISetEnterpriseCustomizeLogoManager;
    SetBackground: APISetEnterpriseCustomizeBackgroundManager;

    constructor(private http: HttpClient) {
        super();

        this.GetEnterpriseDetailInfo = new APIGetEnterpriseDetailInfoManager(http);
        this.ApplyEnterprise = new APIApplyEnterpriseManager(http);

        this.ListEnterpriseAccount = new APIListEnterpriseAccountManager(http);
        this.GetEnterpriseAccountDetail = new APIGetEnterpriseAccountDetailManager(http);
        this.RemoveEnterpriseAccount = new APIRemoveEnterpriseAccountManager(http);
        this.InviteEnterpriseAccount = new APIInviteEnterpriseAccountManager(http);
        this.UpdateEnterpriseAccount = new APIUpdateEnterpriseAccountManager(http);

        this.CreateEnterpriseAccountGroup = new APICreateEnterpriseAccountGroupManager(http);
        this.RemoveEnterpriseAccountGroup = new APIRemoveEnterpriseAccountGroupManager(http);
        this.UpdateEnterpriseAccountGroup = new APIUpdateEnterpriseAccountGroupManager(http);
        this.ListEnterpriseAccountGroup = new APIListEnterpriseAccountGroupManager(http);
        this.AssignDeviceGroupToEnterpriseAccountGroup = new APIAssignDeviceGroupToEnterpriseAccountGroupManager(http);
    
        this.ListEnterpriseDeviceGroup = new APIListEnterpriseDeviceGroupManager(http);

        this.CreateScep = new APIEnterpriseCreateScepManager(http);
        this.ListScep = new APIEnterpriseListScepManager(http);
        this.UpdateScep = new APIEnterpriseUpdateScepManager(http);
        this.RemoveScep = new APIEnterpriseRemoveScepManager(http);

        this.SetLogo = new APISetEnterpriseCustomizeLogoManager(http);
        this.SetBackground = new APISetEnterpriseCustomizeBackgroundManager(http);
    }

    public resetServerConfig(protocol: string, baseUrl: string, port: number): void {
        let targets = [
            this.GetEnterpriseDetailInfo,
            this.ApplyEnterprise,

            this.ListEnterpriseAccount,
            this.GetEnterpriseAccountDetail,
            this.RemoveEnterpriseAccount,
            this.InviteEnterpriseAccount,
            this.UpdateEnterpriseAccount,

            this.CreateEnterpriseAccountGroup,
            this.RemoveEnterpriseAccountGroup,
            this.UpdateEnterpriseAccountGroup,
            this.ListEnterpriseAccountGroup,
            this.AssignDeviceGroupToEnterpriseAccountGroup,

            this.ListEnterpriseDeviceGroup,

            this.CreateScep,
            this.ListScep,
            this.UpdateScep,
            this.RemoveScep,

            this.SetLogo,
            this.SetBackground
        ];

        targets.forEach(t => t.resetServerConfig(protocol, baseUrl, port));
    }
}