import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { switchMap, takeUntil, map } from 'rxjs/operators';

import { IClass } from '../../../lib/common/common.data';
import { PolicyInfo, PolicyType } from './policy.data';
import { PolicyFuncDirective } from './policy-func.directive';
import { PolicyFuncItem, PolicyFuncService, IPolicyFunc, POLICY_FUNC_CREATE, POLICY_FUNC_EDIT, POLICY_FUNC_REMOVE } from './policy-func.service';
import { PolicyService } from './policy.service';
import { AccountService } from '../../../../app/entry/account.service';
import { HelperLib } from 'app/lib/common/helper.lib';

@Component({
    templateUrl: './policy-overview.component.html',
    styleUrls: ['./policy.style.css']
})
export class PolicyOverviewComponent implements OnInit, OnDestroy, IClass {
    className: string;
    _loading: boolean = false;
    private _allUnsubscribe: Subject<void> = new Subject();

    _policy: PolicyInfo;
    _policyCategoryMap: { [type: string]: { policyList: PolicyInfo[], displayName: string, expand: boolean } } = {};
    _policyCount: number = 0;
    _enumPolicyType: typeof PolicyType = PolicyType;

    private _policyFuncHost: PolicyFuncDirective;
    @ViewChild(PolicyFuncDirective, { static: true })
    set policyFuncHost(host: any) {
        if (host) {
            this._policyFuncHost = host;
        }
    }

    constructor(
        private route: ActivatedRoute,
        private accountSvc: AccountService,
        private policySvc: PolicyService,
        private policyFuncSvc: PolicyFuncService) {
    }

    ngOnInit(): void {
        this.policySvc.policyUpdated.pipe(
            takeUntil(this._allUnsubscribe)
        ).subscribe((p: PolicyInfo) => {
            if (this._policy && this._policy.id === p.id) {
                this._policy = null;
                setTimeout(() => {
                    //for update the policy-report view
                    this._policy = p;
                }, 0);
            }
        });

        this._loading = true;
        this.route.paramMap.pipe(
            switchMap((params: ParamMap) => this._refreshPolicyList().pipe(
                map((policyList: PolicyInfo[]) => {
                    this._policy = params.get('id') ? policyList.find(p => p.id === params.get('id')) : null;
                    return;
                })
            )),
            takeUntil(this._allUnsubscribe)
        ).subscribe(() => {
            this.refreshPolicyList(false);
            this._loading = false;
        });
    }

    ngOnDestroy(): void {
        this._allUnsubscribe.next();
        this._allUnsubscribe.unsubscribe();
    }

    supportPolicyUpdate(): boolean {
        return this.accountSvc.hasScope_devicePolicy_update();
    }

    refreshPolicyList(force: boolean = false): void {
        this._policyCategoryMap = {};
        this._loading = true;

        this._refreshPolicyList(force).subscribe(() => this._loading = false);
    }

    private _refreshPolicyList(force: boolean = false): Observable<PolicyInfo[]> {
        return this.policySvc.getPolicyList(force).pipe(
            map((policyList: PolicyInfo[]) => {
                this._policyCategoryMap = policyList.reduce<{ [type: string]: { policyList: PolicyInfo[], displayName: string, expand: boolean } }>((prev, p: PolicyInfo) => {
                    prev[p.type] = prev[p.type] || { policyList: [], displayName: HelperLib.splitUpperWord(p.type), expand: true };
                    prev[p.type].policyList.push(p);

                    return prev;
                }, {});

                this._policyCount = policyList.length;

                return policyList;
            })
        );
    }

    expandCategory(category: { policyList: PolicyInfo[], expand: boolean }, expand: boolean): void {
        category.expand = expand;
    }

    createPolicy(): void {
        this.createPolicyForm(POLICY_FUNC_CREATE);
    }

    selectPolicy(p: PolicyInfo): void {
        this._policy = p;
    }

    editPolicy(): void {
        this.createPolicyForm(POLICY_FUNC_EDIT, this._policy.copy());
    }

    removePolicy(): void {
        this.createPolicyForm(POLICY_FUNC_REMOVE, this._policy);
    }

    private dlgCompleteFunc(ret: { func: string, errorMessage?: string, data?: any }): void {
        this._policy = null;
        if (!ret.errorMessage) {
            this.refreshPolicyList();
        }
    }

    private createPolicyForm(funcName: string, p?: PolicyInfo): void {
        const item: PolicyFuncItem = this.policyFuncSvc.getItemByName(funcName);
        if (item) {
            const viewContainerRef = this._policyFuncHost.viewContainerRef;
            viewContainerRef.clear();

            const componentRef = viewContainerRef.createComponent(item.component);

            (<IPolicyFunc>componentRef.instance).title = item.title;
            (<IPolicyFunc>componentRef.instance).policy = p;
            (<IPolicyFunc>componentRef.instance).dialogCompleteHandler = this.dlgCompleteFunc.bind(this);
        }
    }
}