import { Pipe, PipeTransform } from '@angular/core';

import { TextLib } from '../common/text.lib';

@Pipe({
    name: 'capitalAllWords'
})
export class CapitalAllWordsPipe implements PipeTransform {
    transform(value: string, ...args: string[]): any {
        return TextLib.capitalAllWords(value);
    }
}