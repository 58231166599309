import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ContentComponent } from './content.component';
import { ContentRoutingModule } from './content-routing.module';
import { DeviceModule } from './device/device.module';
import { SettingModule } from './setting/setting.module';
import { TroubleshootModule } from './troubleshoot/troubleshoot.module';
import { LicenseModule } from './license/license.module';
import { TranslateModule } from '../translate/translate.module';
import { CustomPipeModule } from '../lib/pipes/custom-pipe.module';
import { ActivityModule } from './event/activity/activity.module';
import { ConstantService } from '../../app/lib/common/constant.service';
import { UIElementModule } from '../uiElement/uiElement.module';
import { UserPreferenceService } from './user-preference.service';
import { EventFeedModule } from './event/event-feed.module';
import { TopMenuViewComponent } from './menu.component';
import { TopMenuService } from './menu.service';
import { AdminModule } from './admin/admin.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ContentRoutingModule,

        DeviceModule,
        SettingModule,
        TroubleshootModule,
        LicenseModule,
        ActivityModule,
        EventFeedModule,
        AdminModule,

        TranslateModule,
        CustomPipeModule,

        UIElementModule
    ],
    declarations: [
        ContentComponent,
        TopMenuViewComponent
    ],
    providers: [
        ConstantService,
        UserPreferenceService,
        TopMenuService
    ]
})
export class ContentModule {}