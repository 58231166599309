import { Component, OnInit } from '@angular/core';
import { IUserDlgFuncContent } from '../user-dlg-func.def';
import { UserInfo } from '../../user.data';
import { UserService } from '../../user.service';
import { UserGroupInfo } from '../../group/user-group.data';

@Component({
    templateUrl: './user-dlg-changeGroup-content.component.html',
    styleUrls: ['../../user.style.css']
})
export class UserChangeGroupDlgContentComponent implements IUserDlgFuncContent<UserInfo[], { from?: UserGroupInfo, to?: UserGroupInfo }>, OnInit {
    funcName: string;
    other?: { from?: UserGroupInfo, to?: UserGroupInfo } = {};
    data: UserInfo[];
    onActionPrepared: () => void;
    onActionValidChanged: (valid: boolean) => void;

    _isToUngroup: boolean;
    _isFixTargetGroup: boolean;

    constructor(private userSvc: UserService) { }

    ngOnInit(): void {
        if (this.other.to) {
            this._isFixTargetGroup = true;
            if (this.other.to.id === this.userSvc.defaultUserGroupID) {
                this._isToUngroup = true;
            }
        }

        this.updateDataValidity();
    }

    onUserGroupChosen(group: UserGroupInfo): void {
        this.other.to = group;
        this.updateDataValidity();
    }

    private updateDataValidity(): void {
        this.onActionValidChanged(this.other.to && this.other.to.id && this.other.to.id !== this.other.from.id ? true : false);
    }
}