import { Component } from '@angular/core';

import { BaseConfigFormComponent } from '../base/base-config-form.component';
import { DIALOG_NAME_ISSUE_REPORT, ConfigDialogPage } from '../base/base-config-data';
import { DevFuncActionService } from '../base/action/dev-func-action.service';
import { DevFuncConfirmService } from '../base/confirm/dev-func-confirm.service';
import { TroubleshootService } from '../../troubleshoot/troubleshoot.service';
import { DeviceService } from '../../device/device.service';
import { LicenseScopeType } from '../../license/license.data';
import { SharePermissionType } from '../../../lib/common/helper.lib';
import { LicenseService } from '../../license/license.service';
import { ITicketQuestionnaire } from '../../../API/v1/Ticket/api.ticket.common';
import { Logger } from '../../../lib/common/logger';
import { APKService } from '../apk/apk.service';
import { AccountService } from '../../../entry/account.service';
import { ConstantService } from '../../../../app/lib/common/constant.service';
import { NAService } from '../../../API/na.service';
import { TroubleshootMode } from './troubleshoot.data';
import { report } from 'process';

@Component({
    templateUrl: '../base/base-config-form.component.html',
    styleUrls: ['../base/base-config-form.component.css']
})
export class IssueFormComponent extends BaseConfigFormComponent {
    private readonly DEBUG_DEVICE_LIMIT: number = 5;

    constructor(
        protected devFuncActionSvc: DevFuncActionService,
        protected devFuncConfirmSvc: DevFuncConfirmService,
        protected accountSvc: AccountService,
        protected naSvc: NAService,
        protected licenseSvc: LicenseService,
        protected constantSvc: ConstantService,
        protected devSvc: DeviceService,
        protected apkSvc: APKService,
        private troubleshootSvc: TroubleshootService
    ) {
        super(devFuncActionSvc, devFuncConfirmSvc, accountSvc, naSvc, licenseSvc, constantSvc, devSvc, apkSvc);
    }

    ngOnInit(): void {
        this.className = 'Issue-form';
        this._dialogName = DIALOG_NAME_ISSUE_REPORT;
        this._licenseScopeType = LicenseScopeType.remoteAssistance;
        this._sharePermissionType = SharePermissionType.Troubleshoot;

        super.ngOnInit();
    }

    protected isPageValid(page: ConfigDialogPage): boolean {
        if (!super.isPageValid(page)) {
            return false;
        }

        if (this._legal_device_count > this.DEBUG_DEVICE_LIMIT) {
            return false;
        }

        return true;
    }

    protected submit(): void {
        super.submit();

        if (!this._actionData) {
            Logger.logError(this.className, 'submit', 'No valid data');
            this._errorMessage = 'No valid data';
            return;
        }

        if (this._actionData.issueMode === TroubleshootMode.Unknown) {
            Logger.logError(this.className, 'submit', 'Mode should not be "Unknown"');
            this._errorMessage = 'Mode should not be "Unknown"';
            return;
        }

        const reportData: {
            ticketSubject?: string,
            ticketBody?: string,
            questionnaire?: ITicketQuestionnaire,
            ignoreZendesk?: boolean,
            receiverList?: string[]
        } = {
            ticketSubject: this._actionData.issueTitle,
            ticketBody: this._actionData.issueContent || ''
        };

        switch (this._actionData.issueMode) {
            case TroubleshootMode.FAESupport:
                {
                    reportData.questionnaire = {
                        symptom: this._actionData.issueSymptom,
                        frequency: this._actionData.issueFrequency
                    };
                    reportData.ignoreZendesk = false;
                    reportData.receiverList = [];
                }
                break;
            case TroubleshootMode.Email:
                {
                    reportData.ignoreZendesk = true;
                    reportData.receiverList = this._actionData.issueEmailList;
                }
                break;
        }

        this.troubleshootSvc.report(this._bSpecific ? this._legalDevices : this._legalDevices.filter(d => d.isSelect), reportData).subscribe(res => {
            ///do something
            this._page++;
            this._result_msg_success = "Your form has been sent. We'll contact you soon";
            if (res.error !== 0) {
                this._errorMessage = res.error ? '( ' + res.error + ' )' : '' + res.errorMessage;
            }
        });
    }
}