import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';

@Component({
    selector: 'na-debug',
    templateUrl: './debug.component.html',
    styleUrls: ['./debug.component.css']
})
export class DebugComponent implements OnInit {
    _showBorder: boolean = false;
    @Input()
    set showBorder(v: boolean) {
        this._showBorder = v;
    }

    _debug_usb_enabled: boolean = false;
    @Input('debugUSB')
    set usb(v: boolean) {
        if (this._debug_usb_enabled !== v) {
            this._debug_usb_enabled = v;
            if (!this._debug_usb_enabled) {
                this._debug_tcp_enabled = false;
            }
        } 
    }

    _debug_tcp_enabled: boolean = false;
    @Input('debugTCP')
    set tcp(v: boolean) {
        if (this._debug_tcp_enabled !== v && this._debug_usb_enabled) {
            this._debug_tcp_enabled = v;
        }  
    }

    @Output() onDebugModeChanged = new EventEmitter<{usb: boolean, tcp: boolean}>();

    ngOnInit(): void {
        if (!this._debug_usb_enabled) {
            this._debug_tcp_enabled = false;
        }
    }

    switchUSBDebug(v: boolean): void {
        if (this._debug_usb_enabled !== v) {
            this._debug_usb_enabled = v;
            if (!this._debug_usb_enabled) {
                this._debug_tcp_enabled = false;
            }
            this.onDebugModeChanged.emit({usb: this._debug_usb_enabled, tcp: this._debug_tcp_enabled});
        }
    }

    switchTCPDebug(v: boolean): void {
        if (this._debug_tcp_enabled !== v) {
            this._debug_tcp_enabled = v;
            this.onDebugModeChanged.emit({usb: this._debug_usb_enabled, tcp: this._debug_tcp_enabled});
        }
    }
}