import { DatePipe } from '@angular/common';
import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { fromEvent, Subject, Observable, EMPTY, of } from 'rxjs';
import { debounceTime, takeUntil, expand, toArray, map, concatMap } from 'rxjs/operators';

import { SortType } from '../../../lib/common/common.data';
import { EventLogEventFilterData, EventLogInfo, EventLogLevelCode, EventLogSeverityFilterData, EventLogTimeFilterData } from './eventLog.data';
import { EventLogService } from './eventLog.service'; ''
import { UserPreferenceService } from '../../user-preference.service';
import { AccountService } from '../../../entry/account.service';
import { Logger } from '../../../lib/common/logger';
import { HelperLib } from '../../../lib/common/helper.lib';
import { ConstantService } from '../../../lib/common/constant.service';

@Component({
    templateUrl: './log-overview.component.html',
    styleUrls: ['./log-overview.component.css']
})
export class EventLogOverviewComponent implements OnInit, OnDestroy {
    _loading: boolean = false;
    _loadingLink: boolean = false;
    _exporting: boolean = false;
    private _allUnsubscribe: Subject<void> = new Subject();

    _tableHeaderList: { key: string, displayName: string }[] = [];
    _eventLogLinkLoadStatusMap: { [eventID: string]: boolean } = {};

    _currentSortKey: string;
    _currentSortType: SortType = SortType.descend;
    _enumSortType: typeof SortType = SortType;
    _enumEventLogLevelCode: typeof EventLogLevelCode = EventLogLevelCode;
    _currentPage: number = 1;

    _eventLogList: EventLogInfo[] = [];
    _total: number = 0;

    _severityFilter: EventLogSeverityFilterData;
    _timeFilter: EventLogTimeFilterData;
    _eventFilter: EventLogEventFilterData;
    _searchText: string;
    _searchEventItemText: string;

    _errorMessage: string;

    private _searchSubjectRef: ElementRef;
    @ViewChild('searchSubject', { static: true })
    set searchSubject(v: ElementRef) {
        if (v) {
            this._searchSubjectRef = v;
            const searchElement = this._searchSubjectRef.nativeElement;
            const searchInputOb = fromEvent(searchElement, 'input');

            searchInputOb.pipe(
                debounceTime(200),
                takeUntil(this._allUnsubscribe)
            ).subscribe((e: any) => {
                this._searchText = e.target.value ? e.target.value.toLocaleLowerCase() : null;
            });
        }
    }

    private _searchEventItemRef: ElementRef;
    @ViewChild('searchEventItem', { static: true })
    set searchEventItem(v: ElementRef) {
        if (v) {
            this._searchEventItemRef = v;
            const searchElement = this._searchEventItemRef.nativeElement;
            const searchInputOb = fromEvent(searchElement, 'input');

            searchInputOb.pipe(
                debounceTime(200),
                takeUntil(this._allUnsubscribe)
            ).subscribe((e: any) => {
                this._searchEventItemText = e.target.value;
                this._eventFilter.search(this._searchEventItemText);
            });
        }
    }

    private _exportLinkRef: ElementRef<HTMLLinkElement>;
    @ViewChild('exportLink', { static: true })
    set exportLink(v: ElementRef) {
        if (v) {
            this._exportLinkRef = v;
        }
    }

    constructor(private datePipe: DatePipe, private router: Router, private accountSvc: AccountService, private eventLogSvc: EventLogService, private userPrefSvc: UserPreferenceService, private constantSvc: ConstantService) {
        this._tableHeaderList = [
            { key: 'date', displayName: 'Occurrance time' },
            { key: 'category', displayName: 'Category' },
            { key: 'event', displayName: 'Event' },
            { key: 'subject', displayName: 'Subject' }
        ];
    }

    ngOnInit(): void {
        this._loading = true;
        of(true).pipe(
            concatMap(() => this.eventLogSvc.getEventStructure())
        ).subscribe((structure: { [category: string]: { [event: string]: string } }) => {
            //show latest result temporarily.
            this._eventFilter = new EventLogEventFilterData(structure); //, this.userPrefSvc.userPreference.eventLog.filter.event);
            this._severityFilter = new EventLogSeverityFilterData(); //this.userPrefSvc.userPreference.eventLog.filter.level);
            this._timeFilter = new EventLogTimeFilterData(this.datePipe); //, this.userPrefSvc.userPreference.eventLog.filter.time);

            this.applySearch(false);
        });
    }

    ngOnDestroy(): void {
        this._allUnsubscribe.next();
        this._allUnsubscribe.complete();
    }

    startEventFilter(): void {
        this._eventFilter.startFilter();
        this._eventFilter.search(this._searchEventItemText);
    }

    keyDown(keyCode: number): void {
        if (keyCode === this.constantSvc.KEYCODE_RETURN) {
            this.applySearch();
        }
    }

    applySearch(force: boolean = true, page: number = this._currentPage - 1, limit: number = 50): void {
        this._loading = true;
        this._errorMessage = null;

        const userPrefLevelFilter: { [levelName: string]: boolean } = this._severityFilter.applyFilter();
        const userPrefTimeFilter: { beginDate: string, beginTime: string, endDate: string, endTime: string } = this._timeFilter.applyFilter();
        const userPrefEventFilter: { [categoryName: string]: { [eventName: string]: boolean } } = this._eventFilter.applyFilter();

        this.query(force, page * limit, limit).subscribe((result: { data: EventLogInfo[], limit?: number, page?: number, total?: number, errorMessage?: string }) => {
            this._loading = false;
            this._eventLogList = result.data || [];
            this._total = result.total || this._eventLogList.length;
            this._errorMessage = result.errorMessage;

            if (!this._errorMessage) {
                this.userPrefSvc.changeEventLogFilter(userPrefLevelFilter, userPrefTimeFilter, userPrefEventFilter);
            }
        });
    }

    linkToDeviceTaskActivity(eventID: string): void {
        if (this._eventLogLinkLoadStatusMap[eventID]) {
            return;
        }

        this._eventLogLinkLoadStatusMap[eventID] = true;
        this.eventLogSvc.getDeviceTaskEventLogLink(eventID).subscribe((link: string) => {
            this._eventLogLinkLoadStatusMap[eventID] = false;
            if (link) {
                this.router.navigate([link]);
            }
        });
    }

    refresh(): void {
        this.applySearch();
    }

    export(): void {
        this._exporting = true;

        let page: number = 0;
        let retry: number = 0;
        const $query = this.query(true, page, 300);
        $query.pipe(
            expand((result: { data: EventLogInfo[], limit?: number, page?: number, total?: number, errorMessage?: string }) => {
                Logger.logInfo('EventLog', 'Export', 'Export retry ' + ++retry);

                if (!result.limit) {
                    //error occurs,
                    return EMPTY;
                }
                if (result.data.length < result.limit) {
                    return EMPTY;
                }

                return this.query(true, ++page, 300);
            }),
            toArray(),
            map((res: { data: EventLogInfo[], limit?: number, page?: number, total?: number, errorMessage?: string }[]) => {
                const date: string = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH_mm_ss');
                const rowList: string[][] = [];
                rowList.push(['Occurance time', 'Category', 'Severity', 'Event', 'Subject']);
                res.forEach((r: { data: EventLogInfo[], limit?: number, page?: number, total?: number, errorMessage?: string }) => {
                    if (r.data) {
                        r.data.forEach((e: EventLogInfo) => {
                            rowList.push([
                                e.dateTime ? this.datePipe.transform(e.dateTime, 'yyyy-MM-dd HH_mm_ss') : 'Unknown',
                                '"' + e.category + '"',
                                e.level,
                                '"' + e.event + '"',
                                '"' + e.subject + '"'
                            ]);
                        });
                    }
                });
    
                return HelperLib.downloadCsv(this._exportLinkRef.nativeElement, 'EventLog-' + this.accountSvc.accountName + '-' + date, null, rowList);
            })
        ).subscribe(() => {
            this._exporting = false;
        });
    }

    private query(force: boolean, skip: number, limit: number): Observable<{ data: EventLogInfo[], limit?: number, page?: number, total?: number, errorMessage?: string }> {
        const levelList: string[] = this._severityFilter.getFilterData();
        const timeData: { begin: string, end: string } = this._timeFilter.getFilterData();
        const categoryEventData: { categoryList: string[], eventList: string[] } = this._eventFilter.getFilterData();

        return this.eventLogSvc.searchEventLogs(
            force,
            timeData.begin,
            timeData.end,
            levelList,
            categoryEventData.categoryList,
            categoryEventData.eventList,
            this._searchText,
            skip,
            this._currentSortKey && this._currentSortType ? { key: this._currentSortKey, sortType: this._currentSortType } : null,
            limit
        );
    }

    sortDescend(key: string): void {
        let toSearch: boolean = false;
        if (this._currentSortKey !== key) {
            this._currentSortKey = key;
            toSearch = true;
        }

        if (this._currentSortType !== SortType.descend) {
            this._currentSortType = SortType.descend;
            toSearch = true;
        }

        if (toSearch) {
            this.applySearch();
        }
    }

    sortAscend(key: string): void {
        let toSearch: boolean = false;
        if (this._currentSortKey !== key) {
            this._currentSortKey = key;
            toSearch = true;
        }

        if (this._currentSortType !== SortType.ascend) {
            this._currentSortType = SortType.ascend;
            toSearch = true;
        }

        if (toSearch) {
            this.applySearch();
        }
    }

    onPageChange(page: number): void {
        if (this._currentPage !== page) {
            this._currentPage = page;
            this.applySearch();
        }
    }
}