import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../../api.base';
import { GroupRawInfo } from './api.group.common';

export class APIListGroupManager extends APIBaseManager<void, void, void, { [accountName: string]: { [groupID: string]: GroupRawInfo } }> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_ListDevGroup';
        this._apiMethod = 'GET';
    }

    protected getRequestURL(pathParameters: void, queryParameters: void): string {
        return super.getRequestURL() + 'deviceGroups';
    }
}