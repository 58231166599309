import { IVirtualDeviceCalendarItem } from "../../VirtualDevice/virtualDevice.common";

export class PolicyRawInfoContent {
    Sharing?: {
        sharingList: {
            accountName: string;
            permission: string[]
        }[]
    };
    Task?: {
        [settingKey: string]: { resourceData: any };
    };
    Calendar?: {
        calendarList: IVirtualDeviceCalendarItem[];
    };
    Application?: {
        applicationList: {
            name: string,
            version: string,
            components: {
                base: {
                    type: string;
                    link: string;
                    checksum?: string;
                    checksumAlgorithm?: string;
                    mimeType?: string;
                    packageName?: string;
                    className?: string;
                    action?: string;
                    versionCode?: string;
                    versionName?: string;
                    allowUninstall?: boolean;
                    splits?: string[];
                },
                [splitKey: string]: {
                    type: string;
                    link: string;
                    checksum?: string;
                    checksumAlgorithm?: string;
                    mimeType?: string;
                    packageName?: string;
                    className?: string;
                    action?: string;
                    versionCode?: string;
                    versionName?: string;
                    allowUninstall?: boolean;
                    splits?: string[];
                }
            }
        }[];
    }
}

export class PolicyRawInfo {
    isUsed?: boolean;
    policyName: string;
    policyType: string;
    policyID: string;
    policyContent: PolicyRawInfoContent;
}