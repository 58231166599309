import { Pipe, PipeTransform } from '@angular/core';

import { TextLib } from '../common/text.lib';

@Pipe({
    name: 'capitalFirstWord'
})
export class CapitalFirstWordPipe implements PipeTransform {
    transform(value: string, ...args: string[]): any {
        return TextLib.capitalFirstWord(value);
    }
}