import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { AccessKeyInfo } from '../../../../API/v1/Account/api.accessKey.common';
import { DialogPage } from '../../../../lib/common/common.data';
import { IAccessKeyFunc } from '../accessKey-func.service';
import { AccessKeyService } from '../accessKey.service';

@Component({
    templateUrl: './accessKey-inactive.component.html',
    styleUrls: ['../accessKey.style.css']
})
export class AccessKeyInactiveComponent implements OnInit, OnDestroy, IAccessKeyFunc {
    title: string;
    key: AccessKeyInfo;
    dialogCompleteHandler?: (result: any) => void;

    _page: DialogPage = DialogPage.action;
    _enumDialogPage: typeof DialogPage = DialogPage;
    _loading: boolean;
    _inactiveResult: { isFault: boolean, errorMessage?: string };

    constructor(private accKeySvc: AccessKeyService) {

    }

    ngOnInit(): void {

    }
    
    ngOnDestroy(): void {

    }

    inactiveAcc(): void {
        this._loading = true;
        this.accKeySvc.updateAccessKey(this.key, false).subscribe((res: { isFault: boolean, key: AccessKeyInfo, errorMessage?: string }) => {
            this._inactiveResult = {
                isFault: res.isFault,
                errorMessage: res.errorMessage
            };

            this._page = DialogPage.result;
            this._loading = false;
        });
    }
}