import { Component, Input, Output, EventEmitter } from '@angular/core';
import { of as observableOf } from 'rxjs';
import { concatMap } from 'rxjs/operators';

import { DeviceInfo, DeviceActionInfo, TaskStatus } from '../data/device-info';
import { DeviceService } from '../device.service';
import { LicenseService } from '../../license/license.service';
import { LicenseScopeType } from '../../license/license.data';
import { HelperLib, REFRESH_DURATION } from '../../../lib/common/helper.lib';
import { ConstantService } from '../../../lib/common/constant.service';

@Component({
    selector: 'na-device-activity',
    templateUrl: './dev-activity-md.component.html',
    styleUrls: ['./dev-activity-md.component.css']
})
export class DeviceActivityMDComponent {
    private _refreshCountdownMap: { [virtualDeviceID: string]: number } = {};

    _device: DeviceInfo;
    @Input('device')
    set device(dev: DeviceInfo) {
        if (dev && this._device !== dev) {
            this._device = dev;
            this._task = null;
            this.refreshDeviceTask();
        }
    }

    _showLastTrackTime: boolean = true;
    @Input('showTrackTime')
    set showTrackTime(v: boolean) {
        this._showLastTrackTime = v === undefined ? true : v;
    }

    _allowRefresh: boolean = true;
    @Input('allowRefresh')
    set allowRefresh(v: boolean) {
        this._allowRefresh = v === undefined ? true : v;
    }

    _allowClose: boolean = false;
    @Input('allowClose')
    set allowClose(v: boolean) {
        this._allowClose = v === undefined ? false : v;
    }

    _showBorder: boolean = true;
    @Input('showBorder')
    set showBorder(v: boolean) {
        this._showBorder = v === undefined ? true : v;
    }

    @Output() onClosed = new EventEmitter();

    _task: DeviceActionInfo;
    _enumTaskStatus: typeof TaskStatus = TaskStatus;
    _bLoadingTask: boolean = false;
    _bLicenseEnabled: boolean = false;

    constructor(private constantSvc: ConstantService, private devSvc: DeviceService, private licenseSvc: LicenseService) { }

    refreshDeviceTask(force: boolean = false): void {
        this._bLicenseEnabled = false;
        this._bLoadingTask = true;

        if (force) {
            this._refreshCountdownMap[this._device.virtualId] = REFRESH_DURATION;
            HelperLib.countdown(this._refreshCountdownMap[this._device.virtualId], 0, (counter: number) => {
                this._refreshCountdownMap[this._device.virtualId] = counter;
            });
        }

        this.licenseSvc.hasLicenseScope(this._device.virtualId, LicenseScopeType.activityHistory).pipe(
            concatMap((support: boolean) => {
                this._bLicenseEnabled = support;
                
                if (support) {
                    return this.devSvc.updateActivity(this._device, force);
                }

                return observableOf(true);
            })
        ).subscribe(() => {
            this._bLoadingTask = false;
        });
    }

    selectTask(task: DeviceActionInfo): void {
        this._task = task;
    }

    expandContent(data: any): string {
        return (data && data instanceof Object) ? JSON.stringify(data) : data;
    }

    getGeneralTypeName(type: string): string {
        const names: string[] = type.split('.');
        names.shift();
        return names.join(' ');
    }

    close(): void {
        if (this._allowClose && this.onClosed) {
            this.onClosed.emit();
        }
    }
}