import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CustomizationItemInfo } from './customization.data';

@Component({
    selector: 'na-customization-item-img',
    templateUrl: './customization-item-img.component.html',
    styleUrls: ['./customization-item-img.component.css']
})
export class CustomizationItemImageComponent implements OnInit {
    static INSTANCE_ID: number = 1;

    _id: number = CustomizationItemImageComponent.INSTANCE_ID++;
 
    _data: CustomizationItemInfo;
    @Input('data')
    set data(v: CustomizationItemInfo) {
        this._data = v;
    }

    _title: string;
    @Input('title')
    set title(v: string) {
        this._title = v;
    }

    _note: string;
    @Input('note')
    set note(v: string) {
        this._note = v;
    }

    _fileFormat: string = '.png';
    @Input('fileFormat')
    set fileFormat(v: string | string[]) {
        let formats: string[] = [];
        if (!Array.isArray(v)) {
            formats = v.split(','); 
        }
        else {
            formats = v;
        }

        this._fileFormat = formats.map(i => i.startsWith('.') ? i : '.' + i).join(',');
    }

    _maxAllowSize: number = 0.5; //MB
    @Input('maxAllowSize')
    set maxAllowSize(v: number) {
        this._maxAllowSize = v;
    }

    _disabled: boolean = false;
    @Input('disabled')
    set disabled(v: boolean) {
        this._disabled = v;
    }

    _bgColor: string;
    @Input('bgColor')
    set bgColor(v: string) {
        this._bgColor = v;
    }

    _iconColor: string;
    @Input('iconColor')
    set iconColor(v: string) {
        this._iconColor = v;
    }

    private _imgElementRef: ElementRef;
    @ViewChild('imgFile', { static: true})
    set imgFile(v: ElementRef) {
        this._imgElementRef = v;
    }

    @Output() onItemChanged = new EventEmitter<CustomizationItemInfo>();

    ngOnInit(): void {
    }

    selectFile(): void {
        this._imgElementRef.nativeElement.click();
    }

    onFileClick(e: any): void {
        e.target.value = null;
    }

    onFileChange(e: any): void {
        const f: File = e.target.files[0] as File;

        if (f) {
            if ((f.size / 1024 / 1024) > this._maxAllowSize) {
                alert(`File size should be less than ${this._maxAllowSize} MB`);
                return;
            }

            this._data.urlAfter = window.URL.createObjectURL(f);
            this._data.name = f.name;
            this._data.size = f.size;
            this._data.raw = f;

            this.onItemChanged.emit(this._data);
        }
    }

    reset(): void {
        this._data.reset();
        this.onItemChanged.emit(this._data);
    }
}