import { Component, ViewChild, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DeviceGroupFuncInterface } from './group-func.def';
import { DeviceGroupInfo, DeviceGroupType } from '../group.data';
import { DeviceGroupService } from '../dev-group.service';
import { AccountService } from '../../../../entry/account.service';
import { UserService } from '../../../admin/user/user.service';

@Component({
    templateUrl: './group-dlg-create.component.html',
    styleUrls: ['./group-dlg.style.css', './group-dlg-create.component.css']
})
export class DeviceGroupCreateDlgComponent implements DeviceGroupFuncInterface, OnInit, OnDestroy {
    private _index: number = 1;
    title: string;
    group: DeviceGroupInfo;
    _updating: boolean;

    _gList: { index: number, name: string }[] = [];
    _errorMessage: string;
    private _allUnsubscribe: Subject<void> = new Subject();

    private _dlgCloseElementRef: ElementRef;
    @ViewChild('dlgClose', { static: true })
    set dlgClose(holder: ElementRef) {
        if (holder) {
            this._dlgCloseElementRef = holder;
        }
    }

    constructor(private accountSvc: AccountService, private devGroupSvc: DeviceGroupService, private userSvc: UserService) {
        this.addSlot();
    }

    ngOnInit(): void {
        this.accountSvc.loginChanged.pipe(
            takeUntil(this._allUnsubscribe)
        ).subscribe((isLogin: boolean) => {
            if (!isLogin) {
                this._dlgCloseElementRef.nativeElement.click();
            }
        });
    }

    ngOnDestroy(): void {
        this._allUnsubscribe.next();
        this._allUnsubscribe.complete();
    }

    addSlot(): void {
        this._errorMessage = null;
        this._gList.push({
            index: this._index++,
            name: ''
        });
    }

    removeSlot(g: { index: number, name: string }): void {
        this._errorMessage = null;
        this._gList.splice(this._gList.indexOf(g), 1);
    }

    createGroup(): void {
        this._errorMessage = '';
        this._updating = true;
        
        this.devGroupSvc.addNewGroup(null, this.group.id, this._gList.map(g => {
            return {
                name: g.name,
                type: DeviceGroupType.group
            }
        })).subscribe((res: { isFault: boolean, errorMessage?: string }) => {
            this._updating = false;

            if (res.isFault) {
                this._errorMessage = res.errorMessage;
                return;
            }

            this.userSvc.updateUserGroupCache();

            setTimeout(() => {
                this._dlgCloseElementRef.nativeElement.click();
            }, 0);   
        });
    }
}