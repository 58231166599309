import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { SortType } from "app/lib/common/common.data";
import { HelperLib } from "app/lib/common/helper.lib";
import { fromEvent, Subject } from "rxjs";
import { debounceTime, takeUntil } from "rxjs/operators";
import { CertInfo } from "../cert.data";
import { CertService } from "../cert.service";
import { CertFuncItem, CERT_FUNC_ADD, CERT_FUNC_REMOVE, ICertFuncCtrl } from "../form/cert-func.def";
import { CertFuncDirective } from "../form/cert-func.directive";
import { CertFuncService } from "../form/cert-func.service";

@Component({
    selector: 'na-cert-pickup',
    templateUrl: './cert-pickup.component.html',
    styleUrls: ['./cert-pickup.component.css']
})
export class CertPickupComponent implements OnInit, OnDestroy {
    readonly CERT_COLKEY_NAME: string = 'name';
    readonly CERT_COLKEY_EXPIRYDATE: string = 'expiryDate';

    _loading: boolean = false;
    _currentSortKey: string;
    _currentSortType: SortType = SortType.ascend;
    _enumSortType: typeof SortType = SortType;
    _unsubscribe: Subject<void> = new Subject();

    _selectedCertMap: { [certID: string]: { selected: boolean, cert: CertInfo } } = {};
    private _useForeignCertSource: boolean = false;
    _certList: CertInfo[] = [];
    _displayList: CertInfo[] = [];

    _currentPage: number = 1;
    _displayAmount: number = 1;

    @Input() title: string;
    @Input()
    set sourceCerts(v: CertInfo[]) {
        if (v !== undefined) {
            this._certList = v;
            this._useForeignCertSource = true;
            this._selectedCertMap = {};
            this.refactor();
        }
    }

    // locked certificate should be hide
    _lockedCertMap: { [certID: string]: boolean } = {};
    @Input()
    set lockedCerts(v: CertInfo[]) {
        if (v !== undefined) {
            this._lockedCertMap = {};
            v.forEach(cert => {
                this._lockedCertMap[cert.id] = true;
                if (this._selectedCertMap[cert.id] && this._selectedCertMap[cert.id].selected) {
                    this._selectedCertMap[cert.id].selected = false;
                }
            });
        }
    }
    @Input() numberInPage: number = 30;
    @Input() supportSearch: boolean = true;
    @Input() supportCertSelection: boolean = true;
    @Input() supportCertAddition: boolean = true;
    @Input() supportCertRemoval: boolean = true;
    @Input() supportCertMgrLink: boolean = false;

    private _searchRef: ElementRef;
    @ViewChild('search', { static: true })
    set search(v: ElementRef) {
        this._searchRef = v;
        if (this._searchRef) {
            const searchElement = this._searchRef.nativeElement;
            const searchInputOb = fromEvent(searchElement, 'input');
    
            searchInputOb.pipe(
                debounceTime(200),
                takeUntil(this._unsubscribe)
            ).subscribe((e: any) => {
                this.refactor([{ key: this.CERT_COLKEY_NAME, value: e.target.value.toLocaleLowerCase() }]);
            });
        }
    }

    private _funcHost: CertFuncDirective;
    @ViewChild(CertFuncDirective, { static: true })
    set funcHost(host: any) {
        this._funcHost = host;
    }

    @Output() onCertSelected = new EventEmitter<CertInfo[]>();

    constructor(private certSvc: CertService, private certFuncSvc: CertFuncService) { }

    ngOnInit(): void {
        if (!this._useForeignCertSource) {
            this.certSvc.onCertAdded.pipe(
                takeUntil(this._unsubscribe)
            ).subscribe((res: { addedCerts: CertInfo[], currentCerts: CertInfo[] }) => {
                this._certList = res.currentCerts;
                this.refactor();
            });

            this.certSvc.onCertRemoved.pipe(
                takeUntil(this._unsubscribe)
            ).subscribe((res: { removedCerts: CertInfo[], currentCerts: CertInfo[] }) => {
                this._certList = res.currentCerts;
                this.refactor();
            });

            this._loading = true;
            this.certSvc.getCerts().subscribe((res: { certs: CertInfo[], isFault: boolean, errorMessage?: string }) => {
                if (!res.isFault) {
                    this._certList = res.certs;
                    this.refactor();
                }

                this._loading = false;
            });
        }
    }

    ngOnDestroy(): void {
        this._unsubscribe.next();
        this._unsubscribe.complete();
    }

    selectCert(cert: CertInfo, checked: boolean): void {
        this._selectedCertMap[cert.id] = this._selectedCertMap[cert.id] || { selected: false, cert: cert };
        this._selectedCertMap[cert.id].selected = checked;

        this.onCertSelected.emit(HelperLib.mapToList<{ selected: boolean, cert: CertInfo }>(this._selectedCertMap).filter(c => c.selected).map(c => c.cert));
    }

    addCert(): void {
        this.createScepFuncDlg(CERT_FUNC_ADD);
    }

    removeCert(cert: CertInfo): void {
        this.createScepFuncDlg(CERT_FUNC_REMOVE, [cert]);
    }

    onPageChange(page: number): void {
        if (this._loading) {
            return;
        }

        if (this._currentPage !== page) {
            this._currentPage = page;
            this.refactor();
        }
    }

    sortDescend(key: string): void {
        if (this._currentSortKey === key && this._currentSortType === SortType.descend) {
            return;
        }

        this._currentSortKey = key;
        this._currentSortType = SortType.descend;
        this.refactor();
    }

    sortAscend(key: string): void {
        if (this._currentSortKey === key && this._currentSortType === SortType.ascend) {
            return;
        }

        this._currentSortKey = key;
        this._currentSortType = SortType.ascend;
        this.refactor();
    }

    private refactor(filters: { key: string, value: string }[] = []): void {
        // search
        this._displayList = filters.length > 0 ? this._certList.filter(c => {
            for (let filter of filters) {
                if (c[filter.key].toLocaleLowerCase().indexOf(filter.value) < 0) {
                    return false;
                }
            }

            return true;
        }) : this._certList.map(c => c);

        // sort
        this._displayList = this._displayList.sort((a: CertInfo, b: CertInfo) => {
            let ca: string = a[this._currentSortKey];
            let cb: string = b[this._currentSortKey];

            if (ca === cb) {
                return 0;
            }

            switch (this._currentSortType) {
                case SortType.ascend:
                    {
                        return ca >= cb ? 1 : -1;
                    }
                case SortType.descend:
                    {
                        return ca >= cb ? -1 : 1;
                    }
            }
        });

        //notify result page amount.
        this._displayAmount = this._displayList.length;

        //filter by page
        if (this._displayList.length > this.numberInPage) {
            const startIndex = (this._currentPage - 1) * this.numberInPage;
            this._displayList = this._displayList.slice(startIndex, startIndex + this.numberInPage);
        }
    }

    private onActionComplete(res: { funcName: string, isFault: boolean, data?: any, errorMessage?: string }): void { }

    private createScepFuncDlg(funcName: string, data: CertInfo[] = [], other?: any): void {
        const item: CertFuncItem = this.certFuncSvc.getItemByName(funcName);
        if (item) {
            const viewContainerRef = this._funcHost.viewContainerRef;
            viewContainerRef.clear();

            const componentRef = viewContainerRef.createComponent(item.component);

            (<ICertFuncCtrl>componentRef.instance).title = item.title;
            (<ICertFuncCtrl>componentRef.instance).funcName = funcName;
            (<ICertFuncCtrl>componentRef.instance).certList = data;
            (<ICertFuncCtrl>componentRef.instance).other = other;
            (<ICertFuncCtrl>componentRef.instance).onActionCompleted = this.onActionComplete.bind(this);
        }
    }
}