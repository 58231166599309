import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../../api.base';
import { IAlertSetting } from '../api.alert.common';

export interface IUpdateAlertPathParameter {
    alertSettingID: string;
}

export interface IUpdateAlertTxData {
    settingName?: string;
    settingData?: any;
}

export class APIUpdateAlertManager extends APIBaseManager<IUpdateAlertPathParameter, void, IUpdateAlertTxData, IAlertSetting> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_UpdateAlert';
        this._apiMethod = 'PATCH';
    }

    protected getRequestURL(pathParameters: IUpdateAlertPathParameter, queryParameters: void): string {
        return super.getRequestURL() + 'accounts/alertSettings/' + pathParameters.alertSettingID;
    }
}