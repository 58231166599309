import { Component } from '@angular/core';

import { BaseConfigFormComponent } from '../base/base-config-form.component';
import { DIALOG_NAME_ALERT, ConfigDialogPage } from '../base/base-config-data';
import { DevFuncActionService } from '../base/action/dev-func-action.service';
import { DevFuncConfirmService } from '../base/confirm/dev-func-confirm.service';
import { DeviceService } from '../../device/device.service';
import { AlertService } from '../../setting/alert/alert.service';
import { LicenseScopeType } from '../../license/license.data';
import { LicenseService } from '../../license/license.service';
import { APKService } from '../apk/apk.service';
import { AccountService } from '../../../entry/account.service';
import { ConstantService } from '../../../../app/lib/common/constant.service';
import { NAService } from '../../../API/na.service';

@Component({
    templateUrl: '../base/base-config-form.component.html',
    styleUrls: ['../base/base-config-form.component.css']
})
export class AlertFormComponent extends BaseConfigFormComponent {
    constructor(
        protected devFuncActionSvc: DevFuncActionService,
        protected devFuncConfirmSvc: DevFuncConfirmService,
        protected accountSvc: AccountService,
        protected naSvc: NAService,
        protected licenseSvc: LicenseService,
        protected constantSvc: ConstantService,
        protected devSvc: DeviceService,
        protected apkSvc: APKService,
        private alertSvc: AlertService
    ) {
        super(devFuncActionSvc, devFuncConfirmSvc, accountSvc, naSvc, licenseSvc, constantSvc, devSvc, apkSvc);
    }

    ngOnInit(): void {
        this._dialogName = DIALOG_NAME_ALERT;
        this._licenseScopeType = LicenseScopeType.alertEmail;
        super.ngOnInit();
    }

    protected goNext(fromPage?: ConfigDialogPage): void {
        if (this._page === ConfigDialogPage.checking) {
            this._actionData.ruleAssignMap = {};
            this._actionData.needReset = true;
            this._actionData.lastSelectRuleId = '';
        }

        super.goNext();
    }

    protected submit(): void {
        const addedRuleIDList: string[] = [];
        const removedRuleIDList: string[] = [];

        Object.keys(this._actionData.ruleAssignMap).forEach((alertID: string) => {
            if (this._actionData.ruleAssignMap[alertID].current !== this._actionData.ruleAssignMap[alertID].after) {
                this._actionData.ruleAssignMap[alertID].current ? removedRuleIDList.push(alertID) : addedRuleIDList.push(alertID);
            }
        });

        this.alertSvc.updateDeviceAlertList(this._actionData.deviceList, addedRuleIDList, removedRuleIDList).subscribe((res) => {
            if (res) {
                this._page++;
            }
        });
    }
}