import { Component, OnInit, ViewChild } from '@angular/core';
import { of as observableOf } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';

import { AccessKeyInfo } from '../../../API/v1/Account/api.accessKey.common';
import { AccessKeyFuncDirective } from './accessKey-func.directive';
import { AccessKeyFuncItem, AccessKeyFuncService, ACCKEY_FUNC_CREATE, ACCKEY_FUNC_INACTIVE, ACCKEY_FUNC_REMOVE, IAccessKeyFunc } from './accessKey-func.service';
import { AccessKeyService } from './accessKey.service';

@Component({
    templateUrl: './accessKey.component.html',
    styleUrls: ['./accessKey.component.css']
})
export class AccessKeyComponent implements OnInit {
    _loading: boolean;
    _keyList: AccessKeyInfo[] = [];
    _key: AccessKeyInfo;
    _loginResult: { token: string, error?: string };

    _keyStatusUpdateMap: { [key: string]: boolean } = {};
    _keyRemarkMap: { [key: string]: { inEditMode: boolean, remark: string, updating: boolean, errorMessage?: string } } = {};

    private _accKeyFuncHost: AccessKeyFuncDirective;
    @ViewChild(AccessKeyFuncDirective, { static: true })
    set accKeyFuncHost(host: any) {
        if (host) {
            this._accKeyFuncHost = host;
        }
    }

    constructor(
        private accKeySvc: AccessKeyService,
        private accKeyFuncSvc: AccessKeyFuncService) {
    }

    ngOnInit(): void {
        this.refreshAccessKeyList(false);
    }

    selectAccessKey(key: AccessKeyInfo): void {
        this._key = key;
    }

    createNewAccessKey(): void {
        this.doFunc(ACCKEY_FUNC_CREATE);
    }

    private createNewAccessKeyComplete(newKey: AccessKeyInfo): void {
        this._keyStatusUpdateMap[newKey.accessKeyID] = false;
        this._keyRemarkMap[newKey.accessKeyID] = {
            inEditMode: newKey.accessKeyRemark ? false : true,
            remark: newKey.accessKeyRemark,
            updating: false
        };
    }

    refreshAccessKeyList(force: boolean = true): void {
        this._loading = true;
        this.accKeySvc.getAccessKeyList(force).subscribe((res: AccessKeyInfo[]) => {
            this._keyList = res;
            this._keyStatusUpdateMap = {};
            this._keyList.forEach(k => {
                this._keyStatusUpdateMap[k.accessKeyID] = false;
                this._keyRemarkMap[k.accessKeyID] = {
                    inEditMode: k.accessKeyRemark ? false : true,
                    remark: k.accessKeyRemark,
                    updating: false
                }
            });

            this._loading = false;
        });
    }

    turnToEditMode(key: AccessKeyInfo): void {
        this._keyRemarkMap[key.accessKeyID] = this._keyRemarkMap[key.accessKeyID] || { inEditMode: false, remark: key.accessKeyRemark, updating: false };
        this._keyRemarkMap[key.accessKeyID].inEditMode = true;
    }

    updateAccessKeyRemark(key: AccessKeyInfo): void {
        this._keyRemarkMap[key.accessKeyID].updating = true;
        this._keyRemarkMap[key.accessKeyID].inEditMode = false;
        this._keyRemarkMap[key.accessKeyID].errorMessage = null;

        observableOf(this._keyRemarkMap[key.accessKeyID].remark !== key.accessKeyRemark).pipe(
            concatMap((isChange: boolean) => {
                if (isChange) {
                    return this.accKeySvc.updateAccessKey(key, key.isAccessKeyEnabled, this._keyRemarkMap[key.accessKeyID].remark).pipe(
                        map((res: { isFault: boolean, key: AccessKeyInfo, errorMessage?: string }) => {
                            return {
                                remark: res.key.accessKeyRemark,
                                errorMessage: res.errorMessage
                            }
                        })
                    );
                }

                return observableOf({ remark: key.accessKeyRemark });
            })
        ).subscribe((res: { remark: string, errorMessage?: string }) => {
            this._keyRemarkMap[key.accessKeyID].remark = res.remark;
            this._keyRemarkMap[key.accessKeyID].updating = false;
            this._keyRemarkMap[key.accessKeyID].errorMessage = res.errorMessage;
        });
    }

    changeAccessKeyStatus(): void {
        if (this._key.isAccessKeyEnabled) {
            this.doFunc(ACCKEY_FUNC_INACTIVE, this._key);
        }
        else {
            this._keyStatusUpdateMap[this._key.accessKeyID] = true;
            this.accKeySvc.updateAccessKey(this._key, true).subscribe(res => {
                this._keyStatusUpdateMap[this._key.accessKeyID] = false;
            });
        }
    }

    removeAccessKey(): void {
        this.doFunc(ACCKEY_FUNC_REMOVE, this._key);
    }

    private removeAccessKeyComplete(result: { isFault: boolean, errorMessage: string }): void {
        if (!result.isFault) {
            this._key = null;
        }
    }

    private doFunc(funcName: string, key?: AccessKeyInfo): void {
        const item: AccessKeyFuncItem = this.accKeyFuncSvc.getItemByName(funcName);
        if (item) {
            const viewContainerRef = this._accKeyFuncHost.viewContainerRef;
            viewContainerRef.clear();

            const componentRef = viewContainerRef.createComponent(item.component);

            (<IAccessKeyFunc>componentRef.instance).title = item.title;
            (<IAccessKeyFunc>componentRef.instance).key = key;
            switch (funcName) {
                case ACCKEY_FUNC_CREATE:
                    {
                        (<IAccessKeyFunc>componentRef.instance).dialogCompleteHandler = this.createNewAccessKeyComplete.bind(this);
                    }
                    break;
                case ACCKEY_FUNC_REMOVE:
                    {
                        (<IAccessKeyFunc>componentRef.instance).dialogCompleteHandler = this.removeAccessKeyComplete.bind(this);
                    }
                    break;
            }
        }
    }
}