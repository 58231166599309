<ul class='nav nav-tabs'>
    <li *ngIf="supportReportView()" class="nav-item">
        <a id="id_report_link_overview" class="nav-link" [class.active]="_activeTab === _enumReportTabType.records"
            [routerLink]="_reportListRoute">
            <span>All reports</span>
        </a>
    </li>
    <li *ngIf="supportReportUpdate()" class="nav-item">
        <a id="id_report_link_config" class="nav-link" [class.active]="_activeTab === _enumReportTabType.config"
            [routerLink]="_reportConfigRoute">
            <span>Settings</span>
        </a>
    </li>
</ul>
<div class='tab-content' class="pt-2">
    <router-outlet></router-outlet>
</div>