import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { of as observableOf, Subject } from 'rxjs';
import { concatMap, map, takeUntil } from 'rxjs/operators';

import { UserInfo } from '../user.data';
import { UserService } from '../user.service';
import { HelperLib } from '../../../../lib/common/helper.lib';

@Component({
    templateUrl: './user-policy-overview.component.html',
    styleUrls: ['../user.style.css']
})
export class UserPolicyOverviewComponent implements OnInit, OnDestroy {
    _loading: boolean;
    _licenseUsage: number = 0;
    _availableLicenseUsage: number = 0;
    _userRoleList: { name: string, desc: string, userList?: UserInfo[] }[] = [];
    _userRoleUsageList: { roleName: string, usage: number, limit: number }[] = [];
    _activeUserRoleName: string;

    private _allUnsubscribe: Subject<void> = new Subject();

    constructor(private route: ActivatedRoute, private location: Location, private userSvc: UserService) { }

    ngOnInit(): void {
        this._availableLicenseUsage = this.userSvc.userLicenseAmount;

        this.route.paramMap.pipe(
            takeUntil(this._allUnsubscribe)
        ).subscribe((params: ParamMap) => {
            const path: string = this.location.path();
            const lastPath: string = decodeURIComponent(path.substring(path.lastIndexOf('/') + 1));
            if (lastPath !== 'role') {
                this._activeUserRoleName = lastPath;
            }
        });

        this.userSvc.onUserUpdated.pipe(
            takeUntil(this._allUnsubscribe)
        ).subscribe((res: { updatedUserList: UserInfo[], remainingUserList: UserInfo[] }) => {
            this.updateUserList(res.remainingUserList);
        });

        this._loading = true;

        this.userSvc.getUserRoleList().pipe(
            concatMap((res: { userRoleList: { name: string, desc: string }[], isFault: boolean, errorMessage?: string }) => {
                if (!res.isFault) {
                    this._userRoleList = res.userRoleList.map(ur => ({ name: ur.name, desc: ur.desc, userList: []}));
                    return this.userSvc.getUserList().pipe(
                        map((res: { userList: UserInfo[], isFault: boolean, errorMessage?: string }) => {
                            if (!res.isFault) {
                                this.updateUserList(res.userList);
                            }

                            return true;
                        })
                    )
                }

                return observableOf(true);
            }),
            concatMap(() => {
                return this.userSvc.getEnterpriseLicenseUsage();
            }),
            map((res: { license?: { [roleName: string]: { limit: number, usage: number } }, isFault: boolean, errorMessage?: string }) => {
                if (!res.isFault) {
                    this._userRoleUsageList = Object.keys(res.license).map(roleName => Object.assign({ roleName: roleName }, res.license[roleName]));
                }
            })
        ).subscribe(() => {
            this._loading = false;
        });
    }

    private updateUserList(userList: UserInfo[]): void {
        
        const roleMap = userList.reduce((prev, curr) => {
            prev[curr.userRole] = prev[curr.userRole] || [];
            prev[curr.userRole].push(curr);

            return prev;
        }, {});

        this._userRoleList.forEach(ur => ur.userList = roleMap[ur.name] ?? []);
    }

    ngOnDestroy(): void {
        this._userRoleList = [];

        this._allUnsubscribe.next();
        this._allUnsubscribe.complete();
    }

    viewUserList(role: { id: string, name: string, desc: string, userList?: UserInfo[] }): void {
        this._activeUserRoleName = role.name;
    }
}