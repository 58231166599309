import { Component, Input, EventEmitter, Output, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

import { DeviceGroupInfo, DeviceGroupMode, DeviceGroupType, DEVICE_GROUP_FUNC_CREATE } from '../group.data';
import { PolicyService } from '../../../setting/policy/policy.service';
import { PolicyInfo, PolicyType } from '../../../setting/policy/policy.data';
import { AccountService } from '../../../../entry/account.service';
import { DeviceService } from '../../device.service';
import { DeviceGroupService } from '../dev-group.service';
import { HelperLib } from '../../../../lib/common/helper.lib';
import { DeviceGroupFuncInterface, DeviceGroupFuncItem } from '../dlg/group-func.def';
import { DeviceGroupFuncService } from '../dlg/group-func.service';
import { DeviceGroupFuncDirective } from '../dlg/group-func.directive';
import { UserPreferenceService } from '../../../user-preference.service';

@Component({
    selector: 'na-dev-group-tree-wrapper',
    templateUrl: './dev-group-tree-wrapper.component.html',
    styleUrls: ['./dev-group-tree-wrapper.component.css']
})
export class DeviceGroupTreeWrapperComponent implements OnInit, OnDestroy {
    static INSTANCE_ID: number = 1;
    readonly TEXT_LOADING: string = 'loading ...';
    _id: number;

    _loadingPolicy: boolean = false;
    _deviceGroupPolicyList: { type: PolicyType, policyList: PolicyInfo[] }[] = [];
    _targetPolicyGroup: DeviceGroupInfo;

    _currentAccount: { accountID: string, accountName: string };
    _ownerAccount: { accountID: string, accountName: string };
    _sharedAccountList: { accountID: string, accountName: string }[] = [];
    _enumGroupMode: typeof DeviceGroupMode = DeviceGroupMode;

    private _allUnsubscribe: Subject<void> = new Subject();

    _g: DeviceGroupInfo;
    @Input()
    set group(g: DeviceGroupInfo) {
        this._g = g;
    }

    _showTitle: boolean = false;
    @Input()
    set showTitle(v: boolean) {
        this._showTitle = v;
    }

    _title: string = '';
    @Input()
    set title(v: string) {
        this._title = v;
    }

    _allowChangeAccount: boolean = false;
    @Input()
    set allowChangeAccount(allow: boolean) {
        this._allowChangeAccount = allow;

    }

    _groupMode: string = DeviceGroupMode.edit;
    @Input()
    set mode(m: string) {
        this._groupMode = m;
    }

    _moveTarget: DeviceGroupInfo;
    @Input()
    set moveTarget(t: DeviceGroupInfo) {
        this._moveTarget = t;
    }

    _showDevice: boolean = false;
    @Input()
    set showDevice(s: boolean) {
        this._showDevice = s;
    }

    _pickMap: { [groupID: string]: { checked: boolean, groupType: DeviceGroupType, name: string } } = {};
    @Input()
    set pickMap(s: { [groupID: string]: { checked: boolean, groupType: DeviceGroupType, name: string } }) {
        this._pickMap = s;
    }

    _enableFuncUnfold: boolean = false;
    @Input('enableFuncUnfold')
    set enableFuncUnfold(show: boolean) {
        this._enableFuncUnfold = show;
    }

    _enableCollapseFunc: boolean = false;
    @Input('enableCollapseFunc')
    set enableCollapseFunc(show: boolean) {
        this._enableCollapseFunc = show;
    }

    _policyDisplayMode: string = 'embed'; //dlg or embed
    @Input('policyMode')
    set policyDisplayMode(v: string) {
        this._policyDisplayMode = v;
    }

    _styleH: string;
    @Input('styleH')
    set styleH(v: string) {
        this._styleH = v;
    }

    _styleBorder: boolean;
    @Input('styleBorder')
    set styleBorder(v: boolean) {
        this._styleBorder = v;
    }

    @ViewChild(DeviceGroupFuncDirective) groupFuncHost: DeviceGroupFuncDirective;

    @Output() onGroupFuncTrigger = new EventEmitter<{ funcName: string, group: DeviceGroupInfo }>();
    @Output() onGroupMoveTargetChanged = new EventEmitter<DeviceGroupInfo>();
    @Output() onGroupTreeDisplayChanged = new EventEmitter<boolean>();
    @Output() onGroupOwnerAccountChanged = new EventEmitter<string>();
    @Output() onGroupSelected = new EventEmitter<DeviceGroupInfo>();

    constructor(
        private accountSvc: AccountService,
        private policySvc: PolicyService,
        private devSvc: DeviceService,
        private groupSvc: DeviceGroupService,
        private groupFuncSvc: DeviceGroupFuncService,
        private userPrevSvc: UserPreferenceService
    ) {
    }

    ngOnInit(): void {
        this._ownerAccount = { accountID: this.accountSvc.enterpriseAccountID || this.accountSvc.accountID, accountName: this.accountSvc.enterpriseAccountName || this.accountSvc.accountName };
        const currentActiveAccount = this.groupSvc.owner;

        //lock the account view if it is Enterprise
        if (this.accountSvc.isEnterprise()) {
            this._allowChangeAccount = false;
        }

        if (this._allowChangeAccount) {
            HelperLib.checkState(1, () => { return this.groupSvc.isReady }, () => {
                this.changeGroupOwnerAccount(currentActiveAccount);

                this.devSvc.getAllSharedAccountList().subscribe((res: { accountName: string, accountID: string }[]) => {
                    this._sharedAccountList = res;

                    const defaultAccount: { accountID: string, accountName: string } = this._sharedAccountList.find(a => a.accountName === this.userPrevSvc.userPreference.home.group.defaultAccount);
                    if (defaultAccount && defaultAccount.accountID !== this._ownerAccount.accountID) {
                        this.changeGroupOwnerAccount(defaultAccount);
                    }
                });
            });
        }
        else if (!this._g) {
            HelperLib.checkState(1, () => { return this.groupSvc.isReady }, () => {
                this.changeGroupOwnerAccount(currentActiveAccount);
            });
        }

        DeviceGroupTreeWrapperComponent.INSTANCE_ID++;
        this._id = DeviceGroupTreeWrapperComponent.INSTANCE_ID;
    }

    ngOnDestroy(): void {
        this._allUnsubscribe.next();
        this._allUnsubscribe.complete();
    }

    supportGroupUpdate(): boolean {
        return this.accountSvc.hasScope_device_group_update();
    }

    unfoldGroups(): void {
        this.groupSvc.unfoldAllGroups(null);
    }

    expandGroups(): void {
        this.groupSvc.expandAllGroups(null);
    }

    createGroup(): void {
        this.playGroupFunc(DEVICE_GROUP_FUNC_CREATE);
    }

    changeGroupOwnerAccount(account: { accountID: string, accountName: string }): void {
        if (!this._currentAccount || this._currentAccount.accountID !== account.accountID) {
            this._currentAccount = account;
            this.groupSvc.changeOwnerAccount(account);
            this._groupMode = this.accountSvc.isEnterprise() || this._currentAccount.accountID === this.accountSvc.accountID ? this._groupMode : DeviceGroupMode.viewonly;
            this._g = this.groupSvc.getRootGroup(account.accountName);
            this.groupSvc.inspectGroup(null, this.groupSvc.getActiveGroup(account.accountName), true);
            if (this._allowChangeAccount) {
                this.onGroupOwnerAccountChanged.emit(account.accountName);
            }
        }
    }

    onMoveTargetChange(g: DeviceGroupInfo): void {
        this._moveTarget = g;
        this.onGroupMoveTargetChanged.emit(this._moveTarget);
    }

    onGroupCtrlFuncTrigger(funcName: string): void {
        this.playGroupFunc(funcName);
    }

    hideGroup(): void {
        this.onGroupTreeDisplayChanged.emit(false);
    }

    onGroupSelect(group: DeviceGroupInfo): void {
        this.onGroupSelected.emit(group);
    }

    onSubgroupPolicyInspect(group: DeviceGroupInfo): void {
        if (group && group.policies) {
            const supportedPolicyTypes: PolicyType[] = this.policySvc.getSupportPolicyTypesByLevel(this.accountSvc.isEnterprise());
            const policyIDListUnderDeviceGroup: string[] = [].concat(...supportedPolicyTypes.map(type => group.policies[type] || []));
            if (policyIDListUnderDeviceGroup.length > 0) {
                this._loadingPolicy = true;
                this._targetPolicyGroup = group;

                this.policySvc.getPolicyByIDList(policyIDListUnderDeviceGroup).subscribe((policyList: PolicyInfo[]) => {
                    supportedPolicyTypes.forEach(type => {
                        this._deviceGroupPolicyList.push({
                            type: type,
                            policyList: policyList.filter(p => p.type === type)
                        });
                    });

                    this._loadingPolicy = false;
                });
            }
        }
    }

    closePolicyDetailView(): void {
        this._targetPolicyGroup = null;
        this._deviceGroupPolicyList = [];
    }

    playGroupFunc(funcName: string, g?: DeviceGroupInfo): void {
        const item: DeviceGroupFuncItem = this.groupFuncSvc.getFunctionByName(funcName);
        if (item) {
            const viewContainerRef = this.groupFuncHost.viewContainerRef;
            viewContainerRef.clear();

            const componentRef = viewContainerRef.createComponent(item.component);

            (<DeviceGroupFuncInterface>componentRef.instance).title = item.title;
            (<DeviceGroupFuncInterface>componentRef.instance).group = g || this.groupSvc.getActiveGroup(null);
        }
    }
}