import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../api.base';

export class APIValidateAccountTokenManager extends APIBaseManager<void, void, void, string> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_ValidateAccountToken';
        this._apiMethod = 'POST';
    }

    protected getRequestURL(): string {
        return super.getRequestURL() + 'token/validate';
    }

    public getURL(): string {
        return this.getRequestURL();
    }
}