import { HelperLib, WEEKDAY_LIST } from "../../../../app/lib/common/helper.lib";

export class DailyRebootInfo {
    static WEEKDAY_SPLIT_CHAR: string = ',';
    enabled: boolean;
    time: string;
    private _daysMap: { [wid: string]: { checked: boolean, day: string, abbr: string } } = {};

    constructor(raw?: { time?: string, days?: string[] }, enabled?: boolean) {
        this.enabled = enabled ?? (raw?.days?.length > 0 ? true : false);
        this.time = raw?.time || '05:00';

        this._daysMap = WEEKDAY_LIST.reduce((prev, curr) => {
            const wid: string = curr.substring(0, 3);
            prev[wid] = { day: curr, checked: false, abbr: wid };

            return prev;
        }, {});

        if (raw?.days) {
            raw?.days.forEach(wid => {
                if (this._daysMap[wid]) {
                    this._daysMap[wid].checked = true;
                }
            });
        }

    }

    isWeekdayChecked(weekdayId: string): boolean {
        return this._daysMap[weekdayId]?.checked;
    }

    checkWeekday(weekdayId: string, checked: boolean): void {
        this._daysMap[weekdayId].checked = checked;
    }

    transform(): { days: string[], time: string } {
        return { days: this.transformWeekDays(), time: this.time };
    }

    transformWeekDays(): string[] {
        return Object.keys(this._daysMap).filter(wid => this._daysMap[wid].checked).map(wid => this._daysMap[wid].abbr);
    }
}