import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { switchMap, map, takeUntil, delay, concatMap } from 'rxjs/operators';

import { ActivityInfo } from './activity.data';
import { ActivityService } from './activity.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { DeviceInfo, TaskStatus } from '../../device/data/device-info';
import { HelperLib, REFRESH_DURATION } from '../../../lib/common/helper.lib';
import { DeviceService } from '../../device/device.service';
import { ConstantService } from '../../../../app/lib/common/constant.service';
import { AccountService } from '../../../entry/account.service';

@Component({
    templateUrl: './activity-detail.component.html',
    styleUrls: ['./activity-detail.component.css']
})
export class ActivityDetailComponent implements OnInit, OnDestroy {
    _isLoading: boolean = false;
    _exporting: boolean = false;
    _allowCancel: boolean = true;
    _deviceConfig: { virtualDeviceID: string, device?: DeviceInfo, errorMessage?: string, inputData?: { actionID: string, resourceData: any } };
    _refreshCounter: number = 0;
    _deviceStatusList: { name: string, deviceInfoList: { device?: DeviceInfo, errorMessage?: string, inputData?: { actionID: string, resourceData: any } }[], active?: boolean }[] = [];
    _bNoDevice: boolean = false; //if all device under this task has been unpaired
    _isCancelling: boolean = false;

    private _allUnsubscribe: Subject<void> = new Subject();

    _activity: ActivityInfo;
    private set activity(a: ActivityInfo) {
        this._activity = a;
        this._allowCancel = this._activity.statusCode === 'finished' || this._activity.statusCode === 'fail' || this._activity.requestForCancel ? false : true;
    }

    private _reportLinkRef: ElementRef;
    @ViewChild('reportLink', { static: true })
    set reportLink(v: ElementRef) {
        if (v) {
            this._reportLinkRef = v;
        }
    }

    private _dlgCloseElementRef: ElementRef;
    @ViewChild('dlgClose')
    set dlgClose(holder: ElementRef) {
        this._dlgCloseElementRef = holder;
    }

    constructor(
        public constantSvc: ConstantService,
        private accountSvc: AccountService,
        private activitySvc: ActivityService,
        public devSvc: DeviceService,
        private route: ActivatedRoute
    ) {

    }

    ngOnInit(): void {
        this._isLoading = true;
        this.route.paramMap.pipe(
            switchMap((params: ParamMap) => this.activitySvc.getActivityByID(params.get('id'))),
            switchMap((activity: ActivityInfo) => {
                this.activity = activity;
                return this.updateDeviceStatus();
            })
        ).subscribe(() => {
            this._isLoading = false;
        });

        this.accountSvc.loginChanged.pipe(
            takeUntil(this._allUnsubscribe)
        ).subscribe((isLogin: boolean) => {
            if (!isLogin) {
                if (this._dlgCloseElementRef && this._dlgCloseElementRef.nativeElement) {
                    this._dlgCloseElementRef.nativeElement.click();
                }
            }
        });
    }

    ngOnDestroy(): void {
        this._allUnsubscribe.next();
        this._allUnsubscribe.complete();
    }

    refreshTask(): void {
        this._isLoading = true;
        this._refreshCounter = REFRESH_DURATION;
        HelperLib.countdown(this._refreshCounter, 0, (counter: number) => {
            this._refreshCounter = counter;
        });

        this.activitySvc.getActivityByID(this._activity.activityID, true).pipe(
            switchMap((a: ActivityInfo) => {
                this.activity = a;
                return this.updateDeviceStatus();
            })
        ).subscribe(() => {
            this._isLoading = false;
        });
    }

    cancelTask(): void {
        this._isCancelling = true;
        this.activitySvc.cancel(this._activity).pipe(
            concatMap(() => {
                return this.activitySvc.getActivityByID(this._activity.activityID, true)
            }),
            delay(2000)
        ).subscribe((activity: ActivityInfo) => {
            this._isCancelling = false;
            this._activity = activity;
        });
    }

    exportTask(): void {
        this._exporting = true;
        this.activitySvc.export(this._activity).pipe(
            map((c: { header: string, dataList: string[][], date: string }) => {
                return HelperLib.downloadCsv(this._reportLinkRef.nativeElement, '[IAdeaCare-Activity]' + this._activity.subject.replace(' ', '_') + '_' + c.date, c.header, c.dataList);
            })
        ).subscribe(() => {
            this._exporting = false;
        });
    }

    expandObject(data: any): string {
        return HelperLib.expandObject(data);
    }

    viewDeviceConfig(devConfig: { virtualDeviceID: string, device?: DeviceInfo, errorMessage?: string, inputData?: { actionID: string, resourceData: any } }): void {
        this._deviceConfig = devConfig;
    }

    clearDeviceConfig(): void {
        this._deviceConfig = null;
    }

    private updateDeviceStatus(): Observable<void> {
        this._deviceStatusList = [];
        return this.activitySvc.getDeviceStatusList(this._activity).pipe(
            map((ret: {
                success: { virtualDeviceID: string, device?: DeviceInfo, errorMessage?: string, inputData?: { actionID: string, resourceData: any } }[],
                failed: { virtualDeviceID: string, device?: DeviceInfo, errorMessage?: string, inputData?: { actionID: string, resourceData: any } }[],
                cancelled: { virtualDeviceID: string, device?: DeviceInfo, errorMessage?: string, inputData?: { actionID: string, resourceData: any } }[],
                progress: { virtualDeviceID: string, device?: DeviceInfo, errorMessage?: string, inputData?: { actionID: string, resourceData: any } }[],
                pending: { virtualDeviceID: string, device?: DeviceInfo, errorMessage?: string, inputData?: { actionID: string, resourceData: any } }[]
            }) => {
                this._deviceStatusList.push({ name: TaskStatus.success, deviceInfoList: ret.success });
                this._deviceStatusList.push({ name: TaskStatus.fail, deviceInfoList: ret.failed });
                this._deviceStatusList.push({ name: TaskStatus.cancel, deviceInfoList: ret.cancelled });
                this._deviceStatusList.push({ name: TaskStatus.progress, deviceInfoList: ret.progress });
                this._deviceStatusList.push({ name: TaskStatus.pending, deviceInfoList: ret.pending });

                this._bNoDevice = ret.success.length + ret.failed.length + ret.cancelled.length + ret.progress.length + ret.pending.length > 0 ? false : true;

                for (let tab of this._deviceStatusList) {
                    if (tab.deviceInfoList.length > 0) {
                        tab.active = true;
                        break;
                    }
                }

                return;
            })
        );
    }
}