import { Injectable, EventEmitter, Output, Directive } from '@angular/core';
import { of as observableOf, Observable, iif as observableIif, forkJoin } from 'rxjs';
import { map, concatMap, concatAll, delay } from 'rxjs/operators';

import { AccountService } from '../../../entry/account.service';
import { DeviceInfo } from '../../device/data/device-info';
import { AlertInfo, AlertAddedEventArgs, AlertRemovedEventArgs, AlertChangedEventArgs, AlertBaseEventArgs, DeviceMonitorStatusMap } from './alertConfig/alert.data';
import { NAService } from '../../../API/na.service';
import { IAPIRx } from '../../../API/api.base';
import { IAlertSetting, IAlertNotificationInfo } from '../../../API/v1/Alert/api.alert.common';
import { ICreateAlertTxData } from '../../../API/v1/Alert/setting/api.alert.create';
import { IUpdateAlertTxData } from '../../../API/v1/Alert/setting/api.alert.update';
import { IListAlertRxData } from '../../../API/v1/Alert/setting/api.alert.list';
import { IClass } from '../../../lib/common/common.data';
import { Logger } from '../../../lib/common/logger';
import { HelperLib } from '../../../lib/common/helper.lib';
import { IAPIListAlertNotificationRxData } from '../../../API/v1/Alert/notification/api.alert.notification.list';

@Directive()
@Injectable()
export class AlertService implements IClass {
    className: string;
    private _alertList: AlertInfo[] = [];
    private _lastRefreshTime: number = 0;
    private _refreshing: boolean = false;
    private readonly LEAST_UPDATE_TIME: number = 1800000; //30 minutes

    @Output() onAlertChanged = new EventEmitter<AlertBaseEventArgs>();
    @Output() onAlertAdded = new EventEmitter<AlertBaseEventArgs>();
    @Output() onAlertRemoved = new EventEmitter<AlertBaseEventArgs>();

    constructor(
        private naSvc: NAService,
        private accountSvc: AccountService
    ) {
        this.className = 'AlertSvc';
        this.accountSvc.loginChanged.subscribe((isLogin: boolean) => {
            Logger.logInfo(this.className, '', 'Login status change. IsLogin? = ' + isLogin);
            if (!isLogin) {
                this.reset().subscribe(() => { });
            }
        });
    }

    private reset(): Observable<void> {
        Logger.logInfo(this.className, 'reset', '');
        return observableIif(
            () => { return this._refreshing },
            new Observable<boolean>((observer) => {
                HelperLib.checkState(1, () => { return !this._refreshing; }, () => {
                    observer.next(true);
                    observer.complete();
                });
            }),
            observableOf(true)
        ).pipe(
            map(() => {
                this._refreshing = false;
                this._alertList = [];
                this._lastRefreshTime = 0;
            })
        );
    }

    getAlertSampleData(): Observable<any> {
        return this.naSvc.getAlertSample(this.accountSvc.token);
    }

    getAlertSettingList(force: boolean = false): Observable<AlertInfo[]> {
        return new Observable((observer) => {
            HelperLib.checkState(1, () => { return !this._refreshing }, () => {
                observer.next(true);
                observer.complete();
            });
        }).pipe(
            map(() => this.needUpdate(force)),
            concatMap((needUpdate: boolean) => {
                if (needUpdate) {
                    this._refreshing = true;
                    this._alertList = [];
                    return this.naSvc.listAlert(this.accountSvc.token).pipe(
                        map((res: IAPIRx<IListAlertRxData>) => {
                            if (res.error === 0 && res.data) {
                                res.data.itemList.forEach(a => this._alertList.push(new AlertInfo(a)));
                                this._lastRefreshTime = new Date().getTime();
                            }

                            this._refreshing = false;

                            return this._alertList;
                        })
                    );
                }

                return observableOf(this._alertList);
            })
        );
    }

    getOpenAlertNotificationList(skip: number = 0, limit: number = 30): Observable<{ data: { id: string, lastDateTime: string, type: string, subject: string, sourceAlertID: string, isAck: boolean }[], total?: number, skip?: number, limit?: number, errorMessage?: string }> {
        return this.naSvc.listAlertNotifications(false, skip * limit, limit, this.accountSvc.token).pipe(
            map((res: IAPIRx<IAPIListAlertNotificationRxData>) => {
                if (res.error === 0 && res.data) {
                    return {
                        data: res.data.itemList.map((d: IAlertNotificationInfo) => {
                            return {
                                id: d.notificationID,
                                lastDateTime: d.notificationUpdatedDate,
                                subject: d.notificationSubject.replace(/\[[^\]]*\]/, ''),
                                sourceAlertID: d.alertSettingID,
                                isAck: d.isAcknowledged,
                                type: DeviceMonitorStatusMap[d.notificationMetadata.monitorID] ? DeviceMonitorStatusMap[d.notificationMetadata.monitorID].belongEvent.displayName : 'Unknown'
                            }
                        }),
                        total: res.data.total,
                        limit: res.data.limit,
                        skip: res.data.skip
                    };
                }

                return {
                    data: null,
                    errorMessage: res.errorMessage
                };
            })
        )
    }

    getClosedAlertNotificationList(skip: number = 0, limit: number = 30): Observable<{ data: { id: string, lastDateTime: string, type: string, subject: string, sourceAlertID: string }[], total?: number, skip?: number, limit?: number, errorMessage?: string }> {
        return this.naSvc.listAlertNotifications(true, skip * limit, limit, this.accountSvc.token).pipe(
            map((res: IAPIRx<IAPIListAlertNotificationRxData>) => {
                if (res.error === 0 && res.data) {
                    return {
                        data: res.data.itemList.map((d: IAlertNotificationInfo) => {
                            return {
                                id: d.notificationID,
                                lastDateTime: d.notificationUpdatedDate,
                                subject: d.notificationSubject.replace(/\[[^\]]*\]/, ''),
                                sourceAlertID: d.alertSettingID,
                                type: DeviceMonitorStatusMap[d.notificationMetadata.monitorID] ? DeviceMonitorStatusMap[d.notificationMetadata.monitorID].belongEvent.displayName : 'Unknown'
                            }
                        }),
                        total: res.data.total,
                        limit: res.data.limit,
                        skip: res.data.skip
                    };
                }

                return {
                    data: null,
                    errorMessage: res.errorMessage
                };
            })
        )
    }

    ackOpenNotifications(notificationIDList: string[]): Observable<{ notificationID: string, success: boolean }[]> {
        return observableOf(true).pipe(
            concatMap(() => {
                const obs: Observable<any>[] = [];

                notificationIDList.forEach((notificationID: string) => {
                    obs.push(this.naSvc.ackAlertNotification(notificationID, true, this.accountSvc.token).pipe(
                        map((res: IAPIRx<void>) => {
                            return {
                                notificationID: notificationID,
                                success: res.error === 0
                            }
                        })
                    ));
                });

                return forkJoin(obs);
            })
        );
    }

    unackOpenNotification(notificationID: string): Observable<boolean> {
        return this.naSvc.ackAlertNotification(notificationID, false, this.accountSvc.token).pipe(
            delay(1000),
            map((res: IAPIRx<void>) => {
                return res.error === 0;
            })
        );
    }

    private needUpdate(force: boolean = false): boolean {
        return force || !this._lastRefreshTime || (new Date().getTime() - this._lastRefreshTime) > this.LEAST_UPDATE_TIME ? true : false;
    }

    addAlert(a: AlertInfo): Observable<IAPIRx<IAlertSetting>> {
        Logger.logInfo(this.className, 'addAlert', '', a);
        return observableOf(a.id ? false : true).pipe(
            concatMap((isNewRule: boolean) => {
                if (isNewRule) {
                    const alertTxData: ICreateAlertTxData = {
                        alertEventID: a.alertEvent,
                        settingName: a.name,
                        settingData: a.alertData.export()
                    }

                    return this.naSvc.createAlert(alertTxData, this.accountSvc.token);
                }
                else {
                    const alertTxData: IUpdateAlertTxData = {
                        settingName: a.name,
                        settingData: a.alertData.export()
                    }

                    return this.naSvc.updateAlert({ alertSettingID: a.id }, alertTxData, this.accountSvc.token);
                }
            }),
            map((res: IAPIRx<IAlertSetting>) => {
                if (res && res.error === 0 && res.data) {
                    const found: AlertInfo = this._alertList.find(r => r.id === res.data.settingID);
                    if (found) {
                        found.resetByAlert(a);
                        if (this.onAlertChanged) {
                            this.onAlertChanged.emit(AlertChangedEventArgs.create(this._alertList, [found]));
                        }
                    }
                    else {
                        a.id = res.data.settingID;
                        this._alertList.push(a);
                        if (this.onAlertAdded) {
                            this.onAlertAdded.emit(AlertAddedEventArgs.create(this._alertList, [a]));
                        }
                    }
                }

                return res;
            })
        );
    }

    deleteAlert(alertID: string): Observable<string> {
        Logger.logInfo(this.className, 'deleteAlert', 'Delete alert with id = ' + alertID);
        return this.naSvc.removeAlert({ alertSettingID: alertID }, this.accountSvc.token).pipe(
            map((res: IAPIRx<IAlertSetting>) => {
                if (res && res.error === 0) {
                    const found: AlertInfo = this._alertList.find((rule: AlertInfo) => rule.id === alertID);
                    if (found) {
                        const removedAlertList: AlertInfo[] = this._alertList.splice(this._alertList.indexOf(found), 1);
                        if (removedAlertList.length > 0 && this.onAlertRemoved) {
                            this.onAlertRemoved.emit(AlertRemovedEventArgs.create(this._alertList, removedAlertList));
                        }
                    }

                    return '';
                }

                return HelperLib.getErrorMessage(res);
            })
        );
    }

    isAlertValid(a: AlertInfo): { isFault: boolean, errorMessage?: string } {
        const found: AlertInfo = this._alertList.find((alert: AlertInfo) => alert.id === a.id);
        if (!found && !a.name) {
            //The rule is a new rule, it should have a name
            return {
                isFault: true,
                errorMessage: '"Alert name" is empty'
            };
        }

        //check if name is duplicate
        if (this._alertList.find((rule: AlertInfo) => rule.name === a.name && rule.id !== a.id)) {
            return {
                isFault: true,
                errorMessage: '"Alert name" is duplicate'
            };
        }

        //check if it has an event type
        if (!a.alertData) {
            return {
                isFault: true,
                errorMessage: '"Alert data" is empty'
            };
        }

        //check if it has receiver(s)
        if (a.alertData.alertReceiverList.length === 0) {
            return {
                isFault: true,
                errorMessage: '"Deliver to" is empty'
            }
        }

        //check email format of receivers
        let error_format_emails: string[] = [];
        for (let receiver of a.alertData.alertReceiverList) {
            receiver = receiver.trim();
            if (!receiver) {
                continue;
            }

            const match_result = receiver.match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
            if (!match_result) {
                error_format_emails.push('[ ' + receiver + ' ]');
            }
        }

        if (error_format_emails.length > 0) {
            return {
                isFault: true,
                errorMessage: 'Invalid email format : ' + error_format_emails.join(', ')
            };
        }

        return {
            isFault: false
        };
    }

    updateDeviceAlertList(deviceList: DeviceInfo[], addAlertIDList?: string[], removeAlertIDList?: string[]): Observable<boolean> {
        return observableOf(true).pipe(
            concatMap(e => {
                const obs: Observable<any>[] = [];

                addAlertIDList.forEach(alertID => {
                    const alert: AlertInfo = this._alertList.find(r => r.id === alertID);
                    if (alert) {
                        const alertApplyDeviceIDList: { virtualDeviceID: string }[] = (alert.alertData as any).alertApplyDeviceList;
                        deviceList.forEach(d => alertApplyDeviceIDList.push({ virtualDeviceID: d.virtualId }));
                        obs.push(this.addAlert(alert).pipe(map((res: IAPIRx<IAlertSetting>) => {
                            return {
                                result: res,
                                hasNext: true
                            }
                        })));
                    }
                });

                removeAlertIDList.forEach(alertID => {
                    const alert: AlertInfo = this._alertList.find(r => r.id === alertID);
                    if (alert) {
                        let changed: boolean = false;
                        for (let d of deviceList) {
                            const alertApplyDeviceIDList: { virtualDeviceID: string }[] = (alert.alertData as any).alertApplyDeviceList;
                            const found: { virtualDeviceID: string } = alertApplyDeviceIDList.find(p => p.virtualDeviceID === d.virtualId);
                            if (found) {
                                alertApplyDeviceIDList.splice(alertApplyDeviceIDList.indexOf(found), 1);
                                changed = true;
                            }
                        }

                        if (changed) {
                            obs.push(this.addAlert(alert).pipe(map((res: IAPIRx<IAlertSetting>) => {
                                return {
                                    result: res,
                                    hasNext: true
                                }
                            })));
                        }
                    }
                });

                obs.push(observableOf({
                    result: null,
                    hasNext: false
                }));

                return obs;
            }),
            concatAll(),
            map((res: { result: IAPIRx<IAlertSetting>, hasNext: boolean }) => {
                if (!res.hasNext) {
                    return true;
                }
                else {
                    return false;
                }
            })
        );
    }
}