<div class="row">
  <div class="col-12">
    <div>Please confirm the following updates :</div>
    <div *ngIf="!_loading; else templateLoading">
      <div *ngFor="let category of _categoryList" class="card card-list">
        <div class="card-header">
          APK Type : {{ category | capitalFirstWord }}
        </div>
        <div class="card-body">
          <div class="form-group row">
            <span class="col-4">Latest version</span>
            <div class="col-8 pl-0">
              <div *ngIf="_bLoadingMap[category]" class="loading-box"></div>
              <ng-template [ngIf]="!_bLoadingMap[category]">
                <span *ngIf="_updateInfoMap[category].info?.version">{{ _updateInfoMap[category].info.version }}</span>
                <span *ngIf="!_updateInfoMap[category].info?.version" class="warning">No available update</span>
              </ng-template>
            </div>
          </div>
          <div class="form-group row">
            <span class="col-4">Update status</span>
            <div class="col-8 pl-0">
              <div *ngFor="let device of _updateInfoMap[category].devices" class="devices"
                [ngClass]="_bSpecific || device.isSelect ? '' : 'hidden'">
                <span *ngIf="!device.needUpdate; else templateNeedUpdate" class="text-danger fas fa-minus-circle">
                </span>
                <ng-template #templateNeedUpdate>
                  <span class="text-info fas fa-arrow-alt-circle-up">
                  </span>
                </ng-template>
                <span class="ml-2">{{ device?.virtualDeviceName }} : {{ device?.currentAPKVersion }} &rarr;</span>
                <span *ngIf="!_bLoadingMap[category] && _updateInfoMap[category].info?.version">
                  {{ _updateInfoMap[category].info.version }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-2">
        <span>
          <i class="fas fa-arrow-alt-circle-up text-info"></i>
          <span class="ml-1">Update required</span>
        </span>
        <span class="ml-2">
          <i class="fas fa-minus-circle text-danger"></i>
          <span class="ml-1">No need update</span>
        </span>
      </div>
    </div>
    <ng-template #templateLoading>
      <div class="loading">
        <div class="loading-box"></div>
      </div>
    </ng-template>
  </div>
</div>