import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { fromEvent, of as observableOf, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { catchError, switchMap, map, takeUntil, concatMap } from 'rxjs/operators';

import { AccountService } from '../../../../../../app/entry/account.service';
import { DialogPage } from '../../../../../../app/lib/common/common.data';
import { UserGroupInfo } from '../../group/user-group.data';
import { UserInfo } from '../../user.data';
import { UserService } from '../../user.service';

@Component({
    templateUrl: './user-policy-detail.component.html',
    styleUrls: ['../../user.style.css', './user-policy-detail.component.css']
})
export class UserPolicyDetailComponent implements OnInit, OnDestroy {
    _role: { name: string, desc: string };
    _userList: UserInfo[] = [];
    _displayUserList: UserInfo[] = [];
    _editUserRoleMap: { [userID: string]: { name: string, origin: UserInfo } } = {};
    _availableUserRoleList: { name: string, desc: string }[] = [];

    _isInEdit: boolean = false;
    _page: DialogPage = DialogPage.action;
    _enumPage: typeof DialogPage = DialogPage;

    _loadErrorMessage: string;
    _editErrorMessage: string;
    _loading: boolean;
    _searchTxt: string;

    private _allUnsubscribe: Subject<void> = new Subject();

    private _searchRef: ElementRef;
    @ViewChild('search')
    set search(v: ElementRef) {
        if (v && !this._searchRef) {
            this._searchRef = v;
            if (v) {
                const searchElement = this._searchRef.nativeElement;
                const searchInputOb = fromEvent(searchElement, 'input');
        
                searchInputOb.pipe(
                    debounceTime(200),
                    takeUntil(this._allUnsubscribe)
                ).subscribe((e: any) => {
                    this._searchTxt = e.target.value.toLocaleLowerCase();
                    this.refactor();
                });
            }
        }
    }

    constructor(
        private route: ActivatedRoute,
        private accountSvc: AccountService,
        private userSvc: UserService) {
    }

    ngOnInit(): void {
        this._loading = true;

        this.route.paramMap.pipe(
            switchMap((params: ParamMap) => {
                this.reset();
                return this.userSvc.getUserRole(params.get('id'))
            }),
            concatMap((res: { role: { name: string, desc: string }, isFault: boolean, errorMessage?: string }) => {
                if (res.isFault) {
                    throw res.errorMessage;
                }

                this._role = res.role;

                return this.userSvc.getUserList();
            }),
            concatMap((res: { userList: UserInfo[], isFault: boolean, errorMessage?: string }) => {
                if (res.isFault) {
                    throw res.errorMessage;
                }

                this._userList = res.userList.filter(u => u.userRole && u.userRole === this._role.name);
                this._editUserRoleMap = this._userList.reduce((prev, user: UserInfo) => {
                    prev[user.id] = {
                        name: user.userRole,
                        origin: user
                    };

                    return prev;
                }, {});
                this.refactor();

                return this.userSvc.getUserGroupList();
            }),
            concatMap(() => {
                return this.userSvc.getUserRoleList();
            }),
            map((res: { userRoleList: { name: string, desc: string }[], isFault: boolean, errorMessage?: string }) => {
                if (res.isFault) {
                    throw res.errorMessage;
                }

                this._availableUserRoleList = res.userRoleList;
                return true;
            }),
            takeUntil(this._allUnsubscribe),
            catchError((err: any) => {
                this._loadErrorMessage = err;

                return observableOf(true);
            })
        ).subscribe(() => {
            this._loading = false;
        });
    }

    ngOnDestroy(): void {
        this._allUnsubscribe.next();
        this._allUnsubscribe.complete();
    }

    supportUpdateUser(): boolean {
        return this.accountSvc.hasScope_admin_account_update();
    }

    getCurrentUserGroupName(userID: string): string {
        const userGroup: UserGroupInfo = this.userSvc.getCurrentUserGroupByID(userID);
        return userGroup ? userGroup.name : '';
    }

    private reset(): void {
        this._isInEdit = false;
        this._page = DialogPage.action;

        this._loadErrorMessage = null;
        this._editErrorMessage = null;
    }

    changeUserRole(user: UserInfo, role: { name: string, desc: string }): void {
        this._editUserRoleMap[user.id].name = role.name;
    }

    startEdit(): void {
        this._isInEdit = true;
        this._editErrorMessage = null;
    }

    cancelEdit(): void {
        this._isInEdit = false;
    }

    confirmEdit(): void {
        this._page = DialogPage.submit;

        const editUserList: UserInfo[] = Object.keys(this._editUserRoleMap).filter(userID => this._editUserRoleMap[userID].name !== this._editUserRoleMap[userID].origin.userRole).map(userID => {
            const u: UserInfo = this._editUserRoleMap[userID].origin.copy();
            u.userRole = this._editUserRoleMap[userID].name;

            return u;
        });

        const errorList: string[] = [];
        this.userSvc.updateMultipleUsers(editUserList).subscribe((res: { hasNext: boolean, isFault: boolean, errorMessage?: string, user?: UserInfo }) => {
            if (res.isFault) {
                return errorList.push(res.errorMessage);
            }

            if (res.user) {
                const localUser: UserInfo = this._userList.find(u => u.id === res.user.id);
                if (localUser) {
                    this._userList.splice(this._userList.indexOf(localUser), 1);
                }
            }

            if (!res.hasNext) {
                this._page = DialogPage.result;
                this._editErrorMessage = errorList.join(', ');
                this._isInEdit = false;
            }
        });
    }

    private refactor(): void {
        this._displayUserList = this._searchTxt ? this._userList.filter(u => u.email.toLocaleLowerCase().indexOf(this._searchTxt) >= 0) : this._userList;
    }
}