import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { EntryWrapperComponent } from './entry-wrapper.component';
import { LoginComponent } from './login/login.component';
import { SignUpComponent } from './signup/signup.component';
import { SignUpActivationComponent } from './signup/signup-activation.component';
import { PasswordForgetComponent } from './pwd/password-forget.component';
import { PasswordResetComponent } from './pwd/password-reset.component';
import { AuthGuard } from './auth-guard.service';
import { EnterpriseApplyFormComponent } from './register/enterprise-apply.component';

const loginRoutes: Routes = [
    {
        path: 'account',
        component: EntryWrapperComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'login',
                component: LoginComponent
            },
            {
                path: 'login/:account',
                component: LoginComponent
            },
            {
                path: 'register',
                component: SignUpComponent
            },
            {
                path: 'register/activate/:account',
                component: SignUpActivationComponent
            },
            {
                path: 'forgetPassword/:account',
                component: PasswordForgetComponent
            },
            {
                path: 'forgetPassword/verify/:account',
                component: PasswordResetComponent
            },
            {
                path: 'forgetPassword/verify/:account/:code',
                component: PasswordResetComponent
            },
            {
                path: 'application/enterprise',
                component: EnterpriseApplyFormComponent
            }
        ]
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(loginRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class LoginRoutingModule { }