import { Component, Input, ViewChild } from '@angular/core';

import { AlertInfo, OfflineAlertEventMonitorType, AlertType } from '../alert.data';
import { IAlertDataCtrl } from '../alertData/alert-data-ctrl.interface';
import { AlertDataCtrlDirective } from '../alertData/alert-data-ctrl.directive';
import { AlertDataCtrlService, AlertDataCtrlItem } from '../alertData/alert-data-ctrl.service';

@Component({
    selector: 'alert-config-report',
    templateUrl: './alert-config-report.component.html',
    styleUrls: ['../alert.style.css', './alert-config-report.component.css']
})
export class AlertConfigReportComponent {
    _enumAlertEventMonitorType: typeof OfflineAlertEventMonitorType = OfflineAlertEventMonitorType;

    _alert: AlertInfo;
    @Input()
    set alert(a: AlertInfo) {
        this._alert = a;
        if (a) {
            setTimeout(() => {
                this.showEventSpecialControl(this._alert.eventType.type);
            }, 0);   
        }
    }

    @ViewChild(AlertDataCtrlDirective) alertRuleSpecialCtrlHost: AlertDataCtrlDirective;

    constructor(private alertRuleSpecialCtrlSvc: AlertDataCtrlService) {}

    transformReceiverList(receivers: string[]): string {

        return receivers ? receivers.join('\n').trim() : '';
    }

    private showEventSpecialControl(alertType: AlertType): void {
        if (!this._alert) {
            return;
        }
        
        const item: AlertDataCtrlItem = this.alertRuleSpecialCtrlSvc.getItem(alertType);
        if (item) {
            const viewContainerRef = this.alertRuleSpecialCtrlHost.viewContainerRef;
            viewContainerRef.clear();

            const componentRef = viewContainerRef.createComponent(item.component);

            (<IAlertDataCtrl>componentRef.instance).alertData = this._alert.alertData;
            (<IAlertDataCtrl>componentRef.instance).isViewMode = true;
        }
    }
}