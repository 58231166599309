import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of, Observable } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { AndroidGroupType, FirmwareUpdateInfo } from './firmware-data';
import { Logger } from '../../../lib/common/logger';
import { IClass } from '../../../lib/common/common.data';
import { AppConfigService } from '../../../../app/app.config';
import { HelperLib } from '../../../../app/lib/common/helper.lib';

@Injectable()
export class FirmwareService implements IClass {
    className: string;
    readonly FIRMWARE_QUERY_INTERVAL: number = 1800000; //check update info every 30 mins
    private _updatingMap: { [firmwareID: string]: { lastQueryTime?: number, updating?: boolean, updateInfo?: FirmwareUpdateInfo } } = {}; // firmwareID: [product type]-[model name]-[firmware first digit]
    private _productMap: { [productType: string]: { prefix: string, modelList: { name: string, FW: number[] }[] } } = null;
    private _updatingProductMap: boolean = false;

    constructor(private http: HttpClient) {
        this.className = 'FirmwareSvc';
    }

    getLatestInfo(firmwareFamilyPrefix: string, modelName: string, targetAndroidGroupType: AndroidGroupType, forceRefresh: boolean = false): Observable<FirmwareUpdateInfo> {
        Logger.logInfo(this.className, 'getLatestInfo', 'Query firmware info for model ' + modelName);

        return this.getFirmwareProductMap().pipe(
            concatMap((productMap: { [productType: string]: { prefix: string, modelList: { name: string, FW: number[] }[] } }) => {
                if (!productMap) {
                    throw 'Cannot get product map';
                }

                const productMapByPrefixAsKey: { [prefix: string]: { productType: string, prefix: string, modelList: { name: string, FW: number[] }[] } } = Object.keys(productMap).reduce((prev, curr) => {
                    prev[productMap[curr].prefix] = Object.assign({ productType: curr }, productMap[curr]);
                    return prev;
                }, {});

                if (!productMapByPrefixAsKey[firmwareFamilyPrefix]) {
                    return of(null);
                }

                const firmwareMajorDigit: string = HelperLib.getFirmwareFirstDigitByAndroidGroup(targetAndroidGroupType);
                const symbolicID: string = this.getFirmwareSymbolicID(productMapByPrefixAsKey[firmwareFamilyPrefix]?.productType, modelName, firmwareMajorDigit);
                
                return of(this.needUpdate(productMapByPrefixAsKey[firmwareFamilyPrefix]?.productType, modelName, firmwareMajorDigit, forceRefresh)).pipe(
                    concatMap((needUpdate: boolean) => {
                        if (needUpdate) {
                            return this.queryFirmwareInfo(productMapByPrefixAsKey[firmwareFamilyPrefix]?.productType, modelName, firmwareMajorDigit);
                        }
                        else {
                            return of(this._updatingMap[symbolicID].updateInfo);
                        }
                    })
                );
            }),
            catchError(err => {
                return of(null);
            })
        );
    }

    private getFirmwareSymbolicID(productType: string, modelName: string, firmwareFirstDigit: string): string {
        return [productType, modelName, firmwareFirstDigit].join('-');
    }

    private needUpdate(productType: string, modelName: string, firmwareFirstDigit: string, forceUpdate: boolean = false): boolean {
        const symbolicID: string = this.getFirmwareSymbolicID(productType, modelName, firmwareFirstDigit);
        const needUpdate: boolean = forceUpdate || !this._updatingMap[symbolicID] || !this._updatingMap[symbolicID].updateInfo || (new Date().getTime() - this._updatingMap[symbolicID].lastQueryTime) > this.FIRMWARE_QUERY_INTERVAL;
        return needUpdate;
    }

    private getFirmwareProductMap(): Observable<{ [productType: string]: { prefix: string, modelList: { name: string, FW: number[] }[] } }> {
        if (this._productMap && Object.keys(this._productMap).length > 0) {
            return of(this._productMap);
        }

        if (this._updatingProductMap) {
            return new Observable((observer) => {
                HelperLib.checkState(1, () => !this._updatingProductMap, () => {
                    if (this._productMap && Object.keys(this._productMap).length > 0) {
                        observer.next(this._productMap);
                        observer.complete();
                    }
                    else {
                        this.getFirmwareProductMap().subscribe(res => {
                            observer.next(res);
                            observer.complete();
                        })
                    }
                })
            });
        }

        this._updatingProductMap = true;
        return this.http.get<any>(AppConfigService.configs.server.firmware + '/modelList.json', { responseType: 'json' }).pipe(
            map(res => {
                this._productMap = res;
                this._updatingProductMap = false;

                return this._productMap;
            }),
            catchError(err => {
                this._updatingProductMap = false;
                return of(null);
            })
        );
    }

    private queryFirmwareInfo(productType: string, modelName: string, firmwareFirstDigit: string): Observable<FirmwareUpdateInfo> {
        const symbolicID: string = this.getFirmwareSymbolicID(productType, modelName, firmwareFirstDigit);
        this._updatingMap[symbolicID] = this._updatingMap[symbolicID] || {};

        if (this._updatingMap[symbolicID].updating) {
            return new Observable((observer) => {
                HelperLib.checkState(1, () => { return !this._updatingMap[symbolicID].updating; }, () => {
                    observer.next(this._updatingMap[symbolicID].updateInfo);
                    observer.complete();
                });
            });
        }

        this._updatingMap[symbolicID].updating = true;
        return this.http.get<any>([AppConfigService.configs.server.firmware, productType, modelName, 'v' + firmwareFirstDigit, 'latest'].join('/'), { responseType: 'json' }).pipe(
            map(res => {
                res.firmwares = res.firmwares || [];
                // get latest only in case there are multiple firmware update infos in the 'latest' json file
                let datas: FirmwareUpdateInfo[] = res.firmwares.map((updateInfo: FirmwareUpdateInfo) => {
                    if (updateInfo.notice) {
                        updateInfo.notice = '<ul>' + updateInfo.notice.split('\n-').map((notice: string) => '<li>' + notice.replace(/^-/, '') + '</li>').join('') + '</ul>';
                    }

                    return updateInfo;
                }).sort((a: FirmwareUpdateInfo, b: FirmwareUpdateInfo) => a.firmwareVersion >= b.firmwareVersion ? -1 : 1);

                this._updatingMap[symbolicID].updateInfo = datas[0];
                this._updatingMap[symbolicID].updating = false;
                this._updatingMap[symbolicID].lastQueryTime = new Date().getTime();

                Logger.logInfo(this.className, 'queryFirmwareInfo', 'Latest firmware update info: ', this._updatingMap[symbolicID].updateInfo);

                return this._updatingMap[symbolicID].updateInfo;
            }),
            catchError((err) => {
                Logger.logError(this.className, 'queryFirmwareInfo', 'Query firmware update file failed. Error = ', err);
                this._updatingMap[symbolicID].updating = false;
                return of(null);
            })
        );
    }
}