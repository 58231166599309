import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../api.base';
import { ITicketData } from './api.ticket.common';

export interface IGetTicketPathParameter {
    ticketID: string;
}

export class APIGetTicketManager extends APIBaseManager<IGetTicketPathParameter, void, void, ITicketData> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_GetTicket';
        this._apiMethod = 'GET';
    }

    protected getRequestURL(pathParameters: IGetTicketPathParameter, queryParameters: void): string {
        return super.getRequestURL() + 'accounts/tickets/' + pathParameters.ticketID;
    }
}