import { ITimezoneInfo } from "../../../lib/common/common.data";
import { VirtualDeviceStatusHint } from "./virtual-device-info";

export enum DeviceCacheType {
    warranty = 'warranty',
    calendar = 'calendar',
    shadow = 'shadow',
    activity = 'activity',
    screenshot = 'screenshot'
}

export enum OnlineStatus {
    Offline = 'Offline',
    Disconnect = 'Disconnect',
    Online = 'Online',
    Syncing = 'Syncing',
    Error = 'Error',
    Pairing = 'Pairing'
}

export class HeartbeatInfo {
    lastUpdateTime: Date;
}

export enum TaskStatus {
    pending = 'pending',
    progress = 'progress',
    finish = 'finish',
    success = 'success',
    fail = 'fail',
    cancel = 'cancel'
}

export class DeviceActionInfo {
    id: string;
    type: string;
    name: string;
    issueDate: Date;
    startDate?: Date;
    finishDate?: Date;
    status?: TaskStatus;
    resources?: any;
    errorMessage?: string;
    progress?: number;
}

export class SSIDInfo {
    name: string;
    auth: string;
}

export class AvailableOptionInfo {
    Timezones: ITimezoneInfo[];
    Resolutions: string[];
    Rotations: string[];
    ssids: SSIDInfo[];
    HDCP?: boolean;
    IsEmpty: boolean;

    reset(): void {
        this.IsEmpty = true;
        this.HDCP = false;
        this.Timezones = [];
        this.Resolutions = [];
        this.Rotations = [];
        this.ssids = [];
    }

    constructor() {
        this.reset();
    }
}

export class AppStartScreensaverInfo {
    enabled: boolean; //fake property
    data: string;
    type: string;
    extras: { [name: string]: any };
    timeout: number;    //1~86400, default = 60s
    interactive: boolean;

    constructor(o?: any) {
        if (o) {
            this.enabled = o.data ? true : false;
            this.data = o.data;
            this.type = o.type || '';
            this.timeout = o.timeout || 300;
            this.interactive = o.interactive;
        }
        else {
            this.enabled = false;
            this.data = '';
            this.type = '';
            this.timeout = 300;
            this.interactive = false;
        }
    }

    static equal(a: AppStartScreensaverInfo, b: AppStartScreensaverInfo): boolean {
        if (a.enabled != b.enabled || a.data !== b.data || a.type !== b.type || a.timeout !== b.timeout || a.interactive !== b.interactive) {
            return false;
        }

        return true;
    }

    copy(): AppStartScreensaverInfo {
        const s: AppStartScreensaverInfo = new AppStartScreensaverInfo();
        s.data = this.data;
        s.enabled = this.enabled;
        s.type = this.type;
        s.timeout = this.timeout;
        s.interactive = this.interactive;
        if (this.extras) {
            s.extras = {};
            Object.keys(this.extras).forEach(name => {
                s.extras[name] = this.extras[name];
            });
        }

        return s;
    }

    isValid(): boolean {
        if (!this.enabled) {
            return true;
        }

        //allow the type do be determined by the player itself
        if (!this.data || !this.timeout) { // || !this.type) {
            return false;
        }

        return true;
    }

    //do not transform 'type' since it may occur other error when it is a redirect url?
    toSettingData(): { data: string, timeout: number, interactive: boolean } {
        return this.enabled ? {
            data: this.data,
            //type: this.type,
            timeout: this.timeout,
            interactive: this.interactive,
        } : null;
    }
}

export class AppStartOverlayInfo {
    enabled: boolean;
    keep: boolean;
    data: string;
    type: string;
    extras?: any;
    landscape: {
        enabled: boolean,
        left?: string,
        top?: string,
        width?: string,
        height?: string
    };
    portrait: {
        enabled: boolean,
        left?: string,
        top?: string,
        width?: string,
        height?: string
    }
    styles?: {
        left?: string,
        top?: string,
        width?: string,
        height?: string,
        backgrounColor?: string,
        constraint?: {
            screen: {
                orientation: string
            }
        }
    }[];

    constructor(o?: any, keep?: boolean) {
        if (o) {
            this.enabled = o.data ? true : false;
            this.data = o.data;
            this.type = o.type || 'qrcode';
            this.styles = o.styles || [];
        }
        else {
            this.enabled = false;
            this.data = '';
            this.type = 'qrcode';
            this.styles = [];
        }

        this.keep = keep || false;
        this.landscape = this.getLanscape();
        this.portrait = this.getPortrait();
    }

    private getLanscape(): {
        enabled: boolean,
        left?: string,
        top?: string,
        width?: string,
        height?: string
    } {
        const style_landscape = this.getStyle('landscape');
        return {
            enabled: style_landscape ? true : false,
            left: style_landscape && style_landscape.left ? style_landscape.left.replace(/px|%/, '') : '0',
            top: style_landscape && style_landscape.top ? style_landscape.top.replace(/px|%/, '') : '0',
            width: style_landscape && style_landscape.width ? style_landscape.width.replace(/px|%/, '') : '0',
            height: style_landscape && style_landscape.height ? style_landscape.height.replace(/px|%/, '') : '0'
        }
    }

    private getPortrait(): {
        enabled: boolean,
        left?: string,
        top?: string,
        width?: string,
        height?: string
    } {
        const style_portrait = this.getStyle('portrait');
        return {
            enabled: style_portrait ? true : false,
            left: style_portrait && style_portrait.left ? style_portrait.left.replace(/px|%/, '') : '0',
            top: style_portrait && style_portrait.top ? style_portrait.top.replace(/px|%/, '') : '0',
            width: style_portrait && style_portrait.width ? style_portrait.width.replace(/px|%/, '') : '0',
            height: style_portrait && style_portrait.height ? style_portrait.height.replace(/px|%/, '') : '0'
        }
    }

    static equal(a: AppStartOverlayInfo, b: AppStartOverlayInfo): boolean {
        if (a.enabled !== b.enabled) {
            return false;
        }
        if (a.data !== b.data) {
            return false;
        }
        if (a.landscape.enabled !== b.landscape.enabled) {
            return false;
        }
        if (a.landscape.width !== b.landscape.width || a.landscape.height !== b.landscape.height || a.landscape.left !== b.landscape.left || a.landscape.top !== b.landscape.top) {
            return false;
        }
        if (a.portrait.enabled !== b.portrait.enabled) {
            return false;
        }
        if (a.portrait.width !== b.portrait.width || a.portrait.height !== b.portrait.height || a.portrait.left !== b.portrait.left || a.portrait.top !== b.portrait.top) {
            return false;
        }

        return true;
    }

    copy(): AppStartOverlayInfo {
        const s: AppStartOverlayInfo = new AppStartOverlayInfo();
        s.enabled = this.enabled;
        s.keep = this.keep;
        s.data = this.data;
        s.type = this.type;
        s.landscape = this.getLanscape();
        s.portrait = this.getPortrait();
        s.styles = this.styles.map(s => {
            return {
                left: s.left,
                top: s.top,
                width: s.width,
                height: s.height,
                backgrounColor: s.backgrounColor,
                constraint: {
                    screen: {
                        orientation: s.constraint && s.constraint.screen ? s.constraint.screen.orientation : 'landscape'
                    }
                }
            }
        });
        if (this.extras) {
            s.extras = {};
            Object.keys(this.extras).forEach(name => {
                s.extras[name] = this.extras[name];
            });
        }

        return s;
    }

    getStyle(orientation: string) {
        return this.styles ? this.styles.find(s => s.constraint && s.constraint.screen ? s.constraint.screen.orientation === orientation : false) : null;
    }

    toSettingData(): { data: string, type: string, extras?: any, styles?: any[] } {
        if (!this.enabled) {
            return null;
        }

        const ret: { data: string, type: string, extras?: any, styles?: any[] } = {
            data: this.data,
            type: this.type,
            styles: []
        };

        if (this.extras) {
            ret.extras = this.extras;
        }
        if (this.landscape && this.landscape.enabled) {
            ret.styles.push({
                left: this.landscape.left + 'px',
                top: this.landscape.top + 'px',
                width: this.landscape.width + 'px',
                height: this.landscape.height + 'px',
                constraint: {
                    screen: {
                        orientation: 'landscape'
                    }
                }
            });
        }
        if (this.portrait && this.portrait.enabled) {
            ret.styles.push({
                left: this.portrait.left + 'px',
                top: this.portrait.top + 'px',
                width: this.portrait.width + 'px',
                height: this.portrait.height + 'px',
                constraint: {
                    screen: {
                        orientation: 'portrait'
                    }
                }
            });
        }

        return ret;
    }
}

export class AppStartInfo {
    packageName: string;
    className: string;
    action: string;
    uri: string;
    data: string;
    extras?: { [extraID: string]: boolean };
    overlay?: AppStartOverlayInfo;
    screensaver?: AppStartScreensaverInfo;
}

export class DeviceInfo {
    isSelect: boolean;
    virtualId: string;
    virtualName: string;
    virtualPairId: string;
    virtualDeviceOwner: string;
    virtualDeviceOwnerID: string;
    isPaired: boolean;
    taskInfos: DeviceActionInfo[];
    taskTrackTime: Date;

    tempPairingCode: string;

    currentSettings: { [key: string]: any };
    applySettings: { [key: string]: any };
    availableOptions?: AvailableOptionInfo;

    virtualDeviceSharing: {
        [accountID: string]: {
            accountID: string;
            accountName: string;
            permission: string[];
        }
    }

    groupIDPath: string[];
    groupID: string;
    virtualDeviceGroup: {
        groupID: string;
        groupIDPath: string[];
        groupName: string;
        groupNamePath: string[];
        isDefault: boolean;
        parentID: string;
        policies: {
            Configuration?: string[];
            Security?: string[];
        }
    };
    policies: {
        Configuration: string[];
        Security: string[];
    };
    features: {
        clearCache: {
            isSupport: boolean;
        },
        maintenance: {
            isSupport: boolean;
        },
        screenOff: {
            isSupport: boolean;
        },
        otp: {
            isSupport: boolean;
        },
        solution: {
            isSupport: boolean;
        },
        powersave: {
            isSupport: boolean;
        },
        screensaver: {
            isSupport: boolean;
        },
        remoteControl: {
            isSupport: boolean;
            keyEventSupport: boolean;
        }
    };
    scep?: {
        inUse?: {
            certName: string;
            provider?: {
                profile: string;
                type: string;
                url: string;
            },
            state: string;
            usages: string[];
            renewalDaysBeforeExpiration: number;
            notAfter?: string;
        },
        candidates?: {
            certName: string;
            provider?: {
                profile: string;
                type: string;
                url: string;
            },
            state: string;
            usages: string[];
            renewalDaysBeforeExpiration: number;
            notAfter?: string;
        }[];
    }

    constructor() {
        this.taskInfos = [];
        this.availableOptions = new AvailableOptionInfo();
        this.currentSettings = {};
        this.applySettings = {};
        this.policies = {
            Configuration: [],
            Security: []
        };
        this.features = {
            clearCache: { isSupport: false },
            maintenance: { isSupport: false },
            screenOff: { isSupport: false },
            otp: { isSupport: false },
            solution: { isSupport: false },
            screensaver: { isSupport: false },
            powersave: { isSupport: false },
            remoteControl: { isSupport: false, keyEventSupport: false}
        };
        this.scep = {

        };
    }
}

export class ServiceInfo {
    key?: string;
    expireDate?: Date;
    purchaseDate?: Date;
}

export class WarrantyInfo extends ServiceInfo {
    name: string;
    overrideStartDate?: string;
    overrideEndDate?: string;
}


export interface IDevicePairStatusChangeEventArgs {
    virtualDeviceID: string;
    virtualDeviceName: string;
    pairingCode: string;
    device: DeviceInfo;
    isPaired: boolean;
    statusHint: VirtualDeviceStatusHint;
    error?: string;
    errorMessage?: string;
    isFault: boolean;
}

export interface IDeviceUnpairStatusChangeEventArgs {
    virtualDeviceID: string;
    device: DeviceInfo;
    isPaired: boolean;
    error?: string;
    errorMessage?: string;
    isFault: boolean;
}

export interface IPolicyLockMap {
    [source: string]: {
        isSync: boolean;
        isPartialSync?: boolean;
        partialSyncDesc?: string;
        policyID: string;
        policyName: string;
        policyType: string;
    };
}

