<div *ngIf="Enable_contentUrl" class="form-group">
    <label for="config-appstart" class="title">
        AppStart
        <i *ngIf="_lockInfo" class="fas fa-lock ml-2 red-1" data-toggle="tooltip"
            [title]="'Lock by policy <<' + _lockInfo.policyName + '>>'"></i>
    </label>
    <div id="config-appstart" [ngClass]="_showBorder ? 'showBorder' : ''">
        <ul>
            <li>
                <label for="config-appstart-playback">Content URL</label>
                <div class="form-group">
                    <input #appstartContentUrl id="config-appstart-url" type="text" class="form-control form-control-sm"
                        [disabled]="_lockInfo || _disabled"
                        [value]="_config[constantSvc.DEVKEY_APPSTART_CONTENTURL]?.value">
                </div>
                <!-- 
                //reserve for future use              
                <label for="config-appstart-playback">Playback:</label>
                <div id="config-appstart-playback">
                    <div>
                        <div class="cmn-radio">
                            <input #appstartURLBtn id="appstart-radio-url" type="radio" name="appstart-radio-option"
                                [disabled]="_lockInfo || _disabled"
                                [checked]="_appstartPlaybackMode === _enumAppstartPlaybackMode.url"
                                (click)="changeAppstartPlaybackMode(_enumAppstartPlaybackMode.url)">
                            <label for="appstart-radio-url" class="radio"></label>
                            <span class="ml-2">URL</span>
                        </div>
                        <div class="ml-4">
                            <div class="form-group">
                                <label for="config-appstart-url">Data</label>
                                <input #appstartContentUrl id="config-appstart-url" type="text" class="form-control form-control-sm"
                                    [disabled]="_lockInfo || _disabled || !appstartURLBtn.checked"
                                    [value]="_config[constantSvc.DEVKEY_APPSTART_CONTENTURL]?.value">
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="cmn-radio">
                            <input #appstartAPKBtn id="appstart-radio-apk" type="radio" name="appstart-radio-option"
                                [disabled]="_lockInfo || _disabled"
                                [checked]="_appstartPlaybackMode === _enumAppstartPlaybackMode.apk"
                                (click)="changeAppstartPlaybackMode(_enumAppstartPlaybackMode.apk)">
                            <label for="appstart-radio-apk" class="radio"></label>
                            <span class="ml-2">APK</span>
                        </div>
                        <div class="ml-4">
                            <div class="form-group">
                                <label for="config-appstart-package">Package Name</label>
                                <input #appstartPackageName id="config-appstart-package" type="text" class="form-control form-control-sm"
                                    [disabled]="_lockInfo || _disabled || !appstartAPKBtn.checked"
                                    [value]="_config[constantSvc.DEVKEY_APPSTART_PACKAGENAME]?.value">
                            </div>
                            <div class="form-group">
                                <label for="config-appstart-class">Class Name</label>
                                <input #appstartClassName id="config-appstart-class" type="text" class="form-control form-control-sm"
                                    [disabled]="_lockInfo || _disabled || !appstartAPKBtn.checked"
                                    [value]="_config[constantSvc.DEVKEY_APPSTART_CLASSNAME]?.value">
                            </div>
                            <div class="form-group">
                                <label for="config-appstart-action">Action</label>
                                <input #appstartAction id="config-appstart-action" type="text" class="form-control form-control-sm"
                                    [disabled]="_lockInfo || _disabled || !appstartAPKBtn.checked"
                                    [value]="_config[constantSvc.DEVKEY_APPSTART_ACTION]?.value">
                            </div>
                        </div>
                    </div>
                </div> 
                -->
            </li>
            <li>
                <label for="config-appstart-extraOptions">Extras</label>
                <div *ngIf="Enable_appStartExtraOption" id="config-appstart-extraOptions">
                    <ng-template ngFor let-extra [ngForOf]="_appstartExtraList">
                        <div *ngIf="!extra.condition || extra.condition(_androidGroup)" class="appstart-extra-option">
                            <input [id]="extra.property" type="checkbox" class="cmn-checkbox"
                                [disabled]="_lockInfo || _disabled" [checked]="_config[extra.property]?.value"
                                (change)="onExtraChanged(extra, $event.target.checked)" />
                            <label [for]="extra.property">
                                <svg>
                                    <use xlink:href="#checkmark" />
                                </svg>
                                <span class="sr-only">{{ extra.displayName }}</span>
                            </label>
                            <span class="ml-1">{{ extra.displayName }}</span>
                        </div>
                    </ng-template>
                </div>
            </li>
        </ul>
    </div>
</div>