import { Injectable, Directive } from '@angular/core';
import { of as observableOf, Observable, iif as observableIif } from 'rxjs';
import { map, concatMap, delay } from 'rxjs/operators';

import { IAPIRx } from '../../../API/api.base';
import { NAService } from '../../../API/na.service';
import { ReportConfig } from '../../../API/v1/Report/api.report.data';
import { IListReportRxData } from '../../../API/v1/Report/api.report.list';
import { AccountService } from '../../../entry/account.service';
import { IClass } from '../../../lib/common/common.data';
import { HelperLib, REFRESH_DURATION } from '../../../lib/common/helper.lib';
import { ReportInfo } from './report.data';

@Directive()
@Injectable()
export class ReportService implements IClass {
    className: string;
    private readonly REPORT_SETTING_UPDATE_TIME: number = REFRESH_DURATION * 60000;
    _reportSetting: { config: ReportConfig, lastUpdateTime: number, updating: boolean } = {
        config: new ReportConfig(), lastUpdateTime: 0, updating: false
    };

    _report: { data: { [page: number]: { [reportID: string]: ReportInfo } }, total: number, updating: boolean } = {
        data: {}, total: 0, updating: false
    };

    constructor(
        private accountSvc: AccountService,
        private naSvc: NAService
    ) {
        this.className = 'ReportSvc';
        this.accountSvc.loginChanged.subscribe((isLogin: boolean) => {
            if (!isLogin) {
                this.reset().subscribe();
            }
        });
    }

    getReports(force: boolean = false, page: number = 0, limit: number = 30): Observable<{ data: ReportInfo[], skip?: number, total?: number, limit?: number, waiting: boolean, errorMessage?: string }> {
        if (this._report.updating) {
            return observableOf({ data: [], waiting: true });
        }

        if (force) {
            //reset all page data
            this._report.data = {};
        }

        if (!this._report.data[page]) {
            this._report.updating = true;
            return this.naSvc.listReports(page * limit, limit, this.accountSvc.token).pipe(
                map((res: IAPIRx<IListReportRxData>) => {
                    if (res.error === 0 && res.data) {
                        this._report.data[page] = res.data.itemList.reduce((acc, curr) => {
                            acc[curr.weeklyReportID] = new ReportInfo(curr);

                            return acc;
                        }, {});
                        this._report.total = res.data.total;
                    }

                    this._report.updating = false;

                    return {
                        data: this._report.data[page] ? Object.keys(this._report.data[page]).map((reportID: string) => this._report.data[page][reportID]) : [],
                        skip: res.data.skip,
                        limit: res.data.limit,
                        total: res.data.total,
                        waiting: false,
                        errorMessage: HelperLib.getErrorMessage(res)
                    };
                })
            );
        }

        return observableOf({
            data: Object.keys(this._report.data[page]).map((reportID: string) => this._report.data[page][reportID]),
            skip: page * limit,
            limit: limit,
            total: this._report.total,
            waiting: false
        });;
    }

    getReportSetting(): Observable<{ config: ReportConfig, errorMessage?: string }> {
        return new Observable<boolean>((observer) => {
            HelperLib.checkState(1, () => { return !this._reportSetting.updating }, () => {
                observer.next(true);
                observer.complete();
            });
        }).pipe(
            map(() => this.needUpdateReportSetting()),
            concatMap((needUpdate: boolean) => {
                if (needUpdate && !this._reportSetting.updating) {
                    this._reportSetting.updating = true;
                    return this.naSvc.getReportSetting(this.accountSvc.token).pipe(
                        map((res: IAPIRx<ReportConfig>) => {
                            if (res.error === 0 && res.data) {
                                this._reportSetting.config = res.data;
                            }

                            this._reportSetting.lastUpdateTime = new Date().getTime();
                            this._reportSetting.updating = false;

                            return { config: this._reportSetting.config, errorMessage: HelperLib.getErrorMessage(res) };
                        })
                    );
                }
                else {
                    return observableOf({ config: this._reportSetting.config });
                }
            })
        );
    }

    updateReportSetting(config: ReportConfig): Observable<{ config: ReportConfig, errorMessage?: string }> {
        this._reportSetting.updating = true;
        return this.naSvc.updateReportSetting(config, this.accountSvc.token).pipe(
            delay(1000),
            map((res: IAPIRx<ReportConfig>) => {
                if (res.error === 0 && res.data) {
                    this._reportSetting.config = res.data;
                    this._reportSetting.lastUpdateTime = new Date().getTime();
                    this._reportSetting.updating = false;
                }

                return { config: this._reportSetting.config, errorMessage: HelperLib.getErrorMessage(res) };
            })
        )
    }

    private needUpdateReportSetting(): boolean {
        return !this._reportSetting.lastUpdateTime || (new Date().getTime() - this._reportSetting.lastUpdateTime) > this.REPORT_SETTING_UPDATE_TIME ? true : false;
    }

    private reset(): Observable<void> {
        return observableIif(
            () => { return this._reportSetting.updating || this._report.updating },
            new Observable<boolean>((observer) => {
                HelperLib.checkState(1, () => { return !this._reportSetting.updating && !this._report.updating; }, () => {
                    observer.next(true);
                    observer.complete();
                });
            }),
            observableOf(true)
        ).pipe(
            map(() => {
                this._reportSetting = { config: null, lastUpdateTime: 0, updating: false };
                this._report = { data: {}, total: 0, updating: false };
            })
        );
    }
}