import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AppCacheData, AppMode } from './app.data';
import { AppService } from './app.service';
import { AccountService } from './entry/account.service';
import { Logger } from './lib/common/logger';

declare global {
  interface Window { IAdeaNamespace: any; }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  _demoIndex: number = 0;
  _demoRouteList: { route: string, hint?: string }[] = [];
  _appMode: AppMode = AppMode.normal;
  _enumAppMode: typeof AppMode = AppMode;

  private _allUnsubscribe: Subject<void> = new Subject();

  constructor(
    private accountSvc: AccountService, private appSvc: AppService) {
    window.IAdeaNamespace = window.IAdeaNamespace || { setLogLevel: this.setLogLevel };
  }

  ngOnInit(): void {
    Logger.logInfo('App', 'OnInit', '');

    this.appSvc.onAppModeChanged.pipe(
      takeUntil(this._allUnsubscribe)
    ).subscribe((res: { mode: AppMode, data: AppCacheData }) => {
      this._appMode = res.mode;
      this._demoRouteList = res.data.demoRouteList;
    });
  }

  ngOnDestroy(): void {
    this._allUnsubscribe.next();
    this._allUnsubscribe.complete();
  }

  validating(): boolean {
    return this.accountSvc.isChecking;
  }

  goDemoNext(): void {
    this._demoIndex = this._demoIndex + 1 < this._demoRouteList.length ? this._demoIndex + 1 : this._demoIndex;
    this.appSvc.goToRoute(this._demoRouteList[this._demoIndex].route);
  }

  goDemoBack(): void {
    this._demoIndex = this._demoIndex - 1 >= 0 ? this._demoIndex - 1 : this._demoIndex;
    this.appSvc.goToRoute(this._demoRouteList[this._demoIndex].route);
  }

  setLogLevel(logLevel: number): void {
    Logger.setLogLevel(logLevel);
  }

  resetResMode(): void {
    this.appSvc.enterNormalMode();
  }
}
