import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ScreenOffInfo, ScreenOffScheduleMode } from './screen.data';
import { IUIElement } from '../../uiElement.interface';
import { WeekScheduleInfo } from '../basic/weekScheduleSelector.data';

@Component({
    selector: 'na-screenOff',
    templateUrl: './screenOff.component.html',
    styleUrls: ['../../uiElement.style.css', './screenOff.component.css']
})
export class ScreenOffComponent implements OnInit, IUIElement {
    readonly NAME: string = 'screen off';
    _enumScreenOffScheduleMode: typeof ScreenOffScheduleMode = ScreenOffScheduleMode;

    _info: ScreenOffInfo = new ScreenOffInfo();
    @Input('data')
    set data(s: ScreenOffInfo) {
        if (s) {
            this._info = s;
        }
    }

    _disabled: boolean = false;
    @Input('disabled')
    set disabled(s: boolean) {
        this._disabled = s;
        this._info.enabled = !s;
    }

    _unsupportReason: string;
    @Input('unsupportReason')
    set unsupportReason(s: string) {
        this._unsupportReason = s;
    }

    _lockInfo: { isSync: boolean, policyID: string, policyName: string };
    @Input('lock')
    set lock(s: { isSync: boolean, policyID: string, policyName: string }) {
        this._lockInfo = s;
    }

    _showSwitch: boolean = true;
    @Input('showSwitch')
    set showSwitch(s: boolean) {
        this._showSwitch = s;
    }

    @Output() onDataChanged = new EventEmitter<ScreenOffInfo>();
   
    ngOnInit(): void {
        this.notify();
    }

    enableFeature(checked: boolean): void {
        this._info.enabled = checked;
        this.notify();
    }

    onScheduleChanged(schedule: WeekScheduleInfo): void {
        this._info.schedule = schedule;
        this.notify();
    }

    notify(): void {
        this.onDataChanged.emit(this._info);
    }
}