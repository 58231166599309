import { Component, OnInit, OnDestroy, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { Subject, fromEvent } from 'rxjs';
import { takeUntil, debounceTime, map } from 'rxjs/operators';

import { DeviceInfo, OnlineStatus } from '../data/device-info';
import { DeviceService } from '../device.service';
import { SortType } from '../../../lib/common/common.data';
import { DeviceGroupInfo, DeviceGroupMode, GroupSwitch, DEVICE_GROUP_ID_HOME } from '../group/group.data';
import { DeviceGroupService } from '../group/dev-group.service';
import { HelperLib, REFRESH_DURATION } from '../../../lib/common/helper.lib';
import { ConstantService } from '../../../lib/common/constant.service';
import { DashboardView } from '../../../../app/content/user-preference.data';
import { UserPreferenceService } from '../../../../app/content/user-preference.service';
import { AccountService } from '../../../../app/entry/account.service';
import { DisplayItem } from '../data/display-item';
import { DevFuncItem } from '../../../../app/content/devfunc/dev-func-item';
import { DevFuncInterface } from '../../../../app/content/devfunc/dev-func.interface';
import { AppConfigService } from '../../../../app/app.config';
import { DevFuncDirective } from '../../../../app/content/devfunc/dev-func.directive';
import { DevFuncService } from '../../../../app/content/devfunc/dev-func.service';

@Component({
    selector: 'na-dev-pickup-view',
    templateUrl: './dev-pickup-view.component.html',
    styleUrls: ['./dev-pickup-view.component.css']
})
export class DevicePickupViewComponent implements OnInit, OnDestroy {
    readonly NUMBER_IN_PAGE_OPTIONS: number[] = [30, 100, 200];
    readonly SCREENSHOT_REFRESH_DURATION: number = 1200000;

    readonly Enable_BasicConfig: boolean = AppConfigService.configs.devPage.func.basicConfig.enabled;
    readonly Enable_Apk_update: boolean = AppConfigService.configs.devPage.func.apkUpdateEnabled;
    readonly Enable_Firmware_update: boolean = AppConfigService.configs.devPage.func.firmwareUpdateEnabled;
    readonly Enable_Reboot: boolean = AppConfigService.configs.devPage.func.rebootEnabled;
    readonly Enable_Troubleshoot: boolean = AppConfigService.configs.devPage.func.troubleshootEnabled;
    readonly Enable_Share: boolean = AppConfigService.configs.devPage.func.shareEnabled;
    readonly Enable_ClearCache: boolean = AppConfigService.configs.devPage.func.clearCacheEnabled;
    readonly Enable_BatchBasicConfig: boolean = AppConfigService.configs.devPage.func.basicConfig.batchEnabled;

    _deviceList: DeviceInfo[] = [];
    _displayDeviceListUnderGroup: DeviceInfo[] = [];
    _displayDeviceList: DeviceInfo[] = [];
    _showTotalDeviceCount: boolean = false;
    _isRemoteCtrlPreCheckSupport: boolean = false;
    _viewType: DashboardView = DashboardView.table;
    _enumViewType: typeof DashboardView = DashboardView;

    _currentSortKey: string;
    _currentSortType: SortType;
    _enumSortType: typeof SortType = SortType;
    _searchInfo: { key: string, value: string };
    _enumOnlineType: typeof OnlineStatus = OnlineStatus;

    _groupSwitch: GroupSwitch = GroupSwitch.on;
    _refreshCounter: number = 0;
    _loading: boolean = false;
    _exporting: boolean = false;
    _bAllSelected: boolean = false;

    _deviceOwnerName: string;

    _screenshotInfo: {
        [virtualId: string]: {
            url: SafeUrl,
            pending?: boolean
        }
    } = {};

    _allUnsubscribe: Subject<void> = new Subject();

    _numberInPage: number = this.NUMBER_IN_PAGE_OPTIONS[0];
    _currentPage: number = 1;
    _displayDeviceAmount: number = 0;
    _selectedDeviceAmount: number = 0;

    _searchKeyList: { key: string, langKey: string }[] = [];
    _activeSearchKey: { key: string, langKey: string };

    //filter
    _onlineStatusMap: { [state: string]: boolean } = {};
    _hasOnlineStatusFilter: boolean = false;

    _tableColMap: { [key: string]: DisplayItem } = {};
    _tableColumnList: DisplayItem[] = [];

    private _reportLinkRef: ElementRef;
    @ViewChild('reportLink', { static: true })
    set reportLink(v: ElementRef) {
        this._reportLinkRef = v;
    }

    private _searchRef: ElementRef;
    @ViewChild('search', { static: true })
    set search(v: ElementRef) {
        this._searchRef = v;
        const searchElement = this._searchRef.nativeElement;
        const searchInputOb = fromEvent(searchElement, 'input');

        searchInputOb.pipe(
            debounceTime(200),
            takeUntil(this._allUnsubscribe)
        ).subscribe((e: any) => {
            this._searchInfo = { key: this._activeSearchKey.key, value: e.target.value.toLocaleLowerCase() };
            this.refactorDevices('search');
        });
    }

    private _dragImgRef: ElementRef;
    @ViewChild('dragImgSubstitute', { static: true })
    set dragImgElement(v: ElementRef) {
        this._dragImgRef = v;
    }

    @ViewChild(DevFuncDirective, { static: true }) devFuncHost: DevFuncDirective;
    @Output() onGroupInspect = new EventEmitter<DeviceGroupInfo>();
    @Output() onItemSelectCountChange = new EventEmitter<{ group?: number, device: number }>();

    constructor(
        private constantSvc: ConstantService,
        private accountSvc: AccountService,
        private devSvc: DeviceService,
        private groupSvc: DeviceGroupService,
        private userPrefSvc: UserPreferenceService,
        private devFuncSvc: DevFuncService) {
    }

    ngOnInit(): void {
        this._deviceOwnerName = this.accountSvc.isEnterprise() ? this.accountSvc.enterpriseName : '';
        this._groupSwitch = this.groupSvc.groupSwitch;
        this._numberInPage = this.userPrefSvc.userPreference.home.device.pageCapacity;
        this.updateViewRelatedFunction();
        this.constantSvc.getOnlineStatusList().forEach(o => {
            this._onlineStatusMap[o] = true;
        })
        this.updateOnlineStatusFilterColor();
        this.initColumnAndSearch();

        this.groupSvc.onActiveGroupChanged.pipe(
            debounceTime(300),
            takeUntil(this._allUnsubscribe)
        ).subscribe((g: DeviceGroupInfo) => {
            this.refactorDevices('group active');
            this.update_item_selection_count();
        });

        this.groupSvc.onGroupOwnerChanged.pipe(
            takeUntil(this._allUnsubscribe)
        ).subscribe((ret: { accountID: string, mode: DeviceGroupMode }) => {
            this.refactorDevices('group owner change');
        });

        this.groupSvc.onGroupSwitchChanged.pipe(
            debounceTime(300),
            takeUntil(this._allUnsubscribe)
        ).subscribe((groupSwitch: GroupSwitch) => {
            this._groupSwitch = groupSwitch;
            this.updateTableColumnList();
            this.refactorDevices('group switch change');
        });

        this._loading = true;
        HelperLib.checkState(1, () => { return this.groupSvc.isReady }, () => {
            this.devSvc.getDevicesByBatch('device-overview-table.onInit').subscribe((res: { hasNext: boolean, isFault: boolean, devices: DeviceInfo[], total: number, errorMessage?: string }) => {
                if (!res.isFault) {
                    this._deviceList = this._deviceList.concat(res.devices.filter(d => d.isPaired));
                    this.refactorDevices('default');
                }

                this.update_item_selection_count();
                this._loading = false;
            });
        });

        fromEvent(window, 'resize').pipe(
            debounceTime(500)
        ).subscribe(e => {
            this.updateViewRelatedFunction();
        });
    }

    ngOnDestroy(): void {
        this._allUnsubscribe.next();
        this._allUnsubscribe.complete();
    }

    private updateViewRelatedFunction(): void {
        this._isRemoteCtrlPreCheckSupport = this.devSvc.isDeviceSupportRemoteCtrl();
        this._viewType = HelperLib.isMobileLayout() ? DashboardView.grid : (this.userPrefSvc.userPreference.home.device.view || DashboardView.table);
    }

    private initColumnAndSearch(): void {
        this._tableColMap = {};
        this._searchKeyList = [];

        const p: { [key: string]: boolean } = this.userPrefSvc.userPreference.home.device.tableLayoutColumnOptionMap;
        //init table column
        this._tableColMap[this.constantSvc.DEVKEY_FAKE_DISPLAYNAME] = new DisplayItem('name', this.constantSvc.DEVKEY_FAKE_DISPLAYNAME, 'key-name', false, true, p ? p[this.constantSvc.DEVKEY_FAKE_DISPLAYNAME] : true);
        this._tableColMap[this.constantSvc.DEVKEY_INFO_MODEL] = new DisplayItem('model', this.constantSvc.DEVKEY_INFO_MODEL, 'key-dev-model', true, true, p ? p[this.constantSvc.DEVKEY_INFO_MODEL] : true);
        this._tableColMap[this.constantSvc.DEVKEY_NET_LAN_MAC] = new DisplayItem('mac', this.constantSvc.DEVKEY_NET_LAN_MAC, 'key-dev-MAC', true, true, p ? p[this.constantSvc.DEVKEY_NET_LAN_MAC] : false);
        this._tableColMap[this.constantSvc.DEVKEY_NET_LAN_IP] = new DisplayItem('ip', this.constantSvc.DEVKEY_NET_LAN_IP, 'key-net-ip', true, true, p ? p[this.constantSvc.DEVKEY_NET_LAN_IP] : false);
        this._tableColMap[this.constantSvc.DEVKEY_INFO_FW] = new DisplayItem('fw', this.constantSvc.DEVKEY_INFO_FW, 'key-dev-firmwareVersion', true, true, p ? p[this.constantSvc.DEVKEY_INFO_FW] : true);
        this._tableColMap[this.constantSvc.DEVKEY_INFO_APKVERSION] = new DisplayItem('apk', this.constantSvc.DEVKEY_INFO_APKVERSION, 'key-dev-apkVersion', true, true, p ? p[this.constantSvc.DEVKEY_INFO_APKVERSION] : false);
        this._tableColMap[this.constantSvc.DEVKEY_FAKE_HEARTBEAT] = new DisplayItem('heartbeat', this.constantSvc.DEVKEY_FAKE_HEARTBEAT, 'key-dev-heartbeat', true, true, p ? p[this.constantSvc.DEVKEY_FAKE_HEARTBEAT] : false);
        this._tableColMap[this.constantSvc.DEVKEY_INFO_WARRANTY_ENDDATE] = new DisplayItem('warranty', this.constantSvc.DEVKEY_INFO_WARRANTY_ENDDATE, 'key-warranty', true, true, p ? p[this.constantSvc.DEVKEY_INFO_WARRANTY_ENDDATE] : false);
        this._tableColMap[this.constantSvc.DEVKEY_FAKE_OWNER] = new DisplayItem('owner', this.constantSvc.DEVKEY_FAKE_OWNER, 'key-dev-owner', false, false, p ? p[this.constantSvc.DEVKEY_FAKE_OWNER] : true);
        this._tableColMap[this.constantSvc.DEVKEY_FAKE_GROUPNAME] = new DisplayItem('group', this.constantSvc.DEVKEY_FAKE_GROUPNAME, 'key-group', false, false, p ? p[this.constantSvc.DEVKEY_FAKE_GROUPNAME] : true);
        this._tableColMap[this.constantSvc.DEVKEY_FAKE_SCEP_STATUS] = new DisplayItem('scepStatus', this.constantSvc.DEVKEY_FAKE_SCEP_STATUS, 'SCEP status', true, true, p ? p[this.constantSvc.DEVKEY_FAKE_SCEP_STATUS] : true, false);

        this.updateTableColumnList();

        //init search key
        this._searchKeyList.push({ key: this.constantSvc.DEVKEY_INFO_PNAME, langKey: 'key-dev-name' });
        this._searchKeyList.push({ key: this.constantSvc.DEVKEY_INFO_MODEL, langKey: 'key-dev-model' });
        this._searchKeyList.push({ key: this.constantSvc.DEVKEY_NET_LAN_MAC, langKey: 'key-dev-MAC' });
        this._searchKeyList.push({ key: this.constantSvc.DEVKEY_INFO_FW, langKey: 'key-dev-firmwareVersion' });
        this._searchKeyList.push({ key: this.constantSvc.DEVKEY_NET_LAN_IP, langKey: 'key-net-ip' });
        this._searchKeyList.push({ key: 'owner', langKey: 'key-dev-owner' });

        this._activeSearchKey = this._searchKeyList[0];
    }

    private updateTableColumnList(): void {
        this._tableColMap[this.constantSvc.DEVKEY_FAKE_OWNER].allowChoose = this._tableColMap[this.constantSvc.DEVKEY_FAKE_OWNER].allowShow = this._tableColMap[this.constantSvc.DEVKEY_FAKE_GROUPNAME].allowChoose = this._tableColMap[this.constantSvc.DEVKEY_FAKE_GROUPNAME].allowShow = this._groupSwitch === GroupSwitch.off;
        this._tableColumnList = Object.keys(this._tableColMap).map((key: string) => this._tableColMap[key]);
    }

    //get if remote control is support; if not, hide it.
    supportRemoteCtrl(device: DeviceInfo): boolean {
        return this.devSvc.isDeviceSupportRemoteCtrl(device);
    }

    refreshDevices(): void {
        this._refreshCounter = REFRESH_DURATION * 2;
        HelperLib.countdown(this._refreshCounter, 0, (remain_counter: number) => {
            this._refreshCounter = remain_counter;
        });

        this._loading = true;
        this._deviceList = [];
        this._displayDeviceList = [];
        this._screenshotInfo = {};

        this.devSvc.getDevicesByBatch('device-overview-table.refresh', true).subscribe((res: { isFault: boolean, devices: DeviceInfo[], errorMessage?: string }) => {
            if (!res.isFault) {
                this._deviceList = this._deviceList.concat(res.devices.filter(d => d.isPaired));
                this.refactorDevices('refresh', true);
            }

            this._loading = false;
        });
    }

    exportDevices(): void {
        this._exporting = true;
        //for next release
        //let activeGroupID: string = this.groupSvc.getActiveGroup()?.id;
        //activeGroupID = activeGroupID === DEVICE_GROUP_ID_HOME ? null : activeGroupID;
        this.devSvc.export(null, this._onlineStatusMap, this._searchInfo).pipe(
            map((c: { header: string, metadata?: string[], dataList: string[][], date: string }) => {
                return HelperLib.downloadCsv(this._reportLinkRef.nativeElement, 'DeviceList_' + c.date, c.header, c.dataList, c.metadata);
            })
        ).subscribe(() => {
            this._exporting = false;
        });
    }

    changeNumberInPage(currentNumberInPage: number): void {
        if (this._numberInPage !== currentNumberInPage) {
            this._numberInPage = currentNumberInPage;
            this.userPrefSvc.changeHomeDevicePageCapacity(this._numberInPage);
            this.refactorDevices('numberInPage');
        }
    }

    changeTableColumnOption(option: DisplayItem): void {
        option.show = !option.show;
        this.userPrefSvc.changeHomeTableViewColumn(option.key, option.show);
    }

    chooseSearchKey(searchKey: { key: string, langKey: string }): void {
        this._activeSearchKey = searchKey;
        this._searchRef.nativeElement.value = null;
        this._searchInfo = { key: this._activeSearchKey.key, value: null };
        this.refactorDevices('search');
    }

    changeOnlineStatusFilter(key: string, checked: boolean): void {
        this._onlineStatusMap[key] = checked;
        if (!this._onlineStatusMap[key]) {
            this._hasOnlineStatusFilter = true;
        }
        else {
            this.updateOnlineStatusFilterColor();
        }

        this.refactorDevices('online filter');
    }

    private updateOnlineStatusFilterColor(): void {
        this._hasOnlineStatusFilter = false;
        for (let status of Object.keys(this._onlineStatusMap)) {
            if (!this._onlineStatusMap[status]) {
                this._hasOnlineStatusFilter = true;
                break;
            }
        }
    }

    changeView(targetView: DashboardView): void {
        this._viewType = targetView;
        this.userPrefSvc.changeHomeDeviceView(this._viewType);
        if (this._viewType === DashboardView.grid) {
            this.update_screenshot_url();
        }
    }

    selectAllDevicesOnCurrentPage(checked: boolean): void {
        this._bAllSelected = checked;
        this._displayDeviceList.filter(d => d.virtualId).forEach(d => d.isSelect = checked);
        this.update_item_selection_count();
    }

    selectDevice(d: DeviceInfo, checked: boolean): void {
        d.isSelect = checked;
        this._bAllSelected = d.isSelect ? this.get_all_select_status() : false;
        this.update_item_selection_count();
    }

    isTableViewShrink(): boolean {
        return this._groupSwitch == GroupSwitch.on && this.groupSvc.getActiveGroup()?.id !== DEVICE_GROUP_ID_HOME;
    }

    onPageChange(currentPage: number): void {
        if (this._loading) {
            return;
        }

        if (this._currentPage !== currentPage) {
            this._currentPage = currentPage;
            this.refactorDevices('switchPage');
        }
    }

    refreshScreenshot(device: DeviceInfo): void {
        this.update_screenshot_for_device(device, true);
    }

    private update_screenshot_url(force: boolean = false): void {
        if (this._deviceList.length === 0) {
            return;
        }

        this._displayDeviceList.forEach((d: DeviceInfo) => {
            this.update_screenshot_for_device(d, force);
        });
    }

    private update_screenshot_for_device(dev: DeviceInfo, force: boolean = false): void {
        const deviationTime = 100 + Math.random() * 3000;

        if (!this._screenshotInfo[dev.virtualId].pending) {
            if (force) {
                this._screenshotInfo[dev.virtualId].url = null;
            }
            this._screenshotInfo[dev.virtualId].pending = true;

            setTimeout(() => {
                this.devSvc.updateScreenshot(dev, force).subscribe((res: { url: SafeUrl, useCache?: boolean, lastUpdateTime: Date }) => {
                    this._screenshotInfo[dev.virtualId].pending = false;
                    this._screenshotInfo[dev.virtualId].url = res.url;
                });
            }, deviationTime);
        }
    }

    private update_item_selection_count(): void {
        this._selectedDeviceAmount = this._displayDeviceListUnderGroup.filter(d => d.isSelect).length;
        this.onItemSelectCountChange.emit({
            device: this._selectedDeviceAmount
        });
    }

    private get_all_select_status(): boolean {
        for (const d of this._displayDeviceList) {
            if (!d.isSelect) {
                return false;
            }
        }

        return true;
    }

    sortAscend(sortKey: string): void {
        this.sort(sortKey, SortType.ascend);
    }

    sortDescend(sortKey: string): void {
        this.sort(sortKey, SortType.descend);
    }

    private sort(sortKey: string, sortType: SortType): void {
        if (this._currentSortKey !== sortKey || this._currentSortType !== sortType) {
            this._currentSortKey = sortKey;
            this._currentSortType = sortType;
            this.refactorDevices('sort', false);
        }
    }

    onDragStart(e: DragEvent): void {
        const activeGroup: DeviceGroupInfo = this.groupSvc.getActiveGroup(null);

        e.dataTransfer.dropEffect = 'move';
        e.dataTransfer.setData('text/plain', activeGroup ? activeGroup.id : null);
        e.dataTransfer.setDragImage(this._dragImgRef.nativeElement, -20, -20);
    }

    supportDeviceShare(): boolean {
        return this.Enable_Share && !this.accountSvc.isEnterprise() && this.accountSvc.hasScope_device_share();
    }

    supportBasicConfig(): boolean {
        return this.Enable_BatchBasicConfig && this.accountSvc.hasScope_task_basicSetting();
    }

    supportDeviceSecurity(): boolean {
        return this.Enable_BatchBasicConfig && this.accountSvc.hasScope_task_securitySetting();
    }

    supportFirmwareUpdate(): boolean {
        return this.Enable_Firmware_update && this.accountSvc.hasScope_task_firmwareUpdate();
    }

    supportIAdeaCareAPKUpdate(): boolean {
        return this.Enable_Apk_update && this.accountSvc.hasScope_task_iadeaCareApkUpdate();
    }

    supportReboot(): boolean {
        return this.Enable_Reboot && this.accountSvc.hasScope_task_reboot();
    }

    supportTroubleshoot(): boolean {
        return this.Enable_Troubleshoot && this.accountSvc.hasScope_task_troubleshoot();
    }

    supportClearCache(): boolean {
        return this.Enable_ClearCache && this.accountSvc.hasScope_task_clearCache();
    }

    private refactorDevices(from: string, forceUpdateScreen: boolean = false): void {
        //devices
        this._displayDeviceListUnderGroup = this._deviceList.slice();
        //filter by group
        this._showTotalDeviceCount = true;
        if (this._groupSwitch === GroupSwitch.on) {
            const activeGroup: DeviceGroupInfo = this.groupSvc.getActiveGroup(null);
            if (activeGroup) {
                if (activeGroup.id === DEVICE_GROUP_ID_HOME) {
                    this._displayDeviceListUnderGroup = this._displayDeviceListUnderGroup.filter(d => d.virtualDeviceOwnerID === this.groupSvc.ctrlAccountID);
                }
                else {
                    this._displayDeviceListUnderGroup = this._displayDeviceListUnderGroup.filter(d => d.groupID === activeGroup.id && d.virtualDeviceOwnerID === this.groupSvc.ctrlAccountID);
                    this._showTotalDeviceCount = false;
                }
            }
        }
        //filter by onlinestatus or search result
        this._displayDeviceList = this.devSvc.filterDevice(this._displayDeviceListUnderGroup, this._onlineStatusMap, this._searchInfo);

        //sort
        this._displayDeviceList = this._displayDeviceList.sort((a: DeviceInfo, b: DeviceInfo) => {
            let comp_a: string;
            let comp_b: string;
            switch (this._currentSortKey) {
                case 'owner':
                    {
                        comp_a = a.virtualDeviceOwner;
                        comp_b = b.virtualDeviceOwner;
                    }
                    break;
                case this.constantSvc.DEVKEY_NET_LAN_MAC:
                    {
                        comp_a = a.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_CONNECTED] && !a.currentSettings[this.constantSvc.DEVKEY_NET_LAN_CONNECTED] ? a.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_MAC] : a.currentSettings[this.constantSvc.DEVKEY_NET_LAN_MAC];
                        comp_b = b.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_CONNECTED] && !b.currentSettings[this.constantSvc.DEVKEY_NET_LAN_CONNECTED] ? b.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_MAC] : b.currentSettings[this.constantSvc.DEVKEY_NET_LAN_MAC];
                    }
                    break;
                case this.constantSvc.DEVKEY_NET_LAN_IP:
                    {
                        comp_a = a.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_CONNECTED] && !a.currentSettings[this.constantSvc.DEVKEY_NET_LAN_CONNECTED] ? a.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_IP] : a.currentSettings[this.constantSvc.DEVKEY_NET_LAN_IP];
                        comp_b = b.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_CONNECTED] && !b.currentSettings[this.constantSvc.DEVKEY_NET_LAN_CONNECTED] ? b.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_IP] : b.currentSettings[this.constantSvc.DEVKEY_NET_LAN_IP];
                    }
                    break;
                default:
                    {
                        comp_a = a.currentSettings[this._currentSortKey];
                        comp_b = b.currentSettings[this._currentSortKey];
                    }
                    break;
            }

            if (comp_a === comp_b) {
                return 0;
            }

            switch (this._currentSortType) {
                case SortType.ascend:
                    {
                        return comp_a >= comp_b ? 1 : -1;
                    }
                case SortType.descend:
                    {
                        return comp_a >= comp_b ? -1 : 1;
                    }
            }
        });

        //notify result page amount.
        this._displayDeviceAmount = this._displayDeviceList.length;

        //filter by page
        if (this._displayDeviceList.length > this._numberInPage) {
            const startIndex = (this._currentPage - 1) * this._numberInPage;
            this._displayDeviceList = this._displayDeviceList.slice(startIndex, startIndex + this._numberInPage);
        }

        this._displayDeviceList.forEach((d: DeviceInfo) => {
            this._screenshotInfo[d.virtualId] = this._screenshotInfo[d.virtualId] || { url: null };
        });

        if (this._viewType === DashboardView.grid) {
            this.update_screenshot_url(forceUpdateScreen);
        }

        this._bAllSelected = this.get_all_select_status();
        this.update_item_selection_count();
    }

    playDevFunc(funcName: string): void {
        const devFuncItem: DevFuncItem = this.devFuncSvc.getFunctionByName(funcName);
        if (devFuncItem) {
            const viewContainerRef = this.devFuncHost.viewContainerRef;
            viewContainerRef.clear();

            const componentRef = viewContainerRef.createComponent(devFuncItem.component);

            (<DevFuncInterface>componentRef.instance).title = devFuncItem.title;
            (<DevFuncInterface>componentRef.instance).devices = this._displayDeviceList.filter(d => d.isSelect && d.virtualId);;
            (<DevFuncInterface>componentRef.instance).minWidth = 1000;
        }
    }
}