import { NgModule } from '@angular/core';
import { DomainNameValidatorDirective } from './domain-name-validator.directive';
import { EqualValidator } from './equal-validator.directive';
import { UppercaseDirective } from './uppcase.directive';

@NgModule({
    declarations: [
        EqualValidator,
        UppercaseDirective,
        DomainNameValidatorDirective
    ],
    exports: [
        EqualValidator,
        UppercaseDirective,
        DomainNameValidatorDirective    
    ]
})
export class CustomDirectiveModule { }