import { Component } from '@angular/core';
import { DatePipe } from '@angular/common';

import { DevFuncActionInterface } from '../../base/action/dev-func-action.interface';
import { FirmwareFamilyInfo, FIRMWARE_UPDATE_METHOD, FirmwareUpdateScheduleInfo } from '../firmware-data';
import { GMT_OFFSET_LIST } from '../../../../lib/common/helper.lib';

@Component({
    templateUrl: './firmware-action-subform.component.html',
    styleUrls: ['../../base/base-config-form.component.css', './firmware-action-subform.component.css']
})
export class FirmwareActionSubFormComponent implements DevFuncActionInterface {
    private readonly GMTLIST: number[] = GMT_OFFSET_LIST;
    private _actionData: any;
    set actionData(data: any) {
        this._actionData = data;
        if (this._actionData) {
            this._bSpecific = this._actionData.bSpecific;
            this._schedule = this._actionData.schedule;
            this._schedule.date = this._schedule.date || this.datePipe.transform(new Date(), 'yyyy-MM-dd');
            this._firmwareFamilyInfos = this._actionData.firmwareFamilyInfo;

            if (this._firmwareFamilyInfos && this._firmwareFamilyInfos.length > 0) {
                this._familyID = this._firmwareFamilyInfos[0].familyId;

                this._firmwareFamilyInfos.forEach(familyInfo => {
                    this._firmwareLegalityMap[familyInfo.familyId] = this.checkFirmwareLegality(familyInfo);
                });

                //notify to update button status of parent component.
                //should push to the next javascript handling cycle since the callback function may not be assigned in current cycle. 
                setTimeout(() => {
                    this.updateActionStatus();
                }, 0);
            }
        }
    }

    actionCompleteHandler: (result: any) => void;
    actionStatusUpdateHandler: (isValid: boolean) => void;

    _bSpecific: boolean;
    _firmwareFamilyInfos: FirmwareFamilyInfo[];
    _schedule: FirmwareUpdateScheduleInfo;
    _familyID: string;
    _firmwareLegalityMap: { [family: string]: boolean } = {};

    constructor(private datePipe: DatePipe) { }

    changeFirmwareFamily(familyId: string): void {
        this._familyID = familyId;
    }

    updateFamilyLegality(familyInfo: FirmwareFamilyInfo, valid: boolean): void {
        this._firmwareLegalityMap[familyInfo.familyId] = valid;
        this.updateActionStatus();
    }

    enableFirmwareSchedule(enabled: boolean): void {
        this._schedule.enabled = enabled;
        this.updateActionStatus();
    }

    changeFirmwareScheduleDate(d: string): void {
        this._schedule.date = d;
        this.updateActionStatus();
    }

    changeFirmwareScheduleTime(t: string): void {
        this._schedule.time = t;
    }

    changeFirmwareScheduleTimeOffset(o: number): void {
        this._schedule.offset = o;
    }

    private checkFirmwareLegality(familyInfo: FirmwareFamilyInfo): boolean {
        switch (familyInfo.updateMethod) {
            case FIRMWARE_UPDATE_METHOD.FromURL:
                {
                    return familyInfo.customURL ? true : false;
                }
            case FIRMWARE_UPDATE_METHOD.Unknown:
                {
                    return false;
                }
            default:
                {
                    return true;
                }
        }
    }

    private updateActionStatus(): void {
        if (this.actionStatusUpdateHandler) {
            let isValid: boolean = Object.keys(this._firmwareLegalityMap).find(key => !this._firmwareLegalityMap[key]) ? false : true;
            if (isValid && this._schedule.enabled && !this._schedule.date) {
                //check if enable schedule
                isValid = false;
            }

            this.actionStatusUpdateHandler(isValid);
        }
    }
}