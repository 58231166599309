import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../api.base';

export interface IListPermissionSetRxData {
    id: string;
    name: string;
    permissionSetID: string;
    permissionGroupList: {
        name: string;
        permissions: {
            code: string;
            name: string;
            permissionID: string;
            preConditionCodeList: string[];
        }[]
    }[];
}

export class APIListPermissionSetManager extends APIBaseManager<void, void, void, IListPermissionSetRxData[]> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_ListPermissionSet';
        this._apiMethod = 'GET';
        this._allowPayloadPrint = false;
    }

    protected getRequestURL(): string {
        return super.getRequestURL() + 'permissionSets';
    }
}