import { DatePipe } from "@angular/common";
import { EventEmitter, Injectable, Output } from "@angular/core";
import { DeviceInfo } from "../../../../app/content/device/data/device-info";
import { ConstantService } from "../../../../app/lib/common/constant.service";
import { Observable, of as observableOf, of } from "rxjs";
import { concatMap, map } from "rxjs/operators";

import { NAService } from "../../../../app/API/na.service";
import { AccountService } from "../../../../app/entry/account.service";
import { HelperLib, REFRESH_DURATION } from "../../../../app/lib/common/helper.lib";
import { IAPIRx } from "../../../API/api.base";
import { IScepInfo } from "../../../API/v1/Enterprise/scep/api.ent.scep.data";
import { IEnterpriseListScepRxData } from "../../../API/v1/Enterprise/scep/api.ent.scep.list";
import { DeviceService } from "../../device/device.service";
import { ScepChallengeInfo, ScepDeviceInfo, ScepServerInfo, SCEP_USAGE_EAP_ETHERNET } from "./scep.data";

@Injectable()
export class ScepService {
    readonly SCEP_ALIAS_PREFIX: string = this.constantSvc.SCEP_SERVER_ALIAS_PREFIX + '-';
    readonly SCEP_SERVER_REFRESH_DURATION: number = REFRESH_DURATION * 60000;
    readonly SCEP_SERVER_COLKEY_ALIAS: string = 'alias';
    readonly SCEP_SERVER_COLKEY_SUBJECT: string = 'subject';

    private _scepMap: { [id: string]: ScepServerInfo } = {};
    private _updatingScepServer: boolean = false;
    private _lastServerUpdateTime: Date;

    private _deviceRefreshCountdown: number = 0;
    get deviceRefreshCountdown(): number {
        return this._deviceRefreshCountdown;
    }

    @Output() onScepRemoved = new EventEmitter<{ removedScepList: ScepServerInfo[], remainingScepList: ScepServerInfo[] }>();
    @Output() onScepAdded = new EventEmitter<{ addedScepList: ScepServerInfo[], remainingScepList: ScepServerInfo[] }>();
    @Output() onDeviceRefreshCountdownChanged = new EventEmitter<number>();

    constructor(
        private naSvc: NAService,
        private constantSvc: ConstantService,
        private accountSvc: AccountService,
        private devSvc: DeviceService,
        private datePipe: DatePipe) {
    }

    startRefreshDevice(): number {
        this._deviceRefreshCountdown = 60;
        HelperLib.countdown(this._deviceRefreshCountdown, 0, (counter: number) => {
            this._deviceRefreshCountdown = counter;
            this.onDeviceRefreshCountdownChanged.emit(this._deviceRefreshCountdown);
        });

        return this._deviceRefreshCountdown;
    }

    getScepList(forceRefresh: boolean = false): Observable<{ scepList: ScepServerInfo[], isFault: boolean, errorMessage?: string }> {
        if (this._updatingScepServer) {
            return new Observable((observer) => {
                HelperLib.checkState(1, () => { return !this._updatingScepServer }, () => {
                    observer.next({ isFault: false, scepList: HelperLib.mapToList<ScepServerInfo>(this._scepMap) });
                    observer.complete();
                });
            });
        }

        const needUpdate: boolean = (forceRefresh || !this._lastServerUpdateTime || Date.now() - this._lastServerUpdateTime.getTime() > this.SCEP_SERVER_REFRESH_DURATION) || HelperLib.mapToList<ScepServerInfo>(this._scepMap).length === 0 ? true : false;
        if (needUpdate) {
            this._updatingScepServer = true;

            return this.naSvc.listScepServerInfo(this.accountSvc.token).pipe(
                map((res: IAPIRx<IEnterpriseListScepRxData>) => {
                    if (res.error === 0) {
                        res.data.itemList.forEach(s => {
                            this._scepMap[s.settingID] = this.transformIScepToScepServerInfo(s);
                        });
                    }

                    this._lastServerUpdateTime = new Date();
                    this._updatingScepServer = false;

                    return {
                        isFault: res.error !== 0,
                        scepList: HelperLib.mapToList<ScepServerInfo>(this._scepMap),
                        errorMessage: HelperLib.getErrorMessage(res)
                    };
                })
            );
        }
        else {
            return observableOf({ isFault: false, scepList: HelperLib.mapToList<ScepServerInfo>(this._scepMap) });
        }
    }

    getScepByID(id: string): Observable<{ scep: ScepServerInfo, isFault: boolean, errorMessage?: string }> {
        if (this._scepMap[id]) {
            return observableOf({ scep: this._scepMap[id], isFault: false });
        }

        return this.getScepList(true).pipe(
            map((res: { scepList: ScepServerInfo[], isFault: boolean, errorMessage?: string }) => {
                return {
                    scep: this._scepMap[id],
                    isFault: this._scepMap[id] ? false : true,
                    errorMessage: !this._scepMap[id] ? 'Scep is not exist' : null
                }
            })
        );
    }

    createScep(scep: ScepServerInfo): Observable<{ scep: ScepServerInfo, isFault: boolean, errorMessage?: string }> {
        //find if the scep alias is duplicate
        if (HelperLib.mapToList(this._scepMap).find((s: ScepServerInfo) => s.alias === scep.alias)) {
            return observableOf({
                scep: null,
                isFault: true,
                errorMessage: 'SCEP alias \"' + scep.alias + '\" already exists'
            });
        }

        return this.naSvc.createScepServerInfo({
            settingName: this.SCEP_ALIAS_PREFIX + scep.alias,
            settingData: {
                subject: scep.subject,
                providerURL: scep.url,
                usage: {
                    [scep.usage]: 'on'
                },
                renewalDaysBeforeExpiration: scep.autoRenewDay,
                profile: scep.profile,
                keySize: scep.keysize
            }
        }, this.accountSvc.token).pipe(
            map((res: IAPIRx<IScepInfo>) => {
                if (res.error === 0) {
                    this._scepMap[res.data.settingID] = this.transformIScepToScepServerInfo(res.data);

                    this.onScepAdded.emit({
                        addedScepList: [scep],
                        remainingScepList: HelperLib.mapToList<ScepServerInfo>(this._scepMap)
                    });
                }

                return {
                    scep: scep,
                    isFault: res.error !== 0,
                    errorMessage: HelperLib.getErrorMessage(res)
                }
            })
        );
    }

    removeScep(scep: ScepServerInfo): Observable<{ scep: ScepServerInfo, isFault: boolean, errorMessage?: string }> {
        if (!this._scepMap[scep.id]) {
            return observableOf({
                scep: null,
                isFault: true,
                errorMessage: 'Scep \"' + scep.alias + '\" is not exist'
            });
        }

        return this.naSvc.removeScepServerInfo(scep.id, this.accountSvc.token).pipe(
            map((res: IAPIRx<void>) => {
                if (res.error === 0) {
                    delete this._scepMap[scep.id];

                    this.onScepRemoved.emit({
                        removedScepList: [scep],
                        remainingScepList: HelperLib.mapToList<ScepServerInfo>(this._scepMap)
                    });
                }

                return {
                    scep: scep,
                    isFault: false
                }
            })
        );
    }

    updateScep(scep: ScepServerInfo): Observable<{ scep: ScepServerInfo, isFault: boolean, errorMessage?: string }> {
        if (!this._scepMap[scep.id]) {
            return observableOf({
                scep: null,
                isFault: true,
                errorMessage: 'Scep \"' + scep.alias + '\" is not exist'
            });
        }

        return this.naSvc.updateScepServerInfo(scep.id, {
            subject: scep.subject,
            providerURL: scep.url,
            usage: {
                [scep.usage]: 'on'
            },
            renewalDaysBeforeExpiration: scep.autoRenewDay,
            profile: scep.profile,
            keySize: scep.keysize
        }, this.accountSvc.token).pipe(
            map((res: IAPIRx<IScepInfo>) => {
                if (res.error === 0) {
                    this._scepMap[scep.id].assignFrom(res.data);
                }

                return {
                    scep: this._scepMap[scep.id],
                    isFault: res.error !== 0,
                    errorMessage: HelperLib.getErrorMessage(res)
                }
            })
        );
    }

    renewScepDevice(scepDevList: ScepDeviceInfo[], scep: ScepServerInfo, challenge?: ScepChallengeInfo): Observable<{ scep: ScepServerInfo, isFault: boolean, errorMessage?: string }> {
        return this.devSvc.batchScepRenew(scepDevList.map(d => d.dev), scep, challenge).pipe(
            map(() => {
                return {
                    scep: scep,
                    isFault: false
                }
            })
        );
    }

    enrollScepDevice(scep: ScepServerInfo, challenge: ScepChallengeInfo, scepDevList: ScepDeviceInfo[], toUpdate: boolean = false): Observable<{ scep: ScepServerInfo, isFault: boolean, errorMessage?: string }> {
        return this.devSvc.batchScepEnroll(toUpdate, scepDevList.map(s => s.dev), scep, challenge).pipe(
            map(res => {
                return {
                    scep: scep,
                    isFault: false
                }
            })
        );
    }

    export(scep: ScepServerInfo, searchTxt?: string, selectedDevList?: DeviceInfo[]): Observable<{ fileName: string, header: string, metadata?: string[], dataList: string[][], date: string }> {
        return of(true).pipe(
            concatMap(() => {
                return (!selectedDevList || selectedDevList.length === 0) ? this.devSvc.getDevices('scepSvc.export').pipe(
                    map((res: { isFault: boolean, devices: DeviceInfo[], errorMessage?: string }) => res.isFault ? [] : res.devices)
                ) : of(selectedDevList);
            }),
            map((devList: DeviceInfo[]) => {
                const date: string = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH_mm_ss');

                const fileName: string = 'Device_credential_list_' + date;
                const header: string = `Device credential list under ${scep.alias}\n`;
                const metadata: string[] = [`Input: ${devList.length}`, `Checkbox: ${!selectedDevList || selectedDevList.length === 0 ? 'N/A' : devList.length}`];
                const rowList: string[][] = [];
                rowList.push(['Device name', 'MAC address', 'Device group', 'Enrollment status', 'Expiry date']);

                //filter by search
                if (searchTxt) {
                    devList = devList.filter(d => {
                        if (d.virtualName.toLocaleLowerCase().indexOf(searchTxt) >= 0) {
                            return true;
                        }

                        const devMAC: string = d.currentSettings[this.constantSvc.DEVKEY_NET_LAN_MAC] || d.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_MAC];
                        if (devMAC && devMAC.toLocaleLowerCase().indexOf(searchTxt) >= 0) {
                            return true;
                        }

                        return false;
                    });
                }
                metadata.push(`Filter: ${devList.length}, Search=>(${searchTxt || 'N/A'})`);

                //filter by scep name
                const targetDeviceList: DeviceInfo[] = devList.filter(d => d.scep.candidates.find(c => {
                    const stateLowerCase: string = c.state.toLocaleLowerCase();
                    return c.certName === scep.alias && (stateLowerCase === 'enrolling' || stateLowerCase === 'enrolled');
                }));//.filter(d => d.scep.inUse && d.scep.inUse.certName === scep.alias);
                metadata.push(`Filter: ${targetDeviceList.length}, SCEP=>(${scep.alias}); Candidate=>(enrolling | enrolled)`);

                targetDeviceList.forEach(d => {
                    rowList.push([
                        d.virtualName,
                        d.currentSettings[this.constantSvc.DEVKEY_NET_LAN_MAC] || d.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_MAC],
                        d.virtualDeviceGroup ? d.virtualDeviceGroup.groupName : '',
                        d.scep.inUse ? d.scep.inUse.state : '',
                        d.scep.inUse ? d.scep.inUse.notAfter : ''
                    ]);
                });

                return { fileName: fileName, header, metadata: metadata, dataList: rowList, date: date };
            })
        );
    }

    private transformIScepToScepServerInfo(scepRaw: IScepInfo): ScepServerInfo {
        const s: ScepServerInfo = new ScepServerInfo();
        s.id = scepRaw.settingID;
        s.alias = scepRaw.settingName; //.replace(this.SCEP_ALIAS_PREFIX, '');
        if (scepRaw.settingData) {
            s.subject = scepRaw.settingData.subject;
            s.usage = Object.keys(scepRaw.settingData.usage).find(usageName => scepRaw.settingData.usage[usageName] === 'on') || SCEP_USAGE_EAP_ETHERNET;
            s.profile = scepRaw.settingData.profile;
            s.url = scepRaw.settingData.providerURL;
            s.keysize = scepRaw.settingData.keySize;
            s.autoRenewDay = scepRaw.settingData.renewalDaysBeforeExpiration;
        }

        return s;
    }
}