import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../api.base';
import { IVirtualDeviceCalendarItem } from './virtualDevice.common';

export interface IRemoveVirtualDeviceCalendarPathParameter {
    virtualDeviceID: string;
    virtualDevicePairedID: string;
}

export interface IRemoveVirtualDeviceCalendarQueryParameter {
    typeList: string[]; //screenOff, maintenance
}

export class APIRemoveVirtualDeviceCalendarManager extends APIBaseManager<IRemoveVirtualDeviceCalendarPathParameter, IRemoveVirtualDeviceCalendarQueryParameter, void, IVirtualDeviceCalendarItem[]> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_RemoveVirtualDeviceCalendar';
        this._apiMethod = 'DELETE';
    }

    protected getRequestURL(pathParameters: IRemoveVirtualDeviceCalendarPathParameter, queryParameters: IRemoveVirtualDeviceCalendarQueryParameter): string {
        return super.getRequestURL() + 'virtualDevices/' + pathParameters.virtualDeviceID + '/' + pathParameters.virtualDevicePairedID + '/calendars' +
            (queryParameters.typeList.length > 0 ? '?types=' + queryParameters.typeList.join(',') : '');
    }
}