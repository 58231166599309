<div>
  <label class="title">
    <span *ngIf="_showSwitch">{{ NAME | capitalFirstWord }}</span>
    <i *ngIf="_lockInfo" class="fas fa-lock ml-2 red-1" data-toggle="tooltip"
      [title]="'Lock by policy <<' + _lockInfo.policyName + '>>'"></i>
  </label>
  <div *ngIf="_unsupportReason" class="warning-block mb-1">{{ _unsupportReason }}</div>
  <div *ngIf="_showSwitch" class='switch-container'>
    <input id="cmn-toggle-screenoff" class="cmn-toggle cmn-toggle-flat cmn-toggle-rect-flat" type="checkbox"
      [checked]="_info.enabled" [disabled]="_lockInfo || _disabled || _unsupportReason"
      (change)="enableFeature($event.target.checked)">
    <label for="cmn-toggle-screenoff">
      <span class="sr-only">Enable {{ NAME }}</span>
    </label>
  </div>
  <na-weekScheduleSelector [actionName]="'Lock screen from'" [data]="_info?.schedule"
    [disabled]="_lockInfo || _disabled || _unsupportReason || !_info?.enabled" (updated)="onScheduleChanged($event)">
  </na-weekScheduleSelector>
</div>