import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { Subject, fromEvent } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { AppStartScreensaverInfo } from "../../content/device/data/device-info";
import { ConstantService } from "../../lib/common/constant.service";
import { IUIElement } from "../uiElement.interface";

@Component({
    selector: 'na-appstart-screensaver',
    templateUrl: './appstart-screensaver.component.html',
    styleUrls: ['../uiElement.style.css', './appstart-screensaver.component.css']
})
export class AppStartScreensaverComponent implements OnInit, OnDestroy, IUIElement {
    _timeoutList: number[] = [];
    _mediaTypeList: { name: string, type: string }[] = [];
    private _allUnsubscribe: Subject<void> = new Subject();

    _info: AppStartScreensaverInfo;
    @Input('data')
    set data(v: AppStartScreensaverInfo) {
        this._info = v;
    }

    _showBorder: boolean = false;
    @Input()
    showBorder(v: boolean) {
        this._showBorder = v;
    }

    _disabled: boolean = false;
    @Input('disabled')
    set disabled(d: boolean) {
        this._disabled = d;
    }

    _unsupportReason: string;
    @Input('unsupportReason')
    set unsupportReason(v: string) {
        this._unsupportReason = v;
    }

    _lockInfo: { isSync: boolean, policyID: string, policyName: string };
    @Input('lock')
    set lock(d: { isSync: boolean, policyID: string, policyName: string }) {
        this._lockInfo = d;
    }

    private _screensaverUrlRef: ElementRef;
    @ViewChild('screensaverUrl')
    set screensaverUrl(h: ElementRef) {
        this._screensaverUrlRef = h;
        const e = this._screensaverUrlRef.nativeElement;
        const eOb = fromEvent(e, 'input');

        eOb.pipe(
            debounceTime(200),
            takeUntil(this._allUnsubscribe)
        ).subscribe((e: any) => {
            this._info.data = e.target.value;
            this.notify();
        });
    }

    @Output() onScreensaverChanged = new EventEmitter<{ data: AppStartScreensaverInfo, isValid: boolean }>();

    constructor(private constantSvc: ConstantService) {
        this._timeoutList = this.constantSvc.SCREEN_SAVER_TIMEOUT_LIST;
        this._mediaTypeList = this.constantSvc.SCREEN_SAVER_MEDIATYPE_LIST;
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this._allUnsubscribe.next();
        this._allUnsubscribe.complete();
    }

    enableScreensaver(checked: boolean): void {
        if (this._info.enabled !== checked) {
            this._info.enabled = checked;
            this.notify();
        }
    }

    changeTimeout(timeout: number): void {
        if (this._info.timeout !== timeout) {
            this._info.timeout = timeout;
            this.notify();
        }
    }

    /*     
    changeMediaType(mediaType: { name: string, type: string }): void {
        if (this._info.type !== mediaType.type) {
            this._info.type = mediaType.type;
            this.updateScreensaverChange();
        }
    }  
    isMediaTypeValid(): boolean {
        return this._info.enabled && !this._info.type ? false : true;
    } 
    */

    isTimeoutValid(): boolean {
        return this._info.enabled && !this._info.timeout ? false : true;
    }

    isDataValid(): boolean {
        return this._info.enabled && !this._info.data ? false : true;
    }

    private notify(): void {
        this.onScreensaverChanged.emit({ data: this._info, isValid: this._info.isValid() });
    }
}