import { Component, Input, OnDestroy, OnInit, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { Subject, fromEvent } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { SortType } from '../../../../app/lib/common/common.data';
import { UserInfo, UserInviteStatus } from './user.data';
import { UserService } from './user.service';
import { UserGroupInfo } from './group/user-group.data';


@Component({
    selector: 'na-user-pickup',
    templateUrl: './user-pickup.component.html',
    styleUrls: ['./user.style.css', './user-pickup.component.css']
})
export class UserPickupComponent implements OnInit, OnDestroy {
    readonly NUMBER_IN_PAGE_OPTIONS: number[] = [30, 50, 100];

    _loading: boolean;
    _userList: UserInfo[] = [];
    _displayUserList: UserInfo[] = [];
    _isZeroUserUnderGroup: boolean = false;
    _userSelectMap: { [userID: string]: boolean } = {};

    _tableColMap: { [key: string]: { name: string, sortSupport?: boolean, filterSupport?: boolean, show?: boolean } } = {};
    _tableHeaderList: { key: string, name: string, sortSupport?: boolean, filterSupport?: boolean, show?: boolean }[] = [];

    _advFilterList: { key: string, name: string, enabled: boolean, data?: any }[] = [];
    _invitationStatusFilterList: { key: string, name: string, data: string, desc?: string }[] = [
        {
            key: 'accept',
            name: 'Accepted', //should translate
            data: UserInviteStatus.accepted
        },
        {
            key: 'pendRequest',
            name: 'Pending request',
            data: UserInviteStatus.requested
        },
        {
            key: 'pendInvite',
            name: 'Pending invite',
            data: UserInviteStatus.invited
        }
    ];

    _createDateFilterList: { key: string, name: string }[] = [
        {
            key: 'n28',
            name: 'Within the past 28 days'
        },
        {
            key: 'n14',
            name: 'Within the past 21 days'
        },
        {
            key: 'n7',
            name: 'Within the past 7 days'
        },
        {
            key: 'n1',
            name: 'Yesterday'
        },
        {
            key: '0',
            name: 'Today'
        }
    ];

    _currentSortKey: string;
    _currentSortType: SortType;
    _enumSortType: typeof SortType = SortType;

    _inviteStatusMap: { [userID: string]: { inviting: boolean, errorMessage?: string } } = {};
    _enumUserInviteStatus: typeof UserInviteStatus = UserInviteStatus;

    _searchTxt: string;
    _numInPage: number = this.NUMBER_IN_PAGE_OPTIONS[0];
    _currentPage: number = 1;

    private _allUnsubscribe: Subject<void> = new Subject();

    private _searchRef: ElementRef;
    @ViewChild('search', { static: true })
    set search(v: ElementRef) {
        if (v) {
            this._searchRef = v;
            const searchElement = this._searchRef.nativeElement;
            const searchInputOb = fromEvent(searchElement, 'input');

            searchInputOb.pipe(
                debounceTime(200),
                takeUntil(this._allUnsubscribe)
            ).subscribe((e: any) => {
                this._searchTxt = e.target.value.toLocaleLowerCase();
                this.refactor();
            });
        }
    }

    _selectedUserGroup: UserGroupInfo;
    @Input('userGroup')
    set userGroup(v: UserGroupInfo) {
        if (this._selectedUserGroup !== v) {
            this.resetUserSelectionStatus();
            this._selectedUserGroup = v;
            this.refactor();
        }
    }

    _supportFilter: boolean = true;
    @Input('supportFilter')
    set supportFilter(v: boolean) {
        this._supportFilter = v;
    }

    _supportCol: boolean = true;
    @Input('supportCol')
    set supportCol(v: boolean) {
        this._supportCol = v;
    }

    @Output() onUserSelected = new EventEmitter<UserInfo[]>();
    @Output() onUserInviteRequest = new EventEmitter<UserInfo>();

    constructor(public userSvc: UserService) { }

    ngOnInit(): void {
        this.init();

        this.userSvc.onUserAdded.pipe(
            takeUntil(this._allUnsubscribe)
        ).subscribe((res: { addedUserList: UserInfo[], remainingUserList: UserInfo[] }) => {
            res.addedUserList.forEach(user => {
                this._userList.push(user);
                this.refactor();
            });
        });

        this.userSvc.onUserRemoved.pipe(
            takeUntil(this._allUnsubscribe)
        ).subscribe((res: { removedUserList: UserInfo[], remainingUserList: UserInfo[] }) => {
            this._userList = res.remainingUserList;
            this.refactor();
        });

        this.userSvc.onUserUpdated.pipe(
            takeUntil(this._allUnsubscribe)
        ).subscribe((res: { updatedUserList: UserInfo[], remainingUserList: UserInfo[] }) => {
            this._userList = res.remainingUserList;
            this.resetUserSelectionStatus();
            this.refactor();
        });

        this.refreshUsers(false);
    }

    ngOnDestroy(): void {
        this._allUnsubscribe.next();
        this._allUnsubscribe.complete();
    }

    private init(): void {
        this._tableColMap = this.userSvc.userTableColumnMap;
        this._tableHeaderList = Object.keys(this._tableColMap).map(key => Object.assign({ key: key }, this._tableColMap[key]));
        this._advFilterList = Object.keys(this._tableColMap).filter(key => this._tableColMap[key].filterSupport).map(key => Object.assign({ key: key, name: this._tableColMap[key].name, enabled: false, data: null }));
    }

    refreshUsers(forceRefresh: boolean = true): void {
        this._loading = true;
        this.userSvc.getUserList(forceRefresh).subscribe((res: { isFault: boolean, userList: UserInfo[], errorMessage?: string }) => {
            if (!res.isFault) {
                this._userList = res.userList;
                this._userList.forEach(u => this._userSelectMap[u.id] = false);
                this.refactor();
            }

            this._loading = false;
        });
    }

    reInvite(user: UserInfo): void {
        this.onUserInviteRequest.emit(user);
    }

    changeNumberInPage(numberInPage: number): void {
        if (this._numInPage !== numberInPage) {
            this._numInPage = numberInPage;
            this.refactor();
        }
    }

    showColumn(col: { key: string, name: string, sortSupport?: boolean, filterSupport?: boolean, show?: boolean }, checked: boolean): void {
        this._tableColMap[col.key].show = checked;
    }

    enableFilter(filter: { key: string, name: string, enabled: boolean, data?: any }, enabled: boolean): void {
        filter.enabled = enabled;
        if (!filter.enabled) {
            filter.data = null;
            this.refactor();
        }
    }

    changeTextFilterData(filter: { key: string, name: string, enabled: boolean, data?: any }, data: any): void {
        if (filter.data !== data) {
            filter.data = data;
        }
    }

    changeSelectionFilterData(filter: { key: string, name: string, enabled: boolean, data?: any }, data: { key: string, name: any }): void {
        if (!filter.data || filter.data.key !== data.key) {
            filter.data = data;
        }
    }

    applyFilter(filter: { key: string, name: string, enabled: boolean, data?: any }): void {
        this.refactor();
    }

    selectAllUsers(checked: boolean): void {
        this._displayUserList.forEach(u => this._userSelectMap[u.id] = checked);
        this.onUserSelected.emit(this._userList.filter(u => this._userSelectMap[u.id]));
    }

    selectUser(user: UserInfo, checked: boolean): void {
        this._userSelectMap[user.id] = checked;
        this.onUserSelected.emit(this._userList.filter(u => this._userSelectMap[u.id]));
    }

    resetUserSelectionStatus(): void {
        Object.keys(this._userSelectMap).map(userID => this._userSelectMap[userID] = false);
        this.onUserSelected.emit(this._userList.filter(u => this._userSelectMap[u.id]));
    }

    sortDescend(key: string): void {
        if (this._currentSortKey === key && this._currentSortType === SortType.descend) {
            return;
        }

        this._currentSortKey = key;
        this._currentSortType = SortType.descend;
        this.refactor();
    }

    sortAscend(key: string): void {
        if (this._currentSortKey === key && this._currentSortType === SortType.ascend) {
            return;
        }

        this._currentSortKey = key;
        this._currentSortType = SortType.ascend;
        this.refactor();
    }

    onPageChange(page: number): void {
        if (this._currentPage !== page) {
            this._currentPage = page;
        }
    }

    onDragStart(e: DragEvent): void {
        if (this._selectedUserGroup) {
            const dragUserList: UserInfo[] = this._displayUserList.filter(u => this._userSelectMap[u.id]);
            const dragData: string = 'userGroupID:' + this._selectedUserGroup.id + '||userIDList:' + dragUserList.map(u => u.id).join(',');
            e.dataTransfer.dropEffect = 'move';
            e.dataTransfer.setData('text/plain', dragData);
        }
    }

    private refactor(): void {
        //by search
        this._displayUserList = this._searchTxt ? this._userList.filter(u => (u.email + u.firstName + ' ' + u.lastName).toLocaleLowerCase().indexOf(this._searchTxt) >= 0) : this._userList.map(u => u);

        //filter by selected group
        if (this._selectedUserGroup) {
            this._displayUserList = this._displayUserList.filter(u => u.userGroupID === this._selectedUserGroup.id);
            this._isZeroUserUnderGroup = this._displayUserList.length === 0;
        }
        //filter by adv filters
        const advEnabledFilterList = this._advFilterList.filter(f => f.enabled && f.data);
        if (advEnabledFilterList.length > 0) {
            this._displayUserList = this._displayUserList.filter(u => {
                for (let adv of advEnabledFilterList) {
                    switch (adv.key) {
                        case this.userSvc.COL_KEY_STATUS:
                            {
                                return u.invitationStatus === adv.data.data;
                            }
                    }
                }

                return true;
            });
        }

        //by sort
        this._displayUserList = this._displayUserList.sort((a: UserInfo, b: UserInfo) => {
            let ca: string, cb: string;
            switch (this._currentSortKey) {
                case this.userSvc.COL_KEY_NAME:
                    {
                        ca = a.firstName + a.lastName;
                        cb = b.firstName + b.lastName;
                    }
                    break;
                default:
                    {
                        ca = a[this._currentSortKey];
                        cb = b[this._currentSortKey];
                    }
                    break;
            }

            if (ca === cb) {
                return 0;
            }

            switch (this._currentSortType) {
                case SortType.ascend:
                    {
                        return ca >= cb ? 1 : -1;
                    }
                case SortType.descend:
                    {
                        return ca >= cb ? -1 : 1;
                    }
            }
        });

        //by page number
        if (this._displayUserList.length > this._numInPage) {
            const startIndex = (this._currentPage - 1) * this._numInPage;
            this._displayUserList = this._displayUserList.slice(startIndex, startIndex + this._numInPage);
        }
    }
}