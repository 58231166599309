import { Injectable } from '@angular/core';

import { DevFuncConfirmItem } from './dev-func-confirm-item';
import {
  DIALOG_NAME_ISSUE_REPORT,
  DIALOG_NAME_REBOOT,
  DIALOG_NAME_FIRMWARE_UPDATE,
  DIALOG_NAME_APK_UPDATE,
  DIALOG_NAME_BASIC_CONFIG,
  DIALOG_NAME_NET_CONFIG,
  DIALOG_NAME_ALERT,
  DIALOG_NAME_SHARE,
  DIALOG_NAME_MULTI_BASIC_CONFIG,
  DIALOG_NAME_SECURITY,
  DIALOG_NAME_CLEARCACHE
} from '../base-config-data';

import { IssueConfirmSubFormComponent } from '../../troubleshoot/confirm/issue-confirm-subform.component';
import { RebootConfirmSubFormComponent } from '../../reboot/confirm/reboot-confirm-subform.component';
import { FirmwareConfirmSubFormComponent } from '../../firmware/confirm/firmware-confirm-subform.component';
import { BasicConfigConfirmSubFormComponent } from '../../config/confirm/basic-config-confirm-subform.component';
import { NetConfigConfirmSubFormComponent } from '../../config/confirm/net-config-confirm-subform.component';
import { AlertConfirmSubFormComponent } from '../../alert/confirm/alert-confirm-subform.component';
import { ShareConfirmSubFormComponent } from '../../share/confirm/share-confirm-subform.component';
import { APKConfirmSubFormComponent } from '../../apk/confirm/apk-confirm-subform.component';
import { MultiBasicConfigConfirmSubFormComponent } from '../../config-m/confirm/m-basic-config-confirm-subform.component';
import { SecurityConfirmSubFormComponent } from '../../security/confirm/security-confirm-subform.component';
import { ClearCacheConfirmSubFormComponent } from '../../cache/confirm/clearCache-confirm-subform.component';

@Injectable()
export class DevFuncConfirmService {
  private _items: DevFuncConfirmItem[] = [];

  constructor() {
    this._items = [
      new DevFuncConfirmItem(IssueConfirmSubFormComponent, DIALOG_NAME_ISSUE_REPORT),
      new DevFuncConfirmItem(RebootConfirmSubFormComponent, DIALOG_NAME_REBOOT),
      new DevFuncConfirmItem(FirmwareConfirmSubFormComponent, DIALOG_NAME_FIRMWARE_UPDATE),
      new DevFuncConfirmItem(APKConfirmSubFormComponent, DIALOG_NAME_APK_UPDATE),
      new DevFuncConfirmItem(BasicConfigConfirmSubFormComponent, DIALOG_NAME_BASIC_CONFIG),
      new DevFuncConfirmItem(MultiBasicConfigConfirmSubFormComponent, DIALOG_NAME_MULTI_BASIC_CONFIG),
      new DevFuncConfirmItem(NetConfigConfirmSubFormComponent, DIALOG_NAME_NET_CONFIG),
      new DevFuncConfirmItem(AlertConfirmSubFormComponent, DIALOG_NAME_ALERT),
      new DevFuncConfirmItem(ShareConfirmSubFormComponent, DIALOG_NAME_SHARE),
      new DevFuncConfirmItem(SecurityConfirmSubFormComponent, DIALOG_NAME_SECURITY),
      new DevFuncConfirmItem(ClearCacheConfirmSubFormComponent, DIALOG_NAME_CLEARCACHE)
    ];
  }

  getAllItems(): DevFuncConfirmItem[] {
    return this._items;
  }

  getItemByName(name: string): DevFuncConfirmItem {
    return this._items.find(i => i.name === name);
  }
}
