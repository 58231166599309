import { HelperLib } from "../../lib/common/helper.lib";

export class ShareSettingUIInfo {
    static NEW_SHARE_ID: number = 1;

    accountID: string;
    originAccountName: string;
    accountName: string;
    permissionList: { permission: string, displayName: string, beforeChecked: boolean, afterChecked: boolean }[];
    action: string;
    errorMessage?: string;

    constructor(o?: { accountID: string, accountName: string, permission: string[], action?: string, original?: { accountName: string, permissionList: string[] } }) {
        if (o) {
            const permissionMap: { [permission: string]: { permission: string, displayName: string, beforeChecked: boolean, afterChecked: boolean } } = {};
            HelperLib.AdjustableSharePermissionList.forEach((p: { permission: string, displayName: string }) => {
                permissionMap[p.permission] = {
                    permission: p.permission,
                    displayName: p.displayName,
                    beforeChecked: false,
                    afterChecked: false
                };
            });

            if (o.original) {
                o.original.permissionList.forEach(p => {
                    if (permissionMap[p]) {
                        permissionMap[p].beforeChecked = true;
                    }
                });
                o.permission.forEach(p => {
                    if (permissionMap[p]) {
                        permissionMap[p].afterChecked = true;
                    }
                });
            }
            else {
                o.permission.forEach(p => {
                    if (permissionMap[p]) {
                        permissionMap[p].beforeChecked = true;
                        permissionMap[p].afterChecked = true;
                    }
                });
            }

            this.accountID = o.accountID;
            this.accountName = o.accountName;
            this.originAccountName = o.original ? o.original.accountName : o.accountName;
            this.action = o.action;
            this.permissionList = Object.keys(permissionMap).map(p => permissionMap[p]) || [];
        }
        else {
            this.accountID = '+' + ShareSettingUIInfo.NEW_SHARE_ID++;
            this.accountName = '';
            this.errorMessage = 'Account should not be empty';
            this.originAccountName = '';
            this.action = '+';
            this.permissionList = HelperLib.AdjustableSharePermissionList.map(p => {
                return {
                    permission: p.permission,
                    displayName: p.displayName,
                    beforeChecked: false,
                    afterChecked: false
                }
            })
        }
    }

    isChange(): boolean {
        let isChanged: boolean = false;
        if (this.accountName !== this.originAccountName) {
            isChanged = true;
        }
        else {
            for (const p of this.permissionList) {
                if (p.beforeChecked !== p.afterChecked) {
                    isChanged = true;
                    break;
                }
            }
        }

        if (isChanged && !this.action) {
            this.action = '~';
        }

        return isChanged;
    }

    reset(): void {
        this.accountName = this.originAccountName;
        if (!this.accountName) {
            this.errorMessage = 'Account should not be empty';
        }
        
        this.permissionList.forEach(p => p.afterChecked = p.beforeChecked);
        if (this.action !== '+') {
            this.action = '';
        }   
    }

    remove(): void {
        this.action = '-';
    }

    export(): {
        accountID: string,
        accountName: string,
        permission: string[],
        action: string,
        original?: {
            accountName: string,
            permissionList: string[]
        }
    } {
        return {
            accountID: this.accountID,
            accountName: this.accountName,
            permission: this.permissionList.filter(p => p.afterChecked).map(p => p.permission),
            action: this.action,
            original: {
                accountName: this.originAccountName,
                permissionList: this.permissionList.filter(p => p.beforeChecked).map(p => p.permission)
            }
        }
    }
}