import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../../api.base';

export interface IStopRemoteCtrlPathParameter {
    virtualDeviceID: string;
    virtualDevicePairedID: string;
}

export class APIStopRemoteCtrlManager extends APIBaseManager<IStopRemoteCtrlPathParameter, void, void, void> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_StopRemoteCtrl';
        this._apiMethod = 'DELETE';
    }

    protected getRequestURL(pathParameters: IStopRemoteCtrlPathParameter, queryParameters: void): string {
        return `${super.getRequestURL()}virtualDevices/${pathParameters.virtualDeviceID}/${pathParameters.virtualDevicePairedID}/remoteControl`;
    }
}