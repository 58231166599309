<label for="config-timezone" class="title">
    {{ _title }}
</label>
<div id="config-timezone" class="btn-group w-100">
    <button id="dropdownMultiConfigTimezoneLink" type="button" class="btn btn-sm btn-action btn-light dropdown-toggle"
        data-toggle="dropdown">
        {{ _selectedTimezone?.id || 'Select timezone' }}
        <span class="sr-only">Select timezone</span>
    </button>
    <ul class="dropdown-menu dropdown-menu-left option-list w-100" aria-labelledby="dropdownMultiConfigTimezoneLink">
        <li *ngFor="let timezone of _availableTimezoneList" [class.selected]="_selectedTimezone?.id === timezone.id"
            (click)="changeTimezone(timezone)">
            <div>
                GMT{{ timezone.offset }}&nbsp;&nbsp;{{ timezone.displayName }}
            </div>
        </li>
    </ul>
</div>