<div>
    <span class="blue-2">Enabled :</span>
    <span class="ml-2">{{ _data?.enabled }}</span>
</div>
<ng-template [ngIf]="_data?.enabled">
    <div>
        <span class="blue-2">Timeout :</span>
        <span class="ml-2">{{ _data?.timeout / 60 }} minutes</span>
    </div>
    <div>
        <span class="blue-2">Data :</span>
        <span class="ml-2">{{ _data?.data }}</span>
    </div>
</ng-template>