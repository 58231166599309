<div>
  <label for="config-volume" class="title">
    Volume <span class="text-danger small ml-2">(volume will be set to the nearest value the device supports)</span>
    <i *ngIf="_lockInfo" class="fas fa-lock ml-2 red-1" data-toggle="tooltip"
      [title]="'Lock by policy <<' + _lockInfo.policyName + '>>'"></i>
  </label>
  <div *ngIf="_unsupportReason" class="warning-block mb-1">{{ _unsupportReason }}</div>
  <div id="config-volume" [ngClass]="_showBorder ? 'showBorder' : ''">
    <div class="volume" [ngClass]="_lockInfo || _disabled || _unsupportReason || !_valid ? 'inactive' : ''">
      <i class="fas fa-volume-mute volume-ctrl" (click)="mute()"></i>
      <i class="fas fa-volume-down volume-ctrl" (click)="volumeDown()"></i>
      <div #volumeSlider class="volume-slider">
        <div #volumeSliderDown class="volume-slider-down"></div>
        <div #volumeSliderUp class="volume-slider-up" [style.width.px]="_volumeWidth"></div>
        <div #volumeBall class="volume-slider-ball" [style.left.px]="_volumeWidth - 10"></div>
      </div>
      <i class="fas fa-volume-up volume-ctrl" (click)="volumeUp()"></i>
      <span *ngIf="_displayVolumeLevel" class="volume-hint ml-1">{{ volumePercentTransform() }}%</span>
    </div>
  </div>
</div>