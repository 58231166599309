<div>
  <div class="title">
    <p>Please confirm the authorizations to share access of your devices :
      <span *ngIf="!_hasChange" class="highlight">
        (nothing changes)
      </span>
    </p>
  </div>
  <div class="content row">
    <div class="col-xl-3 col-12">
      <strong>Devices</strong>
      <div class="devicelist">
        <div *ngFor="let device of _devices" class="setting-list-item"
          [ngClass]="device.virtualId === _selectDevice?.virtualId ? 'selected': ''" (click)="selectDevice(device)">
          <span>{{ device?.virtualName }}</span>
        </div>
      </div>
    </div>
    <div class="col-xl-9 col-12">
      <strong>Share settings</strong>
      <div>
        <div *ngIf="_shareSettingMap[_selectDevice?.virtualId]?.added.length > 0" class="setting-block">
          <label class="title">New settings ( + )</label>
          <div class="content">
            <div *ngFor="let s of _shareSettingMap[_selectDevice?.virtualId]?.added" class="item">
              <div>
                <label class="col-sm-4 col-md-2 col-12 name">Account</label>
                <span class="col-sm-8 col-md-10 col-12">{{ s.accountName }}</span>
              </div>
              <div>
                <label class="col-12 name">Permission</label>
                <div class="col-12 permission">
                  <span *ngFor="let p of s.permission">
                    {{ p }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="_shareSettingMap[_selectDevice?.virtualId]?.changed.length > 0" class="setting-block">
          <label class="title">Modified settings</label>
          <div class="content">
            <div *ngFor="let s of _shareSettingMap[_selectDevice?.virtualId]?.changed" class="item">
              <div>
                <label class="col-sm-4 col-md-2 col-12 name">Account</label>
                <span class="col-sm-8 col-md-10 col-12">{{ s.accountName }}</span>
              </div>
              <div>
                <label class="col-12 name">Permission</label>
                <div class="col-12 permission">
                  <span *ngFor="let p of s.permission">
                    {{ p }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="_shareSettingMap[_selectDevice?.virtualId]?.removed.length > 0" class="setting-block">
          <label class="title">Removed settings ( - )</label>
          <div class="content">
            <div *ngFor="let s of _shareSettingMap[_selectDevice?.virtualId]?.removed" class="item">
              <div>
                <label class="col-sm-4 col-md-2 col-12 name">Account</label>
                <span class="col-sm-8 col-md-10 col-12">{{ s.accountName }}</span>
              </div>
              <div>
                <label class="col-12 name">Permission</label>
                <div class="col-12 permission">
                  <span *ngFor="let p of s.permission">
                    {{ p }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="_shareSettingMap[_selectDevice?.virtualId]?.unchanged.length > 0" class="setting-block">
          <label class="title">Unchanged settings</label>
          <div class="content">
            <div *ngFor="let s of _shareSettingMap[_selectDevice?.virtualId]?.unchanged" class="item">
              <div>
                <label class="col-sm-4 col-md-2 col-12 name">Account</label>
                <span class="col-sm-8 col-md-10 col-12">{{ s.accountName }}</span>
              </div>
              <div>
                <label class="col-12 name">Permission</label>
                <div class="col-12 permission">
                  <span *ngFor="let p of s.permission">
                    {{ p }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>