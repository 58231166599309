import { Type } from '@angular/core';

export const USER_DLG_FUNC_INVITE: string = 'user-invite';
export const USER_DLG_FUNC_INVITE_CONTENT: string = 'user-invite-content';
export const USER_DLG_FUNC_INVITE_BULK: string = 'user-invite-bulk';
export const USER_DLG_FUNC_INVITE_BULK_CONTENT: string = 'user-invite-bulk-content';
export const USER_DLG_FUNC_RESETPWD: string = 'user-reset-pwd';
export const USER_DLG_FUNC_RESETPWD_CONTENT: string = 'user-reset-pwd-content';
export const USER_DLG_FUNC_REMOVE: string = 'user-remove';
export const USER_DLG_FUNC_REMOVE_CONTENT: string = 'user-remove-content';
export const USER_DLG_FUNC_EDITCONFIRM: string = 'user-edit-confirm';
export const USER_DLG_FUNC_EDITCONFIRM_CONTENT: string = 'user-edit-confirm-content';
export const USER_DLG_FUNC_CHANGEGROUP: string = 'user-change-group';
export const USER_DLG_FUNC_CHANGEGROUP_CONTENT: string = 'user-change-group-content';

export const USER_DLG_GROUP_FUNC_REMOVE: string = 'user-group-remove';
export const USER_DLG_GROUP_FUNC_REMOVE_CONTENT: string = 'user-group-remove-content';
export const USER_DLG_GROUP_FUNC_NEW: string = 'user-group-new';
export const USER_DLG_GROUP_FUNC_NEW_CONTENT: string = 'user-group-new-content';

export class UserDlgFuncItem {
  constructor(public component: Type<any>, public name: string, public title?: string) { }
}

export interface IUserDlgFuncCtrl<D, O>  {
  title: string;
  funcName: string;
  data: D;
  other?: O;
  onActionCompleted: (ret: { funcName: string, isFault: boolean, data?: any, errorMessage?: string }) => void;
  onActionCancelled?: (ret: { funcName: string }) => void;
}

export interface IUserDlgFuncContent<D, O> {
  funcName: string;
  data: D;
  other?: O;
  onActionPrepared: () => void;
  onActionValidChanged: (valid: boolean) => void;
}