import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../api.base';

export interface IGetVirtualDeviceAvailableOptionPathParameter {
    virtualDeviceID: string;
    virtualDevicePairedID: string;
}

export interface IGetVirtualDeviceAvailableOptionRxData {
    Timezones: string[];
    Resolutions: string[];
    Rotations: string[];
    SSIDs: string[];
    HDCP: boolean;
}

export class APIGetVirtualDeviceAvailableOptionManager extends APIBaseManager<IGetVirtualDeviceAvailableOptionPathParameter, void, void, IGetVirtualDeviceAvailableOptionRxData> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_GetVirtualDeviceAvailableOption';
        this._apiMethod = 'GET';
    }

    protected getRequestURL(pathParameters: IGetVirtualDeviceAvailableOptionPathParameter, queryParameters: void): string {
        return super.getRequestURL() + 'virtualDevices/' + pathParameters.virtualDeviceID + '/' + pathParameters.virtualDevicePairedID + '/availableOptions';
    }
}