<div>
    <div>
        <div>
            1. Download csv template (optional)
            <div class="ml-3 mt-1">
                <button type="button" class="btn btn-sm btn-action btn-light"
                    (click)="downloadTemplate()">Download</button>
                <a #downloadLink class="d-none"></a>
            </div>
        </div>
        <div class="mt-4">
            2. Edit your csv file
        </div>
        <div class="mt-4">
            3. Upload your csv file
            <input type="file" accept=".csv" (click)="onFileClick($event)" (change)="onFileChange($event)">
            <div *ngIf="_errorMessage" class="block block-error mt-1">
                <div class="text-break">{{ _errorMessage }}</div>
            </div>
        </div>
        <div *ngIf="_dirty" class="mt-4">
            <strong class="red-1">{{ other.usersFromCsv.length }}</strong> user(s) found.
        </div>
        <div class="form-group row mt-2">
            <label for="col-user-msg" class="col-12 col-form-label col-form-label-sm font-weight-bold">
                Messages
            </label>
            <div class="col-12">
                <textarea id="col-user-msg" rows="5" class="form-control form-control-sm"
                    [(ngModel)]="other.message"></textarea>
            </div>
        </div>
    </div>
</div>