import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'secondTransform'
})
export class SecondTransformPipe implements PipeTransform {
    transform(value: string, ...args: string[]): any {
        if (value === null || value === undefined) {
            return 'Unknown';
        }

        try {
            const valueNum: number = parseInt(value, 10);
            const denominator_minute = 60;
            const denominator_hour = denominator_minute * 60;
            let hour, minute, second = 0;
            hour = Math.floor(valueNum / denominator_hour);
            let remainder = valueNum % denominator_hour;
            if (remainder > 0) {
                minute = Math.floor(remainder / denominator_minute);
                second = remainder % denominator_minute;
            }

            return (hour < 10 ? '0' + hour : hour) + ':' + (minute < 10 ? '0' + minute : minute) + ':' + (second < 10 ? '0' + second : second);
        }
        catch (err) {
            return 'Unknown';
        }
    }
}