import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { AppConfigService } from './app.config';

import { ContentModule } from './content/content.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { PageNotFoundComponent } from './other/page-not-found.component';
import { EntryModule } from './entry/entry.module';
import { NAModule } from './API/na.module';
import { CustomPipeModule } from './lib/pipes/custom-pipe.module';
import { ThemeModule } from './theme/theme.module';
import { AppService } from './app.service';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    CommonModule,

    ContentModule,
    EntryModule,
    NAModule,

    AppRoutingModule,
    CustomPipeModule,
    ThemeModule
  ],
  declarations: [
    AppComponent,
    PageNotFoundComponent,
  ],
  providers: [
    AppConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: (appConfig: AppConfigService) => function () { return appConfig.load(); },
      deps: [AppConfigService],
      multi: true
    },
    AppService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
