<div class="container-fluid">
    <div class="row">
        <div class="col-12 px-0">
            <div class="d-flex">
                <div *ngIf="Enable_Pairing" class="mr-2 layout-desktop">
                    <button id="id_pair_share_link_manageOwnDev" type="button" class="btn btn-sm btn-action btn-light"
                        [disabled]="_isLoading" [routerLink]="'/app/device/pair/own'">
                        Manage owner devices
                    </button>
                </div>
                <button id="id_pair_share_refreshAll" type="button" class="btn btn-sm btn-action btn-light"
                    [disabled]="_refreshCounter > 0 || _isLoading" data-toggle="tooltip"
                    title='{{ "key-dev-refresh" | translate | capitalFirstWord }}' (click)="refreshDevices()">
                    <i [class.rotate]="_refreshCounter > 0" class="fas fa-sync-alt"></i>
                    <span class="sr-only">{{ "key-dev-refresh" | translate }}</span>
                    <ng-template [ngIf]="_refreshCounter > 0"> - {{ _refreshCounter }} s</ng-template>
                </button>
                <div class="flex-fill ml-2">
                    <div class="input-group input-group-sm">
                        <div class="input-group-prepend">
                            <span class="input-group-text">
                                <i class="fas fa-search"></i>
                            </span>
                        </div>
                        <input #search class="form-control" type='text' [disabled]="_isLoading" name='search'
                            placeholder="Search by device name ..." aria-label="Search devices">
                    </div>
                </div>
                <div class="dropdown-wrapper btn-group ml-2">
                    <button id="dropdownPageAmountLink" type="button" class="btn btn-sm btn-action btn-light dropdown-toggle"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [disabled]="_isLoading">
                        {{ _numberInPage }}
                    </button>
                    <ul class="dropdown-menu option-list" aria-labelledby="dropdownPageAmountLink">
                        <li *ngFor="let numberInPageOption of NUMBER_IN_PAGE_OPTIONS" class="dropdown-item"
                            [class.selected]="numberInPageOption === _numberInPage"
                            (click)="changeNumberInPage(numberInPageOption)">
                            {{ numberInPageOption }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-2">
        <div class="layout-mobile mb-2 col-12 px-0">
            <div *ngFor="let device of _displayDeviceList; let recordIndex = index" class="card card-list">
                <div class="card-header">
                    # {{ recordIndex + 1 }}
                </div>
                <div class="card-body">
                    <div class="form-group row">
                        <span class="col-4">Device name</span>
                        <div class="col-8 pl-0">
                            {{ device.virtualName }}
                        </div>
                    </div>
                    <div class="form-group row">
                        <span class="col-4">Shared from</span>
                        <div class="col-8 pl-0">
                            {{ device.virtualDeviceOwner }}
                        </div>
                    </div>
                    <div class="form-group row">
                        <span class="col-4">Action</span>
                        <div class="col-8 pl-0">
                            <button type="button" class="btn btn-action btn-light" data-toggle="modal"
                                data-target="#removeShareDeviceWarningModal"
                                (click)="popupConfirmRemoveShareDialog(device)">Remove</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="layout-desktop col-12 px-0">
            <div class="list-group">
                <div class="list-group-item list-group-header">
                    <div class="row">
                        <span class="col-6 title">Device name</span>
                        <span class="col-3 title">Shared from</span>
                        <span class="col title">Action</span>
                    </div>
                </div>
                <div *ngIf="!_isLoading" class="list-group-content list-group-hoverless list-group-content-nr-2">
                    <div *ngFor="let device of _displayDeviceList" class="list-group-item list-group-item-action">
                        <div class="row align-items-center">
                            <span class="col-6 text-break">
                                {{ device.virtualName }}
                            </span>
                            <span class="col-3">
                                {{ device.virtualDeviceOwner }}
                            </span>
                            <span class="col-auto">
                                <button type="button" class="btn btn-sm btn-light btn-action" data-toggle="modal"
                                    data-target="#removeShareDeviceWarningModal"
                                    (click)="popupConfirmRemoveShareDialog(device)">
                                    Remove
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12">
            <p *ngIf="!_isLoading && _displayDeviceList?.length === 0" class="text-center p-4">No shared devices.</p>
            <div *ngIf="_isLoading" class="loading">
                <p>Please wait...</p>
                <div class="loading-box"></div>
            </div>
        </div>
    </div>
    <div id='removeShareDeviceWarningModal' class='modal fade' data-backdrop='static' data-keyboard='false'
        role='dialog'>
        <div class='modal-dialog'>
            <div class='modal-content'>
                <div class='modal-header'>
                    <span class="modal-title">Remove shared devices</span>
                    <button #dlgClose type="button" class="close" data-dismiss="modal">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class='modal-body'>
                    <div *ngIf="_removeStatusPage === _enumDialogPage.confirm" class="container-fluid px-0">
                        <p>
                            Are you sure to remove the shared device?
                        </p>
                        <div>
                            <strong>From :</strong>
                            <span class="ml-2">{{ _selectedDevice?.virtualDeviceOwner }}</span>
                        </div>
                        <div>
                            <strong>Device name :</strong>
                            <span class="ml-2">
                                {{ _selectedDevice?.virtualName }}
                            </span>
                        </div>
                    </div>
                    <div *ngIf="_removeStatusPage === _enumDialogPage.submit" class="container-fluid px-0">
                        <div class="progress">
                            <div class="progress-bar progress-bar-success progress-bar-striped active"
                                role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"
                                style="width: 100%">Updating
                                the changes... Please wait...</div>
                        </div>
                    </div>
                    <div *ngIf="_removeStatusPage === _enumDialogPage.result" class="container-fluid px-0">
                        <div *ngIf="_errorMessage">
                            <p>Some errors occurred while removing new shared device. Please try again.</p>
                            <p class="warning-block">{{ _errorMessage }}</p>
                        </div>
                        <p *ngIf="!_errorMessage">New settings have been applied successfully.</p>
                    </div>
                    <div class="row control-block d-flex justify-content-center">
                        <button *ngIf="_removeStatusPage === _enumDialogPage.confirm" type="button"
                            class="btn btn-action btn-light" data-dismiss="modal">
                            <span class="fas fa-times-circle icon-left"></span>
                            Cancel
                        </button>
                        <button *ngIf="_removeStatusPage === _enumDialogPage.confirm" type="button"
                            class="btn btn-action btn-light" (click)="removeSharedDevice()">
                            <span class="fas fa-check-circle icon-left"></span>
                            Apply
                        </button>
                        <button *ngIf="_removeStatusPage === _enumDialogPage.result" type="button"
                            class="btn btn-action btn-light" data-dismiss="modal">
                            <span class="fas fa-times-circle icon-left"></span>
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-1">
        <div class="bottom-bar col-12 px-0 d-flex">
            <div class="ml-auto">
                <na-pager [currentPage]="_page" [numberInPage]="_numberInPage" [total]="_displayPlayerAmount"
                    (onPageChanged)="changePage($event)">
                </na-pager>
            </div>
        </div>
    </div>
</div>
<div class="layout-mobile">
    <div class="control-block-bottom fixed-bottom">
        <a class="control-item" [ngClass]="_isLoading ? 'disabled' : ''" [routerLink]="'/app/device/devices'">
            <div class="icon fas fa-home"></div>
            <span>Device overview</span>
        </a>
        <a *ngIf="Enable_Pairing" class="control-item" [ngClass]="_isLoading ? 'disabled' : ''"
            [routerLink]="'/app/device/pair/own'">
            <div class="icon fas fa-handshake"></div>
            <span>Manage owner devices</span>
        </a>
    </div>
</div>