<div *ngIf="_licenseInfo?.iCareLicense" class="license-item">
  <div class="col-lg-3 col-4 license-category">IAdeaCare</div>
  <div class="col-lg-9 col-8 license-content">
    <div *ngIf="_licenseInfo.iCareLicense.currentActivated && _licenseInfo.iCareLicense.currentActivated.length > 0">
      <strong>Type&nbsp;:</strong>
      {{ _licenseInfo?.iCareLicense.currentActivated | mergeContentPipe }}
    </div>
    <div>
      <strong>Expiration date&nbsp;:</strong>
      {{ _licenseInfo?.iCareLicense?.expiryDate | date:'yyyy-MM-dd Z' }}
      <span *ngIf="_licenseInfo?.iCareLicense.isExpired" class="warning-block">Expired</span>
    </div>
  </div>
</div>
<div *ngIf="_licenseInfo?.cloudLicense" class="license-item">
  <div class="col-lg-3 col-4 license-category">Cloud</div>
  <div class="col-lg-9 col-8 license-content">
    <div>
      <strong>Type&nbsp;:</strong>
      Cloud update
    </div>
    <div>
      <strong>Expiration date&nbsp;:</strong>
      {{ _licenseInfo?.cloudLicense?.expiryDate | date:'yyyy-MM-dd Z' }}
      <span *ngIf="_licenseInfo?.cloudLicense.isExpired" class="warning-block">Expired</span>
    </div>
  </div>
</div>
<div *ngIf="!_licenseInfo?.iCareLicense && !_licenseInfo?.cloudLicense" class="warning">
  No allocated license. Install one to enable more features.
</div>
