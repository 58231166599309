import { Component } from '@angular/core';

import { BaseConfigFormComponent } from '../base/base-config-form.component';
import { DIALOG_NAME_NET_CONFIG, ConfigDialogPage } from '../base/base-config-data';
import { DeviceNetConfigControl } from './data/device-net-config.control';
import { DeviceInfo } from '../../device/data/device-info';
import { NetworkType } from '../../device/data/device-net-info';
import { LicenseScopeType } from '../../license/license.data';
import { HelperLib, SharePermissionType } from '../../../lib/common/helper.lib';

@Component({
    templateUrl: '../base/base-config-form.component.html',
    styleUrls: ['../base/base-config-form.component.css']
})
export class NetConfigFormComponent extends BaseConfigFormComponent {
    ngOnInit(): void {
        this._dialogName = DIALOG_NAME_NET_CONFIG;
        this._licenseScopeType = LicenseScopeType.taskConfig;
        this._sharePermissionType = SharePermissionType.NetConfig;

        super.ngOnInit();
    }

    protected goNext(fromPage?: ConfigDialogPage): void {
        if (this._page === ConfigDialogPage.checking) {
            this._actionData.configData = this.init_config_data(this._legalDevices, this._actionData.configData);
        }
        else if (this._page === ConfigDialogPage.action) {
            if (!this._actionData.configData.isSettingAvailable()) {
                this._errorMessage = 'You have to fill or correct the required(*) fields';
                return;
            }
            else {
                this._errorMessage = null;
            }
        }

        super.goNext();
    }

    protected action_page_communicate(data: any): void {
        if (data.command === 'cmd-refresh-availableOption' && data.device) {
            this.devSvc.batchUpdateAvailableOption([data.device]).subscribe(res => {
                this.dialogInteractHandler(res);
            });
        }
    }

    private init_config_data(devs: DeviceInfo[], currentConfigData: DeviceNetConfigControl): DeviceNetConfigControl {
        if (currentConfigData) {
            return currentConfigData;
        }
        else {
            //only get first device now.
            const dev: DeviceInfo = devs[0];
            if (dev) {
                const configData: DeviceNetConfigControl = new DeviceNetConfigControl();
                configData.init(
                    { name: this.constantSvc.DEVKEY_NET_LAN_ENABLED, value: dev.currentSettings[this.constantSvc.DEVKEY_NET_LAN_ENABLED] },
                    { name: this.constantSvc.DEVKEY_NET_LAN_DHCP_ENABLED, value: dev.currentSettings[this.constantSvc.DEVKEY_NET_LAN_DHCP_ENABLED] },
                    { name: this.constantSvc.DEVKEY_NET_LAN_IP, value: dev.currentSettings[this.constantSvc.DEVKEY_NET_LAN_DHCP_ENABLED] ? '' : dev.currentSettings[this.constantSvc.DEVKEY_NET_LAN_IP] },
                    { name: this.constantSvc.DEVKEY_NET_LAN_GATEWAY, value: dev.currentSettings[this.constantSvc.DEVKEY_NET_LAN_DHCP_ENABLED] ? '' : dev.currentSettings[this.constantSvc.DEVKEY_NET_LAN_GATEWAY] },
                    { name: this.constantSvc.DEVKEY_NET_LAN_NETMASK, value: dev.currentSettings[this.constantSvc.DEVKEY_NET_LAN_DHCP_ENABLED] ? '' : dev.currentSettings[this.constantSvc.DEVKEY_NET_LAN_NETMASK] },
                    { name: this.constantSvc.DEVKEY_NET_LAN_DNS1, value: dev.currentSettings[this.constantSvc.DEVKEY_NET_LAN_DHCP_ENABLED] ? '' : dev.currentSettings[this.constantSvc.DEVKEY_NET_LAN_DNS1] },
                    { name: this.constantSvc.DEVKEY_NET_LAN_DNS2, value: dev.currentSettings[this.constantSvc.DEVKEY_NET_LAN_DHCP_ENABLED] ? '' : dev.currentSettings[this.constantSvc.DEVKEY_NET_LAN_DNS2] },
                    { name: this.constantSvc.DEVKEY_NET_WIFI_ENABLED, value: dev.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_ENABLED] },
                    { name: this.constantSvc.DEVKEY_NET_WIFI_DHCP_ENABLED, value: dev.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_ENABLED] ? dev.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_DHCP_ENABLED] : true },
                    { name: this.constantSvc.DEVKEY_NET_WIFI_IP, value: dev.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_DHCP_ENABLED] ? '' : dev.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_IP] },
                    { name: this.constantSvc.DEVKEY_NET_WIFI_GATEWAY, value: dev.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_DHCP_ENABLED] ? '' : dev.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_GATEWAY] },
                    { name: this.constantSvc.DEVKEY_NET_WIFI_NETMASK, value: dev.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_DHCP_ENABLED] ? '' : dev.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_NETMASK] },
                    { name: this.constantSvc.DEVKEY_NET_WIFI_DNS1, value: dev.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_DHCP_ENABLED] ? '' : dev.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_DNS1] },
                    { name: this.constantSvc.DEVKEY_NET_WIFI_DNS2, value: dev.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_DHCP_ENABLED] ? '' : dev.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_DNS2] },
                    { name: this.constantSvc.DEVKEY_NET_WIFI_SSID, value: dev.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_SSID] },
                    { name: this.constantSvc.DEVKEY_NET_WIFI_AUTH, value: !dev.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_AUTH] ? 'None' : dev.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_AUTH] },
                    { name: this.constantSvc.DEVKEY_NET_WIFI_PASSWORD, value: '' }
                );

                if (dev.currentSettings[this.constantSvc.DEVKEY_NET_LAN_CONNECTED]) {
                    configData.netType = NetworkType.Ethernet;
                }
                else if (dev.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_CONNECTED]) {
                    configData.netType = NetworkType.Wifi;
                }

                return configData;
            }
        }

        return null;
    }

    protected submit(): void {
        super.submit();

        const changeItems = this._actionData.configData.getChangeItems();
        const changeItemsToApply = changeItems.map((changeItem: { name: string, value: any, langKey?: string, secure?: boolean, warning?: boolean, isChanged?: boolean, origin?: any }) => {
            return {
                name: changeItem.name,
                value: changeItem.value,
                origin: changeItem.origin,
                langKey: changeItem.langKey
            }
        });

        this.devSvc.batchNetConfig(this._bSpecific ? [this._legalDevices[0]] : this._legalDevices.filter(d => d.isSelect), changeItemsToApply).subscribe(res => {
            this._page++;
            if (res.isFault()) {
                this._errorMessage = HelperLib.getErrorMessage(res);
            }

            if (this.dialogCompleteHandler) {
                this.dialogCompleteHandler(res);
            }
        });
    }
}