<div *ngIf="isInEdit; else templateViewMode">
    <div class="form-group row mb-2">
        <label for="col-policy-configs" class="col-5 col-md-4 col-xl-3 col-form-label col-form-label-sm">
            Configurations
        </label>
        <div class="col-7 col-md-8 col-xl-9">
            <div id="col-policy-configs" class="btn-group w-100">
                <button id="dropdownPolicyConfigLink" type="button"
                    class="btn btn-sm btn-action btn-light dropdown-toggle" data-toggle="dropdown">
                    <strong>{{ _selectedConfigCount }}</strong> configuration(s) selected
                </button>
                <div class="dropdown-menu w-100 pt-0" data-backdrop="static" data-keyboard="false"
                    aria-labelledby="dropdownPolicyConfigLink">
                    <ul class="option-list mb-1">
                        <ng-template ngFor let-c [ngForOf]="_configList">
                            <li class="dropdown-item" (click)="$event.stopPropagation()">
                                <input type="checkbox" [id]="'id-policy-config-' + c.name" class="cmn-checkbox"
                                    [checked]="c.checked" (change)="enableConfig(c, $event.target.checked)">
                                <label [for]="'id-policy-config-' + c.name" (click)="$event.stopPropagation()">
                                    <svg>
                                        <use xlink:href="#checkmark" />
                                    </svg>
                                    <span class="sr-only">Add config {{ c.name }} on table</span>
                                </label>
                                <span class="ml-2">{{ c.name }}</span>
                            </li>
                        </ng-template>
                    </ul>
                    <div class="text-center">
                        <button type="button" class="btn btn-sm btn-action btn-light">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="config-item-block">
        <ng-template ngFor let-c [ngForOf]="_configList">
            <div *ngIf="c.checked" class="config" [class.invalid]="!c.valid" [class.active]="_config?.key === c.key"
                (click)="selectConfig(c)">
                {{ c.name }}
                <span class="ml-2 fas fa-times remover" (click)="enableConfig(c, false)"></span>
            </div>
        </ng-template>
    </div>
    <div *ngIf="_config" class="config-content-block">
        <div [ngSwitch]="_config.key">
            <!-- Appstart -->
            <ng-template [ngSwitchCase]="_enumConfigKey.AppStart">
                <na-appstart [config]="_config.settingMap" [showBorder]="false" [disabled]="!_config.checked"
                    (onAppstartChanged)="onPolicySettingChanged(_config.key, _config, $event)">
                </na-appstart>
                <!-- Overlay qrcode -->
                <na-overlay-qrcode [config]="_config.settingMap" [showBorder]="false" [disabled]="!_config.checked"
                    [keepSupport]="true"
                    (onOverlayQRCodeChanged)="onPolicySettingChanged(_enumConfigKey.OverlayQRCode, _config, $event)">
                </na-overlay-qrcode>
            </ng-template>
            <!-- power save -->
            <ng-template [ngSwitchCase]="_enumConfigKey.Powersave">
                <na-powersave [keyPair]="_config.settingMap"
                    (onPowersaveChanged)="onPolicySettingChanged(_config.key, _config, $event)">
                </na-powersave>
            </ng-template>
            <!-- screen off -->
            <ng-template [ngSwitchCase]="_enumConfigKey.ScreenOff">
                <na-screenOff [data]="_config.settingMap[constantSvc.DEVKEY_FAKE_LOCKSCREEN_SCREENOFF].value"
                    (onDataChanged)="onPolicySettingChanged(_config.key, _config, $event)">
                </na-screenOff>
            </ng-template>
            <!-- screen saver -->
            <ng-template [ngSwitchCase]="_enumConfigKey.ScreenSaver">
                <na-screenSaver [data]="_config.settingMap[constantSvc.DEVKEY_FAKE_LOCKSCREEN_SCREENSAVER].value"
                    (onDataChanged)="onPolicySettingChanged(_config.key, _config, $event)">
                </na-screenSaver>
            </ng-template>
            <!-- maintenance -->
            <ng-template [ngSwitchCase]="_enumConfigKey.MaintenancePlayback">
                <na-mtPlayback [data]="_config.settingMap[constantSvc.DEVKEY_FAKE_MAINTENANCE].value"
                    (updated)="onPolicySettingChanged(_config.key, _config, $event)">
                </na-mtPlayback>
            </ng-template>
            <!-- orientation -->
            <ng-template [ngSwitchCase]="_enumConfigKey.DisplayOrientation">
                <div class="form-group">
                    <strong>
                        {{ _config.settingMap[constantSvc.DEVKEY_HD_VIDEO_ROTATION].langKey }}
                    </strong>
                    <div class="btn-group w-100">
                        <button id="dropdownRotationLink" type="button"
                            class="btn btn-sm btn-action btn-light dropdown-toggle" [disabled]="!_config.checked"
                            data-toggle="dropdown">
                            {{ _config.settingMap[constantSvc.DEVKEY_HD_VIDEO_ROTATION].value }}
                            <span class="caret"></span>
                            <span class="sr-only">Select display orientation</span>
                        </button>
                        <ul class="dropdown-menu dropdown-menu-left option-list w-100"
                            aria-labelledby="dropdownRotationLink">
                            <li *ngFor="let orientation of _config.availableValueList"
                                [class.selected]="_config.settingMap[constantSvc.DEVKEY_HD_VIDEO_ROTATION].value === orientation"
                                (click)="onPolicySettingChanged(_config.key, _config, orientation)">
                                {{ orientation }}
                            </li>
                        </ul>
                    </div>
                </div>
            </ng-template>
            <!-- volume -->
            <ng-template [ngSwitchCase]="_enumConfigKey.Volume">
                <div class="form-group">
                    <na-volume [disabled]="!_config.checked"
                        [volume]="_config.settingMap[constantSvc.DEVKEY_HD_AUDIO_MASTER_SOUND_LEVEL].value"
                        (onVolumeChanged)="onPolicySettingChanged(_config.key, _config, $event)">
                    </na-volume>
                </div>
            </ng-template>
            <!-- timezone -->
            <ng-template [ngSwitchCase]="_enumConfigKey.Timezone">
                <div class="form-group">
                    <strong>
                        {{ _config.settingMap[constantSvc.DEVKEY_TIME_TIMEZONE].langKey }}
                    </strong>
                    <div class="btn-group w-100">
                        <button id="dropdownTimezoneLink" type="button" [disabled]="!_config.checked"
                            class="btn btn-sm btn-action btn-light dropdown-toggle" [class.invalid]="!_config.valid"
                            data-toggle="dropdown">
                            {{ _config.settingMap[constantSvc.DEVKEY_TIME_TIMEZONE].value || 'Select timezone' }}
                            <span class="caret"></span>
                            <span class="sr-only">Select timezone</span>
                        </button>
                        <ul class="dropdown-menu dropdown-menu-left option-list w-100"
                            aria-labelledby="dropdownTimezoneLink">
                            <li *ngFor="let timezone of _config.availableValueList"
                                [class.selected]="_config.settingMap[constantSvc.DEVKEY_TIME_TIMEZONE].value === timezone.id"
                                (click)="onPolicySettingChanged(_config.key, _config, timezone.id)">
                                GMT{{ timezone.offset }}&nbsp;&nbsp;{{ timezone.displayName }}
                            </li>
                        </ul>
                    </div>
                </div>
            </ng-template>
            <!-- time server -->
            <ng-template [ngSwitchCase]="_enumConfigKey.Timeserver">
                <div class="form-group">
                    <na-timeserver [source]="_config.settingMap[constantSvc.DEVKEY_TIME_TIMESERVER_SOURCE].value"
                        [disabled]="!_config.checked"
                        [on]="_config.settingMap[constantSvc.DEVKEY_TIME_TIMESERVER_ENABLED].value"
                        (changed)="onPolicySettingChanged(_config.key, _config, $event)">
                    </na-timeserver>
                </div>
            </ng-template>
            <!-- daily reboot -->
            <ng-template [ngSwitchCase]="_enumConfigKey.DailyReboot">
                <div class="form-group">
                    <na-dailyReboot [config]="_config.settingMap" [disabled]="!_config.checked"
                        (onDataChanged)="onPolicySettingChanged(_config.key, _config, $event)">
                    </na-dailyReboot>
                </div>
            </ng-template>
            <!-- share -->
            <ng-template [ngSwitchCase]="_enumConfigKey.Share">
                <div class="form-group">
                    <na-share [data]="_config.settingMap['share'].value" [disabled]="!_config.checked"
                        [hasLicense]="true"
                        (onShareSettingChanged)="onPolicySettingChanged(_config.key, _config, $event)">
                    </na-share>
                </div>
            </ng-template>
        </div>
    </div>
</div>
<ng-template #templateViewMode>
    <div class="config-item-block">
        <ng-template ngFor let-c [ngForOf]="_configList">
            <div *ngIf="c.checked" class="config" [class.active]="_config?.key === c.key" (click)="selectConfig(c)">
                {{ c.name }}
            </div>
        </ng-template>
    </div>
    <div *ngIf="_config" class="card-body border p-2 px-3 bg-white">
        <div [ngSwitch]="_config.key">
            <!-- Appstart -->
            <ng-template [ngSwitchCase]="_enumConfigKey.AppStart">
                <na-appstart-preview [keyPair]="_config.settingMap" [target]="'value'"></na-appstart-preview>
            </ng-template>
            <!-- Power save -->
            <ng-template [ngSwitchCase]="_enumConfigKey.Powersave">
                <na-powersave-preview [keyPair]="_config.settingMap" [target]="'value'"></na-powersave-preview>
            </ng-template>
            <!-- orientation -->
            <ng-template [ngSwitchCase]="_enumConfigKey.DisplayOrientation">
                <div>
                    {{ _config.settingMap[constantSvc.DEVKEY_HD_VIDEO_ROTATION].value }}
                </div>
            </ng-template>
            <!-- volume -->
            <ng-template [ngSwitchCase]="_enumConfigKey.Volume">
                <div>
                    <na-volume-preview
                        [volume]="_config.settingMap[constantSvc.DEVKEY_HD_AUDIO_MASTER_SOUND_LEVEL].value">
                    </na-volume-preview>
                </div>
            </ng-template>
            <!-- timezone -->
            <ng-template [ngSwitchCase]="_enumConfigKey.Timezone">
                <div>
                    {{ _config.settingMap[constantSvc.DEVKEY_TIME_TIMEZONE].value }}
                </div>
            </ng-template>
            <!-- time server -->
            <ng-template [ngSwitchCase]="_enumConfigKey.Timeserver">
                <na-timeserver-preview [enabled]="_config.settingMap[constantSvc.DEVKEY_TIME_TIMESERVER_ENABLED].value"
                    [source]="_config.settingMap[constantSvc.DEVKEY_TIME_TIMESERVER_SOURCE].value">
                </na-timeserver-preview>
            </ng-template>
            <!-- daily reboot -->
            <ng-template [ngSwitchCase]="_enumConfigKey.DailyReboot">
                <na-dailyReboot-preview [config]="_config.settingMap" [target]="'value'"></na-dailyReboot-preview>
            </ng-template>
            <!-- share -->
            <ng-template [ngSwitchCase]="_enumConfigKey.Share">
                <na-share-preview [data]="_config.settingMap['share'].value"></na-share-preview>
            </ng-template>
            <!-- screen off -->
            <ng-template [ngSwitchCase]="_enumConfigKey.ScreenOff">
                <na-screenOff-preview [data]="_config.settingMap[constantSvc.DEVKEY_FAKE_LOCKSCREEN_SCREENOFF].value">
                </na-screenOff-preview>
            </ng-template>
            <!-- Screen saver -->
            <ng-template [ngSwitchCase]="_enumConfigKey.ScreenSaver">
                <na-screenSaver-preview
                    [data]="_config.settingMap[constantSvc.DEVKEY_FAKE_LOCKSCREEN_SCREENSAVER].value">
                </na-screenSaver-preview>
            </ng-template>
            <!-- maintenance playback -->
            <ng-template [ngSwitchCase]="_enumConfigKey.MaintenancePlayback">
                <na-mtPlayback-preview [data]="_config.settingMap[constantSvc.DEVKEY_FAKE_MAINTENANCE].value">
                </na-mtPlayback-preview>
            </ng-template>
        </div>
    </div>
</ng-template>