import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { iif as observableIf, of as observableOf, from } from 'rxjs';
import { catchError, concatMap } from 'rxjs/operators';

import { NAService } from '../../API/na.service';
import { IAPIRx } from '../../API/api.base';
import { AppConfigService } from '../../app.config';
import { AppService } from '../../app.service';

declare var grecaptcha;

@Component({
    templateUrl: './signup.component.html',
    styleUrls: ['../entry.style.css', './signup.component.css']
})
export class SignUpComponent {
    _regEmail: string;
    _regPassword: string;
    _regPasswordVerify: string;
    _regUserName: string = 'admin';
    _regFirstName: string;
    _regLastName: string;
    _regSecondaryEmail: string;
    _regCompany: string;

    _isSigningUp: boolean = false;

    _bReCaptchaReady: boolean = false;

    constructor(
        private router: Router,
        private naSvc: NAService,
        public appSvc: AppService
    ) {
        grecaptcha.ready(() => {
            this._bReCaptchaReady = true;
        });
    }

    submit(): void {
        this._isSigningUp = true;

        observableIf(() => AppConfigService.configs.common.reCaptcha.enabled ? true : false,
            from(grecaptcha.execute(AppConfigService.configs.common.reCaptcha.key, { action: 'signupPage' })).pipe(
                catchError(error => {
                    return observableOf('');
                })
            ),
            observableOf('')
        ).pipe(
            concatMap(() => {
                return this.naSvc.signUp({ accountEmail: this._regEmail, accountPassword: this._regPassword, firstName: this._regFirstName, lastName: this._regLastName, accountCompanyName: this._regCompany });
            })
        ).subscribe((res: IAPIRx<void>) => {
            this._isSigningUp = false;
            this.router.navigate(['/account/register/activate', this._regEmail]);  
        });
    }
}