import { Component } from '@angular/core';
import * as _semver from 'semver';

import { DevFuncConfirmInterface } from '../../base/confirm/dev-func-confirm.interface';
import { FirmwareFamilyInfo, FIRMWARE_UPDATE_METHOD, FirmwareUpdateScheduleInfo } from '../firmware-data';
import { ConstantService } from '../../../../../app/lib/common/constant.service';

@Component({
    templateUrl: './firmware-confirm-subform.component.html',
    styleUrls: ['../../base/base-config-form.component.css', './firmware-confirm-subform.component.css']
})
export class FirmwareConfirmSubFormComponent implements DevFuncConfirmInterface {
    private _actionData: any;
    set actionData(data: any) {
        this._actionData = data;
        if (this._actionData) {
            this._bSpecific = this._actionData.bSpecific;
            this._schedule = this._actionData.schedule;
            this._firmwareFamilyInfos = this._actionData.firmwareFamilyInfo;
            this.checkNeedUpdate();
        }
    }

    set allowSubmitCallback(fn: (isAllow: boolean) => void) {
        fn(this._noNeedUpdate ? false : true);
    }

    _enumFirmwareUpdateMethod: typeof FIRMWARE_UPDATE_METHOD = FIRMWARE_UPDATE_METHOD;
    _firmwareFamilyInfos: FirmwareFamilyInfo[] = [];
    _schedule: FirmwareUpdateScheduleInfo;
    _bSpecific: boolean = false;
    _noNeedUpdate: boolean = false;

    constructor(private constantSvc: ConstantService) { }

    private hasSelectDevice(familyInfo: FirmwareFamilyInfo): boolean {
        return this._bSpecific || familyInfo.devices.filter(d => d.isSelect).length > 0;
    }

    private checkNeedUpdate(): void {
        this._firmwareFamilyInfos.forEach(f => {
            f.needUpdate = this.need_do_update(f);
        });

        this._noNeedUpdate = this._firmwareFamilyInfos.filter(f => f.needUpdate).length === 0;
    }

    private need_do_update(familyInfo: FirmwareFamilyInfo): boolean {
        if (familyInfo.updateMethod === FIRMWARE_UPDATE_METHOD.Latest) {
            if (!familyInfo.updateInfo) {
                return false;
            }

            let is_all_latest: boolean = true;
            for (const dev of familyInfo.devices) {
                if (dev.currentSettings[this.constantSvc.DEVKEY_INFO_FW] && familyInfo.updateInfo.firmwareVersion) {
                    if (_semver.valid(dev.currentSettings[this.constantSvc.DEVKEY_INFO_FW]) && _semver.valid(familyInfo.updateInfo.firmwareVersion)) {
                        if (_semver.lt(dev.currentSettings[this.constantSvc.DEVKEY_INFO_FW], familyInfo.updateInfo.firmwareVersion, true)) {
                            is_all_latest = false;
                            break;
                        }
                    }
                    else {
                        //if the version format is not compatible with semver rule, check by ourself?
                        //just compare by string rule.
                        if (dev.currentSettings[this.constantSvc.DEVKEY_INFO_FW] < familyInfo.updateInfo.firmwareVersion) {
                            is_all_latest = false;
                            break;
                        }
                    }
                }
            }

            if (is_all_latest) {
                return false;
            }
        }

        if (!this._bSpecific) {
            if (familyInfo.devices.filter(d => d.isSelect).length === 0) {
                return false;
            }
        }

        return true;
    }
}