<div class='modal-dialog config-dlg' role="document">
    <div class='modal-content'>
        <div class='modal-header'>
            <h4 class="modal-title">{{ title }}</h4>
        </div>
        <div class='modal-body'>
            <div>
                <div>
                    The credential <strong class="red-1">{{ _scep?.alias }}</strong> will not be removed from device but only from IAdeaCare. Are you sure to process the deletion ?
                </div>
                <div *ngIf="_page === _enumPage.result" class="mt-2">
                    <div *ngIf="_errorMessage" class="block block-error">
                        <i class="fas fa-exclamation-circle"></i>
                        <span class="ml-2">Remove credential {{ _scep?.alias }} failed :</span>
                        <div>{{ _errorMessage }}</div>
                    </div>
                </div>
            </div>
            <div class="row control-block d-flex justify-content-center align-items-center mt-4">
                <button #btnClose type="button" class="btn btn-sm btn-action btn-light"
                    [disabled]="_page === _enumPage.submit" data-dismiss="modal">
                    <span class="fas fa-times-circle mr-1"></span>
                    {{ 'key-cancel' | translate | capitalFirstWord }}
                </button>
                <button type="button" class="btn btn-sm btn-action btn-light" [disabled]="_page === _enumPage.submit"
                    (click)="remove()">
                    <span class="fas fa-trash-alt mr-1"></span>
                    Delete
                </button>
                <div *ngIf="_page === _enumPage.submit" class="loading loading-p0 ml-4">
                    <div class="loading-box"></div>
                </div>
            </div>
        </div>
    </div>
</div>