import { Injectable } from '@angular/core';
import { ScepAddDlgComponent } from './scep-dlg-add.component';
import { ScepAddDeviceDlgComponent } from './scep-dlg-addDevice.component';
import { ScepRemoveDlgComponent } from './scep-dlg-remove.component';
import { ScepRenewDlgComponent } from './scep-dlg-renew.component';
import { ScepFuncItem, SCEP_FUNC_ADD, SCEP_FUNC_ADD_DEVICE, SCEP_FUNC_REMOVE, SCEP_FUNC_RENEW_DEVICE, SCEP_FUNC_UPDATE } from './scep-func.def';

@Injectable()
export class ScepFuncService {
  private _items: ScepFuncItem[] = [];

  constructor() {
    this._items = [
      new ScepFuncItem(ScepAddDlgComponent, SCEP_FUNC_ADD, 'Add new credential'),
      new ScepFuncItem(ScepRemoveDlgComponent, SCEP_FUNC_REMOVE, 'Remove credential'),
      new ScepFuncItem(ScepRenewDlgComponent, SCEP_FUNC_RENEW_DEVICE, 'Renew device'),
      new ScepFuncItem(ScepAddDeviceDlgComponent, SCEP_FUNC_UPDATE, 'Update credential'),
      new ScepFuncItem(ScepAddDeviceDlgComponent, SCEP_FUNC_ADD_DEVICE, 'Add device')
    ];
  }

  getAllItems(): ScepFuncItem[] {
    return this._items;
  }

  getItemByName(name: string): ScepFuncItem {
    return this._items.find(i => i.name === name);
  }
}
