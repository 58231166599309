import { Component, OnInit } from '@angular/core';

import { DeviceInfo } from '../../../device/data/device-info';
import { HelperLib, SharePermissionType } from '../../../../lib/common/helper.lib';

@Component({
    templateUrl: './share-action-subform.component.html',
    styleUrls: ['../../base/base-config-form.component.css', './share-action-subform.component.css']
})
export class ShareActionSubFormComponent implements OnInit {
    _devices: DeviceInfo[] = [];

    private _individualShareSettingList: { accountID: string, accountName: string, permission: string[] }[] = [];
    private _individualShareSettingDevice: DeviceInfo;

    private _actionData: any;
    set actionData(data: any) {
        this._actionData = data;
        if (this._actionData) {
            this._devices = this._actionData.devices;
            this.get_common_settings();
        }
    }

    constructor() { }

    ngOnInit(): void { }

    viewShareSettingByDevice(device: DeviceInfo): void {
        if (this._individualShareSettingDevice === device) {
            return;
        }

        this._individualShareSettingDevice = device;
        this._individualShareSettingList = [];

        if (this._individualShareSettingDevice.virtualDeviceSharing) {
            Object.keys(this._individualShareSettingDevice.virtualDeviceSharing).forEach((accountID: string) => {
                if (!this._actionData.commonShareInfoMap[accountID]) {
                    this._individualShareSettingList.push(this._individualShareSettingDevice.virtualDeviceSharing[accountID]);
                }
            });
        }
    }

    private get_common_settings(): void {
        this._actionData.commonShareInfoMap = this._actionData.commonShareInfoMap || {};
        if (Object.keys(this._actionData.commonShareInfoMap).length > 0) {
            return;
        }

        //one device
        if (this._actionData.devices.length === 1) {
            this._actionData.commonShareInfoMap = this._actionData.commonShareInfoMap || {};
            const d: DeviceInfo = this._actionData.devices[0] as DeviceInfo;
            if (d && d.virtualDeviceSharing) {

                Object.keys(d.virtualDeviceSharing).forEach((accountID: string) => {
                    this._actionData.commonShareInfoMap[accountID] = {
                        accountID: d.virtualDeviceSharing[accountID].accountID,
                        accountName: d.virtualDeviceSharing[accountID].accountName,
                        permission: d.virtualDeviceSharing[accountID].permission.filter(p => HelperLib.isAdjustableSharePermission(p)).map(p => p),
                        action: ''
                    };
                });
            }

            return;
        }

        //multiple devices
        let target: DeviceInfo;
        let targetSharingListLen: number = 0;

        //1. find a device which has the fewest sharing settings.
        for (const dev of this._actionData.devices) {
            const shareingListLen: number = Object.keys(dev.virtualDeviceSharing).length;
            if (shareingListLen === 0) {
                target = null;
                break;
            }

            if (!target || shareingListLen < targetSharingListLen) {
                target = dev;
                targetSharingListLen = Object.keys(target.virtualDeviceSharing).length;
            }
        }

        if (target) {
            const commonSharingMap: { [accountID: string]: { accountID: string, accountName: string, permission: string[], action: string } } = {};
            Object.keys(target.virtualDeviceSharing).forEach((accountID: string) => {
                commonSharingMap[accountID] = {
                    accountID: target.virtualDeviceSharing[accountID].accountID,
                    accountName: target.virtualDeviceSharing[accountID].accountName,
                    permission: target.virtualDeviceSharing[accountID].permission.map(p => p),
                    action: ''
                };
            });

            for (const dev of this._actionData.devices) {
                if (dev !== target) {
                    const removedAccountIDList: string[] = [];

                    for (const targetAccountID of Object.keys(commonSharingMap)) {
                        //if compared target does not have this shared account, this shared account is not a common case.
                        if (!dev.virtualDeviceSharing[targetAccountID]) {
                            removedAccountIDList.push(targetAccountID);
                            break;
                        }

                        //if compared target does not have same number of permissions on this shared account, this shared account is not a common case.
                        if (dev.virtualDeviceSharing[targetAccountID].permission.length !== commonSharingMap[targetAccountID].permission.length) {
                            removedAccountIDList.push(targetAccountID);
                            break;
                        }

                        //if compared target does not have same permission on this shared account, this shared account is not a common case.
                        let hasSamePermission: boolean = true;
                        for (const permission of commonSharingMap[targetAccountID].permission) {
                            const samePermission: string = dev.virtualDeviceSharing[targetAccountID].permission.find(p => p === permission);
                            if (!samePermission) {
                                hasSamePermission = false;
                                break;
                            }
                        }

                        if (!hasSamePermission) {
                            removedAccountIDList.push(targetAccountID);
                            break;
                        }
                    }

                    if (removedAccountIDList.length > 0) {
                        removedAccountIDList.forEach((removedAccountID: string) => {
                            delete commonSharingMap[removedAccountID];
                        });
                    }

                    if (Object.keys(commonSharingMap).length === 0) {
                        break;
                    }
                }
            }

            this._actionData.commonShareInfoMap = commonSharingMap;
        }
    }

    onShareSettingChanged(e: {
        data: {
            [accountID: string]: {
                accountID: string,
                accountName: string,
                permission: SharePermissionType[],
                action: string,
                original?: {
                    accountName: string,
                    permissionList: string[]
                }
            }
        },
        isValid: boolean
    }): void {
        this._actionData.isValid = e.isValid;
        if (e.isValid) {
            Object.keys(e.data).forEach((accountID: string) => {
                this._actionData.commonShareInfoMap[accountID] = e.data[accountID];
            });
        }
    }
}