import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../../api.base';
import { IScepInfo } from './api.ent.scep.data';

export class APIEnterpriseCreateScepManager extends APIBaseManager<void, void, IScepInfo, IScepInfo> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_CreateScep';
        this._apiMethod = 'POST';
    }

    protected getRequestURL(pathParameters: void, queryParameters: void): string {
        return super.getRequestURL() + 'enterprises/scepSettings';
    }
}