import { Component, OnInit, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DeviceGroupFuncInterface } from './group-func.def';
import { DeviceGroupInfo, DeviceGroupMode, DeviceGroupType, DEVICE_GROUP_ID_HOME } from '../group.data';
import { DeviceGroupService } from '../dev-group.service';
import { AccountService } from '../../../../entry/account.service';

@Component({
    templateUrl: './group-dlg-move.component.html'
})
export class DeviceGroupMoveDlgComponent implements DeviceGroupFuncInterface, OnInit, OnDestroy {
    title: string;
    group: DeviceGroupInfo;

    _updating: boolean;
    other?: { fromGroup?: DeviceGroupInfo, targetGroup?: DeviceGroupInfo };

    _mode: DeviceGroupMode = DeviceGroupMode.pickone;
    _groupRoot: DeviceGroupInfo;
    _moveList: DeviceGroupInfo[] = [];

    _fromGroup: DeviceGroupInfo;
    _targetGroup: DeviceGroupInfo;
    _allowChooseTargetGroup: boolean = true;

    _errorMessage: string;
    _enumGroupType: typeof DeviceGroupType = DeviceGroupType;
    private _allUnsubscribe: Subject<void> = new Subject();

    private _dlgCloseElementRef: ElementRef;
    @ViewChild('dlgClose', { static: true })
    set dlgClose(holder: ElementRef) {
        if (holder) {
            this._dlgCloseElementRef = holder;
        }
    }

    constructor(private accountSvc: AccountService, private groupSvc: DeviceGroupService) {
        this._groupRoot = this.groupSvc.getRootGroup(null);
    }

    ngOnInit(): void {
        this.accountSvc.loginChanged.pipe(
            takeUntil(this._allUnsubscribe)
        ).subscribe((isLogin: boolean) => {
            if (!isLogin) {
                this._dlgCloseElementRef.nativeElement.click();
            }
        });

        if (this.other) {
            this._fromGroup = this.other.fromGroup;
            if (this.other.targetGroup) {
                this._allowChooseTargetGroup = false;
                this._targetGroup = this.other.targetGroup;
            }
        }

        this._moveList = this._fromGroup ? [this._fromGroup] : (this.group ? this.group.childs.filter(c => c.selected || (c.data ? c.data.isSelect : false)) : []);
        if (this.group.id === DEVICE_GROUP_ID_HOME && this._moveList.length === 0) {
            this._moveList = this.groupSvc.getAllGroupDeviceList().filter(d => d.data && d.data.isSelect);
        }
    }

    ngOnDestroy(): void {
        this._allUnsubscribe.next();
        this._allUnsubscribe.complete();
    }

    onTargetGroupChange(g: DeviceGroupInfo): void {
        this._targetGroup = g;
    }

    moveGroup(): void {
        //move sub-groups inside A group to A group
        if (this.group.id === this._targetGroup.id) {
            this._errorMessage = 'Do not need to move';
            return;
        }
        //one of the moved sub-groups = target group
        if (this._moveList.filter(g => g.type === DeviceGroupType.group).find(g => g.id === this._targetGroup.id)) {
            this._errorMessage = 'Could not move group to itself';
            return;
        }
        //move one of the moved devices to HOME
        if (this._targetGroup.id === DEVICE_GROUP_ID_HOME && this._moveList.filter(g => g.type === DeviceGroupType.device).length > 0) {
            this._errorMessage = 'Could not move device to "HOME"';
            return;
        }
        //move parent group to its child group
        if (this._fromGroup) {
            let parentID: string = this._targetGroup.parentID;
            while (parentID && parentID !== DEVICE_GROUP_ID_HOME) {
                if (parentID === this._fromGroup.id) {
                    this._errorMessage = 'Could not move parent group to its child group';
                    return;
                }

                const parentGroup = this.groupSvc.getGroupByID(null, parentID);
                if (parentGroup) {
                    parentID = parentGroup.parentID;
                }
            }
        }

        this._errorMessage = '';
        this._updating = true;

        this.groupSvc.moveGroup(null, this._moveList, this._targetGroup.id, true).subscribe((res: { isFault: boolean, errorMessage?: string }) => {
            this._updating = false;
            if (res.isFault) {
                this._errorMessage = res.errorMessage;
                return;
            }

            setTimeout(() => {
                this._dlgCloseElementRef.nativeElement.click();
            }, 0);
        });
    }
}