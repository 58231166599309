import { Injectable, EventEmitter, Output, Directive } from '@angular/core';
import { Observable, of } from 'rxjs';
import { concatAll, concatMap, map } from 'rxjs/operators';

import { ConfigurableItemKey, IClass } from '../../../lib/common/common.data';
import { Logger } from '../../../lib/common/logger';
import { PolicyInfo, PolicyInfoSecurity, PolicyInfoGroupConfig, PolicyType, PolicyDataGroupConfigItem, PolicyDataGroupConfig, PolicyDataSecurity, PolicyInfoAppManagement, PolicyDataAppManagement, PolicyInfoFirmwareUpdate, PolicyDataFirmwareUpdate, PolicyDataCertificate, PolicyInfoCertificate } from './policy.data';
import { NAService } from '../../../../app/API/na.service';
import { AccountService } from '../../../../app/entry/account.service';
import { PolicyRawInfo, PolicyRawInfoContent } from '../../../../app/API/v1/device/policy/api.policy.common';
import { IAPIRx } from '../../../../app/API/api.base';
import { HelperLib, REFRESH_DURATION, WEEKDAY_ABBR_LIST } from '../../../../app/lib/common/helper.lib';
import { ConstantService } from '../../../../app/lib/common/constant.service';
import { AppStartOverlayInfo } from '../../../../app/content/device/data/device-info';
import { DeviceGroupType } from '../../device/group/group.data';
import { IVirtualDeviceCalendarItem, IVirtualDeviceRxData } from '../../../../app/API/v1/VirtualDevice/virtualDevice.common';
import { GroupRawInfo } from '../../../API/v1/device/group/api.group.common';
import { LockScreenMode, ScreenOffInfo, ScreenSaverInfo } from '../../../uiElement/schedule/screen/screen.data';
import { MaintenancePlaybackInfo } from '../../../uiElement/maintenancePlayback/mtPlayback.data';
import { AppConfigService } from '../../../app.config';
import { AppManagementInfo } from '../../../uiElement/appMgr/appmgr.data';
import { CertInfo } from 'app/content/admin/cert/cert.data';

@Directive()
@Injectable()
export class PolicyService implements IClass {
    readonly Enable_screenOff: boolean = AppConfigService.configs.devPage.func.basicConfig.element.screenOff.enabled;
    readonly Enable_maintenance: boolean = AppConfigService.configs.devPage.func.basicConfig.element.maintenancePlayback;
    readonly Enable_powersave: boolean = AppConfigService.configs.devPage.func.basicConfig.element.powersave;
    readonly Enable_screensaver: boolean = AppConfigService.configs.devPage.func.basicConfig.element.screenSaver;

    static POLICY_INDEX: number = 1;

    className: string;
    _policyList: PolicyInfo[] = [];

    private _ctrlAccountName: string;
    private _refreshing: boolean = false;
    private _lastUpdateTime: Date;

    @Output() policyUpdated = new EventEmitter<PolicyInfo>();

    constructor(
        private naSvc: NAService,
        private accountSvc: AccountService,
        private constantSvc: ConstantService) {
        this.className = 'PolicySvc';
        this.accountSvc.loginChanged.subscribe((isLogin: boolean) => {
            Logger.logInfo(this.className, '', 'Login status changed. IsLogin? = ' + isLogin);

            if (!isLogin) {
                this._policyList = [];
                this._lastUpdateTime = null;
            }
            else {
                this._ctrlAccountName = this.accountSvc.isEnterprise() ? this.accountSvc.enterpriseAccountName : this.accountSvc.accountName;
            }
        });

        this._ctrlAccountName = this.accountSvc.isEnterprise() ? this.accountSvc.enterpriseAccountName : this.accountSvc.accountName;
    }

    getPolicyList(forceRefresh: boolean = false): Observable<PolicyInfo[]> {
        if (this._refreshing) {
            Logger.logInfo(this.className, 'getPolicyList', 'refreshing policies...')
            return new Observable((observer) => {
                HelperLib.checkState(1, () => { return !this._refreshing }, () => {
                    observer.next(this._policyList);
                    observer.complete();
                });
            });
        }

        if (!this.needRefresh() && !forceRefresh) {
            return of(this._policyList);
        }

        this._refreshing = true;
        this._policyList = [];
        return this.naSvc.listPolicy(this.accountSvc.token).pipe(
            concatMap((res: IAPIRx<{ [accountName: string]: { [policyID: string]: PolicyRawInfo } }>) => {
                if (res.error === 0 && res.data && res.data[this._ctrlAccountName]) {
                    const policyRawList: PolicyRawInfo[] = Object.keys(res.data[this._ctrlAccountName]).map((policyID: string) => res.data[this._ctrlAccountName][policyID]);
                    if (policyRawList.length > 0) {
                        return new Observable<{ isFault: boolean, policyRawData: { [accountName: string]: { [policyID: string]: PolicyRawInfo } }, policyAssignMap: { [policyID: string]: { id: string, type: DeviceGroupType, name: string }[] } }>((observer) => {
                            let counter: number = policyRawList.filter(p => p.isUsed).length;
                            const assignMap: { [policyID: string]: { id: string, type: DeviceGroupType, name: string }[] } = {};

                            of(true).pipe(
                                concatMap(() => {
                                    const obs: Observable<number>[] = [];
                                    policyRawList.forEach((p: PolicyRawInfo) => {
                                        if (p.isUsed) {
                                            obs.push(this.getPolicyAssignList(p.policyID).pipe(
                                                map((assignList: { id: string, type: DeviceGroupType, name: string }[]) => {
                                                    assignMap[p.policyID] = assignList;
                                                    counter -= 1;
                                                    return counter;
                                                })
                                            ));
                                        }
                                    });

                                    if (counter === 0) {
                                        obs.push(of(0));
                                    }

                                    return obs;
                                }),
                                concatAll()
                            ).subscribe((c: number) => {
                                if (c === 0) {
                                    observer.next({ isFault: false, policyRawData: res.data, policyAssignMap: assignMap });
                                    observer.complete();
                                }
                            })
                        });
                    }
                }

                return of({ isFault: true, policyRawData: null, policyAssignMap: null });
            }),
            map((res: { isFault: boolean, policyRawData: { [accountName: string]: { [policyID: string]: PolicyRawInfo } }, policyAssignMap: { [policyID: string]: { id: string, type: DeviceGroupType, name: string }[] } }) => {
                if (!res.isFault) {
                    this.transformFromRawContent(res.policyRawData, res.policyAssignMap);
                    this._lastUpdateTime = new Date();
                }

                this._refreshing = false;
                Logger.logInfo(this.className, 'getPolicyList', 'Policy list = ', this._policyList);

                return this._policyList;
            })
        );
    }

    getPolicyAssignList(policyID: string, includeDevice: boolean = false): Observable<{ id: string, type: DeviceGroupType, name: string }[]> {
        const assignList: { id: string, type: DeviceGroupType, name: string }[] = [];

        return this.naSvc.listPolicyGroup({ devicePolicyID: policyID }, this.accountSvc.token).pipe(
            concatMap((res: IAPIRx<GroupRawInfo[]>) => {
                if (res.error === 0 && res.data) {
                    res.data.forEach((g: GroupRawInfo) => {
                        assignList.push({ id: g.groupID, type: DeviceGroupType.group, name: g.groupName });
                    });
                }

                if (includeDevice) {
                    return this.naSvc.listPolicyDevice({ devicePolicyID: policyID }, this.accountSvc.token).pipe(
                        map((res: IAPIRx<IVirtualDeviceRxData[]>) => {
                            if (res.error === 0 && res.data) {
                                res.data.forEach((d: IVirtualDeviceRxData) => {
                                    assignList.push({ id: d.virtualDeviceID, type: DeviceGroupType.device, name: d.virtualDeviceName });
                                });
                            }

                            return assignList;
                        })
                    )
                }

                return of(assignList);
            })
        );
    }

    getPolicyByIDList(policyIDList: string[]): Observable<PolicyInfo[]> {
        return this.getPolicyList().pipe(
            map((policyList: PolicyInfo[]) => {
                const result: PolicyInfo[] = [];
                for (let policyID of policyIDList) {
                    const found: PolicyInfo = this._policyList.find(p => p.id === policyID);
                    if (found) {
                        result.push(found);
                    }
                }

                return result;
            })
        );
    }

    isPolicyNameExist(policy: PolicyInfo): boolean {
        return this._policyList.find(p => p.id !== policy.id && p.name === policy.name) ? true : false;
    }

    getSupportPolicyTypesByLevel(isEnterprise: boolean): PolicyType[] {
        const policyTypes: PolicyType[] = [PolicyType.Configuration, PolicyType.Security, PolicyType.FirmwareUpdate];
        if (isEnterprise) {
            policyTypes.push(PolicyType.Application, PolicyType.Certificate);
        }

        return policyTypes;
    }

    createPolicy(p: PolicyInfo, assignList: { id: string, type: DeviceGroupType, name: string }[] = []): Observable<{ data?: PolicyRawInfo, error: number | string, errorMessage?: string }> {
        Logger.logInfo(this.className, 'createPolicy', 'create policy: ', p);
        return this.naSvc.createPolicy({ policyName: p.name, policyType: p.type, policyContent: this.transformToRawContent(p) }, this.accountSvc.token).pipe(
            concatMap((res: IAPIRx<PolicyRawInfo>) => {
                if (res.error === 0) {
                    this.resetDevicePasswordInSecurityPolicy(p.data as PolicyDataSecurity, res.data);
                    p.id = res.data.policyID;
                    this._policyList.push(p);

                    if (assignList.length > 0) {
                        return this.assignPolicy(p, assignList);
                    }
                }

                return of({ data: res.data, error: res.error, errorMessage: res.error !== 0 ? res.error + ' ' + res.errorMessage : '' });
            })
        );
    }

    deletePolicy(p: PolicyInfo): Observable<{ error: number | string, errorMessage?: string }> {
        Logger.logInfo(this.className, 'deletePolicy', 'delete policy: ', p);
        return this.naSvc.removePolicy({ devicePolicyID: p.id }, this.accountSvc.token).pipe(
            map((res: IAPIRx<any>) => {
                if (res.error === 0) {
                    this._policyList.splice(this._policyList.indexOf(p), 1);
                }

                return { error: res.error, errorMessage: res.error !== 0 ? HelperLib.getErrorMessage(res) : '' };
            })
        );
    }

    updatePolicy(p: PolicyInfo, assignList: { id: string, type: DeviceGroupType, name: string }[] = [], removeList: { id: string, type: DeviceGroupType, name: string }[] = []): Observable<{ data?: PolicyRawInfo, error: number | string, errorMessage?: string }> {
        Logger.logInfo(this.className, 'updatePolicy', 'update policy: ', p);
        return this.naSvc.updatePolicy({ devicePolicyID: p.id }, { policyName: p.name, policyContent: this.transformToRawContent(p) }, this.accountSvc.token).pipe(
            concatMap((res: IAPIRx<PolicyRawInfo>) => {
                if (res.error === 0 && (assignList.length > 0 || removeList.length > 0)) {
                    return this.assignPolicy(p, assignList, removeList).pipe(
                        map((assignRes: { error: number | string, errorMessage?: string }) => Object.assign({ data: res.data }, assignRes))
                    );
                }
                return of({ data: res.data, error: 0, errorMessage: res.error !== 0 ? HelperLib.getErrorMessage(res) : '' });
            }),
            map((res: { data: PolicyRawInfo, error: number | string, errorMessage?: string }) => {
                if (res.error === 0) {
                    this.resetDevicePasswordInSecurityPolicy(p.data as PolicyDataSecurity, res.data);
                    const found: PolicyInfo = this._policyList.find(ps => ps.id === p.id);
                    found.assignFrom(p);

                    if (this.policyUpdated) {
                        this.policyUpdated.next(found);
                    }
                }

                return { data: res.data, error: res.error, errorMessage: res.error !== 0 ? '[' + res.error + '] ' + res.errorMessage : '' };
            })
        );
    }

    assignPolicy(p: PolicyInfo, assignList: { id: string, type: DeviceGroupType, name: string }[] = [], removeList: { id: string, type: DeviceGroupType, name: string }[] = []): Observable<{ error: number | string, errorMessage?: string }> {
        return this.naSvc.assignPolicy({ devicePolicyID: p.id }, {
            assign: {
                deviceGroupIDList: assignList.filter(g => g.type === DeviceGroupType.group).map(g => g.id),
                //virtualDeviceIDList: assignList.filter(g => g.type === DeviceGroupType.device).map(g => g.id)
            },
            remove: {
                deviceGroupIDList: removeList.filter(g => g.type === DeviceGroupType.group).map(g => g.id),
                //virtualDeviceIDList: removeList.filter(g => g.type === DeviceGroupType.device).map(g => g.id)
            }
        }, this.accountSvc.token).pipe(
            map((res: IAPIRx<any>) => {
                if (res.error === 0) {
                    //update cache for assignList and removeList for this policy
                    const finalAssignMap: { [id: string]: { id: string, type: DeviceGroupType, name: string } } = {};
                    p.groupList.forEach(g => { finalAssignMap[g.id] = g });
                    assignList.forEach(a => { finalAssignMap[a.id] = a });
                    removeList.forEach(a => {
                        if (finalAssignMap[a.id]) {
                            delete finalAssignMap[a.id];
                        }
                    });
                    p.groupList = Object.keys(finalAssignMap).map(id => finalAssignMap[id]);
                    p.isUsed = p.groupList.length > 0;

                    //update cache for other policies. 
                    const targetCheckMap: { map: { [id: string]: boolean }, counter: number } = { map: {}, counter: 0 };
                    assignList.forEach(a => {
                        targetCheckMap.map[a.id] = true;
                        targetCheckMap.counter++;
                    });

                    for (let checkPolicy of this._policyList) {
                        if (checkPolicy.id === p.id || checkPolicy.groupList.length === 0 || checkPolicy.type !== p.type) {
                            continue;
                        }

                        const removedAssigneeTargetList: { id: string, type: DeviceGroupType, name: string }[] = [];
                        for (let target of checkPolicy.groupList) {
                            if (targetCheckMap.map[target.id]) {
                                removedAssigneeTargetList.push(target);
                                delete targetCheckMap.map[target.id];
                                targetCheckMap.counter--;
                            }

                            if (targetCheckMap.counter === 0) {
                                break;
                            }
                        }

                        removedAssigneeTargetList.forEach((removedGroup: { id: string, type: DeviceGroupType, name: string }) => {
                            checkPolicy.groupList.splice(checkPolicy.groupList.indexOf(removedGroup), 1);
                        });

                        checkPolicy.isUsed = checkPolicy.groupList.length > 0;

                        if (targetCheckMap.counter === 0) {
                            break;
                        }
                    }
                }

                return { error: res.error, errorMessage: res.error !== 0 ? HelperLib.getErrorMessage(res) : '' }
            })
        );
    }

    private needRefresh(): boolean {
        return (!this._lastUpdateTime || Date.now() - this._lastUpdateTime.getTime() > REFRESH_DURATION * 20000) ? true : false;
    }

    private transformToRawContent(policy: PolicyInfo): PolicyRawInfoContent {
        const policyContent: PolicyRawInfoContent = new PolicyRawInfoContent();

        switch (policy.type) {
            case PolicyType.Security:
                {
                    const securityConfig: PolicyDataSecurity = policy.data as PolicyDataSecurity;
                    if (securityConfig) {
                        policyContent.Task = {
                            [this.constantSvc.TASKTYPE_CONFIG_BASIC]: { resourceData: [] }
                        };
                        policyContent.Task[this.constantSvc.TASKTYPE_CONFIG_BASIC].resourceData.push({
                            name: this.constantSvc.DEVKEY_APPSETTING_CONSOLE_DISABLE_USBSMILUPDATE,
                            value: securityConfig.disableUSBUpdate
                        });
                        policyContent.Task[this.constantSvc.TASKTYPE_CONFIG_BASIC].resourceData.push({
                            name: this.constantSvc.DEVKEY_APPSETTING_CONSOLE_DISABLE_NETACCESS,
                            value: securityConfig.disableRESTApi
                        });
                        if (securityConfig.enableDevicePassword && securityConfig.enableOTP) {
                            policyContent.Task[this.constantSvc.TASKTYPE_CONFIG_BASIC].resourceData.push({
                                name: this.constantSvc.DEVKEY_SECURITY_USER_ADMIN,
                                value: {
                                    auth: {
                                        type: 'totp'
                                    }
                                }
                            });
                        }
                        if (securityConfig.enableDevicePassword && securityConfig.devicePassword?.enabled) {
                            policyContent.Task[this.constantSvc.TASKTYPE_SECURITY_LOCALPWD] = {
                                resourceData: {
                                    [this.constantSvc.DEVKEY_FAKE_DEVICE_PASSWORD]: securityConfig.devicePassword.new
                                }
                            };
                        }
                    }
                }
                break;
            case PolicyType.Configuration:
                {
                    const groupConfig: PolicyDataGroupConfig = policy.data as PolicyDataGroupConfig;
                    const applyList: PolicyDataGroupConfigItem[] = Object.keys(groupConfig.configMap).filter(key => groupConfig.configMap[key].checked).map(key => groupConfig.configMap[key]);
                    if (applyList.length > 0) {
                        applyList.forEach((p: PolicyDataGroupConfigItem) => {
                            switch (p.key) {
                                case ConfigurableItemKey.AppStart:
                                    {
                                        policyContent.Task = policyContent.Task || {};
                                        policyContent.Task[this.constantSvc.TASKTYPE_CONFIG_BASIC] = policyContent.Task[this.constantSvc.TASKTYPE_CONFIG_BASIC] || { resourceData: [] };

                                        const appStartContent: { data?: any, extras?: any, overlay?: any, screensaver?: any } = {};

                                        appStartContent.data = p.settingMap[this.constantSvc.DEVKEY_APPSTART_CONTENTURL].value;
                                        appStartContent.extras = {};
                                        this.constantSvc.getAppstartExtraList().forEach(extra => {
                                            appStartContent.extras[extra.property] = p.settingMap[extra.property].value;
                                        });
                                        if (p.settingMap[this.constantSvc.DEVKEY_APPSTART_OVERLAY].value) {
                                            const overlay: AppStartOverlayInfo = (p.settingMap[this.constantSvc.DEVKEY_APPSTART_OVERLAY].value as AppStartOverlayInfo);
                                            if (overlay.keep) {
                                                appStartContent['::overlay'] = true;
                                            }
                                            else if (overlay.enabled && overlay.data) {
                                                appStartContent.overlay = overlay.toSettingData();
                                            }
                                        }
                                        policyContent.Task[this.constantSvc.TASKTYPE_CONFIG_BASIC].resourceData.push({
                                            name: this.constantSvc.DEVKEY_APPSTART,
                                            value: appStartContent
                                        });
                                    }
                                    break;
                                case ConfigurableItemKey.DisplayOrientation:
                                case ConfigurableItemKey.Volume:
                                case ConfigurableItemKey.Timezone:
                                case ConfigurableItemKey.Timeserver:
                                case ConfigurableItemKey.DailyReboot:
                                case ConfigurableItemKey.Powersave:
                                    {
                                        policyContent.Task = policyContent.Task || {};
                                        policyContent.Task[this.constantSvc.TASKTYPE_CONFIG_BASIC] = policyContent.Task[this.constantSvc.TASKTYPE_CONFIG_BASIC] || { resourceData: [] };

                                        Object.keys(p.settingMap).forEach(key => {
                                            policyContent.Task[this.constantSvc.TASKTYPE_CONFIG_BASIC].resourceData.push({
                                                name: key,
                                                value: p.settingMap[key].value
                                            });
                                        });
                                    }
                                    break;
                                case ConfigurableItemKey.Share:
                                    {
                                        policyContent.Sharing = { sharingList: [] };

                                        Object.keys(p.settingMap['share'].value).forEach((accountID: string) => {
                                            const s: {
                                                accountID: string,
                                                accountName: string,
                                                permission: string[],
                                                action?: string
                                            } = p.settingMap['share'].value[accountID];
                                            if (s.action !== '-') {
                                                policyContent.Sharing.sharingList.push({
                                                    accountName: s.accountName,
                                                    permission: s.permission
                                                });
                                            }
                                        });
                                    }
                                    break;
                                case ConfigurableItemKey.ScreenSaver:
                                    {
                                        policyContent.Calendar = policyContent.Calendar || { calendarList: [] };
                                        const s: ScreenSaverInfo = p.settingMap[this.constantSvc.DEVKEY_FAKE_LOCKSCREEN_SCREENSAVER].value as ScreenSaverInfo;
                                        if (s) {
                                            const rawData = s.transformToRawdata();
                                            if (rawData) {
                                                policyContent.Calendar.calendarList.push(rawData);
                                            }
                                        }
                                    }
                                    break;
                                case ConfigurableItemKey.ScreenOff:
                                    {
                                        policyContent.Calendar = policyContent.Calendar || { calendarList: [] };
                                        const s: ScreenOffInfo = p.settingMap[this.constantSvc.DEVKEY_FAKE_LOCKSCREEN_SCREENOFF].value as ScreenOffInfo;
                                        if (s) {
                                            const rawData = s.transformToRawdata();
                                            if (rawData) {
                                                policyContent.Calendar.calendarList.push(rawData);
                                            }
                                        }
                                    }
                                    break;
                                case ConfigurableItemKey.MaintenancePlayback:
                                    {
                                        policyContent.Calendar = policyContent.Calendar || { calendarList: [] };
                                        const s: MaintenancePlaybackInfo = p.settingMap[this.constantSvc.DEVKEY_FAKE_MAINTENANCE].value as MaintenancePlaybackInfo;
                                        if (s) {
                                            const rawData = s.transformToRawdata();
                                            if (rawData) {
                                                policyContent.Calendar.calendarList.push(rawData);
                                            }
                                        }
                                    }
                                    break;
                            }
                        });
                    }
                }
                break;
            case PolicyType.Application:
                {
                    const data: PolicyDataAppManagement = policy.data as PolicyDataAppManagement;
                    if (data && data.appInfoList) {
                        policyContent.Task = policyContent.Task || {};
                        policyContent.Application = policyContent.Application || { applicationList: [] };

                        data.appInfoList.forEach(app => {
                            if (app.bindToAppstart) {
                                policyContent.Task[this.constantSvc.TASKTYPE_CONFIG_BASIC] = policyContent.Task[this.constantSvc.TASKTYPE_CONFIG_BASIC] || { resourceData: [] };
                                policyContent.Task[this.constantSvc.TASKTYPE_CONFIG_BASIC].resourceData.push({
                                    name: this.constantSvc.DEVKEY_APPSTART,
                                    value: {
                                        packageName: app.packageName,
                                        className: app.className,
                                        action: app.action
                                    }
                                });
                            }

                            policyContent.Application.applicationList.push(app.transformToRaw());
                        });
                    }
                }
                break;
            case PolicyType.FirmwareUpdate:
                {
                    const data: PolicyDataFirmwareUpdate = policy.data as PolicyDataFirmwareUpdate;
                    policyContent.Task = {};
                    policyContent.Task[this.constantSvc.TASKTYPE_CONFIG_BASIC] = policyContent.Task[this.constantSvc.TASKTYPE_CONFIG_BASIC] || { resourceData: [] };
                    policyContent.Task[this.constantSvc.TASKTYPE_CONFIG_BASIC].resourceData.push({
                        name: this.constantSvc.DEVKEY_UPDATE_POLICY,
                        value: data.transformPolicyToRaw()
                    });
                }
                break;
            case PolicyType.Certificate:
                {
                    const data: PolicyDataCertificate = policy.data as PolicyDataCertificate;
                    policyContent.Task = {};
                    policyContent.Task[this.constantSvc.TASKTYPE_CONFIG_BASIC] = policyContent.Task[this.constantSvc.TASKTYPE_CONFIG_BASIC] || { resourceData: [] };
                    policyContent.Task[this.constantSvc.TASKTYPE_CONFIG_BASIC].resourceData.push({
                        name: this.constantSvc.DEVKEY_UPDATE_CERTIFICATE,
                        value: data.transformPolicyToRaw()
                    });
                }
                break;
        }

        return policyContent;
    }

    private transformFromRawContent(d: { [accountName: string]: { [policyID: string]: PolicyRawInfo } }, assignMap: { [policyID: string]: { id: string, type: DeviceGroupType, name: string }[] }): void {
        if (d[this._ctrlAccountName]) {
            Object.keys(d[this._ctrlAccountName]).forEach((policyID: string) => {
                let p: PolicyInfo = null;
                const policyRawInfo: PolicyRawInfo = d[this._ctrlAccountName][policyID];

                switch (policyRawInfo.policyType) {
                    case PolicyType.Security:
                        {
                            p = new PolicyInfoSecurity(policyID, policyRawInfo.isUsed, policyRawInfo.policyName);
                            const securityData: PolicyDataSecurity = p.data as PolicyDataSecurity;
                            // System.Config.BasicSetting.Set
                            const basicResourceData: { name: string, value: any }[] = policyRawInfo.policyContent?.Task?.[this.constantSvc.TASKTYPE_CONFIG_BASIC]?.resourceData || [];
                            basicResourceData.forEach(keyPair => {
                                switch (keyPair.name) {
                                    case this.constantSvc.DEVKEY_APPSETTING_CONSOLE_DISABLE_USBSMILUPDATE:
                                        {
                                            securityData.disableUSBUpdate = keyPair.value;
                                        }
                                        break;
                                    case this.constantSvc.DEVKEY_APPSETTING_CONSOLE_DISABLE_NETACCESS:
                                        {
                                            securityData.disableRESTApi = keyPair.value;
                                        }
                                        break;
                                    case this.constantSvc.DEVKEY_SECURITY_USER_ADMIN:
                                        {
                                            securityData.enableOTP = true;
                                        }
                                        break;
                                }
                            });
                            // System.Local.Password.Set
                            this.resetDevicePasswordInSecurityPolicy(securityData, policyRawInfo);
                            if (securityData.enableOTP || securityData.devicePassword.enabled) {
                                securityData.enableDevicePassword = true;
                            }
                        }
                        break;
                    case PolicyType.Configuration:
                        {
                            p = new PolicyInfoGroupConfig(policyID, policyRawInfo.isUsed, policyRawInfo.policyName);

                            //policy share setting
                            const shareList: { accountName: string, permission: string[] }[] = policyRawInfo.policyContent && policyRawInfo.policyContent.Sharing && policyRawInfo.policyContent.Sharing.sharingList ? policyRawInfo.policyContent.Sharing.sharingList : [];
                            p.data.configMap[ConfigurableItemKey.Share] = {
                                key: ConfigurableItemKey.Share,
                                name: 'Share device',
                                checked: shareList.length > 0 ? true : false,
                                valid: true,
                                settingMap: {}
                            };
                            p.data.configMap[ConfigurableItemKey.Share].settingMap['share'] = {
                                langKey: 'Share to',
                                value: shareList.reduce((r, e) => {
                                    r[e.accountName] = {
                                        accountID: e.accountName,
                                        accountName: e.accountName,
                                        permission: e.permission.map(p => p),
                                        action: ''
                                    };

                                    return r;
                                }, {})
                            };

                            //policy calendar setting
                            const calendarList: IVirtualDeviceCalendarItem[] = policyRawInfo.policyContent && policyRawInfo.policyContent.Calendar && policyRawInfo.policyContent.Calendar.calendarList ? policyRawInfo.policyContent.Calendar.calendarList : [];
                            if (calendarList && calendarList.length > 0) {
                                for (let c of calendarList) {
                                    switch (c.type) {
                                        case LockScreenMode.screenOff:
                                            {
                                                if (this.Enable_screenOff) {
                                                    p.data.configMap[ConfigurableItemKey.ScreenOff] = new PolicyDataGroupConfigItem(ConfigurableItemKey.ScreenOff, 'Lock screen - screen off', {
                                                        isDefaultValid: true,
                                                        checked: true,
                                                        defaultSettingMap: {
                                                            [this.constantSvc.DEVKEY_FAKE_LOCKSCREEN_SCREENOFF]: {
                                                                langKey: 'Screen off',
                                                                value: new ScreenOffInfo(c.metaData && c.metaData.rawData ? c.metaData.rawData : null)
                                                            }
                                                        }
                                                    });
                                                }
                                            }
                                            break;
                                        case LockScreenMode.screenSaver:
                                            {
                                                if (this.Enable_screensaver) {
                                                    p.data.configMap[ConfigurableItemKey.ScreenSaver] = new PolicyDataGroupConfigItem(ConfigurableItemKey.ScreenSaver, 'Lock screen - Screen saver', {
                                                        isDefaultValid: true,
                                                        checked: true,
                                                        defaultSettingMap: {
                                                            [this.constantSvc.DEVKEY_FAKE_LOCKSCREEN_SCREENSAVER]: {
                                                                langKey: 'Screen saver',
                                                                value: new ScreenSaverInfo(c.metaData && c.metaData.rawData ? c.metaData.rawData : null)
                                                            }
                                                        }
                                                    });
                                                }
                                            }
                                            break;
                                        case LockScreenMode.maintenance:
                                            {
                                                if (this.Enable_maintenance) {
                                                    p.data.configMap[ConfigurableItemKey.MaintenancePlayback] = new PolicyDataGroupConfigItem(ConfigurableItemKey.MaintenancePlayback, 'Maintenance playback', {
                                                        isDefaultValid: true,
                                                        checked: true,
                                                        defaultSettingMap: {
                                                            [this.constantSvc.DEVKEY_FAKE_MAINTENANCE]: {
                                                                langKey: 'Maintenance playback',
                                                                value: new MaintenancePlaybackInfo(c.metaData && c.metaData.rawData ? c.metaData.rawData : null)
                                                            }
                                                        }
                                                    });
                                                }
                                            }
                                            break;
                                    }
                                }
                            }

                            //policy task setting
                            const resourceData: { name: string, value: any }[] = policyRawInfo.policyContent && policyRawInfo.policyContent.Task && policyRawInfo.policyContent.Task[this.constantSvc.TASKTYPE_CONFIG_BASIC].resourceData ? policyRawInfo.policyContent.Task[this.constantSvc.TASKTYPE_CONFIG_BASIC].resourceData : [];
                            resourceData.forEach(keyPair => {
                                switch (keyPair.name) {
                                    // Appstart
                                    case this.constantSvc.DEVKEY_APPSTART:
                                        {
                                            if (keyPair.value) {
                                                if (keyPair.value.data !== undefined || keyPair.value.extras) {
                                                    p.data.configMap[ConfigurableItemKey.AppStart] = new PolicyDataGroupConfigItem(ConfigurableItemKey.AppStart, 'Appstart', {
                                                        valid: true,
                                                        checked: true
                                                    });
                                                    // parse appstart
                                                    p.data.configMap[ConfigurableItemKey.AppStart].settingMap[this.constantSvc.DEVKEY_APPSTART_CONTENTURL] = { langKey: 'Content URL', value: keyPair.value.data || '' };
                                                    if (keyPair.value.extras) {
                                                        this.constantSvc.getAppstartExtraList().forEach(extra => {
                                                            p.data.configMap[ConfigurableItemKey.AppStart].settingMap[extra.property] = { langKey: extra.displayName, value: keyPair.value.extras[extra.property] };
                                                        });
                                                    }

                                                    p.data.configMap[ConfigurableItemKey.AppStart].settingMap[this.constantSvc.DEVKEY_APPSTART_OVERLAY] = { langKey: 'Overlay', value: new AppStartOverlayInfo(keyPair.value.overlay, keyPair.value['::overlay'] ? true : false) };
                                                }
                                            }
                                        }
                                        break;
                                    // Volume
                                    case this.constantSvc.DEVKEY_HD_AUDIO_MASTER_SOUND_LEVEL:
                                        {
                                            p.data.configMap[ConfigurableItemKey.Volume] = new PolicyDataGroupConfigItem(ConfigurableItemKey.Volume, 'Volume', {
                                                valid: true,
                                                checked: true,
                                                defaultSettingMap: {
                                                    [keyPair.name]: { langKey: 'Volume', value: keyPair.value }
                                                }
                                            });
                                        }
                                        break;
                                    // Display orientation
                                    case this.constantSvc.DEVKEY_HD_VIDEO_ROTATION:
                                        {
                                            p.data.configMap[ConfigurableItemKey.DisplayOrientation] = new PolicyDataGroupConfigItem(ConfigurableItemKey.DisplayOrientation, 'Display orientation', {
                                                valid: true,
                                                checked: true,
                                                availableSettings: this.constantSvc.DISPLAY_ORIENTATION_LIST,
                                                defaultSettingMap: {
                                                    [keyPair.name]: { langKey: 'Display orientation', value: keyPair.value }
                                                }
                                            });
                                        }
                                        break;
                                    // Timezone
                                    case this.constantSvc.DEVKEY_TIME_TIMEZONE:
                                        {
                                            p.data.configMap[ConfigurableItemKey.Timezone] = new PolicyDataGroupConfigItem(ConfigurableItemKey.Timezone, 'Time zone', {
                                                isDefaultValid: false,
                                                valid: true,
                                                checked: true,
                                                availableSettings: HelperLib.getDefaultTimezoneList(),
                                                defaultSettingMap: {
                                                    [keyPair.name]: { langKey: 'Time zone', value: keyPair.value }
                                                }
                                            });
                                        }
                                        break;
                                    // Time server
                                    case this.constantSvc.DEVKEY_TIME_TIMESERVER_ENABLED:
                                    case this.constantSvc.DEVKEY_TIME_TIMESERVER_SOURCE:
                                        {
                                            p.data.configMap[ConfigurableItemKey.Timeserver] = p.data.configMap[ConfigurableItemKey.Timeserver] || new PolicyDataGroupConfigItem(ConfigurableItemKey.Timeserver, 'Time server', {
                                                valid: true,
                                                checked: true
                                            });
                                            p.data.configMap[ConfigurableItemKey.Timeserver].settingMap[keyPair.name] = { langKey: 'Time server', value: keyPair.value };
                                        }
                                        break;
                                    // Daily reboot
                                    case this.constantSvc.DEVKEY_SCHEDULE_REBOOT_ENABLED:
                                    case this.constantSvc.DEVKEY_SCHEDULE_REBOOT_TIME:
                                    case this.constantSvc.DEVKEY_SCHEDULE_REBOOT:
                                        {
                                            p.data.configMap[ConfigurableItemKey.DailyReboot] = p.data.configMap[ConfigurableItemKey.DailyReboot] || new PolicyDataGroupConfigItem(ConfigurableItemKey.DailyReboot, 'Daily reboot', {
                                                valid: true,
                                                checked: true
                                            });
                                            p.data.configMap[ConfigurableItemKey.DailyReboot].settingMap[keyPair.name] = { langKey: 'Daily reboot', value: keyPair.value };
                                            if (keyPair.name !== this.constantSvc.DEVKEY_SCHEDULE_REBOOT) {
                                                p.data.configMap[ConfigurableItemKey.DailyReboot].settingMap[this.constantSvc.DEVKEY_SCHEDULE_REBOOT] = p.data.configMap[ConfigurableItemKey.DailyReboot].settingMap[this.constantSvc.DEVKEY_SCHEDULE_REBOOT] || { langKey: 'Daily reboot', value: { days: WEEKDAY_ABBR_LIST, time: '04:00' } };
                                            }
                                        }
                                        break;
                                    // Power save
                                    case this.constantSvc.DEVKEY_APPSETTING_CONSOLE_DISABLE_POWERSAVE:
                                    case this.constantSvc.DEVKEY_APPSETTING_CONSOLE_POWERSAVE_TIMEOUT:
                                    case this.constantSvc.DEVKEY_APPSETTING_CONSOLE_POWERSAVE_ACTION:
                                        {
                                            if (this.Enable_powersave) {
                                                p.data.configMap[ConfigurableItemKey.Powersave] = p.data.configMap[ConfigurableItemKey.Powersave] || new PolicyDataGroupConfigItem(ConfigurableItemKey.Powersave, 'Power save', {
                                                    valid: true,
                                                    checked: true
                                                });
                                                p.data.configMap[ConfigurableItemKey.Powersave].settingMap[keyPair.name] = { langKey: 'Power save', value: keyPair.value };
                                            }
                                        }
                                        break;
                                }
                            });
                        }
                        break;
                    case PolicyType.Application:
                        {
                            p = new PolicyInfoAppManagement(policyID, policyRawInfo.isUsed, policyRawInfo.policyName);
                            const appMgrData: PolicyDataAppManagement = p.data as PolicyDataAppManagement;
                            if (policyRawInfo.policyContent) {
                                if (policyRawInfo.policyContent.Application) {
                                    policyRawInfo.policyContent.Application.applicationList.forEach(app => {
                                        appMgrData.appInfoList.push(new AppManagementInfo(app));
                                    });
                                }
                                if (policyRawInfo.policyContent.Task && policyRawInfo.policyContent.Task[this.constantSvc.TASKTYPE_CONFIG_BASIC] && policyRawInfo.policyContent.Task[this.constantSvc.TASKTYPE_CONFIG_BASIC].resourceData) {
                                    const executeAppStartPair: { name: string, value: any } = policyRawInfo.policyContent.Task[this.constantSvc.TASKTYPE_CONFIG_BASIC].resourceData.find(pair => pair.name === this.constantSvc.DEVKEY_APPSTART);
                                    if (executeAppStartPair && executeAppStartPair.value && executeAppStartPair.value.packageName) {
                                        const app: AppManagementInfo = appMgrData.appInfoList.find(app => app.packageName === executeAppStartPair.value.packageName);
                                        if (app) {
                                            app.bindToAppstart = true;
                                        }
                                    }
                                }
                            }
                        }
                        break;
                    case PolicyType.FirmwareUpdate:
                        {
                            p = new PolicyInfoFirmwareUpdate(policyID, policyRawInfo.isUsed, policyRawInfo.policyName);
                            const data: PolicyDataFirmwareUpdate = p.data as PolicyDataFirmwareUpdate;
                            if (policyRawInfo.policyContent) {
                                if (policyRawInfo.policyContent.Task && policyRawInfo.policyContent.Task[this.constantSvc.TASKTYPE_CONFIG_BASIC] && policyRawInfo.policyContent.Task[this.constantSvc.TASKTYPE_CONFIG_BASIC].resourceData) {
                                    const firmwareDataPair: { name: string, value: any } = policyRawInfo.policyContent.Task[this.constantSvc.TASKTYPE_CONFIG_BASIC].resourceData.find(pair => pair.name === this.constantSvc.DEVKEY_UPDATE_POLICY);
                                    if (firmwareDataPair && firmwareDataPair.value) {
                                        data.transformPolicyFromRaw(firmwareDataPair.value);
                                    }
                                }
                            }
                        }
                        break;
                    case PolicyType.Certificate:
                        {
                            p = new PolicyInfoCertificate(policyID, policyRawInfo.isUsed, policyRawInfo.policyName);
                            const data: PolicyDataCertificate = p.data as PolicyDataCertificate;
                            if (policyRawInfo.policyContent) {
                                if (policyRawInfo.policyContent.Task && policyRawInfo.policyContent.Task[this.constantSvc.TASKTYPE_CONFIG_BASIC] && policyRawInfo.policyContent.Task[this.constantSvc.TASKTYPE_CONFIG_BASIC].resourceData) {
                                    const dataPair: { name: string, value: any } = policyRawInfo.policyContent.Task[this.constantSvc.TASKTYPE_CONFIG_BASIC].resourceData.find(pair => pair.name === this.constantSvc.DEVKEY_UPDATE_CERTIFICATE);
                                    if (dataPair && dataPair.value) {
                                        data.installedCertificates = dataPair.value.map(p => new CertInfo(p));
                                    }
                                }
                            }
                        }
                        break;
                }

                if (assignMap && assignMap[p.id]) {
                    p.groupList = assignMap[p.id];
                }

                this._policyList.push(p);
            });
        }
    }

    private resetDevicePasswordInSecurityPolicy(securityData?: PolicyDataSecurity, policyRawInfo?: PolicyRawInfo): void {
        if (!securityData || !policyRawInfo) {
            return;
        }

        // If device password is set before, should get "encrypted_password: '*'" from server response (server will encrypt the password, so show the encrypted password to user is meaningless)
        // Also leave the confirm password empty, if user wants to modify the policy, he should retype the password again.
        const pwdResourceData: { [name: string]: any } = policyRawInfo.policyContent?.Task?.[this.constantSvc.TASKTYPE_SECURITY_LOCALPWD]?.resourceData || {};
        if (pwdResourceData[this.constantSvc.DEVKEY_FAKE_DEVICE_PASSWORD]) {
            securityData.devicePassword = {
                enabled: true,
                new: pwdResourceData[this.constantSvc.DEVKEY_FAKE_DEVICE_PASSWORD],
                confirm: '',
                isValid: true
            };
        }
    }
}