import { Component, Input } from "@angular/core";
import { AppStartOverlayInfo } from "../../content/device/data/device-info";

@Component({
    selector: 'na-overlay-qrcode-preview',
    templateUrl: './overlay-qrcode-preview.component.html'
})
export class OverlayQRCodePreviewComponent {
    _data: AppStartOverlayInfo;
    @Input('data')
    set data(v: AppStartOverlayInfo) {
        this._data = !v || v.enabled === undefined || v.landscape === undefined || v.portrait === undefined ? new AppStartOverlayInfo(v) : v;
    }
}