import { Component, OnInit, Input, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import { IAlertFuncCtrl } from '../alert-func.def';
import { AlertInfo } from '../alert.data';
import { AlertService } from '../../alert.service';
import { DialogPage } from '../../../../../lib/common/common.data';
import { AccountService } from '../../../../../entry/account.service';

@Component({
    templateUrl: './alert-config-remove.component.html',
    styleUrls: ['../alert.style.css']
})
export class AlertConfigRemoveComponent implements OnInit, OnDestroy, IAlertFuncCtrl {
    title: string;

    private _alert: AlertInfo;
    @Input()
    set alert(a: AlertInfo) {
        if (!a) {
            return;
        }

        this._alert = a.id ? a.copy() : a;
    }

    _page: DialogPage = DialogPage.action;
    _enumPage: typeof DialogPage = DialogPage;
    _errorMessage: string;

    private _eventSubscription: Subscription;
    private dlgCloseElementRef: ElementRef;
    @ViewChild('dlgClose', { static: true })
    set dlgClose(holder: ElementRef) {
        if (holder) {
            this.dlgCloseElementRef = holder;
        }
    }

    constructor(private accountSvc: AccountService, private alertSvc: AlertService) {}

    ngOnInit(): void {
        this._eventSubscription = this.accountSvc.loginChanged.subscribe((isLogin: boolean) => {
            if (!isLogin) {
                this.dlgCloseElementRef.nativeElement.click();
            }
        })
    }

    ngOnDestroy(): void {
        if (this._eventSubscription) {
            this._eventSubscription.unsubscribe();
        }
    }

    private remove(): void {
        this._page = DialogPage.submit;
        this.alertSvc.deleteAlert(this._alert.id).subscribe((errorMessage: string) => {
            this._errorMessage = errorMessage;
            this._page = DialogPage.result;
        });
    }
}