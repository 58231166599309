<div class="row">
  <div class="col-12 col-md-4 col-xl-3">
    <div class="d-flex align-items-center">
      <strong>Policy list</strong>
      <button *ngIf="supportPolicyUpdate()" id="id_policy_do_create" type="button" class="btn btn-sm btn-action btn-nb ml-auto"
        data-toggle="modal" data-target="#policyFuncModalHost" [disabled]="_loading" (click)="createPolicy()">
        <i class="fas fa-plus-circle mr-1"></i>
        <span class="">Add</span>
      </button>
      <button *ngIf="supportPolicyUpdate()" id="id_policy_do_refreshAll" type="button"
        class="btn btn-sm btn-action btn-nb" [disabled]="_loading" (click)="refreshPolicyList(true)">
        <i class="fas fa-redo-alt mr-1"></i>
        <span class="">Refresh</span>
      </button>
    </div>
    <div class="na-group-view">
      <div *ngIf="!_loading; else templateLoadingPolicy">
        <div *ngIf="_policyCount > 0; else templateNoPolicy">
          <div *ngFor="let policyCategory of _policyCategoryMap | keyvalue" class="title">
            <i *ngIf="policyCategory.value.expand; else templatePolicyCategoryUnfold" class="fas fa-caret-down"
              (click)="expandCategory(policyCategory.value, false)"></i>
            <ng-template #templatePolicyCategoryUnfold>
              <i class="fas fa-caret-right" (click)="expandCategory(policyCategory.value, true)"></i>
            </ng-template>
            <strong class="ml-2">
                {{ policyCategory.value.displayName }}
            </strong>
            <div *ngIf="policyCategory.value.expand" class="ml-4">
              <div *ngFor="let policy of policyCategory.value.policyList" class="group-item"
                [class.selected]="policy.id === _policy?.id" (click)="selectPolicy(policy)">
                <i class="fas fa-caret-right"></i>
                <span class="ml-2">{{ policy.name }}</span>
              </div>
            </div>
          </div>
        </div>
        <ng-template #templateNoPolicy>
          <div class="my-4 text-center">
            No policy
          </div>
        </ng-template>
      </div>
      <ng-template #templateLoadingPolicy>
        <div class="loading">
          <p>Please wait...</p>
          <div class="loading-box"></div>
        </div>
      </ng-template>
    </div>
  </div>
  <div class="col-12 col-md-8 col-xl-9 pl-md-0 mt-1 mt-md-0">
    <div class="block-titlebar">
      <strong>Policy settings</strong>
      <button *ngIf="supportPolicyUpdate()" type="button"
        class="btn btn-sm btn-action btn-nb ml-auto" data-toggle="modal"
        data-target="#policyFuncModalHost" [disabled]="!_policy" (click)="editPolicy()">
        <i class="fas fa-pen mr-1"></i>
        Edit
      </button>
      <button *ngIf="supportPolicyUpdate()" type="button" class="btn btn-sm btn-action btn-nb ml-2"
        data-toggle="modal" data-target="#policyFuncModalHost" [disabled]="!_policy" (click)="removePolicy()">
        <i class="fas fa-trash-alt mr-1"></i>
        Remove policy
      </button>
    </div>
    <div *ngIf="_policy; else templateNoPolicySelect">
      <na-policy-report [policy]="_policy"></na-policy-report>
    </div>
    <ng-template #templateNoPolicySelect>
      <div class="my-4 text-center">
        Select a policy to inspect the detail setting.
      </div>
    </ng-template>
  </div>
</div>
<div id="policyFuncModalHost" class="modal fade" data-backdrop="static" data-keyboard="false" role="dialog">
  <ng-template policy-func></ng-template>
</div>