import { IReportData, ReportConfig } from "../../../API/v1/Report/api.report.data";

export class WeeklyReportSettingInfo {
    enabled: { origin: boolean, value: boolean };
    deviceStatus: {
        enabled: { origin: boolean, value: boolean };
        deviceAmount: { origin: number, value: number, error?: string };
        uptimeThreshold: { origin: number, value: number, error?: string };
    };
    warrantyExpiration: {
        enabled: { origin: boolean, value: boolean };
        threshold: { origin: number, value: number, error?: string };
    };
    licenseExpiration: {
        enabled: { origin: boolean, value: boolean };
        threshold: { origin: number, value: number, error?: string };
    };

    receivers: { origin: string, value: string, error?: string };

    constructor(o: ReportConfig) {
        this.enabled = { origin: o.isWeeklyReportEnabled, value: o.isWeeklyReportEnabled };
        this.deviceStatus = {
            enabled: { origin: o.deviceStatus.enabled ?? true, value: o.deviceStatus.enabled ?? true },
            deviceAmount: {
                origin: o.deviceStatus.deviceAmountThresholdInPercentage,
                value: o.deviceStatus.deviceAmountThresholdInPercentage,
                error: o.deviceStatus.deviceAmountThresholdInPercentage ? '' : 'Empty value'
            },
            uptimeThreshold: {
                origin: o.deviceStatus.deviceUptimeThresholdInPercentage,
                value: o.deviceStatus.deviceUptimeThresholdInPercentage,
                error: o.deviceStatus.deviceAmountThresholdInPercentage ? '' : 'Empty value'
            }
        };

        this.warrantyExpiration = {
            enabled: { origin: o.warrantyStatus.enabled ?? true, value: o.warrantyStatus.enabled ?? true },
            threshold: {
                origin: o.warrantyStatus.warrantyExpiryThresholdInDays,
                value: o.warrantyStatus.warrantyExpiryThresholdInDays,
                error: o.deviceStatus.deviceAmountThresholdInPercentage ? '' : 'Empty value'
            }
        };

        this.licenseExpiration = {
            enabled: { origin: o.licenseKeyStatus.enabled ?? true, value: o.licenseKeyStatus.enabled ?? true },
            threshold: {
                origin: o.licenseKeyStatus.licenseKeyExpiryThresholdInDays,
                value: o.licenseKeyStatus.licenseKeyExpiryThresholdInDays,
                error: o.deviceStatus.deviceAmountThresholdInPercentage ? '' : 'Empty value'
            }
        };

        const receiver: string = o.reportReceiverList.join(', ');
        this.receivers = { origin: receiver, value: receiver };
    }

    isChange(): boolean {
        if (this.enabled.origin !== this.enabled.value) {
            return true;
        }
        if (this.deviceStatus.enabled.origin !== this.deviceStatus.enabled.value ||
            this.deviceStatus.deviceAmount.origin !== this.deviceStatus.deviceAmount.value ||
            this.deviceStatus.uptimeThreshold.origin !== this.deviceStatus.uptimeThreshold.value) {
            return true;
        }
        if (this.warrantyExpiration.enabled.origin !== this.warrantyExpiration.enabled.value ||
            this.warrantyExpiration.threshold.origin !== this.warrantyExpiration.threshold.value) {
            return true;
        }
        if (this.licenseExpiration.enabled.origin !== this.licenseExpiration.enabled.value ||
            this.licenseExpiration.threshold.origin !== this.licenseExpiration.threshold.value) {
            return true;
        }
        if (this.receivers.origin !== this.receivers.value) {
            return true;
        }

        return false;
    }

    enableWeeklyReport(checked: boolean): void {
        if (this.enabled.value !== checked) {
            this.enabled.value = checked;
        }
    }

    enableNetworkEvaluation(checked: boolean): void {
        if (this.deviceStatus.enabled.value !== checked) {
            this.deviceStatus.enabled.value = checked;
            this.updateEnableSettings();
        }
    }
    changeDeviceStatusAmountRatio(v: number): void {
        if (this.deviceStatus.deviceAmount.value !== v) {
            this.deviceStatus.deviceAmount.value = v;
            this.deviceStatus.deviceAmount.error = this.deviceStatus.deviceAmount.value < 5 || this.deviceStatus.deviceAmount.value > 100 ? 'Only value between 5 ~ 100 is acceptable' : null;
        }
    }
    changeDeviceStatusUptimeRatio(v: number): void {
        if (this.deviceStatus.uptimeThreshold.value !== v) {
            this.deviceStatus.uptimeThreshold.value = v;
            this.deviceStatus.uptimeThreshold.error = this.deviceStatus.uptimeThreshold.value < 5 || this.deviceStatus.uptimeThreshold.value > 100 ? 'Only value between 5 ~ 100 is acceptable' : null;
        }
    }

    enableWarrantyExpiration(checked: boolean): void {
        if (this.warrantyExpiration.enabled.value !== checked) {
            this.warrantyExpiration.enabled.value = checked;
            this.updateEnableSettings();
        }
    }
    changeWarrantyThreshold(threshold: number): void {
        if (this.warrantyExpiration.threshold.value !== threshold) {
            this.warrantyExpiration.threshold.value = threshold;
            this.warrantyExpiration.threshold.error = this.warrantyExpiration.threshold.value < 1 ? 'value should larger than 1 day' : null;
        }
    }

    enableLicenseExipiration(checked: boolean): void {
        if (this.licenseExpiration.enabled.value !== checked) {
            this.licenseExpiration.enabled.value = checked;
            this.updateEnableSettings();
        }
    }
    changeLicenseThreshold(threshold: number): void {
        if (this.licenseExpiration.threshold.value !== threshold) {
            this.licenseExpiration.threshold.value = threshold;
            this.licenseExpiration.threshold.error = this.licenseExpiration.threshold.value < 1 ? 'value should larger than 1 day' : null;
        }
    }

    changeReceivers(receiver: string): void {
        if (this.receivers.value !== receiver) {
            this.receivers.value = receiver;
        }
    }

    private updateEnableSettings(): void {
        if (!this.deviceStatus.enabled.value && !this.warrantyExpiration.enabled.value && !this.licenseExpiration.enabled.value) {
            this.enableWeeklyReport(false);
        }
    }

    isValid(): boolean {
        if (!this.enabled.value) {
            return true;
        }

        if (this.deviceStatus.enabled.value && this.deviceStatus.deviceAmount.error) {
            return false;
        }
        if (this.deviceStatus.enabled.value && this.deviceStatus.uptimeThreshold.error) {
            return false;
        }

        if (this.warrantyExpiration.enabled.value && this.warrantyExpiration.threshold.error) {
            return false;
        }

        if (this.licenseExpiration.enabled.value && this.licenseExpiration.threshold.error) {
            return false;
        }

        return true;
    }

    reset(): void {
        this.enabled.value = this.enabled.origin;

        this.deviceStatus.enabled.value = this.deviceStatus.enabled.origin;
        this.deviceStatus.deviceAmount.value = this.deviceStatus.deviceAmount.origin;
        this.deviceStatus.deviceAmount.error = null;
        this.deviceStatus.uptimeThreshold.value = this.deviceStatus.uptimeThreshold.origin;
        this.deviceStatus.uptimeThreshold.error = null;

        this.warrantyExpiration.enabled.value = this.warrantyExpiration.enabled.origin;
        this.warrantyExpiration.threshold.value = this.warrantyExpiration.threshold.origin;
        this.warrantyExpiration.threshold.error = null;

        this.licenseExpiration.enabled.value = this.licenseExpiration.enabled.origin;
        this.licenseExpiration.threshold.value = this.licenseExpiration.threshold.origin;
        this.licenseExpiration.threshold.error = null;

        this.receivers.value = this.receivers.origin;
        this.receivers.error = null;
    }

    toConfig(): ReportConfig {
        const s: ReportConfig = new ReportConfig();
        s.isWeeklyReportEnabled = this.enabled.value;
        
        s.deviceStatus.enabled = this.deviceStatus.enabled.value;
        s.deviceStatus.deviceAmountThresholdInPercentage = this.deviceStatus.deviceAmount.value;
        s.deviceStatus.deviceUptimeThresholdInPercentage = this.deviceStatus.uptimeThreshold.value;

        s.warrantyStatus.enabled = this.warrantyExpiration.enabled.value;
        s.warrantyStatus.warrantyExpiryThresholdInDays = this.warrantyExpiration.threshold.value;

        s.licenseKeyStatus.enabled = this.licenseExpiration.enabled.value;
        s.licenseKeyStatus.licenseKeyExpiryThresholdInDays = this.licenseExpiration.threshold.value;

        s.reportReceiverList = this.receivers.value ? this.receivers.value.split(/[\s,\n]+/) : [];

        return s;
    }
}

export class ReportInfo {
    reportID: string;
    reportDateTime: string;
    subject: string;
    summary: {
        networkEvaluation: string,
        licenseEvaluation: string,
        warrantyEvaluation: string,
        errorCount: number,
        warningCount: number
    };
    body: string;

    constructor(o?: IReportData) {
        if (o) {
            this.reportID = o.weeklyReportID;
            this.subject = o.subject;
            this.reportDateTime = o.reportDate;
            this.body = o.body.replace(/<br><br>/g, '');
            this.summary = o.reportData.summary;
        }
    }
}