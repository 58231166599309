import { Component, OnInit } from '@angular/core';

import { DeviceInfo } from '../../../../../device/data/device-info';
import { DeviceService } from '../../../../../device/device.service';
import { AlertType, DeviceMonitorStatusMap, DeviceStatusAlertConfigData } from '../../alert.data';
import { AlertHelper } from '../../alert.helper';
import { IAlertDataCtrl } from '../alert-data-ctrl.interface';

@Component({
    templateUrl: './alert-data-device-status.component.html',
    styleUrls: ['../../alert.style.css']
})
export class AlertDeviceStatusDataComponent implements OnInit, IAlertDataCtrl {
    _receivers: string;
    _monitorList: { id: string, displayName: string, checked: boolean }[] = [];
    _defaultSelectDeviceIDList: string[] = [];
    _updateDeviceList: DeviceInfo[] = [];

    private _data: DeviceStatusAlertConfigData = new DeviceStatusAlertConfigData();
    set alertData(data: DeviceStatusAlertConfigData) {
        this._data = data;
        this._receivers = this._data && this._data.alertReceiverList ? this._data.alertReceiverList.join('\n') : '';
        this._defaultSelectDeviceIDList = this._data.alertApplyDeviceList.map(d => d.virtualDeviceID);
    }

    _isViewMode: boolean = true;
    set isViewMode(isView: boolean) {
        this._isViewMode = isView;
    }

    constructor(private devSvc: DeviceService) { }

    ngOnInit(): void {
        const currMonitorMap: { [monitorID: string]: boolean } = this._data.deviceStatusMonitorIDList.reduce((acc, curr) => {
            acc[curr] = true;
            return acc;
        }, {});

        this._monitorList = Object.keys(DeviceMonitorStatusMap).filter((monitorID: string) => DeviceMonitorStatusMap[monitorID].belongEvent.type === AlertType.DeviceStatus).map((monitorID: string) => {
            return {
                id: monitorID,
                displayName: DeviceMonitorStatusMap[monitorID].displayName,
                checked: currMonitorMap[monitorID] || false
            }
        });
    }

    changeMonitorItem(option: { id: string, displayName: string, checked: boolean }, checked: boolean): void {
        option.checked = checked;
        this._data.changeMonitorOptionList(this._monitorList);
    }

    changeReceivers(receiver: string): void {
        this._data.changeReceiver(receiver);
    }

    transformReceiverList(receiverList: string[]): string {
        return AlertHelper.transformReceiverList(receiverList);
    }

    transformApplyDeviceList(deviceIDList: { virtualDeviceID: string }[]): string {
        return AlertHelper.transformApplyDeviceList(this.devSvc, deviceIDList);
    }

    onDeviceStatusAlertDeviceSelectChange(deviceList: DeviceInfo[]): void {
        this._data.changeApplyDeviceList(deviceList);
    }
}