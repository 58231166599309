import { Component, OnInit, OnDestroy } from '@angular/core';
import { AlertService } from '../alert.service';

@Component({
    templateUrl: './alert-close-overview.component.html',
    styleUrls: []
})
export class CloseAlertOverviewComponent implements OnInit, OnDestroy {
    _notificationList: { id: string, lastDateTime: string, type: string, subject: string, sourceAlertID: string }[] = [];

    _isLoading: boolean = false;

    _currentPage: number = 1;
    _total: number;
    _errorMessage: string;

    constructor(private alertSvc: AlertService) { }

    ngOnInit(): void {
        this.getNotificationList();
    }

    ngOnDestroy(): void {}

    private getNotificationList(): void {
        this._isLoading = true;
        this.alertSvc.getClosedAlertNotificationList(this._currentPage - 1).subscribe((res: { data: { id: string, lastDateTime: string, type: string, subject: string, sourceAlertID: string }[], total?: number, skip?: number, limit?: number, errorMessage?: string }) => {
            if (res.errorMessage) {
                this._errorMessage = res.errorMessage;
            }
            else {
                this._total = res.total;
                this._notificationList = res.data;
            }

            this._isLoading = false;
        });
    }

    onPageChange(page: number): void {
        if (this._currentPage !== page) {
            this._currentPage = page;
            this.getNotificationList();
        }
    }
}