export class Logger {
    static logLevel: number = 3;
    static logInfo(tag: string, method: string, msg: string, data: any = null): void {
        //0x01
        if (Logger.logLevel & 0x01) {
            console.log('[' + tag + '][' + method + '] ' + msg, data);
        }  
    }

    static logError(tag: string, method: string, msg: string, data: any = null): void {
        //0x03
        if (Logger.logLevel & 0x03) {
            console.error('[' + tag + '][' + method + '] ' + msg, data);
        }
    }

    static setLogLevel(logLevel: number): void {
        Logger.logLevel = logLevel;
    }
}