import { Component, ElementRef, ViewChild } from '@angular/core';
import { Subject, fromEvent as observableFromEvent } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { AccountService } from '../../../../app/entry/account.service';
import { DialogPage } from '../../../../app/lib/common/common.data';
import { ConstantService } from '../../../../app/lib/common/constant.service';
import { HelperLib, REFRESH_DURATION } from '../../../../app/lib/common/helper.lib';
import { DeviceInfo } from '../data/device-info';
import { DeviceService } from '../device.service';
import { UserPreferenceService } from '../../../../app/content/user-preference.service';
import { AppConfigService } from '../../../../app/app.config';

@Component({
    templateUrl: './dev-pair-share.component.html',
    styleUrls: ['./dev-pair-share.component.css']
})
export class ManageShareDeviceCOmponent {
    readonly NUMBER_IN_PAGE_OPTIONS: number[] = [30, 50, 100, 300];
    readonly Enable_Pairing: boolean = AppConfigService.configs.devPage.func.pairDevEnabled;

    _numberInPage: number = 10;
    _page: number = 1;
    _displayPlayerAmount: number = 0;

    private _comparedAccountID: string;

    private _allUnsubscribe: Subject<void> = new Subject();
    private _searchInfo: { key: string, value: string };
    _isLoading: boolean = false;

    _removeStatusPage: DialogPage;
    private _errorMessage: string;
    _enumDialogPage: typeof DialogPage = DialogPage;
    _refreshCounter: number = 0;

    private _devices: DeviceInfo[] = [];
    _displayDeviceList: DeviceInfo[] = [];
    private _selectedDevice: DeviceInfo;

    private _searchRef: ElementRef;
    @ViewChild('search', { static: true })
    set search(v: ElementRef) {
        if (v) {
            this._searchRef = v;
            const searchElement = this._searchRef.nativeElement;
            const searchInputOb = observableFromEvent(searchElement, 'input');

            searchInputOb.pipe(
                debounceTime(200),
                takeUntil(this._allUnsubscribe)
            ).subscribe((e: any) => {
                this._searchInfo = { key: this.constantSvc.DEVKEY_INFO_PNAME, value: e.target.value.toLocaleLowerCase() };
                this.refactorDevices();
            });
        }
    }

    constructor(
        private constantSvc: ConstantService,
        private accountSvc: AccountService,
        private devSvc: DeviceService,
        private userPrefSvc: UserPreferenceService
    ) {
        this._comparedAccountID = this.accountSvc.isEnterprise() ? this.accountSvc.enterpriseAccountID : this.accountSvc.accountID;
     }

    ngOnInit(): void {
        this.devSvc.devicesChanged.pipe(
            takeUntil(this._allUnsubscribe)
        ).subscribe((devices: DeviceInfo[]) => {
            if (devices) {
                this._devices = devices.filter((d: DeviceInfo) => d.virtualDeviceOwnerID !== this._comparedAccountID);
                this.refactorDevices();
            }
        });

        this._isLoading = true;
        this.devSvc.getDevicesByBatch('device-manage-share.onInit').pipe(
            takeUntil(this._allUnsubscribe)
        ).subscribe((res: { isFault: boolean, devices: DeviceInfo[], errorMessage?: string }) => {
            this._isLoading = false;
            if (!res.isFault) {
                this._devices = this._devices.concat(res.devices.filter((d: DeviceInfo) => d.virtualDeviceOwnerID !== this._comparedAccountID));
                this.refactorDevices();
            }
        });
    }

    ngOnDestroy(): void {
        this._allUnsubscribe.next();
        this._allUnsubscribe.complete();
    }

    refreshDevices(): void {
        this._refreshCounter = REFRESH_DURATION * 2;
        HelperLib.countdown(this._refreshCounter, 0, (remain_counter: number) => {
            this._refreshCounter = remain_counter;
        });

        this._devices = [];
        this._isLoading = true;
        this.devSvc.getDevicesByBatch('device-manage-share.refresh', true).subscribe((res: { isFault: boolean, devices: DeviceInfo[], errorMessage?: string }) => {
            if (!res.isFault) {
                this._devices = this._devices.concat(res.devices.filter((d: DeviceInfo) => d.virtualDeviceOwnerID !== this._comparedAccountID));
                this.refactorDevices();
            }
            this._isLoading = false;
        });
    }

    changeNumberInPage(currentNumberInPage: number): void {
        if (this._isLoading) {
            return;
        }

        if (this._numberInPage !== currentNumberInPage) {
            this._numberInPage = currentNumberInPage;
            this.refactorDevices();
            this.userPrefSvc.changeHomeDevicePageCapacity(this._numberInPage);
        }
    }

    changePage(currentPage: number): void {
        if (this._isLoading) {
            return;
        }

        this._page = currentPage;
        this.refactorDevices();
    }

    private refactorDevices(): void {
        //filter by search text
        this._displayDeviceList = this._searchInfo ? this._devices.filter((d: DeviceInfo) => {
            switch (this._searchInfo.key) {
                case this.constantSvc.DEVKEY_INFO_PNAME:
                    {
                        if (d.virtualName) {
                            return d.virtualName.toLocaleLowerCase().indexOf(this._searchInfo.value) >= 0;
                        }
                        else if (d.currentSettings[this._searchInfo.key]) {
                            return d.currentSettings[this._searchInfo.key].toLocaleLowerCase().indexOf(this._searchInfo.value) >= 0;
                        }
                    }
                    break;
                default:
                    {
                        if (d.currentSettings[this._searchInfo.key]) {
                            return d.currentSettings[this._searchInfo.key].toLocaleLowerCase().indexOf(this._searchInfo.value) >= 0;
                        }
                    }
                    break;
            }
            return false;
        }) : this._devices;

        //update final page amount.
        this._displayPlayerAmount = this._displayDeviceList.length

        //filter by page
        if (this._displayDeviceList.length > this._numberInPage) {
            const startIndex = (this._page - 1) * this._numberInPage;
            this._displayDeviceList = this._displayDeviceList.slice(startIndex, startIndex + this._numberInPage);
        }
    }

    private popupConfirmRemoveShareDialog(device: DeviceInfo): void {
        this._selectedDevice = device;
        this._removeStatusPage = DialogPage.confirm;
        this._errorMessage = null;
    }

    private removeSharedDevice(): void {
        this._removeStatusPage = DialogPage.submit;

        this.devSvc.RemoveSharedDevice(this._selectedDevice.virtualId, this._selectedDevice.virtualPairId).subscribe((res: { isFault: boolean, errorMessage?: string }) => {
            if (res.isFault) {
                this._errorMessage = res.errorMessage;
            }
            else {
                this._displayDeviceList = this._displayDeviceList.splice(this._displayDeviceList.indexOf(this._selectedDevice), 1);
                this._selectedDevice = null;
            }

            this._removeStatusPage = DialogPage.result;
        });
    }
}