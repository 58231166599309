import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DialogPage } from '../../../../lib/common/common.data';
import { ScepServerInfo } from '../scep.data';
import { ScepService } from '../scep.service';
import { IScepFuncCtrl } from './scep-func.def';

@Component({
    templateUrl: './scep-dlg-remove.component.html',
    styleUrls: ['../scep.style.css']
})
export class ScepRemoveDlgComponent implements IScepFuncCtrl, OnInit {
    title: string;
    funcName: string;
    scepList: ScepServerInfo[];
    other?: any;
    onActionCompleted: (ret: { funcName: string; isFault: boolean; data?: any; errorMessage?: string; }) => void;

    _scep: ScepServerInfo;
    _page: DialogPage = DialogPage.action;
    _enumPage: typeof DialogPage = DialogPage;
    _errorMessage: string;

    private _btnCloseElementRef: ElementRef;
    @ViewChild('btnClose', { static: true })
    set btnClose(v: any) {
        this._btnCloseElementRef = v;
    }

    constructor(private scepSvc: ScepService) {}

    ngOnInit(): void {
        this._scep = this.scepList ? this.scepList[0] : null;
    }

    remove(): void {
        this._page = DialogPage.submit;
        this._errorMessage = null;

        this.scepSvc.removeScep(this._scep).subscribe((res: { scep: ScepServerInfo, isFault: boolean, errorMessage?: string }) => {
            this._page = DialogPage.result;
            this._errorMessage = res.errorMessage;
            
            if (!res.isFault) {
                setTimeout(() => {
                    this._btnCloseElementRef.nativeElement.click();
                }, 0);
            }
        });      
    }
}