import { Component, Input, OnDestroy, OnInit, Output, EventEmitter, ViewChild, ElementRef, HostListener } from '@angular/core';
import { Subject } from 'rxjs';
import { concatMap, takeUntil } from 'rxjs/operators';

import { IUserDlgFuncCtrl, UserDlgFuncItem, USER_DLG_FUNC_CHANGEGROUP, USER_DLG_GROUP_FUNC_NEW } from '../dlg/user-dlg-func.def';
import { UserDlgFuncDirective } from '../dlg/user-dlg-func.directive';
import { UserDlgFuncService } from '../dlg/user-dlg-func.service';
import { UserInfo } from '../user.data';
import { UserService } from '../user.service';
import { UserGroupInfo } from './user-group.data';
import { HelperLib } from '../../../../../app/lib/common/helper.lib';
import { AccountService } from '../../../../../app/entry/account.service';

@Component({
    selector: 'na-user-group-view',
    templateUrl: './user-group-view.component.html',
    styleUrls: ['./user-group-view.component.css']
})
export class UserGroupViewComponent implements OnInit, OnDestroy {
    _userGroupList: UserGroupInfo[] = [];
    _userCount: number = 0;
    _userCountMap: { [groupID: string]: number } = {};

    _dragMap: { [userGroupID: string]: boolean } = {};
    _dragUserIDList: string[];
    _dropData: { userGroupFrom: UserGroupInfo, userGroupTo: UserGroupInfo, dragUserList: UserInfo[] };

    _selectedUserGroup: UserGroupInfo;
    private _allUnsubscribe: Subject<void> = new Subject();

    _showGroupTree: boolean = false;
    _isMobileLayout: boolean;

    _funcMode: string = 'default'; //default | selection
    @Input('funcMode')
    set funcMode(v: string) {
        this._funcMode = v;
    }

    private _btnChangeGroupElementRef: ElementRef;
    @ViewChild('btnChangeGroup', { static: true })
    set btnChangeGroup(host: any) {
        this._btnChangeGroupElementRef = host;
    }

    private _userFuncHost: UserDlgFuncDirective;
    @ViewChild(UserDlgFuncDirective, { static: true })
    set userFuncHost(host: any) {
        this._userFuncHost = host;
    }

    @HostListener('window:resize', ['$event'])
    onresize(event) {
        this.updateLayout(event.target.innerWidth);
    }

    @Output() onUserGroupChosen = new EventEmitter<UserGroupInfo>();

    constructor(
        private accountSvc: AccountService,
        private userSvc: UserService,
        private userDlgFuncSvc: UserDlgFuncService
    ) { }

    ngOnInit(): void {
        this.userSvc.onUserAdded.pipe(
            takeUntil(this._allUnsubscribe)
        ).subscribe((res: { addedUserList: UserInfo[], remainingUserList: UserInfo[] }) => {
            res.addedUserList.forEach(user => {
                this.updateUserCount(res.remainingUserList);
            });
        });

        this.userSvc.onUserRemoved.pipe(
            takeUntil(this._allUnsubscribe)
        ).subscribe((res: { removedUserList: UserInfo[], remainingUserList: UserInfo[] }) => {
            this.updateUserCount(res.remainingUserList);
        });

        this.userSvc.onUserUpdated.pipe(
            takeUntil(this._allUnsubscribe)
        ).subscribe((res: { updatedUserList: UserInfo[], remainingUserList: UserInfo[] }) => {
            this.updateUserCount(res.remainingUserList);
        });

        this.userSvc.onUserGroupRemoved.pipe(
            takeUntil(this._allUnsubscribe)
        ).subscribe((res: { removedUserGroup: UserGroupInfo, remainingUserGroupList: UserGroupInfo[] }) => {
            this._userGroupList = res.remainingUserGroupList;
            this.selectUserGroup(this.userSvc.getDefaultUserGroup());
        });

        this.userSvc.onUserGroupAdded.pipe(
            takeUntil(this._allUnsubscribe)
        ).subscribe((res: { addedUserGroup: UserGroupInfo, remainingUserGroupList: UserGroupInfo[] }) => {
            this._userGroupList = res.remainingUserGroupList;
            this.selectUserGroup(res.addedUserGroup);
        });

        this.userSvc.getUserGroupList().pipe(
            concatMap((res: { userGroupList: UserGroupInfo[], isFault: boolean, errorMessage?: string }) => {
                if (!res.isFault) {
                    this._userGroupList = res.userGroupList;
                }

                return this.userSvc.getUserList();
            })
        ).subscribe((res: { userList: UserInfo[], isFault: boolean, errorMessage?: string }) => {
            if (!res.isFault) {
                this.updateUserCount(res.userList);
            }
        });

        this.updateLayout();
    }

    ngOnDestroy(): void {
        this._allUnsubscribe.next();
        this._allUnsubscribe.complete();
    }

    supportUpdateUserGroup(): boolean {
        return this.accountSvc.hasScope_admin_account_group_update();
    }

    private updateLayout(testW?: number): void {
        const isMobileLayout: boolean = HelperLib.isMobileLayout(testW, 768) ? true : false;
        if (this._isMobileLayout !== isMobileLayout) {
            this._isMobileLayout = isMobileLayout;
            this._showGroupTree = !this._isMobileLayout ? true : false;
        }
    }

    selectUserGroup(group: UserGroupInfo = null): void {
        this._selectedUserGroup = group;
        this.onUserGroupChosen.emit(this._selectedUserGroup);
        if (this._isMobileLayout) {
            this._showGroupTree = false;
        }
    }

    onDragEnter(e: DragEvent, g: UserGroupInfo): void {
        this.drag_prev_check(e);

        this._dragMap[g.id] = true;
    }

    onDragOver(e: DragEvent, g: UserGroupInfo): void {
        this.drag_prev_check(e);

        e.dataTransfer.dropEffect = "move";
    }

    onDragLeave(e: DragEvent, g: UserGroupInfo): void {
        this.drag_prev_check(e);

        this._dragMap[g.id] = false;
    }

    onDrop(e: DragEvent, g: UserGroupInfo): void {
        this.drag_prev_check(e);

        const dragStr: string = e.dataTransfer.getData("text");
        const dragDataList: string[] = dragStr.match(/userGroupID:(.*)\|\|userIDList:(.*)/);
        if (dragDataList && dragDataList.length === 3) {
            const userGroupFrom: UserGroupInfo = this.userSvc.getCurrentUserGroupByID(dragDataList[1]);
            const dragUserList: UserInfo[] = dragDataList[2].split(',').map(uID => this.userSvc.getCurrentUserByID(uID));
            //check if some datas are null
            if (userGroupFrom && dragUserList.filter(u => !u).length === 0) {
                this._dropData = {
                    dragUserList: dragUserList,
                    userGroupFrom: userGroupFrom,
                    userGroupTo: g
                };

                this._btnChangeGroupElementRef.nativeElement.click();
            }
        }

        this._dragMap = {};
    }

    private drag_prev_check(e: DragEvent): void {
        e.preventDefault();
        e.stopPropagation();
    }

    changeGroup(): void {
        this.createUserDlg<UserInfo[], { from?: UserGroupInfo, to?: UserGroupInfo }>(USER_DLG_FUNC_CHANGEGROUP, this._dropData.dragUserList, { from: this._dropData.userGroupFrom, to: this._dropData.userGroupTo });
    }

    private updateUserCount(userList: UserInfo[]): void {
        this._userCount = userList.length;
        this._userCountMap = {};

        userList.forEach((u: UserInfo) => {
            this._userCountMap[u.userGroupID] = this._userCountMap[u.userGroupID] || 0;
            this._userCountMap[u.userGroupID]++;
        });
    }

    createUserGroup(): void {
        this.createUserDlg<UserGroupInfo, void>(USER_DLG_GROUP_FUNC_NEW);
    }

    private createUserDlg<D, O>(userFuncName: string, data?: D, other?: O): void {
        const item: UserDlgFuncItem = this.userDlgFuncSvc.getItemByName(userFuncName);
        if (item) {
            const viewContainerRef = this._userFuncHost.viewContainerRef;
            viewContainerRef.clear();

            const componentRef = viewContainerRef.createComponent(item.component);

            (<IUserDlgFuncCtrl<D, O>>componentRef.instance).title = item.title;
            (<IUserDlgFuncCtrl<D, O>>componentRef.instance).funcName = userFuncName;
            (<IUserDlgFuncCtrl<D, O>>componentRef.instance).data = data;
            (<IUserDlgFuncCtrl<D, O>>componentRef.instance).other = other;
            (<IUserDlgFuncCtrl<D, O>>componentRef.instance).onActionCompleted = this.onUserActionComplete.bind(this);
            (<IUserDlgFuncCtrl<D, O>>componentRef.instance).onActionCancelled = this.onUserActionCancel.bind(this);
        }
    }

    private onUserActionComplete(ret: { funcName: string, isFault: boolean, data?: any, errorMessage?: string }): void {
    }

    private onUserActionCancel(ret: { funcName: string }): void {
    }
}