import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';

import { UserInfo } from './user.data';
import { UserService } from './user.service';
import { UserGroupInfo } from './group/user-group.data';
import { UserDlgFuncDirective } from './dlg/user-dlg-func.directive';
import { UserDlgFuncService } from './dlg/user-dlg-func.service';
import { IUserDlgFuncCtrl, UserDlgFuncItem, USER_DLG_FUNC_CHANGEGROUP, USER_DLG_FUNC_INVITE, USER_DLG_FUNC_INVITE_BULK, USER_DLG_FUNC_REMOVE } from './dlg/user-dlg-func.def';
import { AccountService } from '../../../../app/entry/account.service';

@Component({
    templateUrl: './user-overview.component.html',
    styleUrls: ['./user.style.css', './user-overview.component.css']
})
export class UserOverviewComponent implements OnInit, OnDestroy {
    readonly NUMBER_IN_PAGE_OPTIONS: number[] = [30, 50, 100];

    _selectedUserGroup: UserGroupInfo;
    _selectedUserList: UserInfo[] = [];

    private _allUnsubscribe: Subject<void> = new Subject();

    private _userDlgFuncHost: UserDlgFuncDirective;
    @ViewChild(UserDlgFuncDirective, { static: true })
    set userDlgFuncHost(host: any) {
        this._userDlgFuncHost = host;
    }

    private _btnInviteElementRef: ElementRef;
    @ViewChild('btnInvite', { static: true })
    set btnInvite(host: any) {
        this._btnInviteElementRef = host;
    }

    constructor(
        private accountSvc: AccountService,
        public userSvc: UserService,
        private userDlgFuncSvc: UserDlgFuncService) {
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this._allUnsubscribe.next();
        this._allUnsubscribe.complete();
    }

    supportViewUser(): boolean {
        return this.accountSvc.hasScope_admin_account_view();
    }

    supportUpdateUser(): boolean {
        return this.accountSvc.hasScope_admin_account_update();
    }

    supportUpdateUserGroup(): boolean {
        return this.accountSvc.hasScope_admin_account_group_update();
    }

    invite(): void {
        this.createUserDlg<void, void>(USER_DLG_FUNC_INVITE);
    }

    batchInvite(): void {
        this.createUserDlg<void, void>(USER_DLG_FUNC_INVITE_BULK);
    }

    removeUsers(): void {
        this.createUserDlg<UserInfo[], void>(USER_DLG_FUNC_REMOVE, this._selectedUserList);
    }

    changeUserGroup(): void {
        this.createUserDlg<UserInfo[], { from?: UserGroupInfo, to?: UserGroupInfo }>(USER_DLG_FUNC_CHANGEGROUP, this._selectedUserList, { from: this._selectedUserGroup });
    }

    removeUserFromGroup(group: UserGroupInfo): void {
        this.createUserDlg<UserInfo[], { from?: UserGroupInfo, to?: UserGroupInfo }>(USER_DLG_FUNC_CHANGEGROUP, this._selectedUserList, { from: this._selectedUserGroup, to: this.userSvc.getDefaultUserGroup() });
    }

    private onActionComplete(ret: { funcName: string, isFault: boolean, data?: any, errorMessage?: string }): void {
        switch (ret.funcName) {
            case USER_DLG_FUNC_REMOVE:
            case USER_DLG_FUNC_CHANGEGROUP:
                {
                    this._selectedUserList = [];
                }
                break;
        }
    }

    private onActionCancel(ret: { funcName: string }): void {
        
    }

    private createUserDlg<D, O>(userFuncName: string, userList?: D, other?: O): void {
        const item: UserDlgFuncItem = this.userDlgFuncSvc.getItemByName(userFuncName);
        if (item) {
            const viewContainerRef = this._userDlgFuncHost.viewContainerRef;
            viewContainerRef.clear();

            const componentRef = viewContainerRef.createComponent(item.component);

            (<IUserDlgFuncCtrl<D, O>>componentRef.instance).title = item.title;
            (<IUserDlgFuncCtrl<D, O>>componentRef.instance).funcName = userFuncName;
            (<IUserDlgFuncCtrl<D, O>>componentRef.instance).data = userList;
            (<IUserDlgFuncCtrl<D, O>>componentRef.instance).other = other;
            (<IUserDlgFuncCtrl<D, O>>componentRef.instance).onActionCompleted = this.onActionComplete.bind(this);
            (<IUserDlgFuncCtrl<D, O>>componentRef.instance).onActionCancelled = this.onActionCancel.bind(this);
        }
    }

    onUserGroupChosen(userGroup: UserGroupInfo): void {
        this._selectedUserGroup = userGroup;
    }

    onUserSelectionChange(selectedUserList: UserInfo[]): void {
        this._selectedUserList = selectedUserList;
    }

    onUserInviteRequest(user: UserInfo): void {
        this.createUserDlg<UserInfo[], void>(USER_DLG_FUNC_INVITE, [user]);
        this._btnInviteElementRef.nativeElement.click();
    }
}