import { HttpClient } from '@angular/common/http';

import { APIWrapperBase } from '../../../api.wrapper.base';
import { APIStartRemoteCtrlManager } from './api.remoteCtrl.start';
import { APIExtendRemoteCtrlManager } from './api.remoteCtrl.extend';
import { APIQueryRemoteCtrlManager } from './api.remoteCtrl.query';
import { APIStopRemoteCtrlManager } from './api.remoteCtrl.stop';

export class APIRemoteCtrlWrapper extends APIWrapperBase {
    StartRemoteCtrl: APIStartRemoteCtrlManager;
    StopRemoteCtrl: APIStopRemoteCtrlManager;
    ExtendRemoteCtrl: APIExtendRemoteCtrlManager;
    QueryRemoteCtrl: APIQueryRemoteCtrlManager;

    constructor(private http: HttpClient) {
        super();

        this.StartRemoteCtrl = new APIStartRemoteCtrlManager(http);
        this.StopRemoteCtrl = new APIStopRemoteCtrlManager(http);
        this.ExtendRemoteCtrl = new APIExtendRemoteCtrlManager(http);
        this.QueryRemoteCtrl = new APIQueryRemoteCtrlManager(http);
    }

    public resetServerConfig(protocol: string, baseUrl: string, port: number): void {
        [
            this.StartRemoteCtrl,
            this.StopRemoteCtrl,
            this.ExtendRemoteCtrl,
            this.QueryRemoteCtrl
        ].forEach(t => t.resetServerConfig(protocol, baseUrl, port));
    }
}