<div class="row">
    <div class="col-12">
        <h4>Download</h4>
        <ul>
            <li *ngIf="_macDownloadUrl">
                <a href="{{ _macDownloadUrl }}">
                    MAC
                </a>
            </li>
            <li *ngIf="_winDownloadUrl">
                <a id="id_lanTool_link_windowsPackage" href="{{ _winDownloadUrl }}">
                    Windows
                </a>
            </li>
        </ul>
    </div>
    <div *ngIf="_webUrl" class="col-12">
        <h4>Web</h4>
        <ul>
            <li>
                <a id="id_lanTool_link_webPackage" (click)="openLanToolWebLink()">
                    Cross platform version
                    <i class="fas fa-external-link-alt ml-2"></i>
                </a>
            </li>
        </ul>
    </div>
    <form #lanToolWebForm target="_blank" [action]="_webUrl" method="GET">
        <input type="hidden" name="server" [value]="_server">
        <input type="hidden" name="token" [value]="_token">
    </form>
</div>