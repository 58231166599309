import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { Router, Event, NavigationEnd } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TopMenuService } from '../../../../app/content/menu.service';
import { AlertTabType } from './alertConfig/alert.data';

@Component({
    templateUrl: './alert-wrapper.component.html',
    styleUrls: []
})
export class AlertWrapperComponent implements OnInit, OnDestroy {
    private _allUnsubscribe: Subject<void> = new Subject();

    _alertOpenRoute: string;
    _alertCloseRoute: string;
    _alertConfigRoute: string;

    _activeTab: AlertTabType = AlertTabType.none;
    _enumAlertTabType: typeof AlertTabType = AlertTabType;

    constructor(
        private location: Location,
        private router: Router,
        private menuSvc: TopMenuService) {
    }

    ngOnInit(): void {
        this._alertOpenRoute = this.menuSvc.getAlertRoute(AlertTabType.open);
        this._alertCloseRoute = this.menuSvc.getAlertRoute(AlertTabType.close);
        this._alertConfigRoute = this.menuSvc.getAlertRoute(AlertTabType.config);

        this.router.events.pipe(
            takeUntil(this._allUnsubscribe)
        ).subscribe((routeEvent: Event) => {
            if (routeEvent instanceof NavigationEnd) {
                this.update_active_route(this.location.path());
            }
        });

        this.update_active_route(this.location.path());
    }

    ngOnDestroy(): void {
        this._allUnsubscribe.next();
        this._allUnsubscribe.complete();
    }

    private update_active_route(route: string): void {
        const route_pieces: string[] = route.split(/\//g);
        let path_index: number = 1;

        while (route_pieces.length - path_index >= 0) {
            if (AlertTabType[route_pieces[route_pieces.length - path_index]]) {
                this._activeTab = AlertTabType[route_pieces[route_pieces.length - path_index]];
                break;
            }

            path_index++;
        }

        if (this._activeTab === AlertTabType.none) {
            this.router.navigate([this._alertConfigRoute]);
        }
    }
}