import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../../api.base';

export interface IUpdateGroupDevicePathParameter {
    deviceGroupID: string;
}

export interface IUpdateGroupDeviceTxData {
    addList?: string[]; //virtual device ID list
    removeList?: string[];
}

export class APIUpdateGroupDeviceManager extends APIBaseManager<IUpdateGroupDevicePathParameter, void, IUpdateGroupDeviceTxData, any> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_UpdateDeviceInDevGroup';
        this._apiMethod = 'PATCH';
    }

    protected getRequestURL(pathParameters: IUpdateGroupDevicePathParameter, queryParameters: void): string {
        return super.getRequestURL() + 'deviceGroups/' + pathParameters.deviceGroupID + '/devices';
    }
}