import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { of as observableOf, fromEvent as observableFromEvent, Subject } from 'rxjs';
import { concatMap, debounceTime, takeUntil, map } from 'rxjs/operators';

import { DeviceService } from '../device/device.service';
import { DeviceInfo, IDevicePairStatusChangeEventArgs } from '../device/data/device-info';
import { LicenseService } from './license.service';
import { LicenseCategory, LicenseExpiryTimeFilterData, LicenseExpiryTypeFilterData } from './license.data';
import { LicenseFuncItem, ILicenseDynamicComponent, FUNCNAME_LICENSE_EXPLANATION, FUNCNAME_LICENSE_ADD, FUNCNAME_LICENSE_IMPORT, FUNCNAME_LICENSE_REALLOCATE } from './form/license-func.def';
import { LicenseFuncService } from './form/license-func.service';
import { LicenseFuncDirective } from './form/license-func.directive';
import { TableHeaderHandler, SortType } from '../../lib/common/common.data';
import { ILicenseCategoryInfo } from '../../API/v1/License/api.license.common';
import { AppConfigService } from '../../app.config';
import { HelperLib, REFRESH_DURATION } from '../../lib/common/helper.lib';
import { ConstantService } from '../../lib/common/constant.service';
import { DatePipe } from '@angular/common';
import { UserPreferenceService } from '../user-preference.service';
import { AccountService } from '../../entry/account.service';

@Component({
    templateUrl: './device-license.component.html',
    styleUrls: ['./device-license.component.css']
})
export class DeviceLicenseComponent implements OnInit, OnDestroy {
    readonly NUMBER_IN_PAGE_OPTIONS: number[] = [30, 100, 200];
    readonly DEFAULT_NUMBER_IN_PAGE: number = this.NUMBER_IN_PAGE_OPTIONS[0];

    private readonly TH_PLAYERNAME: string = 'th-pName';
    private readonly TH_WARRANTY: string = 'th-warranty';
    private readonly TH_LICENSE_IADEA: string = 'th-licenseIAdea';
    private readonly TH_LICENSE_OTHER: string = 'th-licenseOther';

    FUNCNAME_LICENSE_EXPLANATION: string = FUNCNAME_LICENSE_EXPLANATION;
    FUNCNAME_LICENSE_ADD: string = FUNCNAME_LICENSE_ADD;
    FUNCNAME_LICENSE_IMPORT: string = FUNCNAME_LICENSE_IMPORT;
    FUNCNAME_LICENSE_REALLOCATE: string = FUNCNAME_LICENSE_REALLOCATE;

    _licenseAmount: number = 0;
    _currentPage: number = 1;
    _numberInPage: number = this.DEFAULT_NUMBER_IN_PAGE;
    private _allUnsubscribe: Subject<void> = new Subject();

    _licenseExpiryTimeFilter: LicenseExpiryTimeFilterData;
    _licenseExpiryTypeFilter: LicenseExpiryTypeFilterData;

    _isLoading: boolean = false;
    _exporting: boolean = false;
    _bSelectAll: boolean = false;
    private _searchText: string = '';
    private _enumSortType: typeof SortType = SortType;

    private _devLicenseMap: {
        [virtualDeviceID: string]: {
            device: DeviceInfo;
            iCareLicense: ILicenseCategoryInfo,
            cloudLicense: ILicenseCategoryInfo
        }
    }

    _displayDevLicenseList: {
        device: DeviceInfo,
        iCareLicense: ILicenseCategoryInfo,
        cloudLicense: ILicenseCategoryInfo
    }[];

    private _devSelectionList: { [virtualDeviceId: string]: boolean } = {};
    _tableHeaderHandler: TableHeaderHandler = new TableHeaderHandler();
    _licenseRefreshCounter: number = 0;

    private _searchElementRef: ElementRef;
    @ViewChild('search', { static: true })
    set searchElement(holder: ElementRef) {
        if (holder && !this._searchElementRef) {
            this._searchElementRef = holder;

            if (this._searchElementRef) {
                const searchElement = this._searchElementRef.nativeElement;
                const searchInputOb = observableFromEvent(searchElement, 'input');

                searchInputOb.pipe(
                    debounceTime(200))
                    .subscribe((e: any) => {
                        this._searchText = e.target.value.toLocaleLowerCase();
                        this.refactorDisplayInfo();
                    });
            }
        }
    }

    private _exportLinkRef: ElementRef<HTMLLinkElement>;
    @ViewChild('exportLink', { static: true })
    set exportLink(v: ElementRef) {
        if (v) {
            this._exportLinkRef = v;
        }
    }

    @ViewChild(LicenseFuncDirective, { static: true }) _licenseFuncHost: LicenseFuncDirective;

    constructor(
        private datePipe: DatePipe,
        private accountSvc: AccountService,
        private constantSvc: ConstantService,
        private devSvc: DeviceService,
        private userPrefSvc: UserPreferenceService,
        private licenseSvc: LicenseService,
        private licenseFuncSvc: LicenseFuncService) {
    }

    ngOnInit(): void {
        this.update();
        this._licenseExpiryTypeFilter = new LicenseExpiryTypeFilterData(this.userPrefSvc.userPreference.license.filter.licenseType);
        this._licenseExpiryTimeFilter = new LicenseExpiryTimeFilterData(this.userPrefSvc.userPreference.license.filter.expiryDate);

        //init header
        this._tableHeaderHandler.init([
            {
                id: this.TH_PLAYERNAME,
                displayName: 'device name',
                dataKey: this.constantSvc.DEVKEY_FAKE_DISPLAYNAME,
                sorter: {
                    currentSortType: SortType.none
                }
            },
            {
                id: 'th-devMac',
                displayName: 'MAC',
                dataKey: this.constantSvc.DEVKEY_NET_LAN_MAC
            },
            {
                id: this.TH_WARRANTY,
                displayName: 'warranty',
                dataKey: this.constantSvc.DEVKEY_INFO_WARRANTY_ENDDATE,
                sorter: {
                    currentSortType: SortType.none
                }
            },
            {
                id: this.TH_LICENSE_IADEA,
                displayName: 'IAdeaCare license',
                dataKey: LicenseCategory.ICare,
                sorter: {
                    currentSortType: SortType.none
                }
            },
            {
                id: this.TH_LICENSE_OTHER,
                displayName: 'miscellaneous',
                dataKey: 'miscellaneous'
            }
        ]);

        this._tableHeaderHandler.sortChanged.pipe(
            takeUntil(this._allUnsubscribe)
        ).subscribe((sortRes) => {
            this.refactorDisplayInfo();
        });

        this.licenseSvc.assigneeLicenseChanged.pipe(
            takeUntil(this._allUnsubscribe)
        ).subscribe((changedArgList: { virtualDeviceID: string, updateLicenseInfo: { [licenseCategory: string]: ILicenseCategoryInfo } }[]) => {
            changedArgList.forEach(c => {
                if (c.virtualDeviceID) {
                    if (this._devLicenseMap[c.virtualDeviceID]) {
                        this._devLicenseMap[c.virtualDeviceID].iCareLicense = c.updateLicenseInfo ? c.updateLicenseInfo[LicenseCategory.ICare] : null;
                        this._devLicenseMap[c.virtualDeviceID].cloudLicense = c.updateLicenseInfo ? c.updateLicenseInfo[LicenseCategory.Cloud] : null;
                        this.refactorDisplayInfo();
                    }
                }
            });
        });

        this.devSvc.devicePairStatusChanged.pipe(
            takeUntil(this._allUnsubscribe)
        ).subscribe((devicePairStatus: IDevicePairStatusChangeEventArgs) => {
            if (!devicePairStatus.isFault && devicePairStatus.isPaired) {
                this.licenseSvc.getLicenseByDevice(devicePairStatus.virtualDeviceID, true).subscribe((licenseInfo: { [category: string]: ILicenseCategoryInfo }) => {
                    if (this._devLicenseMap[devicePairStatus.virtualDeviceID]) {
                        this._devLicenseMap[devicePairStatus.virtualDeviceID].iCareLicense = licenseInfo ? licenseInfo[LicenseCategory.ICare] : null;
                        this._devLicenseMap[devicePairStatus.virtualDeviceID].cloudLicense = licenseInfo ? licenseInfo[LicenseCategory.Cloud] : null;
                    }
                    else {
                        this._devLicenseMap[devicePairStatus.virtualDeviceID].device = devicePairStatus.device;
                        this._devLicenseMap[devicePairStatus.virtualDeviceID].iCareLicense = licenseInfo ? licenseInfo[LicenseCategory.ICare] : null;
                        this._devLicenseMap[devicePairStatus.virtualDeviceID].cloudLicense = licenseInfo ? licenseInfo[LicenseCategory.Cloud] : null;

                        this._devSelectionList[devicePairStatus.virtualDeviceID] = false;
                        this.refactorDisplayInfo();
                    }
                });
            }
        });
    }

    ngOnDestroy(): void {
        this._allUnsubscribe.next();
        this._allUnsubscribe.complete();
    }

    supportAddLicense(): boolean {
        return AppConfigService.configs.licensePage.element.enabledAddLicense && this.accountSvc.hasScope_license_assign();
    }

    supportReallocateLicense(): boolean {
        return AppConfigService.configs.licensePage.element.enabledReallocateLicense && this.accountSvc.hasScope_license_assign();
    }

    private reset(): void {
        this._devLicenseMap = {};
        this._displayDevLicenseList = [];
        this._devSelectionList = {};
    }

    private update(force: boolean = false): void {
        this.reset();
        this._isLoading = true;

        let devices: DeviceInfo[];
        this.devSvc.getDevices('license-overview.update').pipe(
            concatMap((res: { isFault: boolean, devices: DeviceInfo[], errorMessage?: string }) => {
                devices = res.devices;
                return this.licenseSvc.getLicenseByDeviceList(res.devices.map(d => d.virtualId), force);
            })
        ).subscribe(res => {
            if (!res.isFault) {
                if (res.licenseData) {
                    Object.keys(res.licenseData).forEach((vID: string) => {
                        if (!this._devLicenseMap[vID]) {
                            this._devLicenseMap[vID] = {
                                device: devices.find(d => d.virtualId === vID),
                                iCareLicense: res.licenseData[vID] ? res.licenseData[vID][LicenseCategory.ICare] : null,
                                cloudLicense: res.licenseData[vID] ? res.licenseData[vID][LicenseCategory.Cloud] : null
                            };
                        }
                        else {
                            this._devLicenseMap[vID].device = devices.find(d => d.virtualId === vID);
                            this._devLicenseMap[vID].iCareLicense = res.licenseData[vID] ? res.licenseData[vID][LicenseCategory.ICare] : null;
                            this._devLicenseMap[vID].cloudLicense = res.licenseData[vID] ? res.licenseData[vID][LicenseCategory.Cloud] : null;
                        }

                        this._devSelectionList[vID] = false;
                    });

                    this.refactorDisplayInfo();
                }

                if (this._displayDevLicenseList.length > 0 || !res.hasNext) {
                    this._isLoading = false;
                }
            }
        });
    }

    private refactorDisplayInfo(): void {
        let devLicenseList: {
            device: DeviceInfo,
            iCareLicense: ILicenseCategoryInfo,
            cloudLicense: ILicenseCategoryInfo
        }[] = HelperLib.mapToList(this._devLicenseMap);

        devLicenseList = this.filterLicense(devLicenseList);

        //sort
        let sortKey: string;
        let sortType: SortType = SortType.none;
        for (const header_info of this._tableHeaderHandler.list) {
            if (header_info.sorter && header_info.sorter.currentSortType !== SortType.none) {
                sortKey = header_info.dataKey;
                sortType = header_info.sorter.currentSortType;
                break;
            }
        }
        if (!sortKey) {
            //use name as default sort key.
            sortKey = this.constantSvc.DEVKEY_FAKE_DISPLAYNAME;
            sortType = SortType.ascend;
        }

        devLicenseList = devLicenseList.sort((a, b) => {
            let a_val: any = '';
            let b_val: any = '';
            if (sortKey === LicenseCategory.ICare) {
                a_val = a.iCareLicense ? a.iCareLicense.currentActivated.length + '-' + a.iCareLicense.currentActivated.join(',') + '-' + a.iCareLicense.expiryDate : null;
                b_val = b.iCareLicense ? b.iCareLicense.currentActivated.length + '-' + b.iCareLicense.currentActivated.join(',') + '-' + b.iCareLicense.expiryDate : null;
            }
            else {
                a_val = a.device.currentSettings[sortKey];
                b_val = b.device.currentSettings[sortKey];
            }

            if (sortType === SortType.ascend) {
                //ascend
                if (a_val && b_val) {
                    return a_val > b_val ? 1 : (a_val < b_val ? -1 : 0);
                }
                else {
                    if (!a_val && b_val) {
                        return -1;
                    }
                    else if (a_val && !b_val) {
                        return 1;
                    }
                    else {
                        return 0;
                    }
                }
            }
            else {
                //descend
                if (a_val && b_val) {
                    return a_val > b_val ? -1 : (a_val < b_val ? 1 : 0);
                }
                else {
                    if (!a_val && b_val) {
                        return 1;
                    }
                    else if (a_val && !b_val) {
                        return -1;
                    }
                    else {
                        return 0;
                    }
                }
            }
        });

        this._licenseAmount = devLicenseList.length;

        //filter by page
        if (devLicenseList.length > this._numberInPage) {
            const startIndex = (this._currentPage - 1) * this._numberInPage;
            this._displayDevLicenseList = devLicenseList.slice(startIndex, startIndex + this._numberInPage);
        }
        else {
            this._displayDevLicenseList = devLicenseList;
        }
    }

    applyFilterSearch(): void {
        const userPrefLicenseTypeFilter: { [type: string]: boolean } = this._licenseExpiryTypeFilter.applyFilter();
        const userPrefDateFilter: { beginDate: string, endDate: string } = this._licenseExpiryTimeFilter.applyFilter();

        this.refactorDisplayInfo();

        this.userPrefSvc.changeLicenseFilter(userPrefLicenseTypeFilter, userPrefDateFilter);
    }

    clearFilterSearch(): void {
        this._licenseExpiryTypeFilter.clear();
        this._licenseExpiryTimeFilter.clear();
    }

    private filterLicense(devLicenseList: {
        device: DeviceInfo,
        iCareLicense: ILicenseCategoryInfo,
        cloudLicense: ILicenseCategoryInfo
    }[]): {
        device: DeviceInfo,
        iCareLicense: ILicenseCategoryInfo,
        cloudLicense: ILicenseCategoryInfo
    }[] {
        if (this._licenseExpiryTypeFilter && this._licenseExpiryTimeFilter) {
            const typeList: string[] = this._licenseExpiryTypeFilter.getFilterData();
            const dateData: { beginDate: string, endDate: string } = this._licenseExpiryTimeFilter.getFilterData();

            if (typeList.length > 0 && dateData.beginDate && dateData.endDate) {
                const expiryFilter: { type: string[], date: { beginDate: string, endDate: string } } = { type: typeList, date: dateData };
                devLicenseList = devLicenseList.filter(dl => {
                    let isMatch: boolean = false;
                    let datestr: string;

                    for (let type of expiryFilter.type) {
                        switch (type) {
                            case LicenseCategory.Warranty:
                                {
                                    datestr = dl.device.currentSettings[this.constantSvc.DEVKEY_INFO_WARRANTY_ENDDATE];
                                }
                                break;
                            case LicenseCategory.ICare:
                                {
                                    datestr = dl.iCareLicense ? dl.iCareLicense.expiryDate : null;
                                }
                                break;
                            case LicenseCategory.Cloud:
                                {
                                    datestr = dl.cloudLicense ? dl.cloudLicense.expiryDate : null;
                                }
                                break;
                        }

                        datestr = datestr ? this.datePipe.transform(datestr, 'yyyy-MM-dd') : null;
                        if (datestr && datestr >= expiryFilter.date.beginDate && datestr <= expiryFilter.date.endDate) {
                            isMatch = true;
                            break;
                        }
                    }

                    return isMatch;
                });
            }
        }

        if (this._searchText) {
            devLicenseList = devLicenseList.filter(dl => {
                let isMatch: boolean = false;
                if (dl.device.virtualName) {
                    isMatch = dl.device.virtualName.toLocaleLowerCase().indexOf(this._searchText) >= 0;
                }

                if (!isMatch) {
                    if (dl.device.currentSettings[this.constantSvc.DEVKEY_INFO_PNAME]) {
                        isMatch = dl.device.currentSettings[this.constantSvc.DEVKEY_INFO_PNAME].toLocaleLowerCase().indexOf(this._searchText) >= 0;
                    }
                }

                return isMatch;
            });
        }

        return devLicenseList;
    }

    export(): void {
        this._exporting = true;

        observableOf(true).pipe(
            map(() => {
                const date: string = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
                const metadata: string[] = [`Input: ${HelperLib.mapToList(this._devLicenseMap).length}`];
                const rowList: string[][] = [];
                rowList.push(['Device name', 'MAC', 'Warranty', 'IAdeaCare license (summary of current activated)', 'IAdeaCare license (detail)', 'Miscellaneous (summary of current activated)', 'Miscellaneous (detail)']);

                let devLicenseList = this.filterLicense(HelperLib.mapToList(this._devLicenseMap));
                const dateFilter: { beginDate: string, endDate: string } = this._licenseExpiryTimeFilter.getFilterData()
                metadata.push(`Filter: ${devLicenseList.length}, Search=>(${this._searchText || 'N/A'}); LicenseType=>(${this._licenseExpiryTypeFilter.getFilterData().join(' | ')}); Expiry=>(${!dateFilter.beginDate && !dateFilter.endDate ? 'N/A' : ((dateFilter.beginDate || '') + ' ~ ' + (dateFilter.endDate || ''))})`);

                const selectedDevLicenseList = devLicenseList.filter(l => this._devSelectionList[l.device.virtualId]);
                metadata.push(`Checkbox: ${selectedDevLicenseList.length > 0 ? selectedDevLicenseList.length : 'N/A'}`);
                devLicenseList = selectedDevLicenseList.length === 0 ? devLicenseList : selectedDevLicenseList;

                devLicenseList.forEach(l => {
                    let detailIAdeaLicenseData: string = '';
                    if (l.iCareLicense && l.iCareLicense.detail) {
                        let index: number = 1;
                        detailIAdeaLicenseData = '\"'

                        Object.keys(l.iCareLicense.detail).forEach((type: string) => {
                            detailIAdeaLicenseData += l.iCareLicense.detail[type].licenseKeyList.map(dl => {
                                return index++ + '. Type: ' + type + '\n' +
                                    'Code: ***-' + dl.licenseKeyPartialCode + '\n' +
                                    'Expiry date: ' + (dl.licenseKeyExpiryDate ? this.datePipe.transform(dl.licenseKeyExpiryDate, 'yyyy-MM-dd') : '---') + '\n' +
                                    'Activated: ' + dl.isLicenseKeyActivated + '\n' +
                                    'Expired: ' + dl.isLicenseKeyExpired
                            }).join('\n');
                        });

                        detailIAdeaLicenseData += '\"';
                    }

                    let detailOtherLicenseData: string = '';
                    if (l.cloudLicense && l.cloudLicense.detail) {
                        let index: number = 1;
                        detailOtherLicenseData = '\"';

                        Object.keys(l.cloudLicense.detail).forEach((type: string) => {
                            detailIAdeaLicenseData += l.cloudLicense.detail[type].licenseKeyList.map(dl => {
                                return index++ + '. Type: ' + type + '\n' +
                                    'Code: ***-' + dl.licenseKeyPartialCode + '\n' +
                                    'Expiry date: ' + (dl.licenseKeyExpiryDate ? this.datePipe.transform(dl.licenseKeyExpiryDate, 'yyyy-MM-dd') : '---') + '\n' +
                                    'Activated: ' + dl.isLicenseKeyActivated + '\n' +
                                    'Expired: ' + dl.isLicenseKeyExpired
                            }).join('\n');
                        });

                        detailOtherLicenseData += '\"';
                    }

                    rowList.push([
                        '"' + l.device.virtualName + '"',
                        l.device.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_CONNECTED] && !l.device.currentSettings[this.constantSvc.DEVKEY_NET_LAN_CONNECTED] ? l.device.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_MAC] : l.device.currentSettings[this.constantSvc.DEVKEY_NET_LAN_MAC],
                        l.device.currentSettings[this.constantSvc.DEVKEY_INFO_WARRANTY_ENDDATE] ? this.datePipe.transform(l.device.currentSettings[this.constantSvc.DEVKEY_INFO_WARRANTY_ENDDATE], 'yyyy-MM-dd') : '---',
                        l.iCareLicense ? '"Type : ' + l.iCareLicense.currentActivated.join(', ') + '\n' + 'Expiry date : ' + (l.iCareLicense.expiryDate ? this.datePipe.transform(l.iCareLicense.expiryDate, 'yyyy-MM-dd') : '---') + (l.iCareLicense.isExpired ? ' (Expired)' : '') + '\"' : '---',
                        detailIAdeaLicenseData,
                        l.cloudLicense ? '"Type : ' + l.cloudLicense.currentActivated.join(', ') + '\n' + 'Expiry date : ' + (l.cloudLicense.expiryDate ? this.datePipe.transform(l.cloudLicense.expiryDate, 'yyyy-MM-dd') : '---') + (l.cloudLicense.isExpired ? ' (Expired)' : '') + '\"' : '---',
                        detailOtherLicenseData
                    ]);
                });

                return HelperLib.downloadCsv(this._exportLinkRef.nativeElement, 'License-' + this.accountSvc.accountName + '-' + date, null, rowList, metadata);
            })
        ).subscribe(() => {
            this._exporting = false;
        });
    }

    onPageChange(page: number): void {
        this._currentPage = page;
        this.refactorDisplayInfo();
    }

    changeNumberInPage(numberInPage: number): void {
        this._numberInPage = numberInPage;
        this.refactorDisplayInfo();
    }

    refreshLicenses(): void {
        this._licenseRefreshCounter = REFRESH_DURATION;
        HelperLib.countdown(this._licenseRefreshCounter, 0, (counter: number) => {
            this._licenseRefreshCounter = counter;
        });

        this.update(true);
    }

    selectAllPlayer(): void {
        this._bSelectAll = !this._bSelectAll;
        Object.keys(this._devSelectionList).forEach((virtualDeviceId: string) => {
            this._devSelectionList[virtualDeviceId] = this._bSelectAll;
        });
    }

    selectPlayer(virtualDeviceId: string): void {
        this._devSelectionList[virtualDeviceId] = !this._devSelectionList[virtualDeviceId];
        if (!this._devSelectionList[virtualDeviceId]) {
            this._bSelectAll = false;
        }
        else {
            let hasOneUnselected: boolean = false;
            for (const virtualDeviceId of Object.keys(this._devSelectionList)) {
                if (!this._devSelectionList[virtualDeviceId]) {
                    hasOneUnselected = true;
                    break;
                }
            }

            this._bSelectAll = hasOneUnselected ? false : true;
        }
    }

    private dialogComplete(): void {

    }

    playDevFunc(funcName: string): void {
        const devFuncItem: LicenseFuncItem = this.licenseFuncSvc.getFunctionByName(funcName);
        if (devFuncItem) {
            const viewContainerRef = this._licenseFuncHost.viewContainerRef;
            viewContainerRef.clear();

            const componentRef = viewContainerRef.createComponent(devFuncItem.component);

            (<ILicenseDynamicComponent>componentRef.instance).title = devFuncItem.title;
            (<ILicenseDynamicComponent>componentRef.instance).dialogCompleteHandler = this.dialogComplete.bind(this);
            switch (funcName) {
                case FUNCNAME_LICENSE_REALLOCATE:
                    {
                        (<ILicenseDynamicComponent>componentRef.instance)._devices = Object.keys(this._devLicenseMap).map((virtualDeviceID: string) => this._devLicenseMap[virtualDeviceID].device);
                    }
                    break;
                case FUNCNAME_LICENSE_ADD:
                case FUNCNAME_LICENSE_IMPORT:
                    {
                        (<ILicenseDynamicComponent>componentRef.instance)._devices = Object.keys(this._devLicenseMap).map((virtualDeviceID: string) => this._devLicenseMap[virtualDeviceID].device).filter(d => this._devSelectionList[d.virtualId]);
                    }
                    break;
            }

        }
    }
}