import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subject, fromEvent } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { AppStartOverlayInfo } from '../../content/device/data/device-info';
import { ConstantService } from '../../lib/common/constant.service';
import { IUIElement } from '../uiElement.interface';
import { TriState } from '../../../app/lib/common/common.data';

@Component({
    selector: 'na-overlay-qrcode',
    templateUrl: './overlay-qrcode.component.html',
    styleUrls: ['../uiElement.style.css', './overlay-qrcode.component.css']
})
export class OverlayQRCodeComponent implements OnInit, OnDestroy, IUIElement {
    _info: AppStartOverlayInfo;
    _enableState: TriState = TriState.Undetermin;
    private _allUnsubscribe: Subject<void> = new Subject();

    _config: { [name: string]: { value: any } };
    @Input('config')
    set config(d: { [name: string]: { value: any } }) {
        this._config = d;
    }

    _keepSupport: boolean = false;
    @Input('keepSupport')
    set keepSupport(d: boolean) {
        this._keepSupport = d;
    }

    _disabled: boolean = false;
    @Input('disabled')
    set disabled(d: boolean) {
        this._disabled = d;
    }

    _unsupportReason: string;
    @Input('unsupportReason')
    set unsupportReason(v: string) {
        this._unsupportReason = v;
    }

    _lockInfo: { isSync: boolean, policyID: string, policyName: string };
    @Input('lock')
    set lock(d: { isSync: boolean, policyID: string, policyName: string }) {
        this._lockInfo = d;
    }

    private _overlayUrlRef: ElementRef;
    @ViewChild('overlayUrl')
    set overlayUrl(h: ElementRef) {
        this._overlayUrlRef = h;
        const e = this._overlayUrlRef.nativeElement;
        const eOb = fromEvent(e, 'input');

        eOb.pipe(
            debounceTime(200),
            takeUntil(this._allUnsubscribe)
        ).subscribe((e: any) => {
            this._info.data = e.target.value;
            this.updateOverlayChange();
        });
    }

    private _overlayLandscapeWidthRef: ElementRef;
    @ViewChild('overlayLandscapeWidth')
    set overlayLandscapeWidth(h: ElementRef) {
        this._overlayLandscapeWidthRef = h;
        const e = this._overlayLandscapeWidthRef.nativeElement;
        const eOb = fromEvent(e, 'input');

        eOb.pipe(
            debounceTime(200),
            takeUntil(this._allUnsubscribe)
        ).subscribe((e: any) => {
            this._info.landscape.width = e.target.value;
            this.updateOverlayChange();
        });
    }

    private _overlayLandscapeHeightRef: ElementRef;
    @ViewChild('overlayLandscapeHeight')
    set overlayLandscapeHeight(h: ElementRef) {
        this._overlayLandscapeHeightRef = h;
        const e = this._overlayLandscapeHeightRef.nativeElement;
        const eOb = fromEvent(e, 'input');

        eOb.pipe(
            debounceTime(200),
            takeUntil(this._allUnsubscribe)
        ).subscribe((e: any) => {
            this._info.landscape.height = e.target.value;
            this.updateOverlayChange();
        });
    }

    private _overlayLandscapeLeftRef: ElementRef;
    @ViewChild('overlayLandscapeLeft')
    set overlayLandscapeLeft(h: ElementRef) {
        this._overlayLandscapeLeftRef = h;
        const e = this._overlayLandscapeLeftRef.nativeElement;
        const eOb = fromEvent(e, 'input');

        eOb.pipe(
            debounceTime(200),
            takeUntil(this._allUnsubscribe)
        ).subscribe((e: any) => {
            this._info.landscape.left = e.target.value;
            this.updateOverlayChange();
        });
    }

    private _overlayLandscapeTopRef: ElementRef;
    @ViewChild('overlayLandscapeTop')
    set overlayLandscapeTop(h: ElementRef) {
        this._overlayLandscapeTopRef = h;
        const e = this._overlayLandscapeTopRef.nativeElement;
        const eOb = fromEvent(e, 'input');

        eOb.pipe(
            debounceTime(200),
            takeUntil(this._allUnsubscribe)
        ).subscribe((e: any) => {
            this._info.landscape.top = e.target.value;
            this.updateOverlayChange();
        });
    }

    private _overlayPortraitWidthRef: ElementRef;
    @ViewChild('overlayPortraitWidth')
    set overlayPortraitWidth(h: ElementRef) {
        this._overlayPortraitWidthRef = h;
        const e = this._overlayPortraitWidthRef.nativeElement;
        const eOb = fromEvent(e, 'input');

        eOb.pipe(
            debounceTime(200),
            takeUntil(this._allUnsubscribe)
        ).subscribe((e: any) => {
            this._info.portrait.width = e.target.value;
            this.updateOverlayChange();
        });
    }

    private _overlayPortraitHeightRef: ElementRef;
    @ViewChild('overlayPortraitHeight')
    set overlayPortraitHeight(h: ElementRef) {
        this._overlayPortraitHeightRef = h;
        const e = this._overlayPortraitHeightRef.nativeElement;
        const eOb = fromEvent(e, 'input');

        eOb.pipe(
            debounceTime(200),
            takeUntil(this._allUnsubscribe)
        ).subscribe((e: any) => {
            this._info.portrait.height = e.target.value;
            this.updateOverlayChange();
        });
    }

    private _overlayPortraitLeftRef: ElementRef;
    @ViewChild('overlayPortraitLeft')
    set overlayPortraitLeft(h: ElementRef) {
        this._overlayPortraitLeftRef = h;
        const e = this._overlayPortraitLeftRef.nativeElement;
        const eOb = fromEvent(e, 'input');

        eOb.pipe(
            debounceTime(200),
            takeUntil(this._allUnsubscribe)
        ).subscribe((e: any) => {
            this._info.portrait.left = e.target.value;
            this.updateOverlayChange();
        });
    }

    private _overlayPortraitTopRef: ElementRef;
    @ViewChild('overlayPortraitTop')
    set overlayPortraitTop(h: ElementRef) {
        this._overlayPortraitTopRef = h;
        const e = this._overlayPortraitTopRef.nativeElement;
        const eOb = fromEvent(e, 'input');

        eOb.pipe(
            debounceTime(200),
            takeUntil(this._allUnsubscribe)
        ).subscribe((e: any) => {
            this._info.portrait.top = e.target.value;
            this.updateOverlayChange();
        });
    }

    @Output() onOverlayQRCodeChanged = new EventEmitter<AppStartOverlayInfo>();

    constructor(private constantSvc: ConstantService) {
    }

    ngOnInit(): void {
        this._info = this._config[this.constantSvc.DEVKEY_APPSTART_OVERLAY].value;
        if (this._info) {
            this._enableState = this._info.keep ? TriState.Undetermin : (this._info.enabled ? TriState.Positive : TriState.Negative);
        }
    }

    ngOnDestroy(): void {
        this._allUnsubscribe.next();
        this._allUnsubscribe.complete();
    }

    onQRCodeTriToggleChanged(state: TriState): void {
        if (state === TriState.Undetermin) {
            this._info.keep = true;
        }
        else {
            this._info.keep = false;
            this._info.enabled = state === TriState.Positive ? true : false;
        }
        this.updateOverlayChange();
    }

    enableQRCode(checked: boolean): void {
        if (this._info.enabled !== checked) {
            this._info.enabled = checked;
            this.updateOverlayChange();
        }
    }

    enableLandscape(checked: boolean): void {
        if (this._info.landscape.enabled !== checked) {
            this._info.landscape.enabled = checked;
            this.updateOverlayChange();
        }
    }

    enablePortrait(checked: boolean): void {
        if (this._info.portrait.enabled !== checked) {
            this._info.portrait.enabled = checked;
            this.updateOverlayChange();
        }
    }

    updateOverlayChange(): void {
        this.onOverlayQRCodeChanged.emit(this._info);
    }
}