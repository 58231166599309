<div class="list-group mt-2">
    <div class="list-group-item list-group-header">
        <div class="row">
            <div class="col title">
                Resolved on
            </div>
            <div class="col title">
                Type
            </div>
            <div class="col title">
                Description
            </div>
        </div>
    </div>
    <div *ngIf="!_isLoading; else templateLoadingClosedAlertList"
        class="list-group-content list-group-hoverless list-group-content-nr-3">
        <div *ngFor="let a of _notificationList" class="list-group-item">
            <div class="row align-items-center">
                <div class="col text-break">
                    <span class="ml-2">{{ a.lastDateTime | date:'yyyy-MM-dd HH:mm:ss Z' }}</span>
                </div>
                <div class="col text-break">
                    {{ a.type }}
                </div>
                <div class="col text-break">
                    {{ a.subject }}
                </div>
            </div>
        </div>
    </div>
    <ng-template #templateLoadingClosedAlertList>
        <div class="loading">
            <p>Please wait...</p>
            <div class="loading-box"></div>
        </div>
    </ng-template>
</div>
<div class="d-flex align-items-center mt-1">
    <div class="ml-auto text-muted font-italic">
        total : {{ _total }}
    </div>
    <div class="ml-2">
        <na-pager [currentPage]="_currentPage" [numberInPage]="30" [total]="_total"
            (onPageChanged)="onPageChange($event)">
        </na-pager>
    </div>
</div>