import { IVirtualDeviceCalendarItem } from "../../API/v1/VirtualDevice/virtualDevice.common";
import { WEEKDAY_ABBR_LIST } from "../../lib/common/helper.lib";

export enum MaintenanceScheduleMode {
    period = 'period',
    always = 'always'
}

export class MaintenancePlaybackInfo {
    enabled: boolean;
    url: string;
    scheduleMode: MaintenanceScheduleMode;
    beginDate: string;
    endDate: string;
    beginTime: string;
    endTime: string;

    constructor(rawData?: string) {
        let useDefault: boolean = true;
        if (rawData) {
            try {
                const d = JSON.parse(rawData);
                if (d) {
                    this.enabled = d.enabled;
                    this.url = d.url;
                    this.scheduleMode = MaintenanceScheduleMode[d.scheduleMode];
                    this.beginTime = d.beginTime;
                    this.endTime = d.endTime;
                    this.beginDate = d.beginDate;
                    this.endDate = d.endDate;
                    useDefault = false;
                }
            }
            catch {
                useDefault = true;
            }
        }

        if (useDefault) {
            this.enabled = false;
            this.url = null;
            this.scheduleMode = MaintenanceScheduleMode.period;
            this.beginTime = '00:00';
            this.endTime = '05:00';
            this.beginDate = '';
            this.endDate = '';
        }
    }

    get isValid(): boolean {
        if (this.enabled) {
            if (!this.url) {
                return false;
            }

            if (!this.isPlaybackTimePeriodScheduleValid()) {
                return false;
            }

            if (!this.isPlaybackDatePeriodScheduleValid()) {
                return false;
            }
        }

        return true;
    }

    isPlaybackTimePeriodScheduleValid(): boolean {
        return this.enabled && this.beginTime == this.endTime ? false : true;
    }

    isPlaybackDatePeriodScheduleValid(): boolean {
        return this.enabled && this.scheduleMode === MaintenanceScheduleMode.period && (!this.beginDate || !this.endDate || (this.beginDate > this.endDate)) ? false : true;
    }

    copy(): MaintenancePlaybackInfo {
        const m: MaintenancePlaybackInfo = new MaintenancePlaybackInfo();
        m.enabled = this.enabled;
        m.scheduleMode = this.scheduleMode;
        m.url = this.url;
        m.beginDate = this.beginDate;
        m.endDate = this.endDate;
        m.beginTime = this.beginTime;
        m.endTime = this.endTime;

        return m;
    }

    transformToRawdata(): IVirtualDeviceCalendarItem {
        if (!this.enabled) {
            return null;
        }

        const calendar: IVirtualDeviceCalendarItem = {
            metaData: { rawData: JSON.stringify(this) },
            type: 'maintenance',
            periodList: [],
            resource: {
                data: this.url
            }
        };

        if (this.enabled) {
            if (this.beginTime < this.endTime) {
                calendar.periodList.push({
                    type: 'daily',
                    dayList: WEEKDAY_ABBR_LIST.map(d => d.toUpperCase()),
                    startTime: this.beginTime + ':00',
                    endTime: this.endTime + ':00',
                    startDate: this.scheduleMode === MaintenanceScheduleMode.period ? this.beginDate : null,
                    endDate: this.scheduleMode === MaintenanceScheduleMode.period ? this.endDate : null
                });
            }
            else {
                //across a day, ex: 20:00:00 ~ 05:00:00 should divide to 20:00:00 ~ 23:59:59 and 00:00:00 ~ 05:00:00
                calendar.periodList.push({
                    type: 'daily',
                    dayList: WEEKDAY_ABBR_LIST.map(d => d.toUpperCase()),
                    startTime: this.beginTime + ':00',
                    endTime: '23:59:59',
                    startDate: this.scheduleMode === MaintenanceScheduleMode.period ? this.beginDate : null,
                    endDate: this.scheduleMode === MaintenanceScheduleMode.period ? this.endDate : null
                });
                calendar.periodList.push({
                    type: 'daily',
                    dayList: WEEKDAY_ABBR_LIST.map(d => d.toUpperCase()),
                    startTime: '00:00:00',
                    endTime: this.endTime + ':00',
                    startDate: this.scheduleMode === MaintenanceScheduleMode.period ? this.beginDate : null,
                    endDate: this.scheduleMode === MaintenanceScheduleMode.period ? this.endDate : null
                });
            }
        }

        return calendar;
    }

    static equal(a: MaintenancePlaybackInfo, b: MaintenancePlaybackInfo): boolean {
        if (!a && !b) {
            return true;
        }

        if ((!a && b) || (a && !b)) {
            return false;
        }

        if (a.enabled !== b.enabled) {
            return false;
        }

        if (a.scheduleMode !== b.scheduleMode) {
            return false;
        }

        if (a.beginTime !== b.beginTime || a.endTime !== b.endTime) {
            return false;
        }

        if (a.beginDate !== b.beginDate || a.endDate !== b.endDate) {
            return false;
        }

        if (a.url !== b.url) {
            return false;
        }

        return true;
    }
}