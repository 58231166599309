<div class="row mb-2 pb-2 firmware-family-content">
  <div *ngIf="!_specific" class="col-lg-5 col-12 px-lg-2 px-0">
    <strong class="mb-1">Device(s) : </strong>
    <div class="list-group mb-2">
      <div class="list-group-item list-group-header black">
        <div class="row">
          <div class="col title d-flex align-items-center">
            <input type="checkbox" id="firmware-family-{{ _familyInfo.familyId }}-th" class="cmn-checkbox"
              [checked]="is_all_device_select()" (change)="select_all_device($event)">
            <label for="firmware-family-{{ _familyInfo.familyId }}-th">
              <svg>
                <use xlink:href="#checkmark" />
              </svg>
              <span class="sr-only">Select all devices</span>
            </label>
            <span class="ml-1">Device name</span>
          </div>
          <div class="col title">Firmware version</div>
        </div>
      </div>
      <div class="list-group-content list-group-content-modal-nr-3">
        <div *ngFor="let device of _familyInfo.devices;" class="list-group-item list-group-item-action">
          <div class="row align-items-center">
            <div class="col d-flex align-items-center text-break">
              <input [id]="'firmware-update-' + device.virtualId" type="checkbox" class="cmn-checkbox"
                [checked]="device.isSelect" (change)="select_single_device(device)" />
              <label [for]="'firmware-update-' + device.virtualId">
                <svg>
                  <use xlink:href="#checkmark" />
                </svg>
                <span class="sr-only">Select device {{device?.virtualName}}</span>
              </label>
              <span class="ml-1">{{ device?.virtualName }}</span>
            </div>
            <div class="col text-break">
              {{ device?.currentSettings[constantSvc.DEVKEY_INFO_FW] }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-7 col-12 px-1" [ngClass]="_specific ? 'col-lg-12' : ''">
    <div>
      <strong class="mb-1">Update method
        <ng-template [ngIf]="_specific">
          for device "{{_familyInfo.devices[0].virtualName}}"
        </ng-template> :
      </strong>
      <div class="d-flex mb-2">
        <button type="button" class="btn btn-sm btn-action btn-light update-choice form-control"
          [ngClass]="_familyInfo.updateMethod === FIRMWARE_UPDATE_METHOD.Latest ? 'active' : ''"
          (click)="changeUpdateMethod(FIRMWARE_UPDATE_METHOD.Latest)">
          <i class="fas fa-check"></i>
          Latest version
        </button>
        <button type="button" class="btn btn-sm btn-action btn-light update-choice form-control"
          [ngClass]="_familyInfo.updateMethod === FIRMWARE_UPDATE_METHOD.FromURL ? 'active' : ''"
          (click)="changeUpdateMethod(FIRMWARE_UPDATE_METHOD.FromURL)">
          <i class="fas fa-check"></i>
          Specific URL
        </button>
      </div>
    </div>
    <div class="update-block border p-2">
      <!-- unknown method -->
      <div [ngClass]="_familyInfo.updateMethod === FIRMWARE_UPDATE_METHOD.Unknown ? '' : 'inactive'"
        class="text-muted text-center">
        Please choose an update method
      </div>
      <!-- Latest firmware -->
      <div [ngClass]="_familyInfo.updateMethod === FIRMWARE_UPDATE_METHOD.Latest ? '' : 'inactive'">
        <div class="float-right">
          <button type="button" class="btn btn-sm btn-action btn-light" [disabled]="_isLoadingLatestFirmwareInfo"
            (click)="refreshLatestFirmwareInfo()">Refresh</button>
        </div>
        <div *ngIf="!_isLoadingLatestFirmwareInfo; else templateLoadingFirmwareInfo">
          <div *ngIf="_familyInfo.updateInfo; else templateNoFirmwareInfo">
            <div>
              <strong>Version</strong>
              <span>&nbsp;:&nbsp;{{ _familyInfo.updateInfo?.firmwareVersion }}</span>
            </div>
            <div class="mt-1">
              <strong>Release date</strong>
              <span>&nbsp;:&nbsp;{{ _familyInfo.updateInfo?.releaseDate }}</span>
            </div>
            <div class="mt-1">
              <strong>Detail</strong>
              <span>&nbsp;:&nbsp;</span>
            </div>
            <div class="firmware-notice" innerHTML="{{ _familyInfo.updateInfo?.notice }}"></div>
          </div>
          <ng-template #templateNoFirmwareInfo>
            <div class="text-center text-danger">
              Could not find update information.
            </div>
          </ng-template>
        </div>
        <ng-template #templateLoadingFirmwareInfo>
          <div class="loading">
            <div class="loading-box mx-auto"></div>
          </div>
        </ng-template>
      </div>
      <!-- Customize firmware url -->
      <div [ngClass]="_familyInfo.updateMethod === FIRMWARE_UPDATE_METHOD.FromURL ? '' : 'inactive'">
        <label for="input-custom-firmware-url">Provide the download URL for your firmware file</label>
        <input #customUrl id="input-custom-firmware-url" type="text" class="form-control download-url"
          placeholder='http(s)://your.firmware.link/' [(ngModel)]="_familyInfo.customURL">
      </div>
    </div>
  </div>
</div>