
export class PowerSaveInfo {
    enabled: boolean;
    timeout: number; // 1 ~ 300 minutes
    mode: string; //sleep | shutdown

    constructor() {
        this.enabled = false;
        this.timeout = 1;
        this.mode = 'sleep';
    }
}