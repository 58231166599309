import { HttpClient } from '@angular/common/http';

import { APIWrapperBase } from '../../api.wrapper.base';
import { APICancelTicketManager } from './api.ticket.cancel';
import { APICreateTicketManager } from './api.ticket.create';
import { APIGetTicketManager } from './api.ticket.get';
import { APIListTicketManager } from './api.ticket.list';

export class APITicketWrapper extends APIWrapperBase {
    CreateTicket: APICreateTicketManager;
    GetTicket: APIGetTicketManager;
    ListTicket: APIListTicketManager;
    CancelTicket: APICancelTicketManager;

    constructor(private http: HttpClient) {
        super();

        this.CreateTicket = new APICreateTicketManager(http);
        this.GetTicket = new APIGetTicketManager(http);
        this.ListTicket = new APIListTicketManager(http);
        this.CancelTicket = new APICancelTicketManager(http);
    }

    public resetServerConfig(protocol: string, baseUrl: string, port: number): void {
        let targets = [
            this.CreateTicket, 
            this.GetTicket, 
            this.ListTicket,
            this.CancelTicket
        ];

        targets.forEach(t => t.resetServerConfig(protocol, baseUrl, port));
    }
}