export const DEVICE_GROUP_ID_ROOT: string = 'g-root';
export const DEVICE_GROUP_ID_HOME: string = 'g-home';
export const DEVICE_GROUP_ID_DEFAULT: string = 'g-default';
export const DEVICE_GROUP_NAME_DEFAULT: string = 'Default group';

export const DEVICE_GROUP_FUNC_CREATE: string = 'group-create';
export const DEVICE_GROUP_FUNC_DELETE: string = 'group-delete';
export const DEVICE_GROUP_FUNC_MOVE: string = 'group-move';
export const DEVICE_GROUP_FUNC_EXCLUDE: string = 'group-exclude';

export enum GroupSwitch {
    on = 'on',
    off = 'off'
}

export enum DeviceGroupMode {
    edit = 'edit',
    pickone = 'pickone',
    pickmulti = 'pickmulti',
    viewonly = 'viewonly',
    pickByAdmin = 'pickByAdmin',
}

export enum DeviceGroupType {
    group = 'group',
    device = 'device'
}

export class DeviceGroupInfo {
    private _name: string;
    set name(name: string) {
        this._name = name;
    }
    get name(): string {
        return this._name;
    }

    private _id: string;
    get id(): string {
        return this._id;
    }
    private _childs: DeviceGroupInfo[];
    get childs(): DeviceGroupInfo[] {
        return this._childs;
    }
    get subgroups(): DeviceGroupInfo[] {
        return this._childs.filter(c => c.type === DeviceGroupType.group);
    }

    get allDescendantDeviceLength(): number {
        let counter: number = 0;
        this.childs.forEach(c => {
            counter += c.type === DeviceGroupType.device ? 1 : c.allDescendantDeviceLength;
        });

        return counter;
    }

    private _parentID: string;
    set parentID(id: string) {
        this._parentID = id;
    }
    get parentID(): string {
        return this._parentID;
    }

    get isPolicyApply(): boolean {
        return this.policies.Configuration.length + this.policies.Security.length > 0 ? true : false;
    }

    type: DeviceGroupType = DeviceGroupType.group;
    expanded?: boolean;
    active?: boolean; //only one group could be active at a time
    hovered?: boolean;
    selected?: boolean;
    removable?: boolean;
    movable?: boolean;
    data?: any;
    policies?: {
        //policy id list
        Configuration: string[];
        Security: string[];
        Application: string[];
        FirmwareUpdate: string[];
    }

    constructor(id: string, parentID: string, name: string, type: DeviceGroupType = DeviceGroupType.group, policies: { Configuration?: string[], Security?: string[], Application?: string[], FirmwareUpdate?: string[] }, removable: boolean = true, movable: boolean = true, expanded: boolean = false, data?: any) {
        this._id = id;
        this._parentID = parentID;
        this._name = name;
        this.type = type;
        this.data = data;
        this.expanded = expanded === undefined ? false : expanded;
        this.active = false;
        this.hovered = false;
        this.selected = false;
        this.removable = removable === undefined ? true : removable;
        this.movable = movable === undefined ? true : movable;
        this._childs = [];
        this.policies = {
            Configuration: policies && policies.Configuration ? policies.Configuration : [],
            Security: policies && policies.Security ? policies.Security : [],
            Application: policies && policies.Application ? policies.Application : [],
            FirmwareUpdate: policies && policies.FirmwareUpdate ? policies.FirmwareUpdate : []
        };
    }

    addChild(child: DeviceGroupInfo): void {
        this._childs.push(child);
    }

    cleanChilds(): void {
        this._childs = [];
    }

    removeChildByID(id: string): void {
        const c: DeviceGroupInfo = this._childs.find(c => c.id === id);
        if (c) {
            this._childs.splice(this._childs.indexOf(c), 1);
        }
    }

    removeChild(c: DeviceGroupInfo): void {
        const index: number = this._childs.indexOf(c);
        if (index >= 0) {
            this._childs.splice(index, 1);
        }
    }

    copy(): DeviceGroupInfo {
        const c: DeviceGroupInfo = new DeviceGroupInfo(this._id, this._parentID, this._name, this.type, this.policies, this.removable, this.movable, this.expanded, this.data);
        this._childs.forEach(c => c.addChild(c));

        return c;
    }
}