import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../../api.base';
import { IRemoteCtrlRTCServer, IRemoteCtrlSignalServer } from './api.remoteCtrl.model';

export interface IStartRemoteCtrlPathParameter {
    virtualDeviceID: string;
    virtualDevicePairedID: string;
}

export interface IStartRemoteCtrlRxData {
    room: string;
    duration: number;
    signalingServer: IRemoteCtrlSignalServer;
    serverList: IRemoteCtrlRTCServer[];
    keepAliveToken: string;
    ticket: string;
}

export class APIStartRemoteCtrlManager extends APIBaseManager<IStartRemoteCtrlPathParameter, void, void, IStartRemoteCtrlRxData> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_StartRemoteCtrl';
        this._apiMethod = 'POST';
    }

    protected getRequestURL(pathParameters: IStartRemoteCtrlPathParameter, queryParameters: void): string {
        return `${super.getRequestURL()}virtualDevices/${pathParameters.virtualDeviceID}/${pathParameters.virtualDevicePairedID}/remoteControl`;
    }
}