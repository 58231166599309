import { Pipe, PipeTransform } from '@angular/core';

import { TextLib } from '../common/text.lib';

@Pipe({
    name: 'extractFirstWord'
})
export class ExtractFirstWordPipe implements PipeTransform {
    transform(value: string, ...args: string[]): any {
        return TextLib.capitalFirstWord(value).substr(0, 1);
    }
}