import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../../api.base';

export interface IAssignPolicyPathParameter {
    devicePolicyID: string;
}

export interface IAssignPolicyTxData {
    assign?: {
        deviceGroupIDList: string[];
        virtualDeviceIDList?: string[];
    };
    remove?: {
        deviceGroupIDList: string[];
        virtualDeviceIDList?: string[];
    }
}

export class APIAssignPolicyManager extends APIBaseManager<IAssignPolicyPathParameter, void, IAssignPolicyTxData, any> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_AssignPolicy';
        this._apiMethod = 'POST';
    }

    protected getRequestURL(pathParameters: IAssignPolicyPathParameter, queryParameters: void): string {
        return super.getRequestURL() + 'devicePolicies/' + pathParameters.devicePolicyID + '/assign';
    }
}