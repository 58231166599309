<label [for]="'regEntItem-' + _id">
    <span class="txt-bold">{{ _title }}</span>
    <i class="fas fa-file-upload icon-file ml-2" (click)="selectFile()"></i>
    <input #imgFile type="file" [attr.accept]="_fileFormat" class="d-none" [disabled]="_disabled"
        (click)="onFileClick($event)" (change)="onFileChange($event)">
    <small class="blue-2 ml-2">(maximum file size: {{ _maxAllowSize }} MB)</small>
</label>
<div [id]="'regEntItem-' + _id" class="img-tile" [class.updated]="_data.isChange()" [style.background]="_bgColor">
    <img [src]="_data.urlAfter | safe: 'url'">
    <i class="fas fa-undo undo" data-target="tooltip" title="Reset" [style.color]="_iconColor" (click)="reset()"></i>
    <i class="fas fa-tags tag" data-target="tooltip" title="File information" [style.color]="_iconColor"></i>
    <div class="details">
        <div>name : {{ _data.name }}</div>
        <div>size : {{ _data.size }} </div>
    </div>
</div>
<small *ngIf="_note" class="d-block text-center">{{ _note }}</small>