import { Component } from '@angular/core';

import { DialogPage } from '../../../../../lib/common/common.data';
import { UserBaseDlgComponent } from '../user-dlg-base.component';
import { USER_DLG_FUNC_INVITE_BULK_CONTENT } from '../user-dlg-func.def';
import { UserInfo } from '../../user.data';

@Component({
    templateUrl: '../user-dlg-base.component.html',
    styleUrls: ['../../user.style.css']
})
export class UserBatchInviteDlgComponent extends UserBaseDlgComponent<UserInfo[], { usersFromCsv?: UserInfo[], message?: string }> {

    ngOnInit(): void {
        this._action_txt = 'Submit';
        this._action_icon = 'fa-envelope-open';
        this._content_func_name = USER_DLG_FUNC_INVITE_BULK_CONTENT;
        
        this._page = DialogPage.action;

        this.other = {
            usersFromCsv: [],
            message: ''
        };

        super.ngOnInit();
    }

    submit(): void {
        this._page = DialogPage.submit;
        const errorList: string[] = [];

        this.userSvc.inviteMultipleUsers(this.other.usersFromCsv, this.other.message).subscribe((res: { user: UserInfo, hasNext: boolean, isFault: boolean, errorMessage?: string }) => {
            if (res.isFault) {
                errorList.push(res.errorMessage);
            }

            if (!res.hasNext) {
                this._page = DialogPage.result;
                if (errorList.length > 0) {
                    this._errorMessage = errorList.join(', ');
                }
            }
        });
    }
}