import { Component, OnInit, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { Subject, of as observableOf } from 'rxjs';
import { takeUntil, map, concatMap } from 'rxjs/operators';

import { DeviceGroupFuncInterface } from './group-func.def';
import { DeviceGroupInfo, DeviceGroupType } from '../group.data';
import { DeviceGroupService } from '../dev-group.service';
import { AccountService } from '../../../../entry/account.service';
import { PolicyService } from '../../../setting/policy/policy.service';
import { UserService } from '../../../admin/user/user.service';


@Component({
    templateUrl: './group-dlg-delete.component.html'
})
export class DeviceGroupDeleteDlgComponent implements DeviceGroupFuncInterface, OnInit, OnDestroy {
    title: string;
    group: DeviceGroupInfo;
    _updating: boolean;

    _deleteList: DeviceGroupInfo[] = [];
    _errorMessage: string;
    _enumGroupType: typeof DeviceGroupType = DeviceGroupType;
    private _allUnsubscribe: Subject<void> = new Subject();

    private _dlgCloseElementRef: ElementRef;
    @ViewChild('dlgClose', { static: true })
    set dlgClose(holder: ElementRef) {
        if (holder) {
            this._dlgCloseElementRef = holder;
        }
    }

    constructor(private accountSvc: AccountService, private groupSvc: DeviceGroupService, private policySvc: PolicyService, private userSvc: UserService) { }

    ngOnInit(): void {
        this.accountSvc.loginChanged.pipe(
            takeUntil(this._allUnsubscribe)
        ).subscribe((isLogin: boolean) => {
            if (!isLogin) {
                this._dlgCloseElementRef.nativeElement.click();
            }
        });

        if (this.group) {
            const selectedChildList: DeviceGroupInfo[] = this.group.childs.filter(c => c.selected || (c.data ? c.data.isSelect : false));
            this._deleteList = selectedChildList.length === 0 ? [this.group] : selectedChildList;
        }
    }

    ngOnDestroy(): void {
        this._allUnsubscribe.next();
        this._allUnsubscribe.complete();
    }

    deleteGroup(): void {
        this._errorMessage = '';
        this._updating = true;

        this.groupSvc.deleteGroup(null, this.group, this._deleteList).pipe(
            concatMap((res: { isFault: boolean, errorMessage?: string }) => {
                if (!res.isFault) {
                    return this.policySvc.getPolicyList(true).pipe(
                        map(() => {
                            return res;
                        })
                    );
                }
                
                return observableOf(res);
            })
        ).subscribe((res: { isFault: boolean, errorMessage?: string }) => {
            this._updating = false;

            if (res.isFault) {
                this._errorMessage = res.errorMessage;
                return;
            }

            this.userSvc.updateUserGroupCache();

            setTimeout(() => {
                this._dlgCloseElementRef.nativeElement.click();
            }, 0);

        });
    }
}