import { query } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../api.base';
import { ITicketData } from './api.ticket.common';

export interface IListTicketRxData {
    skip: number;
    limit: number;
    total: number;
    itemList: ITicketData[];
}

export interface IListTicketQueryParameter {
    limit?: number;
    skip?: number;
    search?: {
        ticketAction?: string;
        ticketStatusCode?: string;
        ticketSubject?: string;
        taskAction?: string;
        //should appear together
        fromDate?: string;
        toDate?: string;
        success?: boolean;
    } | string;
}

export class APIListTicketManager extends APIBaseManager<void, IListTicketQueryParameter, void, IListTicketRxData> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_ListTicket';
        this._apiMethod = 'GET';
    }

    protected getRequestURL(pathParameters: void, queryParameters: IListTicketQueryParameter): string {
        if (queryParameters.search && queryParameters.search instanceof Object) {
            if (Object.keys(queryParameters.search).length > 0) {
                queryParameters.search = JSON.stringify(queryParameters.search);
            }
            else {
                delete queryParameters.search;
            }
        }

        return super.getRequestURL() + 'accounts/tickets' + this.transformObjectQueryParameterToStr(queryParameters);
    }
}