import { Component } from '@angular/core';

import { BaseConfigFormComponent } from '../base/base-config-form.component';
import { DIALOG_NAME_REBOOT, ConfigDialogPage } from '../base/base-config-data';
import { LicenseScopeType } from '../../license/license.data';
import { HelperLib, SharePermissionType } from '../../../lib/common/helper.lib';

@Component({
    templateUrl: '../base/base-config-form.component.html',
    styleUrls: ['../base/base-config-form.component.css', './reboot-form.component.css']
})
export class RebootFormComponent extends BaseConfigFormComponent {
    ngOnInit(): void {
        this._dialogName = DIALOG_NAME_REBOOT;
        this._licenseScopeType = LicenseScopeType.taskConfig;
        this._sharePermissionType = SharePermissionType.Reboot;

        super.ngOnInit();
    }

    allowGoBack(): boolean {
        const isAllowBack = super.allowGoBack();
        if (isAllowBack) {
            if (this._page === ConfigDialogPage.confirm) {
                return false;
            }
        }

        return isAllowBack;
    }

    protected goNext(fromPage?: ConfigDialogPage): void {
        let from: ConfigDialogPage = this._page;
        if (from === ConfigDialogPage.checking) {
            //reboot do not need action page.
            from = ConfigDialogPage.action;
        }

        super.goNext(from);
    }

    protected goBack(): void {
        let from: ConfigDialogPage = this._page;
        if (from === ConfigDialogPage.confirm) {
            //reboot do not need action page.
            from = ConfigDialogPage.action;
        }

        super.goBack(from);
    }

    protected submit(): void {
        super.submit();

        this.devSvc.batchReboot(this._bSpecific ? this._legalDevices : this._legalDevices.filter(d => d.isSelect)).subscribe(res => {
            this._page++;
            if (res.isFault()) {
                this._errorMessage = HelperLib.getErrorMessage(res);
            }

            if (this.dialogCompleteHandler) {
                this.dialogCompleteHandler(res);
            }
        });
        
    }
}