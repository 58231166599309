import { Component } from '@angular/core';

import { DialogPage } from '../../../../../lib/common/common.data';
import { UserBaseDlgComponent } from '../user-dlg-base.component';
import { USER_DLG_FUNC_RESETPWD_CONTENT } from '../user-dlg-func.def';
import { UserInfo } from '../../user.data';

@Component({
    templateUrl: '../user-dlg-base.component.html',
    styleUrls: ['../../user.style.css']
})
export class UserResetPwdDlgComponent extends UserBaseDlgComponent<UserInfo[], void> {

    ngOnInit(): void {
        this._action_txt = 'Send email';
        this._action_icon = 'fa-envelope';
        this._content_func_name = USER_DLG_FUNC_RESETPWD_CONTENT;

        this._isDataValid = true;
        this._page = DialogPage.action;

        super.ngOnInit();
    }

    submit(): void {
        this._page = DialogPage.submit;
        this._errorMessage = null;

        this.userSvc.resetUserPassword(this.data[0]).subscribe((res: { isFault: boolean, errorMessage?: string }) => {
            this._errorMessage = res.errorMessage;
            this._page = DialogPage.result;
        });
    }
}