import { Component, OnInit } from '@angular/core';

import { DeviceInfo } from '../../../device/data/device-info';
import { DevFuncConfirmInterface } from '../../base/confirm/dev-func-confirm.interface';
import { HelperLib } from '../../../../lib/common/helper.lib';

@Component({
    templateUrl: './share-confirm-subform.component.html',
    styleUrls: ['../../base/base-config-form.component.css', './share-confirm-subform.component.css']
})
export class ShareConfirmSubFormComponent implements DevFuncConfirmInterface, OnInit {
    _devices: DeviceInfo[];
    _selectDevice: DeviceInfo;
    _shareSettingMap: {
        [virtualDeviceID: string]: {
            device: DeviceInfo,
            unchanged: { accountID: string, accountName: string, permission: string[], action?: string }[],
            changed: { accountID: string, accountName: string, permission: string[], action?: string }[],
            added: { accountID: string, accountName: string, permission: string[], action?: string }[],
            removed: { accountID: string, accountName: string, permission: string[], action?: string }[]
        }
    } = {};
    _availablePermissions: { permission: string, displayName: string }[];
    _hasChange: boolean = false;

    private _actionData: any;
    set actionData(data: any) {
        this._actionData = data;
        if (this._actionData) {
            this._shareSettingMap = {};
            this._devices = this._actionData.devices;
            this._selectDevice = this._devices && this._devices.length > 0 ? this._devices[0] : null;

            const usedAccountMap: { [account: string]: boolean } = {};
            this._devices.forEach(d => {
                this._shareSettingMap[d.virtualId] = {
                    device: d,
                    unchanged: [],
                    changed: [],
                    added: [],
                    removed: []
                };

                Object.keys(this._actionData.commonShareInfoMap).forEach((accountID: string) => {
                    switch (this._actionData.commonShareInfoMap[accountID].action) {
                        case '+':
                            {
                                this._shareSettingMap[d.virtualId].added.push(this._actionData.commonShareInfoMap[accountID]);
                                this._hasChange = true;
                            }
                            break;
                        case '-':
                            {
                                this._shareSettingMap[d.virtualId].removed.push(this._actionData.commonShareInfoMap[accountID]);
                                this._hasChange = true;
                            }
                            break;
                        case '~':
                            {
                                this._shareSettingMap[d.virtualId].changed.push(this._actionData.commonShareInfoMap[accountID]);
                                this._hasChange = true;
                            }
                            break;
                        default:
                            {
                                this._shareSettingMap[d.virtualId].unchanged.push(this._actionData.commonShareInfoMap[accountID]);
                            }
                            break;
                    }

                    usedAccountMap[this._actionData.commonShareInfoMap[accountID].accountName] = true;
                    usedAccountMap[accountID] = true;

                });

                Object.keys(d.virtualDeviceSharing).forEach((accountID: string) => {
                    if (usedAccountMap[accountID] || usedAccountMap[d.virtualDeviceSharing[accountID].accountName]) {
                        return;
                    }

                    this._shareSettingMap[d.virtualId].unchanged.push(d.virtualDeviceSharing[accountID]);
                })
            });

            this._actionData.confirmShareInfoMap = this._shareSettingMap;
        }
    }

    set allowSubmitCallback(fn: (isAllow: boolean) => void) {
        fn(this._hasChange);
    }

    constructor() { }

    ngOnInit(): void {
        this._availablePermissions = HelperLib.AdjustableSharePermissionList;
    }

    selectDevice(device: DeviceInfo): void {
        this._selectDevice = device;
    }
}