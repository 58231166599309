<div class='modal-dialog config-dlg'>
    <div class='modal-content'>
        <div class='modal-header'>
            <span class="modal-title">{{ title }}</span>
            <button #dlgClose type="button" class="close" [disabled]="_updating" data-dismiss="modal">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class='modal-body'>
            <div>
                <p>Are you sure to remove these devices/groups ?</p>
                <div class="border py-1 px-2">
                    <div *ngFor="let g of _deleteList">
                        <i *ngIf="g.type === _enumGroupType.device" class="fas fa-inbox green-3"></i>
                        <i *ngIf="g.type === _enumGroupType.group" class="fas fa-folder"></i>
                        <span class="ml-2">{{ g.name }}</span>
                    </div>
                    <div *ngIf="_deleteList.length === 0" class="red-1">Please select a group / device.</div>
                </div>
                <div class="mt-4">
                    <small class="d-block green-3">*** All of its child groups will be removed.</small>
                    <small class="d-block green-3">*** All of its child devices will be moved to group 'Default group'</small>
                </div>
            </div>
            <div *ngIf="_errorMessage" class="mt-2 warning-block">{{ _errorMessage }}</div>
            <div *ngIf="_updating" class="progress mt-2">
                <div class="progress-bar progress-bar-success progress-bar-striped active" role="progressbar"
                    aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%">
                    Updating the changes... Please wait...
                </div>
            </div>
            <div class='row control-block d-flex justify-content-center'>
                <button type="button" class="btn btn-sm btn-action btn-light" [disabled]="_updating" data-dismiss="modal">
                    <span class="fas fa-times-circle icon-left"></span>
                    {{ 'key-cancel' | translate | capitalFirstWord }}
                </button>
                <button type="button" class="btn btn-sm btn-action btn-light"
                    [disabled]="_updating || _deleteList.length === 0" (click)="deleteGroup()">
                    <span class="fas fa-check-circle icon-left"></span>
                    {{ 'key-apply' | translate | capitalFirstWord }}
                </button>
            </div>
        </div>
    </div>
</div>