
import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of as observableOf } from 'rxjs';
import { map } from 'rxjs/operators';

import { AccountService } from './account.service';
import { APIBaseManager } from '../API/api.base';
import { HelperLib } from '../lib/common/helper.lib';
import { Logger } from '../lib/common/logger';
import { NAService } from '../API/na.service';
import { AppConfigService } from '../../app/app.config';
import { IAccountToken } from '../lib/common/common.data';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
    constructor(private naSvc: NAService, private accountSvc: AccountService, private router: Router) {
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
        return this.canActivate(childRoute, state);
    }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
        return this.checkLogin(route, state.url);
    }

    private updateCommonConfig(routeSnapshot: ActivatedRouteSnapshot) {
        if (routeSnapshot && routeSnapshot.queryParamMap) {
            let serverProtocol = routeSnapshot.queryParamMap.get('serverProtocol');
            let serverUrl = routeSnapshot.queryParamMap.get('serverUrl');
            const serverPortStr = routeSnapshot.queryParamMap.get('serverPort');
            const supportLoginRedirectStr = routeSnapshot.queryParamMap.get('supportLoginRedirect');

            let serverPort: number = 0;
            if (serverPortStr) {
                try {

                    serverPort = parseInt(serverPortStr);
                }
                catch {
                }
            }

            if (serverProtocol || serverUrl || serverPortStr) {
                serverProtocol = serverProtocol || AppConfigService.configs.server.api.protocol;
                serverUrl = serverUrl || AppConfigService.configs.server.api.baseUrl;
                serverPort = serverPort || AppConfigService.configs.server.api.port;

                Logger.logInfo('authGuard', 'checkLogin', 'Reset server to ' + serverProtocol + '://' + serverUrl + ':' + serverPort);
                this.naSvc.resetServerConfig(serverProtocol, serverUrl, serverPort);
            }
            if (supportLoginRedirectStr) {
                this.accountSvc.supportLoginRedirect = supportLoginRedirectStr === 'true';
            }
        }
    }

    private checkLogin(routeSnapshot: ActivatedRouteSnapshot, url: string): Observable<boolean> {
        Logger.logInfo('authGuard', 'checkLogin', url);
        const defaultRedirLink: string = './app/device/devices';

        if (url.trim() === ('/')) {
            this.router.navigate([defaultRedirLink]);
            return observableOf(true);
        }

        let redirect: string;
        let token: string;
        if (routeSnapshot && routeSnapshot.queryParamMap) {
            this.updateCommonConfig(routeSnapshot);
            token = routeSnapshot.queryParamMap.get('proxyCode');
            redirect = routeSnapshot.queryParamMap.get('redirect');
        }

        if (token) {
            Logger.logInfo('authGuard', 'checkLogin', 'Use proxy token to login', token);
            APIBaseManager.USER_TOKEN = token;
            const tokenInfo: IAccountToken = HelperLib.parseToken(token);
            if (tokenInfo) {
                this.accountSvc.login(token, tokenInfo);
                this.router.navigate([defaultRedirLink]);
            }
            else {
                this.router.navigate(redirect ? ['./' + redirect] : [defaultRedirLink]);
            }

            return observableOf(true);
        }
        else if (url.trim().startsWith('/account/')) {
            return observableOf(true);
        }
        else if (url.trim().startsWith('/?')) {
            this.router.navigate(redirect ? ['./' + redirect] : [defaultRedirLink]);
            return observableOf(true);
        }
        else {
            return this.accountSvc.checkLogin().pipe(
                map((isLogin: boolean) => {
                    if (isLogin) {
                        return true;
                    }

                    this.accountSvc.redirectUrl = url;
                    this.accountSvc.logout();
                    return false;
                })
            );
        }
    }
}