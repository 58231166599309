import { Component, Input } from '@angular/core';
import { DeviceInfo } from '../../data/device-info';
import { NetworkType } from '../../data/device-net-info';
import { ConstantService } from '../../../../../app/lib/common/constant.service';

@Component({
    selector: 'device-info-net',
    templateUrl: './device-info-net.component.html',
    styleUrls: ['./device.style.css']
})
export class DeviceInfoNetComponent {
    private _device: DeviceInfo;
    _currentNetworkType: NetworkType = NetworkType.None;
    _enumNetworkType: typeof NetworkType = NetworkType;

    @Input('device')
    set device(d: DeviceInfo) {
        this._device = d;
    };
    @Input('networkType')
    set networkType(netType: NetworkType) {
        this._currentNetworkType = netType;
    }

    constructor(public constantSvc: ConstantService) {}
}