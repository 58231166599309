export class CertInfo {
    static CERT_ID: number = 1;

    id: string;
    name: string;
    expiryDate: Date;

    constructor(options?: { id?: string, name?: string }) {
        this.id = options?.id || 'cert-' + CertInfo.CERT_ID++;
        this.name = options?.name || '';
    }
}