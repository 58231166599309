import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { CustomPipeModule } from '../../../lib/pipes/custom-pipe.module';
import { TranslateModule } from '../../../translate/translate.module';

import { UIElementModule } from '../../../uiElement/uiElement.module';
import { AccessKeyService } from './accessKey.service';
import { AccessKeyComponent } from './accessKey.component';
import { AccessKeyCreateComponent } from './form/accessKey-create.component';
import { AccessKeyInactiveComponent } from './form/accessKey-inactive.component';
import { AccessKeyRemoveComponent } from './form/accessKey-remove.component';
import { AccessKeyFuncService } from './accessKey-func.service';
import { AccessKeyFuncDirective } from './accessKey-func.directive';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        CustomPipeModule,
        TranslateModule,
        UIElementModule
    ],
    providers: [
        AccessKeyService,
        AccessKeyFuncService
    ],
    declarations: [
        AccessKeyComponent,
        AccessKeyCreateComponent,
        AccessKeyInactiveComponent,
        AccessKeyRemoveComponent,
        AccessKeyFuncDirective
    ]
})
export class AccessKeyModule { }