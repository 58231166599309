<div>
    <div>Get one time password of the day you choose below :</div>
    <div *ngIf="!_support" class="warning-block my-1">{{ constantSvc.FEATURE_UNSUPPORT }}</div>
    <div class="otp-option-block">
        <div class="cmn-radio">
            <input id="cb-otp-now" type="radio" name="otp-option" [checked]="_config.current.enabled"
                [disabled]="_loading || !_support" (click)="enableCurrentDate()" />
            <label for="cb-otp-now" class="radio"></label>
            <strong class="ml-2">
                Today
            </strong>
            <span class="ml-2">
                ({{ _config.current.date }})
            </span>
        </div>
        <div class="cmn-radio mt-1">
            <input id="cb-otp-past" type="radio" name="otp-option" [checked]="_config.past.enabled"
                [disabled]="_loading || !_support" (click)="enablePastDate()" />
            <label for="cb-otp-past" class="radio"></label>
            <strong class="ml-2">
                A specific date in the past
            </strong>
            <input type="date" class="ml-2" aria-label="Select OTP on specific date in the past"
                [attr.max]="_config.current.date" [disabled]="_loading || !_support"
                (change)="_config.past.date = $event.target.value">
        </div>
        <div class="note red-1 ml-4">
            (If the date on device is in the past, please choose this option)
        </div>
    </div>
</div>
<div *ngIf="_config.errorMessage" class="warning-block mt-2">{{ _config.errorMessage }}</div>
<div *ngIf="!_config.loading; else templateQueryPassword">
    <div *ngIf="_config.otp" class="d-flex align-items-center my-4">
        <span>Password is</span>
        <span class="pwd">{{ _config.otp }}</span>
    </div>
</div>
<ng-template #templateQueryPassword>
    <div class="loading">
        <div class="loading-box"></div>
    </div>
</ng-template>