import { HttpClient } from '@angular/common/http';

import { APIWrapperBase } from '../../api.wrapper.base';
import { APIGetEventLogManager } from './api.eventLog.get';
import { APIListEventLogManager } from './api.eventLog.list';
import { APIListEventLogCategoryManager } from './api.eventLog.category.list';

export class APIEventLogWrapper extends APIWrapperBase {
    ListEventCategory: APIListEventLogCategoryManager;
    ListEventLog: APIListEventLogManager;
    GetEventLog: APIGetEventLogManager;

    constructor(private http: HttpClient) {
        super();

        this.ListEventCategory = new APIListEventLogCategoryManager(http);
        this.ListEventLog = new APIListEventLogManager(http);
        this.GetEventLog = new APIGetEventLogManager(http);
    }

    public resetServerConfig(protocol: string, baseUrl: string, port: number): void {
        let targets = [
            this.ListEventCategory,
            this.ListEventLog,
            this.GetEventLog
        ];

        targets.forEach(t => t.resetServerConfig(protocol, baseUrl, port));
    }
}