import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { ConstantService } from "../../lib/common/constant.service";
import { IUIElement } from "../uiElement.interface";

import { PowerSaveInfo } from "./power-save.data";

@Component({
    selector: 'na-powersave',
    templateUrl: './power-save.component.html',
    styleUrls: ['../uiElement.style.css']
})
export class PowerSaveComponent implements OnInit, OnDestroy, IUIElement {

    _info: PowerSaveInfo = new PowerSaveInfo();

    _keyPair: { [name: string]: { value: any } };
    @Input('keyPair')
    set keyPair(d: { [name: string]: { value: any } }) {
        this._keyPair = d;
    }

    _showBorder: boolean = false;
    @Input()
    showBorder(v: boolean) {
        this._showBorder = v;
    }

    _disabled: boolean = false;
    @Input('disabled')
    set disabled(d: boolean) {
        this._disabled = d;
    }

    _unsupportReason: string;
    @Input('unsupportReason')
    set unsupportReason(v: string) {
        this._unsupportReason = v;
    }

    _lockInfo: { isSync: boolean, policyID: string, policyName: string };
    @Input('lock')
    set lock(d: { isSync: boolean, policyID: string, policyName: string }) {
        this._lockInfo = d;
    }

    @Output() onPowersaveChanged = new EventEmitter<{ data: PowerSaveInfo, isValid: boolean }>();

    constructor(private constantSvc: ConstantService) {}

    ngOnInit(): void {
        if (this._keyPair) {
            this._info.enabled = !this._keyPair[this.constantSvc.DEVKEY_APPSETTING_CONSOLE_DISABLE_POWERSAVE].value;
            this._info.timeout = this._keyPair[this.constantSvc.DEVKEY_APPSETTING_CONSOLE_POWERSAVE_TIMEOUT].value;
        }
    }

    ngOnDestroy(): void {
    }

    onTimeoutChange(timeout: number): void {
        this._info.timeout = timeout > 300 ? 300 : timeout < 1 ? 1 : timeout;
        this.updatePowersaveChange();
    }

    enablePowersave(checked: boolean): void {
        if (this._info.enabled !== checked) {
            this._info.enabled = checked;
            this.updatePowersaveChange();
        }
    }

    private updatePowersaveChange(): void {
        this.onPowersaveChanged.emit({ data: this._info, isValid: true })
    }
}