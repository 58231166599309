import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ITimezoneInfo } from '../../../../app/lib/common/common.data';
import { HelperLib } from '../../../../app/lib/common/helper.lib';
import { IUIElement } from '../../uiElement.interface';

@Component({
    selector: 'na-timezone',
    templateUrl: './timezone.component.html',
    styleUrls: ['../../uiElement.style.css']
})
export class TimeszoneComponent implements IUIElement {

    _availableTimezoneList: ITimezoneInfo[] = [];
    _selectedTimezone: ITimezoneInfo;

    _unsupportReason: string;
    _disabled: boolean;
    _lockInfo: { isSync: boolean; policyID: string; policyName: string; };

    _title: string = 'Timezone';
    @Input('title')
    set title(v: string) {
        this._title = v;
    }

    _timezoneID: string; //timezone id
    @Input('data')
    set data(v: string) {
        this._timezoneID = v;
        if (v) {
            if (!this._selectedTimezone || this._selectedTimezone.id !== v) {
                this._selectedTimezone = this._availableTimezoneList.find(s => s.id === v);
            }
        }
    }

    @Output() changed = new EventEmitter<ITimezoneInfo>();

    constructor() {
        this._availableTimezoneList = HelperLib.getDefaultTimezoneList();
    }

    changeTimezone(timezone: ITimezoneInfo): void {
        if (!this._selectedTimezone || this._selectedTimezone.id !== timezone.id) {
            this._selectedTimezone = timezone;
            this.changed.emit(timezone);
        }
        
    }
}