<ng-template [ngIf]="_g">
    <div *ngIf="!_showDevice; else templateShowDevice">
        <ng-template ngFor let-g [ngForOf]="_g.subgroups">
            <div class="group-item" [class.hovered]="g.hovered"
                [class.active]="(_mode === _enumGroupMode.edit || _mode === _enumGroupMode.viewonly) && g.active"
                [class.dragOver]="_dragMap[g.id]" [class.moveTarget]="_moveTarget?.id === g.id" draggable="true" (dragstart)="onDragStart($event, g)"
                (drop)="onDrop($event, g)" (dragover)="onDragOver($event)" (dragenter)="onDragEnter($event, g)"
                (dragleave)="onDragLeave($event, g)">
                <span *ngIf="(_mode === _enumGroupMode.pickmulti || _mode === _enumGroupMode.pickByAdmin) && g.id !== 'g-home'" class="group-checkbox">
                    <input [id]="'ch-group-' + _id + g.name" type="checkbox" class="cmn-checkbox"
                        [checked]="_pickMap[g.id] && _pickMap[g.id].checked ? true : false"
                        (change)="checkoutGroup(g, $event.target.checked)" />
                    <label [for]="'ch-group-' + _id + g.name">
                        <svg>
                            <use xlink:href="#checkmark" />
                        </svg>
                        <span class="sr-only">Checkout {{ g.name }}</span>
                    </label>
                </span>
                <span class="ml-1">
                    <i *ngIf="g.expanded; else viewNoExpandGroupIcon" class="fas fa-caret-down"
                        (click)="g.expanded = !g.expanded"></i>
                    <ng-template #viewNoExpandGroupIcon>
                        <i class="fas fa-caret-right" (click)="g.expanded = !g.expanded"></i>
                    </ng-template>
                </span>
                <span class="name ml-1" [ngClass]="g.type" (click)="inspectGroup(g)">
                    {{ g.name }}
                </span>
                <span *ngIf="_mode !== _enumGroupMode.pickByAdmin" class="ml-1">({{ g.allDescendantDeviceLength }})</span>
                <i *ngIf="_showPolicy && g.isPolicyApply && _mode !== _enumGroupMode.pickByAdmin" class="icon far fa-bookmark ml-1"
                    (click)="inspectGroupPolicy(g)"></i>
            </div>
            <div *ngIf="g.expanded" class="group-child-block">
                <na-dev-group-tree [group]="g" [mode]="_mode" [moveTarget]="_moveTarget" [showPolicy]="_showPolicy"
                    [pickMap]="_pickMap" [account]="_account" (onGroupMoveTargetChanged)="onMoveTargetChange($event)"
                    (onGroupPolicyInspected)="onDeviceSubgroupPolicyInspect($event)"
                    (onGroupSelected)="onDeviceSubgroupSelect($event)">
                </na-dev-group-tree>
            </div>
        </ng-template>
    </div>
    <ng-template #templateShowDevice>
        <div *ngFor="let g of _g.childs">
            <div class="group-item" [class.hovered]="g.hovered"
                [class.active]="(_mode === _enumGroupMode.edit || _mode === _enumGroupMode.viewonly) && g.active"
                [class.moveTarget]="_moveTarget?.id === g.id">
                <span *ngIf="(_mode === _enumGroupMode.pickmulti || _mode === _enumGroupMode.pickByAdmin) && g.id !== 'g-home' && g.type === _enumGroupType.group"
                    class="group-checkbox">
                    <input [id]="'ch-group-' + _id + g.name" type="checkbox" class="cmn-checkbox"
                        [checked]="_pickMap[g.id] && _pickMap[g.id].checked ? true : false"
                        (change)="checkoutGroup(g, $event.target.checked)" />
                    <label [for]="'ch-group-' + _id + g.name">
                        <svg>
                            <use xlink:href="#checkmark" />
                        </svg>
                        <span class="sr-only">Checkout {{ g.name }}</span>
                    </label>
                </span>
                <span class="ml-1">
                    <span *ngIf="g.type === _enumGroupType.group; else viewDeviceIcon">
                        <i *ngIf="g.expanded; else viewNoExpandGroupIcon" class="fas fa-caret-down"
                            (click)="g.expanded = !g.expanded"></i>
                        <ng-template #viewNoExpandGroupIcon>
                            <i class="fas fa-caret-right" (click)="g.expanded = !g.expanded"></i>
                        </ng-template>
                    </span>
                    <ng-template #viewDeviceIcon>
                        <i class="fas fa-inbox green-3"></i>
                    </ng-template>
                </span>
                <span class="name ml-1" [ngClass]="g.type" (click)="inspectGroup(g)">
                    {{ g.name }}
                </span>
                <ng-template [ngIf]="g.type === _enumGroupType.group">
                    <span class="mx-1">({{ g.allDescendantDeviceLength }})</span>
                    <i *ngIf="_showPolicy && g.isPolicyApply" class="icon far fa-bookmark ml-1"
                        (click)="inspectGroupPolicy(g)"></i>
                </ng-template>
            </div>
            <div *ngIf="g.expanded" class="group-child-block">
                <na-dev-group-tree [group]="g" [mode]="_mode" [moveTarget]="_moveTarget" [showDevice]="_showDevice"
                    [showPolicy]="_showPolicy" [pickMap]="_pickMap" [account]="_account"
                    (onGroupMoveTargetChanged)="onMoveTargetChange($event)"
                    (onGroupPolicyInspected)="onDeviceSubgroupPolicyInspect($event)"
                    (onGroupSelected)="onDeviceSubgroupSelect($event)">
                </na-dev-group-tree>
            </div>
        </div>
    </ng-template>
</ng-template>
<button #btnMoveFunc type="button" class="d-none" data-toggle="modal"
    [attr.data-target]="'#devGroupTreeViewFuncModal-' + _id" (click)="changeGroup()"></button>
<div [id]="'devGroupTreeViewFuncModal-' + _id" class="modal fade" data-backdrop="static" data-keyboard="false"
    role="dialog" tabindex="-1">
    <ng-template dev-group-func-host></ng-template>
</div>