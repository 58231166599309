<div class="app-block">
    <div class="px-3 bg-white border-bottom">
        <div class="form-group row mb-0">
            <label [for]="'app-mgrprev-execute-' + _appToLaunch?.id" class="col-12 col-sm-5 col-md-4 col-form-label-sm">
                Default launch app
            </label>
            <div class="col-12 col-sm-7 col-md-8">
                <div [id]="'app-mgrprev-execute-' + _appToLaunch?.id" class="form-control-plaintext-sm text-break">
                    {{ _appToLaunch?.name }}
                </div>
            </div>
        </div>
    </div>
    <div class="ctrl-bar">
        <strong *ngIf="_app; else templateNoApp" class="green-3">
            App detail : {{ _currentAppIndex + 1 }} / {{ _appList.length }}
        </strong>
        <ng-template #templateNoApp>
            <strong>No app</strong>
        </ng-template>
        <ng-template [ngIf]="_appList.length > 1">
            <i class="fas fa-angle-left app-anchor ml-4" [class.disabled]="_currentAppIndex === 0"
                (click)="goPrevApp()"></i>
            <i class="fas fa-angle-right app-anchor ml-4" [class.disabled]="_currentAppIndex === _appList.length - 1"
                (click)="goNextApp()"></i>
        </ng-template>
    </div>
    <div *ngIf="_app" class="py-2 px-3 bg-white">
        <div class="form-group row">
            <label [for]="'app-mgrprev-apkName-' + _app.id" class="col-12 col-sm-5 col-md-4 col-form-label-sm">
                Name
            </label>
            <div class="col-12 col-sm-7 col-md-8">
                <div [id]="'app-mgrprev-apkName-' + _app.id" class="form-control-plaintext-sm text-break">
                    {{ _app?.name }}
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label [for]="'app-mgrprev-apkLink-' + _app.id" class="col-12 col-sm-5 col-md-4 col-form-label-sm">
                APK link
            </label>
            <div class="col-12 col-sm-7 col-md-8">
                <div [id]="'app-mgrprev-apkLink-' + _app.id" class="form-control-plaintext-sm text-break">
                    {{ _app?.apkLink }}
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label [for]="'app-mgrprev-apkLink-' + _app.id" class="col-12 col-sm-5 col-md-4 col-form-label-sm">
                Package name
            </label>
            <div class="col-12 col-sm-7 col-md-8">
                <div [id]="'app-mgrprev-apkLink-' + _app.id" class="form-control-plaintext-sm text-break">
                    {{ _app?.packageName }}
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label [for]="'app-mgrprev-class-' + _app.id" class="col-12 col-sm-5 col-md-4 col-form-label-sm">
                Class name
            </label>
            <div class="col-12 col-sm-7 col-md-8">
                <div [id]="'app-mgrprev-class-' + _app.id" class="form-control-plaintext-sm text-break">
                    {{ _app?.className }}
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label [for]="'app-mgrprev-action-' + _app.id" class="col-12 col-sm-5 col-md-4 col-form-label-sm">
                Action
            </label>
            <div class="col-12 col-sm-7 col-md-8">
                <div [id]="'app-mgrprev-action-' + _app.id" class="form-control-plaintext-sm text-break">
                    {{ _app?.action }}
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label [for]="'app-mgrprev-versionCode-' + _app.id" class="col-12 col-sm-5 col-md-4 col-form-label-sm">
                Version code
            </label>
            <div class="col-12 col-sm-7 col-md-8">
                <div [id]="'app-mgrprev-versionCode-' + _app.id" class="form-control-plaintext-sm text-break">
                    {{ _app?.versionCode }}
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label [for]="'app-mgrprev-versionName-' + _app.id" class="col-12 col-sm-5 col-md-4 col-form-label-sm">
                Version name
            </label>
            <div class="col-12 col-sm-7 col-md-8">
                <div [id]="'app-mgrprev-versionName-' + _app.id" class="form-control-plaintext-sm text-break">
                    {{ _app?.versionName }}
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label [for]="'app-mgrprev-checksum-' + _app.id" class="col-12 col-sm-5 col-md-4 col-form-label-sm">
                Checksum
            </label>
            <div class="col-12 col-sm-7 col-md-8">
                <div [id]="'app-mgrprev-checksum-' + _app.id" class="form-control-plaintext-sm text-break">
                    {{ _app?.checksum }}
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label [for]="'app-mgrprev-checksumAlg-' + _app.id" class="col-12 col-sm-5 col-md-4 col-form-label-sm">
                Checksum algorithm
            </label>
            <div class="col-12 col-sm-7 col-md-8">
                <div [id]="'app-mgrprev-checksumAlg-' + _app.id" class="form-control-plaintext-sm text-break">
                    {{ _app?.checksumAlgorithm }}
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label [for]="'app-mgrprev-downgrade-' + _app.id" class="col-12 col-sm-5 col-md-4 col-form-label-sm">
                Allow downgrade
            </label>
            <div class="col-12 col-sm-7 col-md-8">
                <div [id]="'app-mgrprev-downgrade-' + _app.id" class="form-control-plaintext-sm text-break">
                    {{ _app?.allowDowngrade ? 'YES' : 'NO' }}
                </div>
            </div>
        </div>
        <div *ngIf="_app.splits && _app.splits.length > 0" class="form-group row">
            <label [for]="'app-mgrprev-splits-' + _app.id" class="col-12 col-sm-5 col-md-4 col-form-label-sm">
                Split APK(s)
            </label>
            <div [id]="'app-mgrprev-splits-' + _app.id" class="col-12 col-sm-7 col-md-8">
                <button id="dropdownAppSplitName" type="button" class="btn btn-sm btn-action btn-light dropdown-toggle"
                    data-toggle="dropdown">
                    {{ _split?.name }}
                </button>
                <ul class="dropdown-menu option-list w-100" data-backdrop="static" data-keyboard="false"
                    aria-labelledby="dropdownAppSplitName">
                    <li *ngFor="let splitItem of _app.splits" [class.active]="_split?.id === splitItem.id"
                        (click)="selectSplit(splitItem)">
                        {{ splitItem.name }}
                    </li>
                </ul>
            </div>
            <div *ngIf="_split" class="container-fluid mt-2">
                <div class="form-group row">
                    <label [for]="'app-mgr-split-link-' + _split.id"
                        class="offset-sm-1 col-12 col-sm-4 col-md-3 col-form-label-sm">
                        &#9830; Link
                    </label>
                    <div class="col-12 col-sm-7 col-md-8">
                        <div [id]="'app-mgr-split-link-' + _split.id" class="form-control-plaintext-sm text-break">
                            {{ _split.link }}
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label [for]="'app-mgr-split-checksum-' + _split.id"
                        class="offset-sm-1 col-12 col-sm-4 col-md-3 col-form-label-sm">
                        &#9830; Checksum
                    </label>
                    <div class="col-12 col-sm-7 col-md-8">
                        <div [id]="'app-mgr-split-checksum-' + _split.id" class="form-control-plaintext-sm text-break">
                            {{ _split.checksum }}
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label [for]="'app-mgr-split-checksumAlg-' + _split.id"
                        class="offset-sm-1 col-12 col-sm-4 col-md-3 col-form-label-sm">
                        &#9830; Checksum algorithm
                    </label>
                    <div class="col-12 col-sm-7 col-md-8">
                        <div [id]="'app-mgr-split-checksumAlg-' + _split.id"
                            class="form-control-plaintext-sm text-break">
                            {{ _split.checksumAlgorithm }}
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label [for]="'app-mgr-split-mime-' + _split.id"
                        class="offset-sm-1 col-12 col-sm-4 col-md-3 col-form-label-sm">
                        &#9830; Mime type
                    </label>
                    <div class="col-12 col-sm-7 col-md-8">
                        <div [id]="'app-mgr-split-mime-' + _split.id" class="form-control-plaintext-sm text-break">
                            {{ _split.mimeType }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>