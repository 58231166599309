import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';

import { NAService } from '../../API/na.service';
import { IAPIRx } from '../../API/api.base';

@Component({
    templateUrl: './password-reset.component.html',
    styleUrls: ['../entry.style.css', './password-reset.component.css']
})
export class PasswordResetComponent implements OnInit {
    _account: string;
    _verifyCode: string;

    _isPass: boolean = false;
    _isRunning: boolean = false;
    _errorMessage: string;

    constructor(private route: ActivatedRoute, private naSvc: NAService) { }

    ngOnInit(): void {
        this.route.paramMap.subscribe((params: ParamMap) => {
            this._account = params.get('account');
            this._verifyCode = params.get('code');

            if (this._account && this._verifyCode) {
                this.submit();
            }
        });
    }

    private submit() {
        this._isRunning = true;
        this._errorMessage = null;

        this.naSvc.verifyResetPassword({ accountEmail: this._account, activationCode: this._verifyCode }).subscribe((res: IAPIRx<void>) => {
            if (res.error === 0) {
                this._isPass = true;
            }
            else {
                this._errorMessage = 'Verification failed';
            }

            this._isRunning = false;
        });
    }
}