import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../../api.base';
import { EnterpriseAccountGroupBasicInfo } from './api.ent.data';

export interface ICreateEnterpriseAccountGroupTxData {
    accountGroupName: string;
}

export class APICreateEnterpriseAccountGroupManager extends APIBaseManager<void, void, ICreateEnterpriseAccountGroupTxData, EnterpriseAccountGroupBasicInfo> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_CreateEnterpriseAccountGroup';
        this._apiMethod = 'POST';
    }

    protected getRequestURL(pathParameters: void, queryParameters: void): string {
        return super.getRequestURL() + 'enterprises/accountGroups';
    }
}