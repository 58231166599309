
export class TextLib {
    static capitalAllWords(s: string): string {
        if (!s) {
            return s;
        }

        //return s.replace(/\w\S*/g, function (txt: string) {
        //    return txt.charAt(0).toUpperCase() + txt.substr(1);
        //});
        
        return s.toLocaleUpperCase();
    }

    static capitalFirstWord(s: string): string {
        if (!s) {
            return s;
        }

        return s.charAt(0).toLocaleUpperCase() + s.substring(1);
    }
}