import { Type } from '@angular/core';
import { PolicyInfo } from './policy.data';
import { Injectable } from '@angular/core';
import { PolicyEditorComponent } from './form/policy-edit.component';
import { PolicyRemoveComponent } from './form/policy-remove.component';

export class PolicyFuncItem {
  constructor(public component: Type<any>, public name: string, public title: string) { }
}

export interface IPolicyFunc {
  title: string;
  policy: PolicyInfo;
  func: string;
  dialogCompleteHandler?: (result: { func: string, errorMessage?: string, data?: any }) => void;
}

export const POLICY_FUNC_EDIT: string = 'policy-edit';
export const POLICY_FUNC_CREATE: string = 'policy-create';
export const POLICY_FUNC_REMOVE: string = 'policy-remove';

@Injectable()
export class PolicyFuncService {
  private _items: PolicyFuncItem[] = [];

  constructor() {
    this._items = [
      new PolicyFuncItem(PolicyEditorComponent, POLICY_FUNC_CREATE, 'Create policy'),
      new PolicyFuncItem(PolicyEditorComponent, POLICY_FUNC_EDIT, 'Edit policy'),
      new PolicyFuncItem(PolicyRemoveComponent, POLICY_FUNC_REMOVE, 'Remove policy')
    ];
  }

  getAllItems(): PolicyFuncItem[] {
    return this._items;
  }

  getItemByName(name: string): PolicyFuncItem {
    return this._items.find(i => i.name === name);
  }
}
