import { Component, OnInit, Input, EventEmitter, Output, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { FirmwareUpdateInfo, FirmwareFamilyInfo, FIRMWARE_UPDATE_METHOD } from '../firmware-data';
import { FirmwareService } from '../firmware.service';
import { DeviceInfo } from '../../../device/data/device-info';
import { ConstantService } from '../../../../../app/lib/common/constant.service';

@Component({
    selector: 'firmware-family-content',
    templateUrl: './firmware-family-content.component.html',
    styleUrls: ['./firmware-family-content.component.css']
})
export class FirmwareFamilyContentComponent implements OnInit, AfterViewInit {
    FIRMWARE_UPDATE_METHOD: typeof FIRMWARE_UPDATE_METHOD = FIRMWARE_UPDATE_METHOD;
    _familyInfo: FirmwareFamilyInfo;
    _specific: boolean = false;

    _isLoadingLatestFirmwareInfo: boolean = false;

    @Input('familyInfo')
    set familyInfo(info: FirmwareFamilyInfo) {
        this._familyInfo = info;
    }

    @Input('specific')
    set specific(bSpecific: boolean) {
        this._specific = bSpecific;
    }

    @Output() onValid = new EventEmitter<boolean>();

    @ViewChild('customUrl', { static: true })
    customUrlElementRef: ElementRef;

    constructor(private firmwareSvc: FirmwareService, private constantSvc: ConstantService) { }

    ngOnInit(): void { }

    ngAfterViewInit(): void {
        fromEvent(this.customUrlElementRef?.nativeElement, 'input').pipe(
            debounceTime(200)
        ).subscribe((e: any) => {
            this.onValid.emit(e.target.value ? true : false);
        });
    }

    changeUpdateMethod(method: FIRMWARE_UPDATE_METHOD): void {
        if (this._familyInfo.updateMethod === method) { return; }

        this._familyInfo.updateMethod = method;
        if (method === FIRMWARE_UPDATE_METHOD.Latest) {
            this.refreshLatestFirmwareInfo(false);
        }
        else {
            this.onValid.emit(this.check_update_content_valid());
        }
    }

    refreshLatestFirmwareInfo(force: boolean = true): void {
        this._isLoadingLatestFirmwareInfo = true;
        this._familyInfo.updateInfo = null;

        this.firmwareSvc.getLatestInfo((this._familyInfo.devices[0]?.currentSettings[this.constantSvc.DEVKEY_INFO_PID] as string).substring(0, 23), this._familyInfo.modelName, this._familyInfo.targetAndroidVersion, force).subscribe((firmwareUpdateInfo: FirmwareUpdateInfo) => {
            this._isLoadingLatestFirmwareInfo = false;
            this._familyInfo.updateInfo = firmwareUpdateInfo;
            this.onValid.emit(true);
        });
    }

    private check_update_content_valid(): boolean {
        if (this._familyInfo.updateMethod === FIRMWARE_UPDATE_METHOD.Unknown) {
            return false;
        }
        else if (this._familyInfo.updateMethod === FIRMWARE_UPDATE_METHOD.FromURL) {
            if (!this._familyInfo.customURL) {
                return false;
            }
        }

        return true;
    }

    private select_single_device(d: DeviceInfo): void {
        d.isSelect = !d.isSelect;
        if (!d.isSelect) {
            //no devices to update, so just pass the exam.
            if (this._familyInfo.devices.filter(d => d.isSelect).length === 0) {
                this.onValid.emit(true);
            }
        }
        else {
            this.onValid.emit(this.check_update_content_valid());
        }
    }

    private select_all_device(e: any): void {
        let checked = e.target.checked;
        this._familyInfo.devices.forEach(d => d.isSelect = checked);
        if (!checked) {
            //no devices to update, so just pass the exam.
            this.onValid.emit(true);
        }
        else {
            this.onValid.emit(this.check_update_content_valid());
        }
    }

    private is_all_device_select(): boolean {
        if (!this._familyInfo || !this._familyInfo.devices) {
            return false;
        }

        for (const dev of this._familyInfo.devices) {
            if (!dev.isSelect) {
                return false;
            }
        }

        return true;
    }
}