import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { of, Observable } from 'rxjs';
import { map, catchError, timeout } from 'rxjs/operators';
import { Logger } from '../../../lib/common/logger';
import { AppConfigService } from '../../../app.config';

export class APKUpdateInfo {
    version?: string;
    link?: string;
    category?: string;
    availableAPKList?: string[];
    md5?: string;
}

@Injectable()
export class APKService {
    readonly APK_QUERY_INTERVAL: number = 600000; //check update info every 10min

    private _updateInfoMap: { [apkCategory: string]: { info: APKUpdateInfo, lastQueryTime: number, updating: boolean } } = {};

    constructor(private http: HttpClient) { }

    private needUpdate(category: string, forceUpdate: boolean = false): boolean {
        return forceUpdate ||
            !this._updateInfoMap[category].info ||
            !this._updateInfoMap[category].info.version ||
            !this._updateInfoMap[category].info.link ||
            !this._updateInfoMap[category].lastQueryTime ||
            (new Date().getTime() - this._updateInfoMap[category].lastQueryTime) > this.APK_QUERY_INTERVAL;
    }

    getLatestAPKUpdateInfo(category: string, force: boolean = false): Observable<{ isFault: boolean, info?: APKUpdateInfo, errorMessage?: string }> {
        if (!category) {
            return of({
                isFault: true,
                errorMessage: 'Unknown APK category'
            });
        }

        const apkCategory: string = category;

        Logger.logInfo('apkSvc', 'getLatestAPKUpdateInfo', '', this._updateInfoMap[apkCategory]);
        if (this._updateInfoMap[apkCategory]) {
            //under updating for this apk category
            if (this._updateInfoMap[apkCategory].updating) {
                Logger.logInfo('apkSvc', 'getLatestAPKUpdateInfo', 'APK for ' + apkCategory + ' is under updating');
                return new Observable(observer => {
                    this.checkUpdating(apkCategory, 500, false, () => {
                        Logger.logInfo('apkSvc', 'getLatestAPKUpdateInfo', 'APK for ' + apkCategory + ' finish updating');
                        const isFault: boolean = this._updateInfoMap[apkCategory].info && this._updateInfoMap[apkCategory].info.link && this._updateInfoMap[apkCategory].info.version ? false : true;
                        observer.next({
                            isFault: isFault,
                            info: this._updateInfoMap[apkCategory].info,
                            errorMessage: isFault ? 'No available update' : null
                        });
                        observer.complete();
                    });
                })
            }

            //no need update, use cache for this apk category
            if (!this.needUpdate(apkCategory, force)) {
                Logger.logInfo('apkSvc', 'getLatestAPKUpdateInfo', 'Use cache for category = ' + apkCategory);
                return of({
                    isFault: false,
                    info: this._updateInfoMap[apkCategory].info
                })
            }
        }

        this._updateInfoMap[apkCategory] = this._updateInfoMap[apkCategory] || { info: {}, lastQueryTime: 0, updating: false };
        this._updateInfoMap[apkCategory].updating = true;

        let url = AppConfigService.configs.server.apk + '?apkVersion=' + AppConfigService.configs.server.api.version + '&category=' + apkCategory; //+ AppConfigService.configs.server.api.version
        Logger.logInfo('apkSvc', 'getLatestAPKUpdateInfo', 'query url = ' + url);
        return this.http.get<{ link: string, version: string, category?: string, md5?: string }>(url).pipe(
            map((res: { link: string, version: string, category?: string, md5?: string }) => {
                Logger.logInfo('apkSvc', 'getLatestAPKUpdateInfo', `APK update info for category: ${apkCategory}: `, res);

                this._updateInfoMap[apkCategory].updating = false;

                if (res) {
                    this._updateInfoMap[apkCategory].lastQueryTime = new Date().getTime();
                    this._updateInfoMap[apkCategory].info.link = res.link;
                    this._updateInfoMap[apkCategory].info.version = res.version;
                    this._updateInfoMap[apkCategory].info.md5 = res.md5;
                    this._updateInfoMap[apkCategory].info.category = res.category;
                }

                const isFault: boolean = !this._updateInfoMap[apkCategory].info || !this._updateInfoMap[apkCategory].info.link || !this._updateInfoMap[apkCategory].info.version ? true : false;
                return {
                    isFault: isFault,
                    info: this._updateInfoMap[apkCategory].info,
                    errorMessage: isFault ? 'No available update' : null 
                };
            }),
            timeout(30000),
            catchError((err) => {
                Logger.logError('apkSvc', 'getLatestAPKUpdateInfo', 'Get APK update info for category ' + apkCategory + ' failed. Error = ', err);
                this._updateInfoMap[apkCategory].updating = false;
                return of({
                    isFault: true,
                    errorMessage: err ? err.message : 'Unknow error'
                });
            })
        );
    }

    private checkUpdating(category: string, interval: number, notifyMatchValue: boolean, cb: () => void): void {
        setTimeout(() => {
            if (this._updateInfoMap[category].updating === notifyMatchValue) {
                cb();
            }
            else {
                this.checkUpdating(category, interval, notifyMatchValue, cb);
            }
        }, interval);
    }
}