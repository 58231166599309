import { Injectable } from '@angular/core';
import { CertAddDlgComponent } from './cert-dlg-add.component';
import { CertRemoveDlgComponent } from './cert-dlg-remove.component';
import { CertFuncItem, CERT_FUNC_ADD, CERT_FUNC_REMOVE } from './cert-func.def';

@Injectable()
export class CertFuncService {
  private _items: CertFuncItem[] = [];

  constructor() {
    this._items = [
        new CertFuncItem(CertAddDlgComponent, CERT_FUNC_ADD, 'Add certificate'),
        new CertFuncItem(CertRemoveDlgComponent, CERT_FUNC_REMOVE, 'Remove certificate')
    ];
  }

  getAllItems(): CertFuncItem[] {
    return this._items;
  }

  getItemByName(name: string): CertFuncItem {
    return this._items.find(i => i.name === name);
  }
}
