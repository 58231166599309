import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { map } from 'rxjs/operators';

import { AlertService } from '../alert.service';
import { AlertInfo, AlertChangedEventArgs, AlertAddedEventArgs, AlertRemovedEventArgs } from './alert.data';
import { IAlertFuncCtrl, AlertFuncItem } from './alert-func.def';
import { AlertFuncService, ALERT_RULE_FUNC_EDIT, ALERT_RULE_FUNC_NEW, ALERT_RULE_FUNC_REMOVE } from './alert-func.service';
import { AlertFuncDirective } from './alert-func.directive';
import { IClass } from '../../../../lib/common/common.data';
import { Logger } from '../../../../lib/common/logger';
import { AccountService } from '../../../../../app/entry/account.service';

@Component({
    templateUrl: './alert-config-overview.component.html',
    styleUrls: ['../../common.style.css', './alert.style.css']
})
export class AlertConfigOverviewComponent implements OnInit, OnDestroy, IClass {
    className: string;
    _alertList: AlertInfo[] = [];
    _alert: AlertInfo;
    _isLoading: boolean = false; //if it is loading the rules
    _activeAlertID: string;

    private _alertRuleFuncHost: AlertFuncDirective;
    @ViewChild(AlertFuncDirective, { static: true })
    set alertRuleFuncHost(host: any) {
        if (host) {
            this._alertRuleFuncHost = host;
        }
    }

    constructor(
        private accountSvc: AccountService,
        private alertSvc: AlertService,
        private alertRuleFuncSvc: AlertFuncService,
        private route: ActivatedRoute) {
        this.className = 'Email-page';
    }

    ngOnInit(): void {
        this.alertSvc.onAlertAdded.subscribe((args: AlertAddedEventArgs) => {
            Logger.logInfo(this.className, 'OnInit', 'Alert added', args);

            this._alert = args.updateAlertList[0];
            this._alertList = args.currentAlertList;
        });

        this.alertSvc.onAlertChanged.subscribe((args: AlertChangedEventArgs) => {
            Logger.logInfo(this.className, 'OnInit', 'Alert changed', args);

            this._alert = null;
            this._alertList = args.currentAlertList;
            setTimeout(() => {
                this._alert = args.updateAlertList[0];
            }, 0);
        });

        this.alertSvc.onAlertRemoved.subscribe((args: AlertRemovedEventArgs) => {
            Logger.logInfo(this.className, 'OnInit', 'Alert removed', args);

            this._alertList = args.currentAlertList;

            for (let removeRule of args.updateAlertList) {
                if (this._alert && this._alert.id === removeRule.id) {
                    this._alert = null;
                }
            }
        });

        
        this.route.paramMap.pipe(
            map((params: ParamMap) => params.get('id'))
        ).subscribe((alertID: string) => {
            this._activeAlertID = alertID;
        });

        this.refreshAlert();
    }

    ngOnDestroy(): void {
    }

    supportAlertUpdate(): boolean {
        return this.accountSvc.hasScope_alertSetting_update();
    }

    refreshAlert(force: boolean = false): void {
        this._isLoading = true;
        this._alertList = [];
        this._alert = null;
        this._isLoading = true;
        this.alertSvc.getAlertSettingList(force).subscribe((alertList: AlertInfo[]) => {
            this._isLoading = false;
            this._alertList = alertList;

            if (this._activeAlertID) {
                const found: AlertInfo = this._alertList.find(a => a.id === this._activeAlertID);
                if (found) {
                    this.selectAlert(found);
                }
            }
        });
    }

    createAlert(): void {
        this.createAlertForm(ALERT_RULE_FUNC_NEW, new AlertInfo());
    }

    editAlert(): void {
        this.createAlertForm(ALERT_RULE_FUNC_EDIT, this._alert);
    }

    selectAlert(alert: AlertInfo): void {
        this._alert = alert;
        this._activeAlertID = null;
    }

    deleteAlert(): void {
        this.createAlertForm(ALERT_RULE_FUNC_REMOVE, this._alert);
    }

    private createAlertForm(alertFuncName: string, alert: AlertInfo): void {
        const item: AlertFuncItem = this.alertRuleFuncSvc.getItemByName(alertFuncName);
        if (item) {
            const viewContainerRef = this._alertRuleFuncHost.viewContainerRef;
            viewContainerRef.clear();

            const componentRef = viewContainerRef.createComponent(item.component);

            (<IAlertFuncCtrl>componentRef.instance).title = item.title;
            (<IAlertFuncCtrl>componentRef.instance).alert = alert;
        }
    }
}