import { Component, EventEmitter, Input, Output, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { fromEvent as observableFromEvent, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { IUIElement } from '../../uiElement.interface';
import { TimeserverInfo, TimeserverOption } from './timeserver.data';

@Component({
    selector: 'na-timeserver',
    templateUrl: './timeserver.component.html',
    styleUrls: ['../../uiElement.style.css']
})
export class TimeserverComponent implements OnDestroy, IUIElement {

    _enumTimeserverOption: typeof TimeserverOption = TimeserverOption;
    _timeserverInfo: TimeserverInfo = new TimeserverInfo();
    private _allUnsubscribe: Subject<void> = new Subject();

    _showBorder: boolean = false;
    @Input()
    showBorder(v: boolean) {
        this._showBorder = v;
    }

    @Input('source')
    set source(v: string) {
        switch (v) {
            case TimeserverOption[TimeserverOption.default]:
                {
                    this._timeserverInfo.changeTimeserverOption(TimeserverOption.default);
                }
                break;
            case TimeserverOption[TimeserverOption.content]:
                {
                    this._timeserverInfo.changeTimeserverOption(TimeserverOption.content);
                }
                break;
            default:
                {
                    this._timeserverInfo.changeTimeserverOption(TimeserverOption.customize, v);
                }
                break;
        }
    }

    _disabled: boolean;
    @Input('disabled')
    set disabled(v: boolean) {
        this._disabled = v;
    }

    _unsupportReason: string;
    @Input('unsupportReason')
    set unsupportReason(v: string) {
        this._unsupportReason = v;
    }

    _lockInfo: { isSync: boolean, policyID: string, policyName: string };
    @Input('lock')
    set lock(d: { isSync: boolean, policyID: string, policyName: string }) {
        this._lockInfo = d;
    }

    @Input('on')
    set on(v: boolean) {
        this._timeserverInfo.turnOnOff(v);
    }

    private _urlRef: ElementRef;
    @ViewChild('customUrl', { static: true })
    set search(v: ElementRef) {
        if (v) {
            this._urlRef = v;
            if (this._urlRef) {
                const searchElement = this._urlRef.nativeElement;
                const searchInputOb = observableFromEvent(searchElement, 'input');

                searchInputOb.pipe(
                    debounceTime(200),
                    takeUntil(this._allUnsubscribe)
                ).subscribe((e: any) => {
                    this._timeserverInfo.url = e.target.value;
                    this.changed.next(this._timeserverInfo);
                });
            }
        }
    }

    @Output() changed = new EventEmitter<TimeserverInfo>();

    ngOnDestroy(): void {
        this._allUnsubscribe.next();
        this._allUnsubscribe.complete();
    }

    turnOnOff(checked: boolean): void {
        if (this._timeserverInfo.turnOnOff(checked)) {
            this.changed.next(this._timeserverInfo);
        }
    }

    changeTimeserverOption(option: TimeserverOption, url?: string): void {
        if (this._timeserverInfo.changeTimeserverOption(option, url)) {
            this.changed.next(this._timeserverInfo);
        }
    }
}