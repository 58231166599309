import { Component, OnInit } from '@angular/core';
import { IUserDlgFuncContent } from '../user-dlg-func.def';
import { UserInfo } from '../../user.data';
import { UserService } from '../../user.service';
import { UserGroupInfo } from '../../group/user-group.data';

@Component({
    templateUrl: './user-dlg-editConfirm-content.component.html',
    styleUrls: ['../../user.style.css']
})
export class UserConfirmEditDlgContentComponent implements IUserDlgFuncContent<UserInfo[], UserInfo>, OnInit {
    funcName: string;
    data: UserInfo[];
    other: UserInfo; //origin user data
    onActionPrepared: () => void;
    onActionValidChanged: (valid: boolean) => void;

    _user: UserInfo; //modidfied user data
    _changeList: { field: string, before: string, after: string }[] = [];

    constructor(private userSvc: UserService) { }

    ngOnInit(): void {
        this._user = this.data[0];
        if (this._user && this.other) {
            if (this._user.firstName !== this.other.firstName) {
                this._changeList.push({ field: 'First name', before: this.other.firstName, after: this._user.firstName });
            }
            if (this._user.lastName !== this.other.lastName) {
                this._changeList.push({ field: 'Last name', before: this.other.lastName, after: this._user.lastName });
            }
            if (this._user.company !== this.other.company) {
                this._changeList.push({ field: 'Company', before: this.other.company, after: this._user.company });
            }
            if (this._user.department !== this.other.department) {
                this._changeList.push({ field: 'Department', before: this.other.department, after: this._user.department });
            }
            if (this._user.userGroupID !== this.other.userGroupID) {
                const beforeUserGroup: UserGroupInfo = this.userSvc.getCurrentUserGroupByID(this.other.userGroupID);
                const afterUserGroup: UserGroupInfo = this.userSvc.getCurrentUserGroupByID(this._user.userGroupID);

                this._changeList.push({ field: 'User group', before: beforeUserGroup.name, after: afterUserGroup.name });
            }
            if (this._user.userRole && this._user.userRole !== this.other.userRole) {
                this._changeList.push({ field: 'User role', before: this.other.userRole, after: this._user.userRole });
            }

            this.updateDataValidity();
        }
    }

    private updateDataValidity(): void {
        this.onActionValidChanged(this._changeList.length > 0 ? true : false);
    }
}