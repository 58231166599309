import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { ScepServerInfo, SCEP_USAGE_APP, SCEP_USAGE_EAP_ETHERNET, SCEP_USAGE_EAP_WIFI, SCEP_USAGE_VPN } from '../scep.data';
import { ScepService } from '../scep.service';

@Component({
    selector: 'na-scep-server-detail',
    templateUrl: './scep-server-detail.component.html',
    styleUrls: ['../scep.style.css', './scep-server-detail.component.css']
})
export class SCEPServerDetailComponent implements OnInit, OnDestroy {
    readonly SCEP_ALIAS_PREFIX: string = this.scepSvc.SCEP_ALIAS_PREFIX;

    _editData: ScepServerInfo;
    _scepUsageList: string[] = [SCEP_USAGE_APP, SCEP_USAGE_VPN, SCEP_USAGE_EAP_ETHERNET, SCEP_USAGE_EAP_WIFI];
    private _allUnsubscribe: Subject<void> = new Subject();

    _scep: ScepServerInfo;
    @Input('scep')
    set scep(v: ScepServerInfo) {
        this._scep = v;
    }

    _mode: string = 'update';
    @Input('mode')
    set mode(v: string) {
        this._mode = v;
    }

    _isInEdit: boolean = false;
    @Input('isInEdit')
    set isInEdit(v: boolean) {
        this._isInEdit = v;
        this._editData = this._isInEdit ? this._scep.copy() : new ScepServerInfo();
    }

    _disabled: boolean = false;
    @Input('disabled')
    set diabled(v: boolean) {
        this._disabled = v;
    }

    private _colAliasRef: ElementRef;
    @ViewChild('colAlias', { static: false })
    set colAlias(v: ElementRef) {
        if (v) {
            this._colAliasRef = v;
            const searchElement = this._colAliasRef.nativeElement;
            const searchInputOb = fromEvent(searchElement, 'input');

            searchInputOb.pipe(
                debounceTime(200),
                takeUntil(this._allUnsubscribe)
            ).subscribe((e: any) => {
                this._editData.alias = e.target.value;
                this.onScepInfoUpdated.emit(this._editData);
            });
        }
    }

    private _colSubjectRef: ElementRef;
    @ViewChild('colSubject', { static: false })
    set colSubject(v: ElementRef) {
        if (v) {
            this._colSubjectRef = v;
            const searchElement = this._colSubjectRef.nativeElement;
            const searchInputOb = fromEvent(searchElement, 'input');

            searchInputOb.pipe(
                debounceTime(200),
                takeUntil(this._allUnsubscribe)
            ).subscribe((e: any) => {
                this._editData.subject = e.target.value;
                this.onScepInfoUpdated.emit(this._editData);
            });
        }
    }

    private _colUrlRef: ElementRef;
    @ViewChild('colUrl', { static: false })
    set colUrl(v: ElementRef) {
        if (v) {
            this._colUrlRef = v;
            const searchElement = this._colUrlRef.nativeElement;
            const searchInputOb = fromEvent(searchElement, 'input');

            searchInputOb.pipe(
                debounceTime(200),
                takeUntil(this._allUnsubscribe)
            ).subscribe((e: any) => {
                this._editData.url = e.target.value;
                this.onScepInfoUpdated.emit(this._editData);
            });
        }
    }

    private _colKeysizeRef: ElementRef;
    @ViewChild('colKeysize', { static: false })
    set colKeysize(v: ElementRef) {
        if (v) {
            this._colKeysizeRef = v;
            const searchElement = this._colKeysizeRef.nativeElement;
            const searchInputOb = fromEvent(searchElement, 'input');

            searchInputOb.pipe(
                debounceTime(200),
                takeUntil(this._allUnsubscribe)
            ).subscribe((e: any) => {
                try {
                    const keysizeUInt: number = parseInt(e.target.value);
                    this._editData.keysize = keysizeUInt;
                }
                catch {

                }

                this.onScepInfoUpdated.emit(this._editData);
            });
        }
    }

    private _colProfileRef: ElementRef;
    @ViewChild('colProfile', { static: false })
    set colProfile(v: ElementRef) {
        if (v) {
            this._colProfileRef = v;
            const searchElement = this._colProfileRef.nativeElement;
            const searchInputOb = fromEvent(searchElement, 'input');

            searchInputOb.pipe(
                debounceTime(200),
                takeUntil(this._allUnsubscribe)
            ).subscribe((e: any) => {
                this._editData.profile = e.target.value;
                this.onScepInfoUpdated.emit(this._editData);
            });
        }
    }

    private _colRenewRef: ElementRef;
    @ViewChild('colRenew', { static: false })
    set colRenew(v: ElementRef) {
        if (v) {
            this._colRenewRef = v;
            const searchElement = this._colRenewRef.nativeElement;
            const searchInputOb = fromEvent(searchElement, 'input');

            searchInputOb.pipe(
                debounceTime(200),
                takeUntil(this._allUnsubscribe)
            ).subscribe((e: any) => {
                this._editData.autoRenewDay = parseInt(e.target.value);
                this.onScepInfoUpdated.emit(this._editData);
            });
        }
    }


    @Output() onScepInfoUpdated = new EventEmitter<ScepServerInfo>();

    constructor(private scepSvc: ScepService) {
    }

    ngOnInit(): void {

    }

    ngOnDestroy(): void {
        this._allUnsubscribe.next();
        this._allUnsubscribe.complete();
    }

    changeScepUsage(usage: string): void {
        if (this._editData.usage !== usage) {
            this._editData.usage = usage;
            this.onScepInfoUpdated.emit(this._editData);
        }  
    }
}