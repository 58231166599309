import { Component, Input, OnInit } from '@angular/core';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import { LicenseScopeType, ScopeFunctionInfo } from '../../../../../app/content/license/license.data';
import { PolicyInfo, PolicyType } from '../../../../../app/content/setting/policy/policy.data';
import { PolicyService } from '../../../../../app/content/setting/policy/policy.service';
import { HelperLib } from '../../../../lib/common/helper.lib';
import { DeviceGroupInfo } from '../../group/group.data';
import { DeviceGroupService } from '../../group/dev-group.service';
import { DeviceInfo, IPolicyLockMap } from '../../data/device-info';
import { AccountService } from '../../../../entry/account.service';

@Component({
    selector: 'device-info-policy',
    templateUrl: './device-info-policy.component.html',
    styleUrls: ['./device.style.css', './device-info-policy.component.css']
})
export class DeviceInfoPolicyComponent implements OnInit {
    readonly TEXT_LOADING: string = 'loading ...';

    _loading: boolean = false;
    _policyMap: { [name: string]: { list: PolicyInfo[], status?: string, isPartialSync?: boolean, partialSyncDescList?: string[] } } = {};

    private _device: DeviceInfo;
    @Input('device')
    set device(d: DeviceInfo) {
        this._device = d;
    };

    private _policyLockMap: IPolicyLockMap = {};
    @Input('lock')
    set lock(v: any) {
        this._policyLockMap = v;
    }

    _enumLicenseScope: typeof LicenseScopeType = LicenseScopeType;
    _licenseScopeMap: { [scopeType: string]: ScopeFunctionInfo } = {};
    @Input('licenseScope')
    set licenseScope(v) {
        this._licenseScopeMap = v;
    }

    constructor(private policySvc: PolicyService, private devGroupSvc: DeviceGroupService, private accountSvc: AccountService) { }

    ngOnInit(): void {
        if (this._licenseScopeMap[LicenseScopeType.devicePolicy]) {
            this._loading = true;

            HelperLib.checkState(1, () => { return this.devGroupSvc.isReady }, () => {
                of(true).pipe(
                    concatMap(() => {
                        if (!this._device || !this._device.groupID) {
                            throw 'No device or device group info';
                        }

                        let allPolicyIDList: string[] = [];
                        const supportedPolicyTypeList: PolicyType[] = this.policySvc.getSupportPolicyTypesByLevel(this.accountSvc.isEnterprise());
                        //check policy from device group
                        const g: DeviceGroupInfo = this.devGroupSvc.getGroupByID(null, this._device.groupID);
                        if (g && g.policies) {
                            allPolicyIDList = allPolicyIDList.concat(...supportedPolicyTypeList.map(type => g.policies[type]));
                        }

                        if (allPolicyIDList.length === 0) {
                            throw 'No policy assigned on this group';
                        }

                        return this.policySvc.getPolicyByIDList(allPolicyIDList).pipe(
                            map((policyList: PolicyInfo[]) => {
                                supportedPolicyTypeList.forEach((policyType: PolicyType) => {
                                    this._policyMap[policyType] = {
                                        list: policyList.filter(p => p.type === policyType),
                                    };

                                    const lockList = HelperLib.mapToList(this._policyLockMap).filter(lock => lock.policyType === policyType);
                                    if (lockList.length > 0) {
                                        this._policyMap[policyType].status = lockList.filter(l => !l.isSync).length > 0 ? 'pending' : 'synced';
                                        if (this._policyMap[policyType].status === 'synced') {
                                            this._policyMap[policyType].partialSyncDescList = lockList.filter(l => l.isPartialSync).map(l => l.partialSyncDesc);
                                            //list partial sync items
                                            if (this._policyMap[policyType].partialSyncDescList.length > 0) {
                                                this._policyMap[policyType].isPartialSync = true;
                                            }
                                        }
                                    }
                                });

                                return;
                            })
                        );
                    }),
                    catchError(error => of(error))
                ).subscribe((errorMessage?: string) => {
                    this._loading = false;
                });
            });
        }
    }
}