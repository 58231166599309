import { IVirtualDeviceCalendarItem } from "../../../API/v1/VirtualDevice/virtualDevice.common";
import { WeekScheduleInfo } from "../basic/weekScheduleSelector.data";

export enum LockScreenMode {
    screenOff = 'screenOff',
    screenSaver = 'screenSaver',
    maintenance = 'maintenance'
}

export enum ScreenOffScheduleMode {
    period = 'period',
    weekday = 'weekday'
}

export interface IScreenScheduleInfo<T> {
    enabled: boolean;
    schedule?: WeekScheduleInfo;
    isValid: boolean;
    copy(): T;
    transformToRawdata(options?: { appStart?: { data?: string, packageName?: string, className?: string, action?: string, extras?: { [key: string]: any }, overlay?: { data: string, type: string, extras?: any, styles?: any[] } } }): IVirtualDeviceCalendarItem;
    equal(b: T): boolean;
}

export class LockScreenInfo implements IScreenScheduleInfo<LockScreenInfo> {
    private _lockScreenMode: LockScreenMode;
    get lockScreenMode(): LockScreenMode {
        return this._lockScreenMode;
    }
    set lockScreenMode(m: LockScreenMode) {
        if (this._lockScreenMode !== m) {
            this.data = this.initData(this._lockScreenMode, m);
        }
    }
    lockScreenName: string;

    enabled: boolean;
    data: ScreenSaverInfo | ScreenOffInfo;
    backupData: { [mode: string]: any };

    get isValid(): boolean {
        return !this.enabled || (this.data && this.data.isValid) ? true : false;
    }

    constructor(rawData?: string) {
        let d: LockScreenInfo;
        if (rawData) {
            try {
                d = JSON.parse(rawData);
            }
            catch {
            }
        }

        this.backupData = {};
        if (d) {
            this.enabled = d.enabled;
            this._lockScreenMode = LockScreenMode[d._lockScreenMode];
            this.lockScreenName = d.lockScreenName;
            this.data = this.initData(LockScreenMode[this._lockScreenMode], this._lockScreenMode, JSON.stringify(d.data));
        }
        else {
            this.enabled = false;
            this._lockScreenMode = LockScreenMode.screenOff;
            this.lockScreenName = '';
            this.data = null;
        }
    }

    private initData(fromMode: LockScreenMode, toMode: LockScreenMode, rawData?: string): ScreenOffInfo | ScreenSaverInfo {
        this._lockScreenMode = toMode;
        if (fromMode !== toMode) {
            this.backupData[fromMode] = this.data;
        }

        switch (toMode) {
            case LockScreenMode.screenOff:
                {
                    return this.backupData[toMode] || new ScreenOffInfo(rawData);
                }
            case LockScreenMode.screenSaver:
                {
                    return this.backupData[toMode] || new ScreenSaverInfo(rawData);
                }
        }
    }

    copy(): LockScreenInfo {
        const copied: LockScreenInfo = new LockScreenInfo();
        copied.lockScreenMode = this.lockScreenMode;
        copied.lockScreenName = this.lockScreenName;
        copied.enabled = this.enabled;
        copied.data = this.data?.copy();

        return copied;
    }

    transformToRawdata(options?: { appStart?: { data?: string, packageName?: string, className?: string, action?: string, extras?: { [key: string]: any }, overlay?: { data: string, type: string, extras?: any, styles?: any[] } } }): IVirtualDeviceCalendarItem {
        if (!this.enabled) {
            return null;
        }

        // cast to child data's periodList
        const childCalendar: IVirtualDeviceCalendarItem = this.data.transformToRawdata(options);
        const copied: LockScreenInfo = this.copy();
        delete copied.backupData;
        const calendar: IVirtualDeviceCalendarItem = {
            metaData: { rawData: JSON.stringify(copied) },
            type: this.lockScreenMode,
            periodList: childCalendar.periodList,
            resource: childCalendar.resource
        };

        return calendar;
    }

    equal(b: LockScreenInfo): boolean {
        if (this.enabled !== b.enabled) {
            return false;
        }

        if (this.lockScreenMode !== b.lockScreenMode) {
            return false;
        }

        switch (b.lockScreenMode) {
            case LockScreenMode.screenOff:
                {
                    return (this.data as ScreenOffInfo).equal(b.data as ScreenOffInfo);
                }
            case LockScreenMode.screenSaver:
                {
                    return (this.data as ScreenSaverInfo).equal(b.data as ScreenSaverInfo);
                }
        }
    }
}

export class ScreenSaverInfo implements IScreenScheduleInfo<ScreenSaverInfo> {
    enabled: boolean = false;;
    schedule: WeekScheduleInfo;
    url: string; // screen saver playback url
    timeout: number; // idle time to trigger screen saver

    get isValid(): boolean {
        return !this.enabled || this.schedule.isValid(this.enabled) ? true : false;
    }

    constructor(rawData?: string) {
        let d: any;
        if (rawData) {
            try {
                // for activity, the input rawData maybe the format of lockScreen data structure
                d = JSON.parse(rawData);
                if (d.lockScreenName) {
                    d = new ScreenSaverInfo(JSON.stringify(d.data));
                }
            }
            catch {
            }
        }

        if (d) {
            this.enabled = d.enabled;
            this.url = d.url;
            this.timeout = d.timeout;
            this.schedule = new WeekScheduleInfo(JSON.stringify(d.schedule));
        }
        else {
            this.enabled = false;
            this.url = '';
            this.timeout = 300;
            this.schedule = new WeekScheduleInfo();
        }
    }

    transformToRawdata(options?: { appStart?: { data?: string, packageName?: string, className?: string, action?: string, extras?: { [key: string]: any }, overlay?: { data: string, type: string, extras?: any, styles?: any[] } } }): IVirtualDeviceCalendarItem {
        if (!this.enabled) {
            return null;
        }

        const calendar: IVirtualDeviceCalendarItem = {
            metaData: { rawData: JSON.stringify(this) },
            type: LockScreenMode.screenSaver,
            periodList: this.schedule.transformToRawdata(),
            resource: {
                data: this.url,
                timeout: this.timeout,
                interactive: false
            }
        };

        // if user updates the appStart and screensaver (by solution) simultaneously, apply the appStart changes to the screensaver setting data to generate the solution with updated appStart.
        if (options?.appStart) {
            calendar.resource.appStart = options.appStart;
        }

        return calendar;
    }

    copy(): ScreenSaverInfo {
        const copied = new ScreenSaverInfo();
        copied.enabled = this.enabled;
        copied.url = this.url;
        copied.timeout = this.timeout;
        copied.schedule = this.schedule.copy();

        return copied;
    }

    equal(b: ScreenSaverInfo): boolean {
        if (this.enabled !== b.enabled) {
            return false;
        }

        if (this.timeout !== b.timeout) {
            return false;
        }

        if (this.url !== b.url) {
            return false;
        }

        return WeekScheduleInfo.equal(this.schedule, b.schedule);
    }
}

export class ScreenOffInfo implements IScreenScheduleInfo<ScreenOffInfo> {
    enabled: boolean;
    schedule: WeekScheduleInfo;

    constructor(rawData?: string) {
        let d: any = null;
        if (rawData) {
            try {
                // for activity, the input rawData maybe the format of lockScreen data structure
                d = JSON.parse(rawData);
                if (d.lockScreenName) {
                    d = new ScreenOffInfo(JSON.stringify(d.data));
                }
            }
            catch {
            }
        }

        this.enabled = d ? d.enabled : false;
        this.schedule = d ? new WeekScheduleInfo(JSON.stringify(d.schedule)) : new WeekScheduleInfo();
    }

    get isValid(): boolean {
        return !this.enabled || this.schedule.isValid(this.enabled) ? true : false;
    }

    copy(): ScreenOffInfo {
        const s: ScreenOffInfo = new ScreenOffInfo();
        s.enabled = this.enabled;
        s.schedule = this.schedule.copy();

        return s;
    }

    transformToRawdata(options?: { appStart?: { data?: string, packageName?: string, className?: string, action?: string, extras?: { [key: string]: any }, overlay?: { data: string, type: string, extras?: any, styles?: any[] } } }): IVirtualDeviceCalendarItem {
        if (!this.enabled) {
            return null;
        }

        const calendar: IVirtualDeviceCalendarItem = {
            metaData: { rawData: JSON.stringify(this) },
            type: LockScreenMode.screenOff,
            periodList: this.schedule.transformToRawdata()
        };

        return calendar;
    }

    equal(b: ScreenOffInfo): boolean {
        if (this.enabled !== b.enabled) {
            return false;
        }

        return WeekScheduleInfo.equal(this.schedule, b.schedule);
    }
}