import { Directive, EventEmitter, Injectable, Output } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { map, concatMap, catchError } from "rxjs/operators";

import { NAService } from './API/na.service';
import { AccountService } from './entry/account.service';
import { AppCacheData, AppMode, AppPreviewCacheData } from "./app.data";
import { CustomizationItemInfo } from "./content/admin/customization/customization.data";
import { AppConfigService } from "./app.config";


@Directive()
@Injectable()
export class AppService {
    readonly FILE_SERVER_NAME: string = AppConfigService.configs.server.fileServer.baseUrl;
    readonly FILE_SERVER_PROTOCOL: string = AppConfigService.configs.server.fileServer.protocol;

    private _defaultBgUrl: string = 'assets/resource/bg.jpg';
    private _defaultLogoDarkUrl: string = 'assets/resource/logo_iadea.svg';
    private _defaultLogoLightUrl: string = 'assets/resource/logo_iadea_light.svg';

    private _appMode: AppMode = AppMode.normal;
    private _cacheDataList: { id: string, data: AppCacheData }[] = [];
    private _cacheID: string;

    get appMode(): AppMode {
        return this._appMode;
    }

    private _domain: string = '';
    private _isEnterprise: boolean = false;
    set domain(v: string) {
        this._domain = v;
        if (this._domain !== 'care.dev.iadea.oniadea.com') {
            this.reset();
        }
    }

    get isEnterprise(): boolean {
        return this._isEnterprise;
    }

    bgUrl: string;
    logoDarkUrl: string;
    logoLightUrl: string;

    @Output() onAppModeChanged = new EventEmitter<{ mode: AppMode, data: AppCacheData }>();

    constructor(
        private router: Router,
        private naSvc: NAService,
        private accountSvc: AccountService
    ) {
        this.accountSvc.loginChanged.subscribe((isLogin: boolean) => {
            if (isLogin) {
                this.reset();
            }
        });

        this.reset();
    }

    reset(): void {
        const hostname: string = window.location.hostname;
        if (hostname) {
            const match: string[] = hostname.match(/care.(.+).oniadea.com/);
            if (match && match[1]) {
                let testDomain: string = match[1];
                if (match[1].indexOf('.') > 0) {
                    //test account
                    testDomain = match[1].substring(match[1].indexOf('.') + 1);
                }

                if (testDomain !== "iadea" && testDomain !== 'accenture') {
                    //get the enterprise logo | bg | theme
                    this._isEnterprise = true;
                    const domain_splits: string[] = hostname.split('.');
                    this._domain = domain_splits[domain_splits.length - 3];
                    for (let i = domain_splits.length - 2; i < domain_splits.length; ++i) {
                        this._domain += '.' + domain_splits[i];
                    }
                }
            }
        }

        /*
        if (this._isEnterprise) {
            this.http.get(this.generateEnterpriseUrl('logo-dark'), { responseType: 'blob' }).subscribe(res => {
                const url: string = URL.createObjectURL(res);
                this.logoDarkUrl = url;
            });
            this.http.get(this.generateEnterpriseUrl('logo-light'), { responseType: 'blob' }).subscribe(res => {
                const url: string = URL.createObjectURL(res);
                this.logoLightUrl = url;
            });
            this.http.get(this.generateEnterpriseUrl('background-light'), { responseType: 'blob' }).subscribe(res => {
                const url: string = URL.createObjectURL(res);
                this.bgUrl = url;
            });
        }
        else {
            this.bgUrl = this._defaultBgUrl;
            this.logoDarkUrl = this._defaultLogoDarkUrl;
            this.logoLightUrl = this._defaultLogoLightUrl;
        }
        */

        this.bgUrl = this._isEnterprise ? this.generateEnterpriseUrl('background-light') : this._defaultBgUrl;
        this.logoDarkUrl = this._isEnterprise ? this.generateEnterpriseUrl('logo-dark') : this._defaultLogoDarkUrl;
        this.logoLightUrl = this._isEnterprise ? this.generateEnterpriseUrl('logo-light') : this._defaultLogoLightUrl;

    }

    private generateEnterpriseUrl(itemName: string): string {
        return this.FILE_SERVER_PROTOCOL + '://' + this.FILE_SERVER_NAME + '/enterprises/' + this._domain + '_' + itemName;
    }

    enterPreviewMode(fromUrl: string, demoRouteList: { route: string, hint?: string }[] = [], parseFunc: () => { bg: CustomizationItemInfo, logoDark: CustomizationItemInfo, logoLight: CustomizationItemInfo }, rawData?: any): void {
        fromUrl = fromUrl.replace(/(.+)\b\?(.+)/, '$1');
        if (this._appMode !== AppMode.preview) {
            this._appMode = AppMode.preview;

            const style: { bg: CustomizationItemInfo, logoDark: CustomizationItemInfo, logoLight: CustomizationItemInfo } = parseFunc();
            this.bgUrl = style.bg.urlAfter || this.bgUrl;
            this.logoDarkUrl = style.logoDark.urlAfter || this.logoDarkUrl;
            this.logoLightUrl = style.logoLight.urlAfter || this.logoLightUrl;

            this._cacheID = Date.now().toString();
            const cacheData = new AppPreviewCacheData(fromUrl, demoRouteList, style.bg.urlAfter, style.logoDark.urlAfter, style.logoLight.urlAfter, rawData);
            this.addCacheData(this._cacheID, cacheData);

            this.onAppModeChanged.emit({ mode: this._appMode, data: cacheData });
            this.router.navigate([demoRouteList[0].route]);
        }
    }

    enterNormalMode(): void {
        this._appMode = AppMode.normal;
        this.reset();

        const cID: string = this._cacheID;
        this._cacheID = null;
        const data: AppCacheData = this.getCacheData(cID);

        this.onAppModeChanged.emit({ mode: this._appMode, data: data });
        if (data.backURL) {
            this.router.navigate([data.backURL], { queryParams: { cid: cID } });
        }
    }

    goToRoute(route: string): void {
        this.router.navigate([route]);
    }

    getCacheData(id: string, removeIfExist: boolean = false): AppCacheData {
        const cache = this._cacheDataList.find(c => c.id === id);
        if (cache) {
            if (removeIfExist) {
                this._cacheDataList.splice(this._cacheDataList.indexOf(cache), 1);
            }

            return cache.data;
        }

        return null;
    }

    private addCacheData(id: string, data: AppCacheData): void {
        if (this._cacheDataList.length > 5) {
            this._cacheDataList.shift();
        }

        this._cacheDataList.push({ id: id, data: data });
    }

    save(parseFunc: () => { bg?: CustomizationItemInfo, logoDark?: CustomizationItemInfo, logoLight?: CustomizationItemInfo } = () => { return {} }): Observable<{ isFault: boolean }> {
        const style: { bg?: CustomizationItemInfo, logoDark?: CustomizationItemInfo, logoLight?: CustomizationItemInfo } = parseFunc();

        return of(true).pipe(
            concatMap(() => {
                if (style.bg && style.bg.urlAfter) {
                    const formData = new FormData();
                    formData.append('light', style.bg.raw);
                    return this.naSvc.setEnterpriseBackground(formData, this.accountSvc.token);
                }

                return of(true);
            }),
            concatMap(() => {
                const formData = new FormData();
                if (style.logoLight && style.logoLight.urlAfter) {
                    formData.append('light', style.logoLight.raw);
                }
                if (style.logoDark && style.logoDark.urlAfter) {
                    formData.append('dark', style.logoDark.raw);
                }

                return this.naSvc.setEnterpriseLogo(formData, this.accountSvc.token);
            }),
            map(() => {
                this._defaultBgUrl = style.bg.urlAfter || this._defaultBgUrl;
                this._defaultLogoDarkUrl = style.logoDark.urlAfter || this._defaultLogoDarkUrl;
                this._defaultLogoLightUrl = style.logoLight.urlAfter || this._defaultLogoLightUrl;

                this.reset();

                return {
                    isFault: false
                };
            }),
            catchError(err => {
                return of({
                    isFault: true,
                    errorMessage: err.toString()
                })
            })
        );
    }
}