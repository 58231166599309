import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../../api.base';
import { EnterpriseAccountBasicInfo } from './api.ent.data';

export interface IInviteEnterpriseAccountTxData {
    accountRole: string; // Admin | Editor | Viewer
    accountGroupID?: string;
    accountInfo: {
        accountEmail: string;
        firstName?: string;
        lastName?: string;
        accountCompanyName?: string;
        department?: string;
    };
    message?: string;
}

export class APIInviteEnterpriseAccountManager extends APIBaseManager<void, void, IInviteEnterpriseAccountTxData, EnterpriseAccountBasicInfo[]> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_InviteEnterpriseAccount';
        this._apiMethod = 'POST';
    }

    protected getRequestURL(pathParameters: void, queryParameters: void): string {
        return super.getRequestURL() + 'enterprises/accounts';
    }
}