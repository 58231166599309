import { Component } from '@angular/core';

import { DevFuncConfirmInterface } from '../../base/confirm/dev-func-confirm.interface';

@Component({
    templateUrl: './reboot-confirm-subform.component.html',
    styleUrls: ['../../base/base-config-form.component.css']
})
export class RebootConfirmSubFormComponent implements DevFuncConfirmInterface {
    private _actionData: any;
    set actionData(data: any) {
        this._actionData = data;
        if (this._actionData) {
            this._monitorDeviceNames = this._actionData.deviceNames;
        }
    }

    allowSubmitCallback: (isAllow: boolean) => void;

    _monitorDeviceNames: string;
}