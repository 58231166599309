<div>
    <div class="d-flex align-items-center">
        <label>{{ _title }}</label>
        <div *ngIf="_searchSupport" class="ml-auto">
            <div class="input-group input-group-sm">
                <div class="input-group-prepend">
                    <span class="input-group-text border-bottom-none">
                        <i class="fas fa-search"></i>
                    </span>
                </div>
                <input #search class="form-control border-bottom-none" type='text' [disabled]="_isLoading" name='search'
                    placeholder="Search device name" aria-label="Search device name">
            </div>
        </div>
        <div *ngIf="!_isEnterprise" class="btn-group ml-2" role="group" aria-label="switch between shared devices and your own devices">
            <button type="button" class="btn btn-sm btn-action btn-light border-bottom-none"
                [class.active]="_deviceOwnerType === _enumPlayerOwnerType.self"
                (click)="changeDeviceOwnerType(_enumPlayerOwnerType.self)">
                Own
            </button>
            <button type="button" class="btn btn-sm btn-action btn-light border-bottom-none"
                [class.active]="_deviceOwnerType === _enumPlayerOwnerType.shared"
                (click)="changeDeviceOwnerType(_enumPlayerOwnerType.shared)">
                Shared
            </button>
        </div>
    </div>
    <div class="list-group">
        <div class="list-group-item list-group-header black">
            <div class="row align-items-center">
                <div class="col-8 d-flex title">
                    <input type="checkbox" id="cb-th" class="cmn-checkbox"
                        [checked]="(_deviceOwnerType === _enumPlayerOwnerType.self && _allOwnerDeviceSelected) || (_deviceOwnerType === _enumPlayerOwnerType.shared && _allShareDeviceSelected)"
                        (change)="selectAllPlayer($event.target.checked)">
                    <label for="cb-th">
                        <svg>
                            <use xlink:href="#checkmark" />
                        </svg>
                        <span class="sr-only">Select all devices to monitor in the alert</span>
                    </label>
                    <span class="ml-2">Device name</span>
                    <span *ngIf="_sortSupport" class="sorter">
                        <i class="fas fa-caret-up sort-icon descend"
                            [ngClass]="_currentSortKey === constantSvc.DEVKEY_FAKE_DISPLAYNAME && _currentSortType === _enumSortType.descend ? 'active' : ''"
                            (click)="sortDescend(constantSvc.DEVKEY_FAKE_DISPLAYNAME)"></i>
                        <i class="fas fa-caret-down sort-icon ascend"
                            [ngClass]="_currentSortKey === constantSvc.DEVKEY_FAKE_DISPLAYNAME && _currentSortType === _enumSortType.ascend ? 'active' : ''"
                            (click)="sortAscend(constantSvc.DEVKEY_FAKE_DISPLAYNAME)"></i>
                    </span>
                </div>
                <div class="col-4 title">
                    <span>Model</span>
                    <span *ngIf="_sortSupport" class="sorter">
                        <i class="fas fa-caret-up sort-icon descend"
                            [ngClass]="_currentSortKey === constantSvc.DEVKEY_INFO_MODEL && _currentSortType === _enumSortType.descend ? 'active' : ''"
                            (click)="sortDescend(constantSvc.DEVKEY_INFO_MODEL)"></i>
                        <i class="fas fa-caret-down sort-icon ascend"
                            [ngClass]="_currentSortKey === constantSvc.DEVKEY_INFO_MODEL && _currentSortType === _enumSortType.ascend ? 'active' : ''"
                            (click)="sortAscend(constantSvc.DEVKEY_INFO_MODEL)"></i>
                    </span>
                </div>
            </div>
        </div>
        <div class="list-group-content list-group-hoverless">
            <ng-template ngFor let-device [ngForOf]="_displayDeviceList">
                <div class="list-group-item"
                    *ngIf="_isEnterprise || (_deviceOwnerType === _enumPlayerOwnerType.self && device.virtualDeviceOwnerID === accountSvc.accountID) || (_deviceOwnerType === _enumPlayerOwnerType.shared && device.virtualDeviceOwnerID !== accountSvc.accountID)">
                    <div class="row align-items-center">
                        <div class="col-8 d-flex align-items-center">
                            <input [id]="'cb-td-' + device.virtualId" type="checkbox" class="cmn-checkbox"
                                [checked]="_deviceCheckMap[device.virtualId]" (change)="selectPlayer(device, $event.target.checked)" />
                            <label [for]="'cb-td-' + device.virtualId">
                                <svg>
                                    <use xlink:href="#checkmark" />
                                </svg>
                                <span class="sr-only">Select device {{device?.virtualName}} to monitor</span>
                            </label>
                            <span class="ml-2 text-break">
                                {{ device?.virtualName || device?.virtualId }}
                            </span>
                        </div>
                        <div class="col-4 text-break">
                            {{ device?.currentSettings[constantSvc.DEVKEY_INFO_MODEL] }}
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
    <div class="loading" *ngIf="_isLoading">
        <p>Please wait...</p>
        <div class="loading-box"></div>
    </div>
</div>