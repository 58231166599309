import { Component } from '@angular/core';

import { BaseConfigFormComponent } from '../base/base-config-form.component';
import { DeviceInfo } from '../../device/data/device-info';
import { DIALOG_NAME_FIRMWARE_UPDATE, ConfigDialogPage } from '../base/base-config-data';
import { FirmwareFamilyInfo, FIRMWARE_UPDATE_METHOD, FirmwareUpdateScheduleInfo, AndroidGroupType } from './firmware-data';
import { LicenseScopeType } from '../../license/license.data';
import { HelperLib, SharePermissionType } from '../../../lib/common/helper.lib';
import { Logger } from '../../../lib/common/logger';

@Component({
    templateUrl: '../base/base-config-form.component.html',
    styleUrls: ['../base/base-config-form.component.css']
})
export class FirmwareUpdateFormComponent extends BaseConfigFormComponent {
    ngOnInit(): void {
        this.className = 'Firmware-update-form';
        this._dialogName = DIALOG_NAME_FIRMWARE_UPDATE;
        this._licenseScopeType = LicenseScopeType.firmwareUpdate;
        this._sharePermissionType = SharePermissionType.FirmwareUpdate;

        super.ngOnInit();
    }

    protected goNext(fromPage?: ConfigDialogPage): void {
        if (this._page === ConfigDialogPage.checking) {
            this._actionData.firmwareFamilyInfo = this.init_firmware_family(this._legalDevices, this._actionData.firmwareFamilyInfo);
            this._actionData.schedule = this._actionData.schedule || new FirmwareUpdateScheduleInfo();
        }

        super.goNext();
    }

    private init_firmware_family(legalDevices: DeviceInfo[], currentFamilyInfos?: FirmwareFamilyInfo[]): FirmwareFamilyInfo[] {
        if (legalDevices.length === 0) {
            return [];
        }

        const group_obj: { [familyName: string]: { androidGroup: AndroidGroupType, deviceList: DeviceInfo[] } } = legalDevices.filter(d => d.isSelect || this._bSpecific).reduce((prev, curr) => {
            const firmware_version: string = curr.currentSettings[this.constantSvc.DEVKEY_INFO_FW];
            const target_android_type: AndroidGroupType = HelperLib.getAndroidGroup(firmware_version);
            const familyID: string = curr.currentSettings[this.constantSvc.DEVKEY_INFO_MODEL] + '/' + target_android_type;
            prev[familyID] = prev[familyID] || { androidGroup: target_android_type, deviceList: [] };
            prev[familyID].deviceList.push(curr);

            return prev;
        }, {});

        return Object.keys(group_obj).map((familyID: string) => {
            let familyInfo: FirmwareFamilyInfo;
            const familyname_pieces: string[] = familyID.split('/');

            let found: boolean = false;
            if (currentFamilyInfos) {
                const current_family_info: FirmwareFamilyInfo = currentFamilyInfos.find(currentFamilyInfo => currentFamilyInfo.familyId === familyID);
                if (current_family_info) {
                    familyInfo = current_family_info;
                    familyInfo.devices = group_obj[familyID].deviceList;
                    found = true;
                }
            }

            if (!found) {
                familyInfo = new FirmwareFamilyInfo();
                familyInfo.familyId = familyID;
                familyInfo.familyDisplayName = familyname_pieces[0];
                familyInfo.modelName = familyname_pieces[0];
                familyInfo.targetAndroidVersion = group_obj[familyID].androidGroup;
                familyInfo.devices = group_obj[familyID].deviceList;
            }

            return familyInfo;
        });
    }

    protected submit(): void {
        super.submit();

        Logger.logInfo(this.className, 'submit', 'Submit data = ', this._actionData);

        if (!this._actionData) {
            Logger.logError(this.className, 'submit', 'No valid data');
            //do something?
            return;
        }

        if (!this._actionData.firmwareFamilyInfo) {
            Logger.logError(this.className, 'submit', 'No valid firmware info');
            //do something?
            return;
        }

        const reqDataList: { device: DeviceInfo, downloadLink: string, downloadVersion: string, md5?: string }[] = [];
        this._actionData.firmwareFamilyInfo.forEach((f: FirmwareFamilyInfo) => {
            if (!f.needUpdate || f.devices.length === 0) {
                return;
            }

            if (f.updateMethod === FIRMWARE_UPDATE_METHOD.Latest && !f.updateInfo) {
                return;
            }

            let downloadLink: string = '';
            let downloadVersion: string = '';
            switch (f.updateMethod) {
                case FIRMWARE_UPDATE_METHOD.Latest:
                    {
                        downloadLink = f.updateInfo.link;
                        downloadVersion = f.updateInfo.firmwareVersion;
                    }
                    break;
                case FIRMWARE_UPDATE_METHOD.FromURL:
                    {
                        downloadLink = f.customURL;
                    }
                    break;
            }

            f.devices.forEach(d => {
                if (!this._bSpecific && !d.isSelect) {
                    return;
                }

                if (f.updateMethod === FIRMWARE_UPDATE_METHOD.Latest && d.currentSettings[this.constantSvc.DEVKEY_INFO_FW] === f.updateInfo.firmwareVersion) {
                    return;
                }

                reqDataList.push({ device: d, downloadLink: downloadLink, downloadVersion: downloadVersion });
            });
        });

        let scheduleDate: Date;
        if (this._actionData.schedule) {
            const schedule: FirmwareUpdateScheduleInfo = this._actionData.schedule as FirmwareUpdateScheduleInfo;
            if (schedule && schedule.enabled) {
                scheduleDate = schedule.toDate();
            }
        }

        this.devSvc.batchFirmwareUpdate(reqDataList, scheduleDate).subscribe(res => {
            this._page++;
            if (res.isFault()) {
                this._errorMessage = HelperLib.getErrorMessage(res);
            }

            if (this.dialogCompleteHandler) {
                this.dialogCompleteHandler(res);
            }
        });
    }
}