import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../../api.base';
import { EnterpriseAccountGroupBasicInfo } from './api.ent.data';

export interface IIAssignDeviceGroupToEnterpriseAccountGroupPathParameter {
    accountGroupID: string;
}

export interface IAssignDeviceGroupToEnterpriseAccountGroupTxData {
    accountGroupID: string;
    deviceGroupIDList: string[];
}

export class APIAssignDeviceGroupToEnterpriseAccountGroupManager extends APIBaseManager<IIAssignDeviceGroupToEnterpriseAccountGroupPathParameter, void, IAssignDeviceGroupToEnterpriseAccountGroupTxData, EnterpriseAccountGroupBasicInfo> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_AssignDeviceGroupToEnterpriseAccountGroup';
        this._apiMethod = 'POST';
    }

    protected getRequestURL(pathParameters: IIAssignDeviceGroupToEnterpriseAccountGroupPathParameter, queryParameters: void): string {
        return super.getRequestURL() + 'enterprises/accountGroups/' + pathParameters.accountGroupID;
    }
}