import { Component } from '@angular/core';

import { AccountService } from '../account.service';
import { NAService } from '../../API/na.service';
import { IAPIRx } from '../../API/api.base';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { DialogPage } from '../../../app/lib/common/common.data';

@Component({
    templateUrl: './signup-activation.component.html',
    styleUrls: ['../entry.style.css']
})
export class SignUpActivationComponent {
    _activateCode: string;
    _account: string;

    _page: DialogPage = DialogPage.action;
    _enumPage: typeof DialogPage = DialogPage;

    _errorMessage: string;

    constructor(private route: ActivatedRoute, private accountSvc: AccountService, private naSvc: NAService) {
        this.route.paramMap
            .subscribe((params: ParamMap) => {
                this._account = params.get('account');
            });
    }

    submit(): void {
        this._page = DialogPage.submit;
        this._errorMessage = null;

        this.naSvc.verifySignUp({ accountEmail: this._account, activationCode: this._activateCode }).subscribe((res: IAPIRx<void>) => {
            if (res.error !== 0) {
                this._errorMessage = res.errorMessage.startsWith('[info]') ? res.errorMessage.substring(6) : 'Account activation failed.';
            }

            this._page = DialogPage.result;
        });
    }
}