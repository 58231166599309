import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../../api.base';

export class APISetEnterpriseCustomizeBackgroundManager extends APIBaseManager<void, void, FormData, any> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_SetEnterpriseCustomizeBackground';
        this._apiMethod = 'POST';
    }

    protected getRequestURL(pathParameters: void, queryParameters: void): string {
        return super.getRequestURL() + 'enterprises/backgroundImages';
    }
}