import { Component } from '@angular/core';
import { IUserDlgFuncContent } from '../user-dlg-func.def';
import { UserGroupInfo } from '../../group/user-group.data';

@Component({
    templateUrl: './user-group-dlg-remove-content.component.html',
    styleUrls: ['../../user.style.css']
})
export class UserGroupRemoveDlgContentComponent implements IUserDlgFuncContent<UserGroupInfo, void> {
    data: UserGroupInfo;
    funcName: string;
    other?: void;
    onActionPrepared: () => void;
    onActionValidChanged: (valid: boolean) => void;
}