import { DeviceInfo } from '../../device/data/device-info';

export enum AndroidGroupType {
    And_44 = '4.4',
    And_71 = '7.1',
    And_9 = '9.0',
    And_12 = '12.0'
}

export class FirmwareUpdateInfo {
    modelName: string;
    modelDescription: string;
    PCBRevision: number;
    firmwareVersion: string;
    link: string;
    releaseDate: string;
    notice: string;
}

export enum FIRMWARE_UPDATE_METHOD {
    Unknown = 1,
    Latest,
    FromURL
}

export class FirmwareUpdateScheduleInfo {
    enabled: boolean;
    date: string;
    time: string;
    offset: number;

    constructor() {
        this.enabled = false;
        this.date = '';
        this.time = '00:00';
        this.offset = -(new Date().getTimezoneOffset() / 60);
    }

    toDate(): Date {
        if (!this.enabled) {
            return null;
        }

        return new Date(this.date + ' ' + this.time + ' GMT' + (this.offset >= 0 ? '+' : '') + this.offset + '00'); //.toISOString();
    }
}

export class FirmwareFamilyInfo {
    familyId: string;
    familyDisplayName: string;
    devices: DeviceInfo[];
    modelName: string;
    targetAndroidVersion: AndroidGroupType;
    updateInfo: FirmwareUpdateInfo;
    updateMethod: FIRMWARE_UPDATE_METHOD;
    customURL: string;
    needUpdate: boolean;

    constructor() {
        this.devices = [];
        this.updateMethod = FIRMWARE_UPDATE_METHOD.Unknown;
        this.needUpdate = false;
    }
}

