import { Injectable } from '@angular/core';
import { Type } from '@angular/core';
import { LoginIAdeaComponent } from './iadea/login-iadea.component';
import { LoginAccentureComponent } from './accenture/login-accenture.component';

export class LoginCompanyItem {
  constructor(public component: Type<any>, public company: string, public data?: any) {}
}

@Injectable()
export class LoginCompanyService {
  readonly COMPANY_IADEA: string = 'iadea';
  readonly COMPANY_ACCENTURE: string = 'accenture';

  private _loginCompanyList: LoginCompanyItem[] = [];

  constructor() {
    this._loginCompanyList = [
      new LoginCompanyItem(LoginIAdeaComponent, this.COMPANY_IADEA),
      new LoginCompanyItem(LoginAccentureComponent, this.COMPANY_ACCENTURE)
    ];
  }

  getByName(company: string): LoginCompanyItem {
    return this._loginCompanyList.find(i => i.company === company);
  }
}
