<div *ngIf="isInEdit; else templateViewMode">
    <div>
        <na-cert-pickup [title]="'Policy certificates'" [sourceCerts]="_usedCerts" [supportCertAddition]="false" [supportCertRemoval]="false" [numberInPage]="15" (onCertSelected)="onUsedCertSelected($event)"></na-cert-pickup>
    </div>
    <div class="d-flex justify-content-center my-4">
        <button type="button" class="btn btn-sm btn-action btn-light" [disabled]="_selectedAvailableCerts.length === 0" (click)="addCertificate()">
            <i class="fas fa-arrow-up mr-1"></i>
            Add
        </button>
        <button type="button" class="btn btn-sm btn-action btn-light ml-2" [disabled]="_selectedUsedCerts.length === 0" (click)="releaseCertificate()">
            <i class="fas fa-arrow-down mr-1"></i>
            Remove
        </button>
    </div>
    <div>
        <na-cert-pickup [title]="'Certificate manager'" [lockedCerts]="_usedCerts" [supportCertMgrLink]="true" [supportCertAddition]="false" [supportCertRemoval]="false" [numberInPage]="15" (onCertSelected)="onAvailableCertSelected($event)"></na-cert-pickup>
    </div>
</div>
<ng-template #templateViewMode>
    <div>
        <na-cert-pickup [title]="'Policy certificates'" [supportSearch]="false" [supportCertSelection]="false" [sourceCerts]="_usedCerts" [supportCertAddition]="false" [supportCertRemoval]="false" [numberInPage]="15"></na-cert-pickup>
    </div>
</ng-template>