<div class="dev-rctrl">
    <div class="header">
        <h4 class="mb-0">Remote control</h4>
        <div class="name">{{ _device?.virtualName }}</div>
    </div>
    <div *ngIf="_errorMessage" class="block block-error text-center mt-1">
        {{ _errorMessage }}
    </div>
    <div class="main" [class.d-none]="_initMsg">
        <div class="projection">
            <div class="canvas" [class.dragable]="_isPanMode" [style.width.%]="_zoomRatio"
                [style.top.px]="_panModeOffset.y" [style.left.px]="_panModeOffset.x">
                <img #img width="100%" height="100%" [src]="_currentBase64Image" alt=""
                    [style.display]="_currentBase64Image ? 'inherit' : 'none'"
                    [style.transform]="'rotate(' + _rotateDegree + 'deg)'" draggable="true" />
            </div>
        </div>
        <div class="setting-wrapper">
            <div data-tooltip="Connect" *ngIf="!_isConnect; else templateDisconnect" class="setting"
                [class.disabled]="!_room" (click)="startConnect()">
                <i class="fas fa-play-circle"></i>
            </div>
            <ng-template #templateDisconnect>
                <div data-tooltip="Disconnect" class="setting" (click)="stopConnect()">
                    <i class="fas fa-stop-circle"></i>
                </div>
            </ng-template>
            <div data-tooltip="Zoom out" class="setting" [class.disabled]="!_zoomOutSupport || !_currentBase64Image"
                (click)="zoomOut()">
                <i class="fas fa-search-minus"></i>
            </div>
            <div data-tooltip="Zoom in" class="setting" [class.disabled]="!_zoomInSupport || !_currentBase64Image"
                (click)="zoomIn()">
                <i class="fas fa-search-plus"></i>
            </div>
            <div data-tooltip="Pan mode" class="setting" [class.active]="_isPanMode"
                [class.disabled]="!_currentBase64Image" (click)="panMode()">
                <i class="fas fa-arrows-alt"></i>
            </div>
            <div class="dropright">
                <div data-tooltip="Rotate" class="setting" [class.disabled]="!_currentBase64Image"
                    [attr.data-toggle]="_currentBase64Image ? 'dropdown' : ''" aria-expanded="false">
                    <i class="fas fa-sync-alt"></i>
                </div>
                <div class="dropdown-menu p-0">
                    <div class="rotate-wrapper">
                        <div *ngFor="let degree of ROTATE_DEGREE_OPTIONS" class="rotate-option"
                            [class.active]="_rotateDegree === degree" (click)="rotate(degree)">
                            <i class="fas fa-arrow-alt-circle-up" [style.transform]="'rotate(' + degree + 'deg)'"></i>
                            <div>{{ degree }}&#176;</div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="_keyEventSupport" data-tooltip="Text" class="setting" [class.disabled]="!_currentBase64Image"
                [attr.data-toggle]="_currentBase64Image ? 'modal' : ''" data-target="#sendTxtModal">
                <i class="fas fa-font"></i>
            </div>
            <div data-tooltip="Take screenshot" class="setting" [class.disabled]="!_currentBase64Image"
                [attr.data-toggle]="_currentBase64Image ? 'modal' : ''" data-target="#takephotoModal">
                <i class="fas fa-camera"></i>
            </div>
            <div data-tooltip="Setting page" class="setting" [class.disabled]="!_isConnect" (click)="sendHomeCmd()">
                <i class="fas fa-cog"></i>
            </div>
            <div #idleDlgPopBtn class="d-none" data-toggle="modal" data-target="#idleModal"></div>
            <div #warnDlgPopBtn class="d-none" data-toggle="modal" data-target="#connectionWarnModal"></div>
        </div>
    </div>
    <div class="text-center mt-5" [class.d-none]="!_initMsg">
        <h3>{{ _initMsg }}</h3>
        <div *ngIf="_loading" class="loading">
            <div class="loading-box"></div>
        </div>
    </div>
</div>
<div id="sendTxtModal" class="modal fade" data-backdrop="static" data-keyboard="false" role="dialog" tabindex="-1">
    <div class='modal-dialog config-dlg' role="document">
        <div class='modal-content'>
            <div class='modal-header'>
                <h4 class="modal-title">Text</h4>
                <button #dlgClose type="button" class="close" data-dismiss="modal">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class='modal-body'>
                <div>
                    <div class="input-group">
                        <input #txtInput type="text" class="form-control" aria-label="String content"
                            [(ngModel)]="_textstr">
                    </div>
                </div>
                <div class="d-flex mt-4">
                    <button type="button" class="btn btn-sm btn-action btn-light ml-auto" data-dismiss="modal">
                        <span class="fas fa-times-circle"></span>
                        {{ 'key-cancel' | translate | capitalFirstWord }}
                    </button>
                    <button type="button" class="btn btn-sm btn-action btn-light ml-2" data-dismiss="modal"
                        [disabled]="!_textstr" (click)="sendText(txtInput);">
                        <span class="fas fa-check"></span>
                        {{ 'key-ok' | translate | capitalFirstWord }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<div id="takephotoModal" class="modal fade" data-backdrop="static" data-keyboard="false" role="dialog" tabindex="-1">
    <div class='modal-dialog config-dlg' role="document">
        <div class='modal-content'>
            <div class='modal-header'>
                <h4 class="modal-title">Download screenshot</h4>
                <button #dlgClose type="button" class="close" data-dismiss="modal">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class='modal-body'>
                <div>
                    <div class="input-group">
                        <input #screenshotName type="text" class="form-control" placeholder="screenshot name"
                            aria-label="Image name" aria-describedby="txtPhotoName">
                        <div class="input-group-append">
                            <div id="txtPhotoName" class="input-group-text">.jpg</div>
                        </div>
                    </div>
                </div>
                <div class="d-flex mt-4">
                    <button type="button" class="btn btn-sm btn-action btn-light ml-auto" data-dismiss="modal">
                        <span class="fas fa-times-circle"></span>
                        {{ 'key-cancel' | translate | capitalFirstWord }}
                    </button>
                    <button type="button" class="btn btn-sm btn-action btn-light ml-2" data-dismiss="modal"
                        [disabled]="!screenshotName.value" (click)="downloadPhoto(screenshotName)">
                        <span class="fas fa-download"></span>
                        Download
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<div id="idleModal" class="modal fade" data-backdrop="static" data-keyboard="false" role="dialog" tabindex="-1">
    <div class='modal-dialog config-dlg' role="document">
        <div class='modal-content'>
            <div class='modal-header'>
                <h4 class="modal-title">Remote control connection</h4>
                <button #idleDlgCloseBtn type="button" class="close d-none" data-dismiss="modal">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class='modal-body'>
                <div class="text-center">
                    <p>The page has been left idle for more than 10 minutes.</p>
                    <div>Connection will automatically close in 2 minutes</div>
                    <strong>{{ _idleTimeout | date:'mm:ss' }}</strong>
                </div>
                <div class="text-center mt-4">
                    <button type="button" class="btn btn-sm btn-action btn-light mr-2" data-dismiss="modal"
                        (click)="resetIdleTimeout()">
                        {{ 'Keep use' | translate | capitalFirstWord }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<div id="connectionWarnModal" class="modal fade" data-backdrop="static" data-keyboard="false" role="dialog"
    tabindex="-1">
    <div class='modal-dialog config-dlg' role="document">
        <div class='modal-content'>
            <div class='modal-header'>
                <h4 class="modal-title">Remote control connection</h4>
            </div>
            <div class='modal-body'>
                <div class="text-center">
                    <p>{{ _connectionMsg?.title }}</p>
                    <div>{{ _connectionMsg?.content }}</div>
                    <div *ngIf="_connectionMsg?.showMsgTimeout">{{ _connectionMsg.timeout | date:'mm:ss' }}</div>
                </div>
                <div class="text-center mt-4">
                    <button #warnDlgCloseBtn type="button" class="btn btn-sm btn-action btn-light" data-dismiss="modal">
                        {{ 'key-ok' | translate | capitalFirstWord }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>