import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ConstantService } from '../../../app/lib/common/constant.service';
import { IUIElement } from '../uiElement.interface';

@Component({
    selector: 'na-screen-orientation',
    templateUrl: './screen-orientation.component.html',
    styleUrls: ['../uiElement.style.css']
})
export class ScreenOrientationComponent implements IUIElement {

    _unsupportReason: string;
    _disabled: boolean;

    _title: string = 'Display orientation';
    @Input('title')
    set title(v: string) {
        this._title = v;
    }

    _orientation: string | number;
    @Input('data')
    set data(v: string | number) {
        this._orientation = v;
    }

    _lockInfo: { isSync: boolean, policyID: string, policyName: string };
    @Input('lock')
    set lock(d: { isSync: boolean, policyID: string, policyName: string }) {
        this._lockInfo = d;
    }

    _availableOrientationList: (string | number)[] = [];
    @Input('availableOptionList')
    set availableOptionList(v: (string | number)[]) {
        this._availableOrientationList = v;
    }

    @Output() changed = new EventEmitter<string | number>();

    constructor(private constantSvc: ConstantService) {
        this._availableOrientationList = this.constantSvc.DISPLAY_ORIENTATION_LIST;
    }

    changeOrientation(orientation: string | number): void {
        if (this._orientation !== orientation) {
            this._orientation = orientation;
            this.changed.emit(orientation);
        }
        
    }
}