export const LANG_TW_NAME: string = 'zh-tw';

export const LANG_TW_TRANS = {
    //device
    'key-dev-manage': '機器管理',
    'key-dev-tagname': '虛擬名稱',
    'key-dev-name': '名稱',
    'key-dev-model': '機器型號',
    'key-dev-time': '機器時間',
    'key-dev-mainMAC': '主要實體位址',
    'key-dev-MAC': '實體位址',
    'key-dev-lanMAC': '區網實體位址',
    'key-dev-wifiMAC': '無線網路實體位址',
    'key-dev-firmwareVersion': '韌體版本',
    'key-dev-apkVersion': 'APK版本',
    'key-dev-warrantyExpireDate': '機器保固期限',
    'key-dev-refresh': '更新機器狀態',
    'key-dev-activity': '用戶歷史行為',
    'key-dev-activity-track': '追蹤用戶歷史行為',
    'key-dev-activity-refresh': '更新用戶歷史行為狀態',
    'key-dev-firmwareUpdate': '韌體更新',
    'key-dev-firmwareDownload': '韌體下載',
    'key-dev-firmwareInstall': '韌體安裝',
    'key-dev-apkUpdate': 'APK更新',
    'key-dev-apkDownload': 'APK下載',
    'key-dev-apkInstall': 'APK安裝',
    'key-dev-reboot': '重新啟動',
    'key-dev-heartbeat': '上次更新時間',
    'key-dev-pairCode': '配對號碼',
    'key-dev-pairAction': '配對行為',
    'key-dev-overview': '機器概況',
    'key-dev-amount': '機器總數',
    'key-dev-basicConfig': '基本設定',
    'key-dev-netConfig': '網路設定',
    'key-dev-timezone': '時區',
    'key-dev-resolution': '輸出解析度',
    'key-dev-orientation': '螢幕旋轉角度',
    'key-dev-dailyRebootEnable': '啟用每日機器重啟功能',
    'key-dev-dailyRebootTime': '機器重啟時間',
    'key-dev-dailyReboot': '機器重啟',
    'key-dev-screenCaptureTime': '上一次畫面更新時間',
    'key-dev-screenCaptureUpdate': '畫面更新',
    'key-dev-usbDebugEnable': 'USB偵錯功能',
    'key-dev-tcpDebugEnable': 'TCP偵錯功能',
    'key-dev-hdcpEnable': 'HDCP',
    'key-dev-optionRefresh': '更新機器可調參數',
    'key-dev-settingRefresh': '更新機器設定',
    'key-dev-licenseRefresh': '更新機器憑證',
    'key-dev-contentUrl': '播放內容網址',
    'key-dev-appstart': 'AppStart',
    'key-dev-owner': '擁有者',

    //network
    'key-net-ip': 'IP',
    'key-net-gateway': '閘道器',
    'key-net-mask': '子網路遮罩',
    'key-net-useStatic': '使用靜態IP',
    'key-net-selectSSID': '選擇SSID',
    'key-net-enableDHCP': '啟用DHCP',
    'key-net-enableLan': '啟用乙太網路',
    'key-net-enableWifi': '啟用Wifi',
    
    //firmware
    'key-firmware-linkRemind': '請提供韌體更新連結',
    'key-firmware-family': '韌體分類',

    //general
    'key-login': '登入',
    'key-logout': '登出',
    'key-signup': '註冊',
    'key-search': '搜尋',
    'key-lang': '語言',
    'key-theme': '主題',
    'key-general': '一般',
    'key-network': '網路',
    'key-license': '憑證',
    'key-warranty': '保固期限',
    'key-expireDate': '到期時間',
    'key-issueDate': '發起時間',
    'key-beginDate': '開始時間',
    'key-actDate': '啟用時間',
    'key-endDate': '結束時間',
    'key-status': '狀態',
    'key-activity': '行為',
    'key-lastTrackTime': '上次追蹤時間',
    'key-close': '關閉',
    'key-refresh': '刷新',
    'key-update': '更新',
    'key-cancel': '取消',
    'key-apply': '採用',
    'key-currentVer': '目前版本',
    'key-latestVer': '最新版本',
    'key-fromUrl': '從指定URL選取',
    'key-updateMethod': '選取更新方式',
    'key-version': '版本',
    'key-releaseDate': '出版日期',
    'key-detail': '詳細資訊',
    'key-advanceCtrl': '進階設定',
    'key-device': '裝置',
    'key-ok': 'OK',
    'key-questionnaire': '問卷',
    'key-noAvail': '無法取得',
    'key-finish': '完成',
    'key-fail': '錯誤',
    'key-pending': '等待中',
    'key-progress': '處理中',
    'key-next': '下一步',
    'key-prev': '上一步',
    'key-password': '密碼',
    'key-security': '加密方式',
    'key-ethernet': '乙太網路',
    'key-wifi': 'wifi',
    'key-name': '名稱',
    'key-type': '類別',
    'key-usage': '使用 / 總共',
    'key-duration': '持續時間',
    'key-day': '天',
    'key-assignee': '指定的機器',
    'key-product': '產品序號',
    'key-volume': '音量',
    'key-volumeMax': '最大音量',
    'key-screenoff': '關閉螢幕',
    'key-maintenancePlayback': 'maintenance playback',
    'key-lockScreen': '螢幕鎖定',
    'key-powersave': 'power save',
    'key-screenSaver': 'screen saver',
    'key-timeServer': 'time server',
    'key-timeServerLocation': 'time server同步位置',
    'key-failSafe': 'FailSafe',
    'key-debug': '偵錯',
    'key-group': '組別',

    //view
    'key-view-changeCol': '增減欄位',
    'key-view-table': '表格視圖',
    'key-view-grid': '網狀視圖',

    //trouble-shoot
    'key-troubleshoot': '故障偵錯',

    //nav
    'key-nav-home': '主頁',
    'key-nav-setting': '設定',
    'key-nav-troubleshoot': '偵錯報告',
    'key-nav-subscribe': '訂閱資訊',
}