import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../api.base';
import { IVirtualDeviceRxData } from './virtualDevice.common';

export interface IShareDevicePathParameter {
    virtualDeviceID: string;
}

export interface IShareDeviceTxData {
    sharingList: {
        accountName: string;
        permission: string[];
    }[]
}

export class APIShareDeviceManager extends APIBaseManager<IShareDevicePathParameter, void, IShareDeviceTxData, IVirtualDeviceRxData> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_ShareDevice';
        this._apiMethod = 'POST';
    }

    protected getRequestURL(pathParameters: IShareDevicePathParameter, queryParameters: void): string {
        return super.getRequestURL() + 'virtualDevices/' + pathParameters.virtualDeviceID + '/share';
    }
}