import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../../api.base';
import { GroupRawInfo } from './api.group.common';

export interface IUpdateGroupPathParameter {
    deviceGroupID: string;
}

export interface IUpdateGroupTxData {
    groupName?: string; //renaming group name
    parentID?: string; //moving to another group id
}

export class APIUpdateGroupManager extends APIBaseManager<IUpdateGroupPathParameter, void, IUpdateGroupTxData, GroupRawInfo> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_UpdateDevGroup';
        this._apiMethod = 'PATCH';
    }

    protected getRequestURL(pathParameters: IUpdateGroupPathParameter, queryParameters: void): string {
        return super.getRequestURL() + 'deviceGroups/' + pathParameters.deviceGroupID;
    }
}