import { Type } from '@angular/core';
import { ScepServerInfo } from '../scep.data';

export const SCEP_FUNC_ADD: string = 'scep-add';
export const SCEP_FUNC_REMOVE: string = 'scep-remove';
export const SCEP_FUNC_UPDATE: string = 'scep-update';
export const SCEP_FUNC_RENEW_DEVICE: string = 'scep-dev-renew';
export const SCEP_FUNC_ADD_DEVICE: string = 'scep-dev-add';

export class ScepFuncItem {
  constructor(public component: Type<any>, public name: string, public title: string) {}
}

export interface IScepFuncCtrl {
  title: string;
  funcName: string;
  scepList: ScepServerInfo[];
  other?: any;
  onActionCompleted: (ret: { funcName: string, isFault: boolean, data?: any, errorMessage?: string }) => void;
}