<div class="page-not-found">
    <div class="logo pt-4">
        <img [src]="appSvc.logoDarkUrl | safe: 'url'">
    </div>
    <div class="text-center text-shadow mt-4">
        <h1>404</h1>
        <h3>The page you are looking for doesn't exist or an other error occurred.</h3>
        <h3>We sincerely apologize</h3>
        <div class="home-link">
            <a [routerLink]="['/app/device/devices']">Go Home</a>
        </div>
    </div>
</div>