<div class='modal-dialog config-dlg' role="document">
    <div class='modal-content'>
        <div class='modal-header'>
            <span class="modal-title">{{ title }}</span>
            <button #dlgClose type="button" class="close" data-dismiss="modal">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <a #reportLink class="d-none"></a>
            <div *ngIf="!_loading; else templateLoading">
                <div *ngIf="_errorMessage; else templateNoError">
                    <div class="block  block-error">
                        <div>
                            <i class="fas fa-exclamation-triangle"></i>
                            <span class="ml-2">Fail</span>
                        </div>
                        <div>
                            {{ _errorMessage }}
                        </div>
                    </div>
                </div>
                <ng-template #templateNoError>
                    <div class="block  block-success">
                        <div>
                            <i class="fas fa-check-circle white"></i>
                            <span class="ml-2">Success!</span>
                            <div>
                                You can retrieve the secret access key only when you initially create the key pair.
                                If you lose it you must create a new key pair.
                            </div>
                        </div>
                    </div>
                    <div class="mt-2">
                        <button type="button" class="btn btn-sm btn-action btn-light" (click)="downloadKey()">
                            <i class="fas fa-download"></i>
                            <span class="ml-2">Download .csv file</span>
                        </button>
                        <button type="button" class="btn btn-sm btn-action btn-light ml-2" data-toggle="collapse"
                            data-target="#acckey-appSetting" aria-expanded="false">
                            App setting
                            <i class="fas fa-caret-down icon ml-2"></i>
                            <i class="fas fa-caret-up icon-expand ml-2"></i>
                        </button>
                    </div>
                    <div id="acckey-appSetting" class="collapse">
                        <div class="d-flex mt-2">
                            <div *ngFor="let appSetting of _appSettingList" class="app-setting"
                                (click)="showAppSetting(appSetting)">
                                {{ appSetting.appName }}
                            </div>
                        </div>
                        <div class="mt-2">
                            <div class="card">
                                <div class="card-header d-flex align-items-center">
                                    {{ _appSetting?.appName }} app setting
                                    <i class="fas fa-clone ml-auto" (click)="copyAppSetting()"></i>
                                </div>
                                <div>
                                    <div class="code-block">
                                        <div *ngIf="!_appSetting; else templateAppSetting" class="text-center m-4">
                                            Select one app</div>
                                        <ng-template #templateAppSetting>
                                            <div *ngIf="!_appSettingStatus.loading; else templateLoadingAppSetting">
                                                <div *ngIf="!_appSettingStatus.error; else templateAppSettingLoadFail">
                                                    <code>{{ _appSetting.setting }}</code>
                                                </div>
                                                <ng-template #templateAppSettingLoadFail>
                                                    {{ _appSettingStatus.error }}
                                                </ng-template>
                                            </div>
                                            <ng-template #templateLoadingAppSetting>
                                                <div class="loading">
                                                    <div class="loading-box"></div>
                                                </div>
                                            </ng-template>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="list-group mt-2">
                        <div class="list-group-item list-group-header">
                            <div class="row">
                                <span class="col title">AccessKey ID</span>
                                <span class="col title secret">
                                    Secret
                                    <span *ngIf="_showSecret; else templateHideSecretTitle">
                                        <i class="fas fa-eye-slash ml-2" (click)="showSecret(false)"></i>
                                    </span>
                                    <ng-template #templateHideSecretTitle>
                                        <i class="fas fa-eye ml-2" (click)="showSecret(true)"></i>
                                    </ng-template>
                                </span>
                            </div>
                        </div>
                        <div class="list-group-content list-group-hoverless">
                            <div class="list-group-item">
                                <div class="row align-items-center">
                                    <div class="col">{{ _key?.accessKeyID }}</div>
                                    <div class="col">
                                        <span *ngIf="_showSecret; else templateHideSecret">
                                            {{ _key?.accessKeySecret }}
                                        </span>
                                        <ng-template #templateHideSecret>
                                            ******
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <div class="row control-block d-flex justify-content-center mt-4">
                    <button type="button" class="btn btn-sm btn-action btn-light" data-dismiss="modal">
                        <span class="fas fa-times-circle icon-left"></span>
                        {{ 'key-close' | translate | capitalFirstWord }}
                    </button>
                </div>
            </div>
            <ng-template #templateLoading>
                <div class="loading">
                    <p>Creating access key, please wait ...</p>
                    <div class="loading-box"></div>
                </div>
            </ng-template>
        </div>
    </div>
</div>