import { HttpClient, HttpResponse } from '@angular/common/http';
import { IAPIRx, APIBaseManager } from '../../api.base';
import { Observable } from 'rxjs';

export interface IGetVirtualDeviceScreenshotPathParameter {
    virtualDeviceID: string;
    virtualDevicePairedID: string;
}

export interface IGetVirtualDeviceScreenshotQueryParameter {
    raw: boolean; //true for binary data return
    dateTimeOnly?: boolean; //true for return mtime by header only. It will overwrite the "raw" parameter
    dummy?: boolean; //client add this property only, not offical api parameter
}

export class APIGetVirtualDeviceScreenshotManager extends APIBaseManager<IGetVirtualDeviceScreenshotPathParameter, IGetVirtualDeviceScreenshotQueryParameter, void, any> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_GetVirtualDeviceScreenshot';
        this._apiMethod = 'GET';
    }

    protected getRequestURL(pathParameters: IGetVirtualDeviceScreenshotPathParameter, queryParameters: IGetVirtualDeviceScreenshotQueryParameter): string {
        return super.getRequestURL() + 'virtualDevices/' + pathParameters.virtualDeviceID + '/' + pathParameters.virtualDevicePairedID + '/screenshot' + this.transformObjectQueryParameterToStr(queryParameters);
    }

    public send(pathParameters: IGetVirtualDeviceScreenshotPathParameter, queryParameters: IGetVirtualDeviceScreenshotQueryParameter, txData: void, token: string = null, responseType: string = 'json'): Observable<any> {
        switch (this._apiMethod) {
            case 'GET':
                {
                    return super.send(pathParameters, queryParameters, null, token, null, { responseType: 'blob' });
                }
            default:
                {
                    return super.send(pathParameters, queryParameters, txData, token);
                }
        }
    }

    protected doGetAftermathAction(res: HttpResponse<IAPIRx<object>>): boolean {
        const serverTime = res.headers.get('last-modified');
        if (serverTime) {
            res.body.serverTime = new Date(serverTime).getTime();
        }

        return true;
    }
}