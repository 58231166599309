import { Component, OnInit } from '@angular/core';
import { IUserDlgFuncContent } from '../user-dlg-func.def';
import { UserInfo } from '../../user.data';

@Component({
    templateUrl: './user-dlg-resetPwd-content.component.html',
    styleUrls: ['../../user.style.css']
})
export class UserResetPwdDlgContentComponent implements IUserDlgFuncContent<UserInfo[], void>, OnInit {
    data: UserInfo[];
    funcName: string;
    other?: void;
    onActionPrepared: () => void;
    onActionValidChanged: (valid: boolean) => void;

    _user: UserInfo;

    ngOnInit(): void {
        this._user = this.data[0];
    }
}