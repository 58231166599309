<label class="title">
  Share device to
  <i *ngIf="_lockInfo" class="fas fa-lock ml-2 red-1" data-toggle="tooltip"
    [title]="'Lock by policy <<' + _lockInfo.policyName + '>>'"></i>
</label>
<div class="share-block">
  <ng-template ngFor let-s [ngForOf]="_shareList">
    <div *ngIf="s.action !== '-'" class="setting-item" [class.changed]="false">
      <div class="row align-items-top">
        <div class="col-md-1 col-12 mb-md-0 mb-1">
          <button class="btn btn-sm btn-action btn-action-red btn-light" data-toggle="tooltip"
            title="Remove share setting" [disabled]="_disabled" (click)="remove(s)">
            <i class="fas fa-times"></i>
            <span class="sr-only">Remove share setting</span>
          </button>
        </div>
        <div class="form-group col-lg-6 col-md-5 col-12 mb-md-0 mb-1">
          <input #account type="text" [attr.name]="s.accountID" class="form-control form-control-sm"
            placeholder='Enter shared account...' aria-label="Enter shared account"
            [disabled]="_disabled || !_hasLicense" [ngModel]="s.accountName"
            (ngModelChange)="changeAccountName(s, $event)">
          <div *ngIf="s.errorMessage" class="warning-block">{{ s.errorMessage }}</div>
        </div>
        <div class="col-lg-5 col-md-6 col-12 btn-ctrls">
          <button type="button" class="btn btn-sm btn-action btn-light" data-toggle="tooltip"
            title="Apply editor setting" [disabled]="_disabled || !_hasLicense" (click)="applyEditorMode(s)">
            Editor
          </button>
          <button type="button" class="btn btn-sm btn-action btn-light" data-toggle="tooltip"
            title="Apply viewer setting" [disabled]="_disabled || !_hasLicense" (click)="applyViewerMode(s)">
            Viewer
          </button>
          <button *ngIf="s.isChange()" type="button" class="btn btn-sm btn-action btn-light" data-toggle="tooltip"
            [disabled]="_disabled || !_hasLicense" title="Reset to initial setting" (click)="applyResetMode(s)">
            Reset
          </button>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-11 offset-md-1">
          <div class="permission-block">
            <span *ngFor="let p of s.permissionList">
              <input [id]="'permission-' + s.accountID + '-' + p.permission" type="checkbox" class="cmn-checkbox"
                [checked]="p.afterChecked" [disabled]="_disabled || !_hasLicense"
                (change)="changePermission(s, p, $event.target.checked)" />
              <label [for]="'permission-' + s.accountID + '-' + p.permission">
                <svg>
                  <use xlink:href="#checkmark" />
                </svg>
                <span class="ml-1">{{ p.displayName }}</span>
              </label>
            </span>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
<button *ngIf="_hasLicense" [disabled]="_disabled" class="btn btn-sm btn-action btn-light" (click)="add()">
  <i class="fas fa-plus"></i>
  <span class="ml-2">New</span>
</button>