<div class='modal-dialog config-dlg' role="document">
    <div class='modal-content'>
        <div class='modal-header'>
            <h4 class="modal-title">{{ title }}</h4>
        </div>
        <div class='modal-body my-2'>
            <div class="m-2">
                <div>
                    Select a certificate to upload.
                    <button type="button" class="btn btn-sm btn-action btn-light ml-2" [disabled]="_page === _enumPage.submit" (click)="triggerCertFilePicker()">
                        Select
                        <i class="fas fa-upload ml-2"></i>
                    </button>
                    <div *ngIf="_fileInfo" class="mt-4">
                        <strong>Certificate information :</strong>
                        <div class="container-fluid mt-2">
                            <div class="form-group row">
                                <label for="col-cert-name"
                                    class="col-6 col-sm-3 col-md-2 col-form-label col-form-label-sm">
                                    Name
                                </label>
                                <div class="col-6 col-sm-9 col-md-10">
                                    <div id="col-cert-name" class="form-control-plaintext-sm">
                                        {{ _fileInfo?.name }}
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="col-cert-type"
                                    class="col-6 col-sm-3 col-md-2 col-form-label col-form-label-sm">
                                    Type
                                </label>
                                <div class="col-6 col-sm-9 col-md-10">
                                    <div id="col-cert-type" class="form-control-plaintext-sm">
                                        {{ _fileInfo?.type }}
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="col-cert-size"
                                    class="col-6 col-sm-3 col-md-2 col-form-label col-form-label-sm">
                                    Size
                                </label>
                                <div class="col-6 col-sm-9 col-md-10">
                                    <div id="col-cert-size" class="form-control-plaintext-sm">
                                        {{ _fileInfo?.size }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <input #certFilePicker type="file" accept=".cer" class="d-none" (change)="onFileChange($event)">
                    <div *ngIf="_errorMessage" class="block block-error mt-1">
                        <div class="text-break">{{ _errorMessage }}</div>
                    </div>
                </div>
            </div>
            <div class="row control-block d-flex justify-content-center align-items-center mt-4">
                <button #btnClose type="button" class="btn btn-sm btn-action btn-light"
                    [disabled]="_page === _enumPage.submit" data-dismiss="modal">
                    <span class="fas fa-times-circle mr-1"></span>
                    {{ 'key-cancel' | translate | capitalFirstWord }}
                </button>
                <button type="button" class="btn btn-sm btn-action btn-light" [disabled]="_page === _enumPage.submit"
                    (click)="addCert()">
                    <span class="fas fa-plus-circle mr-1"></span>
                    Add
                </button>
                <div *ngIf="_page === _enumPage.submit" class="loading loading-p0 ml-4">
                    <div class="loading-box"></div>
                </div>
            </div>
        </div>
    </div>
</div>