import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Subject } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';

import { DialogPage } from '../../../../app/lib/common/common.data';
import { HelperLib } from '../../../../app/lib/common/helper.lib';
import { IScepFuncCtrl, ScepFuncItem, SCEP_FUNC_ADD_DEVICE, SCEP_FUNC_RENEW_DEVICE, SCEP_FUNC_UPDATE } from './form/scep-func.def';
import { ScepFuncDirective } from './form/scep-func.directive';
import { ScepFuncService } from './form/scep-func.service';
import { ScepDeviceInfo, ScepDeviceOperationMode, ScepServerInfo } from './scep.data';
import { ScepService } from './scep.service';
import { TopMenuService } from '../../../../app/content/menu.service';

@Component({
    templateUrl: './scep-detail-wrapper.component.html',
    styleUrls: ['./scep.style.css', './scep-detail-wrapper.component.css']
})
export class SCEPDetailWrapperComponent implements OnInit, OnDestroy {
    _scep: ScepServerInfo;
    _scepModify: ScepServerInfo;
    _selectedDeviceList: ScepDeviceInfo[] = [];
    _searchTxt: string;
    _scepRoute: string;

    _loading: boolean = false;
    _exporting: boolean = false;
    _isInEdit: boolean = false;
    _errorMessage: string;

    _page: DialogPage = DialogPage.prepare;
    _enumPage: typeof DialogPage = DialogPage;
    private _allUnsubscribe: Subject<void> = new Subject();

    private _scepFuncHost: ScepFuncDirective;
    @ViewChild(ScepFuncDirective, { static: true })
    set userFuncHost(host: any) {
        this._scepFuncHost = host;
    }

    private _btnUpdateElementRef: ElementRef;
    @ViewChild('btnUpdate', { static: false })
    set btnUpdate(v: any) {
        this._btnUpdateElementRef = v;
    }

    private _exportRef: ElementRef;
    @ViewChild('exportLink')
    set exportLink(holder: ElementRef) {
        if (holder) {
            this._exportRef = holder;
        }
    }

    constructor(
        private route: ActivatedRoute,
        public scepSvc: ScepService,
        private scepFuncSvc: ScepFuncService,
        private menuSvc: TopMenuService) {
    }

    ngOnInit(): void {
        this._scepRoute = this.menuSvc.getScepRoute();
        this._loading = true;

        this.route.paramMap.pipe(
            switchMap((params: ParamMap) => {
                return this.scepSvc.getScepByID(params.get('id'))
            })
        ).subscribe((res: { scep: ScepServerInfo, isFault: boolean, errorMessage?: string }) => {
            this._scep = res.scep;
            this._errorMessage = res.errorMessage;

            this._loading = false;
        });
    }

    ngOnDestroy(): void {
        this._allUnsubscribe.next();
        this._allUnsubscribe.complete();
    }

    startEdit(): void {
        this._isInEdit = true;
        this._scepModify = this._scep.copy();
    }

    cancelEdit(): void {
        this._isInEdit = false;
        this._scepModify = null;
    }

    onScepInfoUpdate(scep: ScepServerInfo): void {
        this._scepModify = scep;
    }

    onDeviceSelect(devList: ScepDeviceInfo[]): void {
        this._selectedDeviceList = devList;
    }

    onDeviceSearchChange(searchTxt: string): void {
        this._searchTxt = searchTxt;
    }

    private onActionComplete(ret: { funcName: string, isFault: boolean, data?: any, errorMessage?: string }): void { }

    renewDevice(): void {
        this.createScepFuncDlg(SCEP_FUNC_RENEW_DEVICE, [this._scep], this._selectedDeviceList);
    }

    addDevice(): void {
        this.createScepFuncDlg(SCEP_FUNC_ADD_DEVICE, [this._scep], { mode: ScepDeviceOperationMode.add });
    }

    export(): void {
        this._exporting = true;

        this.scepSvc.export(this._scep, this._searchTxt, this._selectedDeviceList.map(d => d.dev)).pipe(
            map((c: { fileName: string, header: string, metadata?: string[], dataList: string[][], date: string }) => {
                return HelperLib.downloadCsv(this._exportRef.nativeElement, c.fileName, c.header, c.dataList, c.metadata);
            })
        ).subscribe(() => {
            this._exporting = false;
        });
    }

    updateScep(): void {
        this._page = DialogPage.submit;
        this._errorMessage = null;

        this.scepSvc.updateScep(this._scepModify).subscribe((res: { scep: ScepServerInfo, isFault: boolean, errorMessage?: string }) => {
            this._isInEdit = false;
            if (res.isFault) {
                this._page = DialogPage.result;
                this._errorMessage = res.errorMessage;
            }
            else {
                this._page = DialogPage.action;
                this.createScepFuncDlg(SCEP_FUNC_UPDATE, [this._scep], { mode: ScepDeviceOperationMode.selectAll }); //devList
                setTimeout(() => {
                    this._btnUpdateElementRef.nativeElement.click();
                }, 0);
            }
        });
    }

    private createScepFuncDlg(scepFuncName: string, scepList: ScepServerInfo[] = [], other?: any): void {
        const item: ScepFuncItem = this.scepFuncSvc.getItemByName(scepFuncName);
        if (item) {
            const viewContainerRef = this._scepFuncHost.viewContainerRef;
            viewContainerRef.clear();

            const componentRef = viewContainerRef.createComponent(item.component);

            (<IScepFuncCtrl>componentRef.instance).title = item.title;
            (<IScepFuncCtrl>componentRef.instance).funcName = scepFuncName;
            (<IScepFuncCtrl>componentRef.instance).scepList = scepList;
            (<IScepFuncCtrl>componentRef.instance).other = other;
            (<IScepFuncCtrl>componentRef.instance).onActionCompleted = this.onActionComplete.bind(this);
        }
    }
}