import { ConfigurableItemKey } from "../../../../../app/lib/common/common.data";

export class BasicConfigItem<T> {
    private _isValid: boolean = true;
    get isValid(): boolean {
        return this._isValid;
    }
    set isValid(v: boolean) {
        this._isValid = v;
    }

    errorMessage: string;
    category: ConfigurableItemKey;
    titleLangKey: string;
    settingMap: { [key: string]: { origin: T, langKey?: string, value: T, needOutput?: boolean, needCompare?: boolean, equalFunc?: (a: T, b: T) => boolean } };

    clear(): void {
        Object.keys(this.settingMap).forEach(key => {
            this.settingMap[key].origin = this.settingMap[key].value = this.settingMap[key].langKey = undefined;
        });
    }

    init(category: ConfigurableItemKey, titleLangKey: string, itemList: { key: string, value: T, langKey?: string, needOutput?: boolean, needCompare?: boolean, copyFunc?: (a: T) => T, equalFunc?: (a: T, b: T) => boolean }[]) {
        this.category = category;
        this.titleLangKey = titleLangKey;
        this.settingMap = this.settingMap || {};
        itemList.forEach(item => {
            this.settingMap[item.key] = {
                origin: item.value,
                langKey: item.langKey,
                value: item.copyFunc ? item.copyFunc(item.value) : item.value,
                needOutput: item.needOutput === undefined ? true : item.needOutput,
                needCompare: item.needCompare === undefined ? true : item.needCompare,
                equalFunc: item.equalFunc
            }
        });
    }

    isChanged(): boolean {
        let isChanged: boolean = false;
        for (const key of Object.keys(this.settingMap)) {
            if (this.settingMap[key].needCompare && this.settingMap[key].needOutput) {
                isChanged = this.settingMap[key].equalFunc
                    ? !this.settingMap[key].equalFunc(this.settingMap[key].origin, this.settingMap[key].value)
                    : this.settingMap[key].origin !== this.settingMap[key].value;

                if (isChanged) {
                    break;
                }
            }
        }

        return isChanged;
    }

    transformToSettingData(secure: boolean = false, warning: boolean = false): { name: string, value: T, langKey?: string, secure?: boolean, warning?: boolean, isChanged?: boolean, origin?: T }[] {
        return Object.keys(this.settingMap).filter(key => this.settingMap[key].needOutput).map((key: string) => {
            return {
                name: key,
                value: this.settingMap[key].value,
                langKey: this.settingMap[key].langKey,
                secure: secure,
                warning: warning,
                origin: this.settingMap[key].origin,
                isChange: this.settingMap[key].origin !== this.settingMap[key].value
            }
        });
    }
}

export class DeviceConfigItem<T> {
    origin: T;
    private _name: string;
    private _langKey?: string;
    value: T;
    warning: boolean;

    init(item: { name: string, value: T }, langKey?: string): void {
        this.origin = this.value = item.value;
        this._name = item.name;
        this._langKey = langKey;
        this.warning = false;
    }

    isChanged(): boolean {
        return this.value !== this.origin;
    }

    clear(): void {
        this.value = this.origin = this._langKey = this._name = undefined;
        this.warning = false;
    }

    toObject(secure: boolean = false, warning: boolean = false): { name: string, value: T, langKey?: string, secure?: boolean, warning?: boolean, isChanged?: boolean, origin?: T } {
        return {
            name: this._name,
            value: this.value,
            langKey: this._langKey,
            secure: secure,
            warning: warning,
            origin: this.origin,
            isChanged: this.isChanged()
        };
    }

    constructor() { }
}