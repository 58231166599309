import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../api.base';

export interface ISigninAccessKeyTxData {
    accessKeyID: string;
    accessKeySecret: string;
}

export interface ISigninAccessKeyRxData {
    accountID: string;
    userID: string;
    token: string;
}

export class APISigninAccessKeyManager extends APIBaseManager<void, void, ISigninAccessKeyTxData, ISigninAccessKeyRxData> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_SigninAccessKey';
        this._apiMethod = 'POST';
    }

    protected getRequestURL(pathParameters: void, queryParameters: void): string {
        return super.getRequestURL() + 'tokens/account/accessKey';
    }
}