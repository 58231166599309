import { Directive, forwardRef, Attribute } from '@angular/core';
import { NG_VALIDATORS, AbstractControl, Validator, ValidationErrors } from '@angular/forms';

@Directive({
    selector: '[validateEqual][ngModel]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => EqualValidator), multi: true }
    ]
})
export class EqualValidator implements Validator {
    constructor(
        @Attribute('validateEqual') public validateEqualTag: string, 
        @Attribute('reverse') public reverse: string) {
    }

    private get isReverse() {
        if (!this.reverse) {
            return false;
        }

        return this.reverse === 'true' ? true : false;
    }

    validate(c: AbstractControl): ValidationErrors {
        //self value
        const v = c.value;
        //assume it is empty and should show another error message ?
        if (!v) {
            return null;
        }

        //control to compare
        const e: AbstractControl = c.root.get(this.validateEqualTag);
       
        if (!this.isReverse) {
            if (e && v !== e.value) {
                return {
                    validateEqual: {
                        valid: false
                    }
                };
            }
        }
        else {
            if (e && v === e.value) {
                delete e.errors['validateEqual'];
                if (!Object.keys(e.errors).length) {
                    //need this operation?
                    e.setErrors(null);
                }
            }
            else {
                e.setErrors({
                    validateEqual: {
                        valid: false
                    }
                });
            }
        }

        return null;
    }
}