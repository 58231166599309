import { HttpClient } from '@angular/common/http';

import { APIWrapperBase } from '../../api.wrapper.base';
import { APIImportLicenseManager } from './api.license.import';
import { APIAssignLicenseManager } from './api.license.assign';
import { APIGetLicenseByOwnerManager } from './api.license.get.owner';
import { APIGetLicenseByAssigneeManager } from './api.license.get.assignee';
import { APIGetWarrantyManager } from './api.warranty.get';

export class APILicenseWrapper extends APIWrapperBase {
    ImportLicense: APIImportLicenseManager;
    AssignLicense: APIAssignLicenseManager;
    GetLicenseByOwner: APIGetLicenseByOwnerManager;
    GetLicenseByAssignee: APIGetLicenseByAssigneeManager;
    GetWarranty: APIGetWarrantyManager;

    constructor(private http: HttpClient) {
        super();

        this.ImportLicense = new APIImportLicenseManager(http);
        this.AssignLicense = new APIAssignLicenseManager(http);
        this.GetLicenseByOwner = new APIGetLicenseByOwnerManager(http);
        this.GetLicenseByAssignee = new APIGetLicenseByAssigneeManager(http);
        this.GetWarranty = new APIGetWarrantyManager(http);
    }

    public resetServerConfig(protocol: string, baseUrl: string, port: number): void {
        let targets = [
            this.ImportLicense, 
            this.AssignLicense, 
            this.GetLicenseByOwner,
            this.GetLicenseByAssignee,
            this.GetWarranty
        ];

        targets.forEach(t => t.resetServerConfig(protocol, baseUrl, port));
    }
}