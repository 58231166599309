<div *ngIf="_info">
    <div>
        <span class="text-info">Enabled :</span>
        <span class="ml-1">{{ _info.enabled }}</span>
    </div>
    <ng-template [ngIf]="_info?.enabled">
        <div>
            <span class="text-info">Playback URL :</span>
            <span class="ml-1">{{ _info.url }}</span>
        </div>
        <div>
            <span class="text-info">Schedule :</span>
            <span class="ml-1">{{ getSchedule() }}</span>
        </div>
    </ng-template>
</div>