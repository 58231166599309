
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Md5 } from 'ts-md5';
import * as moment from 'moment-timezone';
import { IAccountToken, ITimezoneInfo } from './common.data';
import { Logger } from './logger';
import { formatDate, WeekDay } from '@angular/common';
import { AndroidGroupType } from '../../../app/content/devfunc/firmware/firmware-data';

export const WEEKDAY_LIST = Object.keys(WeekDay).filter(k => isNaN(Number(k)));
export const WEEKDAY_ABBR_LIST = WEEKDAY_LIST.map(d => d.substring(0, 3));
export const MONTH_LIST = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
export const GMT_OFFSET_LIST = [-11, -10, -9, -8, -7, -6, -5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export const REFRESH_DURATION: number = 30; //s

export enum SharePermissionType {
    BasicConfig = 'BasicConfig',
    NetConfig = 'NetConfig',
    Reboot = 'Reboot',
    FirmwareUpdate = 'FirmwareUpdate',
    Troubleshoot = 'Troubleshoot'
}

export class HelperLib {
    static MOMENT_TIMEZONE_MAP: Map<string, ITimezoneInfo> = new Map();

    static getUsersLocale(defaultValue?: string): string {
        if (typeof window === 'undefined' || typeof window.navigator === 'undefined') {
            return defaultValue || 'en-US';
        }
        const wn = window.navigator as any;
        let lang = wn.languages ? wn.languages[0] : defaultValue;
        lang = lang || wn.language || wn.browserLanguage || wn.userLanguage || 'en-US';
        return lang;
    }

    static splitUpperWord(s: string): string {
        let ret: string = s[0];
        if (s.length > 1) {
            for (let i = 1; i < s.length; i++) {
                if (s[i].toLocaleLowerCase() !== s[i]) {

                }
                ret += s[i].toLocaleLowerCase() !== s[i] ? ' ' + s[i].toLocaleLowerCase() : s[i];
            }
        }

        return ret;
    }

    static expandObject(data: any): any {
        return data instanceof Object ? JSON.stringify(data) : data;
    }

    static getUserLocaleDateFormat(locale?: string): string {
        locale = locale || 'en-US';
        return formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss', locale);
    }

    static isMobileDevice(): boolean {
        return navigator.userAgent.indexOf('Mobile') > 0 ? true : false;
    }

    static isMobileLayout(testWidth?: number, testNumber: number = 992): boolean {
        const w: number = testWidth || window.innerWidth || 0;
        return w < testNumber ? true : false;
    }

    static blobToString(blobData: Blob): Observable<string> {
        const reader = new FileReader();

        let stream = new Observable<string>(observer => {
            reader.onloadend = () => {
                let base64data = (reader.result as string).split(',')[1];
                observer.next(base64data);
                observer.complete();
            };

            reader.onerror = (err) => {
                Logger.logError('HelperLib', 'blobToString', 'Convert error', err);
                observer.error(err);
                observer.complete();
            }

            return function () { }
        });

        reader.readAsDataURL(blobData);

        return stream;
    }

    static blobToMd5(blobData: Blob): Observable<string> {
        return HelperLib.blobToString(blobData).pipe(
            map((result: string) => {
                let ret = Md5.hashStr(result);
                return ret ? ret.toString() : null;
            })
        );
    }

    static parseToken(token: string, tokenName?: string): IAccountToken {
        let result: IAccountToken;

        const token_pieces: string[] = token.split('.');
        if (token_pieces && token_pieces.length === 3) {
            //JWT Token
            const rawToken: string = atob(token_pieces[1])
            if (rawToken) {
                try {
                    result = JSON.parse(rawToken);
                    Logger.logInfo('Helper', 'parseToken', (tokenName ? tokenName : '') + ' Token detail = ', result);

                    if (result.refreshToken) {
                        HelperLib.parseToken(result.refreshToken, 'refresh');
                    }
                }
                catch (ex) {
                    Logger.logError('Helper', 'parseToken', 'Parse ' + (tokenName ? tokenName : '') + ' token failed. Error = ', ex);
                }
            }
        }

        return result;
    }

    static setLocalStorageRecord(key: string, value: string): void {
        localStorage.setItem(key, value);
    }

    static removeLocalStorageRecord(key: string): void {
        localStorage.removeItem(key);
    }

    static getLocalStorageRecord(key: string): string {
        return localStorage.getItem(key);
    }

    static getErrorMessage(result: {
        error: number | string;
        errorMessage: string;
    }): string {
        return result.error !== 0 ? '[' + result.error + '] ' + result.errorMessage : '';
    }

    static AdjustableSharePermissionList = [
        {
            permission: SharePermissionType.BasicConfig,
            displayName: 'Basic config'
        },
        {
            permission: SharePermissionType.NetConfig,
            displayName: 'Network config'
        },
        {
            permission: SharePermissionType.FirmwareUpdate,
            displayName: 'Firmware update'
        },
        {
            permission: SharePermissionType.Reboot,
            displayName: 'Reboot'
        },
        {
            permission: SharePermissionType.Troubleshoot,
            displayName: 'Troubleshoot'
        }
    ];

    static isAdjustableSharePermission(permissionName: string): boolean {
        if (permissionName === 'All' || permissionName === 'Share') {
            return false;
        }

        return true;
    }

    static mapPermissionToUserfriendlyName(permissionName: string): string {
        switch (permissionName) {
            case SharePermissionType.BasicConfig:
                {
                    return 'Basic config';
                }
            case SharePermissionType.NetConfig:
                {
                    return 'Network config';
                }
            case SharePermissionType.FirmwareUpdate:
                {
                    return 'Firmware update'
                }
            default:
                {
                    return permissionName;
                }
        }
    }

    static downloadCsv(triggerItem: HTMLLinkElement, csvName: string, header: string, dataList: string[][], metadata?: string[]): boolean {
        try {
            const csvHeader: string = 'data:application/csv;charset=utf-8,%EF%BB%BF';
            const content = (header ? header + '\n' : '') + (metadata ? metadata.map(m => m + '\n').join('') + '\n' : '') + dataList.map(r => r.join(',')).join('\n');
            const encodeUri = encodeURIComponent(content); //encodeURIComponent can encode following characters: , / ? : @ & = + $ #

            triggerItem.setAttribute('href', csvHeader + encodeUri);
            triggerItem.setAttribute('download', csvName + '.csv');
            triggerItem.click();

            return true;
        }
        catch {
            return false;
        }
    }

    static getDefaultTimezoneList(): ITimezoneInfo[] {
        const timezoneRawDataList: string[] = [
            "GMT+12:00,Marshall Islands Time,Pacific/Majuro"
            , "GMT-11:00,Samoa Standard Time,Pacific/Midway"
            , "HST,Hawaii-Aleutian Standard Time,Pacific/Honolulu"
            , "AKST,Alaska Standard Time,America/Anchorage"
            , "PST,Pacific Standard Time,America/Los_Angeles"
            , "PST,Pacific Standard Time,America/Tijuana"
            , "MST,Mountain Standard Time,America/Phoenix"
            , "GMT-07:00,Mexican Pacific Standard Time,America/Chihuahua"
            , "MST,Mountain Standard Time,America/Denver"
            , "CST,Central Standard Time,America/Costa_Rica"
            , "CST,Central Standard Time,America/Chicago"
            , "CST,Central Standard Time,America/Mexico_City"
            , "CST,Central Standard Time,America/Regina"
            , "GMT-05:00,Colombia Standard Time,America/Bogota"
            , "EST,Eastern Standard Time,America/New_York"
            , "GMT-04:00,Venezuela Time,America/Caracas"
            , "AST,Atlantic Standard Time,America/Barbados"
            , "AST,Atlantic Standard Time,America/Halifax"
            , "GMT-04:00,Amazon Standard Time,America/Manaus"
            , "GMT-04:00,Chile Standard Time,America/Santiago"
            , "GMT-03:30,Newfoundland Standard Time,America/St_Johns"
            , "GMT-03:00,Brasilia Standard Time,America/Sao_Paulo"
            , "GMT-03:00,Argentina Standard Time,America/Argentina/Buenos_Aires"
            , "GMT-03:00,West Greenland Standard Time,America/Godthab"
            , "GMT-03:00,Uruguay Standard Time,America/Montevideo"
            , "GMT-02:00,South Georgia Time,Atlantic/South_Georgia"
            , "GMT-01:00,Azores Standard Time,Atlantic/Azores"
            , "GMT-01:00,Cape Verde Standard Time,Atlantic/Cape_Verde"
            , "GMT+00:00,Western European Standard Time,Africa/Casablanca"
            , "GMT+00:00,Greenwich Mean Time,Europe/London"
            , "GMT+01:00,Central European Standard Time,Europe/Amsterdam"
            , "GMT+01:00,Central European Standard Time,Europe/Belgrade"
            , "GMT+01:00,Central European Standard Time,Europe/Brussels"
            , "GMT+01:00,Central European Standard Time,Europe/Sarajevo"
            , "GMT+01:00,West Africa Standard Time,Africa/Windhoek"
            , "GMT+01:00,West Africa Standard Time,Africa/Brazzaville"
            , "GMT+02:00,Eastern European Standard Time,Asia/Amman"
            , "GMT+02:00,Eastern European Standard Time,Europe/Athens"
            , "GMT+02:00,Eastern European Standard Time,Asia/Beirut"
            , "GMT+02:00,Eastern European Standard Time,Africa/Cairo"
            , "GMT+02:00,Eastern European Standard Time,Europe/Helsinki"
            , "GMT+02:00,Israel Standard Time,Asia/Jerusalem"
            , "GMT+03:00,Moscow Standard Time,Europe/Minsk"
            , "GMT+02:00,Central Africa Time,Africa/Harare"
            , "GMT+03:00,Arabian Standard Time,Asia/Baghdad"
            , "GMT+03:00,Moscow Standard Time,Europe/Moscow"
            , "GMT+03:00,Arabian Standard Time,Asia/Kuwait"
            , "GMT+03:00,East Africa Time,Africa/Nairobi"
            , "GMT+03:30,Iran Standard Time,Asia/Tehran"
            , "GMT+04:00,Azerbaijan Standard Time,Asia/Baku"
            , "GMT+04:00,Georgia Standard Time,Asia/Tbilisi"
            , "GMT+04:00,Armenia Standard Time,Asia/Yerevan"
            , "GMT+04:00,Gulf Standard Time,Asia/Dubai"
            , "GMT+04:30,Afghanistan Time,Asia/Kabul"
            , "GMT+05:00,Pakistan Standard Time,Asia/Karachi"
            , "GMT+05:00,West Kazakhstan Time,Asia/Oral"
            , "GMT+05:00,Yekaterinburg Standard Time,Asia/Yekaterinburg"
            , "GMT+05:30,India Standard Time,Asia/Calcutta"
            , "GMT+05:30,India Standard Time,Asia/Colombo"
            , "GMT+05:45,Nepal Time,Asia/Katmandu"
            , "GMT+06:00,East Kazakhstan Time,Asia/Almaty"
            , "GMT+06:30,Myanmar Time,Asia/Rangoon"
            , "GMT+07:00,Krasnoyarsk Standard Time,Asia/Krasnoyarsk"
            , "GMT+07:00,Indochina Time,Asia/Bangkok"
            , "GMT+07:00,Western Indonesia Time,Asia/Jakarta"
            , "GMT+08:00,China Standard Time,Asia/Shanghai"
            , "GMT+08:00,Hong Kong Standard Time,Asia/Hong_Kong"
            , "GMT+08:00,Irkutsk Standard Time,Asia/Irkutsk"
            , "GMT+08:00,Malaysia Time,Asia/Kuala_Lumpur"
            , "GMT+08:00,Australian Western Standard Time,Australia/Perth"
            , "GMT+08:00,Taipei Standard Time,Asia/Taipei"
            , "GMT+09:00,Korean Standard Time,Asia/Seoul"
            , "GMT+09:00,Japan Standard Time,Asia/Tokyo"
            , "GMT+09:00,Yakutsk Standard Time,Asia/Yakutsk"
            , "GMT+09:30,Australian Central Standard Time,Australia/Adelaide"
            , "GMT+09:30,Australian Central Standard Time,Australia/Darwin"
            , "GMT+10:00,Australian Eastern Standard Time,Australia/Brisbane"
            , "GMT+10:00,Australian Eastern Standard Time,Australia/Hobart"
            , "GMT+10:00,Australian Eastern Standard Time,Australia/Sydney"
            , "GMT+10:00,Vladivostok Standard Time,Asia/Vladivostok"
            , "GMT+10:00,Chamorro Standard Time,Pacific/Guam"
            , "GMT+11:00,Magadan Standard Time,Asia/Magadan"
            , "GMT+12:00,New Zealand Standard Time,Pacific/Auckland"
            , "GMT+12:00,Fiji Standard Time,Pacific/Fiji"
            , "GMT+13:00,Tonga Standard Time,Pacific/Tongatapu"
        ];

        return HelperLib.getTimezoneList(timezoneRawDataList);
    }

    static getFirmwareFirstDigitByAndroidGroup(andGroupType: AndroidGroupType): string {
        switch (andGroupType) {
            case AndroidGroupType.And_44:
            default:
                {
                    return '1';
                }
            case AndroidGroupType.And_71:
                {
                    return '2';
                }
            case AndroidGroupType.And_9:
                {
                    return '3';
                }
            case AndroidGroupType.And_12:
                {
                    return '4';
                }
        }
    }

    static mapToList<T>(map: { [id: string]: T }): T[] {
        return Object.keys(map).map(id => map[id]);
    }

    static getAndroidGroup(firmwareVersion: string): AndroidGroupType {
        if (!firmwareVersion) {
            return AndroidGroupType.And_44;
        }

        //First digit of firmware version represents firmware for different android version
        //1.x.x.x => android 4.4
        //2.x.x.x => android 7
        //3.x.x.x => android 9
        let andType: AndroidGroupType = AndroidGroupType.And_44;
        const firmware_pieces: string[] = firmwareVersion.split('.');
        if (firmware_pieces.length > 1) {
            switch (firmware_pieces[0]) {
                case '1':
                    {
                        andType = AndroidGroupType.And_44;
                    }
                    break;
                case '2':
                    {
                        andType = AndroidGroupType.And_71;
                    }
                    break;
                case '3':
                    {
                        andType = AndroidGroupType.And_9;
                    }
                    break;
                case '4':
                    {
                        andType = AndroidGroupType.And_12;
                    }
            }
        }

        return andType;
    }

    static getTimezoneList(timezoneInputList: string[]): ITimezoneInfo[] {
        if (HelperLib.MOMENT_TIMEZONE_MAP.size === 0) {
            const timezoneIDs: string[] = (moment.tz.names() as string[]);
            timezoneIDs.forEach(timezoneID => {
                HelperLib.MOMENT_TIMEZONE_MAP.set(timezoneID, { id: timezoneID, offset: moment.tz(timezoneID).format('Z') });
            });
        }

        let minusOffsets: ITimezoneInfo[] = [];
        let plusOffsets: ITimezoneInfo[] = [];

        timezoneInputList.forEach(timezone => {
            const timezone_splits = timezone.split(',');
            if (HelperLib.MOMENT_TIMEZONE_MAP.has(timezone_splits[2])) {
                const transformed_timezone: ITimezoneInfo = {
                    id: timezone_splits[2],
                    displayName: timezone_splits[1].replace(/Standard Time/, '') + ', ' + timezone_splits[2],
                    offset: HelperLib.MOMENT_TIMEZONE_MAP.get(timezone_splits[2]).offset
                }

                transformed_timezone.offset.startsWith('-') ? minusOffsets.push(transformed_timezone) : plusOffsets.push(transformed_timezone);
            }
        });

        //timezones.sort((a, b) => a.displayName.localeCompare(b.displayName));
        minusOffsets = minusOffsets.sort((a: ITimezoneInfo, b: ITimezoneInfo) => {
            if (a.offset < b.offset) {
                return 1;
            }
            else if (a.offset === b.offset) {
                return a.id > b.id ? 1 : a.id === b.id ? 0 : -1;
            }
            else {
                return -1;
            }
        });

        plusOffsets = plusOffsets.sort((a: ITimezoneInfo, b: ITimezoneInfo) => {
            if (a.offset > b.offset) {
                return 1;
            }
            else if (a.offset === b.offset) {
                return a.id > b.id ? 1 : a.id === b.id ? 0 : -1;
            }
            else {
                return -1;
            }
        });

        return minusOffsets.concat(plusOffsets);
    }

    static countdown(counter: number, targetValue: number, cb: (counter: number) => void): void {
        if (counter-- === targetValue) {
            return cb(targetValue);
        }

        setTimeout(() => {
            cb(counter);
            HelperLib.countdown(counter, targetValue, cb);
        }, 1000);
    }

    static checkState(interval: number, checkFunc: () => boolean, cb: () => void): void {
        if (checkFunc()) {
            return cb();
        }

        const duration = (interval || 1) * 1000;

        setTimeout(() => {
            HelperLib.checkState(interval, checkFunc, cb);
        }, duration);
    }
}
