import { EventEmitter, Injectable, Output } from "@angular/core";
import { Logger } from "app/lib/common/logger";
import { Observable, of } from "rxjs";
import { delay, map } from "rxjs/operators";
import { CertInfo } from "./cert.data";

@Injectable()
export class CertService {
    private readonly TAG: string = 'Cert';
    _certList: CertInfo[] = [new CertInfo({ name: 'cert1'}), new CertInfo({ name: 'cert2'}), new CertInfo({ name: 'cert3'})];

    @Output() onCertRemoved = new EventEmitter<{ removedCerts: CertInfo[], currentCerts: CertInfo[] }>();
    @Output() onCertAdded = new EventEmitter<{ addedCerts: CertInfo[], currentCerts: CertInfo[] }>();

    getCerts(refresh: boolean = false): Observable<{ certs: CertInfo[], isFault: boolean, errorMessage?: string }> {
        return of({ certs: this._certList, isFault: false }).pipe(delay(3000));
    }

    addCert(cert: CertInfo): Observable<{ isFault: boolean, errorMessage?: string }> {
        Logger.logInfo(this.TAG, 'addCert', 'Add cert: ', cert);
        return of(true).pipe(
            delay(3000),
            map(() => {
                this._certList.push(cert);

                this.onCertAdded.emit({ addedCerts: [cert], currentCerts: this._certList });

                return { isFault: false };
            })
        );
    }

    removeCert(cert: CertInfo): Observable<{ isFault: boolean, errorMessage?: string }> {
        Logger.logInfo(this.TAG, 'removeCert', 'Remove cert: ', cert);
        return of(true).pipe(
            delay(3000),
            map(() => {
                const found: CertInfo = this._certList.find(c => c.id === cert.id);
                if (found) {
                    this._certList.splice(this._certList.indexOf(found), 1);
                }

                this.onCertRemoved.emit({ removedCerts: [found], currentCerts: this._certList });

                return { isFault: !found, errorMessage: !found ? `Cert ${cert.name} is not exist` : '' };
            })
        );
    }
}