export enum LicenseLevel {
    Free = 1,
    Essential,
    Warranty,
    VIP
}

export enum LicenseScopeType {
    all = 1,
    pairing,
    heartbeatAutoRefresh,
    screenshotAutoRefresh,
    firmwareUpdate,
    taskConfig,
    takeScreenshot,
    alertEmail,
    activityHistory,
    sharePlayer,
    devicePolicy,
    remoteAssistance, //troubleshoot
    reportDiagnostic, //a more-detail report regarding to device's health and other information
    reportHealth, //device's simple health report
    playProof,
    clearCache
}

export class ScopeFunctionInfo {
    displayName: string;
    type: LicenseScopeType;
    refreshDuration?: number; //second
    amount?: number;
}

export enum LicenseCategory {
    All = 'all',
    Warranty = 'warranty',
    ICare = 'com.iadea.IAdeaCare',
    RemoteControl = 'com.iadea.IAdeaCare.remote',
    Cloud = 'com.iadea.cloudUpdate',
}

export interface ILicenseBaseFilterData<T, G> {
    filterMessage: string;
    startFilter(): void;
    applyFilter(): G;
    getFilterData(): T;
    clear(): void;
}

export class LicenseExpiryTypeFilterData implements ILicenseBaseFilterData<string[], { [typeKey: string]: boolean }> {
    static TYPE_LIST: { key: LicenseCategory, name: string }[] = [
        { key: LicenseCategory.Warranty, name: 'Warranty' },
        { key: LicenseCategory.ICare, name: 'IAdeaCare License' },
        { key: LicenseCategory.Cloud, name: 'Miscellaneous' }
    ];

    private _applyList: { key: LicenseCategory, name: string, checked: boolean }[];
    private _tmpList: { key: LicenseCategory, name: string, checked: boolean }[];
    get tmpList(): { key: LicenseCategory, name: string, checked: boolean }[] {
        return this._tmpList;
    }
    filterMessage: string;

    constructor(o?: { [typeKey: string]: boolean }) {
        this._applyList = LicenseExpiryTypeFilterData.TYPE_LIST.map(s => {
            return { key: s.key, name: s.name, checked: o && o[s.key] !== undefined ? o[s.key] : true }
        });

        this._tmpList = this._applyList.map(c => { return { key: c.key, name: c.name, checked: c.checked } });
        this.updateFilterMessage();
    }

    clear(): void {
        this._tmpList = LicenseExpiryTypeFilterData.TYPE_LIST.map(s => {
            return { key: s.key, name: s.name, checked: false }
        });
    }

    startFilter(): void {
        this._tmpList = this._applyList.map(c => { return { key: c.key, name: c.name, checked: c.checked } });
    }

    changeType(type: { name: string, checked: boolean }, checked: boolean): void {
        type.checked = checked;
    }

    applyFilter(): { [typeKey: string]: boolean } {
        this._applyList = this.tmpList;
        this.updateFilterMessage();

        return this._applyList.reduce((acc, curr) => {
            acc[curr.key] = curr.checked;
            return acc;
        }, {});
    }

    private updateFilterMessage(): void {
        const selectedList: { name: string, checked: boolean }[] = this._applyList.filter(s => s.checked);
        this.filterMessage = selectedList.length === this._applyList.length ? 'All' : selectedList.map(s => s.name).join(', ');
    }

    getFilterData(): string[] {
        return this._applyList.filter(s => s.checked).map(s => s.key);
    }
}

export class LicenseExpiryTimeFilterData implements ILicenseBaseFilterData<{ beginDate: string, endDate: string }, { beginDate: string, endDate: string }> {
    private _applyData: { beginDate: string, endDate: string };
    private _tmpData: { beginDate: string, endDate: string };
    get tmpData(): { beginDate: string, endDate: string } {
        return this._tmpData;
    }

    filterMessage: string;
    errorMessage: string;

    constructor(o?: { beginDate?: string, endDate?: string }) {
        this._applyData = { beginDate: o && o.beginDate ? o.beginDate : null, endDate: o && o.endDate ? o.endDate : null };
        this._tmpData = { beginDate: this._applyData.beginDate, endDate: this._applyData.endDate, };
        this.updateFilterMessage();
    }

    clear(): void {
        this._tmpData = { beginDate: null, endDate: null };
    }

    startFilter(): void {
        this.errorMessage = null;
        this._tmpData = {
            beginDate: this._applyData.beginDate,
            endDate: this._applyData.endDate
        };
    }

    applyFilter(): { beginDate: string, endDate: string } {
        this._applyData = this._tmpData;
        this.updateFilterMessage();

        return this._applyData;
    }

    getFilterData(): { beginDate: string; endDate: string; } {
        return { beginDate: this._applyData.beginDate, endDate: this._applyData.endDate };
    }

    changeBeginDate(date: string): void {
        this._tmpData.beginDate = date;
        this.checkTimeRangeLegality();
    }

    changeEndDate(date: string): void {
        this._tmpData.endDate = date;
        this.checkTimeRangeLegality();
    }

    private checkTimeRangeLegality(): void {
        this.errorMessage = null;
        if (this._tmpData.beginDate > this._tmpData.endDate) {
            this.errorMessage = 'Time range is invalid';
        }
    }

    private updateFilterMessage(): void {
        this.filterMessage = this._applyData.beginDate + ' ~ ' + this._applyData.endDate;
    }
}