import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../api.base';

export interface IDeleteAccessKeyPathParameter {
    accessKeyID: string;
}

export class APIDeleteAccessKeyManager extends APIBaseManager<IDeleteAccessKeyPathParameter, void, void, any> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_DeleteAccessKey';
        this._apiMethod = 'DELETE';
    }

    protected getRequestURL(pathParameters: IDeleteAccessKeyPathParameter, queryParameters: void): string {
        return super.getRequestURL() + 'accounts/accessKeys/' + pathParameters.accessKeyID;
    }
}