import { NgModule } from '@angular/core';

import { CapitalAllWordsPipe } from './capitalAllWords.pipe';
import { CapitalFirstWordPipe } from './capitalFirstWord.pipe';
import { ExtractFirstWordPipe } from './extractFirstWord.pipe';
import { MergeDeviceNamesPipe } from './mergeDeviceNames.pipe';
import { MergeContentsPipe } from './mergeContents.pipe';
import { SafePipe } from './safePipe';
import { TimeAliasTransformPipe } from './timeAliasTransform.pipe';
import { SecondTransformPipe } from './secondTransform.pipe';

@NgModule({
    declarations: [
        CapitalAllWordsPipe,
        CapitalFirstWordPipe,
        ExtractFirstWordPipe,
        MergeDeviceNamesPipe,
        MergeContentsPipe,
        SafePipe,
        TimeAliasTransformPipe,
        SecondTransformPipe
    ],
    providers: [],
    exports: [
        CapitalAllWordsPipe, 
        CapitalFirstWordPipe, 
        ExtractFirstWordPipe, 
        MergeDeviceNamesPipe, 
        MergeContentsPipe, 
        SafePipe, 
        TimeAliasTransformPipe, 
        SecondTransformPipe
    ]
})
export class CustomPipeModule {}


