import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { fromEvent as observableFromEvent, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { DialogPage } from '../../../../lib/common/common.data';
import { AccessKeyInfo } from '../../../../API/v1/Account/api.accessKey.common';
import { IAccessKeyFunc } from '../accessKey-func.service';
import { AccessKeyService } from '../accessKey.service';

@Component({
    templateUrl: './accessKey-remove.component.html',
    styleUrls: ['../accessKey.style.css', './accessKey-remove.component.css']
})
export class AccessKeyRemoveComponent implements OnInit, OnDestroy, IAccessKeyFunc {
    readonly DELETE_TEXT: string = 'DELETE';
    title: string;
    key: AccessKeyInfo;
    dialogCompleteHandler?: (result: any) => void;

    _page: DialogPage = DialogPage.action;
    _enumDialogPage: typeof DialogPage = DialogPage;
    _loading: boolean;

    _showInactive: boolean = false; //if to show inactive ui (include inactive result)
    _inactiveRequired: boolean = false; //need to inactive the key first
    _inactiveResult: { isFault: boolean, errorMessage?: string };

    _deleteResult: { isFault: boolean, errorMessage?: string };
    _deleteText: string = '';

    private _allUnsubscribe: Subject<void> = new Subject();

    private _deleteInputRef: ElementRef;
    @ViewChild('deleteInput', { static: true })
    set deleteInput(v: ElementRef) {
        if (v) {
            this._deleteInputRef = v;
            const searchElement = this._deleteInputRef.nativeElement;
            const searchInputOb = observableFromEvent(searchElement, 'input');

            searchInputOb.pipe(
                debounceTime(200),
                takeUntil(this._allUnsubscribe)
            ).subscribe((e: any) => {
                this._deleteText = e.target.value;
            });
        }
    }

    constructor(private accKeySvc: AccessKeyService) {

    }

    ngOnInit(): void {
        if (this.key) {
            this._inactiveRequired = this._showInactive = this.key.isAccessKeyEnabled;
        }
    }

    ngOnDestroy(): void {
        this._allUnsubscribe.next();
        this._allUnsubscribe.complete();
    }

    inactiveAcc(): void {
        this._loading = true;
        this.accKeySvc.updateAccessKey(this.key, false).subscribe((res: { isFault: boolean, key: AccessKeyInfo, errorMessage?: string }) => {
            this._inactiveResult = {
                isFault: res.isFault,
                errorMessage: res.errorMessage
            };
            this._loading = false;
            if (!res.errorMessage) {
                this._inactiveRequired = false;
            }
        });
    }

    deleteAcc(): void {
        this._loading = true;
        this.accKeySvc.deleteAccessKey(this.key.accessKeyID).subscribe((res: { isFault: boolean, keyList: AccessKeyInfo[], errorMessage?: string }) => {
            this._deleteResult = {
                isFault: res.isFault,
                errorMessage: res.errorMessage
            };
            this._loading = false;
            this._page = DialogPage.result;
            this.dialogCompleteHandler(this._deleteResult);
        });
    }
}