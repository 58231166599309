import { TaskStatus } from "../device/data/device-info";

export class TicketDeviceInfo {
    virtualDeviceID: string;
    virtualDevicePairedID: string;
    name: string;
    status: TaskStatus;
    taskId: string;
    detailURL?: string;
    errorMessage?: string;
    startTimestamp: number;
    finishTimestamp: number;
    show: boolean;

    isFinished(): boolean {
        return this.status === TaskStatus.success || this.status === TaskStatus.fail;
    }
}

export class TicketInfo {
    id: string;
    status: string;
    issueDateTime: string;
    startedDate: string;
    finishedDate: string;
    subject: string;
    content: string;
    symptom?: string;
    frequency?: string;
    targets: TicketDeviceInfo[];

    isFinished(): boolean {
        return this.status === "reported" || this.status === 'fail';
    }

    constructor() {
        this.targets = [];
    }
}
