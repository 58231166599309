import { Injectable, Type } from '@angular/core';
import { AlertOfflineDataComponent } from './comp/alert-data-offline.component';
import { AlertType } from '../alert.data';
import { AlertAccountAccessDataComponent } from './comp/alert-data-account-access.component';
import { AlertDeviceCommandDataComponent } from './comp/alert-data-device-cmd.component';
import { AlertDeviceStatusDataComponent } from './comp/alert-data-device-status.component';

export class AlertDataCtrlItem {
  constructor(public component: Type<any>, public type: string) {}
}

@Injectable()
export class AlertDataCtrlService {
  private _items: AlertDataCtrlItem[] = [];

  constructor() {
    this._items = [
      new AlertDataCtrlItem(AlertOfflineDataComponent, AlertType.Offline),
      new AlertDataCtrlItem(AlertAccountAccessDataComponent, AlertType.AccountAccess),
      new AlertDataCtrlItem(AlertDeviceCommandDataComponent, AlertType.DeviceCmd),
      new AlertDataCtrlItem(AlertDeviceStatusDataComponent, AlertType.DeviceStatus)
    ];
  }

  getAll(): AlertDataCtrlItem[] {
    return this._items;
  }

  getItem(type: string): AlertDataCtrlItem {
    return this._items.find(i => i.type === type);
  }
}
