import { HttpClient } from '@angular/common/http';

import { APIWrapperBase } from '../../api.wrapper.base';
import { APIUpdateReportConfigManager } from './api.report.config.update';
import { APIGetReportConfigManager } from './api.report.config.get';
import { APIListReportManager } from './api.report.list';
import { APIGetReportManager } from './api.report.get';

export class APIReportWrapper extends APIWrapperBase {
    GetReportConfig: APIGetReportConfigManager;
    UpdateReportConfig: APIUpdateReportConfigManager;
    ListReport: APIListReportManager;
    GetReport: APIGetReportManager;

    constructor(private http: HttpClient) {
        super();

        this.GetReportConfig = new APIGetReportConfigManager(http);
        this.UpdateReportConfig = new APIUpdateReportConfigManager(http);
        this.ListReport = new APIListReportManager(http);
        this.GetReport = new APIGetReportManager(http);
    }

    public resetServerConfig(protocol: string, baseUrl: string, port: number): void {
        let targets = [
            this.GetReportConfig, 
            this.UpdateReportConfig,
            this.ListReport,
            this.GetReport
        ];

        targets.forEach(t => t.resetServerConfig(protocol, baseUrl, port));
    }
}