import { Component, ElementRef, ViewChild } from "@angular/core";
import { DialogPage } from "app/lib/common/common.data";
import { CertInfo } from "../cert.data";
import { CertService } from "../cert.service";
import { ICertFuncCtrl } from "./cert-func.def";

@Component({
    templateUrl: './cert-dlg-add.component.html',
    styleUrls: ['../cert.style.css']
})
export class CertAddDlgComponent implements ICertFuncCtrl {
    title: string;
    funcName: string;
    certList: CertInfo[];
    other?: any;
    onActionCompleted: (ret: { funcName: string; isFault: boolean; data?: any; errorMessage?: string; }) => void;

    _enumPage: typeof DialogPage = DialogPage;
    _page: DialogPage = DialogPage.action;
    _fileInfo: File;
    _errorMessage: string;

    _cert: CertInfo;

    private _btnCloseRef: ElementRef;
    @ViewChild('btnClose', { static: true })
    set btnClose(v: any) {
        this._btnCloseRef = v;
    }

    private _certFilePickerRef: ElementRef;
    @ViewChild('certFilePicker')
    set certFilePicker(v: any) {
        this._certFilePickerRef = v;
    }

    constructor(private certSvc: CertService) { }

    triggerCertFilePicker(): void {
        this._certFilePickerRef?.nativeElement.click();
    }

    onFileChange(e: any): void {
        this._errorMessage = null;
        this._fileInfo = e.target.files[0];
    }

    addCert(): void {
        this._page = DialogPage.submit;
        this._errorMessage = null;

        this._cert = new CertInfo({ name: this._fileInfo.name });
        this.certSvc.addCert(this._cert).subscribe((res: { isFault: boolean, errorMessage?: string }) => {
            this._page = DialogPage.result;
            this._errorMessage = res.errorMessage;

            if (!res.isFault) {
                setTimeout(() => {
                    this._btnCloseRef.nativeElement.click();
                }, 0);
            }
        });
    }
}