import { Injectable, Output, EventEmitter, Directive } from '@angular/core';
import { DeviceService } from '../device/device.service';
import { AccountService } from '../../entry/account.service';

import { Observable } from 'rxjs/Rx';
import { of, iif as observableIf, Subject, zip } from 'rxjs';
import { map, concatAll, concatMap, catchError, buffer } from 'rxjs/operators';
import { LicenseCategory } from './license.data';
import { DeviceInfo, IDeviceUnpairStatusChangeEventArgs } from '../device/data/device-info';
import { LicenseScopeType } from './license.data';
import { LICENSE_SCOPE_FUNCTION_MAP } from './license-scope-map';
import { IAssignLicenseTxData } from '../../API/v1/License/api.license.assign';
import { IImportLicenseTxData, IImportLicenseRxData } from '../../API/v1/License/api.license.import';
import { NAService } from '../../API/na.service';
import { IAPIRx } from '../../API/api.base';
import { IGetLicenseByAssigneeRxData } from '../../API/v1/License/api.license.get.assignee';
import { IGetLicenseByOwnerRxData } from '../../API/v1/License/api.license.get.owner';
import { ILicenseCategoryInfo, ILicenseInfo } from '../../API/v1/License/api.license.common';
import { IClass } from '../../lib/common/common.data';
import { Logger } from '../../lib/common/logger';
import { HelperLib } from 'app/lib/common/helper.lib';

@Directive()
@Injectable()
export class LicenseService implements IClass {
    className: string;
    private readonly BATCH_COUNT: number = 50;

    private _devRefreshTime: { [virtualDeviceID: string]: number } = {};
    private _ownerRefreshTime: number = 0;
    private _devLicenseInfo: {
        [virtualDeviceID: string]: {
            [licenseCategory: string]: ILicenseCategoryInfo
        }
    } = {};
    private _ownerLicenseMap: {
        [licenseCategory: string]: {
            detail: {
                [licenseType: string]: {
                    licenseKeyList: ILicenseInfo[];
                }
            }
        }
    };

    private UPDATE_DURATION: number = 1800000; //30min

    @Output() assigneeLicenseChanged = new EventEmitter<{
        virtualDeviceID: string,
        updateLicenseInfo: {
            [licenseCategory: string]: ILicenseCategoryInfo
        }
    }[]>();

    constructor(private naSvc: NAService, private devSvc: DeviceService, private accountSvc: AccountService) {
        this.className = 'LicenseSvc';
        this.accountSvc.loginChanged.subscribe((isLogin: boolean) => {
            Logger.logInfo(this.className, '', 'Login status change. IsLogin?=' + isLogin);
            if (!isLogin) {
                this._devRefreshTime = {};
                this._devLicenseInfo = {};
                this._ownerRefreshTime = 0;
            }
            else {

            }
        });

        this.devSvc.deviceUnpairStatusChanged.subscribe((deviceUnpairStatus: IDeviceUnpairStatusChangeEventArgs) => {
            if (!deviceUnpairStatus.isFault && !deviceUnpairStatus.isPaired) {
                //update owner licenses
                Logger.logInfo(this.className, '', 'Device unpair event.', deviceUnpairStatus);
                this.getLicenseByAccount(true).subscribe();
            }
        });
    }

    getLicenseByAccount(force: boolean = false): Observable<{
        [licenseCategory: string]: {
            detail: {
                [licenseType: string]: {
                    licenseKeyList: ILicenseInfo[];
                }
            }
        }
    }> {
        return of(true).pipe(
            concatMap(() => {
                if (!this.needUpdate(null, force)) {
                    return of(this._ownerLicenseMap);
                }

                return this.naSvc.getLicenseByOwner({ ownerList: [{ accountID: this.accountSvc.accountID }] }, this.accountSvc.token).pipe(
                    map((res: IAPIRx<IGetLicenseByOwnerRxData[]>) => {
                        if (res && res.error === 0 && res.data) {
                            this._ownerLicenseMap = this._ownerLicenseMap || {};
                            this._ownerLicenseMap = res.data[0].licenses;
                            this._ownerRefreshTime = new Date().getTime();

                            return this._ownerLicenseMap;
                        }

                        return null;
                    })
                );
            }),
            catchError((err) => {
                return of(null);
            })
        );
    }

    hasLicenseScope(virtualDeviceID: string, scope: LicenseScopeType): Observable<boolean> {
        return this.getLicenseByDevice(virtualDeviceID).pipe(
            map(res => {
                if (res && res[LicenseCategory.ICare] && res[LicenseCategory.ICare].scope) {
                    for (const s of res[LicenseCategory.ICare].scope) {
                        if (LICENSE_SCOPE_FUNCTION_MAP[s] && LICENSE_SCOPE_FUNCTION_MAP[s].type === scope) {
                            return true;
                        }
                    }
                }

                return false;
            })
        );
    }

    getLicenseByDevice(virtualDeviceID: string, force: boolean = false): Observable<{ [category: string]: ILicenseCategoryInfo }> {
        return observableIf(
            () => { return !this.needUpdate(virtualDeviceID, force) },
            of(this._devLicenseInfo[virtualDeviceID]),
            of(true).pipe(concatMap(() => {
                return this.naSvc.getLicenseByAssignee({ assigneeList: [{ virtualDeviceID: virtualDeviceID }] }, this.accountSvc.token).pipe(
                    map((res: IAPIRx<IGetLicenseByAssigneeRxData[]>) => {
                        const now: number = Date.now();
                        if (res && res.error === 0 && res.data && res.data.length > 0) {
                            this._devLicenseInfo[virtualDeviceID] = res.data[0].licenses;
                            this._devRefreshTime[virtualDeviceID] = now;
                        }

                        return this._devLicenseInfo[virtualDeviceID];
                    }));
            })));
    }

    getLicenseByDeviceList(virtualDeviceIDList: string[], force: boolean = false): Observable<{
        isFault: boolean;
        hasNext: boolean;
        licenseData?: {
            [virtualDeviceID: string]: {
                [licenseCategory: string]: ILicenseCategoryInfo
            }
        }
    }> {
        const ret: {
            [virtualDeviceID: string]: {
                [licenseCategory: string]: ILicenseCategoryInfo
            }
        } = {};

        const toUpdateDeviceIDList: string[] = [];
        const noUpdateDeviceIDList: string[] = [];

        virtualDeviceIDList.forEach(vID => {
            if (this.needUpdate(vID, force)) {
                toUpdateDeviceIDList.push(vID);
            }
            else {
                noUpdateDeviceIDList.push(vID);
            }
        });

        if (!force && toUpdateDeviceIDList.length === 0) {
            virtualDeviceIDList.forEach((vID: string) => ret[vID] = this._devLicenseInfo[vID]);

            return of({
                isFault: false,
                hasNext: false,
                licenseData: ret
            });
        }

        return of(true).pipe(
            concatMap(() => {
                const obs: Observable<{
                    isFault: boolean;
                    hasNext: boolean;
                    licenseData?: {
                        [virtualDeviceID: string]: {
                            [licenseCategory: string]: ILicenseCategoryInfo
                        }
                    }
                }>[] = [];

                for (let i = 0; i < toUpdateDeviceIDList.length; i += this.BATCH_COUNT) {
                    let target: number = i + this.BATCH_COUNT;
                    target = target > toUpdateDeviceIDList.length ? toUpdateDeviceIDList.length : target;

                    const vIDList: { virtualDeviceID: string }[] = [];
                    for (let j = i; j < target; ++j) {
                        vIDList.push({ virtualDeviceID: toUpdateDeviceIDList[j] });
                    }

                    obs.push(
                        this.naSvc.getLicenseByAssignee({ assigneeList: vIDList }, this.accountSvc.token).pipe(
                            map((res: IAPIRx<IGetLicenseByAssigneeRxData[]>) => {
                                if (res && res.error === 0 && res.data) {
                                    const now: number = Date.now();
                                    res.data.forEach(l => {
                                        if (l.assignee && l.assignee.virtualDeviceID) {
                                            this._devLicenseInfo[l.assignee.virtualDeviceID] = l.licenses;
                                            this._devRefreshTime[l.assignee.virtualDeviceID] = now;
                                        }
                                    });

                                    const result = {};
                                    vIDList.forEach(v => {
                                        result[v.virtualDeviceID] = this._devLicenseInfo[v.virtualDeviceID]
                                    });

                                    return {
                                        isFault: false,
                                        hasNext: true,
                                        licenseData: result
                                    }
                                }

                                return {
                                    isFault: true,
                                    hasNext: true
                                };
                            })
                        )
                    )
                }

                noUpdateDeviceIDList.forEach(vID => {
                    ret[vID] = this._devLicenseInfo[vID];
                });

                obs.push(of({ isFault: false, hasNext: false, licenseData: ret }));

                return obs;
            }),
            concatAll()
        );
    }

    checkLicenseLegality(deviceList: DeviceInfo[], licenseScopeType: LicenseScopeType): Observable<{ dev: DeviceInfo, isLegal: boolean }[]> {
        const emitOb: Subject<void> = new Subject();
        const devMap: { [virtualDeviceID: string]: DeviceInfo } = deviceList.reduce((prev, curr) => {
            prev[curr.virtualId] = curr;
            return prev
        }, {});

        const needCheckDeviceList: DeviceInfo[] = [];
        let resultList: { dev: DeviceInfo, isLegal: boolean }[] = [];

        for (const d of deviceList) {
            if (!d.virtualId) {
                resultList.push({ dev: d, isLegal: false });
            }
            else if (licenseScopeType === LicenseScopeType.all) {
                resultList.push({ dev: d, isLegal: true });
            }
            else {
                needCheckDeviceList.push(d);
            }
        }

        return this.getLicenseByDeviceList(needCheckDeviceList.map(d => d.virtualId), false).pipe(
            map((res: {
                isFault: boolean;
                hasNext: boolean;
                licenseData?: {
                    [virtualDeviceID: string]: {
                        [licenseCategory: string]: ILicenseCategoryInfo
                    }
                }
            }) => {
                if (!res.isFault && res.licenseData) {
                    Object.keys(res.licenseData).forEach((vID: string) => {
                        let isLegal: boolean = true;
                        if (!res.licenseData[vID][LicenseCategory.ICare] || !res.licenseData[vID][LicenseCategory.ICare].scope) {
                            isLegal = false;
                        }
                        else {
                            isLegal = res.licenseData[vID][LicenseCategory.ICare].scope.find((scope: string) => LICENSE_SCOPE_FUNCTION_MAP[scope] && LICENSE_SCOPE_FUNCTION_MAP[scope].type === licenseScopeType) ? true : false;
                        }

                        resultList.push({ dev: devMap[vID], isLegal: isLegal });
                    });
                }

                if (!res.hasNext) {
                    emitOb.next();
                    emitOb.unsubscribe();
                }

                return res;
            }),
            buffer(emitOb),
            map((res: {
                isFault: boolean;
                hasNext: boolean;
                licenseData?: {
                    [virtualDeviceID: string]: {
                        [licenseCategory: string]: ILicenseCategoryInfo
                    }
                }
            }[]) => {
                return resultList;
            })
        );
    }

    importAndAssignLicense(requests: { licenseKeys: string[], targetDevice: DeviceInfo }[]): Observable<{ hasNext: boolean, result?: { isFault: boolean, errorMessage: string, targetDevice: DeviceInfo }[] }> {
        const result: { isFault: boolean, errorMessage: string, targetDevice: DeviceInfo }[] = [];
        const needUpdateDeviceList: DeviceInfo[] = [];

        return of(true).pipe(
            concatMap(() => {
                const obs: Observable<any>[] = [];
                requests.forEach(req => {
                    obs.push(this.importLicense(req.licenseKeys).pipe(
                        concatMap(((res: IAPIRx<IImportLicenseRxData[]>) => {
                            if (res.error !== 0) {
                                if (res.data) {
                                    const errors: IImportLicenseRxData[] = res.data.filter(l => l.error !== 0);
                                    if (errors.length > 0) {
                                        throw errors.map(error => ({
                                            licenseKey: error.licenseKey,
                                            errorMessage: HelperLib.getErrorMessage(error)
                                        }));
                                    }
                                }
                                throw [{ errorMessage: 'Unknown error (data is NULL)' }];
                            }

                            return this.assignLicenseToSingleDevice(res.data.filter(r => r.error === 0).map((r => r.licenseKeyToken)), req.targetDevice);
                        })),
                        map((res: IAPIRx<void>) => ({
                            hasNext: true,
                            isFault: false,
                            targetDevice: req.targetDevice
                        })),
                        catchError((err: any) => of({
                            isFault: true,
                            hasNext: true,
                            targetDevice: req.targetDevice,
                            errorMessage: err
                        }))
                    ));
                });

                obs.push(of({
                    hasNext: false,
                    isFault: false
                }));

                return obs;
            }),
            concatAll(),
            concatMap((res: { hasNext: boolean, isFault: boolean, targetDevice?: DeviceInfo, errorMessage?: any }) => {
                if (!res.hasNext) {
                    if (needUpdateDeviceList.length > 0) {
                        return this.devSvc.batchReloadLicense(needUpdateDeviceList).pipe(
                            concatMap(() => this.naSvc.getLicenseByAssignee({
                                assigneeList: needUpdateDeviceList.map(d => {
                                    return {
                                        virtualDeviceID: d.virtualId
                                    };
                                })
                            }, this.accountSvc.token)),
                            map((res: IAPIRx<IGetLicenseByAssigneeRxData[]>) => {
                                if (res && res.error === 0 && res.data) {
                                    res.data.forEach(r => {
                                        this._devLicenseInfo[r.assignee.virtualDeviceID] = r.licenses;
                                    });

                                    this.assigneeLicenseChanged.emit(res.data.map(r => {
                                        return {
                                            virtualDeviceID: r.assignee.virtualDeviceID,
                                            updateLicenseInfo: r.licenses
                                        }
                                    }));
                                }

                                return {
                                    hasNext: false,
                                    result: result
                                };
                            })
                        );
                    }
                    else {
                        return of({
                            hasNext: false,
                            result: result
                        })
                    }
                }
                else {
                    result.push({ targetDevice: res.targetDevice, isFault: res.isFault, errorMessage: res.errorMessage });

                    if (!res.isFault && res.targetDevice) {
                        needUpdateDeviceList.push(res.targetDevice);
                    }

                    return of({
                        hasNext: true
                    });
                }
            })
        );
    }

    importLicense(licenseKeys: string[]): Observable<IAPIRx<IImportLicenseRxData[]>> {
        const importTxData: IImportLicenseTxData = {
            importList: licenseKeys.map((key: string) => {
                return {
                    owner: {
                        accountID: this.accountSvc.accountID
                    },
                    licenseKeyCode: key
                }
            })
        };

        return this.naSvc.importLicense(importTxData, this.accountSvc.token);
    }

    assignLicenseToSingleDevice(licenseTokenList: string[], targetDevice: DeviceInfo): Observable<IAPIRx<void>> {
        const assignTxData: IAssignLicenseTxData = {
            assignList: licenseTokenList.map((licenseKeyToken: string) => {
                return {
                    licenseKeyToken: licenseKeyToken,
                    assignee: targetDevice ? {
                        virtualDeviceID: targetDevice.virtualId,
                        accountID: this.accountSvc.accountID
                    } : null
                }
            })
        };

        //also notify device to reload license by task if assign is success (if targetDevice isn't NULL)
        return this.naSvc.assignLicense(assignTxData, this.accountSvc.token);
    }

    assignLicenseToMultipleDevice(requests: {
        licenseDataList: { licenseToken: string, licenseCode: string }[],
        targetDevice: DeviceInfo,
    }[], needUpdateLicenseDeviceMap?: { [virtualDeviceID: string]: boolean }): Observable<{ hasNext: boolean, result?: { targetDevice: DeviceInfo, isFault: boolean, licenseCodeList: string[], errorMessage?: string }[] }> {

        const result: { targetDevice: DeviceInfo, licenseCodeList: string[], isFault: boolean, errorMessage?: string }[] = [];

        Logger.logInfo(this.className, 'assignLicenseToMultipleDevice', 'Requests = ', requests);
        Logger.logInfo(this.className, 'assignLicenseToMultipleDevice', 'Affected devices = ', needUpdateLicenseDeviceMap);

        return of(true).pipe(
            concatMap(() => {
                const obs: Observable<any>[] = [];

                requests.forEach((request: {
                    licenseDataList: { licenseToken: string, licenseCode: string }[],
                    targetDevice: DeviceInfo
                }) => {
                    obs.push(
                        this.assignLicenseToSingleDevice(request.licenseDataList.map(l => l.licenseToken), request.targetDevice).pipe(
                            map((res: IAPIRx<void>) => {
                                if (res.error !== 0) {
                                    throw res.errorMessage || res.error;
                                }

                                return {
                                    isFault: false,
                                    hasNext: true,
                                    targetDevice: request.targetDevice,
                                    licenseCodeList: request.licenseDataList.map(l => l.licenseCode)
                                }
                            }),
                            catchError((err: any) => {
                                return of({
                                    isFault: true,
                                    hasNext: true,
                                    targetDevice: request.targetDevice,
                                    errorMessage: err.toString(),
                                    licenseCodeList: request.licenseDataList.map(l => l.licenseCode)
                                });
                            })
                        )
                    );
                });

                obs.push(of({
                    hasNext: false
                }));

                return obs;
            }),
            concatAll(),
            concatMap((res: { hasNext: boolean, isFault: boolean, licenseCodeList: string[], targetDevice?: DeviceInfo, errorMessage?: string }) => {
                if (!res.hasNext) {
                    //try to update license list for each affected devices
                    Logger.logInfo(this.className, 'assignLicenseToMultipleDevice', 'Affected devices = ', needUpdateLicenseDeviceMap);

                    return this.getLicenseByAccount(true).pipe(
                        concatMap(() => {
                            const updateObs: Observable<any>[] = [];
                            Object.keys(needUpdateLicenseDeviceMap).forEach((virtualDeviceID: string) => {
                                updateObs.push(
                                    zip(this.getLicenseByDevice(virtualDeviceID, true), of(virtualDeviceID), this.devSvc.batchReloadLicenseByID([virtualDeviceID])).pipe(
                                        map(([x, y, z]) => {
                                            return {
                                                assignee: { virtualDeviceID: y },
                                                licenseData: x,
                                                hasNext: true
                                            }
                                        })
                                    ));
                            });

                            updateObs.push(of({
                                hasNext: false
                            }));

                            return updateObs;
                        }),
                        concatAll(),
                        map((res: {
                            hasNext: boolean,
                            assignee: { virtualDeviceID: string },
                            licenseData: {
                                [category: string]: ILicenseCategoryInfo
                            }
                        }) => {
                            if (res.hasNext) {
                                //this._devLicenseInfo[res.assignee.virtualDeviceID] = res.licenseData;
                                this.assigneeLicenseChanged.emit([{
                                    virtualDeviceID: res.assignee.virtualDeviceID,
                                    updateLicenseInfo: res.licenseData
                                }]);

                                return {
                                    hasNext: true
                                };
                            }
                            else {
                                return {
                                    hasNext: false,
                                    result: result
                                }
                            }
                        })
                    );
                }
                else {
                    result.push({ targetDevice: res.targetDevice, licenseCodeList: res.licenseCodeList, isFault: res.isFault, errorMessage: res.errorMessage });

                    return of({
                        hasNext: true
                    });
                }
            })
        )
    }

    private needUpdate(virtualDeviceID?: string, force: boolean = false): boolean {
        if (force) {
            return true;
        }

        if (virtualDeviceID) {
            return !this._devRefreshTime[virtualDeviceID] || (Date.now() - this._devRefreshTime[virtualDeviceID]) > this.UPDATE_DURATION ? true : false;
        }

        // if owner license needs update
        return (Date.now() - this._ownerRefreshTime) > this.UPDATE_DURATION ? true : false;
    }

    isLicenseFormatValid(licenseKey: string): boolean {
        const license_pieces: string[] = licenseKey.split(/[-]+/);
        if (license_pieces.length === 5) {
            for (const piece of license_pieces) {
                if (piece.length !== 5) {
                    return false;
                }
            }

            return true;
        }
        else {
            return false;
        }
    }
}