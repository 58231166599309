import { Component, OnInit } from '@angular/core';

import { AlertService } from '../../../setting/alert/alert.service';
import { AlertInfo, AlertType } from '../../../setting/alert/alertConfig/alert.data';
import { DeviceInfo } from '../../../device/data/device-info';
import { DevFuncActionInterface } from '../../base/action/dev-func-action.interface';

@Component({
    templateUrl: './alert-action-subform.component.html',
    styleUrls: ['../../base/base-config-form.component.css', './alert-action-subform.component.css']
})
export class AlertActionSubFormComponent implements OnInit, DevFuncActionInterface {
    _alertList: AlertInfo[] = [];
    _alert: AlertInfo;
    private _device: DeviceInfo;
    _isLoading: boolean;

    private _actionData: any;
    set actionData(data: any) {
        this._actionData = data;
        if (this._actionData) {
            this._device = this._actionData.deviceList[0];
        }
    }

    actionCompleteHandler: (result: any) => void;
    actionStatusUpdateHandler: (isValid: boolean) => void;
    actionCommunicateHandler?: (data: any) => void;

    constructor(private alertSvc: AlertService) { }

    ngOnInit(): void {
        this._isLoading = true;

        if (this.actionStatusUpdateHandler) {
            this.actionStatusUpdateHandler(false);
        }

        this.alertSvc.getAlertSettingList().subscribe((alerts: AlertInfo[]) => {
            this._alertList = alerts.filter(a => a.eventType.type !== AlertType.AccountAccess);
            this._isLoading = false;

            if (this.actionStatusUpdateHandler) {
                this.actionStatusUpdateHandler(true);
            }

            if (this._actionData.needReset) {
                this._actionData.ruleAssignMap = {};
                this._actionData.needReset = false;
                if (this._alertList.length > 0) {
                    this._alertList.forEach((r: AlertInfo) => {
                        //if current device is applied to this rule
                        const bApply: boolean = this.isApplyToAlert(r);
                        this._actionData.ruleAssignMap[r.id] = { current: bApply, after: bApply };
                    });
                }
            }
            else {
                if (this._actionData.lastSelectRuleId) {
                    const found: AlertInfo = this._alertList.find(r => r.id === this._actionData.lastSelectRuleId);
                    if (found) {
                        this._alert = found;
                    }
                }
            }
        });
    }

    private isApplyToAlert(alert: AlertInfo): boolean {
        if (!this._device) {
            return false;
        }

        const alertApplyDeviceList: { virtualDeviceID: string }[] = (alert.alertData as any).alertApplyDeviceList;
        if (!alertApplyDeviceList || alertApplyDeviceList.length === 0) {
            return false;
        }

        return alertApplyDeviceList.find(p => p.virtualDeviceID === this._device.virtualId) ? true : false;
    }

    reset(): void {
        Object.keys(this._actionData.ruleAssignMap).forEach((ruleID: string) => {
            this._actionData.ruleAssignMap[ruleID].after = this._actionData.ruleAssignMap[ruleID].current;
        });
    }

    viewAlert(alert: AlertInfo): void {
        this._alert = alert;
        this._actionData.lastSelectRuleId = this._alert.id;
    }

    removeAlert(alert: AlertInfo): void {
        this._actionData.ruleAssignMap[alert.id].after = false;
    }

    assignAlert(alert: AlertInfo): void {
        this._actionData.ruleAssignMap[alert.id].after = true;
    }

    hasAlertAssigned(): boolean {
        if (this._actionData && this._actionData.ruleAssignMap) {
            for (let id of Object.keys(this._actionData.ruleAssignMap)) {
                if (this._actionData.ruleAssignMap[id].after) {
                    return true;
                }
            }
        }

        return false;
    }
}