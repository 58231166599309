import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CustomPipeModule } from '../../../lib/pipes/custom-pipe.module';
import { TranslateModule } from '../../../translate/translate.module';
import { UIElementModule } from '../../../uiElement/uiElement.module';
import { PolicyOverviewComponent } from './policy-overview.component';
import { PolicyService } from './policy.service';
import { PolicyEditorComponent } from './form/policy-edit.component';
import { PolicyFuncDirective } from './policy-func.directive';
import { PolicyFuncService } from './policy-func.service';
import { PolicyRemoveComponent } from './form/policy-remove.component';
import { DeviceGroupModule } from '../../device/group/group.module';
import { PolicyTypeSecurityComponent } from './comp/policy-security.component';
import { PolicyTypeGroupConfigComponent } from './comp/policy-group-config.component';
import { PolicyTypeService } from './comp/policy-type.service';
import { PolicyTypeDirective } from './comp/policy-type.directive';
import { PolicyReportComponent } from './comp/policy-report.component';
import { PolicyTypeAppManagementComponent } from './comp/policy-appmgr.component';
import { PolicyTypeFirmwareUpdateComponent } from './comp/policy-firmwareUpdate.component';
import { PolicyTypeCertificateComponent } from './comp/policy-cert.component';
import { CertModule } from 'app/content/admin/cert/cert.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        CustomPipeModule,
        TranslateModule,
        UIElementModule,
        DeviceGroupModule,
        CertModule
    ],
    providers: [
        PolicyService,
        PolicyFuncService,
        PolicyTypeService
    ],
    declarations: [
        PolicyFuncDirective,
        PolicyTypeDirective,
        PolicyOverviewComponent,
        PolicyEditorComponent,
        PolicyRemoveComponent,
        PolicyTypeSecurityComponent,
        PolicyTypeGroupConfigComponent,
        PolicyTypeAppManagementComponent,
        PolicyTypeFirmwareUpdateComponent,
        PolicyTypeCertificateComponent,
        PolicyReportComponent
    ],
    exports: [
        PolicyOverviewComponent,
        PolicyReportComponent
    ],
    entryComponents: [
        PolicyEditorComponent,
        PolicyRemoveComponent,
        PolicyTypeSecurityComponent,
        PolicyTypeGroupConfigComponent,
        PolicyTypeAppManagementComponent,
        PolicyTypeFirmwareUpdateComponent,
        PolicyTypeCertificateComponent
    ]
})
export class PolicyModule { }