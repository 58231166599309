import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import * as _semver from 'semver';
import { Observable, of as observableOf, Subject } from 'rxjs';
import { switchMap, map, takeUntil, catchError } from 'rxjs/operators';

import { DeviceService } from '../device.service';
import { NetworkType } from '../data/device-net-info';
import { DeviceInfo, IPolicyLockMap, OnlineStatus, TaskStatus } from '../data/device-info';
import { AccountService } from '../../../entry/account.service';
import { LicenseScopeType, ScopeFunctionInfo } from '../../license/license.data';
import { HelperLib, REFRESH_DURATION, SharePermissionType } from '../../../lib/common/helper.lib';
import { DevFuncDirective } from '../../devfunc/dev-func.directive';
import { DevFuncService } from '../../devfunc/dev-func.service';
import { DevFuncItem } from '../../devfunc/dev-func-item';
import { DevFuncInterface } from '../../devfunc/dev-func.interface';
import { LicenseService } from '../../license/license.service';
import { LicenseCategory } from '../../license/license.data';
import { LICENSE_SCOPE_FUNCTION_MAP } from '../../license/license-scope-map';
import { ILicenseCategoryInfo } from '../../../API/v1/License/api.license.common';
import { CustomResponse, IClass } from '../../../lib/common/common.data';
import { Logger } from '../../../lib/common/logger';
import { APKService, APKUpdateInfo } from '../../devfunc/apk/apk.service';
import { AppConfigService } from '../../../app.config';
import { ConstantService } from '../../../lib/common/constant.service';
import { IVirtualDeviceRxDataSecurity } from '../../../API/v1/VirtualDevice/virtualDevice.common';

@Component({
    selector: 'nt-device-detail',
    templateUrl: './device-detail.component.html',
    styleUrls: ['./device-detail.component.css']
})
export class DeviceDetailComponent implements OnInit, OnDestroy, IClass {
    className: string;

    _dev: DeviceInfo;
    _lockMap: IPolicyLockMap = {};
    _isSharedDevice: boolean = false;
    _errorMessage: string = '';
    _showActivityDlg: boolean = false;

    //if user supports the function; if not, hide the function
    //if device supports the function; if not, disable the function
    _funcSupportMap: { [funcName: string]: { userSupport: boolean, licenseSupport: boolean, countdown: number } } = {};

    private _licenseInfo: {
        iCareLicense: ILicenseCategoryInfo,
        cloudLicense: ILicenseCategoryInfo
    };
    _licenseScopeMap: { [scopeType: string]: ScopeFunctionInfo } = {};

    _loading: boolean = false;
    _loadingLicenses: boolean = false;

    private _sharePermissionStatus: { [sharePermission: string]: boolean } = {};
    _enumOnlineStatus: typeof OnlineStatus = OnlineStatus;
    _currentNetworkType: NetworkType = NetworkType.None;
    _enumLicenseScopeType: typeof LicenseScopeType = LicenseScopeType;

    _hasPendingTask: boolean = false;

    //Action control
    _rebootCounter: number = 0;
    _firmwareCounter: number = 0;
    _basicConfigCounter: number = 0;
    _netConfigCounter: number = 0;
    _securityCounter: number = 0;

    //configure modal id
    basicConfigModalId: string = 'deviceBasicConfigModal';
    netConfigModalId: string = 'deviceNetConfigModal';

    private _apkInfo: { version?: string, link?: string, md5?: string, category?: string, needUpdate: boolean } = { needUpdate: false };
    private _bAPKUpdating: boolean = false;
    private _allUnsubscribe: Subject<void> = new Subject();

    @ViewChild(DevFuncDirective, { static: true }) devFuncHost: DevFuncDirective;

    private _btnCloseActivityRef: ElementRef;
    @ViewChild('activityCloseBtn')
    set activityCloseBtn(holder: ElementRef) {
        if (holder) {
            this._btnCloseActivityRef = holder;
        }
    }

    private dlgCloseElementRef: ElementRef;
    @ViewChild('dlgClose')
    set dlgClose(holder: ElementRef) {
        if (holder) {
            this.dlgCloseElementRef = holder;
        }
    }

    constructor(
        private constantSvc: ConstantService,
        private devSvc: DeviceService,
        private accountSvc: AccountService,
        private licenseSvc: LicenseService,
        private route: ActivatedRoute,
        private devFuncSvc: DevFuncService,
        private apkSvc: APKService
    ) {
        this.className = 'Device-detail-page';
    }

    ngOnInit(): void {
        this.accountSvc.loginChanged.pipe(
            takeUntil(this._allUnsubscribe)
        ).subscribe((isLogin: boolean) => {
            if (!isLogin) {
                if (this.dlgCloseElementRef && this.dlgCloseElementRef.nativeElement) {
                    this.dlgCloseElementRef.nativeElement.click();
                }
            }
        });

        this.devSvc.deviceTaskUpdated.pipe(
            takeUntil(this._allUnsubscribe)
        ).subscribe((dev: DeviceInfo) => {
            if (this._dev && dev.virtualId === this._dev.virtualId) {
                this.update_pending_status();
            }
        });

        this._loadingLicenses = true;
        this._loading = true;

        this.route.paramMap.pipe(
            switchMap((params: ParamMap) => this.update(params.get('id')))
        ).subscribe((err: any) => {
            this._errorMessage = err;
            this._loadingLicenses = false;
            this._loading = false;
        });

        this.devSvc.devicesChanged.pipe(
            takeUntil(this._allUnsubscribe)
        ).subscribe((devices: DeviceInfo[]) => {
            if (this._dev && devices.length > 0) {
                const found: DeviceInfo = devices.find(d => d.virtualId === this._dev.virtualId);
                if (found) {
                    this._dev = found;
                }
            }
        });
    }

    ngOnDestroy(): void {
        Logger.logInfo(this.className, 'OnDestroy', '');
        this._allUnsubscribe.next();
        this._allUnsubscribe.complete();
    }

    private isFunctionAvailable(checkOnlineStatus: boolean = false): boolean {
        if (!this._dev) {
            return false;
        }

        if (!this._licenseScopeMap) {
            return false;
        }

        if (checkOnlineStatus) {
            if (this._dev.currentSettings[this.constantSvc.DEVKEY_FAKE_ONLINESTATUS] !== OnlineStatus.Online) {
                return false;
            }
        }

        return true;
    }

    get supportReboot(): boolean {
        return AppConfigService.configs.devPage.func.rebootEnabled && this.accountSvc.hasScope_task_reboot();
    }

    get isRebootValid(): boolean {
        if (!this.isFunctionAvailable(true)) {
            return false;
        }

        if (this._rebootCounter > 0) {
            return false;
        }
        if (!this._licenseScopeMap[LicenseScopeType.taskConfig]) {
            return false;
        }
        if (!this._sharePermissionStatus[SharePermissionType.Reboot]) {
            return false;
        }
        return true;
    }

    get supportBasicConfig(): boolean {
        return AppConfigService.configs.devPage.func.basicConfig.enabled && this.accountSvc.hasScope_task_basicSetting();
    }

    get isBasicConfigValid(): boolean {
        if (!this.isFunctionAvailable(true)) {
            return false;
        }

        if (this._basicConfigCounter > 0) {
            return false;
        }
        if (!this._licenseScopeMap[LicenseScopeType.taskConfig]) {
            return false;
        }
        if (!this._sharePermissionStatus[SharePermissionType.BasicConfig]) {
            return false;
        }

        return true;
    }

    get supportTroubleshoot(): boolean {
        return AppConfigService.configs.devPage.func.troubleshootEnabled && this.accountSvc.hasScope_task_troubleshoot();
    }

    get isTroubleshootValid(): boolean {
        if (!this.isFunctionAvailable()) {
            return false;
        }

        if (!this._licenseScopeMap[LicenseScopeType.remoteAssistance]) {
            return false;
        }
        if (!this._sharePermissionStatus[SharePermissionType.Troubleshoot]) {
            return false;
        }

        return true;
    }

    get supportFirmwareUpdate(): boolean {
        return AppConfigService.configs.devPage.func.firmwareUpdateEnabled && this.accountSvc.hasScope_task_firmwareUpdate();
    }

    get isFirmwareUpdateValid(): boolean {
        if (!this.isFunctionAvailable(true)) {
            return false;
        }

        if (this._firmwareCounter > 0) {
            return false;
        }

        if (!this._licenseScopeMap[LicenseScopeType.firmwareUpdate]) {
            return false;
        }
        if (!this._sharePermissionStatus[SharePermissionType.FirmwareUpdate]) {
            return false;
        }

        return true;
    }

    get supportNetConfig(): boolean {
        return AppConfigService.configs.devPage.func.netConfig.enabled && this.accountSvc.hasScope_task_netSetting();
    }

    get isNetConfigValid(): boolean {
        if (!this.isFunctionAvailable(true)) {
            return false;
        }

        if (this._netConfigCounter > 0) {
            return false;
        }

        if (!this._licenseScopeMap[LicenseScopeType.taskConfig]) {
            return false;
        }
        if (!this._sharePermissionStatus[SharePermissionType.NetConfig]) {
            return false;
        }

        return true;
    }

    get supportAPKUpdate(): boolean {
        return AppConfigService.configs.devPage.func.apkUpdateEnabled && this.accountSvc.hasScope_task_iadeaCareApkUpdate() && this._apkInfo?.needUpdate;
    }

    get isAPKUpdateValid(): boolean {
        if (!this.isFunctionAvailable(true)) {
            return false;
        }

        if (this._bAPKUpdating) {
            return false;
        }

        if (!this._licenseScopeMap[LicenseScopeType.firmwareUpdate]) {
            return false;
        }
        if (!this._sharePermissionStatus[SharePermissionType.FirmwareUpdate]) {
            return false;
        }

        return true;
    }

    get supportDeviceSecurity(): boolean {
        return this.accountSvc.hasScope_task_securitySetting();
    }

    get isDeviceSecurityValid(): boolean {
        if (!this.isFunctionAvailable(true)) {
            return false;
        }

        if (this._securityCounter > 0) {
            return false;
        }

        if (!this._licenseScopeMap[LicenseScopeType.taskConfig]) {
            return false;
        }
        if (!this._sharePermissionStatus[SharePermissionType.BasicConfig]) {
            return false;
        }

        return true;
    }

    get supportDeviceAlertUpdate(): boolean {
        return AppConfigService.configs.devPage.func.alertEnabled && this.accountSvc.hasScope_alertSetting_update();
    }

    get isDeviceAlertUpdateValid(): boolean {
        if (!this.isFunctionAvailable()) {
            return false;
        }

        if (!this._licenseScopeMap[LicenseScopeType.alertEmail]) {
            return false;
        }

        return true;
    }

    get supportDeviceShare(): boolean {
        return AppConfigService.configs.devPage.func.shareEnabled && !this.accountSvc.isEnterprise() && this.accountSvc.hasScope_device_share();
    }

    get isDeviceShareValid(): boolean {
        if (!this.isFunctionAvailable()) {
            return false;
        }

        if (this._isSharedDevice) {
            return false;
        }
        //policy lock?
        if (this._lockMap[this.constantSvc.DEVKEY_FAKE_SHARE]) {
            return false;
        }

        return true;
    }

    get supportOTP(): boolean {
        if (this._lockMap[this.constantSvc.DEVKEY_SECURITY_USER_ADMIN]) {
            return true;
        }

        if (this._dev.currentSettings[this.constantSvc.DEVKEY_SECURITY_USER_ADMIN]) {
            const security: IVirtualDeviceRxDataSecurity = this._dev.currentSettings[this.constantSvc.DEVKEY_SECURITY_USER_ADMIN] as IVirtualDeviceRxDataSecurity;
            if (security.users && security.users.admin && security.users.admin.auth && security.users.admin.auth.type === 'totp') {
                return true;
            }
        }

        return false;
    }

    get isOTPValid(): boolean {
        if (!this.isFunctionAvailable()) {
            return false;
        }

        if (!this._licenseScopeMap[LicenseScopeType.devicePolicy]) {
            return false;
        }

        return true;
    }

    get supportViewDeviceActivity(): boolean {
        return AppConfigService.configs.devPage.func.userActivity.enabled && this.accountSvc.hasScope_deviceActivity_view();
    }

    get isViewDeviceActivityValid(): boolean {
        if (!this.isFunctionAvailable()) {
            return false;
        }

        if (!this._licenseScopeMap[LicenseScopeType.activityHistory]) {
            return false;
        }

        return true;
    }

    get supportViewLicense(): boolean {
        return AppConfigService.configs.licensePage.enabled && this.accountSvc.hasScope_license_view();
    }

    get supportAddLicense(): boolean {
        if (!AppConfigService.configs.licensePage.element.enabledAddLicense || !AppConfigService.configs.licensePage.element.enabledReallocateLicense || !this.accountSvc.hasScope_license_assign()) {
            return false;
        }

        if (!this.accountSvc.isEnterprise() && this._dev.virtualDeviceOwnerID !== this.accountSvc.accountID) {
            //shared device on normal account
            return false;
        }

        return true;
    }

    private onActivityDlgClosed(): void {
        this._showActivityDlg = false;
        if (this._btnCloseActivityRef) {
            this._btnCloseActivityRef.nativeElement.click();
        }
    }

    showActivityDlg(): void {
        this._showActivityDlg = true;
    }

    private update_pending_status(): void {
        this._hasPendingTask = this._dev.taskInfos && this._dev.taskInfos.filter(a => a.status === TaskStatus.pending || a.status === TaskStatus.progress).length > 0 ? true : false;
    }

    private submitFirmwareUpdateComplete(result: CustomResponse<any>): void {
        Logger.logInfo(this.className, 'submitFrimwareUpdateComplete', 'Data = ', result);

        if (result && !result.isFault()) {
            this._hasPendingTask = true;
        }

        this._firmwareCounter = REFRESH_DURATION;
        HelperLib.countdown(this._firmwareCounter, 0, (counter: number) => {
            this._firmwareCounter = counter;
        });
    }

    private submitRebootComplete(result: CustomResponse<any>): void {
        Logger.logInfo(this.className, 'submitRebootComplete', 'Data = ', result);

        if (result && !result.isFault()) {
            this._hasPendingTask = true;
        }

        this._rebootCounter = REFRESH_DURATION;
        HelperLib.countdown(this._rebootCounter, 0, (counter: number) => {
            this._rebootCounter = counter;
        });
    }

    private submitDeviceConfigComplete(result: CustomResponse<any>): void {
        Logger.logInfo(this.className, 'submitDeviceConfigComplete', 'Data = ', result);

        if (result && !result.isFault()) {
            this._hasPendingTask = true;
        }

        this._basicConfigCounter = REFRESH_DURATION;
        HelperLib.countdown(this._basicConfigCounter, 0, (counter: number) => {
            this._basicConfigCounter = counter;
        });
    }

    private submitNetConfigComplete(result: CustomResponse<any>): void {
        Logger.logInfo(this.className, 'submitNetConfigComplete', 'Data = ', result);

        if (result && !result.isFault()) {
            this._hasPendingTask = true;
        }

        this._netConfigCounter = REFRESH_DURATION;
        HelperLib.countdown(this._netConfigCounter, 0, (counter: number) => {
            this._netConfigCounter = counter;
        });
    }

    private submitSecurityComplete(result: CustomResponse<any>): void {
        Logger.logInfo(this.className, 'submitSecurityComplete', 'Data = ', result);

        if (result && !result.isFault()) {
            this._hasPendingTask = true;
        }

        this._securityCounter = REFRESH_DURATION;
        HelperLib.countdown(this._securityCounter, 0, (counter: number) => {
            this._securityCounter = counter;
        });
    }

    private netConfigInteract(result: CustomResponse<any>): void {
        Logger.logInfo(this.className, 'netConfigInteract', 'Data = ', result);

        if (result && !result.isFault()) {
            this._hasPendingTask = true;
        }
    }

    private onScreenshotTaken(result: CustomResponse<any>): void {
        if (result && !result.isFault()) {
            this._hasPendingTask = true;
        }
    }

    refreshPlayer(): void {
        this._loadingLicenses = true;
        this._loading = true;
        this._errorMessage = null;

        this.update(this._dev.virtualId, false, true).subscribe(err => {
            this._errorMessage = err;
            this._loadingLicenses = false;
            this._loading = false;
        });
    }

    private update(virtualDeviceID: string, autoCheck: boolean = true, force: boolean = false): Observable<any> {
        return this.devSvc.getDeviceByID(virtualDeviceID, autoCheck, autoCheck, force, force).pipe(
            switchMap((res: CustomResponse<DeviceInfo>) => {
                if (res.isFault()) {
                    throw res.errorMessage;
                }

                this._dev = res.data;
                this._isSharedDevice = this.accountSvc.isEnterprise() ? false : (this._dev.virtualDeviceOwnerID !== this.accountSvc.accountID ? true : false);
                this._currentNetworkType = this._dev.currentSettings[this.constantSvc.DEVKEY_NET_LAN_CONNECTED] ?
                    NetworkType.Ethernet :
                    (this._dev.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_CONNECTED] ? NetworkType.Wifi : NetworkType.None);

                if (this._dev && this._dev.currentSettings) {
                    // do apk update
                    this.apkSvc.getLatestAPKUpdateInfo(this._dev.currentSettings[this.constantSvc.DEVKEY_INFO_APP] ? this._dev.currentSettings[this.constantSvc.DEVKEY_INFO_APP].category : null, force).subscribe((res: { isFault: boolean, info?: APKUpdateInfo, errorMessage?: string }) => {
                        if (res && !res.isFault && res.info) {
                            this._apkInfo.version = res.info.version;
                            this._apkInfo.link = res.info.link;
                            this._apkInfo.md5 = res.info.md5;
                            this._apkInfo.category = res.info.category;
        
                            if (res.info.version && this._dev.currentSettings[this.constantSvc.DEVKEY_INFO_APKVERSION] && _semver.lt(this._dev.currentSettings[this.constantSvc.DEVKEY_INFO_APKVERSION], res.info.version, true)) {
                                this._apkInfo.needUpdate = true;
                            }
                        }
                    });
                }

                return this.devSvc.updateShadowDevice(this._dev, force);
            }),
            switchMap((res: { isFault: boolean, errorMessage?: string, data: IPolicyLockMap }) => {
                if (!res.isFault) {
                    this._lockMap = res.data;
                    Logger.logInfo(this.className, 'update', 'Policy lock map = ', this._lockMap);
                }

                return this.devSvc.updateCalendar(this._dev, force);
            }),
            switchMap(() => {
                return this.devSvc.updateWarranty(this._dev, force);
            }),
            map(() => {
                if (this._dev.virtualDeviceOwnerID === (this.accountSvc.isEnterprise() ? this.accountSvc.enterpriseAccountID : this.accountSvc.accountID)) {
                    //your own device
                    Object.keys(SharePermissionType).forEach((permission: string) => {
                        this._sharePermissionStatus[SharePermissionType[permission]] = true;
                    });
                }
                else {
                    //device shared to you
                    Object.keys(SharePermissionType).forEach((permission: string) => {
                        this._sharePermissionStatus[SharePermissionType[permission]] = false;
                    });

                    if (this._dev.virtualDeviceSharing && this._dev.virtualDeviceSharing[this.accountSvc.accountID]) {
                        this._dev.virtualDeviceSharing[this.accountSvc.accountID].permission.forEach((p: string) => {
                            if (this._sharePermissionStatus[p] !== undefined) {
                                this._sharePermissionStatus[p] = true;
                            }
                        });
                    }
                }

                return true;
            }),
            switchMap(() => {
                Logger.logInfo(this.className, 'update', 'Share permission = ', this._sharePermissionStatus);
                return this.licenseSvc.getLicenseByDevice(this._dev.virtualId, force).pipe(
                    map(res => {
                        if (res) {
                            this._licenseInfo = {
                                iCareLicense: res[LicenseCategory.ICare],
                                cloudLicense: res[LicenseCategory.Cloud]
                            }

                            if (this._licenseInfo.iCareLicense && this._licenseInfo.iCareLicense.scope) {
                                this._licenseInfo.iCareLicense.scope.forEach((scope: string) => {
                                    if (LICENSE_SCOPE_FUNCTION_MAP[scope]) {
                                        this._licenseScopeMap[LICENSE_SCOPE_FUNCTION_MAP[scope].type] = LICENSE_SCOPE_FUNCTION_MAP[scope]
                                    }
                                });
                            }
                        }

                        Logger.logInfo(this.className, 'update', 'Available license scope =  ', this._licenseScopeMap);
                        this._loadingLicenses = false;

                        return null;
                    })
                );
            }),
            map(() => {
                Logger.logInfo(this.className, 'update', 'Device = ', this._dev);
                return null;
            }),
            catchError((err) => {
                return observableOf(err);
            })
        );
    }

    private playDevFunc(funcName: string): void {
        const devFuncItem: DevFuncItem = this.devFuncSvc.getFunctionByName(funcName);
        if (devFuncItem) {
            const viewContainerRef = this.devFuncHost.viewContainerRef;
            viewContainerRef.clear();

            const componentRef = viewContainerRef.createComponent(devFuncItem.component);

            (<DevFuncInterface>componentRef.instance).minWidth = 1000;
            (<DevFuncInterface>componentRef.instance).title = devFuncItem.title;
            (<DevFuncInterface>componentRef.instance).devices = this._dev;
            (<DevFuncInterface>componentRef.instance).lockMap = this._lockMap;
            switch (funcName) {
                case this.devFuncSvc.FUNCNAME_REBOOT:
                    {
                        (<DevFuncInterface>componentRef.instance).dialogCompleteHandler = this.submitRebootComplete.bind(this);
                    }
                    break;
                case this.devFuncSvc.FUNCNAME_FIRMWARE:
                    {
                        (<DevFuncInterface>componentRef.instance).dialogCompleteHandler = this.submitFirmwareUpdateComplete.bind(this);
                    }
                    break;
                case this.devFuncSvc.FUNCNAME_BASIC_CONFIG:
                    {
                        (<DevFuncInterface>componentRef.instance).dialogCompleteHandler = this.submitDeviceConfigComplete.bind(this);
                    }
                    break;
                case this.devFuncSvc.FUNCNAME_NET_CONFIG:
                    {
                        (<DevFuncInterface>componentRef.instance).dialogCompleteHandler = this.submitNetConfigComplete.bind(this);
                        (<DevFuncInterface>componentRef.instance).dialogInteractHandler = this.netConfigInteract.bind(this);
                    }
                    break;
                case this.devFuncSvc.FUNCNAME_SECURITY:
                    {
                        (<DevFuncInterface>componentRef.instance).dialogCompleteHandler = this.submitSecurityComplete.bind(this);
                    }
            }
        }
    }
}