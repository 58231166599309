import { HttpClient } from '@angular/common/http';
import { Directive, ElementRef, EventEmitter, forwardRef, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { fromEvent, of, Subject } from 'rxjs';
import { catchError, debounceTime, map, switchMap, takeUntil } from 'rxjs/operators';

@Directive({
    selector: '[domainName]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => DomainNameValidatorDirective), multi: true }
    ]
})
export class DomainNameValidatorDirective implements Validator, OnInit, OnDestroy {
    _eleRef: ElementRef;
    _valid: boolean;
    private _allUnsubscribe: Subject<void> = new Subject();

    @Input() queryDomain: boolean;
    @Input() queryURLTemplate: string;

    @Output() ngModelChange = new EventEmitter<string>();
    @Output() queryStatusChange = new EventEmitter<{ valid: boolean, exist?: boolean, checking?: boolean }>();

    @HostListener('input', ['$event']) onInputChange($event) {
        this.ngModelChange.emit($event.target.value.toLowerCase());
    }

    constructor(private eleRef: ElementRef, private http: HttpClient) {
        this._eleRef = eleRef;
    }

    ngOnInit(): void {
        if (this.queryDomain && this.queryURLTemplate) {
            const searchInputOb = fromEvent(this._eleRef.nativeElement, 'input');

            searchInputOb.pipe(
                debounceTime(200),
                switchMap((e: any) => {
                    if (this._valid) {
                        const url: string = this.queryURLTemplate.replace("[]", e.target.value.toLocaleLowerCase());
                        this.queryStatusChange.emit({ valid: true, checking: true });

                        return this.http.get(url, { responseType: 'text'}).pipe(
                            map((res: string) => {
                                return { valid: this._valid, status: 200 };
                            }),
                            catchError((err) => {
                                return of({ valid: this._valid, query: false, status: 404 });
                            })
                        );
                    }

                    return of({ valid: false, status: 0 });
                }),
                takeUntil(this._allUnsubscribe)
            ).subscribe((res: { valid: boolean, status: number }) => {
                    this.queryStatusChange.emit({ valid: res.valid, checking: false, exist: res.status === 200 });  
            });
        }
    }

    ngOnDestroy(): void {
        this._allUnsubscribe.next();
        this._allUnsubscribe.complete();
    }

    validate(c: AbstractControl): ValidationErrors {
        if (!c.value) {
            return null;
        }

        if (c.value.length < 3) {
            this._valid = false;
            return {
                domainName: {
                    error: 'URL is too short'
                }
            };
        }

        if (c.value.match(/^\-|[^a-z0-9\-]+|\-{2}|(?!\-)\bstage\b(?!\-)|(?!\-)\bdev\b(?!\-)|\-$/)) {
            this._valid = false;
            return {
                domainName: {
                    error: 'Contains invalid characters'
                }
            };
        }

        this._valid = true;
        return null;
    }

    registerOnValidatorChange?(fn: () => void): void {

    }
}