import { DeviceGroupInfo } from "../group.data";

import { Type } from '@angular/core';

export interface DeviceGroupFuncInterface {
    title: string;
    group: DeviceGroupInfo;
    _updating?: boolean;
    other?: any;
}

export class DeviceGroupFuncItem {
  constructor(public component: Type<any>, public name: string, public title: string, public data?: any) {}
}