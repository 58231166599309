import { Component } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DevFuncActionInterface } from '../../base/action/dev-func-action.interface';
import { DeviceInfo } from '../../../device/data/device-info';
import { ConstantService } from '../../../../lib/common/constant.service';

@Component({
    templateUrl: './otp-action-subform.component.html',
    styleUrls: ['../../base/base-config-form.component.css', './otp-action-subform.component.css']
})
export class OTPActionSubFormComponent implements DevFuncActionInterface {
    actionCompleteHandler?: (result: any) => void;
    actionStatusUpdateHandler?: (isValid: boolean) => void;
    actionCommunicateHandler?: (data: any) => void;

    _loading: boolean;
    _device: DeviceInfo;
    _support: boolean = false;
    _config: {
        current: { enabled: boolean, date: string };
        past: { enabled: boolean, date: string };
        loading: boolean;
        errorMessage: string;
        otp: string;
    };

    _actionData: any;
    set actionData(data: any) {
        this._actionData = data;
        this._config = this._actionData.config;
        this._device = this._actionData.deviceList[0];
        if (this._device) {
            this._support = this._device.features.otp.isSupport;
        }

        if (this._config && this._config.current) {
            this._config.current.date = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
        }
    }

    constructor(
        private datePipe: DatePipe,
        private constantSvc: ConstantService
    ) {
    }

    enableCurrentDate(): void {
        this._config.current.enabled = true;
        this._config.past.enabled = false;
    }

    enablePastDate(): void {
        this._config.past.enabled = true;
        this._config.current.enabled = false;
    }
}