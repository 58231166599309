<ul class='nav nav-tabs'>
    <li class="nav-item">
        <a id="id_alert_link_opened" class="nav-link" [class.active]="_activeTab === _enumAlertTabType.open"
            [routerLink]="_alertOpenRoute">
            <span>Open alerts</span>
        </a>
    </li>
    <li class="nav-item">
        <a id="id_alert_link_closed" class="nav-link" [class.active]="_activeTab === _enumAlertTabType.close"
            [routerLink]="_alertCloseRoute">
            <span>Close alerts</span>
        </a>
    </li>
    <li class='nav-item'>
        <a id="id_alert_link_config" class='nav-link' [class.active]="_activeTab === _enumAlertTabType.config"
            [routerLink]="_alertConfigRoute">
            <span>Settings</span>
        </a>
    </li>
</ul>
<div class='tab-content' class="pt-2">
    <router-outlet></router-outlet>
</div>