import { Injectable, EventEmitter, Output, Directive } from '@angular/core';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, concatAll, concatMap, delayWhen, map } from 'rxjs/operators';

import { DeviceGroupInfo, DeviceGroupType, DEVICE_GROUP_ID_ROOT, DEVICE_GROUP_ID_HOME, DEVICE_GROUP_NAME_DEFAULT, DeviceGroupMode, GroupSwitch } from './group.data';
import { Logger } from '../../../lib/common/logger';
import { IClass } from '../../../lib/common/common.data';
import { DeviceService } from '../device.service';
import { DeviceInfo, IDevicePairStatusChangeEventArgs, IDeviceUnpairStatusChangeEventArgs } from '../data/device-info';
import { AccountService } from '../../../entry/account.service';
import { NAService } from '../../../API/na.service';
import { IAPIRx } from '../../../API/api.base';
import { GroupRawInfo } from '../../../API/v1/device/group/api.group.common';
import { ConstantService } from '../../../lib/common/constant.service';
import { VirtualDeviceStatusHint } from '../data/virtual-device-info';
import { UserPreferenceService } from '../../user-preference.service';
import { HelperLib, REFRESH_DURATION } from '../../../lib/common/helper.lib';
import { UserService } from '../../../../app/content/admin/user/user.service';

@Directive()
@Injectable()
export class DeviceGroupService implements IClass {
    className: string = 'GroupSvc';
    readonly DEVGROUP_REFRESH_DURATION: number = REFRESH_DURATION * 20000;

    private _isReady: boolean = false;
    get isReady(): boolean {
        return this._isReady;
    }
    //to record allow-to-see-groups and group device (device as group) under this account, enterprise user could see device groups assigned in its user group only.
    private _groupMap: { [accountName: string]: { map: { [id: string]: DeviceGroupInfo }, activeGroup: DeviceGroupInfo, groupRouteList: DeviceGroupInfo[], defaultGroupID: string } } = {};
    //to record all groups under this account
    private _allGroupMap: { [groupID: string]: DeviceGroupInfo } = {};
    private _allGroupLastUpdateTime: number = 0;
    private _updatingAllGroup: boolean = false;

    _groupSwitch: GroupSwitch = GroupSwitch.on;
    get groupSwitch(): GroupSwitch {
        return this._groupSwitch;
    }

    _owner: { accountID: string, accountName: string };
    get owner(): { accountID: string, accountName: string } {
        return this._owner;
    }

    get ctrlAccountID(): string {
        return this._owner ? this._owner.accountID : null;
    }

    get ctrlAccountName(): string {
        return this._owner ? this._owner.accountName : null;
    }

    @Output() onRouteChanged = new EventEmitter<DeviceGroupInfo[]>();
    @Output() onActiveGroupChanged = new EventEmitter<DeviceGroupInfo>();
    @Output() onGroupOwnerChanged = new EventEmitter<{ accountID: string, mode: DeviceGroupMode }>();
    @Output() onGroupSwitchChanged = new EventEmitter<GroupSwitch>();

    constructor(
        private accountSvc: AccountService,
        private naSvc: NAService,
        private constantSvc: ConstantService,
        private devSvc: DeviceService,
        private userPrefSvc: UserPreferenceService,
        private userSvc: UserService
    ) {
        this.accountSvc.loginChanged.subscribe((isLogin: boolean) => {
            Logger.logInfo(this.className, '', 'Login status changed. IsLogin? = ' + isLogin);

            if (isLogin) {
                this.init();
            }
        });

        this.userSvc.onTargetDeviceGroupUpdated.subscribe((res: { deviceGroupIDList: string[] }) => {
            this.init();
            //location.reload();
        });

        this.devSvc.devicePairStatusChanged.subscribe((res: IDevicePairStatusChangeEventArgs) => {
            if (!res.isFault && res.statusHint === VirtualDeviceStatusHint.DeviceSynced && res.device && res.device.isPaired) {
                Logger.logInfo(this.className, '', 'Device paired & synced, update group device', res);
                //add a virtual group for this device
                this.internalGroupAdd(res.device.virtualDeviceOwner, res.device.groupID, [
                    { name: res.device.virtualName, id: res.device.virtualId, type: DeviceGroupType.device, data: res.device }
                ]);
            }
        });

        this.devSvc.deviceUnpairStatusChanged.subscribe((res: IDeviceUnpairStatusChangeEventArgs) => {
            Logger.logInfo(this.className, '', 'Device unpaired, update group device', res);
            if (!res.isFault && res.device && res.device.groupID) {
                const g: DeviceGroupInfo = this.getGroupByID(null, res.device.groupID);
                if (g) {
                    g.removeChildByID(res.device.virtualId);
                    if (this._groupMap[res.device.virtualDeviceOwner] && this._groupMap[res.device.virtualDeviceOwner].map) {
                        delete this._groupMap[res.device.virtualDeviceOwner].map[res.device.virtualId];
                    }
                }
            }
        });

        this.devSvc.devicesChanged.subscribe((deviceList: DeviceInfo[]) => {
            if (!this._isReady) {
                return;
            }

            Logger.logInfo(this.className, '', 'Device changed, update group device ...');
            Object.keys(this._groupMap).forEach((accountName: string) => {
                Object.keys(this._groupMap[accountName].map).forEach((groupID: string) => {
                    if (this._groupMap[accountName].map[groupID].type === DeviceGroupType.device) {
                        const parentID: string = this._groupMap[accountName].map[groupID].parentID;
                        if (parentID && this._groupMap[accountName].map[parentID]) {
                            this._groupMap[accountName].map[parentID].removeChild(this._groupMap[accountName].map[groupID]);
                        }

                        delete this._groupMap[accountName].map[groupID];
                    }
                });
            });

            deviceList.forEach(d => {
                if (d.isPaired && !this._groupMap[d.virtualDeviceOwner].map[d.virtualId]) {
                    this.internalGroupAdd(d.virtualDeviceOwner, d.groupID, [
                        { name: d.virtualName, id: d.virtualId, type: DeviceGroupType.device, data: d }
                    ]);
                }
            });
        });

        this.init();
    }

    private init(): void {
        this._groupMap = {};
        this.resetEnterpriseDeviceGroup();
        this._groupSwitch = this.userPrefSvc.userPreference.home.overviewLayout === 'group' ? GroupSwitch.on : GroupSwitch.off;
        this._owner = { accountID: this.accountSvc.enterpriseAccountID || this.accountSvc.accountID, accountName: this.accountSvc.enterpriseAccountName || this.accountSvc.accountName };
        this._isReady = false;

        this.refreshOwnerGroupList().pipe(
            concatMap((success: boolean) => {
                this._isReady = true;
                if (!success) {
                    return observableOf({ hasNext: false, isFault: true });
                }

                return this.refreshGroupByDeviceList();
            })
        ).subscribe((res: { hasNext: boolean, isFault: boolean, errorMessage?: string }) => {
            if (!res.hasNext) {
                Logger.logInfo(this.className, 'init', 'Group map = ', this._groupMap);
            }
        });
    }

    /**
     * Refresh all groups under owner account and shared account
     */
    private refreshGroupByDeviceList(): Observable<{ hasNext: boolean, isFault: boolean, errorMessage?: string }> {
        return this.devSvc.getDevicesByBatch('groupSvc.refreshGroupList').pipe(
            map((res: { isFault: boolean, hasNext: boolean, devices: DeviceInfo[], errorMessage?: string }) => {
                if (res.isFault) {
                    return { isFault: true, errorMessage: res.errorMessage, hasNext: res.hasNext };
                }

                const comparedAccountID = this.accountSvc.isEnterprise() ? this.accountSvc.enterpriseAccountID : this.accountSvc.accountID;
                const sharedAccountMap: { [accountName: string]: DeviceInfo[] } = {};
                res.devices.forEach(d => {
                    if (d.isPaired) {
                        if (d.virtualDeviceOwnerID === comparedAccountID) {
                            //add devices under owner account id.
                            this.internalGroupAdd(d.virtualDeviceOwner, d.groupID, [
                                { name: d.virtualName, id: d.virtualId, type: DeviceGroupType.device, data: d }
                            ]);
                        }
                        else {
                            //shared device
                            sharedAccountMap[d.virtualDeviceOwner] = sharedAccountMap[d.virtualDeviceOwner] || [];
                            sharedAccountMap[d.virtualDeviceOwner].push(d);
                        }
                    }
                });

                //create group structure for shared devices and their groups.
                Object.keys(sharedAccountMap).forEach((sharedAccountName: string) => {
                    //g-root (fake)
                    this._groupMap[sharedAccountName] = this._groupMap[sharedAccountName] || { map: {}, activeGroup: null, groupRouteList: [], defaultGroupID: null };
                    if (!this._groupMap[sharedAccountName].map[DEVICE_GROUP_ID_ROOT]) {
                        const rootGroup = new DeviceGroupInfo(DEVICE_GROUP_ID_ROOT, '', '', DeviceGroupType.group, null, false, false, true);
                        this._groupMap[sharedAccountName].map[DEVICE_GROUP_ID_ROOT] = rootGroup;
                    }

                    //g-home (fake)
                    if (!this._groupMap[sharedAccountName].map[DEVICE_GROUP_ID_HOME]) {
                        this.internalGroupAdd(sharedAccountName, DEVICE_GROUP_ID_ROOT, [
                            { id: DEVICE_GROUP_ID_HOME, name: 'Home', type: DeviceGroupType.group, policies: null, removable: false, movable: false, expanded: true }
                        ]);
                    }

                    //always set 'Home' as the active group for shared account
                    this._groupMap[sharedAccountName].activeGroup = this._groupMap[sharedAccountName].map[DEVICE_GROUP_ID_HOME];

                    sharedAccountMap[sharedAccountName].forEach((sharedDevice: DeviceInfo) => {
                        if (sharedDevice.virtualDeviceGroup) {
                            //add shared group from path
                            for (let index = 0; index < sharedDevice.virtualDeviceGroup.groupIDPath.length; ++index) {
                                if (this._groupMap[sharedAccountName].map[sharedDevice.virtualDeviceGroup.groupIDPath[index]]) {
                                    continue;
                                }

                                this.internalGroupAdd(
                                    sharedAccountName,
                                    index > 0 ? sharedDevice.virtualDeviceGroup.groupIDPath[index - 1] : DEVICE_GROUP_ID_HOME,
                                    [
                                        {
                                            id: sharedDevice.virtualDeviceGroup.groupIDPath[index],
                                            name: sharedDevice.virtualDeviceGroup.groupNamePath[index],
                                            type: DeviceGroupType.group,
                                            policies: (index === sharedDevice.virtualDeviceGroup.groupIDPath.length - 1) ? sharedDevice.virtualDeviceGroup.policies : null,
                                            removable: false,
                                            movable: false,
                                        }
                                    ]
                                );
                            }

                            //add shared device
                            this.internalGroupAdd(sharedAccountName, sharedDevice.groupID, [
                                {
                                    id: sharedDevice.virtualPairId,
                                    name: sharedDevice.virtualName,
                                    type: DeviceGroupType.device,
                                    policies: null,
                                    removable: false,
                                    movable: false,
                                    data: sharedDevice
                                }
                            ]);
                        }
                    });
                });

                return { hasNext: res.hasNext, isFault: false };
            })
        );
    }

    refreshOwnerGroupList(checkDuplicateName: boolean = true): Observable<boolean> {
        //refresh group under self account
        return this.naSvc.listDeviceGroup(this.accountSvc.token).pipe(
            map((res: IAPIRx<{ [accountName: string]: { [groupID: string]: GroupRawInfo } }>) => {
                if (res.error !== 0 || !res.data) {
                    Logger.logError(this.className, '', 'ListGroup failed. Error = ', res.error + ' ' + res.errorMessage);
                    return false;
                }

                Object.keys(res.data).forEach((accountName: string) => {
                    //g-root (fake)
                    this._groupMap[accountName] = this._groupMap[accountName] || { map: {}, activeGroup: null, groupRouteList: [], defaultGroupID: null };
                    if (!this._groupMap[accountName].map[DEVICE_GROUP_ID_ROOT]) {
                        const rootGroup = new DeviceGroupInfo(DEVICE_GROUP_ID_ROOT, '', '', DeviceGroupType.group, null, false, false, true);
                        this._groupMap[accountName].map[DEVICE_GROUP_ID_ROOT] = rootGroup;
                    }

                    //g-home (fake)
                    if (!this._groupMap[accountName].map[DEVICE_GROUP_ID_HOME]) {
                        this.internalGroupAdd(accountName, DEVICE_GROUP_ID_ROOT, [
                            { id: DEVICE_GROUP_ID_HOME, name: 'Home', type: DeviceGroupType.group, policies: null, removable: false, movable: false, expanded: true }
                        ]);
                    }

                    this._groupMap[accountName].activeGroup = this._groupMap[accountName].map[DEVICE_GROUP_ID_HOME];

                    //init other groups
                    Object.keys(res.data[accountName]).sort((a: string, b: string) => res.data[accountName][a].groupIDPath.length - res.data[accountName][b].groupIDPath.length).forEach((groupID: string) => {
                        if (res.data[accountName][groupID].isDefault) {
                            this._groupMap[accountName].defaultGroupID = groupID;
                            this.internalGroupAdd(
                                accountName,
                                DEVICE_GROUP_ID_HOME,
                                [
                                    {
                                        id: groupID,
                                        name: DEVICE_GROUP_NAME_DEFAULT,
                                        policies: res.data[accountName][groupID].policies,
                                        type: DeviceGroupType.group,
                                        removable: false,
                                        movable: false
                                    }
                                ],
                                checkDuplicateName
                            );
                        }
                        else {
                            this.internalGroupAdd(
                                accountName,
                                res.data[accountName][groupID].parentID || DEVICE_GROUP_ID_HOME,
                                [
                                    {
                                        id: groupID,
                                        name: res.data[accountName][groupID].groupName,
                                        type: DeviceGroupType.group,
                                        policies: res.data[accountName][groupID].policies,
                                        removable: true,
                                        movable: true
                                    }
                                ],
                                checkDuplicateName
                            );
                        }
                    });
                });

                return true;
            })
        );
    }

    getGroupByID(accountName: string, groupID: string): DeviceGroupInfo {
        accountName = accountName || this.ctrlAccountName;
        return this._groupMap[accountName].map[groupID];
    }

    getParentGroupByID(accountName: string, groupID: string): DeviceGroupInfo {
        accountName = accountName || this.ctrlAccountName;
        return this._groupMap[accountName].map[groupID] ? this._groupMap[accountName].map[this._groupMap[accountName].map[groupID].parentID] : null;
    }

    getRootGroup(accountName: string): DeviceGroupInfo {
        accountName = accountName || this.ctrlAccountName;
        return this._groupMap[accountName].map[DEVICE_GROUP_ID_ROOT];
    }

    getHomeGroup(accountName?: string): DeviceGroupInfo {
        accountName = accountName || this.ctrlAccountName;
        return this._groupMap[accountName]?.map[DEVICE_GROUP_ID_HOME];
    }

    getDefaultGroup(accountName?: string): DeviceGroupInfo {
        accountName = accountName || this.ctrlAccountName;
        return this._groupMap[accountName]?.map[this._groupMap[accountName].defaultGroupID];
    }

    isDefaultGroup(group: DeviceGroupInfo): boolean {
        return group.id === this.getDefaultGroup()?.id;
    }

    getActiveGroup(accountName?: string): DeviceGroupInfo {
        accountName = accountName || this.ctrlAccountName;
        return this._groupMap[accountName].activeGroup;
    }

    getGroupRouteList(accountName: string): DeviceGroupInfo[] {
        accountName = accountName || this.ctrlAccountName;
        return this._groupMap[accountName].groupRouteList;
    }

    unfoldAllGroups(accountName: string): void {
        accountName = accountName || this.ctrlAccountName;
        Object.keys(this._groupMap[accountName].map).filter(gID => this._groupMap[accountName].map[gID].type === DeviceGroupType.group).forEach(gID => this._groupMap[accountName].map[gID].expanded = false);
    }

    expandAllGroups(accountName: string): void {
        accountName = accountName || this.ctrlAccountName;
        Object.keys(this._groupMap[accountName].map).filter(gID => this._groupMap[accountName].map[gID].type === DeviceGroupType.group).forEach(gID => this._groupMap[accountName].map[gID].expanded = true);
    }

    changeOwnerAccount(owner: { accountID: string, accountName: string }): void {
        if (this.ctrlAccountID !== owner.accountID) {
            this._owner.accountID = owner.accountID;
            this._owner.accountName = owner.accountName;

            this.onGroupOwnerChanged.next({ accountID: this.ctrlAccountID, mode: this.ctrlAccountID === this.accountSvc.accountID ? DeviceGroupMode.edit : DeviceGroupMode.viewonly });
        }
    }

    turnOnOffGroup(s: GroupSwitch): void {
        if (this._groupSwitch !== s) {
            this._groupSwitch = s;
            this.onGroupSwitchChanged.next(this._groupSwitch);
        }
    }

    private internalGroupAddPreCheck(
        accountName: string,
        parentGroupID: string,
        groupInfoList: { id?: string, name: string, type: DeviceGroupType, policies?: { Configuration?: string[], Security?: string[] }, removable?: boolean, movable?: boolean, expanded?: boolean, data?: any }[],
        checkDuplicateName: boolean = true
    ): { isFault: boolean, errorMessage?: string } {
        if (!this._groupMap[accountName]) {
            Logger.logError(this.className, 'addNewGroup', 'Account missing: ' + accountName);
            return { isFault: true, errorMessage: 'Account missing: ' + accountName };
        }

        if (!this._groupMap[accountName].map[parentGroupID]) {
            Logger.logError(this.className, 'addNewGroup', 'Parent group ID ' + parentGroupID + ' not exist', groupInfoList);
            return { isFault: true, errorMessage: 'Parent group ID not exist' };
        }

        if (groupInfoList.find(gi => gi.type === DeviceGroupType.group) && parentGroupID === this._groupMap[accountName].defaultGroupID) {
            return { isFault: true, errorMessage: 'Could not add group under "' + DEVICE_GROUP_NAME_DEFAULT + '"' };
        }

        if (groupInfoList.find(gi => !gi.name || (gi.type === DeviceGroupType.group && gi.name.indexOf('/') >= 0))) {
            return { isFault: true, errorMessage: 'Group name should not be empty or contains any "/" characters' };
        }

        //if input group names are duplicate
        if (groupInfoList.length > 1) {
            const duplicateNameMap: { [name: string]: number } = {};
            groupInfoList.forEach(gi => {
                duplicateNameMap[gi.name] = duplicateNameMap[gi.name] || 0;
                duplicateNameMap[gi.name]++;
            });
            const duplicateNames: string[] = Object.keys(duplicateNameMap).filter(name => duplicateNameMap[name] > 1);
            if (duplicateNames.length > 0) {
                return { isFault: true, errorMessage: 'Duplicate group names are not allowed : ' + duplicateNames.join(',') };
            }
        }

        //if input group names are already exist
        if (checkDuplicateName) {
            const existNames: string[] = [];
            groupInfoList.forEach(gi => {
                if (this._groupMap[accountName].map[parentGroupID].childs.filter(c => c.type === DeviceGroupType.group).find(c => c.name === gi.name)) {
                    Logger.logError(this.className, 'addNewGroup', 'Group name ' + gi.name + ' already exists.');
                    existNames.push(gi.name);
                }
            });
            if (existNames.length > 0) {
                return { isFault: true, errorMessage: 'These group names are already exist: ' + existNames.join(',') };
            }
        }

        return { isFault: false };
    }

    private internalGroupAdd(
        accountName: string,
        parentGroupID: string,
        groupInfoList: {
            id: string,
            name: string,
            type: DeviceGroupType,
            policies?: { Configuration?: string[], Security?: string[], Application?: string[], FirmwareUpdate?: string[] },
            removable?: boolean,
            movable?: boolean,
            expanded?: boolean,
            data?: any
        }[],
        checkDuplicateName: boolean = true
    ): { isFault: boolean, errorMessage?: string } {
        accountName = accountName || this.ctrlAccountName;
        const preCheckResult = this.internalGroupAddPreCheck(accountName, parentGroupID, groupInfoList, checkDuplicateName);
        if (preCheckResult.isFault) {
            return preCheckResult;
        }

        groupInfoList.forEach(gi => {
            if (!this._groupMap[accountName].map[gi.id]) {
                const g: DeviceGroupInfo = new DeviceGroupInfo(gi.id, parentGroupID, gi.name, gi.type, gi.policies, gi.removable, gi.movable, gi.expanded, gi.data);

                this._groupMap[accountName].map[gi.id] = g;
                if (checkDuplicateName) {
                    this._groupMap[accountName].map[parentGroupID].childs.push(g);
                }

                //temp
                if (g.data) {
                    g.data.currentSettings[this.constantSvc.DEVKEY_FAKE_GROUPNAME] = this._groupMap[accountName].map[parentGroupID].name;
                }
            }
            else {
                this._groupMap[accountName].map[gi.id].name = gi.name;
                this._groupMap[accountName].map[gi.id].policies = {
                    Configuration: gi.policies && gi.policies.Configuration ? gi.policies.Configuration : [],
                    Security: gi.policies && gi.policies.Security ? gi.policies.Security : [],
                    Application: gi.policies && gi.policies.Application ? gi.policies.Application : [],
                    FirmwareUpdate: gi.policies && gi.policies.FirmwareUpdate ? gi.policies.FirmwareUpdate : []
                };
            }
        });

        return { isFault: false };
    }

    addNewGroup(accountName: string, parentGroupID: string, groupInfoList: { id?: string, name: string, type: DeviceGroupType, removable?: boolean, movable?: boolean, expanded?: boolean, data?: any }[]): Observable<{ isFault: boolean, errorMessage?: string }> {
        accountName = accountName || this.ctrlAccountName;
        Logger.logInfo(this.className, 'addNewGroup', 'Add group under ' + parentGroupID, groupInfoList);

        const preCheckResult = this.internalGroupAddPreCheck(accountName, parentGroupID, groupInfoList);
        if (preCheckResult.isFault) {
            return observableOf(preCheckResult);
        }

        return observableOf(true).pipe(
            concatMap(() => {
                return parentGroupID === DEVICE_GROUP_ID_HOME ? this.naSvc.createDeviceGroup({ groupNameList: groupInfoList.map(gi => gi.name) }, this.accountSvc.token) : this.naSvc.createDeviceGroup({ groupNameList: groupInfoList.map(gi => gi.name), parentID: parentGroupID }, this.accountSvc.token);
            }),
            map((res: IAPIRx<GroupRawInfo[]>) => {
                if (res.error === 0) {
                    res.data.forEach(r => {
                        const g: DeviceGroupInfo = new DeviceGroupInfo(r.groupID, r.parentID || DEVICE_GROUP_ID_HOME, r.groupName, DeviceGroupType.group, null);
                        this._groupMap[accountName].map[r.groupID] = g;
                        this._groupMap[accountName].map[r.parentID || DEVICE_GROUP_ID_HOME].childs.push(g);

                        //temp
                        if (g.data) {
                            g.data.currentSettings[this.constantSvc.DEVKEY_FAKE_GROUPNAME] = this._groupMap[accountName].map[parentGroupID].name;
                        }
                    });

                    this.resetEnterpriseDeviceGroup();

                    if (this.onActiveGroupChanged) {
                        this.onActiveGroupChanged.emit(this._groupMap[accountName].activeGroup);
                    }
                }

                return { isFault: res.error !== 0, errorMessage: res.error === 0 ? '' : res.error + ' ' + res.errorMessage };
            })
        );
    }

    deleteGroup(accountName: string, currentGroup: DeviceGroupInfo, deleteGroupList: DeviceGroupInfo[]): Observable<{ isFault: boolean, errorMessage?: string }> {
        accountName = accountName || this.ctrlAccountName;

        if (currentGroup.id === this._groupMap[accountName].defaultGroupID) {
            //could not remove 'Default Group' and any devices under it.
            return observableOf({ hasNext: false, isFault: true, errorMessage: 'Could not delete group "' + DEVICE_GROUP_NAME_DEFAULT + '" and devices under it.' });
        }
        else {
            if (currentGroup.id === DEVICE_GROUP_ID_HOME && deleteGroupList.length === 1 && deleteGroupList[0].id === currentGroup.id) {
                //could not remove 'Home Group' since it's a fake group
                return observableOf({ hasNext: false, isFault: true, errorMessage: 'Could not delete group "Home"' });
            }

            if (deleteGroupList.find(g => g.id === this._groupMap[accountName].defaultGroupID)) {
                //could not remove 'Default Group'
                return observableOf({ hasNext: false, isFault: true, errorMessage: 'Could not delete group "' + DEVICE_GROUP_NAME_DEFAULT + '", please re-select the groups' });
            }
        }

        const removeDeviceList = deleteGroupList.filter(g => g.type === DeviceGroupType.device && g.data);
        const removeGroupList = deleteGroupList.filter(g => g.type === DeviceGroupType.group);

        const errorList: string[] = [];
        const waitOb = new Observable((observer) => {
            observableOf(true).pipe(
                concatMap(() => {
                    const obs: Observable<{ hasNext: boolean, group?: DeviceGroupInfo, isFault: boolean, errorMessage?: string }>[] = [];

                    //remove groups
                    removeGroupList.forEach(g => {
                        obs.push(this.naSvc.removeDeviceGroup({ deviceGroupID: g.id }, this.accountSvc.token).pipe(
                            map((res: IAPIRx<{ [groupID: string]: GroupRawInfo }>) => {
                                if (res.error === 0) {
                                    this.deleteLoop(accountName, g);
                                }

                                return {
                                    hasNext: true,
                                    group: g,
                                    isFault: res.error !== 0,
                                    errorMessage: res.errorMessage
                                }
                            })
                        ));
                    });

                    //remove devices
                    if (removeDeviceList.length > 0) {
                        obs.push(this.naSvc.updateDeviceInDeviceGroup({ deviceGroupID: currentGroup.id }, { removeList: removeDeviceList.map(g => (g.data as DeviceInfo).virtualId) }, this.accountSvc.token).pipe(
                            map((res: IAPIRx<any>) => {
                                if (res.error === 0) {
                                    removeDeviceList.forEach(g => this.deleteLoop(accountName, g));
                                }

                                return {
                                    hasNext: true,
                                    isFault: res.error !== 0,
                                    errorMessage: res.errorMessage
                                }
                            })
                        ))
                    }

                    obs.push(observableOf({
                        hasNext: false,
                        isFault: false
                    }));

                    return obs;
                }),
                concatAll()
            ).subscribe((res: { hasNext: boolean, group?: DeviceGroupInfo, isFault: boolean, errorMessage?: string }) => {
                if (res.hasNext) {
                    if (res.errorMessage) {
                        errorList.push(res.errorMessage);
                    }
                }
                else {
                    this.resetEnterpriseDeviceGroup();

                    observer.next();
                    observer.complete();
                }
            });
        });

        return observableOf(true).pipe(
            delayWhen(() => waitOb),
            map(() => {
                if (deleteGroupList.length === 1 && deleteGroupList[0].id === currentGroup.id) {
                    //user select the deleted group on the group tree
                    Logger.logInfo(this.className, 'deleteGroup', 'Reset active group and route');
                    this._groupMap[accountName].activeGroup = this._groupMap[accountName].map[this._groupMap[accountName].defaultGroupID];
                    this._groupMap[accountName].activeGroup.active = true;
                    this.updateRoute(accountName, this._groupMap[accountName].activeGroup);
                }

                this.resetEnterpriseDeviceGroup();

                return {
                    isFault: errorList.length > 0,
                    errorMessage: errorList.join(',')
                }
            })
        );
    }

    private deleteLoop(accountName: string, g: DeviceGroupInfo): void {
        if (g.type === DeviceGroupType.device) {
            this.internalGroupMove(accountName, [g], this._groupMap[accountName].defaultGroupID);
        }
        else {
            const targetChildList: DeviceGroupInfo[] = g.childs.map(c => c);
            targetChildList.forEach(c => {
                this.deleteLoop(accountName, c);
            });

            g.cleanChilds();

            //remove self
            if (g.removable) {
                delete this._groupMap[accountName].map[g.id];

                //delete group in _allGroupMap
                delete this._allGroupMap[g.id];

                if (this._groupMap[accountName].map[g.parentID]) {
                    this._groupMap[accountName].map[g.parentID].removeChild(g);
                }
            }
        }
    }

    private internalGroupMoveCheck(accountName: string, currentGroupList: DeviceGroupInfo[], toGroupID: string): { hasNext: boolean, isFault: boolean, errorMessage?: string } {
        if (toGroupID === this._groupMap[accountName].defaultGroupID && currentGroupList.find(g => this._groupMap[accountName].map[g.id].type === DeviceGroupType.group)) {
            return { hasNext: false, isFault: true, errorMessage: 'Could only move groups to "' + DEVICE_GROUP_NAME_DEFAULT + '"' };
        }

        return { hasNext: false, isFault: false };
    }

    private internalGroupMove(accountName: string, fromGroupList: DeviceGroupInfo[], toGroupID: string, notify: boolean = false): { hasNext: boolean, isFault: boolean, errorMessage?: string } {
        accountName = accountName || this.ctrlAccountName;

        const preCheckResult = this.internalGroupMoveCheck(accountName, fromGroupList, toGroupID);
        if (preCheckResult.isFault) {
            return preCheckResult;
        }

        fromGroupList.forEach((g: DeviceGroupInfo) => {
            const origin_parent_id: string = this._groupMap[accountName].map[g.id].parentID;
            if (origin_parent_id === toGroupID || g.id === toGroupID) {
                return;
            }

            //remove from origin group
            if (origin_parent_id && this._groupMap[accountName].map[origin_parent_id]) {
                const index: number = this._groupMap[accountName].map[origin_parent_id].childs.indexOf(this._groupMap[accountName].map[g.id]);
                if (index >= 0) {
                    this._groupMap[accountName].map[origin_parent_id].childs.splice(index, 1);
                }
            }

            //add to new group
            if (this._groupMap[accountName].map[g.id].type === DeviceGroupType.device) {
                (this._groupMap[accountName].map[g.id].data as DeviceInfo).groupID = toGroupID;
                (this._groupMap[accountName].map[g.id].data as DeviceInfo).currentSettings[this.constantSvc.DEVKEY_FAKE_GROUPNAME] = this._groupMap[accountName].map[toGroupID].name;
            }

            this._groupMap[accountName].map[g.id].parentID = toGroupID;
            this._groupMap[accountName].map[toGroupID].childs.push(this._groupMap[accountName].map[g.id]);

            this._groupMap[accountName].map[g.id].selected = false;
            if (this._groupMap[accountName].map[g.id].data) {
                this._groupMap[accountName].map[g.id].data.isSelect = false;
            }
        });

        if (notify) {
            if (this.onActiveGroupChanged) {
                this.onActiveGroupChanged.emit(this._groupMap[accountName].activeGroup);
            }
        }

        return { hasNext: false, isFault: false };
    }

    moveGroup(accountName: string, fromGroupList: DeviceGroupInfo[], toGroupID: string, notify: boolean = false): Observable<{ isFault: boolean, errorMessage?: string }> {
        accountName = accountName || this.ctrlAccountName;

        Logger.logInfo(this.className, 'moveGroup', 'Move to ' + toGroupID + ' from ', fromGroupList);

        const preCheckResult = this.internalGroupMoveCheck(accountName, fromGroupList, toGroupID);
        if (preCheckResult.isFault) {
            return observableOf(preCheckResult);
        }

        const moveDeviceList = fromGroupList.filter(g => g.type === DeviceGroupType.device && g.data);
        const moveGroupList = fromGroupList.filter(g => g.type === DeviceGroupType.group);

        return observableOf(true).pipe(
            concatMap(() => {
                if (moveGroupList.length > 0) {
                    return this.naSvc.batchUpdateDeviceGroup({ groupList: moveGroupList.map(gi => { return { groupID: gi.id, parentID: toGroupID === DEVICE_GROUP_ID_HOME ? null : toGroupID } }) }, this.accountSvc.token).pipe(
                        map((res: IAPIRx<GroupRawInfo[]>) => {
                            if (res.error !== 0) {
                                throw HelperLib.getErrorMessage(res);
                            }

                            this.internalGroupMove(accountName, moveGroupList, toGroupID);

                            this.resetEnterpriseDeviceGroup();

                            return true;
                        })
                    );
                }

                return observableOf(true);
            }),
            concatMap(() => {
                if (moveDeviceList.length > 0 && toGroupID !== DEVICE_GROUP_ID_HOME) {
                    return this.naSvc.updateDeviceInDeviceGroup({ deviceGroupID: toGroupID }, { addList: moveDeviceList.map(g => (g.data as DeviceInfo).virtualId) }, this.accountSvc.token).pipe(
                        map((res: IAPIRx<any>) => {
                            if (res.error !== 0) {
                                throw HelperLib.getErrorMessage(res);
                            }

                            this.internalGroupMove(accountName, moveDeviceList, toGroupID);
                            return true;
                        })
                    );
                }

                return observableOf(true);
            }),
            map(() => {
                if (notify) {
                    this.onActiveGroupChanged.emit(this._groupMap[accountName].activeGroup);
                }

                return { isFault: false };
            }),
            catchError((err: any) => {
                return observableOf({ isFault: true, errorMessage: err })
            })
        );
    }

    renameGroup(accountName: string, g: DeviceGroupInfo, name: string): Observable<{ isFault: boolean, errorMessage?: string }> {
        accountName = accountName || this.ctrlAccountName;

        if (g.id === this._groupMap[accountName].defaultGroupID) {
            return observableOf({ isFault: true, errorMessage: 'Could not rename group "' + DEVICE_GROUP_NAME_DEFAULT + '"' });
        }

        if (!this._groupMap[accountName].map[g.id] || !this._groupMap[accountName].map[g.parentID]) {
            return observableOf({ isFault: true, errorMessage: 'Internal error: no match group' });
        }

        if (this._groupMap[accountName].map[g.parentID].childs.filter(c => c.type === DeviceGroupType.group).find(c => c.name === name)) {
            return observableOf({ isFault: true, errorMessage: 'Group name already exists' });
        }

        return this.naSvc.updateDeviceGroup({ deviceGroupID: g.id }, { groupName: name, parentID: g.parentID === DEVICE_GROUP_ID_HOME ? null : g.parentID }, this.accountSvc.token).pipe(
            map((res: IAPIRx<GroupRawInfo>) => {
                if (res.error !== 0) {
                    return { isFault: true, errorMessage: res.error + ' ' + res.errorMessage };
                }

                g.name = name;

                if (this._allGroupMap[g.id]) {
                    this._allGroupMap[g.id].name = name;
                }

                return { isFault: false };
            })
        );
    }

    inspectGroup(accountName: string, g: DeviceGroupInfo, expand: boolean = false): void {
        accountName = accountName || this.ctrlAccountName;

        if (g.type !== DeviceGroupType.group) {
            return;
        }

        //could only have one group in active state in default mode
        Object.keys(this._groupMap[accountName].map).forEach(gID => this._groupMap[accountName].map[gID].active = false);
        g.active = true;
        this._groupMap[accountName].activeGroup = g;
        g.expanded = expand;

        this.updateRoute(accountName, g);

        if (this.onActiveGroupChanged) {
            this.onActiveGroupChanged.emit(this._groupMap[accountName].activeGroup);
        }
    }

    getHomeGroupRoute(): DeviceGroupInfo[] {
        return this.generateRoute(null, this.getHomeGroup());
    }

    private updateRoute(accountName: string, g: DeviceGroupInfo): void {
        const routes: DeviceGroupInfo[] = this.generateRoute(accountName, g);
        this._groupMap[accountName].groupRouteList = routes;

        if (this.onRouteChanged) {
            this.onRouteChanged.emit(this._groupMap[accountName].groupRouteList);
        }
    }

    private generateRoute(accountName: string, g: DeviceGroupInfo): DeviceGroupInfo[] {
        accountName = accountName || this.ctrlAccountName;

        let routes: DeviceGroupInfo[] = [g];
        let pg: DeviceGroupInfo = g;
        while (pg.parentID && pg.parentID !== DEVICE_GROUP_ID_ROOT && this._groupMap[accountName].map[pg.parentID]) {
            routes.push(this._groupMap[accountName].map[pg.parentID]);
            if (pg.expanded) {
                this._groupMap[accountName].map[pg.parentID].expanded = true;
            }
            pg = this._groupMap[accountName].map[pg.parentID];
        };

        routes = routes.reverse();

        return routes;
    }

    allowCreateGroup(g: DeviceGroupInfo): boolean {
        return g.type === DeviceGroupType.group;
    }

    getAllGroupDeviceList(accountName?: string): DeviceGroupInfo[] {
        accountName = accountName || this.ctrlAccountName;
        if (this._groupMap[accountName] && this._groupMap[accountName].map) {
            return Object.keys(this._groupMap[accountName].map).filter(gID => this._groupMap[accountName].map[gID].type === DeviceGroupType.device).map(gID => this._groupMap[accountName].map[gID]);
        }
        else {
            return [];
        }
    }

    getEnterpriseDeviceHomeGroup(): Observable<DeviceGroupInfo> {
        if (this._updatingAllGroup) {
            return new Observable((observer) => {
                HelperLib.checkState(1, () => { return !this._updatingAllGroup }, () => {
                    observer.next(this._allGroupMap[DEVICE_GROUP_ID_HOME]);
                    observer.complete();
                });
            });
        }

        if (HelperLib.mapToList(this._allGroupMap).length > 0 && Date.now() - this._allGroupLastUpdateTime < this.DEVGROUP_REFRESH_DURATION) {
            return observableOf(this._allGroupMap[DEVICE_GROUP_ID_HOME]);
        }

        this._updatingAllGroup = true;
        this._allGroupMap = {};
        return this.naSvc.getEnterpriseDeviceGroup(this.accountSvc.token).pipe(
            map((res: IAPIRx<{ [accountName: string]: { [groupID: string]: GroupRawInfo } }>) => {
                if (res.error !== 0 || !res.data) {
                    Logger.logError(this.className, '', 'getEnterpriseGroupRoot failed. Error = ', res.error + ' ' + res.errorMessage);
                    return null;
                }

                if (this.accountSvc.enterpriseAccountName && res.data[this.accountSvc.enterpriseAccountName]) {
                    const target = res.data[this.accountSvc.enterpriseAccountName];
                    //g-root (fake)
                    const g_root = new DeviceGroupInfo(DEVICE_GROUP_ID_ROOT, '', '', DeviceGroupType.group, null, false, false, true);
                    this._allGroupMap[DEVICE_GROUP_ID_ROOT] = g_root;
                    //g-home (fake)
                    const g_home: DeviceGroupInfo = new DeviceGroupInfo(DEVICE_GROUP_ID_HOME, DEVICE_GROUP_ID_ROOT, 'Home', DeviceGroupType.group, null, false, false, true);
                    this._allGroupMap[g_home.id] = g_home;
                    this._allGroupMap[DEVICE_GROUP_ID_ROOT].childs.push(g_home);

                    Object.keys(target).sort((a: string, b: string) => target[a].groupIDPath.length - target[b].groupIDPath.length).forEach((groupID: string) => {
                        const g: DeviceGroupInfo = new DeviceGroupInfo(groupID, target[groupID].parentID || DEVICE_GROUP_ID_HOME, target[groupID].groupName, DeviceGroupType.group, null, false, false, true);
                        this._allGroupMap[g.id] = g;
                        if (this._allGroupMap[g.parentID]) {
                            this._allGroupMap[g.parentID].childs.push(g);
                        }
                    });
                }

                this._updatingAllGroup = false;
                this._allGroupLastUpdateTime = Date.now();

                return this._allGroupMap[DEVICE_GROUP_ID_HOME];
            })
        );
    }

    getEnterpriseGroupByID(groupID: string): DeviceGroupInfo {
        return this._allGroupMap[groupID];
    }

    private resetEnterpriseDeviceGroup(): void {
        this._allGroupLastUpdateTime = 0;
    }
}