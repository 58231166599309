<div class="login-page">
  <div class="row">
    <!-- left -->
    <div
      [ngClass]="_appMode === _enumAppMode.preview || _isEnterprise ? 'col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4' : 'col-12 col-lg-5 col-xl-4 offset-lg-1'">
      <div class="login-box">
        <div class="logo">
          <img [src]="appSvc.logoDarkUrl | safe: 'url'">
        </div>
        <div class="content">
          <form>
            <div class="form-group">
              <div class="d-flex">
                <label for="login-email">EMAIL</label>
                <a *ngIf="_showPwd" id="link_diffAccount" class="ml-auto" [class.disalbed]="_isLogining" tabindex="-1"
                  (click)="signinToDiffAccount()">
                  <small>
                    Different account
                  </small>
                </a>
              </div>
              <input id="login-email" type="text" class="form-control" name="accountName"
                [disabled]="_isLogining || _showPwd" [(ngModel)]="_accountName" (keypress)="keyDown($event.keyCode)"
                aria-label="email">
            </div>
            <div *ngIf="_showPwd" class="form-group">
              <div class="d-flex">
                <label for="login-pwd">PASSWORD</label>
                <a *ngIf="_enableForgetPwdFeature" id="link_forgetPassword" class="ml-auto"
                  [class.disabled]="_isLogining" tabindex="-1" (click)="gotoForgetPassword()">
                  <small>Forget</small>
                </a>
              </div>
              <input #passwordInput id="login-pwd" type="password" class="form-control" name="password"
                [disabled]="_isLogining" [(ngModel)]="_accountPassword" (keypress)="keyDown($event.keyCode)"
                autocomplete="current-password" aria-label="user password">
            </div>
          </form>
          <div *ngIf="_errorMessage" class="text-danger center">
            {{ _errorMessage }}
          </div>
          <div class="btn-wrapper">
            <button id="id_entry_do_login" class="btn btn-custom btn-green"
              [disabled]="_isLogining || !_accountName || (_showPwd && !_accountPassword)" (click)="login()">
              {{ _loginBtnText }}
            </button>
            <a #redirectLink [href]="_redirectURL" class="d-none">
              Redirect
            </a>
            <div [class.loading-box]="_isLogining"></div>
            <ng-template [ngIf]="_enableSignupFeature">
              <p class="center my-1">OR</p>
              <button id="id_entry_link_signup" class="btn btn-custom btn-blue" [disabled]="_isLogining"
                [routerLink]="['/account/register']">
                {{ 'key-signup' | translate }}
              </button>
            </ng-template>
          </div>
          <div class="bottom">
            <div [ngClass]="_appMode !== _enumAppMode.preview ? '' : 'text-center'">
              <div>Version: {{ _appVersion }}</div>
              <div>Release: {{ _releaseDate }}</div>
            </div>
            <div *ngIf="_appMode !== _enumAppMode.preview && !_isEnterprise">
              <a class="link-enterprise" [routerLink]="['/account/application/enterprise']">
                Upgrade to enterprise user
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="login-box-footer">
        <a *ngIf="_appMode !== _enumAppMode.preview && !_isEnterprise" class="brand" href="http://www.iadea.com">www.IAdea.com</a>
        <div class="recaptcha">
          This site is protected by reCAPTCHA and the Google
          <a href="https://policies.google.com/privacy">Privacy Policy</a> and
          <a href="https://policies.google.com/terms">Terms of Service</a> apply.
        </div>
      </div>
    </div>
    <!-- right -->
    <div *ngIf="_appMode !== _enumAppMode.preview && !_isEnterprise" class="col-12 col-lg-5 col-xl-6">
      <div class="title text-center text-lg-left">
        {{ _slogan }}
      </div>
      <div class="sub-title text-center text-lg-left mt-lg-5 mt-md-4 mt-2">
        CASE STUDIES
      </div>
      <div class="case-block">
        <a *ngFor="let case of _caseList" [id]="case.id" [href]="case.link" target="_blank" class="case">
          <div class="case-icon">
            <img [src]="case.icon">
          </div>
          <div *ngIf="case.desc" class="case-desc">
            {{ case.desc }}
          </div>
        </a>
      </div>
    </div>
  </div>
</div>