import { Component } from '@angular/core';

import { DevFuncConfirmInterface } from '../../base/confirm/dev-func-confirm.interface';

@Component({
    templateUrl: './security-confirm-subform.component.html',
    styleUrls: ['../../base/base-config-form.component.css', './security-confirm-subform.component.css']
})
export class SecurityConfirmSubFormComponent implements DevFuncConfirmInterface {
    allowSubmitCallback: (isAllow: boolean) => void;

    _actionData: any;
    set actionData(data: any) {
        this._actionData = data;
    }
}