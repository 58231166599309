<div *ngIf="isInEdit; else templateViewMode">
    <div class="form-group row">
        <label for="col-firmware-location" class="col-5 col-md-4 col-xl-3 col-form-label col-form-label-sm">
            Firmware location
        </label>
        <div id="col-firmware-location" class="col-7 col-md-8 col-xl-9">
            <div class="btn-group w-100">
                <button id="dropdownFirmwareLocationLink" type="button"
                    class="btn btn-sm btn-action btn-light dropdown-toggle" data-toggle="dropdown">
                    {{ _firmwareServerSource?.displayName }}
                    <span class="caret"></span>
                </button>
                <ul class="dropdown-menu dropdown-menu-left option-list w-100"
                    aria-labelledby="dropdownFirmwareLocationLink">
                    <li *ngFor="let firmwareSource of FIRMWARE_UPDATE_SERVER_SOURCES"
                        [class.selected]="_firmwareServerSource?.type == firmwareSource.type"
                        (click)="selectFirmwareSource(firmwareSource)">
                        {{ firmwareSource.displayName }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="content-block">
        <div>
            Install update automatically
            <div class='switch-container'>
                <input id="cmn-toggle-firmwareUpdate" class="cmn-toggle cmn-toggle-flat cmn-toggle-rect-flat"
                    type="checkbox" [checked]="_data.enabled"
                    (change)="changeEnableAutoFirmwareUpdate($event.target.checked)">
                <label for="cmn-toggle-firmwareUpdate">
                    <span class="sr-only">Enable firmware auto update</span>
                </label>
            </div>
        </div>
        <ul>
            <li [class.d-none]="_data.serverSource !== 'ota'" class="mt-2">
                OTA URL:
                <input #otaServerUrl type="TEXT" aria-label="firmware update OTA server URL"
                    class="form-control form-control-sm" [value]="_data.otaServer">
            </li>
            <li class="mt-2">
                Check interval (hours)
                <input type="number" aria-label="Firmware update check interval" max="24" min="1" step="1"
                    [value]="_data.checkIntervalHours" class="form-control form-control-sm d-inline-block w-auto"
                    (change)="changeCheckIntervalHours($event.target.valueAsNumber)">
            </li>
            <li *ngIf="_data.enabled" class="mt-2">
                <div class="d-flex flex-wrap align-items-center">
                    Daily maintenance window
                    <div class="btn-group mx-2">
                        <button id="dropdownFirmwareDailyCheckBeginTimeLink" type="button"
                            class="btn btn-sm btn-action btn-light dropdown-toggle" data-toggle="dropdown">
                            {{ _data.installWindow.beginTime }}
                            <span class="sr-only">modify firmware daily check begin time</span>
                        </button>
                        <ul class="dropdown-menu dropdown-menu-left"
                            aria-labelledby="dropdownFirmwareDailyCheckBeginTimeLink" style="min-width:300px">
                            <div class="px-2">
                                <na-timePicker [time]="_data.installWindow.beginTime" [minuteIncre]="5"
                                    [showBorder]="false" [showSelectionTime]="false"
                                    (onTimeChanged)="changeInstallWindowBeginTime($event)">
                                </na-timePicker>
                            </div>
                        </ul>
                    </div>
                    to
                    <div class="btn-group mx-2">
                        <button id="dropdownFirmwareDailyCheckEndTimeLink" type="button"
                            class="btn btn-sm btn-action btn-light dropdown-toggle" data-toggle="dropdown">
                            {{ _data.installWindow.endTime }}
                            <span class="sr-only">modify firmware daily check end time</span>
                        </button>
                        <ul class="dropdown-menu dropdown-menu-left"
                            aria-labelledby="dropdownFirmwareDailyCheckEndTimeLink" style="min-width:300px">
                            <div class="px-2">
                                <na-timePicker [time]="_data.installWindow.endTime" [minuteIncre]="5"
                                    [showBorder]="false" [showSelectionTime]="false"
                                    (onTimeChanged)="changeInstallWindowEndTime($event)">
                                </na-timePicker>
                            </div>
                        </ul>
                    </div>
                    <small>(Update will only occurred during this period)</small>
                </div>
            </li>
            <li *ngIf="_data.enabled" class="mt-2">
                <div class="d-flex flex-wrap align-items-center">
                    Annual freeze period
                    <input type="date" aria-label="Firmware update annual freeze begin date"
                        [value]="_data.annualFreezePeriod.beginDate" [attr.min]="_freezeBeginMinLimit"
                        [attr.max]="_freezeBeginMaxLimit"
                        class="form-control form-control-sm d-inline-block w-auto mx-2"
                        (change)="changeFreezeBeginPeriod($event.target.value)">
                    to
                    <input type="date" aria-label="Firmware update annual freeze end date"
                        [value]="_data.annualFreezePeriod.endDate" [attr.min]="_freezeEndMinLimit"
                        [attr.max]="_freezeEndMaxLimit" class="form-control form-control-sm d-inline-block w-auto mx-2"
                        (change)="changeFreezeEndPeriod($event.target.value)">
                </div>
                <small>(Update will not occurred during this period. 90 days max.)</small>
                <small class="d-block">(Annual freeze period is not supported on device with Android 7.1)</small>
            </li>
        </ul>
    </div>
</div>
<ng-template #templateViewMode>
    <div class="content-block">
        <div>
            <label for="enableFirmwareAutoUpdate">Install update automatically :</label>
            <span id="enableFirmwareAutoUpdate" class="form-control-plaintext-sm ml-2">{{ _data.enabled ? 'Enable' :
                'Disable' }}</span>
        </div>
        <div *ngIf="_data.serverSource === 'ota'">
            <label for="otaServer">OTA URL :</label>
            <span id="otaServer" class="form-control-plaintext-sm ml-2">{{ _data.otaServer }}</span>
        </div>
        <div>
            <label for="checkInterval">Check interval (hours) :</label>
            <span id="checkInterval" class="form-control-plaintext-sm ml-2">{{ _data.checkIntervalHours }}</span>
        </div>
        <ng-template [ngIf]="_data.enabled">
            <div>
                <label for="dailyInstallWindow">Daily maintenance window (HH:MM) :</label>
                <span id="dailyInstallWindow" class="form-control-plaintext-sm ml-2">{{ _data.installWindow.beginTime }}
                    ~ {{ _data.installWindow.endTime }}</span>
            </div>
            <div>
                <label for="annualFreezePeriod">Annual freeze periods (MM-DD) :</label>
                <span id="annualFreezePeriod" class="form-control-plaintext-sm ml-2">{{
                    _data.annualFreezePeriod.beginDate?.substring(5) }} ~ {{
                    _data.annualFreezePeriod.endDate?.substring(5) }}</span>
            </div>
        </ng-template>
    </div>
</ng-template>