
import { Injectable, Directive, EventEmitter, Output } from '@angular/core';
import { AppConfigService } from 'app/app.config';
import { AccountService } from '../../app/entry/account.service';
import { ConstantService } from '../../app/lib/common/constant.service';

export interface IMenu {
    id: string;
    path: string;
    icon?: string; // menu icon
    name: string; // menu name
    title?: string; // content title when switch to this page
    support?: boolean;
    isRoot?: boolean;
    isLink?: boolean;
    isExpand?: boolean;
    children?: IMenu[];
    compatiblePaths?: string[];
}

@Directive()
@Injectable()
export class TopMenuService {
    readonly MENU_ID_ROOT: string = 'id_lb_menu_root';
    readonly MENU_ID_ROOT_ADMIN: string = this.MENU_ID_ROOT + '_admin';

    readonly MENU_ID_DEVICE_WRAPPER: string = 'id_lb_menu_devices';
    readonly MENU_ID_DEVICE_ALL: string = 'id_lb_link_devices'
    readonly MENU_ID_DEVICE_PAIR: string = 'id_lb_link_pair';
    readonly MENU_ID_DEVICE_POLICY: string = 'id_lb_link_devPolicy';

    readonly MENU_ID_NOTIFICATION_WRAPPER: string = 'id_lb_menu_notification';
    readonly MENU_ID_NOTIFICATION_ALERT: string = 'id_lb_link_alert';
    readonly MENU_ID_NOTIFICATION_REPORT: string = 'id_lb_link_report';

    readonly MENU_ID_MISCELLANEOUS_WRAPPER: string = 'id_lb_menu_miscellaneous';
    readonly MENU_ID_MISCELLANEOUS_LANTOOL: string = 'id_lb_link_lanTool';
    readonly MENU_ID_MISCELLANEOUS_ACCKEY: string = 'id_lb_link_accKey';

    readonly MENU_ID_TROUBLESHOOT: string = 'id_lb_menu_troubleshoot';
    readonly MENU_ID_LICENSE_WRAPPER: string = 'id_lb_menu_license';
    readonly MENU_ID_LICENSE_ACCOUNT: string = 'id_lb_menu_license_account';
    readonly MENU_ID_LICENSE_DEVICE: string = 'id_lb_menu_license_device';

    readonly MENU_ID_EVENT_WRAPPER: string = 'id_lb_menu_event';
    readonly MENU_ID_EVENT_LOG: string = 'id_lb_link_eventLog';
    readonly MENU_ID_EVENT_ACTIVITY: string = 'id_lb_link_devActivity';

    readonly MENU_ID_ADMIN_WRAPPER: string = 'id_lb_menu_admin';
    readonly MENU_ID_USER_ALL: string = 'id_lb_link_user';
    readonly MENU_ID_USER_POLICY: string = 'id_lb_link_userPolicy';
    readonly MENU_ID_ADMIN_SCEP: string = 'id_lb_link_scep';
    readonly MENU_ID_USER_CUSTOMIZATION: string = 'id_lb_link_customization';
    readonly MENU_ID_CERTIFICATE: string = 'id_lb_link_certificate';

    private _activeMenuMap: { [menuID: string]: boolean } = {};
    private _menu: IMenu;
    get menu(): IMenu {
        return this._menu;
    }

    private _menuAdmin: IMenu;
    get menuAdmin(): IMenu {
        return this._menuAdmin;
    }

    @Output() onAdminMenuExpandChanged = new EventEmitter<{ menu: IMenu, expand: boolean }>();

    constructor(private constantSvc: ConstantService, private accountSvc: AccountService) {
        this.init();

        this.accountSvc.loginChanged.subscribe((isLogin: boolean) => {
            if (isLogin) {
                this.init();
            }
        });
    }

    private init(): void {
        this._menu = {
            id: this.MENU_ID_ROOT,
            name: 'Root',
            path: 'app',
            isRoot: true,
            support: true,
            children: [
                {
                    id: this.MENU_ID_DEVICE_WRAPPER,
                    name: 'Devices',
                    path: 'device',
                    icon: '#icon-nav-devs',
                    support: true,
                    children: [
                        {
                            id: this.MENU_ID_DEVICE_ALL,
                            name: 'All devices',
                            path: 'devices',
                            support: true,
                            isLink: true
                        },
                        {
                            id: this.MENU_ID_DEVICE_PAIR,
                            name: 'Registration',
                            path: 'pair/own',
                            support: this.accountSvc.hasScope_device_update(),
                            isLink: true
                        },
                        {
                            id: this.MENU_ID_DEVICE_POLICY,
                            name: 'Policy',
                            path: 'policy',
                            support: true,
                            isLink: true
                        }
                    ]
                },
                {
                    id: this.MENU_ID_NOTIFICATION_WRAPPER,
                    name: 'Notifications',
                    path: 'notification',
                    icon: '#icon-nav-notification',
                    support: true,
                    children: [
                        {
                            id: this.MENU_ID_NOTIFICATION_ALERT,
                            name: 'Alert',
                            path: 'alert',
                            support: true,
                            isLink: true
                        },
                        {
                            id: this.MENU_ID_NOTIFICATION_REPORT,
                            name: 'Report',
                            path: 'report',
                            support: true,
                            isLink: true
                        },
                    ]
                },
                {
                    id: this.MENU_ID_TROUBLESHOOT,
                    name: 'Troubleshoot',
                    path: 'troubleshoot',
                    icon: '#icon-nav-debug',
                    support: this.accountSvc.hasScope_ticket_view(),
                    isLink: true
                },
                {
                    id: this.MENU_ID_LICENSE_WRAPPER,
                    name: 'License',
                    path: 'license',
                    icon: '#icon-nav-license',
                    support: this.accountSvc.hasScope_license_view(),
                    children: [
                        {
                            id: this.MENU_ID_LICENSE_ACCOUNT,
                            name: 'Account',
                            title: 'Account license',
                            path: 'account',
                            support: true,
                            isLink: true
                        },
                        {
                            id: this.MENU_ID_LICENSE_DEVICE,
                            name: 'Device',
                            title: 'Device license',
                            path: 'device',
                            support: true,
                            isLink: true
                        },
                    ]
                },
                {
                    id: this.MENU_ID_EVENT_WRAPPER,
                    name: 'Event feeds',
                    path: 'event',
                    icon: '#icon-nav-activity',
                    support: true,
                    children: [
                        {
                            id: this.MENU_ID_EVENT_LOG,
                            name: 'Event log',
                            path: 'log',
                            support: this.accountSvc.hasScope_event_view(),
                            isLink: true
                        },
                        {
                            id: this.MENU_ID_EVENT_ACTIVITY,
                            name: 'Device activity',
                            path: 'activity',
                            support: this.accountSvc.hasScope_event_view(),
                            isLink: true
                        }
                    ]
                },
                {
                    id: this.MENU_ID_MISCELLANEOUS_WRAPPER,
                    name: 'Miscellaneous',
                    path: 'miscellaneous',
                    icon: '#icon-nav-miscellaneous',
                    support: true,
                    children: [
                        {
                            id: this.MENU_ID_MISCELLANEOUS_LANTOOL,
                            name: 'LAN tool',
                            path: 'LanConfigTool',
                            support: this.accountSvc.hasScope_tools_lanTool() && AppConfigService.configs.settingPage.lanTool.enabled,
                            isLink: true
                        },
                        {
                            id: this.MENU_ID_MISCELLANEOUS_ACCKEY,
                            name: 'Access key',
                            path: 'accessKey',
                            support: this.accountSvc.hasScope_accessKey_update(),
                            isLink: true
                        },
                        {
                            id: this.MENU_ID_CERTIFICATE,
                            name: 'Certificate',
                            path: 'cert',
                            support: true,
                            isLink: true
                        }
                    ]
                }
            ]
        };

        this._menuAdmin = {
            id: this.MENU_ID_ROOT_ADMIN,
            name: 'Root',
            path: 'app',
            isRoot: true,
            support: this.accountSvc.hasScope_enterprise_admin(),
            children: [
                {
                    id: this.MENU_ID_ADMIN_WRAPPER,
                    name: 'Admin',
                    path: 'admin',
                    icon: '#icon-nav-admin',
                    support: true,
                    children: [
                        {
                            id: this.MENU_ID_USER_ALL,
                            name: 'User account',
                            path: 'users/all',
                            support: true,
                            isLink: true
                        },
                        {
                            id: this.MENU_ID_USER_POLICY,
                            name: 'User role',
                            path: 'users/role',
                            support: true,
                            isLink: true
                        },
                        {
                            id: this.MENU_ID_ADMIN_SCEP,
                            name: 'SCEP',
                            path: 'scep',
                            support: true,
                            isLink: true
                        },
                        {
                            id: this.MENU_ID_USER_CUSTOMIZATION,
                            name: 'Customization',
                            path: 'customization',
                            support: true,
                            isLink: true
                        }
                    ]
                }
            ]
        }
    }

    getAlertRoute(innerTabName?: string): string {
        const routeIDs: string[] = [this.MENU_ID_NOTIFICATION_WRAPPER, this.MENU_ID_NOTIFICATION_ALERT];
        return this.findRouteByIDs(this._menu, routeIDs, innerTabName);
    }

    getReportRoute(innerTabName?: string): string {
        const routeIDs: string[] = [this.MENU_ID_NOTIFICATION_WRAPPER, this.MENU_ID_NOTIFICATION_REPORT];
        return this.findRouteByIDs(this._menu, routeIDs, innerTabName);
    }

    getScepRoute(): string {
        const routeIDs: string[] = [this.MENU_ID_ADMIN_WRAPPER, this.MENU_ID_ADMIN_SCEP];
        return this.findRouteByIDs(this._menuAdmin, routeIDs);
    }

    getUserRoute(): string {
        const routeIDs: string[] = [this.MENU_ID_ADMIN_WRAPPER, this.MENU_ID_USER_ALL];
        return this.findRouteByIDs(this._menuAdmin, routeIDs);
    }

    private findRouteByIDs(rootMenu: IMenu, routeIDs: string[], innerTabName?: string): string {
        let routeIndex: number = 0;
        const routes: string[] = [rootMenu.path];

        while (routeIndex < routeIDs.length) {
            const cMenu: IMenu = rootMenu.children.find(c => c.id === routeIDs[routeIndex]);
            if (!cMenu) {
                break;
            }

            routes.push(cMenu.path);
            rootMenu = cMenu;
            routeIndex++;
        }

        return '/' + routes.join('/') + (innerTabName ? '/' + innerTabName : '');
    }

    expandMenu(m: IMenu): boolean {
        //only menu with childrens should expand
        if (m.children && m.children.length > 0) {
            m.isExpand = !m.isExpand;
            if (m.id === this.MENU_ID_ADMIN_WRAPPER) {
                this.onAdminMenuExpandChanged.emit({ menu: m, expand: m.isExpand });
            }
        }

        return m.isExpand;
    }

    private resetExpand(rootMenu?: IMenu, expandMenu?: IMenu): void {
        const targetList: IMenu[] = rootMenu ? rootMenu.children : this._menu.children.concat(this._menuAdmin.children);
        for (let menu of targetList) {
            if (expandMenu && menu.id === expandMenu.id) {
                continue;
            }

            menu.isExpand = false;
            if (menu.children) {
                this.resetExpand(menu, expandMenu);
            }
        }
    }

    isMenuActive(menu: IMenu): boolean {
        return this._activeMenuMap[menu.id];
    }

    updateActiveMenuRoute(path: string): IMenu[] {
        if (!path) {
            return [];
        }

        Object.keys(this._activeMenuMap).map(mID => this._activeMenuMap[mID] = false);
        this.resetExpand();

        let targetList: IMenu[] = this._menu.children.concat(this._menuAdmin.children);
        let path_to_search: string = path.substring(5);  //starts after /app/
        const menu_routes: IMenu[] = [];
        do {
            let foundMenu: IMenu;
            for (let menu of targetList) {
                if (path_to_search.indexOf(menu.path) === 0) {
                    foundMenu = menu;
                    break;
                }
            }

            targetList = [];
            if (foundMenu) {
                this._activeMenuMap[foundMenu.id] = true;
                menu_routes.push(foundMenu);

                path_to_search = path_to_search.substring(foundMenu.path.length + 1);
                if (path_to_search.length === 0) {
                    break;
                }

                targetList = foundMenu.children;
            }
        } while (targetList && targetList.length > 0);

        if (menu_routes.length > 0) {
            for (let i = 0; i < menu_routes.length - 1; ++i) {
                menu_routes[i].isExpand = true;
            }
        }

        //get admin menu expand status
        const adminMenu: IMenu = this._menuAdmin.children.find(m => m.id === this.MENU_ID_ADMIN_WRAPPER);
        if (adminMenu) {
            this.onAdminMenuExpandChanged.emit({ menu: adminMenu, expand: adminMenu.isExpand });
        }

        return menu_routes;
    }
}