export class EnterpriseInfo {
    name: string;
    url: string;
    rootAccount: string; //email
    secondEmail: string;
    domain: string;
    sso: {
        enabled: boolean;
        tenantID: string;
        appID: string;
        appName: string;
        appIdentity: string;
        appCert: string;
        userName: string;
        userPwd: string;
    }

    constructor() {
        this.name = '';
        this.url = '';
        this.rootAccount = '';
        this.secondEmail = '';
        this.domain = '';
        this.sso = {
            enabled: false,
            tenantID: '',
            appID: '',
            appName: '',
            appIdentity: '',
            appCert: '',
            userName: '',
            userPwd: ''
        }
    }
}