import { Pipe, PipeTransform } from '@angular/core';

import { DeviceInfo } from '../../content/device/data/device-info';

@Pipe({
    name: 'mergeDeviceNamePipe'
})
export class MergeDeviceNamesPipe implements PipeTransform {
    transform(value: DeviceInfo[], ...args: string[]): string {
        if (value === undefined) {
            return '';
        }

        return value.map(d => d.virtualName).join(', ');
    }
}