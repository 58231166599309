import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IPolicyTypeFunc } from './policy-type.service';
import { PolicyType, PolicyDataFirmwareUpdate, PolicyFirmwareServerSource } from '../policy.data';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { DatePipe } from '@angular/common';

@Component({
    templateUrl: './policy-firmwareUpdate.component.html',
    styleUrls: ['../policy.style.css']
})
export class PolicyTypeFirmwareUpdateComponent implements IPolicyTypeFunc, OnInit, OnDestroy {
    readonly MAX_ANNUAL_FREEZE_LENGTH: number = 89;
    type: PolicyType;
    _data: PolicyDataFirmwareUpdate;
    set data(d: PolicyDataFirmwareUpdate) {
        this._data = d;
        this._firmwareServerSource = this.FIRMWARE_UPDATE_SERVER_SOURCES.find(s => s.type === this._data.serverSource);
    }
    isInEdit: boolean;
    onPolicyDataValidate?: (valid: boolean) => void;

    _freezeEndMaxLimit: string;
    _freezeEndMinLimit: string;
    _freezeBeginMaxLimit: string;
    _freezeBeginMinLimit: string;

    private _unsubscribe$: Subject<void> = new Subject();

    private _otaServerUrlElementRef: ElementRef;
    @ViewChild('otaServerUrl')
    set otaServerUrl(s: ElementRef) {
        this._otaServerUrlElementRef = s;
        if (this._otaServerUrlElementRef) {
            const searchInputOb = fromEvent(this._otaServerUrlElementRef.nativeElement, 'input');

            searchInputOb.pipe(
                debounceTime(300),
                takeUntil(this._unsubscribe$)
            ).subscribe((e: any) => {
                this._data.otaServer = e.target.value;
            });
        }
    }

    readonly FIRMWARE_UPDATE_SERVER_SOURCES: { type: PolicyFirmwareServerSource, displayName: string }[] = [
        {
            type: 'current',
            displayName: 'Latest firmware from current server'
        },
        {
            type: 'ota',
            displayName: 'Customized OTA server URL'
        }
    ];
    _firmwareServerSource: { type: PolicyFirmwareServerSource, displayName: string };

    constructor(private datePipe: DatePipe) {}

    ngOnInit(): void {
        if (this._data.annualFreezePeriod.beginDate) {
            this._freezeEndMinLimit = this.getDateFormat(this._data.annualFreezePeriod.beginDate, -this.MAX_ANNUAL_FREEZE_LENGTH);
            this._freezeEndMaxLimit = this.getDateFormat(this._data.annualFreezePeriod.beginDate, this.MAX_ANNUAL_FREEZE_LENGTH);
        }
        if (this._data.annualFreezePeriod.endDate) {
            this._freezeBeginMinLimit = this.getDateFormat(this._data.annualFreezePeriod.endDate, -this.MAX_ANNUAL_FREEZE_LENGTH);
            this._freezeBeginMaxLimit = this.getDateFormat(this._data.annualFreezePeriod.endDate, this.MAX_ANNUAL_FREEZE_LENGTH);
        }
    }

    ngOnDestroy(): void {
        this._unsubscribe$.next();
        this._unsubscribe$.complete();
    }

    selectFirmwareSource(source: { type: PolicyFirmwareServerSource, displayName: string }): void {
        this._firmwareServerSource = source;
        this._data.serverSource = source.type;
    }

    changeEnableAutoFirmwareUpdate(enabled: boolean): void {
        this._data.enabled = enabled;
    }

    changeCheckIntervalHours(hours: number): void {
        this._data.checkIntervalHours = hours;
    }

    changeInstallWindowBeginTime(time: string): void {
        this._data.installWindow.beginTime = time;
        this.validateData();
    }

    changeInstallWindowEndTime(time: string): void {
        this._data.installWindow.endTime = time;
        this.validateData();
    }

    changeFreezeBeginPeriod(date: string): void {
        this._data.annualFreezePeriod.beginDate = date;
        this._freezeEndMinLimit = this.getDateFormat(date, -this.MAX_ANNUAL_FREEZE_LENGTH);
        this._freezeEndMaxLimit = this.getDateFormat(date, this.MAX_ANNUAL_FREEZE_LENGTH);
        this.validateData();
    }

    changeFreezeEndPeriod(date: string): void {
        this._data.annualFreezePeriod.endDate = date;
        this._freezeBeginMinLimit = this.getDateFormat(date, -this.MAX_ANNUAL_FREEZE_LENGTH);
        this._freezeBeginMaxLimit = this.getDateFormat(date, this.MAX_ANNUAL_FREEZE_LENGTH);
        this.validateData();
    }

    private getDateFormat(date: string, addDay: number = 0): string {
        const d: Date = new Date(date);
        return addDay === 0 ? this.datePipe.transform(d, 'yyyy-MM-dd') : this.datePipe.transform(d.setDate(d.getDate() + addDay), 'yyyy-MM-dd');
    }

    private validateData(): void {
        let valid: boolean = true;
        if (this._data.enabled) {
            const installWindowBeginFlag: boolean = !this._data.installWindow.beginTime;
            const installWindowEndFlag: boolean = !this._data.installWindow.endTime;
            if (installWindowBeginFlag !== installWindowEndFlag) {
                valid = false;
            }

            if (valid) {
                const freezeBeginFlag: boolean = !this._data.annualFreezePeriod.beginDate;
                const freezeEndFlag: boolean = !this._data.annualFreezePeriod.endDate;
                if (freezeBeginFlag !== freezeEndFlag) {
                    valid = false;
                }
            }
        }
        this.onPolicyDataValidate(valid);
    }
}