<!-- route on small view -->
<div *ngIf="_funcMode === 'default'" class="d-flex d-md-none group-routes">
    <i *ngIf="!_showGroupTree; else templateShowUserGroupTree" class="fas fa-chevron-circle-down mr-2"
        data-toggle="tooltip" title="Show user group" (click)="_showGroupTree = true"></i>
    <ng-template #templateShowUserGroupTree>
        <i class="fas fa-chevron-circle-up mr-2" data-toggle="tooltip" title="Hide user group"
            (click)="_showGroupTree = false"></i>
    </ng-template>
    <div class="group-route-item" (click)="selectUserGroup()">All users</div>
    <div *ngIf="_selectedUserGroup" class="group-route-item" (click)="selectUserGroup(g)">
        {{ _selectedUserGroupID }}
    </div>
</div>
<div *ngIf="_showGroupTree" class="group-view" [class.float]="_isMobileLayout">
    <div *ngIf="_funcMode === 'default'" class="title-block">
        <label for="userGroups">User groups :</label>
        <i *ngIf="supportUpdateUserGroup()" class="fas fa-folder-plus ml-auto" data-toggle="modal"
            data-target="#userFuncUnderUserGroupViewModalHost" (click)="createUserGroup()">
            <span class="sr-only">New user group</span>
        </i>
    </div>
    <div id="userGroups" class="content-block" [class.h-auto]="_funcMode !== 'default'">
        <div class="group-item" [class.selected]="!_selectedUserGroup" (click)="selectUserGroup()">
            All users ({{ _userCount }})
        </div>
        <div class="ml-2">
            <div *ngFor="let g of _userGroupList" class="group-item" [class.selected]="g.id === _selectedUserGroup?.id"
                [class.dragOver]="_dragMap[g.id]" (click)="selectUserGroup(g)" (drop)="onDrop($event, g)"
                (dragover)="onDragOver($event)" (dragenter)="onDragEnter($event, g)"
                (dragleave)="onDragLeave($event, g)">
                <i class="fas fa-caret-right"></i>
                <span class="ml-2">{{ g.name }} ({{ _userCountMap[g.id] || 0 }})</span>
            </div>
        </div>
    </div>
</div>
<button #btnChangeGroup type="button" class="btn d-none" data-toggle="modal"
    data-target="#userFuncUnderUserGroupViewModalHost" (click)="changeGroup()">Change group</button>
<div id="userFuncUnderUserGroupViewModalHost" class="modal fade" data-backdrop="static" data-keyboard="false"
    role="dialog">
    <ng-template user-dlg-func></ng-template>
</div>