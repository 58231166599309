import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../../api.base';

export interface IUnAckAlertNotificationPathParameter {
    alertNotificationID: string;
}

export class APIUnAckAlertNotificationManager extends APIBaseManager<IUnAckAlertNotificationPathParameter, void, void, any> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_UnAckAlertNotification';
        this._apiMethod = 'DELETE';
    }

    protected getRequestURL(pathParameters: IUnAckAlertNotificationPathParameter, queryParameters: void): string {
        return super.getRequestURL() + 'accounts/alertNotifications/' + pathParameters.alertNotificationID + '/unacknowledge';
    }
}