import { Component, ElementRef, ViewChild } from '@angular/core';
import { HelperLib } from '../../../../../lib/common/helper.lib';
import { IUserDlgFuncContent } from '../user-dlg-func.def';
import { UserInfo } from '../../user.data';
import { UserService } from '../../user.service';

@Component({
    templateUrl: './user-dlg-inviteBulk-content.component.html',
    styleUrls: ['../../user.style.css']
})
export class UserInviteBulkDlgContentComponent implements IUserDlgFuncContent<UserInfo[], { usersFromCsv?: UserInfo[], message?: string }> {
    data: UserInfo[];
    funcName: string;
    other?: { usersFromCsv?: UserInfo[], message?: string } = {};
    onActionPrepared: () => void;
    onActionValidChanged: (valid: boolean) => void;

    _dirty: boolean = false; //if user had load the .csv.
    _errorMessage: string;

    private _downloadFileRef: ElementRef;
    @ViewChild('downloadLink')
    set downloadLink(holder: ElementRef) {
        if (holder) {
            this._downloadFileRef = holder;
        }
    }

    constructor(private userSvc: UserService) {}

    downloadTemplate(): void {
        const header: string = 'User invitation list (columns with * are the required fields)\n';
        const rowList: string[][] = [];
        rowList.push(['First name*', 'Last name*', 'Email*', 'Company', 'Department']);

        HelperLib.downloadCsv(this._downloadFileRef.nativeElement, 'User_invitation_template', header, rowList);
    }

    onFileClick(e: any): void {
        e.target.value = null;
    }

    onFileChange(e: any): void {
        this._errorMessage = null;
        this.other.usersFromCsv = [];
        this.extractContent(e.target.files[0]);
    }

    private extractContent(f: File): void {
        const reader = new FileReader();
        reader.onloadend = () => {
            const raw = reader.result as string;
            const rowList: string[] = raw.trim().split(/[\r\n]+/);

            if (rowList) {
                let meetFirstLegalLine: boolean = false;

                for (let i = 0; i < rowList.length; ++i) {
                    const colList: string[] = rowList[i].split(/,/);

                    if (colList && colList.length === 5) {
                        if (!meetFirstLegalLine) {
                            if (colList[0] === 'First name*') {
                                meetFirstLegalLine = true;
                            }
                            
                            continue;
                        }

                        //Columnes: first name, last name, email, company, department, user policy, user group
                        const u: UserInfo = new UserInfo();
                        u.firstName = colList[0];
                        u.lastName = colList[1];
                        u.email = colList[2];
                        u.company = colList[3];
                        u.department = colList[4];
                        u.userRole = 'Viewer';
                        u.userGroupID = this.userSvc.getDefaultUserGroup().id;

                        this.other.usersFromCsv.push(u);
                    }
                }

                this._dirty = true;
                if (this.other.usersFromCsv.length === 0) {
                    this._errorMessage = 'Do not find any user';
                    this.onActionValidChanged(false);
                }
                else {
                    this.onActionValidChanged(true);
                }
            }
            else {
                this._errorMessage = 'Parse file content failed. Please check your file again';
                this.onActionValidChanged(false);
            }
        };
        reader.onerror = (error: any) => {
            this._errorMessage = error.toString();
            this.onActionValidChanged(false);
        };
        reader.readAsText(f);
    }
}