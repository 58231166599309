import { Injectable } from '@angular/core';
import { UserDlgFuncItem, USER_DLG_FUNC_CHANGEGROUP, USER_DLG_FUNC_EDITCONFIRM, USER_DLG_FUNC_INVITE, USER_DLG_FUNC_INVITE_BULK, USER_DLG_FUNC_REMOVE, USER_DLG_FUNC_RESETPWD, USER_DLG_GROUP_FUNC_NEW } from './user-dlg-func.def';
import { UserChangeGroupDlgComponent } from './user/user-dlg-changeGroup.component';
import { UserConfirmEditDlgComponent } from './user/user-dlg-editConfirm.component';
import { UserInviteDlgComponent } from './user/user-dlg-invite.component';
import { UserBatchInviteDlgComponent } from './user/user-dlg-inviteBulk.component';
import { UserRemoveDlgComponent } from './user/user-dlg-remove.component';
import { UserResetPwdDlgComponent } from './user/user-dlg-resetPwd.component';
import { UserGroupRemoveDlgComponent } from './group/user-group-dlg-remove.component';
import { USER_DLG_GROUP_FUNC_REMOVE } from './user-dlg-func.def';
import { UserGroupNewDlgComponent } from './group/user-group-dlg-new.component';

@Injectable()
export class UserDlgFuncService {
  private _items: UserDlgFuncItem[] = [];

  constructor() {
    this._items = [
      new UserDlgFuncItem(UserRemoveDlgComponent, USER_DLG_FUNC_REMOVE, 'Delete user'),
      new UserDlgFuncItem(UserInviteDlgComponent, USER_DLG_FUNC_INVITE, 'Invite user'),
      new UserDlgFuncItem(UserBatchInviteDlgComponent, USER_DLG_FUNC_INVITE_BULK, 'Invite multiple users'),
      new UserDlgFuncItem(UserChangeGroupDlgComponent, USER_DLG_FUNC_CHANGEGROUP, 'Change user group'),
      new UserDlgFuncItem(UserResetPwdDlgComponent, USER_DLG_FUNC_RESETPWD, 'Reset user password'),
      new UserDlgFuncItem(UserConfirmEditDlgComponent, USER_DLG_FUNC_EDITCONFIRM, 'Edit user'),

      new UserDlgFuncItem(UserGroupRemoveDlgComponent, USER_DLG_GROUP_FUNC_REMOVE, 'Remove user group'),
      new UserDlgFuncItem(UserGroupNewDlgComponent, USER_DLG_GROUP_FUNC_NEW, 'New user group')
    ];
  }

  getItemByName(name: string): UserDlgFuncItem {
    return this._items.find(i => i.name === name);
  }
}
