<div>
  <div class="col-12 px-2">
    <div class="title">Please confirm the update method you choose.</div>
    <div *ngIf="_noNeedUpdate" class="warning-block mb-2">
      No need update
    </div>
    <div *ngIf="_schedule && _schedule.enabled" class="mt-1">
      <strong>Schedule : </strong>
      <span class="blue-2">{{ _schedule.date }} {{ _schedule.time }} GMT {{ _schedule.offset }}:00</span>
    </div>
    <div *ngFor="let familyInfo of _firmwareFamilyInfos" class="card card-list">
      <div class="card-header">
        {{ familyInfo.familyDisplayName }} (Android {{ familyInfo.targetAndroidVersion }})
      </div>
      <div class="card-body">
        <div class="form-group row">
          <span class="col-4">Update method</span>
          <div class="col-8 pl-0">
            <ng-template [ngIf]="familyInfo.updateMethod === _enumFirmwareUpdateMethod.Latest">
              <span>Latest version</span>
              <span class="ml-2">
                (
                <span *ngIf="familyInfo.updateInfo" class="text-info">
                  {{ familyInfo.updateInfo?.releaseDate }}, {{ familyInfo.updateInfo?.firmwareVersion }}
                </span>
                <span *ngIf="!familyInfo.updateInfo" class="text-danger">
                  Could not find update information.
                </span>
                )
              </span>
            </ng-template>
            <ng-template [ngIf]="familyInfo.updateMethod === _enumFirmwareUpdateMethod.FromURL">
              <span>Specific URL</span>
              <span class="text-info"> ({{ familyInfo.customURL }})</span>
            </ng-template>
          </div>
        </div>
        <div class="form-group row">
          <span class="col-4">Update status</span>
          <div class="col-8 pl-0">
            <span *ngIf="!hasSelectDevice(familyInfo)" class="text-danger">
              Do not select a device
            </span>
            <ng-template [ngIf]="familyInfo.updateMethod === _enumFirmwareUpdateMethod.Latest">
              <div *ngFor="let device of familyInfo.devices" class="devices"
                [ngClass]="_bSpecific || device.isSelect ? '' : 'hidden'">
                <span *ngIf="!familyInfo.updateInfo" class="text-danger fas fa-question-circle">
                </span>
                <span *ngIf="device?.currentSettings[constantSvc.DEVKEY_INFO_FW] >= familyInfo.updateInfo?.firmwareVersion"
                  class="text-danger fas fa-minus-circle">
                </span>
                <span *ngIf="device?.currentSettings[constantSvc.DEVKEY_INFO_FW] < familyInfo.updateInfo?.firmwareVersion"
                  class="text-info fas fa-arrow-alt-circle-up">
                </span>
                <span class="ml-2">{{ device?.virtualName }} :</span>
                <span>
                  {{ device?.currentSettings[constantSvc.DEVKEY_INFO_FW] }} &rarr;
                </span>
                <span *ngIf="familyInfo.updateInfo">
                  {{ familyInfo.updateInfo?.firmwareVersion }}
                </span>
              </div>
            </ng-template>
            <ng-template [ngIf]="familyInfo.updateMethod === _enumFirmwareUpdateMethod.FromURL">
              <div *ngFor="let device of familyInfo.devices" class="devices"
                [ngClass]="_bSpecific || device.isSelect ? '' : 'hidden'">
                <span class="text-info fas fa-arrow-alt-circle-up"></span>
                <span class="ml-2">{{ device?.virtualName }} :</span>
                <span>
                  {{ device?.currentSettings[constantSvc.DEVKEY_INFO_FW] }} &rarr;
                </span>
                <span>Specific URL</span>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-1">
      <i class="fas fa-arrow-alt-circle-up text-info"></i>
      <span class="ml-2">Update required</span>
      <i class="fas fa-minus-circle text-danger ml-2"></i>
      <span class="ml-2">No need update</span>
    </div>
  </div>
</div>