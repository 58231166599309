import { Component, Input } from "@angular/core";
import { AppStartScreensaverInfo } from "../../content/device/data/device-info";

@Component({
    selector: 'na-appstart-screensaver-preview',
    templateUrl: './appstart-screensaver-preview.component.html'
})
export class AppStartScreensaverPreviewComponent {
    _data: AppStartScreensaverInfo;
    @Input('data')
    set data(v: AppStartScreensaverInfo) {
        this._data = v;
        //we do not export 'enabled' property when set screensaver to device, 
        //that is, we may get raw screensaver data without 'enabled' property on UI.
        if (this._data.enabled === undefined) {
            this._data.enabled = this._data.data ? true : false;
        }
    }
}