import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { CustomPipeModule } from '../../../lib/pipes/custom-pipe.module';
import { TranslateModule } from '../../../translate/translate.module';
import { UIElementModule } from '../../../uiElement/uiElement.module';
import { SCEPOverviewComponent } from './scep-overview.component';
import { SCEPDetailWrapperComponent } from './scep-detail-wrapper.component';
import { ScepService } from './scep.service';
import { SCEPServerDetailComponent } from './comp/scep-server-detail.component';
import { SCEPAuthComponent } from './comp/scep-auth.component';
import { ScepAddDlgComponent } from './form/scep-dlg-add.component';
import { ScepFuncService } from './form/scep-func.service';
import { ScepFuncDirective } from './form/scep-func.directive';
import { ScepRemoveDlgComponent } from './form/scep-dlg-remove.component';
import { ScepRenewDlgComponent } from './form/scep-dlg-renew.component';
import { ScepDevicePickupComponent } from './comp/scep-dev-pickup.component';
import { ScepAddDeviceDlgComponent } from './form/scep-dlg-addDevice.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        CustomPipeModule,
        TranslateModule,
        UIElementModule
    ],
    providers: [
        ScepService,
        ScepFuncService
    ],
    declarations: [
        SCEPOverviewComponent,
        SCEPDetailWrapperComponent,
        SCEPServerDetailComponent,
        SCEPAuthComponent,
        ScepDevicePickupComponent,
        ScepFuncDirective,
        ScepAddDlgComponent,
        ScepRemoveDlgComponent,
        ScepRenewDlgComponent,
        ScepAddDeviceDlgComponent
    ],
    exports: []
})
export class SCEPModule { }