import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from './translate.service';

@Pipe({
    name: 'translate',
    pure: false
})
export class TranslatePipe implements PipeTransform {

    constructor(private _translateSvc: TranslateService) {}

    transform(value: any, args: string | string[]): any {
        if (!value) {
            return;
        }

        return this._translateSvc.instant(value, args);
    }

}
