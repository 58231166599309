import { Injectable, Inject, EventEmitter } from '@angular/core';
import { TRANSLATIONS_TOKEN } from './translations';

export class LanguageItem {
    displayName: string;
    id: string;
}

@Injectable()
export class TranslateService {
    readonly LANGUAGES: LanguageItem[] = [
        {
            displayName: 'English',
            id: 'en'
        },
        {
            displayName: '繁體中文',
            id: 'zh-tw'
        }
    ]

    private _defaultLang: string = 'en';
    private _currentLang: string;
    private _fallback: boolean;

    private PLACEHOLDER = '%';

    public onLangChanged: EventEmitter<string> = new EventEmitter<string>();

    public get currentLang() {
        return this._currentLang || this._defaultLang;
    }

    public getLanguage(langId?: string): LanguageItem {
        return this.LANGUAGES.find(langItem => langId ? langItem.id === langId : langItem.id === this._defaultLang);
    }

    public setDefaultLanguage(langId: string): LanguageItem {
        this._defaultLang = langId;
        return this.LANGUAGES.find(langItem => langItem.id === langId);
    }

    public enableFallback(enable: boolean): void {
        this._fallback = enable;
    }

    constructor( @Inject(TRANSLATIONS_TOKEN) private _translations: any) {
        this._currentLang = this._defaultLang;
    }

    public useLanguage(lang: string): void {
        this._currentLang = lang;

        this.onLangChanged.emit(lang);
    }

    private translate(key: string): string {
        let translation = key;

        if (this._translations[this._currentLang] && this._translations[this._currentLang][key]) {
            return this._translations[this._currentLang][key];
        }

        if (!this._fallback) {
            return translation;
        }

        if (this._translations[this._defaultLang] && this._translations[this._defaultLang][key]) {
            return this._translations[this._defaultLang][key];
        }

        return translation;
    }

    public instant(key: string, words?: string | string[]): string {
        const translation: string = this.translate(key);

        if (!words) {
            return translation;
        }

        return this.replace(translation, words);
    }

    public replace(word: string = '', words: string | string[] = '') {
        let translation: string = word;

        const values: string[] = [].concat(words);
        values.forEach((v, i) => {
            translation = translation.replace(this.PLACEHOLDER.concat(<any>i), v);
        });

        return translation;
    }
}
