import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { LoginRoutingModule } from './entry-routing.module';
import { EntryWrapperComponent } from './entry-wrapper.component';
import { SignUpComponent } from './signup/signup.component';
import { SignUpActivationComponent } from './signup/signup-activation.component';
import { PasswordForgetComponent } from './pwd/password-forget.component';
import { PasswordResetComponent } from './pwd/password-reset.component';
import { AuthGuard } from './auth-guard.service';
import { AccountService } from './account.service';
import { TranslateModule } from '../translate/translate.module';
import { CustomPipeModule } from '../lib/pipes/custom-pipe.module';
import { LoginModule } from './login/login.module';
import { EnterpriseApplyFormComponent } from './register/enterprise-apply.component';
import { CustomDirectiveModule } from '../directive/custom-directive.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        LoginRoutingModule,

        TranslateModule,
        CustomPipeModule,
        CustomDirectiveModule,

        LoginModule
    ],
    declarations: [
        EntryWrapperComponent,
        SignUpComponent,
        SignUpActivationComponent,
        PasswordForgetComponent,
        PasswordResetComponent,
        EnterpriseApplyFormComponent
    ],
    providers: [
        AuthGuard,
        AccountService
    ]
})
export class EntryModule {}