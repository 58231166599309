import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../../api.base';
import { IScepInfo } from './api.ent.scep.data';

export interface IEnterpriseListScepRxData {
    skip: number;
    limit: number;
    total: number;
    itemList: IScepInfo[]
}

export class APIEnterpriseListScepManager extends APIBaseManager<void, void, void, IEnterpriseListScepRxData> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_ListScep';
        this._apiMethod = 'GET';
    }

    protected getRequestURL(pathParameters: void, queryParameters: void): string {
        return super.getRequestURL() + 'enterprises/scepSettings';
    }
}