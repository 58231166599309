import { Component, OnDestroy, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { ScepChallengeInfo } from '../scep.data';

@Component({
    selector: 'na-scep-auth',
    templateUrl: './scep-auth.component.html',
    styleUrls: ['../scep.style.css']
})
export class SCEPAuthComponent implements OnDestroy {
    readonly EAP_IDENTITY_LIST: string[] = ['MAC', 'Device ID'];

    _challengeInfo: ScepChallengeInfo = new ScepChallengeInfo();
    private _allUnsubscribe: Subject<void> = new Subject();

    private _colPwdRef: ElementRef;
    @ViewChild('colPwd', { static: false })
    set colPwd(v: ElementRef) {
        if (v) {
            this._colPwdRef = v;
            const ele = this._colPwdRef.nativeElement;
            const ob = fromEvent(ele, 'input');

            ob.pipe(
                debounceTime(200),
                takeUntil(this._allUnsubscribe)
            ).subscribe((e: any) => {
                this._challengeInfo.challengePassword = e.target.value;
                this.onChallengeChanged.emit(this._challengeInfo);
            });
        }
    }

    private _colEapDomainRef: ElementRef;
    @ViewChild('colEAPDomain', { static: false })
    set colEAPDomain(v: ElementRef) {
        if (v) {
            this._colEapDomainRef = v;
            const ele = this._colEapDomainRef.nativeElement;
            const ob = fromEvent(ele, 'input');

            ob.pipe(
                debounceTime(200),
                takeUntil(this._allUnsubscribe)
            ).subscribe((e: any) => {
                this._challengeInfo.eapDomain = e.target.value;
                this.onChallengeChanged.emit(this._challengeInfo);
            });
        }
    }

    @Output() onChallengeChanged = new EventEmitter<ScepChallengeInfo>();

    constructor() {}

    ngOnDestroy(): void {
        this._allUnsubscribe.next();
        this._allUnsubscribe.complete();
    }

    changeEAPIdentity(identity: string): void {
        if (this._challengeInfo.eapIdentity !== identity) {
            this._challengeInfo.eapIdentity = identity;
            this.onChallengeChanged.emit(this._challengeInfo);
        }
    }
}