import { HttpClient } from '@angular/common/http';

import { APIWrapperBase } from '../../../api.wrapper.base';
import { APIAssignPolicyManager } from './api.policy.assign';
import { APICreatePolicyManager } from './api.policy.create';
import { APIListPolicyManager } from './api.policy.list';
import { APIListPolicyDeviceManager } from './api.policy.listDevice';
import { APIListPolicyGroupManager } from './api.policy.listGroup';
import { APIRemovePolicyManager } from './api.policy.remove';
import { APIUpdatePolicyManager } from './api.policy.update';

export class APIPolicyWrapper extends APIWrapperBase {
    CreatePolicy: APICreatePolicyManager;
    ListPolicy: APIListPolicyManager;
    ListPolicyGroup: APIListPolicyGroupManager;
    ListPolicyDevice: APIListPolicyDeviceManager;
    UpdatePolicy: APIUpdatePolicyManager;
    RemovePolicy: APIRemovePolicyManager;
    AssignPolicy: APIAssignPolicyManager;

    constructor(private http: HttpClient) {
        super();

        this.CreatePolicy = new APICreatePolicyManager(http);
        this.ListPolicy = new APIListPolicyManager(http);
        this.ListPolicyGroup = new APIListPolicyGroupManager(http);
        this.ListPolicyDevice = new APIListPolicyDeviceManager(http);
        this.UpdatePolicy = new APIUpdatePolicyManager(http);
        this.RemovePolicy = new APIRemovePolicyManager(http);
        this.AssignPolicy = new APIAssignPolicyManager(http);
    }

    public resetServerConfig(protocol: string, baseUrl: string, port: number): void {
        let targets = [
            this.CreatePolicy,
            this.ListPolicy,
            this.ListPolicyGroup,
            this.ListPolicyDevice,
            this.UpdatePolicy,
            this.RemovePolicy,
            this.AssignPolicy
        ];

        targets.forEach(t => t.resetServerConfig(protocol, baseUrl, port));
    }
}