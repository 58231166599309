import { Component } from '@angular/core';
import { IUserDlgFuncContent } from '../user-dlg-func.def';
import { UserInfo } from '../../user.data';

@Component({
    templateUrl: './user-dlg-remove-content.component.html',
    styleUrls: ['../../user.style.css']
})
export class UserRemoveDlgContentComponent implements IUserDlgFuncContent<UserInfo[], void> {
    data: UserInfo[];
    funcName: string;
    other?: void;
    onActionPrepared: () => void;
    onActionValidChanged: (valid: boolean) => void;
}