<div>
    <div class="container-fluid scroll scroll-xs px-0 pr-md-2">
        <div class="form-group row">
            <label for="col-policy-report-name" class="col-6 col-md-3 col-xl-2 col-form-label">
                Policy name
            </label>
            <div class="col-6 col-md-9 col-xl-10">
                <div id="col-policy-report-name" class="form-control-plaintext">
                    {{ _policy.name }}
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label for="col-policy-report-type" class="col-6 col-md-3 col-xl-2 col-form-label">
                Policy type
            </label>
            <div class="col-6 col-md-9 col-xl-10">
                <div id="col-policy-report-type" class="form-control-plaintext">
                    {{ _policy.type }}
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label for="col-policy-report-setting" class="col-12 col-form-label">
                Setting
            </label>
            <div class="col-12">
                <ng-template policy-type></ng-template>
            </div>
        </div>
        <div class="form-group row">
            <label for="col-policy-report-group" class="col-12 col-form-label">
                Apply to
            </label>
            <div class="col-12">
                <div class="list-block p-1 bg-white">
                    <div *ngFor="let gd of _policy.groupList" class="mr-2">
                        <i *ngIf="gd.type === _enumDevGroupType.device" class="fas fa-inbox green-3 mr-1"></i>
                        {{ gd.name }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>