export class AppManagementSplitInfo {
    id: number;
    name: string;
    type: string;
    link: string;
    checksum?: string;
    checksumAlgorithm?: string;
    mimeType?: string;
}

export class AppManagementInfo {
    static APP_ID: number = 1;
    static APP_SPLIT_ID: number = 1;

    name: string;
    id: string;
    apkLink: string;
    packageName: string;
    className?: string;
    action?: string;

    versionCode?: string;
    versionName?: string;
    checksum?: string;
    checksumAlgorithm: string;

    bindToAppstart: boolean;
    allowDowngrade: boolean;

    splits: AppManagementSplitInfo[] = [];

    constructor(rawData?: {
        id?: string,
        name: string,
        version?: string,
        components?: {
            base: {
                type: string;
                link: string;
                checksum?: string;
                checksumAlgorithm?: string;
                mimeType?: string;
                packageName?: string;
                className?: string;
                action?: string;
                versionCode?: string;
                versionName?: string;
                allowUninstall?: boolean;
                splits?: string[];
            },
            [splitKey: string]: {
                type: string;
                link: string;
                checksum?: string;
                checksumAlgorithm?: string;
                mimeType?: string;
                packageName?: string;
                className?: string;
                action?: string;
                versionCode?: string;
                versionName?: string;
                allowUninstall?: boolean;
                splits?: string[];
            }
        }
    }) {
        this.name = '';
        this.id = 'app-' + AppManagementInfo.APP_ID++;
        this.apkLink = '';
        this.packageName = '';
        this.className = '';
        this.action = '';
        this.versionCode = '';
        this.versionName = '';
        this.checksum = '';
        this.checksumAlgorithm = 'MD5';

        this.bindToAppstart = false;
        this.allowDowngrade = false;

        if (rawData) {
            this.id = rawData.id || this.id;
            this.name = rawData.name;
            if (rawData.components && rawData.components.base) {
                this.apkLink = rawData.components.base.link;
                this.packageName = rawData.components.base.packageName;
                this.className = rawData.components.base.className;
                this.action = rawData.components.base.action;
                this.versionCode = rawData.components.base.versionCode;
                this.versionName = rawData.components.base.versionName;
                this.checksum = rawData.components.base.checksum;
                this.checksumAlgorithm = rawData.components.base.checksumAlgorithm;
                this.allowDowngrade = rawData.components.base.allowUninstall;
                if (rawData.components.base.splits && rawData.components.base.splits.length > 0) {
                    this.splits = rawData.components.base.splits.map(splitName => ({
                        id: AppManagementInfo.APP_SPLIT_ID++,
                        name: splitName,
                        type: rawData.components[splitName]?.type,
                        link: rawData.components[splitName]?.link,
                        checksum: rawData.components[splitName]?.checksum,
                        checksumAlgorithm: rawData.components[splitName]?.checksumAlgorithm,
                        mimeType: rawData.components[splitName]?.mimeType
                    }));
                }
            }
        }
    }

    isValid(): boolean {
        if (!this.apkLink || !this.packageName) {
            return false;
        }

        return true;
    }

    addSplit(): AppManagementSplitInfo {
        this.splits = this.splits || [];
        const newSplit: AppManagementSplitInfo = {
            id: AppManagementInfo.APP_SPLIT_ID++,
            name: '',
            type: 'SPLIT_APK',
            link: '',
            checksum: '',
            checksumAlgorithm: 'MD5',
            mimeType: 'application/vnd.android.package-archive'
        }
        this.splits.push(newSplit);

        return newSplit;
    }

    removeSplit(splitID: number): void {
        const splitItem = this.splits.find(s => s.id === splitID);
        if (splitItem) {
            this.splits.splice(this.splits.indexOf(splitItem), 1);
        }
    }

    transformToRaw(): {
        name: string,
        version: string,
        components: {
            base: {
                type: string;
                link: string;
                checksum?: string;
                checksumAlgorithm?: string;
                mimeType?: string;
                packageName?: string;
                className?: string;
                action?: string;
                versionCode?: string;
                versionName?: string;
                allowUninstall?: boolean;
                splits?: string[];
            },
            [splitKey: string]: {
                type: string;
                link: string;
                checksum?: string;
                checksumAlgorithm?: string;
                mimeType?: string;
                packageName?: string;
                className?: string;
                action?: string;
                versionCode?: string;
                versionName?: string;
                allowUninstall?: boolean;
                splits?: string[];
            }
        }
    } {
        const appTo: any = {
            name: this.name,
            version: (this.versionName || '1.0.0.0') + '-' + Date.now(),
            components: {
                base: {
                    type: 'APK',
                    packageName: this.packageName,
                    className: this.className,
                    action: this.action,
                    checksum: this.checksum,
                    checksumAlgorithm: this.checksumAlgorithm,
                    link: this.apkLink,
                    versionCode: this.versionCode,
                    versionName: this.versionName,
                    allowUninstall: this.allowDowngrade,
                    mimeType: 'application/vnd.android.package-archive',
                    splits: this.splits.map(s => s.name)
                }
            }
        };

        if (this.splits.length > 0) {
            this.splits.forEach(splitItem => {
                if (splitItem.name) {
                    appTo.components[splitItem.name] = {
                        type: splitItem.type,
                        link: splitItem.link,
                        checksum: splitItem.checksum,
                        checksumAlgorithm: splitItem.checksumAlgorithm,
                        mimeType: splitItem.mimeType
                    }
                }
            });
        }

        return appTo;
    }

    copy(): AppManagementInfo {
        const s: AppManagementInfo = new AppManagementInfo();
        s.id = this.id;
        s.name = this.name;
        s.apkLink = this.apkLink;
        s.packageName = this.packageName;
        s.className = this.className;
        s.action = this.action;
        s.versionCode = this.versionCode;
        s.versionName = this.versionName;
        s.checksum = this.checksum;
        s.checksumAlgorithm = this.checksumAlgorithm;
        s.bindToAppstart = this.bindToAppstart;
        s.allowDowngrade = this.allowDowngrade;
        s.splits = this.splits.map(s => s);

        return s;
    }
}