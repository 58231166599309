import { Injectable } from '@angular/core';
import { UserDlgFuncItem, USER_DLG_FUNC_CHANGEGROUP_CONTENT, USER_DLG_FUNC_EDITCONFIRM_CONTENT, USER_DLG_FUNC_INVITE_BULK_CONTENT, USER_DLG_FUNC_INVITE_CONTENT, USER_DLG_FUNC_REMOVE_CONTENT, USER_DLG_FUNC_RESETPWD_CONTENT, USER_DLG_GROUP_FUNC_NEW_CONTENT, USER_DLG_GROUP_FUNC_REMOVE_CONTENT } from './user-dlg-func.def';
import { UserChangeGroupDlgContentComponent } from './user/user-dlg-changeGroup-content.component';
import { UserConfirmEditDlgContentComponent } from './user/user-dlg-editConfirm-content.component';
import { UserInviteDlgContentComponent } from './user/user-dlg-invite-content.component';
import { UserInviteBulkDlgContentComponent } from './user/user-dlg-inviteBulk-content.component';
import { UserRemoveDlgContentComponent } from './user/user-dlg-remove-content.component';
import { UserResetPwdDlgContentComponent } from './user/user-dlg-resetPwd-content.component';
import { UserGroupNewDlgContentComponent } from './group/user-group-dlg-new-content.component';
import { UserGroupRemoveDlgContentComponent } from './group/user-group-dlg-remove-content.component';

@Injectable()
export class UserDlgContentFuncService {
  private _items: UserDlgFuncItem[] = [];

  constructor() {
    this._items = [
      new UserDlgFuncItem(UserRemoveDlgContentComponent, USER_DLG_FUNC_REMOVE_CONTENT),
      new UserDlgFuncItem(UserInviteDlgContentComponent, USER_DLG_FUNC_INVITE_CONTENT),
      new UserDlgFuncItem(UserInviteBulkDlgContentComponent, USER_DLG_FUNC_INVITE_BULK_CONTENT),
      new UserDlgFuncItem(UserChangeGroupDlgContentComponent, USER_DLG_FUNC_CHANGEGROUP_CONTENT),
      new UserDlgFuncItem(UserResetPwdDlgContentComponent, USER_DLG_FUNC_RESETPWD_CONTENT),
      new UserDlgFuncItem(UserConfirmEditDlgContentComponent, USER_DLG_FUNC_EDITCONFIRM_CONTENT),

      new UserDlgFuncItem(UserGroupRemoveDlgContentComponent, USER_DLG_GROUP_FUNC_REMOVE_CONTENT),
      new UserDlgFuncItem(UserGroupNewDlgContentComponent, USER_DLG_GROUP_FUNC_NEW_CONTENT)
    ];
  }

  getItemByName(name: string): UserDlgFuncItem {
    return this._items.find(i => i.name === name);
  }
}
