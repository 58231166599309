<div class="group-tree-wrapper">
    <div *ngIf="_showTitle" class="d-flex align-items-center">
        <label>{{ _title }}</label>
        <div class="ml-auto">
            <div class="group-ctrls">
                <ng-template [ngIf]="_enableFuncUnfold">
                    <div class="icon" (click)="unfoldGroups()">
                        <i class="fas fa-folder" data-toggle="tooltip" title="Collapse group"></i>
                    </div>
                    <div class="icon" (click)="expandGroups()">
                        <i class="fas fa-folder-open" data-toggle="tooltip" title="Expand group"></i>
                    </div>
                </ng-template>
                <ng-template [ngIf]="supportGroupUpdate() && _groupMode === _enumGroupMode.edit">
                    <div data-toggle="modal" [attr.data-target]="'#groupFuncModalHost-' + _id" class="icon"
                        (click)="createGroup()">
                        <i class="fas fa-folder-plus" data-toggle="tooltip" title="Create group"></i>
                    </div>
                </ng-template>
                <div *ngIf="_enableCollapseFunc" class="icon" (click)="hideGroup()">
                    <i class="fas fa-times-circle" data-toggle="tooltip" title="Hide group list"></i>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="_allowChangeAccount" class="group-source-block mb-1">
        <button [id]="'dropdownGroupAccountLink-' + _id" type="button"
            class="btn btn-sm btn-action btn-light dropdown-toggle" data-toggle="dropdown" [disabled]="_loading">
            {{ _currentAccount?.accountName }}
        </button>
        <div class="dropdown-menu w-100" [attr.aria-labelledby]="'dropdownGroupAccountLink-' + _id">
            <div (click)="changeGroupOwnerAccount(_ownerAccount)" class="source">{{ _ownerAccount?.accountName }}</div>
            <ng-template [ngIf]="_sharedAccountList.length > 0">
                <div class="divider">--- Other accounts ---</div>
                <div *ngFor="let sharedAccount of _sharedAccountList" class="source"
                    (click)="changeGroupOwnerAccount(sharedAccount)">
                    {{ sharedAccount.accountName }}
                </div>
            </ng-template>
        </div>
    </div>
    <div *ngIf="_targetPolicyGroup" class="container-fluid policy-detail-block my-1 py-1">
        <div class="form-group row">
            <span for="col-policy" class="col-12">
                Policy of group <strong class="green-3">{{ _targetPolicyGroup.name }}</strong>
                <button type="button" class="close" (click)="closePolicyDetailView()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </span>
            <div class="col-12">
                <span *ngIf="_loadingPolicy; else templateShowPolicyName" class="text-loading"
                    [attr.data-text]="TEXT_LOADING">{{ TEXT_LOADING }}</span>
                <ng-template #templateShowPolicyName>
                    <div *ngFor="let item of _deviceGroupPolicyList" class="form-group row">
                        <label [for]="'col-policy-' + item.type" class="col-6 col-form-label col-form-label-sm">
                            {{ item.type }}
                        </label>
                        <div class="col-6">
                            <div [id]="'col-policy-' + item.type"
                                class="form-control-plaintext form-control-plaintext-sm">
                                <div *ngFor="let p of item.policyList">
                                    <a [routerLink]="'/app/device/policy/' + p.id" data-dismiss="modal">{{ p.name }}</a>
                                    <i class="fas fa-external-link-alt ml-2 blue-2"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
    <div class="group-tree-block" [style.border]="_styleBorder ? '1px solid var(--gray-5)' : 'none'"
        [style.height]="_styleH && !_enableCollapseFunc ? _styleH : 'auto'">
        <na-dev-group-tree [group]="_g" [mode]="_groupMode" [moveTarget]="_moveTarget" [showDevice]="_showDevice"
            [showPolicy]="_policyDisplayMode === 'embed'" [pickMap]="_pickMap"
            (onGroupMoveTargetChanged)="onMoveTargetChange($event)"
            (onGroupPolicyInspected)="onSubgroupPolicyInspect($event)" (onGroupSelected)="onGroupSelect($event)">
        </na-dev-group-tree>
    </div>
</div>
<div [id]="'groupFuncModalHost-' + _id" class="modal fade" data-backdrop="static" data-keyboard="false" role="dialog"
    tabindex="-1">
    <ng-template dev-group-func-host></ng-template>
</div>