import { Component, OnInit } from '@angular/core';
import { IPolicyTypeFunc } from './policy-type.service';
import { PolicyType, PolicyDataCertificate } from '../policy.data';
import { CertInfo } from 'app/content/admin/cert/cert.data';
import { HelperLib } from 'app/lib/common/helper.lib';

@Component({
    templateUrl: './policy-cert.component.html',
    styleUrls: ['../policy.style.css']
})
export class PolicyTypeCertificateComponent implements OnInit, IPolicyTypeFunc {
    onPolicyDataValidate?: (valid: boolean) => void;
    type: PolicyType;
    _data: PolicyDataCertificate;
    set data(d: PolicyDataCertificate) {
        this._data = d;
    }
    isInEdit: boolean;

    _usedCertMap: { [certID: string]: CertInfo } = {};
    _usedCerts: CertInfo[] = [];
    _selectedUsedCerts: CertInfo[] = [];
    _selectedAvailableCerts: CertInfo[] = [];

    ngOnInit(): void {
        if (this._data?.installedCertificates) {
            this._data.installedCertificates.forEach(cert => this._usedCertMap[cert.id] = cert);
            this._usedCerts = this._data.installedCertificates.map(cert => cert);
        }
    }

    onAppDataValidChange(valid: boolean): void {
        this.onPolicyDataValidate(valid);
    }

    addCertificate(): void {
        this._selectedAvailableCerts.forEach(cert => this._usedCertMap[cert.id] = cert);
        this.updateUsedCerts();
        this._selectedAvailableCerts = [];
    }

    releaseCertificate(): void {
        this._selectedUsedCerts.forEach(cert => delete this._usedCertMap[cert.id]);
        this.updateUsedCerts();
        this._selectedUsedCerts = [];
    }

    onUsedCertSelected(certs: CertInfo[]): void {
        this._selectedUsedCerts = certs;
    }

    onAvailableCertSelected(certs: CertInfo[]): void {
        this._selectedAvailableCerts = certs;
    }

    private updateUsedCerts(): void {
        this._usedCerts = HelperLib.mapToList<CertInfo>(this._usedCertMap);
        this._data.installedCertificates = this._usedCerts;
    }
}