import { Component, Input } from '@angular/core';

import { ITimezoneInfo } from '../../../../../app/lib/common/common.data';
import { ConstantService } from '../../../../../app/lib/common/constant.service';
import { DeviceInfo } from '../../data/device-info';
import { HelperLib } from '../../../../lib/common/helper.lib';

@Component({
    selector: 'device-info-general',
    templateUrl: './device-info-general.component.html',
    styleUrls: ['./device.style.css']
})
export class DeviceInfoGeneralComponent {
    _device: DeviceInfo;
    @Input('device')
    set device(d: DeviceInfo) {
        this._device = d;
        if (this._device.currentSettings[this.constantSvc.DEVKEY_TIME_TIMEZONE]) {
            const timezone_pieces: string[] = this._device.currentSettings[this.constantSvc.DEVKEY_TIME_TIMEZONE].split(',');
            
            if (timezone_pieces.length === 3) {
                this._timezoneOffset = timezone_pieces[0];
                
                if (!this._timezoneOffset && timezone_pieces[2]) {
                    if (this._device.availableOptions && this._device.availableOptions.Timezones) {
                        const match: ITimezoneInfo = this._device.availableOptions.Timezones.find(t => t.id === timezone_pieces[2]);
                        if (match) {
                            this._timezoneOffset = match.offset;
                        }
                    }
                }
 
                if (!this._timezoneOffset && timezone_pieces[2]) {
                    const match: ITimezoneInfo = HelperLib.getDefaultTimezoneList().find(t => t.id === timezone_pieces[2]);
                    if (match) {
                        this._timezoneOffset = match.offset;
                    }
                }

                if (!this._timezoneOffset) {
                    this._timezoneOffset = '';
                    this._timezoneFormat += ' Z';
                }
            }     
        }
    };

    _timezoneOffset: string = '';
    _timezoneFormat: string = 'yyyy-MM-dd HH:mm:ss';

    constructor(public constantSvc: ConstantService) { }
}