<label class="title">
    <span *ngIf="_showSwitch">{{ NAME | capitalFirstWord }}</span>
    <i *ngIf="_lockInfo" class="fas fa-lock ml-2 red-1" data-toggle="tooltip"
        [title]="'Lock by policy <<' + _lockInfo.policyName + '>>'"></i>
</label>
<div *ngIf="_unsupportReason" class="warning-block mb-1">{{ _unsupportReason }}</div>
<div [ngClass]="_showBorder? 'showBorder' : ''">
    <div *ngIf="_showSwitch" class='switch-container'>
        <input id="cmn-toggle-screensaver" class="cmn-toggle cmn-toggle-flat cmn-toggle-rect-flat" type="checkbox"
            [disabled]="_lockInfo || _disabled || _unsupportReason" [checked]="_info.enabled"
            (change)="enableScreensaver($event.target.checked)">
        <label for="cmn-toggle-screensaver">
            <span class="sr-only">Enable screen saver</span>
        </label>
    </div>
    <div class="mt-2">
        <div>
            Device goes screen saver after
            <div class="dropdown btn-group">
                <button id="dropdownScreensaverTimeoutLink" type="button"
                    class="btn btn-sm btn-action btn-light dropdown-toggle text-right"
                    [disabled]="_lockInfo || _disabled || _unsupportReason || !_info.enabled" data-toggle="dropdown">
                    {{ _info.timeout / 60 }}
                </button>
                <ul class="dropdown-menu option-list w-100" aria-labelledby="dropdownScreensaverTimeoutLink">
                    <li *ngFor="let timeout of IDLE_TIMEOUT_LIST" [class.active]="_info.timeout == timeout"
                        class="dropdown-item" (click)="changeTimeout(timeout)">
                        {{ timeout / 60 }}
                    </li>
                </ul>
            </div>
            minutes
        </div>
        <div>
            <label for="screensaver-url">Screen saver content URL</label>
            <div>
                <input #screensaverUrl id="screensaver-url" type="text" class="form-control form-control-sm"
                    aria-label="Screen saver content URL"
                    [disabled]="_lockInfo || _disabled || _unsupportReason || !_info.enabled" [value]="_info.url">
            </div>
        </div>
    </div>
    <div class="mt-2">
        Schedule
        <na-weekScheduleSelector [actionName]="'Lock screen from'" [data]="_info?.schedule"
            [disabled]="_lockInfo || _disabled || _unsupportReason || !_info?.enabled"
            (updated)="onScheduleChanged($event)">
        </na-weekScheduleSelector>
    </div>
</div>