<div *ngIf="_changeList.length > 0; else templateNoUserContentChange">
    <div>Are you sure you want to change the user setting ?</div>
    <div class="list-group mt-1">
        <div class="list-group-item list-group-header black">
            <div class="row">
                <div class="col-4 title text-break">
                    Field
                </div>
                <div class="col-4 title text-break">
                    Before change
                </div>
                <div class="col-4 title text-break">
                    After change
                </div>
            </div>
        </div>
        <div class="list-group-content list-group-hoverless">
            <ng-template ngFor let-m [ngForOf]="_changeList">
                <div class="list-group-item">
                    <div class="row">
                        <div class="col-4 text-break">
                            {{ m.field }}
                        </div>
                        <div class="col-4 text-break">
                            {{ m.before }}
                        </div>
                        <div class="col-4 text-break">
                            {{ m.after }}
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</div>
<ng-template #templateNoUserContentChange>
    No changes.
</ng-template>