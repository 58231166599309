import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../api.base';

export interface IRemoveShareDevicePathParameter {
    virtualDeviceID: string;
}

export class APIRemoveShareDeviceManager extends APIBaseManager<IRemoveShareDevicePathParameter, void, void, any> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_RemoveShareDevice';
        this._apiMethod = 'DELETE';
    }

    protected getRequestURL(pathParameters: IRemoveShareDevicePathParameter, queryParameters: void): string {
        return super.getRequestURL() + 'virtualDevices/' + pathParameters.virtualDeviceID + '/share';
    }
}