import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { UIElementModule } from '../../../uiElement/uiElement.module';
import { TranslateModule } from '../../../translate/translate.module';
import { CustomPipeModule } from '../../../lib/pipes/custom-pipe.module';
import { ActivityOverviewComponent } from './activity-overview.component';
import { ActivityService } from './activity.service';
import { ActivityDetailComponent } from './activity-detail.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,

        UIElementModule,
        TranslateModule,
        CustomPipeModule
    ],
    declarations: [
        ActivityOverviewComponent,
        ActivityDetailComponent
    ],
    providers: [
        DatePipe,
        ActivityService
    ],
    exports: [
        ActivityOverviewComponent,
        ActivityDetailComponent
    ]
})
export class ActivityModule { }