import { DatePipe } from "@angular/common";

export enum EventLogLevelCode {
    Info = 'Info',
    Warn = 'Warn',
    Error = 'Error'
}

export class EventLogInfo {
    id: string;
    category: string;
    dateTime: string;
    event: string;
    subject: string;
    level: string;
    toLink: boolean;

    constructor(id: string, dateTime: string, category: string, event: string, level: string, subject: string) {
        this.id = id;
        this.dateTime = dateTime;
        this.category = category;
        this.event = event;
        this.level = level;
        this.subject = subject;

        this.toLink = this.category === "Device task" && this.event !== "Reload License" && this.event !== "Active mode" ? true : false;
    }
}

export interface IEventLogBaseFilterData<T, G> {
    filterMessage: string;
    startFilter(): void;
    applyFilter(): G;
    getFilterData(): T;
}

export class EventLogSeverityFilterData implements IEventLogBaseFilterData<string[], { [levelName: string]: boolean }> {
    static SEVERITY_LEVEL_LIST: string[] = ['Info', 'Warn', 'Error'];

    private _applyList: { name: string, checked: boolean }[];
    private _tmpList: { name: string, checked: boolean }[];
    get tmpList(): { name: string, checked: boolean }[] {
        return this._tmpList;
    }
    filterMessage: string;

    constructor(lastPreference?: { [levelName: string]: boolean }) {
        this._applyList = EventLogSeverityFilterData.SEVERITY_LEVEL_LIST.map(s => {
            return { name: s, checked: lastPreference && lastPreference[s] !== undefined ? lastPreference[s] : true }
        });

        this._tmpList = this._applyList.map(c => { return { name: c.name, checked: c.checked } });
        this.updateFilterMessage();
    }

    startFilter(): void {
        this._tmpList = this._applyList.map(c => { return { name: c.name, checked: c.checked } });
    }

    changeSeverity(severity: { name: string, checked: boolean }, checked: boolean): void {
        severity.checked = checked;
    }

    applyFilter(): { [levelName: string]: boolean } {
        this._applyList = this.tmpList;
        this.updateFilterMessage();

        return this._applyList.reduce((acc, curr) => {
            acc[curr.name] = curr.checked;
            return acc;
        }, {});
    }

    private updateFilterMessage(): void {
        const selectSeverityList: { name: string, checked: boolean }[] = this._applyList.filter(s => s.checked);
        this.filterMessage = selectSeverityList.length === this._applyList.length ? 'All' : selectSeverityList.map(s => s.name).join(', ');
    }

    getFilterData(): string[] {
        return this._applyList.filter(s => s.checked).map(s => s.name);
    }
}

export class EventLogTimeFilterData implements IEventLogBaseFilterData<{ begin: string, end: string }, { beginDate: string, beginTime: string, endDate: string, endTime: string }> {
    readonly DATE_RANGE: number = 90;
    dateMaxLimit: string;
    dateMinLimit: string;

    private _applyData: { beginDate: string, beginTime: string, endDate: string, endTime: string };
    private _tmpData: { beginDate: string, beginTime: string, endDate: string, endTime: string };
    get tmpData(): { beginDate: string, beginTime: string, endDate: string, endTime: string } {
        return this._tmpData;
    }

    filterMessage: string;
    errorMessage: string;

    constructor(private datePipe: DatePipe, lastPreference?: { beginDate?: string, beginTime?: string, endDate?: string, endTime?: string }) {
        this.dateMaxLimit = this.getDateFormat();
        this.dateMinLimit = this.getDateFormat(-this.DATE_RANGE);

        this._applyData = {
            beginDate: lastPreference && lastPreference.beginDate ? lastPreference.beginDate : this.getDateFormat(-1),
            beginTime: lastPreference && lastPreference.beginTime ? lastPreference.beginTime : this.getTimeFormat(),
            endDate: lastPreference && lastPreference.endDate ? lastPreference.endDate : this.getDateFormat(),
            endTime: lastPreference && lastPreference.endTime ? lastPreference.endTime : this.getTimeFormat()
        };
        this._tmpData = {
            beginDate: this._applyData.beginDate,
            beginTime: this._applyData.beginTime,
            endDate: this._applyData.endDate,
            endTime: this._applyData.endTime
        };
        this.updateFilterMessage();
    }

    startFilter(): void {
        this._tmpData = {
            beginDate: this._applyData.beginDate,
            beginTime: this._applyData.beginTime,
            endDate: this._applyData.endDate,
            endTime: this._applyData.endTime
        };
    }

    applyFilter(): { beginDate: string, beginTime: string, endDate: string, endTime: string } {
        this._applyData = this._tmpData;
        this.updateFilterMessage();

        return this._applyData;
    }

    getFilterData(): { begin: string; end: string; } {
        return { begin: this._applyData.beginDate + ' ' + this._applyData.beginTime, end: this._applyData.endDate + ' ' + this._applyData.endTime };
    }

    changeBeginDate(date: string): void {
        this._tmpData.beginDate = date;
        this.checkTimeRangeLegality();
    }

    changeBeginTime(time: string): void {
        this._tmpData.beginTime = time;
        this.checkTimeRangeLegality();
    }

    changeEndDate(date: string): void {
        this._tmpData.endDate = date;
        this.checkTimeRangeLegality();
    }

    changeEndTime(time: string): void {
        this._tmpData.endTime = time;
        this.checkTimeRangeLegality();
    }

    private checkTimeRangeLegality(): void {
        this.errorMessage = null;
        if (this._tmpData.beginDate + this._tmpData.beginTime > this._tmpData.endDate + this._tmpData.endTime) {
            this.errorMessage = 'Time range is invalid';
        }
    }

    private getDateFormat(addDay: number = 0): string {
        const d: Date = new Date;
        return addDay === 0 ? this.datePipe.transform(d, 'yyyy-MM-dd') : this.datePipe.transform(d.setDate(d.getDate() + addDay), 'yyyy-MM-dd');
    }

    private getTimeFormat(): string {
        const d: Date = new Date;
        let h: string = this.datePipe.transform(d, 'HH');
        let m: string = '30';

        return h + ':' + m;
    }

    private updateFilterMessage(): void {
        this.filterMessage = this._applyData.beginDate + ' ' + this._applyData.beginTime + ' ~ ' + this._applyData.endDate + ' ' + this._applyData.endTime;
    }
}

export class EventLogEventFilterData implements IEventLogBaseFilterData<{ categoryList: string[], eventList: string[] }, { [categoryName: string]: { [eventName: string]: boolean } }> {
    filterMessage: string;
    private _applyDataMap: { [categoryName: string]: { [eventName: string]: boolean } } = {};
    tmpDataList: {
        categoryID: string;
        categoryName: string;
        checked: boolean;
        show: boolean;
        checkCount: number;
        eventList?: { eventName: string, checked: boolean }[];
        displayEventList?: { eventName: string, checked: boolean }[];
    }[] = [];

    constructor(eventTable: { [category: string]: { [event: string]: string } }, lastPreference?: { [categoryName: string]: { [eventName: string]: boolean } }) {
        this._applyDataMap = {};
        //get full category & event structure from server
        if (eventTable) {
            Object.keys(eventTable).forEach((categoryName: string) => {
                this._applyDataMap[categoryName] = {};

                Object.keys(eventTable[categoryName]).forEach((eventName: string) => {
                    this._applyDataMap[categoryName][eventName] = lastPreference && lastPreference[categoryName] && lastPreference[categoryName][eventName] !== undefined ? lastPreference[categoryName][eventName] : false;
                });

                //create tmp data
                this.initTmpItem(categoryName);
            });

            this.tmpDataList.sort((a, b) => a.categoryName > b.categoryName ? 1 : -1);
            this.updateSelectEventAmount();
        }
    }

    private initTmpItem(categoryName: string): void {
        const tmp: {
            categoryID: string;
            categoryName: string;
            checked: boolean;
            show: boolean;
            checkCount;
            eventList?: { eventName: string, checked: boolean }[];
            displayEventList?: { eventName: string, checked: boolean }[];
        } = {
            categoryID: categoryName.replace(/ /g, ''),
            categoryName: categoryName,
            checked: false,
            show: true,
            checkCount: 0
        };

        const eventNameList: string[] = Object.keys(this._applyDataMap[categoryName]);
        tmp.checkCount = eventNameList.filter(en => this._applyDataMap[categoryName][en] === true).length;
        tmp.checked = tmp.checkCount === eventNameList.length ? true : false;
        tmp.eventList = eventNameList.map(en => { return { eventName: en, checked: this._applyDataMap[categoryName][en] } })
        tmp.displayEventList = tmp.eventList;

        this.tmpDataList.push(tmp);
    }

    startFilter(): void {
        this.tmpDataList = [];

        Object.keys(this._applyDataMap).forEach((categoryName: string) => {
            this.initTmpItem(categoryName);
        });

        this.tmpDataList.sort((a, b) => a.categoryName > b.categoryName ? 1 : -1);
    }

    applyFilter(): { [categoryName: string]: { [eventName: string]: boolean } } {
        this.tmpDataList.forEach(t => {
            t.eventList.forEach(e => {
                this._applyDataMap[t.categoryName][e.eventName] = e.checked;
            });
        });
        this.updateSelectEventAmount();

        return this._applyDataMap;
    }

    getFilterData(): { categoryList: string[]; eventList: string[]; } {
        const categoryList: string[] = [];
        const eventList: string[] = [];

        this.tmpDataList.forEach(c => {
            if (c.checked) {
                categoryList.push(c.categoryName);
            }
            else {
                c.eventList.forEach(e => {
                    if (e.checked) {
                        eventList.push(e.eventName);
                    }
                });
            }
        });

        return { categoryList: categoryList, eventList: eventList };
    }

    selectAllCategoryAndEvents(checked: boolean): void {
        this.tmpDataList.forEach(e => {
            e.checked = checked;
            e.eventList.forEach(eventItem => eventItem.checked = checked);
        });
    }

    selectCategory(category: {
        categoryID: string;
        categoryName: string;
        checked: boolean;
        show: boolean;
        eventList: { eventName: string, checked: boolean }[];
        displayEventList?: { eventName: string, checked: boolean }[];
    }, checked: boolean): void {
        category.checked = checked;
        category.eventList.forEach(eventItem => eventItem.checked = checked);
    }

    selectEvent(category: {
        categoryID: string;
        categoryName: string;
        checked: boolean;
        show: boolean;
        eventList: { eventName: string, checked: boolean }[];
        displayEventList?: { eventName: string, checked: boolean }[];
    }, event: { eventName: string, checked: boolean }, checked: boolean): void {
        event.checked = checked;
        if (!event.checked) {
            category.checked = false;
        }
        else {
            let allEventItemChecked: boolean = true;
            for (const e of category.eventList) {
                if (!e.checked) {
                    allEventItemChecked = false;
                    break;
                }
            }

            category.checked = allEventItemChecked;
        }
    }

    search(keyword: string): void {
        const t: string = keyword ? keyword.toLocaleLowerCase() : '';
        if (t.startsWith('cat:')) {
            const category_name: string = t.substring(4).trim();
            this.tmpDataList.forEach(i => {
                i.show = i.categoryName.toLocaleLowerCase().indexOf(category_name) >= 0 ? true : false;
                if (i.show) {
                    i.displayEventList = i.eventList;
                }
            });
        }
        else {
            this.tmpDataList.forEach(i => {
                i.show = true;
                i.displayEventList = t ? i.eventList.filter(eventItem => eventItem.eventName.toLocaleLowerCase().indexOf(t) >= 0) : i.eventList;
            });
        }
    }

    private updateSelectEventAmount(): void {
        let count: number = 0;
        this.tmpDataList.forEach(c => {
            count += c.checked ? c.eventList.length : c.eventList.filter(e => e.checked).length;
        });

        this.filterMessage = count === 0 ? null : count + ' events selected';
    }
}