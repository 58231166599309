import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../api.base';

export interface IGetEventLogPathParameter {
    eventID: string;
}

export interface IGetEventLogRxData {
    logID: string;
    logDate: string;
    logLevel: {
        level: number,
        code: string
    },
    logIssuer: {
        accountID: string;
        accountName?: string;
    },
    logOwner: {
        accountID: string;
        accountName?: string;
        virtualDeviceID?: string;
    },
    logCategory: string;
    logEvent: string;
    logSubject: string;
    logData: any;
    logDateTime: string;
}

export class APIGetEventLogManager extends APIBaseManager<IGetEventLogPathParameter, void, void, IGetEventLogRxData> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_GetEventLog';
        this._apiMethod = 'GET';
    }

    protected getRequestURL(pathParameters: IGetEventLogPathParameter, queryParameters: void): string {
        return super.getRequestURL() + 'eventLogs/' + pathParameters.eventID;
    }
}