import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../../api.base';

export interface IExtendRemoteCtrlPathParameter {
    virtualDeviceID: string;
    virtualDevicePairedID: string;
}

export interface IExtendRemoteCtrlTxData {
    room: string;
    keepAliveToken: string;
}

export interface IExtendRemoteCtrlRxData {
    room: string;
    duration: number;
    keepAliveToken: string;
}

export class APIExtendRemoteCtrlManager extends APIBaseManager<IExtendRemoteCtrlPathParameter, void, IExtendRemoteCtrlTxData, IExtendRemoteCtrlRxData> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_ExtendRemoteCtrl';
        this._apiMethod = 'PATCH';
    }

    protected getRequestURL(pathParameters: IExtendRemoteCtrlPathParameter, queryParameters: void): string {
        return `${super.getRequestURL()}virtualDevices/${pathParameters.virtualDeviceID}/${pathParameters.virtualDevicePairedID}/remoteControl`;
    }
}