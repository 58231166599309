<div *ngIf="!_isViewMode; else templateViewMode" class="row">
    <div class="col-12">
        <div class="form-group">
            <label>Description</label>
            <div class="desc">
                Send an alert email when account got lock up after <strong class="red-1">5</strong> failed attempts to
                login within 1 hour.
            </div>
        </div>
        <div class="form-group">
            <label for="alert-receiver">Deliver to</label>
            <div>
                <textarea id="alert-receiver" class="form-control" rows="6"
                    placeholder="Enter valid email(s) here by spliting with comma or 'Enter'..." [ngModel]="_receivers"
                    (ngModelChange)="changeReceivers($event)">
                </textarea>
                <span class="note text-muted ml-2">(split by comma or 'Enter')</span>
            </div>
        </div>
    </div>
</div>
<ng-template #templateViewMode>
    <div>
        <!-- deliver to -->
        <div class="form-group row">
            <label for="col-alert-receiver" class="col-12">
                Alert receiver
            </label>
            <div class="col-12">
                <div id="col-alert-receiver" class="alert-list-block">
                    {{transformReceiverList(_data.alertReceiverList)}}
                </div>
            </div>
        </div>
    </div>
</ng-template>