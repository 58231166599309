<div>
  <ng-template [ngIf]="!_bSpecific">
    <div class="message mb-2">
      Different device may belong to different firmware family. Please select update method for each device family if
      there are multiple tabs.
    </div>
    <ul class="nav nav-tabs">
      <li *ngFor="let familyInfo of _firmwareFamilyInfos" class="nav-item"
        [ngClass]="_firmwareLegalityMap[familyInfo.familyId] === true ? 'valid' : ''">
        <a class="nav-link" [ngClass]="_familyID === familyInfo.familyId ? 'active' : ''" data-toggle="tab" role="tab"
          href="#firmware-family-{{ familyInfo.familyId }}" (click)="changeFirmwareFamily(familyInfo.familyId)">
          <div>{{ familyInfo.familyDisplayName }}</div>
          <div class="note">(Android {{ familyInfo.targetAndroidVersion }})</div>
        </a>
      </li>
    </ul>
    <div class="tab-content pt-1">
      <div *ngFor="let familyInfo of _firmwareFamilyInfos" id="firmware-family-{{ familyInfo.familyId }}"
        class="container-fluid">
        <firmware-family-content *ngIf='_familyID === familyInfo.familyId' [familyInfo]="familyInfo"
          [specific]="_bSpecific" class="tab-pane fade show active" role="tabpanel"
          [ngClass]="_familyID === familyInfo.familyId ? 'active' : ''"
          (onValid)="updateFamilyLegality(familyInfo, $event)">
        </firmware-family-content>
      </div>
    </div>
  </ng-template>
  <ng-template [ngIf]="_bSpecific">
    <div *ngFor="let familyInfo of _firmwareFamilyInfos" id="firmware-family-{{ familyInfo.familyId }}"
      class="container-fluid">
      <firmware-family-content *ngIf='_familyID === familyInfo.familyId' [familyInfo]="familyInfo"
        [specific]="_bSpecific" class="tab-pane fade show active" role="tabpanel"
        [ngClass]="_familyID === familyInfo.familyId ? 'active' : ''"
        (onValid)="updateFamilyLegality(familyInfo, $event)">
      </firmware-family-content>
    </div>
  </ng-template>
  <div *ngIf="_schedule" class="mb-4">
    <div class="d-flex align-items-center">
      <input type="checkbox" id="firmware-schedule" class="cmn-checkbox" [checked]="_schedule.enabled"
        (change)="enableFirmwareSchedule($event.target.checked)">
      <label for="firmware-schedule">
        <svg>
          <use xlink:href="#checkmark" />
        </svg>
        <span class="sr-only">Schedule update in future time</span>
      </label>
      <strong class="ml-2">Schedule update in future time</strong>
    </div>
    <div class="d-flex align-items-center flex-wrap ml-3 mt-1">
      <span>Update on</span>
      <input type="date" class="ml-2" [value]="_schedule.date" [disabled]="!_schedule.enabled"
        aria-label="Firmware schedule date" (change)="changeFirmwareScheduleDate($event.target.value)">
      <span class="ml-2">starting at</span>
      <div class="btn-group ml-2">
        <button id="dropdownFirmwareScheduleTimeLink" type="button"
          class="btn btn-sm btn-action btn-light dropdown-toggle" [disabled]="!_schedule.enabled"
          data-toggle="dropdown">
          {{ _schedule.time }}
          <span class="sr-only">modify firmware update time</span>
        </button>
        <ul class="dropdown-menu dropdown-menu-left" aria-labelledby="dropdownFirmwareScheduleTimeLink"
          style="min-width:300px">
          <div class="px-2">
            <na-timePicker [time]="_schedule.time" [minuteIncre]="5" [showBorder]="false" [showSelectionTime]="false"
              (onTimeChanged)="changeFirmwareScheduleTime($event)">
            </na-timePicker>
          </div>
        </ul>
      </div>
      <div class="btn-group ml-2">
        <button id="dropdownFirmwareScheduleOffsetLink" type="button"
          class="btn btn-sm btn-action btn-light dropdown-toggle" [disabled]="!_schedule.enabled"
          data-toggle="dropdown">
          GMT <ng-template [ngIf]="_schedule.offset >= 0">+</ng-template>{{ _schedule.offset }}:00
          <span class="sr-only">modify firmware update time offset</span>
        </button>
        <ul class="dropdown-menu dropdown-menu-left option-list" aria-labelledby="dropdownFirmwareScheduleOffsetLink">
          <li *ngFor="let o of GMTLIST" (click)="changeFirmwareScheduleTimeOffset(o)">
            GMT <ng-template [ngIf]="o >= 0">+</ng-template>{{ o }}:00
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>