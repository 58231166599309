import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { DevFuncDirective } from './dev-func.directive';
import { DevFuncService } from './dev-func.service';
import { FirmwareService } from './firmware/firmware.service';

import { UIElementModule } from '../../uiElement/uiElement.module';

import { BasicConfigFormComponent } from './config/basic-config-form.component';
import { NetConfigFormComponent } from './config/net-config-form.component';

import { BaseConfigFormComponent } from './base/base-config-form.component';
import { IssueFormComponent } from './troubleshoot/issue-form.component';
import { RebootFormComponent } from './reboot/reboot-form.component';
import { FirmwareUpdateFormComponent } from './firmware/firmware-update-form.component';
import { AlertFormComponent } from './alert/alert-form.component';
import { ShareFormComponent } from './share/share-form.component';
import { APKFormComponent } from './apk/apk-form.component';

import { APKService } from './apk/apk.service';

import { TranslateModule } from '../../translate/translate.module';
import { CustomPipeModule } from '../../lib/pipes/custom-pipe.module';

import { DevFuncActionModule } from './base/action/dev-func-action.module';
import { DevFuncConfirmModule } from './base/confirm/dev-func-confirm.module';
import { MultiBasicConfigFormComponent } from './config-m/m-basic-config-form.component';
import { SecurityFormComponent } from './security/security-form.component';
import { ClearCacheFormComponent } from './cache/clearCache-form.component';
import { OTPFormComponent } from './otp/otp-form.component';

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        RouterModule,
        TranslateModule,
        CustomPipeModule,
        UIElementModule,
        DevFuncActionModule,
        DevFuncConfirmModule
    ],
    providers: [
        DevFuncService,
        FirmwareService,
        APKService
    ],
    declarations: [
        DevFuncDirective,
        RebootFormComponent,
        IssueFormComponent,
        BasicConfigFormComponent,
        NetConfigFormComponent,
        BaseConfigFormComponent,
        MultiBasicConfigFormComponent,
        FirmwareUpdateFormComponent,
        AlertFormComponent,
        ShareFormComponent,
        APKFormComponent,
        SecurityFormComponent,
        ClearCacheFormComponent,
        OTPFormComponent
    ],
    exports: [
        DevFuncDirective
    ]
})
export class DevFuncModule {
}