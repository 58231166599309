import { Component } from '@angular/core';

import { DialogPage } from '../../../../../lib/common/common.data';
import { UserBaseDlgComponent } from '../user-dlg-base.component';
import { USER_DLG_FUNC_INVITE_CONTENT } from '../user-dlg-func.def';
import { UserInfo } from '../../user.data';
import { concatMap, map } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
    templateUrl: '../user-dlg-base.component.html',
    styleUrls: ['../../user.style.css']
})
export class UserInviteDlgComponent extends UserBaseDlgComponent<UserInfo[], { user?: UserInfo, domain?: string, message?: string, isFormValid?: boolean }> {

    private _isReinvite: boolean = false;

    ngOnInit(): void {
        this._isReinvite = this.data && this.data.length > 0 && this.data[0].id ? true : false;
        this._action_txt = this._isReinvite ? 'Invite again' : 'Invite';
        this._action_icon = 'fa-envelope-open';
        this._content_func_name = USER_DLG_FUNC_INVITE_CONTENT;

        this._page = DialogPage.action;

        this.other = {
            user: this.data && this.data.length > 0 ? this.data[0] : new UserInfo(),
            domain: '',
            message: ''
        };

        super.ngOnInit();
    }

    submit(): void {
        this._page = DialogPage.submit;

        this.userSvc.isEnterpriseLicenseSufficient(this.other.user.userRole).pipe(
            concatMap((sufficient: boolean) => {
                this.other.user.email = this.other.user.email.replace(/@$/, '');
                if (!this._isReinvite) {
                    this.other.user.email += '@' + this.other.domain;
                }

                if (sufficient || this._isReinvite) {
                    return this.userSvc.inviteUser(this.other.user, this.other.message).pipe(
                        map((res: { user: UserInfo, isFault: boolean, errorMessage?: string }) => {
                            this._errorMessage = res.errorMessage;
                            this._page = DialogPage.result;

                            return true;
                        })
                    );
                }

                //show alert message
                alert(this.userSvc.USER_ROLE_EXCEED_ALERT);
                this._page = DialogPage.action;

                return of(true);
            })
        ).subscribe();
    }
}