import { Component, Input, OnInit } from "@angular/core";
import { ConstantService } from "../../lib/common/constant.service";
import { PowerSaveInfo } from "./power-save.data";

@Component({
    selector: 'na-powersave-preview',
    templateUrl: './power-save-preview.component.html',
    styleUrls: []
})
export class PowerSavePreviewComponent implements OnInit {
    _info: PowerSaveInfo = new PowerSaveInfo();

    _keyPair: { [name: string]: { value: any } };
    @Input('keyPair')
    set keyPair(v: { [name: string]: { value: any } }) {
        this._keyPair = v;
    }

    _target: string;
    @Input('target')
    set target(v: string) {
        this._target = v;
    }

    constructor(private constantSvc: ConstantService) { }

    ngOnInit(): void {
        if (this._keyPair && this._target) {
            this._info.enabled = !this._keyPair[this.constantSvc.DEVKEY_APPSETTING_CONSOLE_DISABLE_POWERSAVE][this._target];
            this._info.timeout = this._keyPair[this.constantSvc.DEVKEY_APPSETTING_CONSOLE_POWERSAVE_TIMEOUT][this._target];
        }
    }
}