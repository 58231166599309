import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../../api.base';
import { IAlertSetting } from '../api.alert.common';

export interface ICreateAlertTxData {
    alertEventID: string;
    settingName: string;
    settingData: any;
}

export class APICreateAlertManager extends APIBaseManager<void, void, ICreateAlertTxData, IAlertSetting> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_CreateAlert';
        this._apiMethod = 'POST';
    }

    protected getRequestURL(pathParameters: void, queryParameters: void): string {
        return super.getRequestURL() + 'accounts/alertSettings';
    }
}