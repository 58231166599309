import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { ConstantService } from "app/lib/common/constant.service";
import { IUIElement } from "app/uiElement/uiElement.interface";
import { fromEvent, Subject } from "rxjs";
import { debounceTime, takeUntil } from "rxjs/operators";
import { WeekScheduleInfo } from "../basic/weekScheduleSelector.data";
import { ScreenSaverInfo } from "./screen.data";

@Component({
    selector: 'na-screenSaver',
    templateUrl: './screenSaver.component.html',
    styleUrls: ['../../uiElement.style.css', './screenSaver.component.css']
})
export class ScreenSaverComponent implements OnInit, IUIElement {
    readonly NAME: string = 'screen saver';
    readonly IDLE_TIMEOUT_LIST: number[] = this.constantSvc.SCREEN_SAVER_TIMEOUT_LIST;
    private _unsubscriber: Subject<void> = new Subject();

    _info: ScreenSaverInfo = new ScreenSaverInfo();
    @Input('data')
    set data(s: ScreenSaverInfo) {
        if (s) {
            this._info = s;
        }
    }

    _disabled: boolean = false;
    @Input('disabled')
    set disabled(s: boolean) {
        this._disabled = s;
        this._info.enabled = !s;
    }

    _unsupportReason: string;
    @Input('unsupportReason')
    set unsupportReason(s: string) {
        this._unsupportReason = s;
    }

    _lockInfo: { isSync: boolean, policyID: string, policyName: string };
    @Input('lock')
    set lock(s: { isSync: boolean, policyID: string, policyName: string }) {
        this._lockInfo = s;
    }

    _showSwitch: boolean = true;
    @Input('showSwitch')
    set showSwitch(s: boolean) {
        this._showSwitch = s;
    }

    _showBorder: boolean = false;
    @Input('showBorder')
    set showBorder(v: boolean) {
        this._showBorder = v;
    }

    private _screensaverUrlRef: ElementRef;
    @ViewChild('screensaverUrl')
    set screensaverUrl(h: ElementRef) {
        this._screensaverUrlRef = h;
        const eOb = fromEvent(this._screensaverUrlRef.nativeElement, 'input');

        eOb.pipe(
            debounceTime(200),
            takeUntil(this._unsubscriber)
        ).subscribe((e: any) => {
            this._info.url = e.target.value;
            this.notify();
        });
    }

    @Output() onDataChanged = new EventEmitter<ScreenSaverInfo>();

    constructor(private constantSvc: ConstantService) {}

    ngOnInit(): void {
        this.notify();
    }

    enableScreensaver(checked: boolean): void {
        if (this._info.enabled !== checked) {
            this._info.enabled = checked;
            this.notify();
        }
    }

    changeTimeout(timeout: number): void {
        if (this._info.timeout != timeout) {
            this._info.timeout = timeout;
            this.notify();
        };
    }

    onScheduleChanged(scheudule: WeekScheduleInfo): void {
        this._info.schedule = scheudule;
        this.notify();
    }

    private notify(): void {
        this.onDataChanged.emit(this._info);
    }
}