import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../../api.base';
import { IAlertSetting } from '../api.alert.common';

export interface IGetAlertPathParameter {
    alertSettingID: string;
}

export class APIGetAlertManager extends APIBaseManager<IGetAlertPathParameter, void, void, IAlertSetting> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_GetAlert';
        this._apiMethod = 'GET';
    }

    protected getRequestURL(pathParameters: IGetAlertPathParameter, queryParameters: void): string {
        return super.getRequestURL() + 'accounts/alertSettings/' + pathParameters.alertSettingID;
    }
}