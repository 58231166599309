<div *ngIf="_alert; else templateNoAlert" class="container-fluid alert-report">
  <!-- name -->
  <div class="form-group row layout-desktop">
    <label for="col-alert-name" class="col-lg-7 col-6 col-form-label-sm">
      Alert name
    </label>
    <div class="col-lg-5 col-6">
      <div id="col-alert-name" class="form-control-plaintext">
        {{ _alert?.name }}
      </div>
    </div>
  </div>
  <!-- event -->
  <div class="form-group row">
    <label for="col-alert-event" class="col-lg-7 col-6 col-form-label-sm">
      Tracking event
    </label>
    <div class="col-lg-5 col-6">
      <div id="col-alert-event" class="form-control-plaintext">
        {{ _alert?.eventType?.displayName }}
      </div>
    </div>
  </div>
  <!-- dynamically change based on different event type -->
  <div>
    <ng-template alert-data-ctrl></ng-template>
  </div>
</div>
<ng-template #templateNoAlert>
  <div class="text-center my-4 p-2">
    Choose a alert to inspect its detail content.
  </div>
</ng-template>