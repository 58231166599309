<div class='modal-dialog config-dlg'>
    <div class='modal-content'>
        <div class='modal-header'>
            <span class="modal-title">{{ title }}</span>
            <button #dlgClose type="button" class="close" data-dismiss="modal">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class='modal-body'>
            <div *ngIf="_page === _enumPage.prepare">
                <ul class="pl-0">
                    <li *ngIf="_devices.length === 0" class="text-danger">
                        Please select a device.
                    </li>
                    <li *ngIf="_shareForbiddenDevices?.length > 0">
                        Cannot use
                        <q>{{ title }}</q> function because these devices with shared access do not have sufficient
                        permission :
                        <label class="text-danger">{{ _shareForbiddenDevices | mergeDeviceNamePipe }}</label>
                    </li>
                </ul>
            </div>
            <div *ngIf="_page === _enumPage.action" class="container-fluid px-1">
                <div class="list-group mt-3">
                    <div class="list-group-item list-group-header black">
                        <div class="row">
                            <span class="col-6 title">Device name</span>
                            <span class="col-6 title text-break">License code</span>
                        </div>
                    </div>
                    <div class="list-group-content list-group-hoverless list-group-content-modal">
                        <div *ngFor="let device of _legalDevices" class="list-group-item list-group-item-action">
                            <div class="row align-items-center">
                                <span class="col-lg-6 col-12 text-break">
                                    {{ device.virtualName }}
                                </span>
                                <span class="col-lg-6 col-12 mt-sm-0 mt-1">
                                    <input type="text" class="form-control form-control-sm"
                                        [(ngModel)]="_devLicenseKeyMap[device.virtualId].raw"
                                        aria-label="Input license code">
                                    <div *ngIf="_devLicenseKeyMap[device.virtualId].formatError"
                                        class="warning-block m-0">
                                        {{ _devLicenseKeyMap[device.virtualId].formatError }}
                                    </div>
                                    <div *ngIf="_devLicenseKeyMap[device.virtualId].duplicateError"
                                        class="warning-block m-0">
                                        {{ _devLicenseKeyMap[device.virtualId].duplicateError }}
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="note text-muted">separate your license code by comma</div>
            </div>
            <div *ngIf="_page === _enumPage.confirm" class="container-fluid px-1 layout-desk">
                <div>
                    Click 'Apply' to confirm your changes, or click 'Prev' to modify again.
                </div>
                <div class="list-group mT-1">
                    <div class="list-group-item list-group-header black">
                        <div class="row">
                            <span class="col-lg-6 col-12 title">Device name</span>
                            <span class="col-lg-6 col-12 text-break title">Applied license(s)</span>
                        </div>
                    </div>
                    <div class="list-group-content list-group-content-modal-nr-1">
                        <div *ngFor="let device of _legalDevices" class="list-group-item list-group-item-action">
                            <div class="row align-items-center">
                                <span class="col-lg-6 col-12 text-break">
                                    {{ device.virtualName }}
                                </span>
                                <span class="col-lg-6 col-12 mt-sm-0 mt-1">
                                    <ul class="mb-1 p-0">
                                        <li *ngFor="let licenseKey of _devLicenseKeyMap[device.virtualId].keys">
                                            {{ licenseKey }}
                                        </li>
                                    </ul>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="_page === _enumPage.submit" class="container-fluid px-1">
                <div class="progress">
                    <div class="progress-bar progress-bar-success progress-bar-striped active" role="progressbar"
                        aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%">
                        Updating the changes... Please wait...
                    </div>
                </div>
            </div>
            <div *ngIf="_page === _enumPage.result" class="container-fluid px-1">
                <p *ngIf="_devLicenseImportErrorList.length === 0">New settings have been applied successfully.</p>
                <div *ngIf="_devLicenseImportErrorList.length > 0">
                    Some settings are failed.
                    <div *ngFor="let errorItem of _devLicenseImportErrorList" class="my-1 p-1 border">
                        <div>
                            <span>Device :</span>
                            <span>
                                {{ errorItem.device?.virtualName }}
                            </span>
                        </div>
                        <strong class="text-danger">Error : </strong>
                        <div class="text-danger ml-2">
                            <div *ngFor="let errorLicenseItem of errorItem.errorMessageList">
                                {{ errorLicenseItem.licenseKey ? errorLicenseItem.licenseKey + ' : ' : '' }} {{ errorLicenseItem.errorMessage }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class='row control-block d-flex justify-content-center'>
                <button *ngIf="allowGoBack()" type="button" class="btn btn-sm btn-action btn-light" (click)="goBack()">
                    <span class="fas fa-angle-double-left icon-left"></span>
                    {{ 'key-prev' | translate | capitalFirstWord }}
                </button>
                <button *ngIf="allowCancel()" type="button" class="btn btn-sm btn-action btn-light"
                    data-dismiss="modal">
                    <span class="fas fa-times-circle icon-left"></span>
                    {{ 'key-cancel' | translate | capitalFirstWord }}
                </button>
                <button *ngIf="allowGoNext()" type="button" class="btn btn-sm btn-action btn-light" (click)="goNext()">
                    {{ 'key-next' | translate | capitalFirstWord }}
                    <span class="fas fa-angle-double-right icon-right"></span>
                </button>
                <button *ngIf="allowSubmit()" type="button" class="btn btn-sm btn-action btn-light" (click)="goNext();">
                    <span class="fas fa-check-circle icon-left"></span>
                    {{ 'key-apply' | translate | capitalFirstWord }}
                </button>
                <button *ngIf="allowClose()" type="button" class="btn btn-sm btn-action btn-light" data-dismiss="modal">
                    <span class="fas fa-times-circle icon-left"></span>
                    {{ 'key-close' | translate | capitalFirstWord }}
                </button>
            </div>
        </div>
    </div>
</div>