import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../../api.base';
import { EnterpriseAccountGroupBasicInfo } from './api.ent.data';

export interface IRemoveEnterpriseAccountGroupPathParameter {
    accountGroupID: string;
}

export class APIRemoveEnterpriseAccountGroupManager extends APIBaseManager<IRemoveEnterpriseAccountGroupPathParameter, void, void, { [groupID: string]: EnterpriseAccountGroupBasicInfo }> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_RemoveEnterpriseAccountGroup';
        this._apiMethod = 'DELETE';
    }

    protected getRequestURL(pathParameters: IRemoveEnterpriseAccountGroupPathParameter, queryParameters: void): string {
        return super.getRequestURL() + 'enterprises/accountGroups/' + pathParameters.accountGroupID;
    }
}