<div class="list-group mt-1">
    <div class="list-group-item list-group-header black">
        <div class="row">
            <div class="col-6 title text-break">
                Permissions
            </div>
            <div *ngFor="let role of _userRoleList" class="col-2 title text-break text-center">
                {{ role | capitalFirstWord }}
            </div>
        </div>
    </div>
    <div class="list-group-content list-group-hoverless">
        <div *ngFor="let s of _userScopeList" class="list-group-item">
            <div *ngFor="let sub of s.subScopeList" class="row">
                <div class="col-6 text-break">
                    {{ s.prefix }} {{ s.prefix ? '-' : '' }} {{ sub.permission }}
                </div>
                <div *ngFor="let role of _userRoleList" class="col-2 text-center">
                    <span *ngIf="sub.role[role]; else templateScopeNotApply"
                        class="fas fa-check-circle green-3"></span>
                    <ng-template #templateScopeNotApply>
                        <span class="fas fa-times-circle red-1"></span>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</div>