import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../../api.base';
import { PolicyRawInfo } from './api.policy.common';

export class APIListPolicyManager extends APIBaseManager<void, void, void, { [accountName: string]: { [policyID: string]: PolicyRawInfo } }> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_ListPolicy';
        this._apiMethod = 'GET';
    }

    protected getRequestURL(pathParameters: void, queryParameters: void): string {
        return super.getRequestURL() + 'devicePolicies';
    }
}