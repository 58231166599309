<div class='modal-dialog config-dlg'>
    <div class='modal-content'>
        <div class='modal-header'>
            <span class="modal-title">{{ title }}</span>
            <button #dlgClose type="button" class="close" [disabled]="_updating" data-dismiss="modal">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class='modal-body'>
            <div class="row">
                <div class="mb-2 mb-md-0" [ngClass]="_allowChooseTargetGroup ? 'col-12 col-md-6' : 'col-12'">
                    <label>
                        Move selected groups / devices
                        <ng-template [ngIf]="!_fromGroup"> under <strong><{{ group?.name }}></strong>
                        </ng-template>
                        <ng-template [ngIf]="!_allowChooseTargetGroup">
                            to <<strong>{{ _targetGroup?.name }}</strong>>
                        </ng-template>
                    </label>
                    <div class="border py-1 px-2">
                        <div *ngFor="let sg of _moveList">
                            <i *ngIf="sg.type === _enumGroupType.device" class="fas fa-inbox green-3"></i>
                            <i *ngIf="sg.type === _enumGroupType.group" class="fas fa-folder"></i>
                            {{ sg.name }}
                        </div>
                        <div *ngIf="_moveList.length === 0" class="red-1">
                            Please select at least a device or a group from the list in last page.
                        </div>
                    </div>
                </div>
                <div *ngIf="_allowChooseTargetGroup" class="col-md-6 col-12">
                    <na-dev-group-tree-wrapper [group]="_groupRoot" [mode]="_mode" [showTitle]="true"
                        [title]="'To <' + (_targetGroup?.name ? _targetGroup?.name : '') + '>'" [styleBorder]="true"
                        [enableFuncUnfold]="true" (onGroupMoveTargetChanged)="onTargetGroupChange($event)">
                    </na-dev-group-tree-wrapper>
                </div>
            </div>
            <div *ngIf="_errorMessage" class="mt-2 warning-block">{{ _errorMessage }}</div>
            <div *ngIf="_updating" class="progress mt-2">
                <div class="progress-bar progress-bar-success progress-bar-striped active" role="progressbar"
                    aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%">
                    Updating the changes... Please wait...
                </div>
            </div>
            <div class="mt-2">
                *** only groups/devices and their decendants selected under current active group could be moved.
            </div>
            <div class='row control-block d-flex justify-content-center'>
                <button type="button" class="btn btn-sm btn-action btn-light" [disabled]="_updating"
                    data-dismiss="modal">
                    <span class="fas fa-times-circle icon-left"></span>
                    {{ 'key-cancel' | translate | capitalFirstWord }}
                </button>
                <button type="button" class="btn btn-sm btn-action btn-light"
                    [disabled]="_updating || _moveList.length === 0 || !_targetGroup" (click)="moveGroup()">
                    <span class="fas fa-check-circle icon-left"></span>
                    {{ 'key-apply' | translate | capitalFirstWord }}
                </button>
            </div>
        </div>
    </div>
</div>