<!-- group on/off -->
<div class="group-ctrl">
  <span>Group :</span>
  <div class='switch-container ml-2'>
    <input id="id_home_do_turnGroupOn" class="cmn-toggle cmn-toggle-flat cmn-toggle-rect-flat" type="checkbox"
      [disabled]="_loading" [checked]="_groupSwitch === _enumGroupSwitch.on" (change)="turnOnOffGroupView()">
    <label for="id_home_do_turnGroupOn" class="sr-only">
      Turn on group view
    </label>
  </div>
</div>
<div class="container-fluid">
  <div class="row">
    <div *ngIf="_groupSwitch === _enumGroupSwitch.on" class="col-12 col-lg-3 px-0">
      <!-- group trees -->
      <div class="group-tree" [class.d-none]="!_showGroupTree">
        <div class="group-tree-body">
          <na-dev-group-tree-wrapper [showTitle]="true" [title]="'Device groups :'" [policyMode]="'dlg'"
            [allowChangeAccount]="true" [styleH]="'calc(100vh - 180px)'" [styleBorder]="!_groupCtrlAllowHide"
            [enableCollapseFunc]="_groupCtrlAllowHide" [enableFuncUnfold]="true"
            (onGroupTreeDisplayChanged)="_showGroupTree = $event"
            (onGroupFuncTrigger)="playGroupFunc($event.funcName, $event.group)"
            (onGroupOwnerAccountChanged)="onDeviceGroupAccountChanged($event)"
            (onGroupSelected)="onDeviceGroupInspect($event)">
          </na-dev-group-tree-wrapper>
        </div>
      </div>
    </div>
    <div class="px-0" [ngClass]="_groupSwitch === _enumGroupSwitch.on ? 'col-12 col-lg-9 pl-lg-2' : 'col-12'">
      <div *ngIf="_groupSwitch === _enumGroupSwitch.on" class="d-flex">
        <!-- group routes -->
        <div class="d-lg-none">
          <i class="fas fa-chevron-circle-down mr-2" data-toggle="tooltip" title="Show group"
            (click)="_showGroupTree = !_showGroupTree"></i>
        </div>
        <div *ngIf="_groupRouteList.length > 0" class="group-routes">
          <div class="d-flex">
            <div *ngFor="let g of _groupRouteList" class="group-route-item" (click)="inspectGroup(g, true)">
              {{ g.name }}
            </div>
          </div>
        </div>
      </div>
      <div class="group-content">
        <div *ngIf="_selectedDeviceGroup && _groupSwitch === _enumGroupSwitch.on" class="mb-2">
          <na-dev-group-detail [group]="_selectedDeviceGroup"></na-dev-group-detail>
          <div class="block-titlebar mt-2">
            <strong class="txt-bold">Device list</strong>
            <div class="ml-auto">
              <button type="button" class="btn btn-sm btn-action btn-light btn-nb btn-edit" data-toggle="modal"
                data-target="#groupFuncModalHost" [disabled]="_selectedDeviceCount + _selectedGroupCount === 0"
                (click)="changeDeviceGroup()">
                <i class="fas fa-exchange-alt mr-1"></i>
                Change group
              </button>
              <button *ngIf="!groupSvc.isDefaultGroup(_selectedDeviceGroup)" type="button"
                class="btn btn-sm btn-action btn-light btn-nb btn-edit" data-toggle="modal"
                data-target="#groupFuncModalHost" [disabled]="_selectedDeviceCount + _selectedGroupCount === 0"
                (click)="removeDeviceFromGroup(_selectedDeviceGroup)">
                <i class="fas fa-trash-alt mr-1"></i>
                Remove from group
              </button>
            </div>
          </div>
        </div>
        <div>
          <na-dev-pickup-view (onGroupInspect)="onDeviceGroupInspect($event)"
            (onItemSelectCountChange)="onItemSelectionChange($event)">
          </na-dev-pickup-view>
        </div>
      </div>
    </div>
  </div>
</div>
<div id="groupFuncModalHost" class="modal fade" data-backdrop="static" data-keyboard="false" role="dialog"
  tabindex="-1">
  <ng-template dev-group-func-host></ng-template>
</div>