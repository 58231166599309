import { Component, Input } from '@angular/core';

import { ILicenseInfo } from '../../../../../app/API/v1/License/api.license.common';

@Component({
    selector: 'device-info-license',
    templateUrl: './device-info-license.component.html',
    styleUrls: ['./device-info-license.component.css']
})
export class DeviceInfoLicenseComponent {
    _licenseInfo: {
        iCareLicense: {
            currentActivated: string[];
            detail: {
                [licenseType: string]: {
                    expireDate: string;
                    isCurrentActivated: boolean;
                    isExpired: boolean;
                    licenseKeyList: ILicenseInfo[]
                }
            };
            scope: string[];
            expireDate: string;
            isExpired: boolean;
        }, cloudLicense: {
            currentActivated: string[];
            detail: {
                [licenseType: string]: {
                    expireDate: string;
                    isCurrentActivated: boolean;
                    isExpired: boolean;
                    licenseKeyList: ILicenseInfo[]
                }
            };
            scope: string[];
            expireDate: string;
            isExpired: boolean;
        }
    };

    @Input('license')
    set license(license) {
        this._licenseInfo = license;
    }
}