import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AlertDataCtrlDirective } from './alert-data-ctrl.directive';
import { AlertDataCtrlService } from './alert-data-ctrl.service';

import { TranslateModule } from '../../../../../translate/translate.module';
import { CustomPipeModule } from '../../../../../lib/pipes/custom-pipe.module';

import { AlertOfflineDataComponent } from './comp/alert-data-offline.component';
import { AlertAccountAccessDataComponent } from './comp/alert-data-account-access.component';
import { AlertDeviceCommandDataComponent } from './comp/alert-data-device-cmd.component';
import { AlertDeviceStatusDataComponent } from './comp/alert-data-device-status.component';
import { UIElementModule } from '../../../../../uiElement/uiElement.module';


@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        UIElementModule,
        TranslateModule,
        CustomPipeModule
    ],
    providers: [
        AlertDataCtrlService
    ],
    declarations: [
        AlertDataCtrlDirective,
        AlertOfflineDataComponent,
        AlertAccountAccessDataComponent,
        AlertDeviceCommandDataComponent,
        AlertDeviceStatusDataComponent
    ],
    exports: [
        AlertDataCtrlDirective
    ]
})
export class AlertDataCtrlModule {
}