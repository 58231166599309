import { Pipe, PipeTransform } from '@angular/core';

import { DeviceInfo } from '../../content/device/data/device-info';

@Pipe({
    name: 'mergeContentPipe'
})
export class MergeContentsPipe implements PipeTransform {
    transform(contents: string[], ...args: string[]): string {
        if (!contents) {
            return '';
        }

        let delimiter: string = '';
        if (args && args.length > 0) {
            args.forEach((arg: string) => {
                delimiter += arg; 
            });
        }
        else {
            delimiter = ',';
        }

        delimiter += ' ';
        
        return contents.join(delimiter);
    }
}