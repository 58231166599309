<div *ngIf="_info">
    <div>
        <span class="text-info">Enabled :</span>
        <span class="ml-2">{{ _info.enabled }}</span>
    </div>
    <ng-template [ngIf]="_info?.enabled">
        <div>
            <span class="text-info">Mode :</span>
            <span class="ml-2">{{ _info?.lockScreenName }}</span>
        </div>
        <div>
            <span [ngSwitch]="_info.lockScreenMode">
                <!-- user sign up and wait for admin to send invitation mail -->
                <ng-template [ngSwitchCase]="_enumLockScreenMode.screenOff">
                    <na-screenOff-preview [data]="_info.data" [showSwitch]="false" (onDataChanged)="onLockScreenSettingChanged($event)"></na-screenOff-preview>
                </ng-template>
                <!-- admin send invitation mail and wait for user to accept -->
                <ng-template [ngSwitchCase]="_enumLockScreenMode.screenSaver">
                    <na-screenSaver-preview [data]="_info.data" [showSwitch]="false" (onDataChanged)="onLockScreenSettingChanged($event)"></na-screenSaver-preview>
                </ng-template>
            </span>
        </div>
    </ng-template>
</div>