
export class UserGroupInfo {
    static USER_GROUP_ID: number = 1;
    
    id: string;
    name: string;
    appliedDeviceGroupIDList?: string[];
    isDefault: boolean;
    autoAssignDeviceGroup: boolean;

    constructor(id: string, name: string, isDefault: boolean = false, deviceGroupIDList: string[] = [], autoAssignDeviceGroup: boolean = false) {
        this.id = id;
        this.name = name;
        this.isDefault = isDefault;
        this.autoAssignDeviceGroup = false;
        this.appliedDeviceGroupIDList = deviceGroupIDList;
        this.autoAssignDeviceGroup = autoAssignDeviceGroup;
    }
}