import { Injectable } from '@angular/core';

import { LicenseAddFormComponent } from './license-add-form.component';
import { LicenseReAllocateFormComponent } from './license-reallocate-form.component';
import { LicenseImportFormComponent } from './license-import-form.component';
import { LicenseFuncItem, FUNCNAME_LICENSE_ADD, FUNCNAME_LICENSE_EXPLANATION, FUNCNAME_LICENSE_REALLOCATE, FUNCNAME_LICENSE_IMPORT } from './license-func.def';

@Injectable()
export class LicenseFuncService {
  private _funcs: LicenseFuncItem[] = [];
  get Funcs(): LicenseFuncItem[] {
      return this._funcs;
  }

  constructor() {
    this._funcs = [
      new LicenseFuncItem(LicenseAddFormComponent, FUNCNAME_LICENSE_ADD, 'Add License'),
      new LicenseFuncItem(LicenseImportFormComponent, FUNCNAME_LICENSE_IMPORT, 'Import License'),
      new LicenseFuncItem(LicenseReAllocateFormComponent, FUNCNAME_LICENSE_REALLOCATE, 'Reallocate License'),
    ];
  }

  getFunctionByName(name: string): LicenseFuncItem {
    return this._funcs.find(i => i.name === name);
  }
}