<div>
  <div>Following are the setting(s) to apply :
    <span *ngIf="!_config.hasChangeItems()" class="highlight ml-2">(nothing to apply)</span>
  </div>
  <div class="list-group mt-1">
    <div class="list-group-item list-group-header black">
      <div class="row">
        <div class="col-2 title text-break">
          Setting
        </div>
        <div class="col-5 title text-break">
          Last value
        </div>
        <div class="col-5 title text-break">
          New value
        </div>
      </div>
    </div>
    <div class="list-group-content list-group-hoverless">
      <ng-template ngFor let-config [ngForOf]="_config.getAllConfigurableItems()">
        <div *ngIf="config.isChanged()" class="list-group-item">
          <div class="row">
            <div class="col-md-2 col-12 d-flex align-items-center text-break">
              {{ config.titleLangKey | translate | capitalFirstWord }}
            </div>
            <div class="col-md-5 col-12">
              <div [ngSwitch]="config.category">
                <ng-template [ngSwitchCase]="_enumConfigCategory.LockScreen">
                  <na-lockScreen-preview [data]="config.settingMap[constantSvc.DEVKEY_FAKE_LOCKSCREEN].origin">
                  </na-lockScreen-preview>
                </ng-template>
                <ng-template [ngSwitchCase]="_enumConfigCategory.Volume">
                  <na-volume-preview [volume]="config.settingMap[constantSvc.DEVKEY_HD_AUDIO_MASTER_SOUND_INDEX].origin"
                    [volumeMax]="config.settingMap[constantSvc.DEVKEY_HD_AUDIO_MASTER_SOUND_MAXINDEX].origin">
                  </na-volume-preview>
                </ng-template>
                <ng-template [ngSwitchCase]="_enumConfigCategory.MaintenancePlayback">
                  <na-mtPlayback-preview [data]="config.settingMap[constantSvc.DEVKEY_FAKE_MAINTENANCE].origin">
                  </na-mtPlayback-preview>
                </ng-template>
                <ng-template [ngSwitchCase]="_enumConfigCategory.Timeserver">
                  <na-timeserver-preview
                    [enabled]="config.settingMap[constantSvc.DEVKEY_TIME_TIMESERVER_ENABLED].origin"
                    [source]="config.settingMap[constantSvc.DEVKEY_TIME_TIMESERVER_SOURCE].origin">
                  </na-timeserver-preview>
                </ng-template>
                <ng-template [ngSwitchCase]="_enumConfigCategory.DailyReboot">
                  <na-dailyReboot-preview [config]="config.settingMap" [target]="'origin'"></na-dailyReboot-preview>
                </ng-template>
                <ng-template [ngSwitchCase]="_enumConfigCategory.AppStart">
                  <na-appstart-preview [keyPair]="config.settingMap" [target]="'origin'"></na-appstart-preview>
                </ng-template>
                <ng-template [ngSwitchCase]="_enumConfigCategory.OverlayQRCode">
                  <na-overlay-qrcode-preview [data]="config.settingMap[constantSvc.DEVKEY_APPSTART_OVERLAY].origin">
                  </na-overlay-qrcode-preview>
                </ng-template>
                <ng-template [ngSwitchCase]="_enumConfigCategory.Powersave">
                  <na-powersave-preview [keyPair]="config.settingMap" [target]="'origin'"></na-powersave-preview>
                </ng-template>
                <ng-template [ngSwitchCase]="_enumConfigCategory.ADB">
                  <na-debug-preview [debugUSB]="config.settingMap[constantSvc.DEVKEY_DEBUG_ADB_ENABLED].origin"
                    [debugTCP]="config.settingMap[constantSvc.DEVKEY_DEBUG_ADB_TCP_ENABLED].origin"></na-debug-preview>
                </ng-template>
                <div *ngSwitchDefault>
                  <span>{{ getConfigValue(config, true) }}</span>
                </div>
              </div>
            </div>
            <div class="col-md-5 col-12">
              <div [ngSwitch]="config.category">
                <ng-template [ngSwitchCase]="_enumConfigCategory.LockScreen">
                  <na-lockScreen-preview [data]="config.settingMap[constantSvc.DEVKEY_FAKE_LOCKSCREEN].value">
                  </na-lockScreen-preview>
                </ng-template>
                <ng-template [ngSwitchCase]="_enumConfigCategory.Volume">
                  <na-volume-preview [volume]="config.settingMap[constantSvc.DEVKEY_HD_AUDIO_MASTER_SOUND_INDEX].value"
                    [volumeMax]="config.settingMap[constantSvc.DEVKEY_HD_AUDIO_MASTER_SOUND_MAXINDEX].value">
                  </na-volume-preview>
                </ng-template>
                <ng-template [ngSwitchCase]="_enumConfigCategory.MaintenancePlayback">
                  <na-mtPlayback-preview [data]="config.settingMap[constantSvc.DEVKEY_FAKE_MAINTENANCE].value">
                  </na-mtPlayback-preview>
                </ng-template>
                <ng-template [ngSwitchCase]="_enumConfigCategory.Timeserver">
                  <na-timeserver-preview [enabled]="config.settingMap[constantSvc.DEVKEY_TIME_TIMESERVER_ENABLED].value"
                    [source]="config.settingMap[constantSvc.DEVKEY_TIME_TIMESERVER_SOURCE].value">
                  </na-timeserver-preview>
                </ng-template>
                <ng-template [ngSwitchCase]="_enumConfigCategory.DailyReboot">
                  <na-dailyReboot-preview [config]="config.settingMap" [target]="'value'"></na-dailyReboot-preview>
                </ng-template>
                <ng-template [ngSwitchCase]="_enumConfigCategory.AppStart">
                  <na-appstart-preview [keyPair]="config.settingMap" [target]="'value'"></na-appstart-preview>
                </ng-template>
                <ng-template [ngSwitchCase]="_enumConfigCategory.OverlayQRCode">
                  <na-overlay-qrcode-preview [data]="config.settingMap[constantSvc.DEVKEY_APPSTART_OVERLAY].value">
                  </na-overlay-qrcode-preview>
                </ng-template>
                <ng-template [ngSwitchCase]="_enumConfigCategory.Powersave">
                  <na-powersave-preview [keyPair]="config.settingMap" [target]="'value'"></na-powersave-preview>
                </ng-template>
                <ng-template [ngSwitchCase]="_enumConfigCategory.ADB">
                  <na-debug-preview [debugUSB]="config.settingMap[constantSvc.DEVKEY_DEBUG_ADB_ENABLED].value"
                    [debugTCP]="config.settingMap[constantSvc.DEVKEY_DEBUG_ADB_TCP_ENABLED].value"></na-debug-preview>
                </ng-template>
                <div *ngSwitchDefault>
                  <span>{{ getConfigValue(config) }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>