import { Component } from '@angular/core';
import { Observable, of as observableOf } from 'rxjs';
import { concatAll, map } from 'rxjs/operators';

import { BaseConfigFormComponent } from '../base/base-config-form.component';
import { DIALOG_NAME_SHARE, ConfigDialogPage } from '../base/base-config-data';
import { DeviceInfo } from '../../device/data/device-info';
import { LicenseScopeType } from '../../license/license.data';
import { IAPIRx } from '../../../API/api.base';
import { PolicyType } from '../../../../app/content/setting/policy/policy.data';
import { concatMap } from 'rxjs/operators';
import { IVirtualDeviceRxData } from '../../../API/v1/VirtualDevice/virtualDevice.common';

@Component({
    templateUrl: '../base/base-config-form.component.html',
    styleUrls: ['../base/base-config-form.component.css', './share-form.component.css']
})
export class ShareFormComponent extends BaseConfigFormComponent {
    ngOnInit(): void {
        this._dialogName = DIALOG_NAME_SHARE;
        this._licenseScopeType = LicenseScopeType.sharePlayer;
        this._sharePermissionType = 'Share';
        this._policyLockType = PolicyType.Configuration;

        super.ngOnInit();
    }

    protected afterInitProcess(): void {
        if (this._legalDevices.length > 0) {
            if (this._devices.length === 1 && this._shareForbiddenDevices.length === 0) {
                this.goNext();
            }
        }
    }

    protected goNext(fromPage?: ConfigDialogPage): void {
        if (this._page === ConfigDialogPage.checking) {
            //If only one device is selected and it is not a shared device,
            //user could remove existed share settings on this device even it has no share license. 
            if (this._devices.length === 1) {
                if (this._shareForbiddenDevices.length === 0) {
                    this._actionData.devices = this._devices;
                    this._actionData.hasLicense = this._licenseForbiddenDevices.length === 1 ? false : true;
                }
            }
            else {
                this._actionData.devices = this._legalDevices.filter(d => d.isSelect || this._bSpecific);
                if (this._actionData.devices.length === 1) {
                    this._actionData.hasLicense = this._licenseForbiddenDevices.find(d => d.virtualId === (this._actionData.devices[0] as DeviceInfo).virtualId) ? false : true;
                }
                else {
                    this._actionData.hasLicense = true;
                }
            }

            this._actionData.commonShareInfoMap = {};
            this._actionData.isValid = true;
            this._actionData.confirmShareInfoMap = {};
        }

        super.goNext();
    }

    allowGoNext(): boolean {
        if (this._page === ConfigDialogPage.action && !this._actionData.isValid) {
            return false;
        }

        return super.allowGoNext();
    }

    allowGoBack(): boolean {
        if (this._devices.length === 1 && this._page === ConfigDialogPage.action) {
            return false;
        }

        return super.allowGoBack();
    }

    protected goBack(fromPage?: ConfigDialogPage): void {
        if (this.allowGoBack) {
            super.goBack();
        }
    }

    protected submit(): void {
        super.submit();
        this._errorMessage = null;

        observableOf(true).pipe(
            concatMap(() => {
                const obs: Observable<{ hasNext: boolean, updateRes: IAPIRx<IVirtualDeviceRxData> }>[] = [];

                Object.keys(this._actionData.confirmShareInfoMap).forEach((virtualDeviceID: string) => {
                    const shareList: {
                        accountName: string;
                        permission: string[];
                    }[] = this._actionData.confirmShareInfoMap[virtualDeviceID].unchanged.concat(
                        this._actionData.confirmShareInfoMap[virtualDeviceID].changed
                    ).concat(
                        this._actionData.confirmShareInfoMap[virtualDeviceID].added
                    ).map((s: { accountID: string, accountName: string, permission: string[], action?: string }) => {
                        return {
                            accountName: s.accountName,
                            permission: s.permission
                        }
                    });

                    obs.push(
                        this.naSvc.shareDevice({ virtualDeviceID: virtualDeviceID }, { sharingList: shareList }, this.accountSvc.token).pipe(
                            map((result: IAPIRx<IVirtualDeviceRxData>) => {
                                //update local cache
                                if (result.error === 0 && result.data) {
                                    (this._actionData.confirmShareInfoMap[virtualDeviceID].device as DeviceInfo).virtualDeviceSharing = result.data.virtualDeviceSharing || {};
                                }

                                return {
                                    hasNext: true,
                                    updateRes: result
                                }
                            })
                        )
                    );
                });

                obs.push(observableOf({
                    hasNext: false,
                    updateRes: null
                }));

                return obs;
            }),
            concatAll()
        ).subscribe((res: { hasNext: boolean, updateRes: IAPIRx<IVirtualDeviceRxData> }) => {
            if (res) {
                //last one
                if (!res.hasNext) {
                    this._page++;
                }
                else if (res.updateRes.error !== 0) {
                    this._errorMessage = res.updateRes.errorMessage || res.updateRes.error.toString();
                }
            }
        });
    }
}