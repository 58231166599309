import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { DeviceInfo } from '../../../app/content/device/data/device-info';
import { ConstantService } from '../../../app/lib/common/constant.service';
import { AppConfigService } from '../../../app/app.config';
import { HelperLib } from '../../../app/lib/common/helper.lib';
import { AndroidGroupType } from '../../../app/content/devfunc/firmware/firmware-data';
import { AppStartPlaybackMode } from './appstart.data';

@Component({
    selector: 'na-appstart',
    templateUrl: './appstart.component.html',
    styleUrls: ['../uiElement.style.css', './appstart.component.css']
})
export class AppStartComponent implements OnInit, OnDestroy {
    readonly Enable_contentUrl: boolean = AppConfigService.configs.devPage.func.basicConfig.element.contentUrl;
    readonly Enable_appStartExtraOption: boolean = AppConfigService.configs.devPage.func.basicConfig.element.appStartExtraOption;
    readonly Enable_appStartOverlay: boolean = AppConfigService.configs.devPage.func.basicConfig.element.overlayOption;

    private readonly _allUnsubscribe: Subject<void> = new Subject();

    _androidGroup: AndroidGroupType = AndroidGroupType.And_71;
    _appstartExtraList: {
        property: string;
        displayName: string;
        condition?: (androidVersion: AndroidGroupType) => boolean
    }[] = [];

    _enumAppstartPlaybackMode: typeof AppStartPlaybackMode = AppStartPlaybackMode;
    _appstartPlaybackMode: AppStartPlaybackMode = AppStartPlaybackMode.apk;

    private _device: DeviceInfo;
    @Input('device')
    set device(d: DeviceInfo) {
        this._device = d;
    }

    _config: { [name: string]: { value: any } };
    @Input('config')
    set config(d: { [name: string]: { value: any } }) {
        this._config = d;
    }

    _showBorder: boolean = false;
    @Input('showBorder')
    set showBorder(d: boolean) {
        this._showBorder = d;
    }

    _disabled: boolean = false;
    @Input('disabled')
    set disabled(d: boolean) {
        this._disabled = d;
    }

    _lockInfo: { isSync: boolean, policyID: string, policyName: string };
    @Input('lock')
    set lock(d: { isSync: boolean, policyID: string, policyName: string }) {
        this._lockInfo = d;
    }

    @ViewChild('appstartContentUrl')
    set contentUrl(v: ElementRef) {
        if (v) {
            fromEvent(v.nativeElement, 'input').pipe(
                debounceTime(200),
                takeUntil(this._allUnsubscribe)
            ).subscribe((e: any) => {
                this.onAppstartChanged.emit({ property: { name: this.constantSvc.DEVKEY_APPSTART_CONTENTURL, value: e.target.value }});
            });
        }
    }

    /*
    @ViewChild('appstartPackageName')
    set packageName(v: ElementRef) {
        if (v) {
            fromEvent(v.nativeElement, 'input').pipe(
                debounceTime(200),
                takeUntil(this._allUnsubscribe)
            ).subscribe((e: any) => {
                this.onAppstartChanged.emit({ property: { name: this.constantSvc.DEVKEY_APPSTART_PACKAGENAME, value: e.target.value }});
            });
        }
    }

    @ViewChild('appstartClassName')
    set className(v: ElementRef) {
        if (v) {
            fromEvent(v.nativeElement, 'input').pipe(
                debounceTime(200),
                takeUntil(this._allUnsubscribe)
            ).subscribe((e: any) => {
                this.onAppstartChanged.emit({ property: { name: this.constantSvc.DEVKEY_APPSTART_CLASSNAME, value: e.target.value }});
            });
        }
    }

    @ViewChild('appstartAction')
    set action(v: ElementRef) {
        if (v) {
            fromEvent(v.nativeElement, 'input').pipe(
                debounceTime(200),
                takeUntil(this._allUnsubscribe)
            ).subscribe((e: any) => {
                this.onAppstartChanged.emit({ property: { name: this.constantSvc.DEVKEY_APPSTART_ACTION, value: e.target.value } });
            });
        }
    }
    */

    @Output() onAppstartChanged = new EventEmitter<{ playbackMode?: AppStartPlaybackMode, property?: { name: string, value: any } }>();

    constructor(private constantSvc: ConstantService) {
        this._appstartExtraList = this.constantSvc.getAppstartExtraList();
    }

    ngOnInit(): void {
        if (this._device) {
            this._androidGroup = HelperLib.getAndroidGroup(this._device.currentSettings[this.constantSvc.DEVKEY_INFO_FW]);
            this._appstartPlaybackMode = this._device.currentSettings[this.constantSvc.DEVKEY_APPSTART_PACKAGENAME] === 'com.iadea.player' || this._device.currentSettings[this.constantSvc.DEVKEY_APPSTART_PACKAGENAME] === '' ? AppStartPlaybackMode.url : AppStartPlaybackMode.apk;
        }
    }

    ngOnDestroy(): void {
        this._allUnsubscribe.next();
        this._allUnsubscribe.complete();
    }

    /*     
    changeAppstartPlaybackMode(mode: AppStartPlaybackMode): void {
        if (mode !== this._appstartPlaybackMode) {
            this._appstartPlaybackMode = mode;
            this.onAppstartChanged.emit({ playbackMode: this._appstartPlaybackMode });
        }
    }
    */

    onExtraChanged(extra: {
        property: string;
        displayName: string;
        condition?: (androidVersion: AndroidGroupType) => boolean
    }, checked: boolean): void {
        this._config[extra.property].value = checked;
        this.onAppstartChanged.emit({ property: { name: extra.property, value: checked }});
    }
}