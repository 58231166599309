export class TimeserverInfo {
    private _enabled: boolean = false;
    get enabled(): boolean {
        return this._enabled;
    }

    private _option: TimeserverOption = TimeserverOption.default;
    private _url: string = TimeserverOption[TimeserverOption.default];
    set url(u: string) {
        if (this._url !== u) {
            this._url = u;
        }
    }
    get url(): string {
        return this._url;
    }

    get isValid(): boolean {
        return this._option === TimeserverOption.customize && !this._url ? false : true;
    }

    isOptionEnabled(option: TimeserverOption): boolean {
        return this._option === option;
    }

    turnOnOff(on: boolean): boolean {
        if (this._enabled !== on) {
            this._enabled = on;

            if (!this._enabled) {
                this.changeTimeserverOption(TimeserverOption.default);
            }

            return true;
        }

        return false;
    }

    changeTimeserverOption(option: TimeserverOption, currentUrl: string = ''): boolean {
        if (this._option !== option) {
            this._option = option;
            this._url = option !== TimeserverOption.customize ? TimeserverOption[option] : currentUrl;

            return true;
        }

        return false;
    }
}

export enum TimeserverOption {
    default,
    content,
    customize
}