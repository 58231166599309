import { Component, Input } from "@angular/core";
import { WeekScheduleInfo } from "./weekScheduleSelector.data";

@Component({
    selector: 'na-weekScheduleSelector-preview',
    templateUrl: './weekScheduleSelector-preview.component.html'
})
export class WeekScheduleSelectorPreviewComponent {
    _info: WeekScheduleInfo;
    @Input('data')
    set data(s: WeekScheduleInfo) {
        if (s) {
            this._info = s;
        }
    }
}