export class CaseInfo {
    id?: string;
    desc?: string;
    link?: string;
    icon?: string;
}

export const LOGIN_SLOGAN: string = 'Discover what IAdea products can offer to your projects';
export const LOGIN_CASE_LIST: CaseInfo[] = [
    {
        id: 'id_entry_link_IAdeaAdEducation',
        desc: 'Higher Eds',
        link: 'https://www.iadea.com/markets/education/',
        icon: 'assets/icon/HigherEDU.png'
    },
    {
        id: 'id_entry_link_IAdeaAdWorkplace',
        desc: 'Workplace',
        link: 'https://www.iadea.com/markets/workplace/',
        icon: 'assets/icon/Education.png'
    },
    {
        id: 'id_entry_link_IAdeaAdSolutions',
        desc: 'Others',
        link: 'https://www.iadea.com/#solutions',
        icon: 'assets/icon/Other.png'
    }
];