import { Component, Input } from '@angular/core';
import { ScreenOffInfo } from './screen.data';

@Component({
    selector: 'na-screenOff-preview',
    templateUrl: './screenOff-preview.component.html'
})
export class ScreenOffPreviewComponent {
    _info: ScreenOffInfo;
    @Input('data')
    set data(s: ScreenOffInfo) {
        if (s) {
            this._info = s;
        }
    }

    _showSwitch: boolean = true;
    @Input('showSwitch')
    set showSwitch(s: boolean) {
        this._showSwitch = s;
    }
}