import { WeekDay } from "@angular/common";
import { WEEKDAY_ABBR_LIST } from "../../../lib/common/helper.lib";

export enum WeekDayScheduleMode {
    period = 'period',
    weekday = 'weekday'
}

export class WeekScheduleInfo {
    basic: {
        beginTime: string;
        endTime: string;
        scheduleMode: WeekDayScheduleMode;
        beginDate?: string;
        endDate?: string;
        dayList: { day: string, checked: boolean }[];
    };
    weekend: {
        enabled: boolean;
        beginTime?: string;
        endTime?: string;
        dayList: { day: string, checked: boolean }[];
    };

    constructor(rawData?: string) {
        let d: WeekScheduleInfo = null;
        if (rawData) {
            try {
                d = JSON.parse(rawData);
            }
            catch {
            }
        }

        this.basic = d && d.basic ? d.basic : {
            beginTime: '00:00',
            endTime: '05:00',
            scheduleMode: WeekDayScheduleMode.weekday,
            beginDate: '',
            endDate: '',
            dayList: WEEKDAY_ABBR_LIST.map(w => ({ day: w, checked: false }))
        };
        this.weekend = d && d.weekend ? d.weekend : {
            enabled: false,
            beginTime: '00:00',
            endTime: '05:00',
            dayList: [{ day: WeekDay[WeekDay.Saturday].substring(0, 3), checked: false }, { day: WeekDay[WeekDay.Sunday].substring(0, 3), checked: false }]
        };
    }

    isValid(featureEnabled: boolean): boolean {
        let isValid: boolean = this.isBasicTimePeriodScheduleValid(featureEnabled);
        if (isValid) {
            switch (this.basic.scheduleMode) {
                case WeekDayScheduleMode.period:
                    {
                        isValid = this.isBasicDatePeriodScheduleValid(featureEnabled);
                    }
                    break;
                case WeekDayScheduleMode.weekday:
                    {
                        isValid = this.isBasicWeekdayScheduleValid(featureEnabled);
                    }
                    break;
            }
        }

        if (isValid && this.weekend.enabled) {
            isValid = this.isWeekendTimePeriodScheduleValid(featureEnabled);
            if (isValid) {
                isValid = this.isWeekendDayScheduleValid(featureEnabled);
            }
        }

        return isValid;
    }

    isBasicTimePeriodScheduleValid(featureEnabled: boolean): boolean {
        return featureEnabled && this.basic.beginTime == this.basic.endTime ? false : true;
    }

    isBasicDatePeriodScheduleValid(featureEnabled: boolean): boolean {
        return featureEnabled && this.basic.scheduleMode == WeekDayScheduleMode.period && (!this.basic.beginDate || !this.basic.endDate || (this.basic.beginDate > this.basic.endDate)) ? false : true;
    }

    isBasicWeekdayScheduleValid(featureEnabled: boolean): boolean {
        return featureEnabled && this.basic.scheduleMode == WeekDayScheduleMode.weekday && this.basic.dayList.filter(d => d.checked).length == 0 ? false : true;
    }

    isWeekendTimePeriodScheduleValid(featureEnabled: boolean): boolean {
        return featureEnabled && this.weekend.enabled && this.weekend.beginTime == this.weekend.endTime ? false : true;
    }

    isWeekendDayScheduleValid(featureEnabled: boolean): boolean {
        return featureEnabled && this.weekend.enabled && this.weekend.dayList.filter(d => d.checked).length == 0 ? false : true;
    }

    copy(): WeekScheduleInfo {
        const s: WeekScheduleInfo = new WeekScheduleInfo();

        s.basic.beginTime = this.basic.beginTime;
        s.basic.endTime = this.basic.endTime;
        s.basic.beginDate = this.basic.beginDate;
        s.basic.endDate = this.basic.endDate;
        s.basic.scheduleMode = this.basic.scheduleMode;
        s.basic.dayList = this.basic.dayList.map(d => { return { checked: d.checked, day: d.day } });

        if (this.weekend) {
            s.weekend.enabled = this.weekend.enabled;
            s.weekend.beginTime = this.weekend.beginTime;
            s.weekend.endTime = this.weekend.endTime;
            s.weekend.dayList = this.weekend.dayList ? this.weekend.dayList.map(d => { return { checked: d.checked, day: d.day } }) : [];
        }

        return s;
    }

    getWeekDayScheduleDescription(): string {
        if (!this.basic) {
            return '';
        }

        if (this.basic.scheduleMode === WeekDayScheduleMode.period) {
            return this.basic.beginDate + ' ' + this.basic.beginTime + ' ~ ' + this.basic.endDate + ' ' + this.basic.endTime;
        }
        else {
            return this.basic.beginTime + ' ~ ' + this.basic.endTime + ' repeatly on ' + this.basic.dayList.filter(d => d.checked).map(d => d.day).join(', ');
        }
    }

    getWeekendScheduleDescription(): string {
        if (!this.weekend || !this.weekend.enabled) {
            return '';
        }

        return this.weekend.beginTime + ' ~ ' + this.weekend.endTime + ' on ' + this.weekend.dayList.filter(d => d.checked).map(d => d.day).join(', ');
    }

    transformToRawdata(): {
        type: string;
        dayList: string[];
        startDate: string;
        endDate: string;
        startTime: string;
        endTime: string;
    }[] {
        const periodList: {
            type: string;
            dayList: string[];
            startDate: string;
            endDate: string;
            startTime: string;
            endTime: string;
        }[] = [];

        const DAILY_TYPE_STR: string = 'daily';
        if (!this.weekend.enabled) {
            if (this.basic.beginTime < this.basic.endTime) {
                periodList.push({
                    type: DAILY_TYPE_STR,
                    dayList: this.basic.dayList.filter(d => d.checked).map(d => d.day.toUpperCase()),
                    startTime: this.basic.beginTime + ':00',
                    endTime: this.basic.endTime + ':00',
                    startDate: this.basic.scheduleMode === WeekDayScheduleMode.period ? this.basic.beginDate : null,
                    endDate: this.basic.scheduleMode === WeekDayScheduleMode.period ? this.basic.endDate : null
                });
            }
            else {
                //across a day, ex: 20:00:00 ~ 05:00:00 should divid into 20:00:00 ~ 23:59:59 and 00:00:00 ~ 05:00:00
                periodList.push({
                    type: DAILY_TYPE_STR,
                    dayList: this.basic.dayList.filter(d => d.checked).map(d => d.day.toUpperCase()),
                    startTime: this.basic.beginTime + ':00',
                    endTime: '23:59:59',
                    startDate: this.basic.scheduleMode === WeekDayScheduleMode.period ? this.basic.beginDate : null,
                    endDate: this.basic.scheduleMode === WeekDayScheduleMode.period ? this.basic.endDate : null
                }, {
                    type: DAILY_TYPE_STR,
                    dayList: this.basic.dayList.filter(d => d.checked).map(d => d.day.toUpperCase()),
                    startTime: '00:00:00',
                    endTime: this.basic.endTime + ':00',
                    startDate: this.basic.scheduleMode === WeekDayScheduleMode.period ? this.basic.beginDate : null,
                    endDate: this.basic.scheduleMode === WeekDayScheduleMode.period ? this.basic.endDate : null
                });
            }
        }
        else {
            // weekday
            if (this.basic.beginTime < this.basic.endTime) {
                periodList.push({
                    type: DAILY_TYPE_STR,
                    dayList: this.basic.dayList.filter(d => d.checked && d.day !== 'Sat' && d.day !== 'Sun').map(d => d.day.toUpperCase()),
                    startTime: this.basic.beginTime + ':00',
                    endTime: this.basic.endTime + ':00',
                    startDate: this.basic.scheduleMode === WeekDayScheduleMode.period ? this.basic.beginDate : null,
                    endDate: this.basic.scheduleMode === WeekDayScheduleMode.period ? this.basic.endDate : null
                });
            }
            else {
                periodList.push({
                    type: DAILY_TYPE_STR,
                    dayList: this.basic.dayList.filter(d => d.checked && d.day !== 'Sat' && d.day !== 'Sun').map(d => d.day.toUpperCase()),
                    startTime: this.basic.beginTime + ':00',
                    endTime: '23:59:59',
                    startDate: this.basic.scheduleMode === WeekDayScheduleMode.period ? this.basic.beginDate : null,
                    endDate: this.basic.scheduleMode === WeekDayScheduleMode.period ? this.basic.endDate : null
                }, {
                    type: DAILY_TYPE_STR,
                    dayList: this.basic.dayList.filter(d => d.checked && d.day !== 'Sat' && d.day !== 'Sun').map(d => d.day.toUpperCase()),
                    startTime: '00:00:00',
                    endTime: this.basic.endTime + ':00',
                    startDate: this.basic.scheduleMode === WeekDayScheduleMode.period ? this.basic.beginDate : null,
                    endDate: this.basic.scheduleMode === WeekDayScheduleMode.period ? this.basic.endDate : null
                });
            }

            // weekend
            if (this.weekend.beginTime < this.weekend.endTime) {
                periodList.push({
                    type: DAILY_TYPE_STR,
                    dayList: this.weekend.dayList.filter(d => d.checked).map(d => d.day.toUpperCase()),
                    startTime: this.weekend.beginTime + ':00',
                    endTime: this.weekend.endTime + ':00',
                    startDate: this.basic.scheduleMode === WeekDayScheduleMode.period ? this.basic.beginDate : null,
                    endDate: this.basic.scheduleMode === WeekDayScheduleMode.period ? this.basic.endDate : null
                });
            }
            else {
                periodList.push({
                    type: DAILY_TYPE_STR,
                    dayList: this.weekend.dayList.filter(d => d.checked).map(d => d.day.toUpperCase()),
                    startTime: this.weekend.beginTime + ':00',
                    endTime: '23:59:59',
                    startDate: this.basic.scheduleMode === WeekDayScheduleMode.period ? this.basic.beginDate : null,
                    endDate: this.basic.scheduleMode === WeekDayScheduleMode.period ? this.basic.endDate : null
                }, {
                    type: DAILY_TYPE_STR,
                    dayList: this.weekend.dayList.filter(d => d.checked).map(d => d.day.toUpperCase()),
                    startTime: '00:00:00',
                    endTime: this.basic.endTime + ':00',
                    startDate: this.basic.scheduleMode === WeekDayScheduleMode.period ? this.basic.beginDate : null,
                    endDate: this.basic.scheduleMode === WeekDayScheduleMode.period ? this.basic.endDate : null
                });
            }
        }

        return periodList;
    }

    static equal(a: WeekScheduleInfo, b: WeekScheduleInfo): boolean {
        if (!a && !b) {
            return true;
        }

        if ((!a && b) || (a && !b)) {
            return false;
        }

        //check basic settings
        if (a.basic.beginTime !== b.basic.beginTime || a.basic.endTime !== b.basic.endTime) {
            return false;
        }

        if (a.basic.scheduleMode !== b.basic.scheduleMode) {
            return false;
        }

        if (a.basic.scheduleMode === WeekDayScheduleMode.weekday) {
            for (let i = 0; i < a.basic.dayList.length; ++i) {
                if (a.basic.dayList[i].checked !== b.basic.dayList[i].checked) {
                    return false;
                }
            }
        }
        else if (a.basic.beginDate !== b.basic.beginDate || a.basic.endDate !== b.basic.endDate) {
            return false;
        }

        //check weekend settings
        if (a.weekend.enabled !== b.weekend.enabled) {
            return false;
        }

        if (a.weekend.beginTime !== b.weekend.beginTime || a.weekend.endTime !== b.weekend.endTime) {
            return false;
        }

        for (let i = 0; i < a.weekend.dayList.length; ++i) {
            if (a.weekend.dayList[i].checked !== b.weekend.dayList[i].checked) {
                return false;
            }
        }

        return true;
    }
}