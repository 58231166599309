import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AccountService } from '../../entry/account.service';
import { SharePermissionType } from '../../lib/common/helper.lib';
import { ShareSettingUIInfo } from './share.data';

@Component({
    selector: 'na-share',
    templateUrl: './share.component.html',
    styleUrls: ['../uiElement.style.css', './share.style.css', './share.component.css']
})
export class ShareComponent implements OnInit {
    _shareList: ShareSettingUIInfo[] = [];

    @Input('data')
    set data(vMap: {
        [accountID: string]: {
            accountID: string,
            accountName: string,
            permission: string[],
            action?: string
        }
    }) {
        Object.keys(vMap).map((accountID: string) => this._shareList.push(new ShareSettingUIInfo(vMap[accountID])));
    }

    _disabled: boolean;
    @Input('disabled')
    set disabled(v: boolean) {
        this._disabled = v;
    }

    _lockInfo: { isSync: boolean, policyID: string, policyName: string };
    @Input('lock')
    set lock(d: { isSync: boolean, policyID: string, policyName: string }) {
        this._lockInfo = d;
    }

    _hasLicense: boolean = false;
    @Input('hasLicense')
    set hasLicense(hasLicense: boolean) {
        this._hasLicense = hasLicense;
    }

    @Output() onShareSettingChanged = new EventEmitter<{
        data: {
            [accountID: string]: {
                accountID: string,
                accountName: string,
                permission: SharePermissionType[],
                action: string
            }
        }, isValid: boolean
    }>();

    constructor(private accountSvc: AccountService) { }

    ngOnInit(): void { }

    add(): void {
        this._shareList.push(new ShareSettingUIInfo());
        this.notifyUpdate();
    }

    remove(s: ShareSettingUIInfo): void {
        s.remove();
        this.notifyUpdate();
    }

    changePermission(s: ShareSettingUIInfo, p: { permission: string, displayName: string, beforeChecked: boolean, afterChecked: boolean }, checked: boolean): void {
        if (p.afterChecked !== checked) {
            p.afterChecked = checked;
            s.isChange();
            this.notifyUpdate();
        }
    }

    changeAccountName(s: ShareSettingUIInfo, d: string): void {
        if (s.accountName !== d) {
            s.accountName = d;
            s.isChange();

            if (s.accountName === this.accountSvc.accountName) {
                s.errorMessage = 'Could not share to yourself';
            }
            else if (!s.accountName) {
                s.errorMessage = 'Account should not be empty';
            }
            else if (this.isAccountNameExist(s.accountID, s.accountName)) {
                s.errorMessage = 'Account is duplicate';
            }
            else {
                s.errorMessage = '';
            }

            this.notifyUpdate();
        }
    }

    applyEditorMode(s: ShareSettingUIInfo): void {
        s.permissionList.forEach(p => p.afterChecked = true);
        s.isChange();
        this.notifyUpdate();
    }

    applyViewerMode(s: ShareSettingUIInfo): void {
        s.permissionList.forEach(p => p.afterChecked = false);
        s.isChange();
        this.notifyUpdate();
    }

    applyResetMode(s: ShareSettingUIInfo): void {
        s.reset();
        this.notifyUpdate();
    }

    notifyUpdate(): void {
        this.onShareSettingChanged.emit({
            data: this._shareList.reduce((r, e) => {
                r[e.accountID] = e.export();
                return r;
            }, {}),
            isValid: this._shareList.filter(s => s.errorMessage).length > 0 ? false : true
        });
    }

    private isAccountNameExist(accountID: string, accountName: string): boolean {
        if (this._shareList.length === 1) {
            return false;
        }

        return this._shareList.find(s => s.accountID !== accountID && s.accountName === accountName) ? true : false;
    }
}