import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AccountService } from '../../../../../app/entry/account.service';
import { DevFuncActionInterface } from '../../base/action/dev-func-action.interface';
import { STR_MODE_EMAIL, STR_MODE_FAE_SUPPORT, TroubleshootMode } from '../troubleshoot.data';

@Component({
    templateUrl: './issue-action-subform.component.html',
    styleUrls: ['../../base/base-config-form.component.css', './issue-action-subform.component.css']
})
export class IssueActionSubFormComponent implements DevFuncActionInterface, OnDestroy {
    readonly ERROR_SYMPTOM: string[] = ['playback crash', 'constantly reboot', 'others'];
    readonly ERROR_FREQUENCY: string[] = ['every hour', 'every 2~3 hour', 'every day', 'unknown'];
    readonly STR_MODE_FAE_SUPPORT: string = STR_MODE_FAE_SUPPORT;
    readonly STR_MODE_EMAIL: string = STR_MODE_EMAIL;
    readonly RECIPIENT_LIMIT: number = 5;

    private _actionData: any;
    set actionData(data: any) {
        this._actionData = data;
        if (this._actionData) {
            this._monitorDeviceNames = this._actionData.deviceNames;
            this._issueTitle = this._actionData.issueTitle;
            this._issueData = this._actionData.issueContent;
            this._issueSymptom = this._actionData.issueSymptom || this.ERROR_SYMPTOM[this.ERROR_SYMPTOM.length - 1];
            this._issueFrequency = this._actionData.issueFrequency || this.ERROR_FREQUENCY[this.ERROR_FREQUENCY.length - 1];
            this._emailList = this.transformEmail(this._actionData.issueEmailList);
            this.changeMode(this._actionData.issueMode);
        }
    }

    actionCompleteHandler: (result: any) => void;
    actionStatusUpdateHandler: (isValid: boolean) => void;

    _str_mode: string;
    _mode: TroubleshootMode = TroubleshootMode.Unknown;
    _enumIssueMode: typeof TroubleshootMode = TroubleshootMode;

    _emailList: { email: string, removable: boolean }[];
    _issueTitle: string;
    _issueData: string;
    _issueSymptom: string;
    _issueFrequency: string;
    _monitorDeviceNames: string;

    private _allUnsubscribe: Subject<void> = new Subject();

    private _emailForm: NgForm;
    @ViewChild('emailForm', { static: false })
    set emailForm(f: NgForm) {
        if (f && !this._emailForm) {
            this._emailForm = f;
            if (this._emailForm) {
                this._emailForm.statusChanges.pipe(
                    takeUntil(this._allUnsubscribe)
                ).subscribe((status: string) => {
                    this.actionStatusUpdateHandler(status === "VALID" ? true : false);
                });
            }
        }
    }

    private _faeForm: NgForm;
    @ViewChild('faeForm', { static: false })
    set faeForm(f: NgForm) {
        if (f && !this._faeForm) {
            this._faeForm = f;
            if (this._faeForm) {
                this._faeForm.statusChanges.pipe(
                    takeUntil(this._allUnsubscribe)
                ).subscribe((status: string) => {
                    this.actionStatusUpdateHandler(status === "VALID" ? true : false);
                });
            }
        }
    }

    constructor(private accountSvc: AccountService) { }

    ngOnDestroy(): void {
        if (this.actionCompleteHandler) {
            const result = {
                issueMode: this._mode,
                issueTitle: this._issueTitle,
                issueContent: this._issueData,
                issueSymptom: this._issueSymptom,
                issueFrequency: this._issueFrequency,
                issueEmailList: this._emailList.map(e => e.email)
            };

            this.actionCompleteHandler(result);
        }

        this._allUnsubscribe.next();
        this._allUnsubscribe.complete();
    }

    changeMode(mode: TroubleshootMode = TroubleshootMode.Unknown): void {
        this._mode = mode;
        switch (mode) {
            case TroubleshootMode.Email:
                {
                    this._str_mode = this.STR_MODE_EMAIL;
                }
                break;
            case TroubleshootMode.FAESupport:
                {
                    this._str_mode = this.STR_MODE_FAE_SUPPORT;
                }
                break;
            default:
                {
                    this._str_mode = '';
                    setTimeout(() => {
                        this.actionStatusUpdateHandler(false);
                    }, 0);
                }
                break;
        }
    }

    addRecipient(): void {
        if (this._emailList.length === this.RECIPIENT_LIMIT) {
            return;
        }

        this._emailList.push({
            email: '',
            removable: true
        });
    }

    removeRecipient(e: { email: string, removable: boolean }): void {
        if (e.removable) {
            this._emailList.splice(this._emailList.indexOf(e), 1);
        }
    }

    private transformEmail(emailList: string[] = []): { email: string, removable: boolean }[] {
        return emailList.length === 0 ?
            [{ email: this.accountSvc.accountName, removable: false }] :
            emailList.map(e => {
                return e === this.accountSvc.accountName ? { email: e, removable: false } : { email: e, removable: true };
            });
    }
}