import { Type } from '@angular/core';
import { CertInfo } from '../cert.data';

export const CERT_FUNC_ADD: string = 'cert-add';
export const CERT_FUNC_REMOVE: string = 'cert-remove';

export class CertFuncItem {
  constructor(public component: Type<any>, public name: string, public title: string) {}
}

export interface ICertFuncCtrl {
  title: string;
  funcName: string;
  certList: CertInfo[];
  other?: any;
  onActionCompleted: (ret: { funcName: string, isFault: boolean, data?: any, errorMessage?: string }) => void;
}