<div class="list-group">
  <div class="list-group-item list-group-header">
    <div class="row">
      <span class="col title">Report sent on</span>
      <span class="col title">Network status</span>
      <span class="col title">Warranty</span>
      <span class="col title">License</span>
      <span class="col title">Error</span>
      <span class="col title">Warning</span>
    </div>
  </div>
  <div *ngIf="!_isLoading; else templateLoadingReportList"
    class="list-group-content list-group-hoverless list-group-content-nr-3">
    <div *ngIf="_errorMessage; else templateLoadReportListPass" class="warning-block mt-4">
      {{ _errorMessage }}
    </div>
    <ng-template #templateLoadReportListPass>
      <div *ngFor="let report of _reportList" class="list-group-item"
        [ngClass]="_report?.reportID === report.reportID ? 'active': ''">
        <div class="row align-items-center">
          <span class="col text-break">
            {{ report.reportDateTime | date:'yyyy-MM-dd HH:mm:ss' }}
            <i class="far fa-sticky-note fa-link" data-toggle="modal" data-target="#reportDetailModal" (click)="viewReport(report)"></i>
          </span>
          <span class="col text-break">{{ report.summary?.networkEvaluation }}</span>
          <span class="col text-break">{{ report.summary?.warrantyEvaluation }}</span>
          <span class="col text-break">{{ report.summary?.licenseEvaluation }}</span>
          <span class="col text-break">{{ report.summary?.errorCount }}</span>
          <span class="col text-break">{{ report.summary?.warningCount }}</span>
        </div>
      </div>
    </ng-template>
  </div>
  <ng-template #templateLoadingReportList>
    <div class="loading">
      <p>Please wait...</p>
      <div class="loading-box"></div>
    </div>
  </ng-template>
</div>
<div class="d-flex align-items-center mt-1">
  <div class="ml-auto text-muted font-italic">
    total : {{ _total }}
  </div>
  <div class="ml-2">
    <na-pager [currentPage]="_currentPage" [numberInPage]="50" [total]="_total" (onPageChanged)="onPageChange($event)">
    </na-pager>
  </div>
</div>
<div id='reportDetailModal' tabindex="-1" class='modal fade' data-backdrop='static'
  data-keyboard='false' role='dialog'>
  <div class='modal-dialog config-dlg'>
    <div class='modal-content'>
      <div class='modal-header'>
        <span class="modal-title">Report</span>
        <button #dlgClose type="button" class="close" data-dismiss="modal">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class='modal-body'>
        <div *ngIf="_report" class="report-detail" [innerHTML]="_report.body"></div>
        <div class="row control-block d-flex justify-content-center mt-4">
          <button type="button" class="btn btn-sm btn-action btn-light" data-dismiss="modal"
            (click)="closeReport()">
            <span class="fas fa-times-circle icon-left"></span>
            <span>Close</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>