<div>
    <div *ngIf="supportUpdateUserGroup()" class="block-titlebar">
        <strong class="txt-bold">Group detail</strong>
        <button type="button" class="btn btn-sm btn-action btn-light btn-nb btn-edit ml-auto" [class.active]="_isInEdit"
            [disabled]="_loading" (click)="startEdit()">
            <i class="fas fa-pen mr-1"></i>
            Edit
        </button>
        <button type="button" class="btn btn-sm btn-action btn-light btn-nb btn-edit ml-2"
            [disabled]="_loading || _isInEdit || _userGroup?.isDefault" data-toggle="modal"
            data-target="#userGroupDeleteModalHost" (click)="removeUserGroup()">
            <i class="fas fa-trash-alt mr-1"></i>
            Delete group
        </button>
    </div>
    <div class="form-group row mt-2">
        <label for="col-userGroup-name" class="col-12 col-sm-5 col-md-4 col-form-label col-form-label-sm">
            Group name
        </label>
        <div class="col-12 col-sm-7 col-md-8 col-xl-6">
            <div *ngIf="!_isInEdit || _userGroup?.isDefault; else templateEditUserGroupName" id="col-userGroup-name"
                class="form-control-plaintext-sm">
                {{ _userGroup?.name }}
            </div>
            <ng-template #templateEditUserGroupName>
                <input id="col-userGroup-name" type="text" class="form-control form-control-sm"
                    [(ngModel)]="_editData.groupName">
            </ng-template>
        </div>
    </div>
    <div class="form-group row">
        <label for="col-userGroup-deviceGroup" class="col-12 col-sm-5 col-md-4 col-form-label col-form-label-sm">
            Device group
        </label>
        <div class="col-12 col-sm-7 col-md-8 col-xl-6">
            <div *ngIf="!_isInEdit; else templateEditDeviceGroup" id="col-userGroup-deviceGroup"
                class="form-control-plaintext-sm">
                {{ _devGroupCount }} device groups selected
            </div>
            <ng-template #templateEditDeviceGroup>
                <div id="col-userGroup-deviceGroup" class="position-relative">
                    <button id="dropdownDeviceGroupLink" type="button"
                        class="btn btn-sm btn-action btn-light dropdown-toggle" data-toggle="dropdown">
                        {{ _devGroupCount }} device groups selected
                    </button>
                    <div class="dropdown-menu option-list w-100" data-backdrop="static" data-keyboard="false"
                        aria-labelledby="dropdownDeviceGroupLink">
                        <strong>Device group :</strong>
                        <div class="dev-group-block" (click)="$event.stopPropagation()">
                            <na-dev-group-tree-wrapper [group]="_devGroupRoot" [mode]="_devGroupMode"
                                [enableFuncUnfold]="false" [showTitle]="true" [styleBorder]="false"
                                [pickMap]="_editData.devGroupMap">
                            </na-dev-group-tree-wrapper>
                        </div>
                        <div class="text-right mt-2">
                            <button type="button" class="btn btn-sm btn-action btn-light"
                                (click)="selectDeviceGroups()">
                                OK
                            </button>
                        </div>
                    </div>
                </div>
            </ng-template>
            <div class="mt-1">
                <div class="d-flex align-items-center">
                    <ng-template [ngIf]="_isInEdit">
                        <input type="checkbox" id="chbox-assign-new-devgroup" class="cmn-checkbox"
                            [checked]="_editData.autoAssignDeviceGroup" [disabled]="!_isInEdit"
                            (change)="_editData.autoAssignDeviceGroup = $event.target.checked">
                        <label for="chbox-assign-new-devgroup" class="mr-2">
                            <svg>
                                <use xlink:href="#checkmark" />
                            </svg>
                            <span class="sr-only">Enable to assign new device group</span>
                        </label>
                    </ng-template>
                    <span *ngIf="_userGroup?.autoAssignDeviceGroup || _isInEdit" class="form-control-plaintext-sm"
                        [class.red-1]="!_isInEdit">
                        Automatically assign new device group.
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="_page === _enumPage.result" class="mt-2">
        <div *ngIf="_errorMessage" class="block block-error">
            <i class="fas fa-exclamation-circle"></i>
            <span class="ml-2">Edit group info failed : </span>
            <div>{{ _errorMessage }}</div>
        </div>
    </div>
</div>
<div *ngIf="_isInEdit" class="mt-2 d-flex align-items-center">
    <button type="button" class="btn btn-sm btn-action btn-light ml-auto" [disabled]="_page === _enumPage.submit"
        (click)="cancelEdit()">
        <span class="fas fa-times-circle"></span>
        Cancel
    </button>
    <button type="button" class="btn btn-sm btn-action btn-light ml-2" [disabled]="_page === _enumPage.submit"
        (click)="saveEdit()">
        <span class="fas fa-save"></span>
        Save
    </button>
    <div *ngIf="_page === _enumPage.submit" class="loading loading-p0 ml-4">
        <div class="loading-box"></div>
    </div>
</div>
<div id="userGroupDeleteModalHost" class="modal fade" data-backdrop="static" data-keyboard="false" role="dialog">
    <ng-template user-dlg-func></ng-template>
</div>