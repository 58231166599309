import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AlertConfigReportComponent } from './alert-config-report.component';
import { AlertDataCtrlModule } from '../alertData/alert-data-ctrl.module';

import { CustomPipeModule } from '../../../../../lib/pipes/custom-pipe.module';
import { TranslateModule } from '../../../../../translate/translate.module';

@NgModule({
    imports: [
        CommonModule,

        CustomPipeModule,
        TranslateModule,

        AlertDataCtrlModule
    ],
    declarations: [
        AlertConfigReportComponent
    ],
    exports: [
        AlertConfigReportComponent
    ]
})
export class AlertElementModule { }