import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../../api.base';
import { EnterpriseAccountBasicInfo } from './api.ent.data';

export class APIListEnterpriseAccountManager extends APIBaseManager<void, void, void, EnterpriseAccountBasicInfo[]> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_ListEnterpriseAccount';
        this._apiMethod = 'GET';
    }

    protected getRequestURL(pathParameters: void, queryParameters: void): string {
        return super.getRequestURL() + 'enterprises/accounts';
    }
}