
import { Injectable, EventEmitter, Output, Directive } from '@angular/core';
import { Router } from '@angular/router';
import { of as observableOf, Observable, Subject, timer, interval } from 'rxjs';
import { mapTo, concatMap, map, catchError, switchMap, takeUntil } from 'rxjs/operators';

import { NAService } from '../API/na.service';
import { LOCALSTORAGE_KEY_ACCOUNT, LOCALSTORAGE_KEY_TOKEN, LOCALSTORAGE_KEY_TOKEN_INIT_SERVER_TIME, LOCALSTORAGE_KEY_TOKEN_EXPIRE_SERVER_TIME, IClass, IAccountToken, LOCALSTORAGE_KEY_TOKEN_INIT_LOCAL_TIME } from '../lib/common/common.data';
import { HelperLib } from '../lib/common/helper.lib';
import { IAPIRx, APIBaseManager } from '../API/api.base';
import { Logger } from '../lib/common/logger';
import { AppConfigService } from '../../app/app.config';
import { ICreateAccountTokenRxData } from '../API/v1/Account/api.account.token.create';

export class UserScope {
    public static readonly Enterprise: UserScope = new UserScope('Enterprise', 'Enterprise');
    public static readonly Enterprise_SSO: UserScope = new UserScope('Enterprise.SSO', 'Enterprise SSO');
    public static readonly Enterprise_Admin: UserScope = new UserScope('Enterprise.Admin', 'Administrator');
    public static readonly Enterprise_Editor: UserScope = new UserScope('Enterprise.Editor', 'Editor');
    public static readonly Enterprise_Viewer: UserScope = new UserScope('Enterprise.Viewer', 'Viewer');

    public static readonly Task_basicSetting: UserScope = new UserScope('IAdeaCare.task.basicSetting', 'Device - Basic configuration');
    public static readonly Task_clearCache: UserScope = new UserScope('IAdeaCare.task.clearCache', 'Device - Clear cache');
    public static readonly Task_firmwareUpdate: UserScope = new UserScope('IAdeaCare.task.firmwareUpdate', 'Device - Update firmware');
    public static readonly Task_networkSetting: UserScope = new UserScope('IAdeaCare.task.networkSetting', 'Device - Network');
    public static readonly Task_reboot: UserScope = new UserScope('IAdeaCare.task.reboot', 'Device - Reboot');
    public static readonly Task_screenshot: UserScope = new UserScope('IAdeaCare.task.screenshot', '');
    public static readonly Task_securitySetting: UserScope = new UserScope('IAdeaCare.task.securitySetting', '');
    public static readonly Task_troubleshoot: UserScope = new UserScope('IAdeaCare.task.troubleshoot', '');
    public static readonly Task_iadeaCareApkUpdate: UserScope = new UserScope('IAdeaCare.task.IAdeaCareAPKUpdate', '');

    public static readonly Ticket_view: UserScope = new UserScope('IAdeaCare.ticket.view', '');

    public static readonly Device_share: UserScope = new UserScope('IAdeaCare.device.share', '');
    public static readonly Device_update: UserScope = new UserScope('IAdeaCare.device.update', '');
    public static readonly Device_view: UserScope = new UserScope('IAdeaCare.device.view', '');
    public static readonly Device_remoteControl: UserScope = new UserScope('IAdeaCare.device.remoteControl', '');

    public static readonly DeviceGroup_update: UserScope = new UserScope('IAdeaCare.deviceGroup.update', '');
    public static readonly DevicePolicy_update: UserScope = new UserScope('IAdeaCare.devicePolicy.update', '');

    public static readonly AlertSetting_view: UserScope = new UserScope('IAdeaCare.alertSetting.view', '');
    public static readonly AlertSetting_update: UserScope = new UserScope('IAdeaCare.alertSetting.update', '');
    public static readonly AlertNotification_acknowledge: UserScope = new UserScope('IAdeaCare.alertNotification.acknowledge', '');

    public static readonly WeekReport_view: UserScope = new UserScope('IAdeaCare.weeklyReport.view', '');
    public static readonly WeekReport_update: UserScope = new UserScope('IAdeaCare.weeklyReport.update', '');

    public static readonly DeviceActivity_view: UserScope = new UserScope('IAdeaCare.deviceActivity.view', '');
    public static readonly Event_view: UserScope = new UserScope('IAdeaCare.event.view', '');

    public static readonly Account_accessKey_update: UserScope = new UserScope('IAdeaCare.account.accessKey.update', '');

    public static readonly LicenseKey_view: UserScope = new UserScope('IAdeaCare.licenseKey.view', '');
    public static readonly LicenseKey_assign: UserScope = new UserScope('IAdeaCare.licenseKey.assign', '');

    public static readonly Tools_lanConfigTool: UserScope = new UserScope('IAdeaCare.tools.LANConfigTool', '');

    public static readonly Enterprise_account_view: UserScope = new UserScope('IAdeaCare.enterprise.account.view', '');
    public static readonly Enterprise_account_update: UserScope = new UserScope('IAdeaCare.enterprise.account.update', '');
    public static readonly Enterprise_account_group_update: UserScope = new UserScope('IAdeaCare.enterprise.account.group.update', '');

    private constructor(public readonly code: string, public readonly name: string) { }
}

@Directive()
@Injectable()
export class AccountService implements IClass {
    className: string;
    isLoggedIn: boolean = false;

    get accountID(): string {
        return this._tokenDetail ? this._tokenDetail.accountID : '';
    }
    get accountName(): string {
        return this._tokenDetail ? this._tokenDetail.accountName : '';
    }
    get enterpriseAccountID(): string {
        return this._tokenDetail ? this._tokenDetail.enterpriseAccountID : '';
    }
    get enterpriseAccountName(): string {
        return this._tokenDetail ? this._tokenDetail.enterpriseAccountName : '';
    }
    get enterpriseID(): string {
        return this._tokenDetail ? this._tokenDetail.enterpriseID : '';
    }
    get enterpriseName(): string {
        return this._tokenDetail ? this._tokenDetail.enterpriseName : '';
    }
    private _token: string;
    get token(): string {
        return this._token;
    }
    private _tokenDetail: IAccountToken;
    private _userScopeDic: { [scope: string]: boolean } = {};

    supportLoginRedirect: boolean = true;
    redirectUrl: string;
    errorMessage: string = '';

    private _isChecking: boolean = false;
    get isChecking(): boolean {
        return this._isChecking;
    }

    @Output() loginChanged = new EventEmitter<boolean>();

    constructor(private naSvc: NAService, private router: Router) {
        this.className = 'AccountSvc';
        Logger.logInfo(this.className, '', '');
    }

    /**
     * Login. Record login info to AccountService.
     * @param loginInfo 
     */
    login(token: string, tokenDetail: IAccountToken): void {
        Logger.logInfo(this.className, 'login', '');
        this._tokenDetail = tokenDetail;
        this._token = token;
        tokenDetail.userScope = tokenDetail.userScope || [];
        tokenDetail.userScope.reduce((prev: { [scope: string]: boolean }, curr: string) => { prev[curr] = true; return prev; }, this._userScopeDic)
        this.isLoggedIn = true;

        HelperLib.setLocalStorageRecord(LOCALSTORAGE_KEY_ACCOUNT, tokenDetail.accountName);
        HelperLib.setLocalStorageRecord(LOCALSTORAGE_KEY_TOKEN, token);
        HelperLib.setLocalStorageRecord(LOCALSTORAGE_KEY_TOKEN_INIT_SERVER_TIME, tokenDetail.iat.toString());
        HelperLib.setLocalStorageRecord(LOCALSTORAGE_KEY_TOKEN_EXPIRE_SERVER_TIME, tokenDetail.exp.toString());

        this.loginChanged.emit(true);
    }

    /**
     * Logout. Remove login info in AccountService.
     */
    logout(appLogoutOnly: boolean = false): void {
        Logger.logInfo(this.className, 'logout', '');
        this._userScopeDic = {};
        this.isLoggedIn = false;
        APIBaseManager.USER_TOKEN = null;

        this.clearAllRecords();

        const token = this._token;
        this._token = null;
        this._tokenDetail = null;

        this.loginChanged.emit(false);

        //redirect if needed
        if (!appLogoutOnly && AppConfigService.configs.setting.logoutUrl && token) {
            window.location.href = AppConfigService.configs.setting.logoutUrl + '?token=' + token;
        }
        else {
            this.router.navigate(['./account/login']);
        }
    }

    checkLogin(): Observable<boolean> {
        Logger.logInfo(this.className, 'checkLogin', '');
        if (this.isLoggedIn) {
            return observableOf(true);
        }

        this._isChecking = true;

        return observableOf(true).pipe(
            concatMap(() => {
                const lastAccountToken: string = HelperLib.getLocalStorageRecord(LOCALSTORAGE_KEY_TOKEN);
                if (!lastAccountToken) {
                    throw '[accountSvc] No last token record';
                }

                const lastAccountName: string = HelperLib.getLocalStorageRecord(LOCALSTORAGE_KEY_ACCOUNT);
                if (!lastAccountName) {
                    throw '[accountSvc] No last act record';
                }

                return this.naSvc.validateAccountToken(lastAccountToken);
            }),
            map((res: IAPIRx<string>) => {
                this._isChecking = false;
                Logger.logInfo(this.className, 'checkLogin', 'isLogin? = ', res);

                if (res.error === 0 && res.data) {
                    const tokenInfo: IAccountToken = HelperLib.parseToken(res.data);
                    if (tokenInfo) {
                        this.login(res.data, tokenInfo);
                        return true;
                    }
                }

                return false;
            }),
            catchError((err: any) => {
                this._isChecking = false;

                return observableOf(false);
            })
        );
    }

    checkPassword(oldPassword: string, reCaptchaToken: string): Observable<{ isFault: boolean, errorMessage?: string }> {
        return this.naSvc.createAccountToken({
            accountName: this._tokenDetail ? this._tokenDetail.accountName : '',
            accountPassword: oldPassword,
            reCaptchaSiteKey: AppConfigService.configs.common.reCaptcha.key,
            reCaptchaToken: reCaptchaToken
        }).pipe(
            map((res: IAPIRx<ICreateAccountTokenRxData>) => {
                return res.error === 0 && res.data ? { isFault: false } : { isFault: true, errorMessage: HelperLib.getErrorMessage(res) };
            })
        );
    }

    updatePassword(newPassword: string): Observable<{ isFault: boolean, errorMessage?: string }> {
        return this.naSvc.updatePassword({ password: newPassword }, this.token).pipe(
            map((res: IAPIRx<void>) => {
                return res.error === 0 ? { isFault: false } : { isFault: true, errorMessage: res.errorMessage || res.error.toString() };
            })
        );
    }

    private clearAllRecords(): void {
        HelperLib.removeLocalStorageRecord(LOCALSTORAGE_KEY_ACCOUNT);
        HelperLib.removeLocalStorageRecord(LOCALSTORAGE_KEY_TOKEN);
        HelperLib.removeLocalStorageRecord(LOCALSTORAGE_KEY_TOKEN_INIT_SERVER_TIME);
        HelperLib.removeLocalStorageRecord(LOCALSTORAGE_KEY_TOKEN_EXPIRE_SERVER_TIME);

        //remove this one on later version
        HelperLib.removeLocalStorageRecord(LOCALSTORAGE_KEY_TOKEN_INIT_LOCAL_TIME);
    }

    private hasScope(scope: string): boolean {
        return this._userScopeDic[scope];
    }

    hasScope_device_update(): boolean {
        return this.hasScope(UserScope.Device_update.code);
    }

    hasScope_device_group_update(): boolean {
        return this.hasScope(UserScope.DeviceGroup_update.code);
    }

    hasScope_device_share(): boolean {
        return this.hasScope(UserScope.Device_share.code);
    }

    hasScope_task_basicSetting(): boolean {
        return this.hasScope(UserScope.Task_basicSetting.code);
    }

    hasScope_device_remoteControl(): boolean {
        return this.hasScope(UserScope.Device_remoteControl.code);
    }

    hasScope_task_securitySetting(): boolean {
        return this.hasScope(UserScope.Task_securitySetting.code);
    }

    hasScope_task_firmwareUpdate(): boolean {
        return this.hasScope(UserScope.Task_firmwareUpdate.code);
    }

    hasScope_task_iadeaCareApkUpdate(): boolean {
        return this.hasScope(UserScope.Task_iadeaCareApkUpdate.code);
    }

    hasScope_task_reboot(): boolean {
        return this.hasScope(UserScope.Task_reboot.code);
    }

    hasScope_task_troubleshoot(): boolean {
        return this.hasScope(UserScope.Task_troubleshoot.code);
    }

    hasScope_task_clearCache(): boolean {
        return this.hasScope(UserScope.Task_clearCache.code);
    }

    hasScope_task_screenshot(): boolean {
        return this.hasScope(UserScope.Task_screenshot.code);
    }

    hasScope_task_netSetting(): boolean {
        return this.hasScope(UserScope.Task_networkSetting.code);
    }

    hasScope_deviceActivity_view(): boolean {
        return this.hasScope(UserScope.DeviceActivity_view.code);
    }

    hasScope_license_assign(): boolean {
        return this.hasScope(UserScope.LicenseKey_assign.code);
    }

    hasScope_license_view(): boolean {
        return this.hasScope(UserScope.LicenseKey_view.code);
    }

    hasScope_devicePolicy_update(): boolean {
        return this.hasScope(UserScope.DevicePolicy_update.code);
    }

    hasScope_alertSetting_view(): boolean {
        return this.hasScope(UserScope.AlertSetting_view.code);
    }

    hasScope_alertSetting_update(): boolean {
        return this.hasScope(UserScope.AlertSetting_update.code);
    }

    hasScope_alertNotification_ack(): boolean {
        return this.hasScope(UserScope.AlertNotification_acknowledge.code);
    }

    hasScope_weeklyReport_view(): boolean {
        return this.hasScope(UserScope.WeekReport_view.code);
    }

    hasScope_weeklyReport_update(): boolean {
        return this.hasScope(UserScope.WeekReport_update.code);
    }

    hasScope_tools_lanTool(): boolean {
        return this.hasScope(UserScope.Tools_lanConfigTool.code);
    }

    hasScope_accessKey_update(): boolean {
        return this.hasScope(UserScope.Account_accessKey_update.code);
    }

    hasScope_ticket_view(): boolean {
        return this.hasScope(UserScope.Ticket_view.code);
    }

    hasScope_event_view(): boolean {
        return this.hasScope(UserScope.Event_view.code);
    }

    hasScope_admin_account_view(): boolean {
        return this.hasScope(UserScope.Enterprise_account_view.code);
    }

    hasScope_admin_account_update(): boolean {
        return this.hasScope(UserScope.Enterprise_account_update.code);
    }

    hasScope_admin_account_group_update(): boolean {
        return this.hasScope(UserScope.Enterprise_account_group_update.code);
    }

    isEnterprise(): boolean {
        return this.hasScope(UserScope.Enterprise.code);
    }

    isEnterpriseSSO(): boolean {
        return this.hasScope(UserScope.Enterprise_SSO.code);
    }

    hasScope_enterprise_admin(): boolean {
        return this.hasScope(UserScope.Enterprise_Admin.code);
    }

    hasScope_enterprise_editor(): boolean {
        return this.hasScope(UserScope.Enterprise_Editor.code);
    }

    hasScope_enterprise_viewer(): boolean {
        return this.hasScope(UserScope.Enterprise_Viewer.code);
    }
}